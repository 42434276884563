import React, { useState, useEffect } from "react";
import BaseInputText from "../BaseInputText";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseButtonSecondary from "../BaseButtonSecondary";
import StyledMessage from "../StyledMessage";
import {
  BusinessRegistrationIdentifier,
  BusinessType,
  CompanyType,
  JobPosition,
  TwilioManager,
  TwilioSmsRegistrationRequest,
} from "../../models/twilio";
import { useToastMessageStore } from "../../store/toast-messages";
import { phoneUtils } from "../../utils";
import { createToastMessageID } from "../../utils";
import * as strings from "../../strings";

// Helper function to display error messages with StyledMessage
const ErrorMessage = ({ message }: { message?: string }) => {
  if (!message) return null;
  return <p className="mt-1 text-sm text-red-600">{message}</p>;
};

interface Props {
  onSubmit: (data: TwilioSmsRegistrationRequest) => void;
  isSubmitting: boolean;
  initialData?: Partial<TwilioSmsRegistrationRequest>;
}

export default function BusinessRegistrationForm({
  onSubmit,
  isSubmitting,
  initialData = {},
}: Props) {
  const { addToastMessage } = useToastMessageStore();

  const [formData, setFormData] = useState<
    Partial<TwilioSmsRegistrationRequest>
  >({
    business_name: "",
    business_type: BusinessType.LIMITED_LIABILITY_COMPANY,
    company_type: CompanyType.PRIVATE,
    business_registration_identifier: BusinessRegistrationIdentifier.EIN,
    website_url: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    street: "",
    city: "",
    region: "",
    postal_code: "",
    iso_country: "US",
    business_title: "",
    job_position: JobPosition.OTHER,

    // CustomerProfile fields
    ...initialData,
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [validationSummary, setValidationSummary] = useState<string[]>([]);
  const [showValidationSummary, setShowValidationSummary] = useState(false);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = e.target;

    if (type === "checkbox") {
      const checkbox = e.target as HTMLInputElement;
      setFormData({
        ...formData,
        [name]: checkbox.checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Client-side validation
    const newErrors: Record<string, string> = {};
    const errorMessages: string[] = [];

    // Required fields validation
    if (!formData.business_name) {
      newErrors.business_name = "Business name is required";
      errorMessages.push("Business name is required");
    }

    if (!formData.first_name) {
      newErrors.first_name = "First name is required";
      errorMessages.push("First name is required");
    }

    if (!formData.last_name) {
      newErrors.last_name = "Last name is required";
      errorMessages.push("Last name is required");
    }

    if (!formData.email) {
      newErrors.email = "Email is required";
      errorMessages.push("Email is required");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
      errorMessages.push("Please enter a valid email address");
    }

    if (!formData.phone_number) {
      newErrors.phone_number = "Phone number is required";
      errorMessages.push("Phone number is required");
    }

    if (!formData.street) {
      newErrors.street = "Street address is required";
      errorMessages.push("Street address is required");
    }

    if (!formData.city) {
      newErrors.city = "City is required";
      errorMessages.push("City is required");
    }

    if (!formData.region) {
      newErrors.region = "State/Province is required";
      errorMessages.push("State/Province is required");
    }

    if (!formData.postal_code) {
      newErrors.postal_code = "Postal code is required";
      errorMessages.push("Postal code is required");
    }

    if (!formData.business_registration_number) {
      newErrors.business_registration_number =
        "Registration number is required";
      errorMessages.push("Registration number is required");
    }

    if (!formData.business_title) {
      newErrors.business_title = "Business title is required";
      errorMessages.push("Business title is required");
    }

    // Validate phone number format
    let formattedValue = formData.phone_number;

    if (formData.phone_number) {
      const { PhoneNumber, warning } = phoneUtils.parse(formData.phone_number);
      if (warning === true) {
        newErrors.phone_number = "Phone number format is invalid";
        errorMessages.push("Phone number format is invalid");
      } else if (PhoneNumber) {
        formattedValue = PhoneNumber.number;
        // Update the form data with the formatted phone number
        setFormData((prev) => ({
          ...prev,
          phone_number: formattedValue,
        }));
      }
    }

    // Website URL validation
    if (formData.website_url) {
      try {
        new URL(formData.website_url);
      } catch (e) {
        newErrors.website_url = "Please enter a valid URL";
        errorMessages.push("Please enter a valid URL");
      }
    }

    // If there are errors, update the errors state and prevent submission
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setValidationSummary(errorMessages);
      setShowValidationSummary(true);
      return;
    }

    // If validation passes, proceed with submission
    try {
      const validatedData =
        TwilioManager.validateTwilioSmsRegistrationRequest(formData);
      setShowValidationSummary(false);
      onSubmit(validatedData);
    } catch (error) {
      // Handle validation errors from Zod
      if (error instanceof Error) {
        setErrors({ form: error.message });
        setValidationSummary([error.message]);
        setShowValidationSummary(true);
        addToastMessage({
          id: createToastMessageID(),
          message: error.message,
          dialog: false,
          type: "error",
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="rounded-lg bg-blue-50 p-4 text-blue-800">
        <h3 className="mb-1 font-medium">SMS Business Registration</h3>
        <p className="text-sm">
          This information is required by mobile carriers to register your
          business for text messaging. All fields are required unless marked as
          optional.
        </p>
      </div>

      <div className="space-y-4">
        <h3 className="text-lg font-medium">Business Information</h3>

        <BaseInputText
          inputName="business_name"
          text="Legal Business Name"
          value={formData.business_name}
          onChange={handleInputChange}
          className={errors.business_name ? "border-red-500" : ""}
          admin
        />

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <label
              htmlFor="business_type"
              className="mb-1 block text-sm font-medium text-gray-700"
            >
              Business Type
            </label>
            <select
              id="business_type"
              name="business_type"
              value={formData.business_type}
              onChange={handleInputChange}
              className={`block w-full rounded border p-2 ${
                errors.business_type ? "border-red-500" : "border-gray-300"
              }`}
            >
              <option value={BusinessType.SOLE_PROPRIETORSHIP}>
                Sole Proprietorship
              </option>
              <option value={BusinessType.PARTNERSHIP}>Partnership</option>
              <option value={BusinessType.LIMITED_LIABILITY_COMPANY}>
                Limited Liability Company
              </option>
              <option value={BusinessType.CO_OPERATIVE}>Co-operative</option>
              <option value={BusinessType.NON_PROFIT_CORPORATION}>
                Non-Profit Corporation
              </option>
              <option value={BusinessType.CORPORATION}>Corporation</option>
            </select>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <label
              htmlFor="company_type"
              className="mb-1 block text-sm font-medium text-gray-700"
            >
              Company Type
            </label>
            <select
              id="company_type"
              name="company_type"
              value={formData.company_type}
              onChange={handleInputChange}
              className={`block w-full rounded border p-2 ${
                errors.company_type ? "border-red-500" : "border-gray-300"
              }`}
            >
              <option value={CompanyType.GOVERNMENT}>Government</option>
              <option value={CompanyType.NON_PROFIT}>Non-Profit</option>
              <option value={CompanyType.PRIVATE}>Private</option>
              <option value={CompanyType.PUBLIC}>Public</option>
            </select>
          </div>
        </div>

        <BaseInputText
          inputName="website_url"
          text="Website URL"
          value={formData.website_url}
          onChange={handleInputChange}
          className={errors.website_url ? "border-red-500" : ""}
          placeholder="https://example.com"
          admin
        />

        {/* Business Registration Number Dropdown */}
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div>
            <label
              htmlFor="business_registration_identifier"
              className="mb-1 block text-sm font-medium text-gray-700"
            >
              Business Type
            </label>
            <select
              id="business_registration_identifier"
              name="business_registration_identifier"
              value={formData.business_registration_identifier}
              onChange={handleInputChange}
              className={`block w-full rounded border p-2 ${
                errors.business_registration_identifier
                  ? "border-red-500"
                  : "border-gray-300"
              }`}
            >
              <option value={BusinessRegistrationIdentifier.EIN}>
                US: Employer Identification Number (EIN)
              </option>
              <option value={BusinessRegistrationIdentifier.CBN}>
                Canada: Canadian Business Number
              </option>
            </select>
          </div>
        </div>

        <BaseInputText
          inputName="business_registration_number"
          text="Business Registration Number"
          value={formData.business_registration_number || ""}
          onChange={handleInputChange}
          className={
            errors.business_registration_number ? "border-red-500" : ""
          }
          admin
        />
      </div>

      <div className="space-y-4">
        <h3 className="text-lg font-medium">Business Address</h3>

        <BaseInputText
          inputName="street"
          text="Street Address"
          value={formData.street}
          onChange={handleInputChange}
          className={errors.street ? "border-red-500" : ""}
          admin
        />

        <BaseInputText
          inputName="street_secondary"
          text="Street Address Line 2 (optional)"
          value={formData.street_secondary || ""}
          onChange={handleInputChange}
          className={errors.street_secondary ? "border-red-500" : ""}
          admin
        />

        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <BaseInputText
            inputName="city"
            text="City"
            value={formData.city}
            onChange={handleInputChange}
            className={errors.city ? "border-red-500" : ""}
            admin
          />

          <BaseInputText
            inputName="region"
            text="State/Province"
            value={formData.region}
            onChange={handleInputChange}
            className={errors.region ? "border-red-500" : ""}
            admin
          />

          <BaseInputText
            inputName="postal_code"
            text="Postal Code"
            value={formData.postal_code}
            onChange={handleInputChange}
            className={errors.postal_code ? "border-red-500" : ""}
            admin
          />
        </div>

        <div>
          <label
            htmlFor="iso_country"
            className="mb-1 block text-sm font-medium text-gray-700"
          >
            Country
          </label>
          <select
            id="iso_country"
            name="iso_country"
            value={formData.iso_country}
            onChange={handleInputChange}
            className={`block w-full rounded border p-2 ${
              errors.iso_country ? "border-red-500" : "border-gray-300"
            }`}
          >
            <option value="US">United States</option>
            <option value="CA">Canada</option>
          </select>
        </div>
      </div>

      <div className="space-y-4">
        <h3 className="text-lg font-medium">Contact Information</h3>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <BaseInputText
            inputName="first_name"
            text="First Name"
            value={formData.first_name}
            onChange={handleInputChange}
            className={errors.first_name ? "border-red-500" : ""}
            admin
          />

          <BaseInputText
            inputName="last_name"
            text="Last Name"
            value={formData.last_name}
            onChange={handleInputChange}
            className={errors.last_name ? "border-red-500" : ""}
            admin
          />
        </div>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <BaseInputText
            inputName="email"
            text="Email"
            value={formData.email}
            onChange={handleInputChange}
            className={errors.email ? "border-red-500" : ""}
            admin
          />

          <BaseInputText
            inputName="phone_number"
            text="Phone Number"
            value={formData.phone_number}
            onChange={handleInputChange}
            className={errors.phone_number ? "border-red-500" : ""}
            placeholder=""
            admin
          />
        </div>

        <BaseInputText
          inputName="business_title"
          text="Business Title"
          value={formData.business_title || ""}
          onChange={handleInputChange}
          className={errors.business_title ? "border-red-500" : ""}
          admin
        />

        <div>
          <label
            htmlFor="job_position"
            className="mb-1 block text-sm font-medium text-gray-700"
          >
            Job Position
          </label>
          <select
            id="job_position"
            name="job_position"
            value={formData.job_position}
            onChange={handleInputChange}
            className={`block w-full rounded border p-2 ${
              errors.job_position ? "border-red-500" : "border-gray-300"
            }`}
          >
            <option value={JobPosition.DIRECTOR}>Director</option>
            <option value={JobPosition.GM}>General Manager (GM)</option>
            <option value={JobPosition.VP}>Vice President (VP)</option>
            <option value={JobPosition.CEO}>
              Chief Executive Officer (CEO)
            </option>
            <option value={JobPosition.CFO}>
              Chief Financial Officer (CFO)
            </option>
            <option value={JobPosition.GENERAL_COUNSEL}>General Counsel</option>
            <option value={JobPosition.OTHER}>Other</option>
          </select>
        </div>
      </div>

      <div className="flex justify-end space-x-4 pt-4">
        <BaseButtonSecondary
          type="button"
          onClick={() => window.history.back()}
          disabled={isSubmitting}
        >
          Cancel
        </BaseButtonSecondary>
        <BaseButtonPrimary type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Submit Registration"}
        </BaseButtonPrimary>
      </div>

      {/* Validation summary below submit button */}
      {showValidationSummary && validationSummary.length > 0 && (
        <div className="mt-4 flex flex-row justify-end">
          <StyledMessage type="error">
            {{
              message: (
                <div>
                  <p className="font-medium">
                    Please fix the following errors:
                  </p>
                  <ul className="mt-2">
                    {validationSummary.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              ),
            }}
          </StyledMessage>
        </div>
      )}
    </form>
  );
}
