import ReactMarkdown from "react-markdown";

import { STILTON } from "../../strings";
import { Link } from "react-router-dom";
import { WORK_RECORD_AND_TASKS_URL } from "../../urls";

interface Props {
  /** Defaults to "Stilton A.I." */
  headerText?: string;
  isLoadingOverview: boolean;
  onRefreshOverview: () => void;
  overviewContent: string | null;
}

export default function StiltonActivityOverview(props: Props) {
  const processOverviewText = (text: string) => {
    return text.replace(
      /\[craftRecordID: ([^\]]+)\]/g,
      (_, craftRecordID) =>
        `[View Job](${WORK_RECORD_AND_TASKS_URL}/${craftRecordID})`,
    );
  };

  // to avoid having a nested ternary --
  function TheContent(): JSX.Element {
    if (!props.isLoadingOverview && !props.overviewContent) {
      // user hasn't interacted with the panel yet
      return (
        <div className="flex h-full items-center justify-center text-gray-500">
          <p>{STILTON.REFRESH_GET_OVERVIEW}</p>
        </div>
      );
    }
    if (props.isLoadingOverview && !props.overviewContent) {
      // user is waiting for data to load
      return (
        <div className="flex h-full items-center justify-center">
          <div className="relative flex h-8 w-8 items-center justify-center">
            <div className="absolute h-8 w-8 animate-spin rounded-full border-2 border-purple-600 border-t-transparent"></div>
          </div>
        </div>
      );
    }
    if (props.overviewContent) {
      return (
        <ReactMarkdown
          components={{
            h3: ({ node: _, ...props }) => (
              <h3 className="mb-2 mt-4 text-lg font-bold" {...props} />
            ),
            h4: ({ node: _, ...props }) => (
              <h4 className="mb-1 mt-3 text-base font-semibold" {...props} />
            ),
            ul: ({ node: _, ...props }) => (
              <ul className="mb-3 ml-4 list-disc" {...props} />
            ),
            li: ({ node: _, ...props }) => <li className="mb-1" {...props} />,
            p: ({ node: _, ...props }) => <p className="mb-3" {...props} />,
            strong: ({ node: _, ...props }) => (
              <strong className="font-semibold" {...props} />
            ),
            a: ({ node: _, href, ...props }) => (
              <Link
                to={href || ""}
                className="text-blue-600 hover:text-blue-800 hover:underline"
                {...props}
              />
            ),
          }}
        >
          {processOverviewText(props.overviewContent)}
        </ReactMarkdown>
      );
    }
    return (
      <div className="flex h-full items-center justify-center text-gray-500">
        {/* as of now, this is really just for show. ...also, it makes typescript happy */}
        <p>{STILTON.WENT_WRONG}</p>
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col rounded-lg border border-gray-200 bg-white shadow-md">
      {/* HEADER - purple gazz. */}
      <div className="flex items-center justify-between gap-2 rounded-t-lg border-b border-purple-800 bg-purple-600 p-4 text-white">
        <div className="flex items-center gap-2">
          <img
            src="/white_hardhat_transparent_bg.svg"
            alt="Stilton AI Icon"
            className="h-6 w-6"
          />
          <h2 className="text-base font-semibold xs:text-lg">
            {props.headerText ?? STILTON.NAME}
          </h2>
        </div>

        <button
          onClick={props.onRefreshOverview}
          disabled={props.isLoadingOverview}
          className="flex items-center rounded-lg bg-white/10 px-3 py-1 text-sm font-medium transition-colors hover:bg-white/20 disabled:cursor-default disabled:opacity-50"
        >
          {props.isLoadingOverview ? (
            <div className="relative flex h-4 w-4 items-center justify-center">
              <div className="absolute h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent"></div>
            </div>
          ) : (
            "Refresh"
          )}
        </button>
      </div>

      <div className="flex-1 overflow-y-auto p-4">
        <TheContent />
      </div>
    </div>
  );
}
