import { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import type { ColDef } from "ag-grid-community";
import { useSiteKeyDocStore } from "../../store/site-key-doc";

import PhoneNumbersPage from "./PhoneNumbersPage";
import PurchaseNumberDialog from "../../components/phones/PurchaseNumberDialog";
import { DbRead } from "../../database";
import { phoneUtils } from "../../utils";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import EditNumberDialog from "../../components/phones/EditNumberDialog";
import { ExistingSitePhoneNumber } from "../../models/phone-numbers";

interface PhoneNumber {
  id: string;
  phoneNumber: string;
  description: string;
  dateCreated: string;
}

const columnDefs: ColDef[] = [
  {
    field: "phoneNumber",
    headerName: "Number",
    maxWidth: 200,
    cellRenderer: (params: any) => phoneUtils.display(params.value),
  },
  {
    field: "description",
    headerName: "Description",
  },
  {
    field: "dateCreated",
    headerName: "Date Created",
    cellRenderer: (params: any) => new Date(params.value).toLocaleDateString(),
  },
];

export default function PhoneNumbersContainer() {
  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);
  const [numbers, setNumbers] = useState<ExistingSitePhoneNumber[]>([]);
  const [isPurchaseDialogOpen, setIsPurchaseDialogOpen] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState<PhoneNumber | null>(
    null,
  );

  useEffect(() => {
    const fetchNumbers = async () => {
      if (!siteKeyDoc?.id) return;

      const numbers = await DbRead.phones.listNumbers(siteKeyDoc.id);
      setNumbers(numbers);
    };
    fetchNumbers();
  }, [siteKeyDoc?.id]);

  const handleRowClick = (params: any) => {
    setSelectedNumber(params.data);
  };

  if (!siteKeyDoc?.id) {
    return <div>Loading...</div>;
  }

  return (
    <PhoneNumbersPage title="Tracking Numbers">
      {{
        tableControlsSlot: (
          <div className="flex items-center justify-end space-x-4">
            <div>
              Your first 5 numbers are free. Each additional number is $2/month
            </div>
            <BaseButtonPrimary
              type="button"
              onClick={() => setIsPurchaseDialogOpen(true)}
              className="w-full justify-center uppercase xs:w-52"
            >
              Purchase New Number
            </BaseButtonPrimary>
          </div>
        ),
        tableSlot: (
          <>
            <PurchaseNumberDialog
              open={isPurchaseDialogOpen}
              onClose={() => setIsPurchaseDialogOpen(false)}
              onPurchaseComplete={async () => {
                const phoneNumbers = await DbRead.phones.listNumbers(
                  siteKeyDoc.id,
                );
                setNumbers(phoneNumbers);
              }}
              siteKey={siteKeyDoc.id}
            />

            {selectedNumber && (
              <EditNumberDialog
                open={true}
                onClose={() => setSelectedNumber(null)}
                onSave={async () => {
                  const numbers = await DbRead.phones.listNumbers(
                    siteKeyDoc.id,
                  );
                  setNumbers(numbers);
                }}
                siteKey={siteKeyDoc.id}
                number={selectedNumber}
              />
            )}

            <AgGridReact
              rowData={numbers}
              columnDefs={columnDefs}
              defaultColDef={{
                flex: 1,
                resizable: true,
                sortable: true,
              }}
              onRowClicked={handleRowClick}
              rowStyle={{ cursor: "pointer" }}
            />
          </>
        ),
      }}
    </PhoneNumbersPage>
  );
}
