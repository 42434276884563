//Libs
//Local

import HeadingOne from "./HeadingOne";

interface Props {
  title: string;
  children?: string;
  className?: string;
  detailsInfoArea: boolean;
}

export default function PageHeading({
  title,
  children,
  className,
  detailsInfoArea,
}: Props) {
  const textColor = detailsInfoArea ? "text-white" : "text-primary";

  return (
    <div>
      <HeadingOne fontColor={textColor} className="inline-block">
        {title}
      </HeadingOne>
      <div className={className}>{children}</div>
    </div>
  );
}
