//Libs
import { useEffect, useMemo, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Timestamp } from "firebase/firestore";
import PersonIcon from "@mui/icons-material/Person";
// import { DevTool } from "@hookform/devtools";

//Local
import BaseButtonPrimary from "../BaseButtonPrimary";
import * as strings from "../../strings";
import BaseModal from "../BaseModal";
import { ExistingCustomer } from "../../models/customer";
import { ExistingCustomerLocation } from "../../models/customer-location";
import CustomerTableDialog from "../customers/CustomerTableDialog";
import { CustomerDetails } from "../estimates/CustomerDetails";
import { CustomerLocationSection } from "../customers/CreateTask";
import { DbRead, DbWrite } from "../../database";
import {
  Membership,
  MembershipManager,
  MembershipStatus,
} from "../../models/membership";
import { useAuthStore } from "../../store/firebase-auth";
import { useMembershipTemplatesStore } from "../../store/membership-templates";
import { ExistingMembershipTemplate } from "../../models/membership-template";
import AssetEquipmentListCheckboxes from "./AssetEquipmentListCheckboxes";
import { ExistingAsset } from "../../models/asset";
import { logger } from "../../logging";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import BaseInputSelect from "../BaseInputSelect";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import { AddEditMembershipForm } from "./AddEditMembershipForm";
import StyledMessage from "../StyledMessage";
import { MembershipFormSchema, MembershipFormState } from "./formTypes";

interface Props {
  isDialogOpen: boolean;
  closeDialog: () => void;

  siteKey: string;
  assetsEnabled: boolean;
  defaultEmailDuesCollectedReceipt: boolean;
  customer: ExistingCustomer | null;
  customerLocation: ExistingCustomerLocation | null;
  customerLocationOptions: ExistingCustomerLocation[] | null;
  locationID: string | null;
  currency: string;
}

export default function CreateMembershipDialog(props: Props) {
  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  const firebaseUser = useAuthStore((state) => state.firebaseUser);

  const [siteKeyLocationList, isLoadingSKLocs] = useSiteKeyLocationsStore(
    (state) => [state.siteKeyLocationList, state.loading],
  );
  const membershipTemplateList = useMembershipTemplatesStore(
    (state) => state.membershipTemplates,
  );

  const [pickCustomerDialogOpen, setPickCustomerDialogOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] =
    useState<ExistingCustomer | null>(props.customer);

  // this is only used for site location
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedSiteKeyLoc, setSelectedSiteKeyLoc] =
    useState<ExistingSiteKeyLocation | null>(null);

  const [cusLocOptions, setCusLocOptions] = useState<
    ExistingCustomerLocation[] | null
  >(props.customerLocationOptions);
  const [selectedCusLoc, setSelectedCusLoc] =
    useState<ExistingCustomerLocation | null>(props.customerLocation);

  const [selectedTemplate, setSelectedTemplate] =
    useState<ExistingMembershipTemplate | null>(null);
  // so we can fetch the template after it's been selected:
  const [templateID, setTemplateID] = useState<string | null>(null);

  const [assetOptions, setAssetOptions] = useState<ExistingAsset[]>([]);
  const [assetIDs, setAssetIDs] = useState<string[] | undefined>([]);

  const defaultValues = useMemo(
    (): MembershipFormState => ({
      lastCompletedTaskDate: null,
      lastPaymentAmount: null,
      lastPaymentDate: null,
      membershipEndDate: null,
      membershipStartDate: null,
      nextInvoiceDate: null,
      nextScheduledTaskDate: null,
      notes: null,
      status: null,
      automaticallyGenerateTasks: false,
      automaticallySendReceipt: props.defaultEmailDuesCollectedReceipt,
      automaticallySendInvoice: false,
      automaticallyPayInvoice: false,
      membershipTemplateID: null,
      customerID: props.customer?.id ?? null,
      customerLocationID: props.customerLocation?.id ?? null,
      overrideEmail: undefined,
      overrideInvoice: undefined,
      // not editable
      formType: "create",
    }),
    [
      props.customer?.id,
      props.customerLocation?.id,
      props.defaultEmailDuesCollectedReceipt,
    ],
  );

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
    setValue,
    watch,
  } = useForm<MembershipFormState>({
    defaultValues: defaultValues,
    resolver: zodResolver(MembershipFormSchema),
    mode: "onChange",
  });

  const watchTaskGenToggle = watch("automaticallyGenerateTasks");
  const watchAutoPayToggle = watch("automaticallyPayInvoice");
  const watchEmailInvoiceToggle = watch("automaticallySendInvoice");
  const watchEmailReceiptToggle = watch("automaticallySendReceipt");
  const watchStartDate = watch("membershipStartDate");
  const watchEndDate = watch("membershipEndDate");
  const watchNextInvoiceDate = watch("nextInvoiceDate");
  const watchOverrideEmail = watch("overrideEmail");
  const watchOverrideInvoice = watch("overrideInvoice");

  useEffect(() => {
    function getAssetsForCustomerLocation() {
      if (!selectedCusLoc) return undefined;
      const unsubscribeFn = DbRead.assets.subscribeByCustomerLocationID({
        siteKey: props.siteKey,
        customerLocationID: selectedCusLoc.id,
        onChange: setAssetOptions,
        onError: logger.error,
      });
      return unsubscribeFn;
    }
    const unsubscribe = getAssetsForCustomerLocation();
    return () => unsubscribe && unsubscribe();
  }, [props.siteKey, selectedCusLoc]);

  useEffect(() => {
    if (isLoadingSKLocs) return;
    if (siteKeyLocationList.length === 1) {
      setSelectedSiteKeyLoc(siteKeyLocationList[0]);
    }
  }, [isLoadingSKLocs, siteKeyLocationList]);

  useEffect(() => {
    function getCusLocOptions() {
      if (!selectedCustomer) return undefined;
      const unsubscribeFn = DbRead.customerLocations.subscribeByCustomerID({
        siteKey: props.siteKey,
        customerID: selectedCustomer.id,
        onChange: setCusLocOptions,
        onError: logger.error,
      });
      return unsubscribeFn;
    }
    const unsubscribe = getCusLocOptions();
    return () => unsubscribe && unsubscribe();
  }, [props.siteKey, selectedCustomer]);

  useEffect(() => {
    function getTemplate() {
      if (!templateID) return undefined;
      const unsubscribeFn = DbRead.membershipTemplates.subscribe({
        siteKey: props.siteKey,
        templateID: templateID,
        onChange: setSelectedTemplate,
        onError: logger.error,
      });
      return unsubscribeFn;
    }
    const unsubscribe = getTemplate();
    return () => unsubscribe && unsubscribe();
  }, [props.siteKey, templateID]);

  useEffect(() => {
    if (!props.customer) return;
    setSelectedCustomer(props.customer);
  }, [props.customer]);

  useEffect(() => {
    // Always update the form value when template changes, using the template value or false
    setValue(
      "automaticallyGenerateTasks",
      selectedTemplate?.automaticallyGenerateTasks ?? false,
    );
    setValue(
      "automaticallySendInvoice",
      selectedTemplate?.automaticallySendInvoice ?? false,
    );
    setValue(
      "automaticallyPayInvoice",
      selectedTemplate?.automaticallyPayInvoice ?? false,
    );
  }, [selectedTemplate, setValue]);

  const selectCustomerDialog = (
    <CustomerTableDialog
      isOpen={pickCustomerDialogOpen}
      onClose={() => setPickCustomerDialogOpen(false)}
      siteKey={props.siteKey}
      onSelectCustomer={(c: ExistingCustomer) => {
        setSelectedCustomer(c);
        setValue("customerID", c.id);
        setPickCustomerDialogOpen(false);
      }}
      addCustomerButton={null}
    />
  );

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  function closeAndReset() {
    reset();
    // don't want to reset these if the dialog was opened from ShowSingleCustomer.
    if (!props.customer) setSelectedCustomer(null);
    if (!props.customerLocationOptions?.length) setCusLocOptions(null);
    if (!props.customerLocation) {
      setSelectedCusLoc(null);
      setAssetOptions([]);
    }

    // the user will always have to select a template, and though the assetOptions
    // should be persisted, the assetIDs list is populated from user interaction and
    // should therefore be reset.
    setPickCustomerDialogOpen(false);
    setAssetIDs([]);
    setTemplateID(null);
    setSelectedTemplate(null);
    setErrorMessage(null);

    props.closeDialog();
  }

  const selectCustomerButton = (
    <BaseButtonPrimary
      type="button"
      onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.currentTarget.blur(); // allows the customer dialog to get focus
        setPickCustomerDialogOpen(true);
      }}
    >
      <PersonIcon fontSize="small" className="mr-2" />
      {strings.SELECT_CUSTOMER}
    </BaseButtonPrimary>
  );

  function getCLSectionTitle(
    customer: ExistingCustomer | null,
    options: ExistingCustomerLocation[] | null,
  ): string {
    if (!customer) {
      return "No locations to choose from, please select a customer.";
    }
    if (!options || options.length === 0) {
      return "Add a location for this customer.";
    }
    return "";
  }

  const customerLocationSection = (
    <>
      {!selectedCustomer || cusLocOptions?.length === 0 ? (
        <div className="rounded-md border border-gray-500 px-3 py-2">
          <h6 className="mb-0 max-w-fit text-base font-semibold text-primary">
            {strings.SELECT_LOCATION} <span className="text-redFail">*</span>
          </h6>
          <p className="text-sm font-normal text-gray-700">
            {getCLSectionTitle(selectedCustomer, cusLocOptions)}
          </p>
        </div>
      ) : (
        <CustomerLocationSection
          allowSelection={true}
          selectedCustomer={selectedCustomer}
          customerLocationOptions={cusLocOptions}
          selectedCustomerLocation={selectedCusLoc}
          setSelectedCustomerLocation={(location) => {
            setSelectedCusLoc(location);
            setValue("customerLocationID", location?.id ?? null);
          }}
          openEditExistingCustomerLocation={undefined}
          membershipTemplateList={[]}
          customerLocationMemberships={[]}
          useBaseInputSelect
        />
      )}
    </>
  );

  const onSubmit: SubmitHandler<MembershipFormState> = async (formValues) => {
    logger.info("CreateMembershipDialog onSubmit values", formValues);
    if (!firebaseUser) {
      throw Error("CreateMembershipDialog onSubmit: firebaseUser is null");
    }
    if (!selectedSiteKeyLoc) {
      setErrorMessage("Select a site location");
      return;
    }
    if (
      !selectedCustomer ||
      !selectedTemplate ||
      !formValues.customerLocationID ||
      !formValues.membershipTemplateID
    ) {
      return;
    }

    const now = Timestamp.now();
    const membership: Membership = {
      createdBy: firebaseUser.uid,
      customerID: selectedCustomer.id,
      customerLocationID: formValues.customerLocationID,
      customerName: selectedCustomer.name,
      lastCompletedTaskDate: formValues.lastCompletedTaskDate,
      lastModifiedBy: firebaseUser.uid,
      lastPaymentAmount: formValues.lastPaymentAmount,
      lastPaymentDate: formValues.lastPaymentDate,
      locationID: selectedSiteKeyLoc.id,
      membershipEndDate: formValues.membershipEndDate,
      membershipStartDate: formValues.membershipStartDate,
      membershipTemplateID: formValues.membershipTemplateID,
      nextInvoiceDate: formValues.nextInvoiceDate,
      nextScheduledTaskDate: formValues.nextScheduledTaskDate,
      automaticallyGenerateTasks: formValues.automaticallyGenerateTasks,
      notes:
        formValues.notes && formValues.notes.length > 0
          ? formValues.notes
          : null,
      status: formValues.status ?? MembershipStatus.Draft,
      timestampCreated: now,
      timestampLastModified: now,
    };
    // Conditionally add to the membership
    if (assetIDs && assetIDs.length) membership.assetIDs = assetIDs;
    if (selectedTemplate.invoiceMethod === "automatic") {
      membership.automaticallySendReceipt = formValues.automaticallySendReceipt;
      membership.automaticallySendInvoice = formValues.automaticallySendInvoice;
      membership.automaticallyPayInvoice = formValues.automaticallyPayInvoice;
    }

    // OVERRIDE DATA
    if (formValues.overrideEmail) {
      if (!membership.overrideData) membership.overrideData = {};
      if (
        formValues.overrideEmail &&
        formValues.overrideEmail.trim().length > 0
      ) {
        membership.overrideData.email = formValues.overrideEmail;
      }
    }
    if (formValues.overrideInvoice) {
      if (!membership.overrideData) membership.overrideData = {};
      membership.overrideData.invoice = formValues.overrideInvoice;
    }

    try {
      const valid = MembershipManager.parse(membership);
      logger.info("create membership", valid);
      await DbWrite.memberships.create(
        props.siteKey,
        MembershipManager.convertForFirestore(valid),
      );
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulCreate("membership"),
        dialog: false,
        type: "success",
      });
      closeAndReset();
    } catch (e) {
      logger.error(e);
      // setErrorMessage("Unable to create membership")
      addMessage({
        id: createToastMessageID(),
        message: strings.failedCreate("membership"),
        dialog: true,
        type: "error",
      });
    }
  };

  const customerSection = (
    <div>
      {selectedCustomer === null && selectCustomerButton}
      {selectedCustomer && (
        <div className="-mt-4 md:mt-0 md:min-w-48 md:max-w-96">
          <CustomerDetails
            customer={selectedCustomer}
            openEditCustomerDialog={null}
            openCallDialog={null}
            shouldConstrainWidth={false}
          />
        </div>
      )}
      {errors.customerID?.message && (
        <div className="mt-2 text-sm">
          <StyledMessage type="error">
            {{ message: errors.customerID.message }}
          </StyledMessage>
        </div>
      )}
    </div>
  );

  const templateSection = (
    <div>
      <Controller
        name="membershipTemplateID"
        control={control}
        render={({ field }) => (
          <BaseInputSelect
            id="membershipTemplateID"
            inputName="membershipTemplateID"
            text="Membership Type"
            admin
            required
            {...field}
            value={field.value ?? ""}
            onChange={(event) => {
              const val = event.target.value === "" ? null : event.target.value;
              field.onChange(val);
              setTemplateID(val);
            }}
          >
            <option value="" disabled>
              Select a Membership Type
            </option>

            {membershipTemplateList.map((template) => (
              <option key={template.id} value={template.id}>
                {template.title}
              </option>
            ))}
          </BaseInputSelect>
        )}
      />
      {errors.membershipTemplateID?.message && (
        <div className="mt-2 text-sm">
          <StyledMessage type="error">
            {{ message: errors.membershipTemplateID.message }}
          </StyledMessage>
        </div>
      )}
    </div>
  );

  const selectSiteLocationElement = siteKeyLocationList.length > 1 && (
    <div>
      <BaseInputSelect
        id="locationID"
        inputName="locationID"
        text="Site Location"
        admin
        required
        value={selectedSiteKeyLoc?.id ?? ""}
        onChange={(event) => {
          const loc = siteKeyLocationList.find(
            (l) => l.id === event.target.value,
          );
          setSelectedSiteKeyLoc(loc ?? null);
        }}
      >
        <option value="" disabled>
          Select Site Location
        </option>

        {siteKeyLocationList.map((skl) => (
          <option key={skl.id} value={skl.id}>
            {skl.title}
          </option>
        ))}
      </BaseInputSelect>
      {errorMessage && (
        <div className="mt-2 text-sm">
          <StyledMessage type="error">
            {{ message: errorMessage }}
          </StyledMessage>
        </div>
      )}
    </div>
  );

  const assetSection = props.assetsEnabled && assetOptions.length > 0 && (
    // Allow user to specify one or more assets that might apply to this membership
    <>
      <h6 className="text-base font-semibold text-primary">
        {strings.SELECT_ASSET}:
      </h6>
      <AssetEquipmentListCheckboxes
        assetsForCustomer={assetOptions}
        assetIDs={assetIDs}
        setAssetIDs={setAssetIDs}
      />
    </>
  );

  const header = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white">
      <h1 className="inline-flex items-center text-xl font-semibold">
        {strings.NEW_MEMBERSHIP}
      </h1>
      <button type="button" onClick={closeAndReset}>
        <XMarkIcon
          aria-label="close create membership dialog"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <>
      <BaseModal
        closeModal={() => {}} // prevent unintentionally closing dialog
        open={props.isDialogOpen}
        title={header}
        allowOverflowY={true}
        parentDivStyles="inline-block transform overflow-hidden max-w-4xl rounded-lg bg-white text-left align-middle shadow-xl transition-all"
      >
        <AddEditMembershipForm
          formType="create"
          siteKey={props.siteKey}
          rhfHandleSubmit={handleSubmit}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
          closeAndReset={closeAndReset}
          control={control}
          errors={errors}
          template={selectedTemplate}
          setValue={setValue}
          currency={props.currency}
          customer={selectedCustomer}
          locationID={selectedSiteKeyLoc?.id ?? null}
          customerTaxRate={selectedCusLoc?.totalTaxRate ?? 0}
          watchedFields={{
            autoGenerateTasks: watchTaskGenToggle,
            autoSendReceipt: watchEmailReceiptToggle,
            autoEmailInvoice: watchEmailInvoiceToggle,
            autoPayInvoice: watchAutoPayToggle,
            membershipStartDate: watchStartDate,
            membershipEndDate: watchEndDate,
            nextInvoiceDate: watchNextInvoiceDate,
            overrideEmail: watchOverrideEmail,
            overrideInvoice: watchOverrideInvoice,
          }}
        >
          {{
            customerSection: customerSection,
            customerLocationSection: customerLocationSection,
            selectSiteLocationElement: selectSiteLocationElement,
            selectTemplateElement: templateSection,
            assetSection: assetSection,
          }}
        </AddEditMembershipForm>
      </BaseModal>
      {selectCustomerDialog}
      {/* <DevTool control={control} /> */}
    </>
  );
}
