import { useState } from "react";
import BaseButtonPrimary from "../../../../components/BaseButtonPrimary";
import BaseButtonSecondary from "../../../../components/BaseButtonSecondary";
import StyledMessage from "../../../../components/StyledMessage";
import { formatPhoneNumber, formatSSN } from "../../../../utils/formatters";
import currencyFormatter from "../../../../currency";
import { DbWrite } from "../../../../database";

interface ReviewStepProps {
  data: {
    business: {
      businessName: string;
      legalName: string;
      ownershipStructureType:
        | "LLC"
        | "Corporation"
        | "Partnership"
        | "SoleProprietorship";
      street1: string;
      street2?: string;
      city: string;
      state: string;
      zipCode: string;
      countryCode: string;
      descriptorPhone: string;
      taxId: string;
      webSite?: string;
      email: string;
      description: string;
      //   merchantCategoryCode: string;
      currencyCode: string;
      registrationYear: string;
      timeZoneCode: string;
    };
    estimates: {
      annualDirectDebitVolume: number;
      annualCardsVolume: number;
      avgDirectDebitTransactionAmount: number;
      avgCardsTransactionAmount: number;
      maxTransactionAmount: number;
    };
    deposit: {
      bankName: string;
      holderName: string;
      routingNumber: string;
      accountNumber: string;
      accountType: "C" | "S";
    };
    officer: {
      title:
        | "Owner"
        | "Partner"
        | "Director"
        | "President"
        | "CEO"
        | "CFO"
        | "Chairman"
        | "Comptroller"
        | "General Manager"
        | "Office Manager"
        | "Treasurer"
        | "Vice President";
      firstName: string;
      lastName: string;
      street1: string;
      street2?: string;
      city: string;
      state: string;
      zipCode: string;
      countryCode: string;
      birthDate: string;
      socialSecurity: string;
      phone: string;
      email: string;
      driverLicense?: string;
      driverLicenseState?: string;
      driverLicenseCountryCode: string;
    };
    termsAccepted: boolean;
    owners: {
      title:
        | "Owner"
        | "Partner"
        | "Director"
        | "President"
        | "CEO"
        | "CFO"
        | "Chairman"
        | "Comptroller"
        | "General Manager"
        | "Office Manager"
        | "Treasurer"
        | "Vice President";
      firstName: string;
      lastName: string;
      street1: string;
      street2?: string;
      city: string;
      state: string;
      zipCode: string;
      countryCode: string;
      birthDate: string;
      socialSecurity: string;
      phone: string;
      email: string;
      driverLicense?: string;
      driverLicenseState?: string;
      driverLicenseCountryCode: string;
      stakePercentage: number;
    }[];
  };
  onComplete: (success: boolean, message: string) => void;
}

export default function ReviewStep({ data, onComplete }: ReviewStepProps) {
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(data.termsAccepted);

  const formatAddress = (address: {
    street1: string;
    city: string;
    state: string;
    zipCode: string;
  }) => {
    return `${address.street1}, ${address.city}, ${address.state} ${address.zipCode}`;
  };

  const handleSubmit = async () => {
    if (!termsAccepted) {
      setError("Please accept the terms and conditions to continue");
      return;
    }

    if (data.owners.length === 0) {
      setError("At least one owner is required");
      return;
    }

    const totalOwnership = data.owners.reduce(
      (sum, owner) => sum + owner.stakePercentage,
      0,
    );
    if (totalOwnership !== 100) {
      setError("Total ownership percentage must equal 100%");
      return;
    }

    setIsSubmitting(true);
    setError("");

    try {
      // Format dates and SSNs for API
      const formattedData = {
        ...data,
        officer: {
          ...data.officer,
          birthDate: data.officer.birthDate.replace(/-/g, ""),
          socialSecurity: data.officer.socialSecurity.replace(/-/g, ""),
        },
        owners: data.owners.map((owner) => ({
          ...owner,
          birthDate: owner.birthDate.replace(/-/g, ""),
          socialSecurity: owner.socialSecurity.replace(/-/g, ""),
        })),
      };

      const siteKey = "123123";
      const { success, message } =
        await DbWrite.onboarding.createMerchantApplication(
          siteKey,
          formattedData,
        );
      onComplete(success, message);
    } catch (err) {
      onComplete(
        false,
        "An error occurred while submitting the application. Please try again.",
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-2xl font-bold text-gray-900">
          Review Your Information
        </h2>
        <p className="mt-1 text-sm text-gray-500">
          Please review all information carefully before submitting your
          application.
        </p>
      </div>

      <div className="space-y-6">
        {/* Business Information Section */}
        <div className="rounded-lg border border-gray-200 p-6">
          <h3 className="text-lg font-medium text-gray-900">
            Business Information
          </h3>
          <dl className="mt-4 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
            <div>
              <dt className="text-sm font-medium text-gray-500">Legal Name</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data.business.legalName}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">DBA Name</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data.business.businessName || "N/A"}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Tax ID</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data.business.taxId}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">
                Business Type
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data.business.ownershipStructureType}
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">
                Business Address
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {formatAddress(data.business)}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Phone</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {formatPhoneNumber(data.business.descriptorPhone)}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Email</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data.business.email}
              </dd>
            </div>
          </dl>
        </div>

        {/* Processing Volume Section */}
        <div className="rounded-lg border border-gray-200 p-6">
          <h3 className="text-lg font-medium text-gray-900">
            Processing Volume
          </h3>
          <dl className="mt-4 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
            <div>
              <dt className="text-sm font-medium text-gray-500">
                Average Card Transaction
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {currencyFormatter(
                  data.estimates.avgCardsTransactionAmount,
                  "USD",
                )}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">
                Annual Cards Volume
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {currencyFormatter(data.estimates.annualCardsVolume, "USD")}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">
                Average Direct Debit
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {currencyFormatter(
                  data.estimates.avgDirectDebitTransactionAmount,
                  "USD",
                )}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">
                Annual Direct Debit Volume
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {currencyFormatter(
                  data.estimates.annualDirectDebitVolume,
                  "USD",
                )}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">
                Maximum Transaction
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {currencyFormatter(data.estimates.maxTransactionAmount, "USD")}
              </dd>
            </div>
          </dl>
        </div>

        {/* Owner Information Section */}
        <div className="rounded-lg border border-gray-200 p-6">
          <h3 className="text-lg font-medium text-gray-900">
            Owner Information
          </h3>
          <dl className="mt-4 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
            <div>
              <dt className="text-sm font-medium text-gray-500">Name</dt>
              <dd className="mt-1 text-sm text-gray-900">{`${data.officer.firstName} ${data.officer.lastName}`}</dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">Title</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data.officer.title}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">SSN</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {formatSSN(data.officer.socialSecurity)}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">
                Date of Birth
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data.officer.birthDate}
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">
                Home Address
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {formatAddress(data.officer)}
              </dd>
            </div>
          </dl>
        </div>

        {/* Banking Information Section */}
        <div className="rounded-lg border border-gray-200 p-6">
          <h3 className="text-lg font-medium text-gray-900">
            Banking Information
          </h3>
          <dl className="mt-4 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
            <div>
              <dt className="text-sm font-medium text-gray-500">Bank Name</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data.deposit.bankName}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">
                Account Type
              </dt>
              <dd className="mt-1 text-sm capitalize text-gray-900">
                {data.deposit.accountType === "C" ? "Checking" : "Savings"}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">
                Account Holder
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data.deposit.holderName}
              </dd>
            </div>
            <div>
              <dt className="text-sm font-medium text-gray-500">
                Account Number
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {"•".repeat(data.deposit.accountNumber.length - 4) +
                  data.deposit.accountNumber.slice(-4)}
              </dd>
            </div>
          </dl>
        </div>

        {/* Owners Information Section */}
        <div className="rounded-lg border border-gray-200 p-6">
          <h3 className="text-lg font-medium text-gray-900">Business Owners</h3>
          <div className="mt-4 space-y-6">
            {data.owners.map((owner, index) => (
              <div
                key={index}
                className="border-t pt-4 first:border-t-0 first:pt-0"
              >
                <h4 className="mb-4 font-medium text-gray-900">
                  Owner {index + 1}
                </h4>
                <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                  <div>
                    <dt className="text-sm font-medium text-gray-500">Name</dt>
                    <dd className="mt-1 text-sm text-gray-900">{`${owner.firstName} ${owner.lastName}`}</dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium text-gray-500">Title</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {owner.title}
                    </dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium text-gray-500">
                      Ownership
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">{`${owner.stakePercentage}%`}</dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium text-gray-500">SSN</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {formatSSN(owner.socialSecurity)}
                    </dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium text-gray-500">
                      Date of Birth
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {owner.birthDate}
                    </dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium text-gray-500">Email</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {owner.email}
                    </dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium text-gray-500">Phone</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {formatPhoneNumber(owner.phone)}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Home Address
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {formatAddress(owner)}
                    </dd>
                  </div>
                  {owner.driverLicense && (
                    <>
                      <div>
                        <dt className="text-sm font-medium text-gray-500">
                          Driver's License
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {owner.driverLicense}
                        </dd>
                      </div>
                      <div>
                        <dt className="text-sm font-medium text-gray-500">
                          License State
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {owner.driverLicenseState}
                        </dd>
                      </div>
                    </>
                  )}
                  <div>
                    <dt className="text-sm font-medium text-gray-500">
                      Country
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {owner.countryCode}
                    </dd>
                  </div>
                </dl>
              </div>
            ))}
          </div>
        </div>

        {/* Terms and Conditions */}
        <div className="rounded-lg border border-gray-200 p-6">
          <div className="flex items-start">
            <div className="flex h-5 items-center">
              <input
                id="terms"
                type="checkbox"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
                className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
              />
            </div>
            <div className="ml-3">
              <label htmlFor="terms" className="text-sm text-gray-700">
                I agree to the{" "}
                <a href="#" className="hover:text-primary-dark text-primary">
                  terms and conditions
                </a>{" "}
                and confirm that all provided information is accurate.
              </label>
            </div>
          </div>
        </div>

        {error && (
          <StyledMessage type="error">{{ message: error }}</StyledMessage>
        )}

        <div className="flex justify-between pt-6">
          <BaseButtonSecondary
            type="button"
            onClick={() => window.history.back()}
            disabled={isSubmitting}
          >
            Back
          </BaseButtonSecondary>

          <BaseButtonPrimary
            type="button"
            onClick={handleSubmit}
            disabled={isSubmitting}
            isBusy={isSubmitting}
            busyText="Submitting..."
          >
            Submit Application
          </BaseButtonPrimary>
        </div>
      </div>
    </div>
  );
}
