import { z } from "zod";
import { guardIsPlainObject } from "../utils";

export const TwilioManager = {
  validateTwilioSmsRegistrationRequest,
};

export interface TwilioIncomingCallParams {
  AccountSid?: string;
  ApiVersion?: string;
  CallSid?: string;
  CallStatus?: string;
  CallToken?: string;
  Called?: string;
  CalledCity?: string;
  CalledCountry?: string;
  CalledState?: string;
  CalledZip?: string;
  Caller?: string;
  CallerCity?: string;
  CallerCountry?: string;
  CallerState?: string;
  CallerZip?: string;
  Direction?: string;
  From?: string;
  FromCity?: string;
  FromCountry?: string;
  FromState?: string;
  FromZip?: string;
  StirVerstat?: string;
  To?: string;
  ToCity?: string;
  ToCountry?: string;
  ToState?: string;
  ToZip?: string;
}

export interface AvailablePhoneNumber {
  phoneNumber: string;
  locality: string;
  region: string;
}

// SMS Capabilities
export interface TwilioSmsSettings {
  smsEnabled: boolean;
  phoneNumberId?: string; // ID of the phone number used for SMS
  a2pBrandId?: string; // ID of the registered A2P brand
  a2pCampaignId?: string; // ID of the registered A2P campaign
}

// A2P 10DLC Brand Registration Types
// 'Sole Proprietorship', 'Partnership', 'Limited Liability Corporation', 'Co-operative', 'Non-profit Corporation', 'Corporation'
export enum BusinessType {
  SOLE_PROPRIETORSHIP = "Sole Proprietorship",
  PARTNERSHIP = "Partnership",
  LIMITED_LIABILITY_COMPANY = "Limited Liability Corporation",
  CO_OPERATIVE = "Co-operative",
  NON_PROFIT_CORPORATION = "Non-profit Corporation",
  CORPORATION = "Corporation",
}

export enum JobPosition {
  DIRECTOR = "DIRECTOR",
  GM = "GM",
  VP = "VP",
  CEO = "CEO",
  CFO = "CFO",
  GENERAL_COUNSEL = "GENERAL_COUNSEL",
  OTHER = "OTHER",
}

// Business Registration Identifier
// Accepted Values:

// EIN US: Employer Identification Number (EIN)
// DUNS US: DUNS Number (Dun & Bradstreet)
// CCN Canada: Canadian Corporation Number
// NOTE: to register for A2P 10DLC, you must select CBN because CCN is no longer accepted.
// CBN Canada: Canadian Business Number
// CN Great Britain: Company Number
// ACN Australia: Company Number from ASIC (ACN)
// CIN India: Corporate Identity Number
// VAT Estonia: VAT Number
// VATRN Romania: VAT Registration Number
// RN Israel: Registration Number
// Other Other

export enum BusinessRegistrationIdentifier {
  EIN = "EIN",
  CBN = "CBN",
}

export interface A2PRegistrationStatus {
  brandId?: string;
  brandStatus?: string;
  campaignId?: string;
  campaignStatus?: string;
  errors?: string[];
}

export interface TwilioIncomingSmsParams {
  MessageSid?: string;
  SmsSid?: string;
  AccountSid?: string;
  MessagingServiceSid?: string;
  From?: string;
  To?: string;
  Body?: string;
  NumMedia?: string;
  NumSegments?: string;
  SmsStatus?: string;
  FromCity?: string;
  FromState?: string;
  FromZip?: string;
  FromCountry?: string;
  ToCity?: string;
  ToState?: string;
  ToZip?: string;
  ToCountry?: string;
}

export enum CompanyType {
  GOVERNMENT = "government",
  NON_PROFIT = "non-profit",
  PRIVATE = "private",
  PUBLIC = "public",
}

/**
 * Unified request interface for Twilio SMS Registration operations
 * Combines all required fields to create a Customer Profile, Trust Product, Brand, and Messaging Service
 */
export interface TwilioSmsRegistrationRequest {
  // Common fields used across multiple operations
  business_name: string;
  website_url: string;
  email: string;

  // Contact information
  first_name: string;
  last_name: string;
  phone_number: string;

  // Address information
  street: string;
  street_secondary?: string;
  city: string;
  region: string;
  postal_code: string;
  iso_country: string;

  // Business information
  business_type: BusinessType;
  company_type: CompanyType;
  business_registration_number: string;
  business_registration_identifier: BusinessRegistrationIdentifier;

  // Authorized representative information
  business_title: string;
  job_position: JobPosition;
}

/**
 * Unified response interface for Twilio SMS Registration operations
 */
export interface TwilioSmsRegistrationResponse {
  // Status information
  success: boolean;
  message: string;
  request_id?: string;

  // Customer Profile information
  customer_profile_sid?: string;
  customer_profile_status?: string;

  // Trust Product information
  trust_product_sid?: string;
  trust_product_status?: string;

  // Brand information
  brand_id?: string;
  brand_status?: string;

  // Messaging Service information
  messaging_service_id?: string;
  messaging_service_status?: string;

  // Evaluation information
  evaluation_results?: {
    passed: boolean;
    issues?: Array<{
      step: string;
      message: string;
    }>;
  };
}

function validateTwilioSmsRegistrationRequest(
  value: unknown,
): TwilioSmsRegistrationRequest {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = twilioSmsRegistrationRequestSchema.parse(value);
  return result;
}

// Zod schema for Twilio SMS Registration Request
const twilioSmsRegistrationRequestSchema = z.object({
  business_name: z.string(),
  website_url: z.string(),
  email: z.string(),
  first_name: z.string(),
  last_name: z.string(),
  phone_number: z
    .string()
    .regex(/^\+\d+$/, "Phone number must be in format +XXXXXXXXXXX"),
  street: z.string(),
  street_secondary: z.string().optional(),
  city: z.string(),
  region: z.string(),
  postal_code: z.string(),
  iso_country: z.string(),
  business_type: z.nativeEnum(BusinessType),
  business_registration_number: z.string(),
  business_registration_identifier: z.nativeEnum(
    BusinessRegistrationIdentifier,
  ),
  business_title: z.string(),
  job_position: z.nativeEnum(JobPosition),
  company_type: z.nativeEnum(CompanyType),
});
