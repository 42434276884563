// libs
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Controller, useForm, useWatch } from "react-hook-form";
import DatePicker from "react-datepicker";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { User } from "firebase/auth";
import { DateTime } from "luxon";
import { parseISO, isValid } from "date-fns";
import {
  TimeField,
  Label,
  DateInput,
  DateSegment,
  TimeValue,
} from "react-aria-components";
import AccessTime from "@mui/icons-material/AccessTime";
import { Time } from "@internationalized/date";

// local
import { DbRead } from "../../database";
import {
  CustomizationMap,
  ReportSpec,
  ReportConfig,
  frequencyOptions,
  isFrequencyOption,
  isTimeToGenerateOption,
} from "../../models/reports";
import ReportsPageHeader from "../../components/reports/ReportsPageHeader";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import BaseInputText from "../../components/BaseInputText";
import BaseInputSelect from "../../components/BaseInputSelect";
import StyledSwitchGroup from "../../components/StyledSwitchGroup";
import * as strings from "../../strings";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import { DbWrite } from "../../database";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { useAuthStore } from "../../store/firebase-auth";
import { useToastMessageStore } from "../../store/toast-messages";
import {
  createToastMessageID,
  guardIsPlainObject,
  isValidISO8601,
} from "../../utils";
import { REPORTS_URL } from "../../urls";
import { CustomDateInput } from "../../components/DatePickerWithManualEntry";
import { useUserPermissionsStore } from "../../store/user-permissions";
import MultipleUidDialog, {
  IMultipleUid_AssignUser,
} from "../../components/CustomFields/MultipleUidDialog";
import { useSiteKeyUsersStore } from "../../store/site-key-users";
import { useUserDisplayNamesStore } from "../../store/user-display-names-map";
import { logger } from "../../logging";
import NotFound404 from "../NotFound404";

const ReportConfigFormSchema = z.object({
  subscribed: z.boolean(),
  frequency: z
    .enum(frequencyOptions)
    .optional()
    .transform((val) => val?.toLowerCase()),
  timeToGenerate: z.union([z.number(), z.instanceof(Time)]).optional(),
  specCustomizations: z
    .record(z.union([z.string(), z.number(), z.boolean(), z.date()]).optional())
    .optional(),
  configName: z.string().min(1, "Report title is required."),
});

type CustomizationDefaultValue = string | number | boolean | Date | undefined;

type CustomizationDefaultValues = {
  [index: string]: CustomizationDefaultValue;
};

/** FIXME:
 * -- 1 edit & save report. 2 refresh page. 3 see old data...
 * -- timeToGenerate field is changing from uncontrolled to controlled
 *
 * -- ReportsGeneratedItem and ReportsSavedItem need sorted by date
 */

// type CustomizationItemFormType = Omit<CustomizationItem, "default"> & {
//   default?: string | number | boolean | Date;
// };
// type CustomizationMapFormType = { [index: string]: CustomizationItemFormType };

type ReportConfigFormState = z.infer<typeof ReportConfigFormSchema>;

const actionOptions = ["download", "email", "save", "delete"] as const;
type ActionOptions = (typeof actionOptions)[number];

export default function ReportSpecPage() {
  const navigate = useNavigate();
  const { reportType } = useParams();
  const locationState = useLocation().state;
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const siteKeyUserPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const [siteKeyDoc, siteKeyDocLoading] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);

  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );
  const siteKeyUsersList = useSiteKeyUsersStore(
    (state) => state.siteKeyUsersList,
  );

  const userDisplayNames = useUserDisplayNamesStore(
    (state) => state.userDisplayNames,
  );

  const userList = useRef<IMultipleUid_AssignUser[]>([]);

  const [formDefVals, setFormDefVals] = useState<ReportConfigFormState | null>(
    null,
  );
  const [currentReportSpec, setCurrentReportSpec] = useState<ReportSpec | null>(
    null,
  );
  const savedReportConfig: ReportConfig | null =
    locationState?.reportConfig ?? null;
  const [isBusy, setIsBusy] = useState<ActionOptions | null>(null);
  const [subscribedUserList, setSubscribedUserList] = useState<string[]>([]);
  const [multipleUidDialogOpen, setMultipleUidDialogOpen] = useState(false);
  const [selectedUserIDs, setSelectedUserIDs] = useState<string[]>([]);

  const userIsSiteAdmin = siteKeyUserPermissions?.permissions.isSiteAdmin;

  useEffect(() => {
    async function getReportSpec() {
      if (!siteKeyDoc || !reportType) return;
      const typesWithSpec = await DbRead.reports.getTypesWithSpec(
        siteKeyDoc.id,
      );
      setCurrentReportSpec(typesWithSpec[reportType]);
    }
    getReportSpec();
  }, [reportType, siteKeyDoc]);

  useEffect(() => {
    async function fetchReportUserList() {
      if (!siteKeyDoc || !reportType) return undefined;

      // Subscribe to user report configs
      const unsubscribeFromReportConfigs =
        DbRead.reports.subscribeUserListReportConfigs({
          siteKey: siteKeyDoc.id,
          reportType,
          siteKeyUserPermissions,
          onChange: setSubscribedUserList,
        });
      return unsubscribeFromReportConfigs;
    }
    const promise = fetchReportUserList();

    return () => {
      promise.then((unsubscribe) => {
        if (unsubscribe) unsubscribe();
      });
    };
  }, [reportType, siteKeyDoc, siteKeyUserPermissions]);

  useEffect(() => {
    async function getUserList() {
      userList.current = siteKeyUsersList
        // we don't want system users to be selectable in the multiple-uid dialog
        .filter((user) => user.systemUser !== true)
        .map((user) => ({
          uid: user.id,
          name: user.displayName,
          company: user.companyName,
          avatarUrl: user.userPhoto_URL,
          isAssigned: false,
          subscribed: subscribedUserList.includes(user.id),
          assignedVehicle: null,
        }));
    }

    getUserList();
  }, [siteKeyUsersList, subscribedUserList]);

  let savedConfigTimestampLastMod = "";
  if (savedReportConfig && savedReportConfig.timestampLastModified) {
    const dateTime = DateTime.fromISO(savedReportConfig.timestampLastModified);
    savedConfigTimestampLastMod = dateTime.toFormat("dd LLL yyyy, HH:mm");
  }

  useEffect(() => {
    if (!currentReportSpec) return;
    const vals = getDefaultValues(currentReportSpec, savedReportConfig);
    setFormDefVals(vals);
  }, [currentReportSpec, savedReportConfig]);

  const {
    control,
    formState: { isSubmitting },
    watch,
    reset,
    handleSubmit,
    setValue,
  } = useForm<ReportConfigFormState>({
    defaultValues: formDefVals ?? getInitialDefVals(),
    resolver: zodResolver(ReportConfigFormSchema),
    mode: "onChange",
  });

  // Re-populate the form fields when we get the default values. (The "initial
  // default values" will never be displayed on the UI because there's an early
  // return when formDefVals is null.)
  useEffect(() => {
    if (!formDefVals) return;
    reset(formDefVals);
  }, [formDefVals, reset]);

  const watchSubscribed = useWatch({ control: control, name: "subscribed" });

  // #region Handle form submission
  // SECTION:
  async function handleSave(
    siteKey: string,
    reportConfig: ReportConfig,
    isNewSubscription: boolean,
  ): Promise<void> {
    const shouldSaveMultipleConfigs =
      isNewSubscription === true &&
      userIsSiteAdmin === true &&
      selectedUserIDs.length > 0;

    if (shouldSaveMultipleConfigs) {
      const promises = selectedUserIDs.map((userID) => {
        const updatedReportConfig: ReportConfig = {
          ...reportConfig,
          user: userID,
        };
        console.log("updatedReportConfig", updatedReportConfig); // REMOVE:
        return DbWrite.reports.saveConfig(updatedReportConfig, siteKey);
      });
      await Promise.all(promises);
      setSelectedUserIDs([]);

      return;
    }

    await DbWrite.reports.saveConfig(reportConfig, siteKey);
  }

  function buildReportConfig(
    formValues: ReportConfigFormState,
  ): { reportConfig: ReportConfig; isNewSubscription: boolean } | null {
    if (!siteKeyDoc || !reportType) return null;

    const { specCustomizations } = formValues;
    let customizations: ReportConfig["configCustomizations"] | null = null;
    if (
      guardIsPlainObject(specCustomizations) &&
      Object.keys(specCustomizations).length > 0
    ) {
      customizations = { ...specCustomizations };
      for (const [key, val] of Object.entries(customizations)) {
        if (val instanceof Date) customizations[key] = val.toISOString();
      }
    }

    const reportConfig: ReportConfig = {
      type: reportType,
      user: firebaseUser.uid,
      configName: formValues.configName,
      subscribed: formValues.subscribed ?? false,
      timestampLastModified: DateTime.utc().toISO(),
    };
    if (customizations) {
      reportConfig.configCustomizations = customizations;
    }
    const frequency = formValues.frequency?.toLowerCase();
    if (isFrequencyOption(frequency)) {
      reportConfig.frequency = frequency;
    }
    if (
      typeof formValues.timeToGenerate === "number" &&
      isTimeToGenerateOption(formValues.timeToGenerate)
    ) {
      reportConfig.timeToGenerate = formValues.timeToGenerate;
    }
    if (savedReportConfig) {
      reportConfig.id = savedReportConfig.id;
    }
    // if (formValues.whiteLabel) {
    //   reportConfig.whiteLabel = formValues.whiteLabel;
    // }

    return { reportConfig, isNewSubscription: formValues.subscribed };
  }

  async function handleClickDownload(
    formValues: ReportConfigFormState,
  ): Promise<void> {
    if (!siteKeyDoc || !reportType) return;

    const result = buildReportConfig(formValues);
    if (!result) return;
    const { isNewSubscription, reportConfig } = result;

    setIsBusy("download");
    if (isNewSubscription) {
      try {
        // Save the config to enable the subscription
        await handleSave(siteKeyDoc.id, reportConfig, isNewSubscription);
      } catch (e) {
        logger.error(e);
        addToastMessage({
          id: createToastMessageID(),
          message: "Failed to save the report. Please try again later.",
          dialog: false,
          type: "error",
        });

        setIsBusy(null);
        return;
      }
      addToastMessage({
        id: createToastMessageID(),
        message: "Report config saved. Downloading report...",
        dialog: false,
        type: "success",
      });
    }

    try {
      const reportData = await DbWrite.reports.generateReportData({
        siteKey: siteKeyDoc.id,
        configuration: reportConfig,
      });

      if (!reportData.downloadURL) {
        addToastMessage({
          id: createToastMessageID(),
          message: "Unable to download the report. Please try again later.",
          dialog: false,
          type: "error",
        });
        setIsBusy(null);
        return;
      }

      // reset(formDefaultValues);
      window.location.href = reportData.downloadURL;
      addToastMessage({
        id: createToastMessageID(),
        message: "Download complete.",
        dialog: false,
        type: "success",
      });

      setIsBusy(null);
    } catch (e) {
      logger.error(e);
      setIsBusy(null);

      addToastMessage({
        id: createToastMessageID(),
        message: "Failed to generate the report.",
        dialog: false,
        type: "error",
      });
    }
  }

  async function handleClickEmail(
    formValues: ReportConfigFormState,
  ): Promise<void> {
    if (!siteKeyDoc || !reportType) return;

    const result = buildReportConfig(formValues);
    if (!result) return;
    const { isNewSubscription, reportConfig } = result;

    setIsBusy("email");
    if (isNewSubscription) {
      try {
        // Save the config to enable the subscription
        await handleSave(siteKeyDoc.id, reportConfig, isNewSubscription);
      } catch (e) {
        logger.error(e);
        addToastMessage({
          id: createToastMessageID(),
          message: "Failed to save the report. Please try again later.",
          dialog: false,
          type: "error",
        });

        setIsBusy(null);
        return;
      }
      addToastMessage({
        id: createToastMessageID(),
        message: "Report config saved. Emailing report...",
        dialog: false,
        type: "success",
      });
    }

    try {
      const reportData = await DbWrite.reports.generateReportData({
        siteKey: siteKeyDoc.id,
        configuration: reportConfig,
      });

      if (!reportData.id) {
        addToastMessage({
          id: createToastMessageID(),
          message: "Failed to generate the report.",
          dialog: false,
          type: "error",
        });
        setIsBusy(null);
        return;
      }

      await DbWrite.reports.sendEmail(siteKeyDoc.id, reportData.id);
      addToastMessage({
        id: createToastMessageID(),
        message: "Email sent.",
        dialog: false,
        type: "success",
      });
      // reset(formDefaultValues);
      setIsBusy(null);
    } catch (e) {
      logger.error(e);
      setIsBusy(null);

      addToastMessage({
        id: createToastMessageID(),
        message: "Failed to email the report. Please try again later.",
        type: "error",
        dialog: false,
      });
    }
  }

  async function handleClickSave(
    formValues: ReportConfigFormState,
  ): Promise<void> {
    if (!siteKeyDoc || !reportType) return;

    const result = buildReportConfig(formValues);
    if (!result) return;
    const { isNewSubscription, reportConfig } = result;

    setIsBusy("save");
    try {
      await handleSave(siteKeyDoc.id, reportConfig, isNewSubscription);

      setIsBusy(null);
      addToastMessage({
        id: createToastMessageID(),
        message: "Report config saved.",
        dialog: false,
        type: "success",
      });
    } catch (e) {
      logger.error(e);
      setIsBusy(null);

      addToastMessage({
        id: createToastMessageID(),
        message: "Failed to save the report. Please try again later.",
        dialog: false,
        type: "error",
      });
    }
  }

  async function handleClickDelete(
    _formValues: ReportConfigFormState,
  ): Promise<void> {
    if (!siteKeyDoc || !reportType) return;
    if (!savedReportConfig || !savedReportConfig.id) return;
    setIsBusy("delete");

    try {
      await DbWrite.reports.deleteConfig(siteKeyDoc.id, savedReportConfig.id);
      setIsBusy(null);
      navigate(REPORTS_URL);
      addToastMessage({
        id: createToastMessageID(),
        message: "Deleted report configuration.",
        dialog: false,
        type: "success",
      });
    } catch (e) {
      logger.error(e);
      setIsBusy(null);

      addToastMessage({
        id: createToastMessageID(),
        message:
          "Failed to delete the report configuration. Please try again later.",
        dialog: false,
        type: "error",
      });
    }
  }
  // #endregion Handle form submission

  const multipleUidDialog = userIsSiteAdmin === true && (
    <MultipleUidDialog
      open={multipleUidDialogOpen}
      closeDialog={() => setMultipleUidDialogOpen(false)}
      userList={userList.current}
      handleSubmit={(event) => {
        const selectedUids = event
          .filter((user) => user.isAssigned)
          .map((user) => user.uid);
        setSelectedUserIDs(selectedUids);
      }}
    />
  );

  if (siteKeyDocLoading || !currentReportSpec || !formDefVals) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }
  if (!siteKeyDoc && !siteKeyDocLoading) return <NotFound404 />;

  const reportTitle = watch("configName");
  const isReportTitleEmpty = reportTitle?.trim() === "";
  const selectedUserNames = selectedUserIDs.map(
    (userID) => userDisplayNames[userID],
  );

  return (
    <div className="flex h-full w-full flex-col items-center">
      <div className="w-full sm:w-[800px]">
        <ReportsPageHeader
          title={savedReportConfig ? "Edit Report" : "New Report"}
          backButtonHref="/reports"
        />

        {/* Report Cards */}
        <div className="mb-6 mt-12 flex flex-col rounded-md border border-gray-300">
          <div className="rounded-t-md border-b border-gray-300 bg-gray-50 px-6 py-5">
            <h3 className="text-xl font-semibold">
              {savedReportConfig ? "Edit Report" : "Create New Report"}
            </h3>
            <p className="text-sm text-gray-400">
              {savedReportConfig
                ? `${currentReportSpec.reportName} | ${savedConfigTimestampLastMod}`
                : currentReportSpec.reportName}
            </p>
          </div>
          {/* Report Config Form */}
          <form
            autoComplete="off"
            className="flex flex-col space-y-8 p-8 text-lg"
          >
            <Controller
              name="configName"
              control={control}
              rules={{ required: "Report Title is required." }}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <BaseInputText
                    text="Report Title"
                    inputName="configName"
                    admin={true}
                    required={true}
                    {...field}
                  />
                  {error && <p className="text-red-600">{error.message}</p>}
                </div>
              )}
            />

            {/* formDefVals.specCustomizations -- contains value to display */}
            {formDefVals.specCustomizations &&
              Object.entries(formDefVals.specCustomizations).map(([key]) => {
                /** original definitions -- `.default` contains non-mutated value */
                const customization = currentReportSpec.customizations[key];

                if (customization.type === "timestamp") {
                  return (
                    <div key={key} className="gap-4">
                      <div className="flex w-full items-center justify-start gap-4">
                        <div className="mr-10">
                          <label className="whitespace-nowrap">
                            {customization.name}
                          </label>
                        </div>
                        <Controller
                          key={key}
                          name={`specCustomizations.${key}`}
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <DatePicker
                                selected={
                                  field.value instanceof Date
                                    ? field.value
                                    : new Date()
                                }
                                onChange={(date) => field.onChange(date)}
                                customInput={<CustomDateInput />}
                                // showTimeSelect
                              />
                              {error && (
                                <p className="text-red-600">{error.message}</p>
                              )}
                            </>
                          )}
                        />
                      </div>
                    </div>
                  );
                } else if (customization.type === "selection") {
                  return (
                    <div key={key} className="grid grid-cols-2 gap-4">
                      <div className="flex items-center justify-between gap-4">
                        <label className="whitespace-nowrap">
                          {`${customization.name}:`}
                        </label>
                        <Controller
                          key={key}
                          name={`specCustomizations.${key}`}
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <>
                              <BaseInputSelect
                                inputName={field.name}
                                admin={true}
                                required={true}
                                className="min-w-[240px]"
                                {...field}
                                value={
                                  typeof field.value === "string"
                                    ? field.value
                                    : undefined
                                }
                              >
                                {customization.selectionOptions?.map(
                                  (optionValue) => (
                                    <option
                                      key={optionValue}
                                      value={optionValue}
                                    >
                                      {customization.selectionOptionsRenamingMap
                                        ? customization
                                            .selectionOptionsRenamingMap[
                                            optionValue
                                          ]
                                        : optionValue}
                                    </option>
                                  ),
                                )}
                              </BaseInputSelect>
                              {error && (
                                <p className="text-red-600">{error.message}</p>
                              )}
                            </>
                          )}
                        />
                      </div>
                    </div>
                  );
                } else if (customization.type === "boolean") {
                  return (
                    <div key={key} className="flex items-center gap-4">
                      <label className="whitespace-nowrap">
                        {`${customization.name}:`}
                      </label>
                      <Controller
                        key={key}
                        name={`specCustomizations.${key}`}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <>
                            <StyledSwitchGroup
                              readableName=""
                              onBlur={field.onBlur}
                              onChange={field.onChange}
                              ref={field.ref}
                              checked={
                                typeof field.value === "boolean"
                                  ? field.value
                                  : false
                              }
                              id={`specCustomizations.${key}`}
                              name={field.name}
                            />
                            {error && (
                              <p className="text-red-600">{error.message}</p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  );
                }
                // TODO handle other types ...
                return null;
              })}

            <hr className="my-4 block w-full border border-gray-200" />
            <span className="items-center">
              Subscribe to automated emails for this report
            </span>
            <div className="flex items-center gap-4 py-1">
              <Controller
                name="subscribed"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <StyledSwitchGroup
                      readableName="Subscribe"
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      ref={field.ref}
                      checked={field.value ?? false}
                      id="subscribed"
                      name={field.name}
                    />
                    {error && <p className="text-red-600">{error.message}</p>}
                  </>
                )}
              />
            </div>
            {userIsSiteAdmin === true && watchSubscribed === true && (
              <BaseButtonSecondary
                type="button"
                onClick={() => setMultipleUidDialogOpen(true)}
                className="w-fit justify-center uppercase tracking-wide"
                data-testid="download-report-button"
              >
                {"Select users to subscribe to this report"}
              </BaseButtonSecondary>
            )}

            {selectedUserIDs.length > 0 && watchSubscribed === true && (
              <div className="font-light text-gray-500">
                User selected for subscription:{" "}
                <span className="font-bold text-primary">
                  {selectedUserNames.join(", ")}
                </span>
              </div>
            )}

            {watchSubscribed === true && (
              <div>
                <Controller
                  name="frequency"
                  control={control}
                  render={({
                    field: { onChange, value, name, ref },
                    fieldState: { error },
                  }) => (
                    <>
                      <BaseInputSelect
                        inputName={name}
                        text="Frequency"
                        admin={true}
                        required={true}
                        className="capitalize"
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                        onBlur={() => {
                          setValue(name, value, {
                            shouldValidate: true,
                          });
                        }}
                        ref={ref}
                      >
                        <option value="" disabled>
                          Frequency
                        </option>
                        {frequencyOptions.map((type) => (
                          <option
                            className="capitalize"
                            key={type}
                            value={type}
                          >
                            {type}
                          </option>
                        ))}
                      </BaseInputSelect>
                      {error && <p className="text-red-600">{error.message}</p>}
                    </>
                  )}
                />
              </div>
            )}

            {/* FIXME: "WARN: A component changed from uncontrolled to controlled" */}
            {watchSubscribed === true && (
              <Controller
                name="timeToGenerate"
                key="timeToGenerate"
                control={control}
                render={({ field: { name, value, ref, onChange } }) => (
                  <TimeField
                    granularity="hour"
                    ref={ref}
                    name={name}
                    id={name}
                    value={typeof value === "number" ? new Time(value) : value}
                    onChange={(timeVal: Time | null) =>
                      onChange(timeVal?.hour ?? undefined)
                    }
                  >
                    <Label>{strings.TIME_TO_GENERATE}</Label>
                    <div className="mt-1 flex items-center gap-2">
                      <AccessTime className="text-gray-500" />
                      <DateInput className="flex rounded border border-gray-400 p-1 transition hover:border-gray-500">
                        {(segment) => (
                          <DateSegment
                            className="mx-0.5 rounded-sm px-1 transition hover:bg-gray-50 focus:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-600"
                            segment={segment}
                          />
                        )}
                      </DateInput>
                    </div>
                  </TimeField>
                )}
              />
            )}

            <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
              <BaseButtonPrimary
                type="button"
                onClick={() => handleSubmit(handleClickDownload)()}
                disabled={isSubmitting || isReportTitleEmpty}
                isBusy={isBusy === "download"}
                busyText={strings.buttons.BUSY_GENERATING}
                className="w-full justify-center uppercase"
                data-testid="download-report-button"
              >
                {strings.buttons.DOWNLOAD}
              </BaseButtonPrimary>
              <BaseButtonPrimary
                type="button"
                onClick={() => handleSubmit(handleClickEmail)()}
                disabled={isSubmitting || isReportTitleEmpty}
                isBusy={isBusy === "email"}
                busyText={strings.buttons.BUSY_SENDING}
                className="w-full justify-center uppercase"
                data-testid="email-report-button"
              >
                {strings.buttons.SEND_AS_EMAIL}
              </BaseButtonPrimary>
              <BaseButtonSecondary
                type="button"
                onClick={() => handleSubmit(handleClickSave)()}
                disabled={isSubmitting || isReportTitleEmpty}
                isBusy={isBusy === "save"}
                busyText={strings.buttons.BUSY_SAVING}
                className="w-full justify-center uppercase"
                data-testid="save-report-button"
              >
                {strings.buttons.SAVE}
              </BaseButtonSecondary>
              {savedReportConfig && (
                <BaseButtonSecondary
                  type="button"
                  onClick={() => handleSubmit(handleClickDelete)()}
                  disabled={isSubmitting || isReportTitleEmpty}
                  isBusy={isBusy === "delete"}
                  busyText={strings.buttons.BUSY_DELETING}
                  className="w-full justify-center uppercase"
                  data-testid="delete-report-button"
                >
                  {strings.buttons.DELETE}
                </BaseButtonSecondary>
              )}
            </div>
          </form>
        </div>
      </div>
      {multipleUidDialog}
    </div>
  );
}

function getInitialDefVals(): ReportConfigFormState {
  return {
    configName: "",
    specCustomizations: {},
    subscribed: false,
    frequency: "daily",
    timeToGenerate: undefined,
  };
}

function getDefaultValues(
  reportSpec: ReportSpec,
  savedReportConfig: ReportConfig | null,
): ReportConfigFormState {
  if (savedReportConfig) {
    const originals = savedReportConfig.configCustomizations;
    const specDefVals: ReportConfigFormState["specCustomizations"] = {};

    if (originals && Object.keys(originals).length > 0) {
      for (const [key, val] of Object.entries(originals)) {
        if (
          typeof val === "string" ||
          typeof val === "number" ||
          typeof val === "boolean" ||
          val === undefined
        ) {
          // needed to narrow the type; didn't want to typecast
          specDefVals[key] = val;
        }
        if (key.includes("datetime") && typeof val === "string") {
          specDefVals[key] = parseISO(val);
        }
      }
    }

    return {
      configName: savedReportConfig.configName,
      specCustomizations: specDefVals,
      subscribed: savedReportConfig.subscribed,
      frequency: savedReportConfig.frequency,
      timeToGenerate: savedReportConfig.timeToGenerate,
    };
  }

  const customizationDefVals = getCustomizationDefVals(
    reportSpec.customizations,
  );
  return {
    configName: reportSpec.reportName,
    specCustomizations: customizationDefVals,
    subscribed: false,
    frequency: "daily",
    // timeToGenerate: 7,
    timeToGenerate: undefined,
  };
}

/** Get default values for the ReportSpec's customizations. */
function getCustomizationDefVals(
  customizations: CustomizationMap,
): CustomizationDefaultValues {
  if (Object.keys(customizations).length === 0) return {};

  return Object.entries(customizations).reduce((acc, [key, val]) => {
    // prevent loss of customizations that we don't act on
    acc[key] = val.default;

    if (val.selectionOptions && val.selectionOptions.length > 0) {
      acc[key] = val.selectionOptions[0];
    }

    if (
      val.type === "timestamp" &&
      typeof val.default === "string" &&
      isValidISO8601(val.default)
    ) {
      const parsedDateSystemTime = new Date(val.default);
      const browserTimezoneOffset = parsedDateSystemTime.getTimezoneOffset();
      const parsedDate = new Date(
        parsedDateSystemTime.getTime() + browserTimezoneOffset * 60 * 1000,
      );
      acc[key] = isValid(parsedDate) ? parsedDate : new Date();
    } else if (val.type === "timestamp") {
      acc[key] = new Date();
    }

    return acc;
  }, {} as CustomizationDefaultValues);
}
