//Libs
import axios from "axios";
import cloneDeep from "lodash/cloneDeep";
import { Timestamp } from "firebase/firestore";

//Local
import { ExistingTask } from "../../../models/task";
import {
  VroomManager,
  VroomOutput,
  VroomRoute,
  VroomUnassigned,
} from "../../../models/vroom";
import { convertToVroomVehicles } from "./convertToVroomVehicles";
import { convertTasksToVroomJobs } from "./convertTasksToVroomJobs";
import { ExistingVehicle } from "../../../models/vehicle";
import { logger } from "../../../logging";
import { ExistingSiteKeyLocation } from "../../../models/site-key-location";
import { ExistingStiltCalendarEvent } from "../../../models/stilt-calendar-event";
import { DateTime } from "luxon";
import { OTaskStatus } from "../../../models/task-status";
import { SmartSchedulingFormValues } from "../../../models/smart-scheduling-options";

// This sexy interface has the tasks already formatted, but also includes the VROOM data for easy reference by whoever is asked for VROOM optimization
export interface OptimizationStats {
  originalTotalDistanceMiles: number;
  optimizedTotalDistanceMeters?: number;
  unassignedCount: number;
  unassignedReasons: string[];
}

export interface OptimizedTasks {
  tasks: ExistingTask[];
  unassigned: VroomUnassigned[];
  vroomOutput: VroomOutput | null;
  stats: OptimizationStats;
  vroomRoutesMap: Record<string, VroomRoute>;
}

export const VROOM_ALLOCATION_CONFIG = {
  PORTABLE_SKILL: 69420, // Currently hardcoded
  DEFAULT_MAX_TASKS: 8, // Currently hardcoded
  VEHICLE_ID_SKILL_OFFSET: 4000, // Currently hardcoded
  API_ENDPOINT: "https://routing.stilt-web.com/vroom/",
};

export async function allocateResourcesToTasksVROOM(args: {
  day: DateTime;
  tasks: ExistingTask[];
  vehicles: ExistingVehicle[];
  stiltEvents: ExistingStiltCalendarEvent[];
  siteKeyWorkTypes: number[];
  siteKeyTaskTypes: number[];
  getLocationDoc: (locationID: string) => ExistingSiteKeyLocation;
  smartSchedulingSettings: SmartSchedulingFormValues;
  originalTotalDistanceMiles: number;
  optimizeTasksOfAllStatuses?: boolean;
  _attemptCount?: number;
}): Promise<OptimizedTasks> {
  // Initialize attempt counter if not provided
  const attemptCount = args._attemptCount || 1;

  if (args.tasks.length === 0)
    return {
      tasks: [],
      unassigned: [],
      vroomOutput: null,
      vroomRoutesMap: {},
      stats: {
        originalTotalDistanceMiles: args.originalTotalDistanceMiles,
        unassignedCount: 0,
        unassignedReasons: [],
      },
    };

  //Remove tasks with null timestampScheduled
  const filteredTasks = args.tasks.filter(
    (task) => task.timestampScheduled != null,
  );
  const tasksOptimized = cloneDeep(filteredTasks);

  // Pull out the tasks that are on vehicles excluded from
  const includedVehicles = args.vehicles.filter(
    (vehicle) => vehicle.omitFromOptimization !== true,
  );

  const includedTasks: ExistingTask[] = [];
  const excludedTasks: ExistingTask[] = [];
  filteredTasks.forEach((task) => {
    const initiallyAssignedVehicleID =
      task.taskSpecificDetails?.assignedVehicleID;

    // If no vehicleID assigned already, we'll include it in the optimization
    if (!initiallyAssignedVehicleID) {
      includedTasks.push(task);
      return;
    }
    const assignedVehicle = args.vehicles.find(
      (vehicle) => vehicle.id === initiallyAssignedVehicleID,
    );

    // If the vehicle wasn't found, we'll include it in the optimization
    if (!assignedVehicle) {
      includedTasks.push(task);
      return;
    }

    if (assignedVehicle?.omitFromOptimization === true) {
      excludedTasks.push(task);
    } else {
      includedTasks.push(task);
    }
  });

  // const maxPortables = 4
  const portableSkill = VROOM_ALLOCATION_CONFIG.PORTABLE_SKILL;
  const maxTasks = VROOM_ALLOCATION_CONFIG.DEFAULT_MAX_TASKS;

  // Create mapping of integers to vehicleIDs
  const vehicleIDsAsSkills: Record<string, number> = {};
  includedVehicles.forEach((vehicle, index) => {
    vehicleIDsAsSkills[vehicle.id] =
      index + VROOM_ALLOCATION_CONFIG.VEHICLE_ID_SKILL_OFFSET;
  });

  // Set lockAssignedVehicle to true for all tasks that aren't AWAITING or SCHEDULED taskStatus
  if (args.optimizeTasksOfAllStatuses !== true) {
    includedTasks.forEach((task) => {
      if (
        task.taskStatus !== OTaskStatus.AWAITING &&
        task.taskStatus !== OTaskStatus.SCHEDULED
      ) {
        task.taskSpecificDetails = {
          ...task.taskSpecificDetails,
          lockAssignedVehicle: true,
        };
      }
    });
  }

  const vehiclesToOptimize = includedVehicles.map((vehicle) => ({
    ...vehicle,
    // Use vehicle's maxTasksPerDay if it exists, otherwise use default
    maxTasks: vehicle.maxTasksPerDay ?? maxTasks,
  }));
  const vroomVehicles = convertToVroomVehicles({
    vehicles: vehiclesToOptimize,
    maxTasks: maxTasks, // Keep this for backward compatibility
    siteKeyWorkTypes: args.siteKeyWorkTypes,
    siteKeyTaskTypes: args.siteKeyTaskTypes,
    getLocationDoc: args.getLocationDoc,
    portableSkill: portableSkill,
    vehicleIDsAsSkills: vehicleIDsAsSkills,
    stiltEvents: args.stiltEvents,
    day: args.day,
  });
  const vroomJobs = convertTasksToVroomJobs({
    tasks: includedTasks,
    getLocationDoc: args.getLocationDoc,
    portableSkill: portableSkill,
    vehicleIDsAsSkills: vehicleIDsAsSkills,
    smartSchedulingSettings: args.smartSchedulingSettings,
  });

  const data = {
    jobs: vroomJobs,
    vehicles: vroomVehicles,
  };
  logger.info("vroom outgoing", data);

  let vroomResponse: VroomOutput;
  try {
    // Use config for API endpoint
    const response = await axios.post(
      VROOM_ALLOCATION_CONFIG.API_ENDPOINT,
      data,
    );
    vroomResponse = response.data;
    logger.info("vroom incoming", vroomResponse);
  } catch (error: any) {
    logger.error("VROOM API error:", error.response?.data || error);

    // Handle VROOM routing errors
    if (error.response?.data?.code === 3) {
      // Error code 3 indicates routing errors
      const errorMessage = error.response.data.error;
      const coordinates = errorMessage.match(/\[(.*?)\]/)?.[1];

      console.log("Removing tasks with coordinates:", coordinates);
      // Find tasks that caused routing errors and mark them as unassigned
      const problematicTasks = includedTasks.filter((task) => {
        const taskCoords = `${task.longitude};${task.latitude}`;
        return coordinates?.includes(taskCoords);
      });
      // Non-problematic tasks
      const nonProblematicTasks = includedTasks.filter((task) => {
        const taskCoords = `${task.longitude};${task.latitude}`;
        return !coordinates?.includes(taskCoords);
      });

      // Check if we've exceeded max attempts
      if (attemptCount >= 5) {
        logger.warn(
          "Max optimization attempts reached (5) - marking all tasks as unassigned",
        );
        return {
          vroomRoutesMap: {},
          tasks: [
            ...excludedTasks,
            ...includedTasks.map((task) => ({
              ...task,
              taskSpecificDetails: {
                ...task.taskSpecificDetails,
                assignedVehicleID: null,
                optimized: true,
                routingError: true,
              },
            })),
          ],
          unassigned: includedTasks.map((task) => ({
            id: 0,
            description: task.id,
            location: [
              args.getLocationDoc(task.locationID).longitude,
              args.getLocationDoc(task.locationID).latitude,
            ],
            type: "job",
            reason: "Routing error: Maximum optimization attempts reached",
          })),
          vroomOutput: null,
          stats: {
            originalTotalDistanceMiles: args.originalTotalDistanceMiles,
            unassignedCount: includedTasks.length,
            unassignedReasons: [
              "Routing error: Maximum optimization attempts reached",
            ],
          },
        };
      }

      // Recursively try to optimize the non-problematic tasks with incremented attempt counter
      let optimizedResult;
      try {
        optimizedResult = await allocateResourcesToTasksVROOM({
          ...args,
          tasks: [...nonProblematicTasks],
          _attemptCount: attemptCount + 1,
        });
      } catch (retryError) {
        // If retry fails, return all tasks as unassigned
        return {
          vroomRoutesMap: {},
          tasks: [
            ...excludedTasks,
            ...includedTasks.map((task) => ({
              ...task,
              taskSpecificDetails: {
                ...task.taskSpecificDetails,
                assignedVehicleID: null,
                optimized: true,
                routingError: true,
              },
            })),
          ],
          unassigned: includedTasks.map((task) => ({
            id: 0,
            description: task.id,
            location: [
              args.getLocationDoc(task.locationID).longitude,
              args.getLocationDoc(task.locationID).latitude,
            ],
            type: "job",
            reason: "Routing error: Unable to optimize after multiple attempts",
          })),
          vroomOutput: null,
          stats: {
            originalTotalDistanceMiles: args.originalTotalDistanceMiles,
            unassignedCount: includedTasks.length,
            unassignedReasons: [
              "Routing error: Unable to optimize after multiple attempts",
            ],
          },
        };
      }

      // Combine the results from the retry with the problematic tasks
      return {
        tasks: [
          ...excludedTasks,
          ...optimizedResult.tasks,
          ...problematicTasks.map((task) => ({
            ...task,
            taskSpecificDetails: {
              ...task.taskSpecificDetails,
              assignedVehicleID: null,
              optimized: true,
              routingError: true,
            },
          })),
        ],
        unassigned: [
          ...optimizedResult.unassigned,
          ...problematicTasks.map((task) => ({
            id: 0,
            description: task.id,
            location: [
              args.getLocationDoc(task.locationID).longitude,
              args.getLocationDoc(task.locationID).latitude,
            ],
            type: "job",
            reason: `Routing error: Unable to find route to location [${coordinates}]`,
          })),
        ],
        vroomRoutesMap: optimizedResult.vroomRoutesMap,
        vroomOutput: optimizedResult.vroomOutput,
        stats: {
          originalTotalDistanceMiles: args.originalTotalDistanceMiles,
          optimizedTotalDistanceMeters:
            optimizedResult.stats.optimizedTotalDistanceMeters,
          unassignedCount:
            optimizedResult.stats.unassignedCount + problematicTasks.length,
          unassignedReasons: [
            ...optimizedResult.stats.unassignedReasons,
            `Routing error: Unable to find route to location [${coordinates}]`,
          ],
        },
      };
    }

    // For other errors, rethrow
    throw error;
  }

  const OUTPUT: ExistingTask[] = [];
  const vroomRoutesMap: Record<string, VroomRoute> = {};
  const vroomOutput: VroomOutput = VroomManager.createFromJSON(vroomResponse);
  logger.info({ vroomOutput });

  // First let's see if any tasks couldn't be assigned to a vehicle
  const unassignedTasks = vroomOutput.unassigned;
  for (const unassignedTask of unassignedTasks) {
    const taskID = unassignedTask.description;
    const foundTask = tasksOptimized.find((task) => task.id === taskID);
    if (!foundTask) continue;

    const newTSD = {
      ...foundTask.taskSpecificDetails,
      assignedVehicleID: null,
      optimized: true,
    };

    OUTPUT.push({
      ...foundTask,
      taskSpecificDetails: newTSD,
      timestampScheduled: Timestamp.fromMillis(
        foundTask.timestampScheduled!.toMillis(),
      ),
    });
  }

  // Then we'll add in the night tasks, which are not being changed
  for (const task of excludedTasks) {
    OUTPUT.push({
      ...task,
    });
  }

  for (const route of vroomOutput.routes) {
    const vehicleID = vroomVehicles.find(
      (vehicle) => vehicle.id === route.vehicle,
    )?.description;

    if (!vehicleID) {
      throw Error(
        `Unable to locate vehicleID for the route: ${JSON.stringify(route, null, 2)}`,
      );
    }

    vroomRoutesMap[vehicleID] = route;

    for (const step of route.steps) {
      // id is undefined for start and end. exclude them; they aren't jobs.
      // it's important to use strict equality here - 0 is a valid id
      if (step.id === undefined) continue;

      const taskID = vroomJobs.find((vroomJob) => {
        return vroomJob.id === step.id;
      })?.description;
      if (!taskID) continue;

      const foundTask = tasksOptimized.find((task) => task.id === taskID);
      if (!foundTask) continue;

      const newTSD = {
        ...foundTask.taskSpecificDetails,
        assignedVehicleID: vehicleID,
        optimized: true,
      };

      OUTPUT.push({
        ...foundTask,
        taskSpecificDetails: newTSD,
        timestampScheduled: Timestamp.fromMillis(step.arrival * 1000),
      });
    }
  }

  const stats: OptimizationStats = {
    originalTotalDistanceMiles: args.originalTotalDistanceMiles,
    optimizedTotalDistanceMeters: vroomOutput?.summary?.distance,
    unassignedCount: unassignedTasks.length,
    unassignedReasons: unassignedTasks
      .filter((t) => t.reason)
      .map((t) => t.reason!),
  };

  return {
    tasks: OUTPUT,
    unassigned: unassignedTasks,
    vroomOutput,
    stats,
    vroomRoutesMap,
  };
}
