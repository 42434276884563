import React from "react";
import { MdOutlineAccountTree } from "react-icons/md";

const styles: React.CSSProperties = {
  display: "block",
  height: "38px",
  width: "38px",
  cursor: "pointer",
  padding: "6px",
  paddingBottom: "4px",
};

type Props = {
  overrideIconStyles?: React.CSSProperties;
  /** if not provided: rounded-full text-gray-700 hover:bg-primaryOpacity90 */
  overrideButtonClasses?: string;
} & React.ComponentPropsWithRef<"button">;

/** Job icon (the one used in side nav), wrapped in button with ref */
export const ButtonIconCreateJob = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const { overrideIconStyles, overrideButtonClasses, ...rest } = props;

    return (
      <button
        ref={ref}
        {...rest}
        className={`${overrideButtonClasses ?? "rounded-full text-gray-700 hover:bg-primaryOpacity90"}`}
      >
        <MdOutlineAccountTree
          aria-label="create job button"
          style={{ ...styles, ...overrideIconStyles }}
        />
      </button>
    );
  },
);
