//Libs
import React, {
  Fragment,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import isEqual from "lodash/isEqual";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import AddBoxIcon from "@mui/icons-material/AddBox";

//Local
import * as strings from "../../strings";
import {
  ExistingInventoryTransaction,
  InventoryTransaction,
  readableInventoryTransactionType,
} from "../../models/inventory-transaction";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { ExistingSiteKeyCompany } from "../../models/site-key-companies";
import { ExistingInventoryObject } from "../../models/inventory-object";
import SearchBox from "../../components/SearchBox";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
// This is for tables that use percentage-based heights - ie `h-full min-h-[500px]`
import "../../assets/css/ag-grid-inject-height.css";
import { ExistingInventoryLocation } from "../../models/inventory-location";
import HeadingOne from "../../components/HeadingOne";

interface Props {
  /* DATA */
  inventoryTransactionList: (
    | ExistingInventoryTransaction
    | InventoryTransaction
  )[];
  userDisplayNamesMap: Record<string, string>;
  companiesList: ExistingSiteKeyCompany[];
  inventoryLocations: ExistingInventoryLocation[];
  inventoryObjectList: ExistingInventoryObject[];
  /* FUNCTIONS */
  onAddInventoryTransaction: () => void;
  hideTitle?: boolean;
  /* CHILDREN */
  children: {
    AddInventoryTransaction: React.ReactNode;
  };
}

export default function InventoryTransactionListPage({
  hideTitle,
  inventoryTransactionList,
  inventoryLocations,
  userDisplayNamesMap,
  companiesList,
  inventoryObjectList,
  onAddInventoryTransaction,
  ...props
}: Props) {
  return (
    <div className={"ag-theme-alpine flex flex-col"}>
      {hideTitle !== true && (
        <HeadingOne className="mb-8 mt-4">
          {strings.INVENTORY_TRANSACTION}
        </HeadingOne>
      )}
      <InventoryObjectListTable
        inventoryTransactionList={inventoryTransactionList}
        inventoryLocations={inventoryLocations}
        userDisplayNamesMap={userDisplayNamesMap}
        companiesList={companiesList}
        inventoryObjectList={inventoryObjectList}
        onAddInventoryTransaction={onAddInventoryTransaction}
      />
      {props.children.AddInventoryTransaction}
    </div>
  );
}

const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

const commonColProps = {
  // width: 150,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
};

interface InventoryObjectListTableProps {
  inventoryTransactionList: Props["inventoryTransactionList"];
  inventoryLocations: Props["inventoryLocations"];
  userDisplayNamesMap: Props["userDisplayNamesMap"];
  companiesList: Props["companiesList"];
  inventoryObjectList: Props["inventoryObjectList"];
  onAddInventoryTransaction: Props["onAddInventoryTransaction"];
}

/* TABLE COMPONENT */
const InventoryObjectListTable = React.memo(
  ({
    inventoryTransactionList,
    userDisplayNamesMap,
    companiesList,
    inventoryLocations,
    inventoryObjectList,
    onAddInventoryTransaction,
  }: InventoryObjectListTableProps) => {
    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<any>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        gridRef.current.api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    const onFilterTextBoxChanged = useCallback(() => {
      gridRef.current!.api.setQuickFilter(
        (document.getElementById("filter-text-box") as HTMLInputElement).value,
      );
    }, []);

    const renderTimestampCreated = (
      params: ICellRendererParams,
    ): JSX.Element => {
      return (
        <div>{convertToReadableTimestamp(params.data.timestampCreated)}</div>
      );
    };

    const formattedType = (params: ICellRendererParams): JSX.Element => {
      return (
        <div className="capitalize">
          {readableInventoryTransactionType[params.data.type]}
        </div>
      );
    };

    const renderUserDisplayName = (
      params: ICellRendererParams,
    ): JSX.Element => {
      const displayName = userDisplayNamesMap[params.data.uid];
      return <div className="capitalize">{displayName}</div>;
    };

    const renderCompanyName = (params: ICellRendererParams): JSX.Element => {
      const currentCompany = companiesList.find((company) => {
        return company.id === params.data.companyID;
      });
      return <div className="capitalize">{currentCompany?.name}</div>;
    };

    const renderInventoryLocation = (
      params: ICellRendererParams,
    ): JSX.Element => {
      const inventoryLocation = inventoryLocations.find((il) => {
        return il.id === params.data.inventoryLocationID;
      });
      return <div className="capitalize">{inventoryLocation?.title}</div>;
    };

    const renderObjectTitle = (params: ICellRendererParams): JSX.Element => {
      const currentObject = inventoryObjectList.find((inventoryObject) => {
        return inventoryObject.id === params.data.inventoryObjectID;
      });
      return <div className="capitalize">{currentObject?.title}</div>;
    };

    const columnDefs: ColDef[] = [
      {
        headerName: "Date",
        field: "timestampCreated",
        cellRenderer: renderTimestampCreated,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Object",
        field: "inventoryObjectID",
        cellRenderer: renderObjectTitle,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Qty",
        field: "value",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "User",
        field: "uid",
        cellRenderer: renderUserDisplayName,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Type",
        field: "type",
        cellRenderer: formattedType,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Inventory Location",
        field: "inventoryLocationID",
        cellRenderer: renderInventoryLocation,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
    ];

    return (
      <Fragment>
        <div className="flex h-full flex-col-reverse items-center gap-4 sm:flex-row sm:items-end sm:justify-between">
          <SearchBox
            onInput={onFilterTextBoxChanged}
            widthClasses="w-full max-w-md sm:w-96"
          />
          <button
            type="button"
            onClick={onAddInventoryTransaction}
            className="text-primary"
          >
            <AddBoxIcon
              aria-label="Add Inventory Transaction"
              sx={{ fontSize: 45 }}
            />
          </button>
        </div>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={commonColProps}
          className="mt-5 shadow"
          rowData={inventoryTransactionList}
          animateRows={true}
          rowSelection="single"
          cacheQuickFilter={true}
          columnDefs={columnDefs}
        />
      </Fragment>
    );
  },
  (previous, next) => {
    const isInventoryTransactionListTheSame = isEqual(
      previous.inventoryTransactionList,
      next.inventoryTransactionList,
    );
    return isInventoryTransactionListTheSame;
  },
);
