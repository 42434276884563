/**
 * This is used specifically for OpenAI responses that we asked to receive in markdown.
 */
export default function stripBackticks(response: string) {
  if (response.startsWith("```") && response.endsWith("```")) {
    const x = response.slice(3, -3);
    if (x.startsWith("markdown")) return x.slice(8);
    return x;
  }
  return response;
}
