import React from "react";
import PersonIcon from "@mui/icons-material/Person";

const styles = {
  display: "block",
  height: "38px",
  width: "38px",
  cursor: "pointer",
  padding: "6px",
};

type Props = {
  overrideIconStyles?: React.CSSProperties;
  /** if not provided: rounded-full text-gray-700 hover:bg-primaryOpacity90 */
  overrideButtonClasses?: string;
} & React.ComponentPropsWithRef<"button">;

/** Person icon, wrapped in button with ref */
export const ButtonViewCustomer = React.forwardRef<HTMLButtonElement, Props>(
  (props, ref) => {
    const { overrideIconStyles, overrideButtonClasses, ...rest } = props;

    return (
      <button
        ref={ref}
        {...rest}
        className={`${overrideButtonClasses ?? "rounded-full text-gray-700 hover:bg-primaryOpacity90"}`}
      >
        <PersonIcon
          aria-label="view customer button"
          style={{ ...styles, ...overrideIconStyles }}
        />
      </button>
    );
  },
);
