import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { z } from "zod";
import { FirebaseError } from "firebase/app";
import { useToastMessageStore } from "../store/toast-messages";
import { createToastMessageID } from "../utils";

import * as strings from "../strings";
import BaseInputText from "../components/BaseInputText";
import StyledMessage from "../components/StyledMessage";
import BaseButtonPrimary from "../components/BaseButtonPrimary";
import BaseButtonSecondary from "../components/BaseButtonSecondary";
import MarketingCarousel from "../components/MarketingCarousel";

// Schema for initial signup
const SignUpFormSchema = z
  .object({
    email: z.string().email({ message: "Please enter a valid email address" }),
    password: z
      .string()
      .min(6, { message: strings.passwordFieldLengthRequired(6) })
      .max(200),
    confirmPassword: z
      .string()
      .min(1, { message: strings.REQUIRED_FIELD })
      .max(200),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });

type SignUpFormState = z.infer<typeof SignUpFormSchema>;

interface Props {
  handleSignUp: (formValues: SignUpFormState) => Promise<void>;
  goToLoginPage: () => void;
  handleResendVerification: (email: string) => Promise<void>;
  handlePasswordReset: (email: string) => Promise<void>;
  logo: string;
}

export default function SignUpPage(props: Props) {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [existingAccountError, setExistingAccountError] = useState<{
    message: string;
    email: string;
  } | null>(null);
  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<SignUpFormState>({
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
    resolver: zodResolver(SignUpFormSchema),
    mode: "onChange",
  });

  const email = watch("email");

  const onSubmit = async (formValues: SignUpFormState) => {
    try {
      setExistingAccountError(null);
      await props.handleSignUp(formValues);
    } catch (error) {
      if (error instanceof FirebaseError) {
        if (error.code === "functions/already-exists") {
          const response = error.customData as {
            message: string;
            email: string;
          };
          setExistingAccountError(response);
        }
      }
    }
  };

  const handleResendVerification = async () => {
    if (!existingAccountError) return;

    try {
      await props.handleResendVerification(email);
      addMessage({
        id: createToastMessageID(),
        message: strings.VERIFICATION_EMAIL_SENT,
        type: "success",
        dialog: false,
      });
    } catch (error) {
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        type: "error",
        dialog: false,
      });
    }
  };

  const handlePasswordReset = async () => {
    if (!existingAccountError) return;

    try {
      await props.handlePasswordReset(email);
      addMessage({
        id: createToastMessageID(),
        message: strings.PASSWORD_RESET_EMAIL_SENT,
        type: "success",
        dialog: false,
      });
    } catch (error) {
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        type: "error",
        dialog: false,
      });
    }
  };

  return (
    <div className="flex h-screen w-full">
      {/* Left Panel - Sign Up Form */}
      <div className="flex w-full flex-col items-center justify-center bg-white px-8 lg:w-1/2">
        <div className="w-full max-w-md">
          <div className="mb-8">
            <div className="flex items-center justify-center rounded-md bg-black p-4 py-12">
              <img
                src={props.logo}
                alt="Stilt logo"
                className="h-12 w-auto object-contain "
              />
            </div>
            <h1 className="mt-6 text-center text-3xl font-bold text-gray-900">
              Start Your Free Trial
            </h1>
            <p className="mt-2 text-center text-sm text-gray-600">
              No credit card required. Get started in minutes.
            </p>
          </div>

          {existingAccountError ? (
            <div className="mb-8 rounded-lg border border-yellow-200 bg-yellow-50 p-4">
              <p className="text-sm text-yellow-800">
                {existingAccountError.message}
              </p>
              <div className="mt-4 flex space-x-4">
                {existingAccountError.message.includes("verification") ? (
                  <BaseButtonSecondary
                    onClick={handleResendVerification}
                    className="text-sm"
                  >
                    Resend Verification Email
                  </BaseButtonSecondary>
                ) : (
                  <BaseButtonSecondary
                    onClick={handlePasswordReset}
                    className="text-sm"
                  >
                    Reset Password
                  </BaseButtonSecondary>
                )}
                <BaseButtonSecondary
                  onClick={props.goToLoginPage}
                  className="text-sm"
                >
                  Sign In
                </BaseButtonSecondary>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              {/* Email */}
              <div>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <BaseInputText
                      admin={true}
                      required
                      inputName="email"
                      text="Email"
                      type="email"
                      {...field}
                    />
                  )}
                />
                {errors.email?.message && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: errors.email.message }}
                    </StyledMessage>
                  </div>
                )}
              </div>

              {/* Password */}
              <div>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <BaseInputText
                      required
                      admin={true}
                      type={showPassword ? "text" : "password"}
                      inputName="password"
                      text="Password"
                      {...field}
                    >
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                        ) : (
                          <EyeIcon className="h-5 w-5 text-gray-500" />
                        )}
                      </button>
                    </BaseInputText>
                  )}
                />
                {errors.password?.message && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: errors.password.message }}
                    </StyledMessage>
                  </div>
                )}
              </div>

              {/* Confirm Password */}
              <div>
                <Controller
                  name="confirmPassword"
                  control={control}
                  render={({ field }) => (
                    <BaseInputText
                      required
                      admin={true}
                      type={showConfirmPassword ? "text" : "password"}
                      inputName="confirmPassword"
                      text="Confirm Password"
                      {...field}
                    >
                      <button
                        type="button"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                      >
                        {showConfirmPassword ? (
                          <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                        ) : (
                          <EyeIcon className="h-5 w-5 text-gray-500" />
                        )}
                      </button>
                    </BaseInputText>
                  )}
                />
                {errors.confirmPassword?.message && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: errors.confirmPassword.message }}
                    </StyledMessage>
                  </div>
                )}
              </div>

              <BaseButtonPrimary
                type="submit"
                disabled={isSubmitting}
                isBusy={isSubmitting}
                busyText="Creating Account..."
                className="w-full justify-center"
              >
                Start Free Trial
              </BaseButtonPrimary>
            </form>
          )}

          {!existingAccountError && (
            <div className="mt-6 text-center">
              <span className="text-sm text-gray-600">
                Already have an account?{" "}
              </span>
              <BaseButtonSecondary
                type="button"
                onClick={props.goToLoginPage}
                className="hover:text-primary-dark text-sm font-medium text-primary"
              >
                Sign in
              </BaseButtonSecondary>
            </div>
          )}
        </div>
      </div>

      {/* Right Panel - Marketing Carousel */}
      <div className="hidden lg:block lg:w-1/2">
        <MarketingCarousel />
      </div>
    </div>
  );
}
