// Libs
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import React from "react";
import { ExistingAsset } from "../../models/asset";

// Local
import * as strings from "../../strings";
import { ExistingTask } from "../../models/task";
import { getReadableTaskStatus } from "../../models/task-status";
import {
  getTaskStatusClasses,
  getTaskStatusIcon,
  getTaskStatusPillClasses,
} from "../../assets/js/tasks";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import TaskCardActionButtons from "./TaskCardActionButtons";
import { phoneUtils } from "../../utils/phoneUtils";
import { SettingsApplications } from "@mui/icons-material";

interface Props {
  task: ExistingTask;
  existingEstimatesForTask: boolean;
  primaryContactPhone?: string;
  assets: ExistingAsset[];
  onAssetClick?: (asset: ExistingAsset) => void;
  // FUNCTIONS
  handleDeleteTask: (id: ExistingTask["id"]) => Promise<void>;
  /** RTD = Reschedule Task Dialog */
  handleOpenRTD: (id: ExistingTask["id"]) => void;
  handleOpenEditTaskDialog: (id: ExistingTask["id"]) => void;
  handleCreateNewEstimate: (taskDoc: ExistingTask) => void;
  handleOpenEstimateListDialog?: (taskID: ExistingTask["id"]) => void;

  // COMPONENTS
  children: {
    detailsAndEventsPanels: React.ReactNode;
    changeTaskStatusButton: React.ReactNode;
  };
}

export default function TaskCardOpen(props: Props): JSX.Element {
  return (
    <Accordion
      style={{ borderRadius: "0.375rem" }}
      variant="elevation"
      elevation={1}
      className=""
    >
      <AccordionSummary className="flex w-full items-center transition-colors hover:bg-gray-50">
        <div className="flex w-full flex-col">
          <h3 className="text-base text-primary">{props.task.title}</h3>
          <div className="text-sm text-gray-400">
            <div className="flex items-center space-x-2">
              <div
                className={`rounded-full px-2 py-1 text-xs font-medium ${getTaskStatusPillClasses(props.task.taskStatus)}`}
              >
                {getReadableTaskStatus(props.task.taskStatus)}
              </div>
              {props.task.urgent && (
                <span className="ml-2 text-red-700">({strings.URGENT})</span>
              )}
              {props.task.membershipIDs &&
                props.task.membershipIDs.length > 0 && (
                  <div className="rounded-lg bg-blue-200 px-2 py-1 text-xs font-medium text-black">
                    {strings.MEMBERSHIP_TASK}
                  </div>
                )}
            </div>
          </div>
          {props.task.timestampScheduled && (
            <p className="text-sm text-gray-400">
              Scheduled for{" "}
              <span className="text-gray-900">
                {convertToReadableTimestamp(props.task.timestampScheduled)}
              </span>
            </p>
          )}
          {props.primaryContactPhone && (
            <p className="text-sm text-gray-400">
              Primary Contact Phone:{" "}
              <span className="text-gray-900">
                {phoneUtils.display(props.primaryContactPhone)}
              </span>
            </p>
          )}
          {props.assets &&
            props.assets.map((asset) => (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  props.onAssetClick?.(asset);
                }}
                className="mt-1 flex cursor-pointer items-center gap-1 text-sm text-gray-600 hover:text-primary"
              >
                <SettingsApplications className="h-4 w-4" />
                <span>
                  {asset.title} - {asset.description}
                </span>
              </div>
            ))}
        </div>
        <span
          className={`${getTaskStatusClasses(
            props.task.taskStatus,
          )} ml-2 self-center`}
        >
          {getTaskStatusIcon(props.task.taskStatus)}
        </span>
      </AccordionSummary>
      <AccordionDetails className="flex flex-col text-gray-700">
        {props.task.description && (
          <>
            <p className="text-gray-500">{strings.ADDITIONAL_NOTES}</p>
            <p className="mb-3 text-sm">{props.task.description}</p>
          </>
        )}
        {props.children.detailsAndEventsPanels}

        <TaskCardActionButtons
          task={props.task}
          existingEstimatesForTask={props.existingEstimatesForTask}
          handleDeleteTask={props.handleDeleteTask}
          handleOpenRTD={props.handleOpenRTD}
          handleOpenEditTaskDialog={props.handleOpenEditTaskDialog}
          handleCreateNewEstimate={props.handleCreateNewEstimate}
          handleOpenEstimateListDialog={props.handleOpenEstimateListDialog}
        >
          {{ changeTaskStatusButton: props.children.changeTaskStatusButton }}
        </TaskCardActionButtons>
      </AccordionDetails>
    </Accordion>
  );
}
