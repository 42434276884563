import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";
import BaseButtonPrimary from "../../../../components/BaseButtonPrimary";

interface ProgressStepProps {
  success: boolean;
  message: string;
}

export default function ProgressStep({ success, message }: ProgressStepProps) {
  return (
    <div className="text-center">
      <div className="flex justify-center">
        {success ? (
          <CheckCircleIcon className="h-20 w-20 text-green-500" />
        ) : (
          <XCircleIcon className="h-20 w-20 text-red-500" />
        )}
      </div>

      <h2 className="mt-4 text-2xl font-bold text-gray-900">
        {success ? "Application Submitted" : "Submission Failed"}
      </h2>

      <p className="mt-2 text-gray-600">{message}</p>

      <div className="mt-8">
        <Link to="/">
          <BaseButtonPrimary type="button">
            Return to Dashboard
          </BaseButtonPrimary>
        </Link>
      </div>
    </div>
  );
}
