// Libs
import { useState } from "react";
import { useQuery } from "react-query";
import SiteIcon from "@mui/icons-material/Public";
import LogoutIcon from "@mui/icons-material/Logout";
import type { SvgIconComponent } from "@mui/icons-material";
import List from "@mui/material/List";

// Local
import { DbRead, DbWrite } from "../database";
import { UserInfoListItem } from "../components/UserInfo";
import SelectDefaultSiteKeyDialog from "./SelectDefaultSiteKeyDialog";
import { useRootUserStore } from "../store/root-user";
import { signUserOut } from "../init-firebase";
import { useNavigate } from "react-router-dom";
import { ADMIN_USER_SETTINGS_URL } from "../urls";

interface ListItemInputs {
  icon: SvgIconComponent;
  heading: string;
  subheading: string;
  onClick: () => void;
}

function UnapprovedUserPage() {
  const navigate = useNavigate();
  const rootUser = useRootUserStore((state) => state.rootUser);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // get sites available to this user
  const { data: siteKeyList = [], isLoading: isLoadingSiteKeyList } = useQuery(
    ["unapprovedUserPage_siteKeyList"],
    () => DbRead.siteKey.getRootUserListOfSiteKeyDocs(),
  );

  if (isLoadingSiteKeyList) {
    return <div>Loading...</div>;
  }

  async function handleSwitchSiteKey(selectedSiteKey: string) {
    if (!rootUser) return;
    await DbWrite.rootUser.update(
      rootUser.defaultSiteKey ?? "unknown",
      { defaultSiteKey: selectedSiteKey },
      rootUser.id,
    );
    // nav to regular user settings page
    navigate(`${ADMIN_USER_SETTINGS_URL}`);
  }

  const actions: ListItemInputs[] = [];
  if (rootUser && siteKeyList.length) {
    actions.push({
      icon: SiteIcon,
      heading: "Change Default Site",
      subheading: rootUser?.defaultSiteKey ?? "No default site selected",
      onClick: () => setIsDialogOpen(true),
    });
  }
  actions.push({
    icon: LogoutIcon,
    heading: "Logout",
    subheading: "Sign out of your account",
    onClick: async () => await signUserOut(),
  });

  const selectDefaultSiteKeyDialog = rootUser && (
    <SelectDefaultSiteKeyDialog
      isDialogOpen={isDialogOpen}
      closeDialog={() => setIsDialogOpen(false)}
      defaultSiteKey={rootUser.defaultSiteKey}
      handleSelectionNewSiteKey={handleSwitchSiteKey}
      siteKeyList={siteKeyList}
    />
  );

  return (
    <div className="mx-auto flex max-w-screen-lg flex-col rounded-md px-6 pb-2 pt-8 shadow-darker">
      <div className="mb-6 space-y-2 text-center text-gray-600">
        <h2 className="text-xl font-semibold text-primary">
          Account Pending Approval
        </h2>
        <p>
          You are not approved for this site. If you believe this is in error,
          contact your administrator for assistance.
        </p>
      </div>
      <List className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        {actions.map((action) => (
          <UserInfoListItem
            key={action.heading}
            icon={action.icon}
            heading={action.heading}
            subheading={action.subheading}
            onClick={action.onClick}
            isAdmin={false}
          />
        ))}
      </List>
      {selectDefaultSiteKeyDialog}
    </div>
  );
}

export default UnapprovedUserPage;
