//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

//Local
import * as strings from "../../strings";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseModal from "../BaseModal";
import { ExistingEstimate } from "../../models/estimate";
import { logger as devLogger } from "../../logging";
import { ErrorMessage } from "../ErrorMessage";
import StyledMessage from "../StyledMessage";
import BaseInputTextArea from "../BaseInputTextArea";
import HeadingOne from "../HeadingOne";

interface Props {
  /* DATA */
  isDialogOpen: boolean;
  estimateInternalNotes: ExistingEstimate["internalNotes"];
  /* FUNCTIONS */
  closeDialog: () => void;
  onEditInternalNotes: (value: string | null) => Promise<void>;
}

export const EditInternalNotesFormSchema = z.object({
  internalNotes: z.string().max(8000).nullable(),
});
export type EditInternalNotesFormState = z.infer<
  typeof EditInternalNotesFormSchema
>;

export default function EditInternalNotesDialog(props: Props) {
  const [displayError, setDisplayError] = useState<boolean>(false);

  const editInternalNotesFormDefaultValue: EditInternalNotesFormState =
    useMemo(() => {
      return {
        internalNotes: props.estimateInternalNotes,
      };
    }, [props.estimateInternalNotes]);

  const { control, formState, reset, handleSubmit, getFieldState } =
    useForm<EditInternalNotesFormState>({
      defaultValues: editInternalNotesFormDefaultValue,
      resolver: zodResolver(EditInternalNotesFormSchema),
      mode: "onChange",
    });

  const { errors, isSubmitting } = formState;

  /* fn that handle all the states that needs to be reset when the dialog is closed */
  function closeAndReset() {
    reset();
    setDisplayError(false);
    props.closeDialog();
  }

  useEffect(() => {
    reset({ internalNotes: props.estimateInternalNotes });
  }, [props.estimateInternalNotes, reset]);

  async function onSubmit(data: EditInternalNotesFormState) {
    // Check if the field has been modified.
    const internalNotesState = getFieldState("internalNotes", formState);
    const hasCommentChanged = internalNotesState.isDirty;

    if (hasCommentChanged === false) {
      devLogger.debug("The comment hasn't changed");
      return;
    }

    try {
      await props.onEditInternalNotes(data.internalNotes);
      devLogger.debug("Internal notes has successfully updated!");
      // Close dialog if successful.
      closeAndReset();
    } catch (error) {
      setDisplayError(true);
      devLogger.error(error);
    }
  }

  const editInternalNotesHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <HeadingOne fontColor="text-white" fontSize="text-xl">
        {strings.EDIT_INTERNAL_NOTES}
      </HeadingOne>
      <button type="button" onClick={() => props.closeDialog()}>
        <XMarkIcon
          aria-label="close edit estimate notes form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  const errorMessage = (
    <ErrorMessage
      message={strings.ERR_EDIT_INTERNAL_NOTES}
      clearMessage={() => setDisplayError(false)}
    />
  );

  const internalNotesTouched = getFieldState(
    "internalNotes",
    formState,
  ).isTouched;
  const internalNotesChanged = getFieldState(
    "internalNotes",
    formState,
  ).isDirty;

  return (
    <BaseModal
      closeModal={props.closeDialog}
      open={props.isDialogOpen}
      title={editInternalNotesHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="relative flex flex-col space-y-8 p-8 text-lg">
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-8"
        >
          <div>
            <Controller
              name="internalNotes"
              control={control}
              render={({ field }) => (
                <BaseInputTextArea
                  text="Internal Notes"
                  placeholder={strings.PLACEHOLDER_INTERNAL_NOTE}
                  inputName="internalNotes"
                  admin={true}
                  required={false}
                  rows={3}
                  {...field}
                  value={field.value == null ? "" : field.value}
                />
              )}
            />
            {errors.internalNotes?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.internalNotes.message }}
                </StyledMessage>
              </div>
            )}
          </div>
          <div className="mt-2 text-xs text-red-400">
            {!internalNotesChanged && internalNotesTouched
              ? "Notes hasn't changed"
              : ""}
          </div>

          {/* Action Buttons */}
          <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
            <BaseButtonSecondary
              type="button"
              className="w-full justify-center uppercase"
              onClick={closeAndReset}
            >
              {strings.buttons.CANCEL}
            </BaseButtonSecondary>

            <BaseButtonPrimary
              type="submit"
              formNoValidate
              disabled={isSubmitting}
              isBusy={isSubmitting}
              busyText={strings.buttons.BUSY_SAVING}
              className="w-full justify-center uppercase"
            >
              {strings.buttons.SAVE}
            </BaseButtonPrimary>
          </div>
        </form>
        {displayError ? (
          <span className="absolute bottom-10 left-1/2 w-3/4 -translate-x-1/2 sm:w-96">
            {errorMessage}
          </span>
        ) : null}
      </div>
    </BaseModal>
  );
}
