import { useState } from "react";
import BaseInputText from "../../../../components/BaseInputText";
import BaseButtonPrimary from "../../../../components/BaseButtonPrimary";
import BaseButtonSecondary from "../../../../components/BaseButtonSecondary";
import StyledMessage from "../../../../components/StyledMessage";
import BaseInputSelect from "../../../../components/BaseInputSelect";

interface BankingInfo {
  bankName: string;
  holderName: string;
  routingNumber: string;
  accountNumber: string;
  accountType: "C" | "S"; // Match the API schema
}

interface BankingStepProps {
  initialData: BankingInfo;
  onComplete: (data: BankingInfo) => void;
}

export default function BankingStep({
  initialData,
  onComplete,
}: BankingStepProps) {
  const [formData, setFormData] = useState<BankingInfo>(initialData);
  const [error, setError] = useState("");

  const handleRoutingNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const routingNumber = e.target.value.replace(/\D/g, "").slice(0, 9);
    setFormData((prev) => ({
      ...prev,
      routingNumber,
    }));
  };

  const handleAccountNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const accountNumber = e.target.value.replace(/\D/g, "");
    setFormData((prev) => ({
      ...prev,
      accountNumber,
    }));
  };

  const validateForm = (): boolean => {
    if (!formData.accountType) {
      setError("Please select an account type");
      return false;
    }

    if (formData.routingNumber.length !== 9) {
      setError("Please enter a 9-digit routing number");
      return false;
    }

    if (
      formData.accountNumber.length < 4 ||
      formData.accountNumber.length > 17
    ) {
      setError("Please enter a valid account number");
      return false;
    }

    if (!formData.bankName) {
      setError("Please enter your bank name");
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setError("");
      onComplete(formData);
    }
  };

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-2xl font-bold text-gray-900">
          Banking Information
        </h2>
        <p className="mt-1 text-sm text-gray-500">
          Please provide your business banking information for deposits.
        </p>
      </div>

      <div className="space-y-6">
        <div>
          <BaseInputSelect
            text="Account Type"
            value={formData.accountType}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                accountType: e.target.value as "C" | "S",
              }))
            }
            required
            admin
          >
            <option value="">Select Account Type</option>
            <option value="C">Checking Account</option>
            <option value="S">Savings Account</option>
          </BaseInputSelect>
        </div>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <BaseInputText
            text="Name on Account"
            value={formData.holderName}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, holderName: e.target.value }))
            }
            required
            admin
            placeholder="Enter the name on the account"
          />

          <BaseInputText
            text="Bank Name"
            value={formData.bankName}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, bankName: e.target.value }))
            }
            required
            admin
          />
        </div>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <BaseInputText
            text="Routing Number"
            value={formData.routingNumber}
            onChange={handleRoutingNumberChange}
            required
            admin
            placeholder="9 digits"
            maxLength={9}
          />

          <BaseInputText
            text="Account Number"
            value={formData.accountNumber}
            onChange={handleAccountNumberChange}
            required
            admin
            type="password"
            placeholder="Enter your account number"
          />
        </div>

        {error && (
          <StyledMessage type="error">{{ message: error }}</StyledMessage>
        )}

        <div className="flex justify-between pt-6">
          <BaseButtonSecondary
            type="button"
            onClick={() => window.history.back()}
          >
            Back
          </BaseButtonSecondary>

          <BaseButtonPrimary type="button" onClick={handleSubmit}>
            Continue
          </BaseButtonPrimary>
        </div>
      </div>
    </div>
  );
}
