import { useState } from "react";
import { Control } from "react-hook-form";
// Local
import { TemplateFormState } from "./types";
import { AddEditTemplateForm } from "./AddEditTemplateForm";
import { CREATE_TEMPLATE, buttons } from "../../strings";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import NavButtonBack from "../../components/NavButtonBack";
import HeadingOne from "../../components/HeadingOne";

interface Props {
  onSubmit: (formValues: TemplateFormState) => Promise<void>;
  onCancel: () => void;
  children: {
    pbiSection: React.ReactNode;
    renewalPBISection: React.ReactNode;
    taskGenSection: (control: Control<TemplateFormState>) => React.ReactNode;
  };
}

export default function AddTemplatePage(props: Props) {
  const [isBusy, setIsBusy] = useState(false);

  return (
    <div className="relative mx-auto mb-10 w-full md:mx-0 md:max-w-[1280px]">
      <div className="fixed bottom-4 right-20 z-10 sm:bottom-auto sm:right-8 sm:top-24">
        <BaseButtonPrimary
          type="submit"
          form="addTemplateForm"
          formNoValidate
          disabled={isBusy}
          isBusy={isBusy}
          busyText={buttons.BUSY_SAVING}
          className="w-40 uppercase"
        >
          Save Template
        </BaseButtonPrimary>
      </div>

      <div className="mb-8 mt-4 flex flex-wrap items-center gap-y-2">
        <NavButtonBack onClick={props.onCancel} />
        <HeadingOne className="leading-3">{CREATE_TEMPLATE}</HeadingOne>
      </div>

      <AddEditTemplateForm
        id="addTemplateForm"
        onSubmit={props.onSubmit}
        setIsBusy={setIsBusy}
      >
        {{
          pbiSection: props.children.pbiSection,
          renewalPBISection: props.children.renewalPBISection,
          taskGenSection: props.children.taskGenSection,
        }}
      </AddEditTemplateForm>
    </div>
  );
}
