//Libs
import {
  ColDef,
  ICellRendererParams,
  RowClickedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import {
  Fragment,
  memo,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

//Local
import { ExistingPriceBookItem } from "../../models/price-book-item";
import * as strings from "../../strings";
import currencyFormatter from "../../currency";
import ChipTag from "../../components/ChipTag";
import SearchBox from "../../components/SearchBox";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
// This is for tables that use percentage-based heights - ie `h-full min-h-[500px]`
import "../../assets/css/ag-grid-inject-height.css";
import SiteKeyLocationCustomFilter from "../../components/tables/SiteKeyLocationCustomFilter";
import { ExistingPriceBookItemCategory } from "../../models/price-book-item-category";
import HeadingOne from "../../components/HeadingOne";

export interface Props {
  /* DATA */
  priceBookItemList: ExistingPriceBookItem[];
  priceBookItemCategories: ExistingPriceBookItemCategory[];
  currency: string;
  /* FUNCTIONS */
  onSearch: () => Promise<void>;
  onEditPBItem: (priceBookItem: ExistingPriceBookItem) => void;
  addNewPriceBookItemButton: React.ReactNode;
  exportToExcelButton: React.ReactNode;
  syncLightspeedButton: React.ReactNode;
  addEditPriceBookItemCategoriesButton: React.ReactNode;
  getLocationTitle: (id: string) => string;
  siteKeyLocationList: ExistingSiteKeyLocation[];
}

export default function PriceBookItemListPage({
  priceBookItemList,
  priceBookItemCategories,
  onSearch,
  currency,
  onEditPBItem,
  addNewPriceBookItemButton,
  exportToExcelButton,
  syncLightspeedButton,
  addEditPriceBookItemCategoriesButton,
  getLocationTitle,
  siteKeyLocationList,
}: Props) {
  return (
    <Fragment>
      <HeadingOne className="mb-4 md:mb-8">{strings.PRICEBOOK}</HeadingOne>
      <div className="flex flex-col-reverse justify-between gap-3 md:flex-row">
        <SearchBox
          onInput={onSearch}
          widthClasses="w-full sm:max-w-xs lg:max-w-sm"
        />
        <div className="flex flex-col justify-end gap-2 sm:flex-row sm:items-end">
          {syncLightspeedButton}
          {exportToExcelButton}
          {addEditPriceBookItemCategoriesButton}
          {addNewPriceBookItemButton}
        </div>
      </div>
      <div className={"ag-theme-alpine h-[1000px] max-h-[70vh] pb-6"}>
        <PriceBookItemListTable
          priceBookItemList={priceBookItemList}
          priceBookItemCategories={priceBookItemCategories}
          onEditPBItem={onEditPBItem}
          currency={currency}
          getLocationTitle={getLocationTitle}
          siteKeyLocationList={siteKeyLocationList}
        />
      </div>
    </Fragment>
  );
}

const commonColProps = {
  minWidth: 150,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass:
    "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",
  flex: 1,
};

interface PriceBookItemListTableProps {
  priceBookItemList: ExistingPriceBookItem[];
  priceBookItemCategories: ExistingPriceBookItemCategory[];
  currency: string;
  siteKeyLocationList: ExistingSiteKeyLocation[];
  onEditPBItem: (priceBookItem: ExistingPriceBookItem) => void;
  getLocationTitle: (id: string) => string;
}

/* TABLE COMPONENT */
const PriceBookItemListTable = memo(
  ({
    priceBookItemList,
    currency,
    onEditPBItem,
    getLocationTitle,
    priceBookItemCategories,
    siteKeyLocationList,
  }: PriceBookItemListTableProps) => {
    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<AgGridReact>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        gridRef.current.api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    const renderUnitPriceCell = useCallback(
      function (params: ICellRendererParams) {
        return (
          <div>{` ${currencyFormatter(params.data.unitPrice, currency)}/${
            params.data.units
          }`}</div>
        );
      },
      [currency],
    );

    const renderItem = (params: ICellRendererParams): JSX.Element => {
      return (
        <div className="my-2 flex items-center">
          <div className="flex flex-col space-y-2">
            <span className="block whitespace-normal text-base font-medium leading-5">
              {params.data.title}
            </span>
            <span className="block whitespace-normal text-sm leading-5 text-gray-400">
              {params.data.description}
            </span>
          </div>
        </div>
      );
    };

    function renderTagsCell(params: ICellRendererParams): JSX.Element | null {
      const tagsList = params.value;
      if (!Array.isArray(tagsList) || tagsList.length === 0) return null;
      return (
        <div className="flex flex-wrap items-center">
          {tagsList.map((tag) => {
            if (typeof tag === "string") {
              const key = tag + params.node.data.id;
              return <ChipTag tag={tag} key={key} />;
            } else {
              return null;
            }
          })}
        </div>
      );
    }

    const renderSiteKeyLocation = (
      params: ICellRendererParams,
    ): JSX.Element => {
      return (
        <div className="capitalize">
          {getLocationTitle(params.data.locationID)}
        </div>
      );
    };

    const renderCategory = (params: ICellRendererParams): JSX.Element => {
      return (
        <div className="capitalize">
          {priceBookItemCategories.find((c) => c.id === params.data.categoryID)
            ?.name ?? ""}
        </div>
      );
    };

    const columnDefs: ColDef[] = [
      {
        headerName: "Item",
        cellRenderer: renderItem,
        tooltipValueGetter: () => "Edit Price Book Item",
        autoHeight: true,
        minWidth: 400,
        flex: 2,
      },
      {
        headerName: "Unit Price $",
        field: "unitPrice",
        cellRenderer: renderUnitPriceCell,
      },
      {
        headerName: "Tags",
        field: "tags",
        cellRenderer: renderTagsCell,
        autoHeight: true,
      },
      {
        headerName: "Category",
        field: "categoryID",
        cellRenderer: renderCategory,
        autoHeight: true,
      },
    ];

    if (siteKeyLocationList.length > 1) {
      columnDefs.push({
        field: "locationID",
        headerName: "Location",
        cellRenderer: renderSiteKeyLocation,
        filter: SiteKeyLocationCustomFilter,
      });
    }

    return (
      <AgGridReact
        reactiveCustomComponents
        ref={gridRef}
        onGridReady={onGridReady}
        defaultColDef={commonColProps}
        className="mt-5 shadow"
        rowData={priceBookItemList}
        animateRows={true}
        rowSelection="single"
        cacheQuickFilter={true}
        columnDefs={columnDefs}
        onRowClicked={(event: RowClickedEvent) => onEditPBItem(event.data)}
      />
    );
  },
  // (previous, next) => {
  //   const isPriceBookItemListTheSame = isEqual(
  //     previous.priceBookItemList,
  //     next.priceBookItemList
  //   );
  //
  //   return isPriceBookItemListTheSame;
  // }
);
