//Libs
import { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { User } from "firebase/auth";

//Local
import GLAccountListPage from "./GLAccountListPage";
import { logger } from "../../logging";
import { DbRead, DbWrite } from "../../database";
import {
  ExistingGLAccount,
  GLAccount_CreateAPI,
  GLAccountManager,
} from "../../models/gl-account";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import * as strings from "../../strings";
import AddNewGLAccountDialog, {
  GLAccountFormState,
} from "../../components/AddNewGLAccountDialog";
import { useAuthStore } from "../../store/firebase-auth";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import HeadingOne from "../../components/HeadingOne";

interface Props {
  siteKey: string;
}

export default function GLAccountListContainer({ siteKey }: Props) {
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);
  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  const [gLAccountList, setGLAccountList] = useState<ExistingGLAccount[]>([]);
  const [isGetGLAccounts, setIsGetGLAccounts] = useState<boolean>(false);
  const [addNewGLAccountDialogOpen, setAddNewGLAccountDialogOpen] =
    useState<boolean>(false);

  // Fetch the list of general ledger accounts when this component loads.
  useEffect(() => {
    async function getGLAccountList() {
      logger.debug("getGLAccountList useEffect called ");
      // Query gl accounts via realtime updates. Set the list of general ledger accounts.
      const unsubscribeAllInvoicesInDateRange = DbRead.gLAccounts.subscribeAll({
        siteKey: siteKey,
        onChange: (gLAccountList: ExistingGLAccount[]) => {
          setGLAccountList(gLAccountList), setIsGetGLAccounts(false);
        },
        onError: (error) =>
          logger.error(`Error in getGLAccountList: ${error.message}`),
      });
      return unsubscribeAllInvoicesInDateRange;
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    setIsGetGLAccounts(true);
    const unsubscribePromise = getGLAccountList();
    // Return an anonymous ƒn for cleanup.
    return () => {
      logger.debug(
        "(GLAccountListContainer) getGLAccountList useEffect return ƒn just invoked.",
      );
      unsubscribePromise.then((unsubscribe) => {
        if (unsubscribe) {
          logger.debug("Now unsubscribing from gLAccounts.");
          unsubscribe();
        }
      });
      logger.debug("Done running cleanup ƒn for getGLAccountList useEffect.");
    };
  }, [siteKey]);

  async function handleSaveNewGLAccount(formValues: GLAccountFormState) {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    const newGLAccount: GLAccount_CreateAPI = {
      ...formValues,
      balance: 0,
      currency: siteKeyDoc?.customizations.accounting?.currency ?? "USD",
      customData: {},
      deleted: false,
      lastModifiedBy: firebaseUser.uid,
      siteKey: siteKey,
    };

    //Validate
    const validatedGLAccount = GLAccountManager.parseCreate(newGLAccount);
    logger.info("Validated General Ledger Account:", validatedGLAccount);

    //DB
    try {
      await DbWrite.gLAccounts.create(validatedGLAccount);
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulAdd(validatedGLAccount.name),
        dialog: false,
        type: "success",
      });
      setAddNewGLAccountDialogOpen(false);
    } catch (error) {
      logger.error(`An error occurred during handleSaveNewGLAccount`, error);
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  }

  const addNewGLAccountButton = (
    <BaseButtonPrimary
      type="button"
      onClick={() => setAddNewGLAccountDialogOpen(true)}
      className="w-full text-primary sm:w-auto"
    >
      <AddCircleIcon fontSize="small" className="mr-2" />
      {strings.ADD_NEW_GL_ACCOUNT}
    </BaseButtonPrimary>
  );

  const addNewGLAccountDialog = (
    <AddNewGLAccountDialog
      isDialogOpen={addNewGLAccountDialogOpen}
      closeDialog={() => setAddNewGLAccountDialogOpen(false)}
      handleSave={handleSaveNewGLAccount}
    />
  );

  // Display loading clipboard while customer list loads.
  if (isGetGLAccounts) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <HeadingOne className="mb-8">
        {strings.GENERAL_LEDGER_ACCOUNTS}
      </HeadingOne>
      <div className="self-end">{addNewGLAccountButton}</div>
      <GLAccountListPage gLAccountList={gLAccountList} />;
      {addNewGLAccountDialog}
    </div>
  );
}
