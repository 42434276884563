//Libs

//Local
import { ExistingTask } from "../../../models/task";
import { ExistingVehicle } from "../../../models/vehicle";
import { calculateTotalRouteDistance } from "./calculateTotalRouteDistance";
import { ExistingSiteKeyUserDoc } from "../../../models/site-key-users";
import { ExistingSiteKeyLocation } from "../../../models/site-key-location";
import { ExistingSiteKeyUserPermissions } from "../../../models/site-key-user-permissions";

export async function generateTotalRouteDistances(args: {
  tasks: ExistingTask[];
  vehicleList?: ExistingVehicle[];
  technicianList?: ExistingSiteKeyUserDoc[];
  permissionsMap?: Record<string, ExistingSiteKeyUserPermissions>;
  siteKeyLocations: ExistingSiteKeyLocation[];
}): Promise<number> {
  const asyncCalls: Promise<number>[] = [];

  const {
    tasks,
    vehicleList,
    technicianList,
    permissionsMap,
    siteKeyLocations,
  } = args;

  // Get the first scheduled task
  const sortedTasks = tasks.sort((a, b) => {
    // Sort by timestampScheduled
    if (a.timestampScheduled === null) {
      return -1;
    }
    if (b.timestampScheduled === null) {
      return 1;
    }
    return a.timestampScheduled > b.timestampScheduled ? -1 : 1;
  });

  if (vehicleList) {
    vehicleList.forEach((vehicle) => {
      const tasksForVehicle = sortedTasks.filter(
        (task) => task.taskSpecificDetails["assignedVehicleID"] === vehicle.id,
      );
      if (tasksForVehicle.length > 0) {
        asyncCalls.push(
          calculateTotalRouteDistance(
            tasksForVehicle,
            vehicle.latitude,
            vehicle.longitude,
          ),
        );
      }
    });
    const distanceResponses = await Promise.all(asyncCalls);
    // Sum all distances and convert to miles
    const distanceSum = Math.ceil(
      distanceResponses.reduce((partialSum, a) => partialSum + a, 0),
    );
    return distanceSum;
  }

  if (technicianList && permissionsMap) {
    technicianList.forEach((technician) => {
      const tasksForTechnician = sortedTasks.filter((task) => {
        if (Array.isArray(task.taskSpecificDetails["assignedTo"])) {
          // check if technician is in assignedTo array
          return task.taskSpecificDetails["assignedTo"].includes(technician.id);
        }
      });
      if (tasksForTechnician.length > 0) {
        // first check if the technician has a defaultLocationID
        const permissions = permissionsMap[technician.id];
        const technicianLocation = siteKeyLocations.find(
          (location) => location.id === permissions?.defaultLocationID,
        );
        if (technicianLocation) {
          asyncCalls.push(
            calculateTotalRouteDistance(
              tasksForTechnician,
              technicianLocation.latitude,
              technicianLocation.longitude,
            ),
          );
        } else {
          const firstTask = sortedTasks[0];
          const firstTaskLocation = siteKeyLocations.find(
            (location) => location.id === firstTask.locationID,
          );
          if (firstTaskLocation) {
            asyncCalls.push(
              calculateTotalRouteDistance(
                tasksForTechnician,
                firstTaskLocation.latitude,
                firstTaskLocation.longitude,
              ),
            );
          }
        }
      }
    });

    const distanceResponses = await Promise.all(asyncCalls);
    // Sum all distances and convert to miles
    const distanceSum = Math.ceil(
      distanceResponses.reduce((partialSum, a) => partialSum + a, 0),
    );
    return distanceSum;
  }

  return 0;
}
