import { z } from "zod";
import { zFallback } from "../utils";

export interface ServiceWindow {
  startHour: number;
  endHour: number;
  humanReadableString: string;
}

export interface SmartSchedulingOption {
  startHour: number;
  endHour: number;
  vehicle: string; // This is the vehicle ID
  humanReadableString: string;
  totalDistance: number;
}

export interface SmartSchedulingFormValues {
  defaultServiceWindowsByTaskType: Record<string, ServiceWindow[]>;
  defaultWorkDurationByTaskType: Record<string, number>;
}

// Function to parse from an object to a SmartSchedulingFormValues
export function parseSmartSchedulingFormValues(
  value: unknown,
): SmartSchedulingFormValues {
  if (value === undefined) {
    return {
      defaultServiceWindowsByTaskType: {},
      defaultWorkDurationByTaskType: {},
    };
  }
  return SmartSchedulingFormValuesSchema.parse(value);
}

const SmartSchedulingFormValuesSchema = z.object({
  defaultWorkDurationByTaskType: zFallback(
    z.record(z.string(), z.number()),
    {},
    "SmartSchedulingFormValuesSchema: 'defaultWorkDurationByTaskType'",
  ),
  defaultServiceWindowsByTaskType: zFallback(
    z.record(
      z.string(),
      z.array(
        z.object({
          startHour: z.number().int(),
          endHour: z.number().int(),
          humanReadableString: z.string(),
        }),
      ),
    ),
    {},
    "SmartSchedulingFormValuesSchema: 'defaultServiceWindowsByTaskType'",
  ),
});
