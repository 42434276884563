import { Timestamp } from "firebase/firestore";
import { z } from "zod";
// Local
import {
  InvoiceOverrideSchema,
  membershipStatus,
} from "../../models/membership";
import { StiltLineItemSchema } from "../../models/invoice";

// FORM STATE AND SCHEMA FOR CREATE/EDIT MEMBERSHIP

// nextInvoiceDate should be required on create, but not on edit.
// - this is so if they're creating the membership via CreateMembershipDialog, they
//   have to pick a date, which will ensure that the polling function will pick it up.
// - should not be required on edit for several reasons.

/** fields that the user can edit when creating a bespoke membership invoice. */
export const InvOverrideSubsetFormSchema = z.object({
  note: z.string().max(8000).nullable(),
  internalNotes: z.string().max(8000).nullable(),
  lineItems: z.array(StiltLineItemSchema).min(1, {
    message: "At least one line item is required",
  }),
  discount: z.number().min(0).max(100).or(z.literal("")),
});
export type InvOverrideSubsetFormState = z.infer<
  typeof InvOverrideSubsetFormSchema
>;

// includes the fields the user can't edit
// const InvoiceOverrideFullSchema = InvOverrideSubsetFormSchema.extend({
//   totalTaxAmount: z.number(),
//   totalTaxAmountPST: z.number().optional(),
//   totalTaxAmountGST: z.number().optional(),
//   subTotal: z.number(),
//   totalAmount: z.number(),
// });

export const MembershipFormSchema = z
  .object({
    notes: z.string().max(1000).nullable(),
    automaticallyGenerateTasks: z.boolean(),
    automaticallySendReceipt: z.boolean(),
    automaticallySendInvoice: z.boolean(),
    automaticallyPayInvoice: z.boolean(),
    membershipEndDate: z.instanceof(Timestamp).nullable(),
    membershipStartDate: z
      .instanceof(Timestamp)
      .nullable()
      // want the default value to be null, but don't want it to be null on submit
      .refine((val) => (val === null ? false : true), {
        message: "Select a start date",
      }),
    lastCompletedTaskDate: z.instanceof(Timestamp).nullable(),
    nextScheduledTaskDate: z.instanceof(Timestamp).nullable(),
    lastPaymentDate: z.instanceof(Timestamp).nullable(),
    nextInvoiceDate: z.instanceof(Timestamp).nullable(),
    lastPaymentAmount: z.number().nullable(),
    status: z
      .enum(membershipStatus)
      .nullable()
      .refine((val) => (val === null ? false : true), {
        message: "Select a status",
      }),
    membershipTemplateID: z
      .string()
      .nullable()
      .refine((val) => (!val || val.length === 0 ? false : true), {
        message: "Select a membership type",
      }),
    customerID: z
      .string()
      .nullable()
      .refine((val) => (val === null ? false : true), {
        message: "Select a customer",
      }),
    customerLocationID: z
      .string()
      .nullable()
      .refine((val) => (val === null ? false : true), {
        message: "Select a customer location",
      }),
    overrideEmail: z.string().min(0).max(200).optional(),
    overrideInvoice: InvoiceOverrideSchema.optional(),
    formType: z.enum(["create", "edit"]),
  })
  .superRefine((data, ctx) => {
    // force nextInvoiceDate to be populated on create
    if (data.formType === "create" && data.nextInvoiceDate === null) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Select an invoice date",
        path: ["nextInvoiceDate"],
      });
    }
  });

export type MembershipFormState = z.infer<typeof MembershipFormSchema>;
