import { DateTime } from "luxon";
import { Timestamp } from "firebase/firestore";
// Local
import { logger } from "../logging";

/**
 * Given one argument, will compare the given timestamp to today.
 *
 * Will return number of months between the two, no rounding has been applied.
 * (Compares using the given Timestamp's start of day and the start of today.)
 *
 ** If the given Timestamp is in the past, the return value will be negative.
 ** If the given Timestamp is in the future, the return value will be positive.
 ** If the given Timestamp is today, the return value will be 0.
 ** If a determination cannot be made, the return value will be null.
 ** Will not throw an error.
 *
 * ------
 * FUNCTION OVERLOAD:
 *
 * Returns the number of months (non-rounded) between the given timestamps. (Uses start of day for both timestamps.)
 *
 * __`compare`, the second argument, is the anchor:__
 *
 ** If `timestamp` is a date before the `compare` date, the return value will be negative.
 ** If `timestamp` is a date after the `compare` date, the return value will be positive.
 ** If `timestamp` is the same date as `compare`, the return value will be 0.
 ** If a determination cannot be made, the return value will be null.
 ** Will not throw an error.
 */
export function getMonthsBetween(timestamp: Timestamp): number | null;
export function getMonthsBetween(
  timestamp: Timestamp,
  compare: Timestamp,
): number | null;
export function getMonthsBetween(
  timestamp: Timestamp,
  compare?: Timestamp,
): number | null {
  if (!compare) {
    let givenLux: DateTime;
    try {
      const ms = timestamp.toMillis();
      givenLux = DateTime.fromMillis(ms);
    } catch (e) {
      logger.warn(`given timestamp: ${JSON.stringify(timestamp)}`);
      logger.warn(e);
      return null;
    }
    const utcStartOfGivenDay = givenLux.startOf("day").toUTC();
    const utcStartOfToday = DateTime.now().startOf("day").toUTC();
    // console.log(utcStartOfGivenDay.toISO(), "utcStartOfGivenDay, ISO");
    // console.log(utcStartOfToday.toISO(), "utcStartOfToday, ISO");

    const monthsBtwn = utcStartOfGivenDay
      .diff(utcStartOfToday, "months")
      .toObject().months;
    // console.log({ monthsBtwn });
    if (monthsBtwn === undefined) return null;
    return monthsBtwn;
  }

  // how far away is the given timestamp from the compare timestamp? compare is the anchor
  let luxTimestamp: DateTime;
  let luxCompare: DateTime;
  try {
    const msTS = timestamp.toMillis();
    const msCompare = compare.toMillis();
    luxTimestamp = DateTime.fromMillis(msTS);
    luxCompare = DateTime.fromMillis(msCompare);
  } catch (e) {
    logger.warn(`given timestamp: ${JSON.stringify(timestamp)}`);
    logger.warn(`given compare: ${JSON.stringify(compare)}`);
    logger.warn(e);
    return null;
  }
  const utcStartOfDayTS = luxTimestamp.startOf("day").toUTC();
  const utcStartOfDayCompare = luxCompare.startOf("day").toUTC();
  const monthsBtwn = utcStartOfDayTS
    .diff(utcStartOfDayCompare, "months")
    .toObject().months;
  // console.log(luxTimestamp.toISO(), luxCompare.toISO(), monthsBtwn);
  if (monthsBtwn === undefined) return null;
  return monthsBtwn;
}
