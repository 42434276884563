import { useState } from "react";
import BaseModal from "../BaseModal";
import BaseInputText from "../BaseInputText";
import BaseButtonPrimary from "../BaseButtonPrimary";
import StyledMessage from "../StyledMessage";
import { DbRead, DbWrite } from "../../database";
import { logger } from "../../logging";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { phoneUtils } from "../../utils";
import { AvailablePhoneNumber } from "../../models/twilio";

interface Props {
  open: boolean;
  onClose: () => void;
  onPurchaseComplete: () => void;
  siteKey: string;
}

export default function PurchaseNumberDialog({
  open,
  onClose,
  onPurchaseComplete,
  siteKey,
}: Props) {
  const [areaCode, setAreaCode] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [availableNumbers, setAvailableNumbers] = useState<
    AvailablePhoneNumber[]
  >([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedNumber, setSelectedNumber] =
    useState<AvailablePhoneNumber | null>(null);

  const handleSearch = async () => {
    if (!areaCode.match(/^\d{3}$/)) {
      setErrorMessage("Please enter a valid 3-digit area code");
      return;
    }

    setIsSearching(true);
    setErrorMessage(null);
    try {
      const numbers = await DbRead.twilio.searchForPhoneNumbers(
        siteKey,
        areaCode,
      );
      setAvailableNumbers(numbers);
    } catch (error) {
      logger.error(error);
      setErrorMessage("Failed to search for phone numbers");
    } finally {
      setIsSearching(false);
    }
  };

  const handlePurchase = async (phoneNumber: string) => {
    setIsPurchasing(true);
    setErrorMessage(null);
    try {
      const purchasedNumber = await DbWrite.twilio.purchasePhoneNumber(
        siteKey,
        phoneNumber,
      );
      setSelectedNumber({
        phoneNumber: purchasedNumber.phoneNumber,
        locality: "",
        region: "",
      });
    } catch (error) {
      logger.error(error);
      setErrorMessage("Failed to purchase phone number");
    } finally {
      setIsPurchasing(false);
    }
  };

  const handleUpdateNumber = async () => {
    if (!selectedNumber) return;

    setIsPurchasing(true);
    setErrorMessage(null);
    try {
      await DbWrite.twilio.updatePhoneNumber(
        siteKey,
        selectedNumber.phoneNumber,
        {
          title,
          description,
        },
      );
      onPurchaseComplete();
      onClose();
      // Reset form
      setTitle("");
      setDescription("");
      setSelectedNumber(null);
      setAvailableNumbers([]);
      setAreaCode("");
    } catch (error) {
      logger.error(error);
      setErrorMessage("Failed to update phone number details");
    } finally {
      setIsPurchasing(false);
    }
  };

  const header = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white">
      <h1 className="text-xl font-semibold">Purchase Tracking Number</h1>
      <button type="button" onClick={onClose}>
        <XMarkIcon className="h-6 text-white" />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={onClose}
      open={open}
      title={header}
      parentDivStyles="inline-block transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl"
    >
      <div className="px-4 pb-4 pt-5 sm:p-6">
        <div className="space-y-6">
          <div className="flex items-center gap-4">
            <BaseInputText
              admin={true}
              inputName="areaCode"
              text="Area Code"
              value={areaCode}
              onChange={(e) => setAreaCode(e.target.value)}
              placeholder="Enter 3-digit area code"
              className="max-w-[200px]"
            />
            <BaseButtonPrimary
              onClick={handleSearch}
              isBusy={isSearching}
              busyText="Searching..."
            >
              Search Numbers
            </BaseButtonPrimary>
          </div>

          {errorMessage && (
            <StyledMessage type="error">
              {{ message: errorMessage }}
            </StyledMessage>
          )}

          {availableNumbers.length > 0 && !selectedNumber && (
            <div className="rounded-lg border p-4">
              <h2 className="mb-4 text-xl font-semibold">Available Numbers</h2>
              <div className="space-y-4">
                {availableNumbers.map((number) => (
                  <div
                    key={number.phoneNumber}
                    className="flex items-center justify-between rounded-md border p-4"
                  >
                    <div>
                      <div className="font-medium">
                        {phoneUtils.display(number.phoneNumber)}
                      </div>
                      <div className="text-sm text-gray-600">
                        {number.locality} {number.region}
                      </div>
                    </div>
                    <BaseButtonPrimary
                      onClick={() => handlePurchase(number.phoneNumber)}
                      isBusy={isPurchasing}
                      busyText="Purchasing..."
                    >
                      Purchase
                    </BaseButtonPrimary>
                  </div>
                ))}
              </div>
            </div>
          )}

          {selectedNumber && (
            <div className="rounded-lg border p-4">
              <h2 className="mb-4 text-xl font-semibold">Configure Number</h2>
              <div className="space-y-4">
                <BaseInputText
                  admin={true}
                  inputName="title"
                  text="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="e.g., Google Ads Campaign Q4 2024"
                />
                <BaseInputText
                  admin={true}
                  inputName="description"
                  text="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Add details about how this number will be used"
                />
                <BaseButtonPrimary
                  onClick={handleUpdateNumber}
                  isBusy={isPurchasing}
                  busyText="Saving..."
                >
                  Save Configuration
                </BaseButtonPrimary>
              </div>
            </div>
          )}
        </div>
      </div>
    </BaseModal>
  );
}
