import create from "zustand";
import { ExistingChatMessageReadStatus } from "../models/chat_message_read_status";
import { DbRead } from "../database";
import { logger } from "../logging";

interface UnreadMessageCountsState {
  unreadCounts: ExistingChatMessageReadStatus[];
  totalUnreadCount: number;
  setUnreadCounts: (unreadCounts: ExistingChatMessageReadStatus[]) => void;
  clearUnreadCounts: () => void;
  subscribeToUnreadCounts: (siteKey: string, userId: string) => () => void;
  isSubscribed: boolean;
}

export const useUnreadMessageCountsStore = create<UnreadMessageCountsState>(
  (set, get) => ({
    unreadCounts: [],
    totalUnreadCount: 0,
    isSubscribed: false,
    setUnreadCounts: (unreadCounts: ExistingChatMessageReadStatus[]) => {
      const totalCount = unreadCounts.reduce(
        (sum, item) => sum + item.unreadCount,
        0,
      );
      set(() => ({ unreadCounts, totalUnreadCount: totalCount }));
    },
    clearUnreadCounts: () =>
      set(() => ({ unreadCounts: [], totalUnreadCount: 0 })),
    subscribeToUnreadCounts: (siteKey: string, userId: string) => {
      // Don't subscribe again if already subscribed
      if (get().isSubscribed) {
        return () => {}; // Return empty unsubscribe function
      }

      // Set subscribed flag
      set({ isSubscribed: true });

      // Set up subscription
      const unsubscribe = DbRead.chatMessageReadStatus.subscribe({
        siteKey,
        userId,
        onChange: (readStatusList) => {
          try {
            const { setUnreadCounts } = get();
            setUnreadCounts(readStatusList);
          } catch (error) {
            logger.error("Error processing unread message counts", error);
            set({ unreadCounts: [], totalUnreadCount: 0 });
          }
        },
        onError: (error) => {
          logger.error("Error fetching unread message counts", error);
          set({ unreadCounts: [], totalUnreadCount: 0 });
        },
      });

      // Return unsubscribe function that also resets the subscribed flag
      return () => {
        unsubscribe && unsubscribe();
        set({ isSubscribed: false });
      };
    },
  }),
);
