// Libs
import { useCallback, useEffect, useState } from "react";
import { User } from "firebase/auth";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

// Local
import { useAuthStore } from "../../store/firebase-auth";
import { useMembershipTemplatesStore } from "../../store/membership-templates";
import { useToastMessageStore } from "../../store/toast-messages";
import { useTypesenseStore } from "../../store/typesense";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { useUserPermissionsStore } from "../../store/user-permissions";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import { DbWrite } from "../../database";
import { logger } from "../../logging";
import { createToastMessageID } from "../../utils";
import { MEMBERSHIP_TEMPLATES } from "../../urls";
import * as strings from "../../strings";
import {
  MembershipTemplate_CreateAPI,
  MembershipTemplateManager,
} from "../../models/membership-template";
import AddTemplatePage from "./AddTemplatePage";
import { getTemplateFormSections } from "./template-utils";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import AddEditTaskGenerationDialog from "./AddEditTaskGenerationDialog";
import { typesensePriceBookItemsQuery } from "../../utils/typesenseQueries";
import { ExistingPriceBookItem } from "../../models/price-book-item";
import {
  DialogStates,
  TemplateFormState,
  TemporaryTaskGeneration,
} from "./types";

interface Props {
  siteKey: string;
}

export default function AddTemplateContainer({ siteKey }: Props) {
  const navigate = useNavigate();
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const addMessage = useToastMessageStore((state) => state.addToastMessage);
  const fetchMembershipTemplateList = useMembershipTemplatesStore(
    (state) => state.fetch,
  );
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const [typesenseSearchKey, typesenseLoading] = useTypesenseStore((state) => [
    state.scopedSearchKey,
    state.loading,
  ]);
  const [siteKeyDoc, siteKeyDocIsLoading] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);
  const siteKeyLocationList = useSiteKeyLocationsStore(
    (state) => state.siteKeyLocationList,
  );

  // States
  const [typesensePBIs, setTypesensePBIs] = useState<ExistingPriceBookItem[]>(
    [],
  );
  const [dialogStates, setDialogStates] = useState<DialogStates>({
    pbi: false,
    renewalPBI: false,
    taskGen: false,
  });
  const [pbi, setPBI] = useState<ExistingPriceBookItem | null>(null);
  const [renewalPBI, setRenewalPBI] = useState<ExistingPriceBookItem | null>(
    null,
  );
  const [taskGenList, setTaskGenList] = useState<TemporaryTaskGeneration[]>([]);
  const [taskGenDoc, setTaskGenDoc] = useState<TemporaryTaskGeneration | null>(
    null,
  );

  // Effects and callbacks
  useEffect(() => {
    async function getPBItems() {
      if (!typesenseSearchKey) return;
      const pbItems = await typesensePriceBookItemsQuery(
        typesenseSearchKey,
        "",
      );
      setTypesensePBIs(pbItems);
    }
    getPBItems();
  }, [typesenseSearchKey]);

  const onFilterTextBoxChanged = useCallback(
    async (searchTerm: string) => {
      if (!typesenseSearchKey) return;
      const pbItems = await typesensePriceBookItemsQuery(
        typesenseSearchKey,
        searchTerm,
      );
      setTypesensePBIs(pbItems);
    },
    [typesenseSearchKey],
  );

  // Mutations
  const mutateAddTemplate = useMutation(
    async (template: MembershipTemplate_CreateAPI) => {
      await DbWrite.membershipTemplates.create(template);
    },
  );

  // Functions
  function goToTemplatesPage() {
    navigate(`${MEMBERSHIP_TEMPLATES}`);
    resetPricebookItems();
    setTaskGenList([]);
  }

  function resetPricebookItems() {
    setPBI(null);
    setRenewalPBI(null);
  }

  async function handleCreateTemplate(formValues: TemplateFormState) {
    if (!firebaseUser) {
      logger.error("firebaseUser is null");
      return;
    }
    if (!pbi) {
      addMessage({
        id: createToastMessageID(),
        message: strings.SELECT_PBI,
        dialog: false,
        type: "error",
      });
      return;
    }

    const template: MembershipTemplate_CreateAPI = {
      ...formValues,
      delayedStartDaysAllowed: 0,
      deleted: false,
      taskGeneration: taskGenList, // zod's default behavior is to strip unknown properties. so the temporary ids will be removed.
      priceBookItemID: pbi.id,
      price: pbi.unitPrice,
      renewalPriceBookItemID: renewalPBI?.id ?? null,
      createdBy: firebaseUser.uid,
      lastModifiedBy: firebaseUser.uid,
      siteKey: siteKey,
      automaticallyGenerateTasks:
        taskGenList.length === 0
          ? false
          : formValues.automaticallyGenerateTasks,
    };
    const validTemplate = MembershipTemplateManager.parseCreate(template);
    try {
      await mutateAddTemplate.mutateAsync(validTemplate);
      goToTemplatesPage();
      if (siteKeyDoc && userPermissions) {
        fetchMembershipTemplateList(siteKeyDoc, userPermissions);
      }
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulAdd(validTemplate.title),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(error);
      addMessage({
        id: createToastMessageID(),
        message: strings.failedCreate("membership template"),
        dialog: false,
        type: "error",
      });
    }
  }

  const { pbiComponent, renewalPBIComponent, taskGenComponent } =
    getTemplateFormSections({
      pbi,
      renewalPBI,
      dialogStates,
      setDialogStates,
      onFilterTextBoxChanged,
      typesensePBIs,
      siteKeyDoc,
      setPBI,
      setRenewalPBI,
      taskGenerationList: taskGenList,
      setTaskGenerationList: setTaskGenList,
      setTaskGenerationDoc: setTaskGenDoc,
    });

  // Components
  const taskGenDialog = siteKeyDoc && (
    <AddEditTaskGenerationDialog
      isDialogOpen={dialogStates.taskGen}
      closeDialog={() =>
        setDialogStates((prev) => ({ ...prev, taskGen: false }))
      }
      taskGenerationDoc={taskGenDoc}
      siteKeyDoc={siteKeyDoc}
      siteKeyLocationList={siteKeyLocationList}
      handleAddNewTask={(task) => {
        const copyOfPrevList = [...taskGenList];
        copyOfPrevList.push({ ...task, id: uuidv4() });
        setTaskGenList(copyOfPrevList);
      }}
      handleEditTask={(editTask) => {
        const copyOfTaskGenList = [...taskGenList];
        const indexToBeEdited = copyOfTaskGenList.findIndex(
          (task) => task.id === editTask.id,
        );
        copyOfTaskGenList[indexToBeEdited] = editTask;
        setTaskGenList(copyOfTaskGenList);
      }}
    />
  );

  if (typesenseLoading || siteKeyDocIsLoading) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  return (
    <>
      <AddTemplatePage
        onSubmit={handleCreateTemplate}
        onCancel={goToTemplatesPage}
      >
        {{
          pbiSection: pbiComponent,
          renewalPBISection: renewalPBIComponent,
          taskGenSection: taskGenComponent,
        }}
      </AddTemplatePage>
      {taskGenDialog}
    </>
  );
}
