//Libs
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useMutation } from "react-query";
import { DbRead, DbWrite } from "../../database";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import {
  CalendarDate,
  CalendarDateTime,
  getLocalTimeZone,
  toCalendarDate,
  today,
  ZonedDateTime,
} from "@internationalized/date";
import { RangeValue } from "@react-types/shared";
import { DocumentData } from "@firebase/firestore";

//Local
import InvoiceListPage, { InvoiceActionTypes } from "./InvoiceListPage";
import { CUSTOMERS_URL, WORK_RECORD_AND_TASKS_URL } from "../../urls";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import {
  ExistingStiltInvoice,
  OStiltInvoiceStatus,
  StiltInvoice_UpdateAPI,
  StiltInvoiceManager,
  StiltInvoiceStatus,
  StiltInvoiceStatusValues,
  TemplatePaymentTerm,
} from "../../models/invoice";
import { useNavToViewEstimate } from "../../navigation";
import { ExistingCustomer } from "../../models/customer";
import { ExistingCustomerLocation } from "../../models/customer-location";
import { logger } from "../../logging";
import HandlePaymentDialog from "../../components/Invoices/HandlePaymentDialog";
import { useToastMessageStore } from "../../store/toast-messages";
import {
  convertFSTimestampToLuxonDT,
  createToastMessageID,
  getRoundedCurrency,
} from "../../utils";
import * as strings from "../../strings";
import DropdownSelectionInvoiceList from "../../components/Invoices/DropdownSelectionInvoiceList";
import InvoiceDetailsBox from "../../components/Invoices/InvoiceDetailsBox";
import RecordManualPaymentDialog, {
  NewManualPayment,
  RecordManualPaymentDialogProps,
} from "../../components/Invoices/RecordManualPaymentDialog";
import { SchedulingButton } from "../Scheduling/SchedulingContainer";
import {
  APIPaymentSavedCard,
  CreateMultiPayment,
  ExistingStiltPayment,
  paymentMethods,
  StiltPayment_CreateAPI,
  StiltPaymentManager,
} from "../../models/stilt-payment";
import { useAuthStore } from "../../store/firebase-auth";
import { generatePaymentUniqueLink } from "../../assets/js/generatePaymentUniqueLink";
import ViewInvoiceDialog, {
  ViewInvoiceDialogProps,
} from "../../components/Payments/ViewInvoiceDialog";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { useUserPermissionsStore } from "../../store/user-permissions";
import RADateRangePicker from "../../components/DateRangePicker/RADateRangePicker";
import EditInvoiceDialog from "../../components/Invoices/EditInvoiceDialog";
import { diffObjects } from "../../assets/js/object-diff";
import { generatePDF } from "../../components/Invoices/generatePDF";
import { getEmailList } from "../../utils/getEmailList";
import BaseInputSelect from "../../components/BaseInputSelect";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import { useUserDisplayNamesStore } from "../../store/user-display-names-map";
import HandleSendEmailDialog from "../../components/estimates/HandleSendEmailDialog";
import currencyFormatter from "../../currency";
import MultiPayCreditCardDialog, {
  MultiPayCreditCardDialogProps,
} from "../../components/Invoices/MultiPayCreditCardConfirmationDialog";
import { generateMultiPaymentUniqueLink } from "../../assets/js/generateMultiPaymentUniqueLink";
import ConfirmationDialogForInvoiceActions, {
  ConfirmationDialogForInvoiceActionsProps,
} from "../../components/ConfirmationDialogForInvoiceActions";

interface Props {
  siteKey: string;
}

export default function InvoiceListContainer({ siteKey }: Props) {
  const navToViewEstimate = useNavToViewEstimate();
  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  const firebaseUser = useAuthStore((state) => state.firebaseUser);
  const [siteKeyDoc, siteKeyDocLoading] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const [siteKeyLocationList, siteKeyLocationListIsLoading, getLocationTitle] =
    useSiteKeyLocationsStore((state) => [
      state.siteKeyLocationList,
      state.loading,
      state.getLocationTitle,
    ]);
  const [userDisplayNamesMap, userDisplayNamesMapIsLoading] =
    useUserDisplayNamesStore((state) => [
      state.userDisplayNames,
      state.loading,
    ]);

  /* USE STATES */
  const [isHandlePaymentDialogOpen, setIsHandlePaymentDialogOpen] =
    useState(false);
  const [isTableHandlePaymentDialogOpen, setIsTableHandlePaymentDialogOpen] =
    useState(false);
  const [stiltInvoiceID, setStiltInvoiceID] = useState<
    ExistingStiltInvoice["id"] | null
  >(null);
  const [stiltInvoiceStatusSelection, setStiltInvoiceStatusSelection] =
    useState<StiltInvoiceStatusValues | null>(null);
  // const [selectedDateTime, setSelectedDateTime] = useState<DateTime>(
  //   DateTime.now(),
  // );
  const [isViewInvoiceDialogOpen, setIsViewInvoiceDialogOpen] = useState(false);
  const [isGeneratingCustomerStatements, setIsGeneratingCustomerStatements] =
    useState(false);
  const [viewInvoiceDialogProps, setViewInvoiceDialogProps] =
    useState<ViewInvoiceDialogProps | null>(null);

  const [bulkManualPaymentDialogProps, setBulkManualPaymentDialogProps] =
    useState<RecordManualPaymentDialogProps | null>(null);
  const [multiPayCreditCardDialogProps, setMultiPayCreditCardDialogProps] =
    useState<MultiPayCreditCardDialogProps | null>(null);

  // Set the list of invoices to the list of invoices in the date range.
  const [invoiceListOptions, setInvoiceListOptions] = useState<
    ExistingStiltInvoice[]
  >([]);
  const [paymentsList, setPayments] = useState<ExistingStiltPayment[]>([]);
  const selectedInvoice = invoiceListOptions.find(
    (invoice) => invoice.id === stiltInvoiceID,
  );
  const [currentCustomer, setCurrentCustomer] =
    useState<ExistingCustomer | null>(null);

  const [editInvoiceDialogOpen, setEditInvoiceDialogOpen] = useState(false);

  const [selectedDueDate, setSelectedDueDate] = useState<DateTime | null>(null);
  const [selectedIssueDate, setSelectedIssueDate] = useState<DateTime>(
    DateTime.now(),
  );
  const [paymentTerms, setPaymentTerms] = useState<
    ExistingStiltInvoice["paymentTerms"] | null
  >(null);

  const templatesPaymentTerms: Record<string, TemplatePaymentTerm> =
    siteKeyDoc?.customizations.paymentTerms ?? {};

  useEffect(() => {
    if (selectedInvoice) {
      if (selectedInvoice.dueDate != null) {
        setSelectedDueDate(
          convertFSTimestampToLuxonDT(selectedInvoice.dueDate),
        );
      }
      if (selectedInvoice.issueDate != null) {
        setSelectedIssueDate(
          convertFSTimestampToLuxonDT(selectedInvoice.issueDate),
        );
      }
    }
  }, [selectedInvoice]);

  // Retrieve customer from selected invoice. For paying with a saved card.
  useEffect(() => {
    async function getCustomer() {
      if (!selectedInvoice) return;

      const customer = await DbRead.customers.get(
        siteKey,
        selectedInvoice.customerID,
      );
      setCurrentCustomer(customer);
    }
    getCustomer();
  }, [selectedInvoice, siteKey]);

  // State for InvoiceDateRangePicker, defaults to last 30 days
  const [range, setRange] = useState<RangeValue<CalendarDate>>({
    start: today(getLocalTimeZone()).subtract({
      days: siteKeyDoc?.customizations?.defaultInvoicePageDateRangeDays ?? 30,
    }),
    end: today(getLocalTimeZone()),
  });

  const [actionsLoading, setActionsLoading] = useState(false);

  const [confirmationDialogProps, setConfirmationDialogProps] =
    useState<ConfirmationDialogForInvoiceActionsProps>({
      invoiceActionType: "",
      body: "",
      isOpen: false,
      handleConfirmAction: () => {},
      title: "",
      isSubmitting: false,
      onClose: () => {},
      pdfBatchActionButtons: false,
      pendingInvoiceIDsLength: null,
    });

  const [isEmailReceiptDialogOpen, setIsEmailReceiptDialogOpen] =
    useState(false);
  const [customerDoc, setCustomerDoc] = useState<ExistingCustomer | null>(null);

  const emailList = selectedInvoice
    ? getEmailList(selectedInvoice, customerDoc)
    : [];
  const [isGetInvoices, setIsGetInvoices] = useState(false);

  // This function is called when the date range changes.
  // Converts the date range to CalendarDate objects and sets the range state.
  // The React-Aria DateRangePicker component requires its "onChange"
  // to use "RangeValue<CalendarDate | ZonedDateTime | CalendarDateTime>"
  // objects (not just "RangeValue<CalendarDate>" objects) and doesn't work
  // without this conversion.
  function handleDateRangeChange(
    value: RangeValue<CalendarDate | ZonedDateTime | CalendarDateTime>,
  ) {
    logger.debug(
      `Invoked handleDateRangeChange for range: ${value.start.month}-${value.start.day} to ${value.end.month}-${value.end.day}`,
    );
    try {
      const convertedStart = toCalendarDate(value.start);
      const convertedEnd = toCalendarDate(value.end);
      const converted = { start: convertedStart, end: convertedEnd };
      setRange(converted);
      logger.debug(
        `set range to ${converted.start.month}-${converted.start.day} to ${converted.end.month}-${converted.end.day}`,
      );
    } catch (e) {
      // TODO: Handle this error better
      if (e instanceof Error) {
        console.log("Error in handleDateRangeChange", e);
        logger.error("Error in handleDateRangeChange", e);
      }
    }
  }

  // Fetch the list of payments when this component loads.
  useEffect(() => {
    async function getPayments() {
      logger.debug("getPayments useEffect called ");
      // Query payments via realtime updates. Set the list of invoices.
      const unsubscribeAllPaymentsInDateRange =
        DbRead.payments.subscribeInDateRange({
          siteKey: siteKey,
          dateRange: range,
          onChange: setPayments,
          onError: (error) =>
            logger.error(`Error in getPayments: ${error.message}`),
        });
      return unsubscribeAllPaymentsInDateRange;
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    const unsubscribePromise = getPayments();
    // Return an anonymous ƒn for cleanup.
    return () => {
      logger.debug(
        "(InvoiceListContainer) getPayments useEffect return ƒn just invoked.",
      );
      unsubscribePromise.then((unsubscribe) => {
        if (unsubscribe) {
          logger.debug("Now unsubscribing from payments.");
          unsubscribe();
        }
      });
      logger.debug("Done running cleanup ƒn for getPayments useEffect.");
    };
  }, [siteKey, range]);

  // Fetch the list of invoices when this component loads.
  useEffect(() => {
    async function getInvoices() {
      logger.debug("getInvoices useEffect called ");
      // Query invoices via realtime updates. Set the list of invoices.
      const unsubscribeAllInvoicesInDateRange =
        DbRead.invoices.subscribeAllInvoicesInDateRange({
          siteKey: siteKey,
          dateRange: range,
          onChange: (stiltInvoiceList: ExistingStiltInvoice[]) => {
            setInvoiceListOptions(stiltInvoiceList), setIsGetInvoices(false);
          },
          onError: (error) =>
            logger.error(`Error in getInvoices: ${error.message}`),
        });
      return unsubscribeAllInvoicesInDateRange;
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    setIsGetInvoices(true);
    const unsubscribePromise = getInvoices();
    // Return an anonymous ƒn for cleanup.
    return () => {
      logger.debug(
        "(InvoiceListContainer) getInvoices useEffect return ƒn just invoked.",
      );
      unsubscribePromise.then((unsubscribe) => {
        if (unsubscribe) {
          logger.debug("Now unsubscribing from invoices.");
          unsubscribe();
        }
      });
      logger.debug("Done running cleanup ƒn for getInvoices useEffect.");
    };
  }, [siteKey, range]);

  const invoiceStatus = [null, ...Object.values(OStiltInvoiceStatus)];

  const filteredListOfInvoices = filterInvoicesByDropdownSelection(
    stiltInvoiceStatusSelection,
    invoiceListOptions,
  );

  /* HISTORY */
  const navigate = useNavigate();

  function goToCustomerPage(customerId: string) {
    navigate(`${CUSTOMERS_URL}/${customerId}`);
  }

  function goToWorkRecordAndTasksPage(
    craftRecordID: ExistingStiltInvoice["craftRecordID"],
  ) {
    navigate(`${WORK_RECORD_AND_TASKS_URL}/${craftRecordID}`);
  }

  function goToPaymentPage(paymentLink: string) {
    window.open(paymentLink, "_blank");
  }

  async function openEditInvoiceDialog() {
    if (selectedInvoice) {
      setEditInvoiceDialogOpen(true);
    }
  }

  function handlePaymentTermsChange(
    event: React.ChangeEvent<HTMLSelectElement>,
  ) {
    const value = event.target.value;
    if (value === "") {
      setPaymentTerms(null);
    } else {
      setPaymentTerms(value);
      const selectedPaymentTermTemplate = Object.entries(
        templatesPaymentTerms,
      ).find(([key, _]) => key === value);
      if (!selectedPaymentTermTemplate) return;
      const newDueDate = selectedIssueDate.plus({
        days: selectedPaymentTermTemplate[1].daysUntilDue,
      });
      setSelectedDueDate(newDueDate);
    }
  }

  async function goToViewEstimate(
    estimateID: string,
    customerID: string,
    customerLocationID: string,
    invoiceID: string,
  ) {
    const promises = [
      DbRead.customers.get(siteKey, customerID),
      DbRead.customerLocations.getSingle(siteKey, customerLocationID),
      DbRead.invoices.getSingle(siteKey, invoiceID),
    ];
    const [customerDoc, customerLocationDoc, invoiceDoc] =
      await Promise.all(promises);

    if (estimateID && customerDoc && customerLocationDoc) {
      navToViewEstimate(
        estimateID,
        customerDoc as ExistingCustomer,
        customerLocationDoc as ExistingCustomerLocation,
        invoiceDoc as ExistingStiltInvoice,
      );
    }
  }

  /* MUTATIONS */

  const mutateRecordManualPayment = useMutation(
    async (args: { paymentData: StiltPayment_CreateAPI }) => {
      await DbWrite.payments.manualPayment(args.paymentData);
    },
  );

  const mutateRecordBulkPayment = useMutation(
    async (args: { payments: CreateMultiPayment[]; siteKey: string }) => {
      await DbWrite.payments.manualBulkPayment(args);
    },
  );

  const mutateUpdateInvoice = useMutation(
    async (args: { validPartialInvoiceData: StiltInvoice_UpdateAPI }) => {
      await DbWrite.invoices.update(args.validPartialInvoiceData);
    },
  );

  /* FUNCTIONS */

  function onClickHandlePaymentButton(invoiceID: ExistingStiltInvoice["id"]) {
    setIsHandlePaymentDialogOpen(true);
    setStiltInvoiceID(invoiceID);
  }

  function onClickHandlePaymentTableRow(invoiceID: ExistingStiltInvoice["id"]) {
    setIsTableHandlePaymentDialogOpen(true);
    setStiltInvoiceID(invoiceID);
  }

  async function handleGoToPaymentPage(): Promise<void> {
    if (!stiltInvoiceID) return;
    const paymentLink = await generatePaymentUniqueLink(
      siteKey,
      stiltInvoiceID,
      "web",
    );
    if (paymentLink != null) {
      goToPaymentPage(paymentLink);
    } else {
      addMessage({
        id: createToastMessageID(),
        message: strings.ERROR_PAYMENT_LINK,
        type: "error",
        dialog: false,
      });
    }
  }

  /** send itemized invoice along with payment link */
  async function emailInvoice(
    email: string[],
    includeJobPhotos: boolean,
  ): Promise<void> {
    if (!stiltInvoiceID) return;

    if (!selectedInvoice) {
      addMessage({
        id: createToastMessageID(),
        message: strings.ERR_EMAILING_INVOICE,
        dialog: false,
        type: "error",
      });
      return;
    }
    if (email.length === 0) {
      addMessage({
        id: createToastMessageID(),
        message: strings.NO_EMAIL_FOR_CUSTOMER,
        dialog: false,
        type: "error",
      });
      return;
    }

    const paymentLink = await generatePaymentUniqueLink(
      siteKey,
      stiltInvoiceID,
      "email",
    );
    if (!paymentLink) {
      logger.error("emailInvoice: missing invoice link");
      addMessage({
        id: createToastMessageID(),
        message: strings.ERR_GEN_INVOICE_LINK,
        dialog: false,
        type: "error",
      });
      return;
    }

    try {
      await DbWrite.invoices.sendViaEmail({
        siteKey,
        invoiceURL: paymentLink,
        customerEmailList: email,
        includeJobPhotos: includeJobPhotos,
      });
      addMessage({
        id: createToastMessageID(),
        message: strings.EMAILED_CUSTOMER_INVOICE,
        dialog: false,
        type: "success",
      });
      setStiltInvoiceID(null);
    } catch (e) {
      addMessage({
        id: createToastMessageID(),
        message: strings.ERR_EMAILING_INVOICE,
        dialog: false,
        type: "error",
      });
      logger.error("error emailing customer invoice", e);
      setStiltInvoiceID(null);
    }
  }

  async function sendEmailFromHandlePaymentDialog(
    emails: string[],
    shouldIncludePhotos: boolean,
  ): Promise<void> {
    if (!selectedInvoice) return;
    if (selectedInvoice.amountDue > 0) {
      await emailInvoice(emails, shouldIncludePhotos);
    } else {
      await handleSendEmailReceipt(emails);
    }
  }

  async function openEmailReceiptDialog(invoiceID: string): Promise<void> {
    setStiltInvoiceID(invoiceID);
    const invoice = invoiceListOptions.find(
      (invoice) => invoice.id === invoiceID,
    );
    if (!invoice) return;
    setIsEmailReceiptDialogOpen(true);
    const selectedCustomerDoc = await DbRead.customers.get(
      siteKey,
      invoice.customerID,
    );
    setCustomerDoc(selectedCustomerDoc);
  }

  async function handleGenerateCustomerStatements(): Promise<void> {
    setIsGeneratingCustomerStatements(true);
    const url = await DbRead.customers.generateBatchCustomerStatements(siteKey);
    setIsGeneratingCustomerStatements(false);
    window.open(url, "_blank")?.focus();
  }

  async function handleSendEmailReceipt(
    emailAddresses: string[],
  ): Promise<void> {
    if (!stiltInvoiceID) return;
    const invoice = invoiceListOptions.find(
      (invoice) => invoice.id === stiltInvoiceID,
    );

    if (!invoice) {
      const idList = invoiceListOptions.map((inv) => inv.id);
      logger.error(
        `Invoice ID: ${stiltInvoiceID}. SiteKey: ${siteKey}. Couldn't locate invoice using invoiceListOptions, ids are: ${idList}`,
      );
      addMessage({
        id: createToastMessageID(),
        message: strings.ERR_EMAILING_RECEIPT_NOTIFIED,
        type: "error",
        dialog: false,
      });
      return;
    }

    if (emailAddresses.length === 0 || !emailAddresses) {
      addMessage({
        id: createToastMessageID(),
        message: strings.NO_EMAIL_FOR_CUSTOMER,
        dialog: false,
        type: "error",
      });
      return;
    }

    try {
      await DbWrite.payments.emailReceipt({
        siteKeyID: siteKey,
        invoiceID: stiltInvoiceID,
        customerEmailList: emailAddresses,
      });
      addMessage({
        id: createToastMessageID(),
        message: strings.EMAILED_CUSTOMER_RECEIPT,
        dialog: false,
        type: "success",
      });
    } catch (e) {
      logger.error("Failed to email receipt to customer", e);
      addMessage({
        id: createToastMessageID(),
        message: strings.ERR_EMAILING_RECEIPT_NOTIFIED,
        dialog: false,
        type: "error",
      });
    }
  }

  async function handleGetPDF(invoiceID: string) {
    if (siteKeyDoc) {
      await generatePDF.invoice(
        siteKeyDoc,
        [invoiceID],
        true,
        siteKeyDoc?.customizations.defaultIncludeJobPhotos ?? false,
        userPermissions,
      );
    }
  }

  async function handleGetPDFBatch(
    invoiceIDs: string[],
    includeJobPhotos: boolean,
    displayPaymentLink: boolean,
  ) {
    if (siteKeyDoc) {
      await generatePDF.invoice(
        siteKeyDoc,
        invoiceIDs,
        displayPaymentLink,
        includeJobPhotos,
        userPermissions,
      );
    }
  }

  async function handleChangeInvoiceStatusBatch(
    invoiceIDs: string[],
    newStatus: StiltInvoiceStatus,
  ) {
    if (siteKeyDoc) {
      await DbWrite.invoices.batchUpdateStatus(
        siteKeyDoc.id,
        invoiceIDs,
        newStatus,
      );
    }
  }

  async function handleInvoiceActionSelected(
    invoiceActionType: InvoiceActionTypes,
    selectedRows: ExistingStiltInvoice[],
  ) {
    if (invoiceActionType === "convertDraftToPending") {
      const invoiceIDs: any[] = [];
      for (const row of selectedRows) {
        // Only send if there's an email
        if (row.status === "draft") {
          invoiceIDs.push(row.id);
        }
      }
      setConfirmationDialogProps({
        invoiceActionType: "convertDraftToPending",
        body: `Of the ${selectedRows.length} invoices selected, ${invoiceIDs.length} will be converted to Pending. Confirm changing ${invoiceIDs.length} invoices to Pending?`,
        title: "Convert Invoices To Pending Status?",
        isSubmitting: false,
        isOpen: true,
        pdfBatchActionButtons: false,
        pendingInvoiceIDsLength: null,
        onClose: () =>
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            isOpen: false,
          }),
        handleConfirmAction: async () => {
          setActionsLoading(true);
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            isOpen: false,
          });
          try {
            await handleChangeInvoiceStatusBatch(
              invoiceIDs,
              StiltInvoiceStatus.PENDING,
            );
            addMessage({
              id: createToastMessageID(),
              message: `Invoices converted to Pending`,
              dialog: false,
              type: "success",
            });
          } catch (e) {
            addMessage({
              id: createToastMessageID(),
              message: `Error converting invoices to pending`,
              dialog: false,
              type: "warning",
            });
          } finally {
            setActionsLoading(false);
          }
        },
      });
    }
    if (invoiceActionType === "convertZeroAmountInvoicesToPaid") {
      const invoiceIDs: any[] = [];
      for (const row of selectedRows) {
        // Only allow this if invoice has no amountDue and is not canceled
        if (row.status !== "canceled" && row.amountDue === 0) {
          invoiceIDs.push(row.id);
        }
      }
      setConfirmationDialogProps({
        invoiceActionType: "convertZeroAmountInvoicesToPaid",
        body: `Of the ${selectedRows.length} invoices selected, ${invoiceIDs.length} will be converted to Paid. Confirm changing ${invoiceIDs.length} invoices to Paid?`,
        title: "Convert Invoices To Paid Status?",
        isSubmitting: false,
        isOpen: true,
        pdfBatchActionButtons: false,
        pendingInvoiceIDsLength: null,
        onClose: () =>
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            isOpen: false,
          }),
        handleConfirmAction: async () => {
          setActionsLoading(true);
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            isOpen: false,
          });
          try {
            await handleChangeInvoiceStatusBatch(
              invoiceIDs,
              StiltInvoiceStatus.PAID,
            );
            addMessage({
              id: createToastMessageID(),
              message: `Invoices converted to Paid`,
              dialog: false,
              type: "success",
            });
          } catch (e) {
            addMessage({
              id: createToastMessageID(),
              message: `Error converting invoices to paid`,
              dialog: false,
              type: "warning",
            });
          } finally {
            setActionsLoading(false);
          }
        },
      });
    }
    if (invoiceActionType === "generatePDF") {
      setActionsLoading(true);
      const invoiceIDs: string[] = [];
      const pendingInvoiceIDs: string[] = [];
      for (const row of selectedRows) {
        if (typeof row.id === "string") {
          invoiceIDs.push(row.id);
        }
        if (row.status === StiltInvoiceStatus.PENDING) {
          pendingInvoiceIDs.push(row.id);
        }
      }
      if (pendingInvoiceIDs.length > 0) {
        setConfirmationDialogProps({
          invoiceActionType: "generatePDF",
          body: `Of the ${selectedRows.length} invoices selected, ${pendingInvoiceIDs.length} are still in Pending status. Would you like to change these invoices to Submitted?`,
          title: "Generate PDF",
          isSubmitting: false,
          isOpen: true,
          pdfBatchActionButtons: true,
          pendingInvoiceIDsLength: pendingInvoiceIDs.length,
          onClose: () =>
            setConfirmationDialogProps({
              ...confirmationDialogProps,
              isOpen: false,
            }),
          handleConfirmAction: async (
            status: StiltInvoiceStatus | null,
            includeJobPhotos: boolean,
            displayPaymentLink: boolean,
          ) => {
            setActionsLoading(true);
            setConfirmationDialogProps({
              ...confirmationDialogProps,
              isOpen: false,
            });
            if (status === StiltInvoiceStatus.SUBMITTED) {
              try {
                await handleChangeInvoiceStatusBatch(
                  pendingInvoiceIDs,
                  StiltInvoiceStatus.SUBMITTED,
                );
                addMessage({
                  id: createToastMessageID(),
                  message: `Invoices set to submitted`,
                  dialog: false,
                  type: "success",
                });
              } catch (e) {
                addMessage({
                  id: createToastMessageID(),
                  message: `Error converting invoices to submitted`,
                  dialog: false,
                  type: "warning",
                });
              } finally {
                await handleGetPDFBatch(
                  invoiceIDs,
                  includeJobPhotos,
                  displayPaymentLink,
                );
                setActionsLoading(false);
              }
            } else {
              await handleGetPDFBatch(
                invoiceIDs,
                includeJobPhotos,
                displayPaymentLink,
              );
              setActionsLoading(false);
            }
          },
        });
      } else {
        setActionsLoading(true);
        setConfirmationDialogProps({
          invoiceActionType: "generatePDF",
          body: ``,
          title: "Generate PDF",
          isSubmitting: false,
          isOpen: true,
          pdfBatchActionButtons: true,
          pendingInvoiceIDsLength: null,
          onClose: () =>
            setConfirmationDialogProps({
              ...confirmationDialogProps,
              isOpen: false,
            }),
          handleConfirmAction: async (
            status: StiltInvoiceStatus | null,
            includeJobPhotos: boolean,
            displayPaymentLink: boolean,
          ) => {
            setActionsLoading(true);
            setConfirmationDialogProps({
              ...confirmationDialogProps,
              isOpen: false,
            });
            if (status === StiltInvoiceStatus.SUBMITTED) {
              try {
                await handleChangeInvoiceStatusBatch(
                  pendingInvoiceIDs,
                  StiltInvoiceStatus.SUBMITTED,
                );
                addMessage({
                  id: createToastMessageID(),
                  message: `Invoices set to submitted`,
                  dialog: false,
                  type: "success",
                });
              } catch (e) {
                addMessage({
                  id: createToastMessageID(),
                  message: `Error converting invoices to submitted`,
                  dialog: false,
                  type: "warning",
                });
              } finally {
                await handleGetPDFBatch(
                  invoiceIDs,
                  includeJobPhotos,
                  displayPaymentLink,
                );
                setActionsLoading(false);
              }
            } else {
              await handleGetPDFBatch(
                invoiceIDs,
                includeJobPhotos,
                displayPaymentLink,
              );
              setActionsLoading(false);
            }
          },
        });
        setActionsLoading(false);
      }

      setActionsLoading(false);
    }
    if (invoiceActionType === "sendEmailInvoice") {
      const invoicesToEmail: any[] = [];
      for (const row of selectedRows) {
        // Only send if there's an email
        if (typeof row.email === "string") {
          invoicesToEmail.push(row);
        }
      }
      setConfirmationDialogProps({
        invoiceActionType: "sendEmailInvoice",
        body: `Of the ${selectedRows.length} invoices selected, ${invoicesToEmail.length} have email addresses.\n\nConfirm sending ${invoicesToEmail.length} invoices via email?\n\nThis will also update Draft and Pending invoices to Submitted status`,
        title: "Send Invoices Via Email?",
        isSubmitting: false,
        isOpen: true,
        pdfBatchActionButtons: true,
        pendingInvoiceIDsLength: null,
        onClose: () =>
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            isOpen: false,
          }),
        handleConfirmAction: async () => {
          setActionsLoading(true);
          setConfirmationDialogProps({
            ...confirmationDialogProps,
            isOpen: false,
          });
          const invoicesWithError = [];
          for (const invoice of invoicesToEmail) {
            const paymentLink = await generatePaymentUniqueLink(
              siteKey,
              invoice.id,
              "email",
            );
            if (!paymentLink) {
              invoicesWithError.push(invoice);
            } else {
              try {
                // await new Promise((r) => setTimeout(r, 500));
                await DbWrite.invoices.sendViaEmail({
                  siteKey,
                  invoiceURL: paymentLink,
                  customerEmailList: [invoice.email],
                  includeJobPhotos: false, //TODO: CHANGE ME
                });
              } catch (_e) {
                invoicesWithError.push(invoice);
              }
            }
          }
          setActionsLoading(false);
          if (invoicesWithError.length === 0) {
            addMessage({
              id: createToastMessageID(),
              message: `Sent invoices to ${invoicesToEmail.length} customers`,
              dialog: false,
              type: "success",
            });
          } else {
            addMessage({
              id: createToastMessageID(),
              message: `Error sending ${invoicesWithError.length} invoices`,
              dialog: false,
              type: "warning",
            });
          }
        },
      });
    }
    if (invoiceActionType === "applyManualPayment") {
      if (!siteKeyDoc) return;
      const invoicesToApplyPaymentTo: ExistingStiltInvoice[] = [];
      for (const row of selectedRows) {
        // exclude draft, canceled, and paid invoices
        if (
          row.status !== "paid" &&
          row.status !== "draft" &&
          row.status !== "canceled"
        ) {
          invoicesToApplyPaymentTo.push(row);
        }
      }

      let totalAmount = 0;
      for (const inv of invoicesToApplyPaymentTo) {
        if (inv.amountDue > 0) {
          totalAmount += inv.amountDue;
        } else {
          // remove inv from list
          invoicesToApplyPaymentTo.splice(
            invoicesToApplyPaymentTo.indexOf(inv),
            1,
          );
        }
      }
      totalAmount = getRoundedCurrency(totalAmount);

      if (invoicesToApplyPaymentTo.length === 0) {
        addMessage({
          id: createToastMessageID(),
          message:
            "No valid invoices to apply payment to. Only invoices that are in Pending, Submitted, or Partially Paid status can be paid with a multi-invoice payment.",
          dialog: false,
          type: "warning",
        });
        return;
      }

      const siteKeyCurrency =
        typeof siteKeyDoc.customizations.accounting.currency === "string"
          ? siteKeyDoc.customizations.accounting.currency
          : "USD";

      const invoiceMessage = `You are about to apply a payment of ${currencyFormatter(totalAmount, siteKeyCurrency)} to the preceding invoices. Please note that receipts must be sent manually after payment is applied.`;

      setBulkManualPaymentDialogProps({
        isDialogOpen: true,
        closeDialog: () => setBulkManualPaymentDialogProps(null),
        siteKeyDoc: siteKeyDoc,
        amountDue: totalAmount,
        onRecordManualPayment: async (
          formValues: NewManualPayment,
          timestampPaymentMade: DateTime,
        ) => {
          await handleRecordManualBulkPayment(
            invoicesToApplyPaymentTo,
            formValues,
            timestampPaymentMade,
          );
        },
        children: {
          multiInvoiceList: (
            <ul>
              {invoicesToApplyPaymentTo.map((inv) => (
                <li key={inv.id}>
                  Invoice Number: {inv.invoiceNumber ?? "Unknown"} -{" "}
                  {currencyFormatter(
                    inv.amountDue,
                    siteKeyDoc?.customizations?.accounting?.currency ?? "USD",
                  )}
                </li>
              ))}
            </ul>
          ),
          multiInvoiceMessage: <p>{invoiceMessage}</p>,
        },
      });
    }
    if (invoiceActionType === "payViaCard") {
      if (!siteKeyDoc) return;
      const invoicesToApplyPaymentTo: ExistingStiltInvoice[] = [];
      for (const row of selectedRows) {
        // exclude draft, canceled, and paid invoices
        if (
          row.status !== "paid" &&
          row.status !== "draft" &&
          row.status !== "canceled"
        ) {
          invoicesToApplyPaymentTo.push(row);
        }
      }

      let totalAmount = 0;
      for (const inv of invoicesToApplyPaymentTo) {
        if (inv.amountDue > 0) {
          totalAmount += inv.amountDue;
        } else {
          // remove inv from list
          invoicesToApplyPaymentTo.splice(
            invoicesToApplyPaymentTo.indexOf(inv),
            1,
          );
        }
      }
      totalAmount = getRoundedCurrency(totalAmount);

      if (invoicesToApplyPaymentTo.length === 0) {
        addMessage({
          id: createToastMessageID(),
          message:
            "No valid invoices to apply payment to. Only invoices that are in Pending, Submitted, or Partially Paid status can be paid with a multi-invoice payment.",
          dialog: false,
          type: "warning",
        });
        return;
      }

      const siteKeyCurrency =
        typeof siteKeyDoc.customizations.accounting.currency === "string"
          ? siteKeyDoc.customizations.accounting.currency
          : "USD";

      const invoiceMessage = `You are about to open a payment link to pay ${currencyFormatter(totalAmount, siteKeyCurrency)} via credit card for the preceding invoices. Please note that receipts must be sent manually after payment is applied.`;

      setMultiPayCreditCardDialogProps({
        isDialogOpen: true,
        closeDialog: () => setMultiPayCreditCardDialogProps(null),
        amountDue: totalAmount,
        onSubmitPayment: async () => {
          await handleGetMultiPaymentCreditCardLink(
            invoicesToApplyPaymentTo,
            multiPayCreditCardDialogProps?.amountDue ?? 0,
            DateTime.now(),
          );
        },
        children: {
          multiInvoiceList: (
            <ul>
              {invoicesToApplyPaymentTo.map((inv) => (
                <li key={inv.id}>
                  Invoice Number: {inv.invoiceNumber ?? "Unknown"} -{" "}
                  {currencyFormatter(
                    inv.amountDue,
                    siteKeyDoc?.customizations?.accounting?.currency ?? "USD",
                  )}
                </li>
              ))}
            </ul>
          ),
          multiInvoiceMessage: <p>{invoiceMessage}</p>,
        },
      });
    }
  }

  function openViewInvoiceDialog(invoiceID: string): void {
    if (!siteKeyDoc) return;

    setViewInvoiceDialogProps({
      siteKeyID: siteKey,
      invoiceID,
      merchantName: siteKeyDoc.name,
      merchantLogoURL:
        siteKeyDoc.customizations.accounting?.merchantLogoURL ?? null,
    });
    setIsViewInvoiceDialogOpen(true);
    setStiltInvoiceID(invoiceID);
  }

  function closeViewInvoiceDialog(): void {
    setIsViewInvoiceDialogOpen(false);
    setViewInvoiceDialogProps(null);
  }

  async function handleRefund(
    paymentID: string,
    refundAmount: number,
  ): Promise<void> {
    // try/catch is in the InvoiceSummary component; don't want it here
    return DbWrite.payments.issueRefund(siteKey, paymentID, refundAmount);
  }

  async function handleSaveUpdatedInvoice(
    updatedInvoice: Partial<ExistingStiltInvoice>,
  ) {
    if (!selectedInvoice) return;

    const updatedInvoiceFields: StiltInvoice_UpdateAPI = {
      ...updatedInvoice,
      paymentTerms: paymentTerms,
      dueDate: selectedDueDate ? selectedDueDate.toString() : null,
      issueDate: selectedIssueDate?.toString(),
    };

    const diffInvoiceValues: DocumentData = diffObjects(
      selectedInvoice,
      updatedInvoiceFields,
    ).diff;

    if (Object.keys(diffInvoiceValues).length === 0) {
      logger.debug("No values have changed");
      return;
    }

    const editInvoiceToValidate: Partial<ExistingStiltInvoice> = {
      ...diffInvoiceValues,
      id: selectedInvoice.id,
      refPath: selectedInvoice.refPath,
    };

    /* validate values before sending to DB */
    const validatedEditInvoice = StiltInvoiceManager.parseUpdate(
      editInvoiceToValidate,
    );
    logger.info("validatedEditInvoice", validatedEditInvoice);

    try {
      await mutateUpdateInvoice.mutateAsync({
        validPartialInvoiceData: validatedEditInvoice,
      });

      logger.debug("Payment terms has been updated successfully.");
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(
          `Payment terms for Invoice #${selectedInvoice.invoiceNumber}`,
        ),
        dialog: false,
        type: "success",
      });
      setIsViewInvoiceDialogOpen(false);
    } catch (error) {
      logger.error(`An error occurred during handleSaveNewPaymentTerms`, error);
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }

    setStiltInvoiceID(null);
  }

  // async function handleSaveUpdatedInvoiceLineItems(lineItems: StiltLineItem[]) {
  //   if (!selectedInvoice) return;

  //   const editPaymentToValidate: Partial<ExistingStiltInvoice> = {
  //     lineItems: lineItems,
  //     id: selectedInvoice.id,
  //     refPath: selectedInvoice.refPath,
  //   };

  //   /* validate values before sending to DB */
  //   const validatedEditInvoice = StiltInvoiceManager.parseUpdate(
  //     editPaymentToValidate,
  //   );
  //   logger.info("validatedEditInvoice", validatedEditInvoice);

  //   try {
  //     await mutateUpdateInvoice.mutateAsync({
  //       validPartialInvoiceData: validatedEditInvoice,
  //     });

  //     logger.debug("Payment terms has been updated successfully.");
  //     addMessage({
  //       id: createToastMessageID(),
  //       message: strings.successfulUpdate(
  //         `Payment terms for Invoice #${selectedInvoice.invoiceNumber}`,
  //       ),
  //       type: "success",
  //     });
  //     setOpenViewPaymentDialog(false);
  //   } catch (error) {
  //     logger.error(`An error occurred during handleSaveNewPaymentTerms`, error);
  //     addMessage({
  //       id: createToastMessageID(),
  //       message: strings.UNEXPECTED_ERROR,
  //       type: "error",
  //     });
  //   }

  //   setStiltInvoiceID(null);
  //   setDefaultPaymentTerms(null);
  // }

  async function applyManualPayment(
    formValues: NewManualPayment,
    timestampPaymentMade: DateTime,
  ): Promise<void> {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    if (!stiltInvoiceID) return;

    if (!selectedInvoice) {
      addMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: true,
        type: "error",
      });
      return;
    }

    const valuesToBeValidated: StiltPayment_CreateAPI = {
      ...formValues,
      stringTimestampPaymentMade: timestampPaymentMade.toISO(),
      customerID: selectedInvoice.customerID,
      invoiceID: stiltInvoiceID,
      customData: {},
      createdBy: firebaseUser.uid,
      siteKey: siteKey,
      billToCustomerID: selectedInvoice.billToCustomerID,
      locationID: selectedInvoice.locationID,
      deleted: false,
    };

    const validPaymentData =
      StiltPaymentManager.parseCreate(valuesToBeValidated);
    logger.info("validPaymentData", validPaymentData);

    try {
      await mutateRecordManualPayment.mutateAsync({
        paymentData: validPaymentData,
      });
      if (
        validPaymentData.amount === selectedInvoice.amountDue &&
        selectedInvoice.status !== "draft" &&
        siteKeyDoc?.customizations.sendAutomatedReceiptToCustomers
      ) {
        if (selectedInvoice.email !== null) {
          addMessage({
            id: createToastMessageID(),
            message: strings.SUCCESS_MANUAL_PAYMENT_WITH_RECEIPT,
            dialog: true,
            type: "success",
          });
        } else {
          addMessage({
            id: createToastMessageID(),
            message: strings.SUCCESS_MANUAL_PAYMENT_NO_EMAIL,
            dialog: true,
            type: "info",
          });
        }
      } else {
        addMessage({
          id: createToastMessageID(),
          message: strings.SUCCESS_MANUAL_PAYMENT_NO_RECEIPT,
          dialog: true,
          type: "success",
        });
      }
    } catch (err) {
      logger.error("handleRecordManualPayment", err);
      addMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: true,
        type: "error",
      });
      return;
    }
  }

  async function handleRecordManualBulkPayment(
    selectedInvoices: ExistingStiltInvoice[],
    formValues: NewManualPayment,
    timestampPaymentMade: DateTime,
  ): Promise<void> {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }

    // Create the array of multi-payments
    const payments: CreateMultiPayment[] = [];
    for (const invoice of selectedInvoices) {
      const valuesToBeValidated: CreateMultiPayment = {
        ...formValues,
        // IMPORTANT: `amount` must be `formValues.amount`. not the invoice amountDue
        timestampCreated: DateTime.now().toISO(),
        timestampPaymentMade: timestampPaymentMade.toISO(),
        customerID: invoice.customerID,
        invoiceID: invoice.id,
        customData: {},
        createdBy: firebaseUser.uid,
        billToCustomerID: invoice.billToCustomerID,
        locationID: invoice.locationID,
      };

      payments.push(valuesToBeValidated);
    }

    const dataToBeValidates = {
      payments,
      siteKey: siteKey,
    };

    const validPaymentData =
      StiltPaymentManager.parseCreateMultiPayment(dataToBeValidates);

    try {
      await mutateRecordBulkPayment.mutateAsync(validPaymentData);
      addMessage({
        id: createToastMessageID(),
        message: strings.SUCCESS_MANUAL_PAYMENT_NO_RECEIPT,
        dialog: false,
        type: "success",
      });
    } catch (err) {
      if (err instanceof Error) {
        // 409 = "conflict" error - means that the payment amount did not
        // equal the sum of the amountDue of the given invoices
        if (err.message.includes("409")) {
          addMessage({
            id: createToastMessageID(),
            message: strings.PAYMENT_DID_NOT_MATCH_INVOICE_AMOUNTS,
            dialog: false,
            type: "error",
          });
          return;
        }
      }
      logger.error("handleRecordManualPayment", err);
      addMessage({
        id: createToastMessageID(),
        message: strings.ERROR_RECORD_MANUAL_PAYMENT,
        dialog: false,
        type: "error",
      });
      return;
    }
  }

  async function handleGetMultiPaymentCreditCardLink(
    selectedInvoices: ExistingStiltInvoice[],
    amount: number,
    timestampPaymentMade: DateTime,
  ): Promise<void> {
    if (firebaseUser == null) {
      logger.error("firebaseUser is null");
      return;
    }
    if (!siteKeyDoc) {
      logger.error("siteKeyDoc is null");
      return;
    }

    const paymentSource = siteKeyDoc.customizations?.accounting?.ziftAccountID
      ? "ziftHPP"
      : "payaHPP";

    // Create the array of multi-payments
    const payments: CreateMultiPayment[] = [];
    for (const invoice of selectedInvoices) {
      const valuesToBeValidated: CreateMultiPayment = {
        amount: amount,
        checkNumber: null,
        memo: null,
        paymentMethod: "credit_card",
        paymentSource: paymentSource,
        // IMPORTANT: `amount` must be `formValues.amount`. not the invoice amountDue
        timestampCreated: DateTime.now().toISO(),
        timestampPaymentMade: timestampPaymentMade.toISO(),
        customerID: invoice.customerID,
        invoiceID: invoice.id,
        customData: {},
        createdBy: firebaseUser.uid,
        billToCustomerID: invoice.billToCustomerID,
        locationID: invoice.locationID,
      };

      payments.push(valuesToBeValidated);
    }

    const uniqueLink = await generateMultiPaymentUniqueLink(
      siteKey,
      selectedInvoices.map((inv) => inv.id),
      "web",
    );
    const paymentID = uniqueLink?.split("/").pop();
    if (!paymentID) {
      addMessage({
        id: createToastMessageID(),
        message: strings.ERROR_PAYMENT_LINK,
        dialog: false,
        type: "error",
      });
      return;
    }

    try {
      const paymentLink =
        await DbRead.payments.getMultiPayCreditCardData(paymentID);

      if (paymentLink) {
        window.open(paymentLink, "_blank")?.focus();
      } else {
        addMessage({
          id: createToastMessageID(),
          message: strings.ERROR_PAYMENT_LINK,
          dialog: false,
          type: "error",
        });
      }
    } catch (err) {
      if (err instanceof Error) {
        // 409 = "conflict" error - means that the payment amount did not
        // equal the sum of the amountDue of the given invoices
        if (err.message.includes("409")) {
          addMessage({
            id: createToastMessageID(),
            message: strings.PAYMENT_DID_NOT_MATCH_INVOICE_AMOUNTS,
            dialog: false,
            type: "error",
          });
          return;
        }
      }
      logger.error("handleGetMultiPaymentCreditCardLink", err);
      addMessage({
        id: createToastMessageID(),
        message: strings.ERROR_PAYMENT_LINK,
        dialog: false,
        type: "error",
      });
      return;
    }
  }

  async function payWithCardOnFile(args: {
    invoiceID: string;
    expiry: string;
    lastFour: number;
    amount: number;
  }): Promise<void> {
    if (!siteKeyDoc) throw Error("payWithCardOnFile missing siteKeyDoc");

    const processor = siteKeyDoc.customizations.accounting?.ziftAccountID
      ? "zift"
      : "paya";
    const data: APIPaymentSavedCard = {
      siteKeyID: siteKey,
      invoiceID: args.invoiceID,
      amount: args.amount,
      cardLastFour: args.lastFour,
      cardExpiry: args.expiry,
    };
    const valid = StiltPaymentManager.parseCreateWithSavedCard(data);
    await DbWrite.payments.createWithSavedCard(valid, processor);
  }

  const bulkManualPaymentDialog = bulkManualPaymentDialogProps && (
    <RecordManualPaymentDialog {...bulkManualPaymentDialogProps} />
  );

  const multiPayCreditCardDialog = multiPayCreditCardDialogProps && (
    <MultiPayCreditCardDialog {...multiPayCreditCardDialogProps} />
  );

  const dropdownSelectionInvoiceList = (
    <DropdownSelectionInvoiceList
      onSelectionStatus={(invoiceStatus) =>
        setStiltInvoiceStatusSelection(invoiceStatus)
      }
      stiltInvoiceStatus={invoiceStatus}
    />
  );

  const confirmationDialog = (
    <ConfirmationDialogForInvoiceActions
      invoiceActionType={confirmationDialogProps.invoiceActionType}
      isOpen={confirmationDialogProps.isOpen}
      onClose={confirmationDialogProps.onClose}
      handleConfirmAction={confirmationDialogProps.handleConfirmAction}
      isSubmitting={confirmationDialogProps.isSubmitting}
      title={confirmationDialogProps.title}
      body={confirmationDialogProps.body}
      pdfBatchActionButtons={confirmationDialogProps.pdfBatchActionButtons}
      pendingInvoiceIDsLength={confirmationDialogProps.pendingInvoiceIDsLength}
    />
  );

  const invoiceDetailsBox = (
    <InvoiceDetailsBox
      invoiceList={invoiceListOptions}
      paymentsList={paymentsList}
      currency={siteKeyDoc?.customizations.accounting?.currency ?? "USD"}
    />
  );

  const dueDatePicker = selectedInvoice && (
    <div className="flex flex-col">
      {selectedDueDate ? `Due Date` : "Due Date Not Selected"}
      <div className="mt-2 flex items-center justify-between gap-4">
        <DatePicker
          selected={
            selectedDueDate
              ? selectedDueDate.toJSDate()
              : DateTime.now().toJSDate()
          }
          onChange={(date: Date) => {
            const luxonDate = DateTime.fromJSDate(date);
            setSelectedDueDate(luxonDate);
            setPaymentTerms(null);
          }}
          customInput={<SchedulingButton />}
        />
      </div>
    </div>
  );

  const issueDatePicker = selectedInvoice && (
    <div className="flex flex-col">
      {selectedIssueDate ? `Issue Date` : "Issue Date Not Selected"}
      <div className="mt-2 flex items-center justify-between gap-4">
        <DatePicker
          selected={
            selectedIssueDate
              ? selectedIssueDate.toJSDate()
              : DateTime.now().toJSDate()
          }
          onChange={(date: Date) => {
            const luxonDate = DateTime.fromJSDate(date);
            setSelectedIssueDate(luxonDate);
            setPaymentTerms(null);
          }}
          customInput={<SchedulingButton />}
        />
      </div>
    </div>
  );

  const paymentTermsSelector = (
    <BaseInputSelect
      inputName="paymentTerms"
      text="Payment Terms"
      admin={true}
      required={true}
      className="capitalize"
      value={paymentTerms === null ? "" : paymentTerms}
      onChange={handlePaymentTermsChange}
    >
      <option value="">None</option>
      {Object.entries(templatesPaymentTerms).map(([key, paymentTerm]) => {
        return (
          <option key={key} value={key}>
            {paymentTerm.title}
          </option>
        );
      })}
    </BaseInputSelect>
  );

  const editInvoiceDialog = selectedInvoice && (
    <EditInvoiceDialog
      isDialogOpen={editInvoiceDialogOpen}
      closeDialog={() => setEditInvoiceDialogOpen(false)}
      invoiceDoc={selectedInvoice}
      handleSave={handleSaveUpdatedInvoice}
      dueDate={dueDatePicker}
      issueDate={issueDatePicker}
      paymentTerms={paymentTermsSelector}
    />
  );

  /** this one opens when the invoice dialog is already open */
  const handlePaymentDialog = isHandlePaymentDialogOpen &&
    siteKeyDoc &&
    selectedInvoice &&
    currentCustomer &&
    userPermissions && (
      <HandlePaymentDialog
        isDialogOpen={isHandlePaymentDialogOpen}
        closeDialog={() => setIsHandlePaymentDialogOpen(false)}
        goToPaymentPage={handleGoToPaymentPage}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin === true}
        invoiceID={selectedInvoice.id}
        invoiceAmount={selectedInvoice.amountDue}
        invoiceStatus={selectedInvoice.status}
        invoiceSentToCustomer={selectedInvoice.timestampSentToCustomer}
        customer={currentCustomer}
        payWithCardOnFile={payWithCardOnFile}
        defaultIncludePhotos={
          siteKeyDoc.customizations.defaultIncludeJobPhotos ?? false
        }
        emailList={getEmailList(selectedInvoice, customerDoc)}
        sendEmail={sendEmailFromHandlePaymentDialog}
        paymentMethods={
          siteKeyDoc.customizations.manualPaymentMethods ?? [...paymentMethods]
        }
        applyManualPayment={applyManualPayment}
      />
    );

  /** this one opens when table row's action button is clicked */
  const tableHandlePaymentDialog = isTableHandlePaymentDialogOpen &&
    siteKeyDoc &&
    selectedInvoice &&
    currentCustomer &&
    userPermissions && (
      <HandlePaymentDialog
        isDialogOpen={isTableHandlePaymentDialogOpen}
        closeDialog={() => setIsTableHandlePaymentDialogOpen(false)}
        goToPaymentPage={handleGoToPaymentPage}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin === true}
        invoiceID={selectedInvoice.id}
        invoiceAmount={selectedInvoice.amountDue}
        invoiceStatus={selectedInvoice.status}
        invoiceSentToCustomer={selectedInvoice.timestampSentToCustomer}
        customer={currentCustomer}
        payWithCardOnFile={payWithCardOnFile}
        defaultIncludePhotos={
          siteKeyDoc.customizations.defaultIncludeJobPhotos ?? false
        }
        emailList={getEmailList(selectedInvoice, customerDoc)}
        sendEmail={sendEmailFromHandlePaymentDialog}
        paymentMethods={
          siteKeyDoc.customizations.manualPaymentMethods ?? [...paymentMethods]
        }
        applyManualPayment={applyManualPayment}
      />
    );

  const viewInvoiceDialog = isViewInvoiceDialogOpen &&
    viewInvoiceDialogProps &&
    userPermissions &&
    !userDisplayNamesMapIsLoading && (
      <ViewInvoiceDialog
        handlePayment={onClickHandlePaymentButton}
        isOpen={isViewInvoiceDialogOpen}
        onClose={closeViewInvoiceDialog}
        invoiceID={viewInvoiceDialogProps["invoiceID"]}
        siteKeyID={viewInvoiceDialogProps["siteKeyID"]}
        merchantLogoURL={viewInvoiceDialogProps["merchantLogoURL"]}
        merchantName={viewInvoiceDialogProps["merchantName"]}
        handleRefund={handleRefund}
        userIsSiteAdmin={userPermissions.permissions.isSiteAdmin === true}
        editInvoice={openEditInvoiceDialog}
        sendEmail={emailInvoice}
        deleteInvoice={handleDeleteInvoice}
        handleDeletePayment={handleDeletePayment}
        userDisplayNamesMap={userDisplayNamesMap}
      >
        {{
          EditInvoiceDialog: editInvoiceDialog,
          HandlePaymentDialog: handlePaymentDialog,
        }}
      </ViewInvoiceDialog>
    );

  const invoiceDateRangePicker = (
    <RADateRangePicker value={range} onChange={handleDateRangeChange} />
  );

  const handleEmailReceiptDialog = selectedInvoice && (
    <HandleSendEmailDialog
      defaultIncludeJobPhotos={
        siteKeyDoc?.customizations.defaultIncludeJobPhotos ?? false
      }
      isDialogOpen={isEmailReceiptDialogOpen}
      closeDialog={() => setIsEmailReceiptDialogOpen(false)}
      sendEmailReceipt={handleSendEmailReceipt}
      title={strings.SEND_RECEIPT_TO_CUSTOMER}
      merchantName={siteKeyDoc?.name ?? ""}
      timestampSentToCustomer={selectedInvoice.timestampSentToCustomer}
      customerEmailList={emailList}
    />
  );

  // Display loading clipboard while customer list loads.
  if (
    siteKeyDocLoading ||
    siteKeyLocationListIsLoading ||
    userDisplayNamesMapIsLoading
  ) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  return (
    <Fragment>
      <InvoiceListPage
        invoiceList={filteredListOfInvoices}
        dateRange={range}
        currency={siteKeyDoc?.customizations.accounting?.currency ?? "USD"}
        isGetInvoices={isGetInvoices}
        isGeneratingCustomerStatements={isGeneratingCustomerStatements}
        goToCustomerPage={goToCustomerPage}
        goToViewEstimate={goToViewEstimate}
        goToWorkRecordAndTasksPage={goToWorkRecordAndTasksPage}
        openHandlePaymentDialog={onClickHandlePaymentTableRow}
        emailReceipt={openEmailReceiptDialog}
        getPDF={handleGetPDF}
        generateCustomerStatements={handleGenerateCustomerStatements}
        openInvoiceDialog={openViewInvoiceDialog}
        actionsLoading={actionsLoading}
        invoiceActionSelected={handleInvoiceActionSelected}
        siteKeyLocationList={siteKeyLocationList}
        getLocationTitle={getLocationTitle}
        userDisplayNamesMap={userDisplayNamesMap}
      >
        {{
          DropdownSelectionInvoiceList: dropdownSelectionInvoiceList,
          InvoiceDetailsBox: invoiceDetailsBox,
          InvoiceDateRangePicker: invoiceDateRangePicker,
          BulkManualPaymentDialog: bulkManualPaymentDialog,
          MultiPayCreditCardDialog: multiPayCreditCardDialog,
        }}
      </InvoiceListPage>
      {tableHandlePaymentDialog}
      {viewInvoiceDialog}
      {confirmationDialog}
      {handleEmailReceiptDialog}
    </Fragment>
  );
}

export function filterInvoicesByDropdownSelection(
  stiltInvoiceStatus: StiltInvoiceStatusValues | null,
  listOfInvoices: ExistingStiltInvoice[],
): ExistingStiltInvoice[] {
  logger.debug(
    "Starting to filterInvoicesByDropdownSelection. Status: ",
    stiltInvoiceStatus,
  );
  let filteredList: ExistingStiltInvoice[] = [];
  switch (stiltInvoiceStatus) {
    case "draft":
      filteredList = listOfInvoices.filter((invoice) => {
        const filtering = invoice.status === stiltInvoiceStatus;
        return filtering;
      });
      return filteredList;
    case "canceled":
      filteredList = listOfInvoices.filter((invoice) => {
        const filtering = invoice.status === stiltInvoiceStatus;
        return filtering;
      });
      return filteredList;
    case "paid":
      filteredList = listOfInvoices.filter((invoice) => {
        const filtering = invoice.status === stiltInvoiceStatus;
        return filtering;
      });
      return filteredList;
    case "submitted":
      filteredList = listOfInvoices.filter((invoice) => {
        const filtering = invoice.status === stiltInvoiceStatus;
        return filtering;
      });
      return filteredList;
    case "partiallyPaid":
      filteredList = listOfInvoices.filter((invoice) => {
        const filtering = invoice.status === stiltInvoiceStatus;
        return filtering;
      });
      return filteredList;
    case "pending":
      filteredList = listOfInvoices.filter((invoice) => {
        const filtering = invoice.status === stiltInvoiceStatus;
        return filtering;
      });
      return filteredList;
    case null:
      return listOfInvoices;
    default:
      const _exhaustivenessCheck: never = stiltInvoiceStatus;
      return _exhaustivenessCheck;
  }
}

async function handleDeleteInvoice(
  siteKeyID: string,
  invoiceID: string,
): Promise<void> {
  return DbWrite.invoices.delete(siteKeyID, invoiceID);
}

async function handleDeletePayment(
  siteKeyID: string,
  paymentID: string,
): Promise<void> {
  return DbWrite.payments.delete(siteKeyID, paymentID);
}
