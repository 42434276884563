import { useState } from "react";
import { useQuery } from "react-query";
import { Wrapper } from "@googlemaps/react-wrapper";
import { useRootUserStore } from "../../store/root-user";
import { DbRead } from "../../database";
import AddressStep from "./Steps/AddressStep";
import LoadingSpinner from "../../components/LoadingSpinner";
import OnboardingProgress from "./Components/OnboardingProgress";
import LocationsStep from "./Steps/LocationsStep";
import CompanyStep from "./Steps/CompanyStep";
import { CraftTypeValues } from "../../models/craft-types";
import { LocationsCSInterface } from "./Steps/LocationsStep";
import { SITE_ONBOARDING_STEPS } from "./Components/OnboardingProgress";

// Import our new components (to be created)

export type OnboardingStep = "company" | "locations" | "review" | "progress";

export default function OnboardingContainer() {
  const rootUser = useRootUserStore((state) => state.rootUser);
  const apiKey = import.meta.env.VITE_APP_GOOGLE_MAP_KEY;

  const [currentStep, setCurrentStep] = useState<OnboardingStep>("company");

  // State to collect all onboarding data
  const [onboardingData, setOnboardingData] = useState({
    company: {
      name: rootUser?.companyName || "",
      craftTypes: [] as CraftTypeValues[],
      mainPointOfContact: rootUser?.displayName || "",
      address: {
        formatted: "",
        latitude: 0,
        longitude: 0,
      },
    },
    locations: [] as LocationsCSInterface[],
  });

  // Get template config
  const { data: templateList = [], isLoading: templateListLoading } = useQuery(
    ["onboarding_templateList"],
    () => DbRead.newSiteConfig.getAll(),
  );

  const handleStepComplete = (stepData: any) => {
    setOnboardingData((prev) => ({
      ...prev,
      [currentStep]: stepData,
    }));

    // Move to next step
    switch (currentStep) {
      case "company":
        setCurrentStep("locations");
        break;
      case "locations":
        setCurrentStep("review");
        break;
      case "review":
        setCurrentStep("progress");
        break;
    }
  };

  if (!rootUser || templateListLoading) {
    return <LoadingSpinner />;
  }

  const renderStep = () => {
    switch (currentStep) {
      case "company":
        return (
          <Wrapper apiKey={apiKey} libraries={["places"]}>
            <CompanyStep
              onComplete={(data) => {
                setOnboardingData((prev) => ({
                  ...prev,
                  company: {
                    ...prev.company,
                    ...data,
                  },
                }));
                setCurrentStep("locations");
              }}
              initialData={onboardingData.company}
              templateList={templateList}
            />
          </Wrapper>
        );
      case "locations":
        return (
          <Wrapper apiKey={apiKey} libraries={["places"]}>
            <LocationsStep
              onComplete={(locations) => {
                setOnboardingData((prev) => ({
                  ...prev,
                  locations,
                }));
                setCurrentStep("review");
              }}
              initialData={onboardingData.locations}
              siteAddress={onboardingData.company.address}
            />
          </Wrapper>
        );
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="mx-auto max-w-3xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="overflow-hidden rounded-lg bg-white shadow">
          {/* Progress indicator */}
          <div className="px-4 py-5 sm:p-6">
            <OnboardingProgress
              currentStep={currentStep}
              steps={SITE_ONBOARDING_STEPS}
            />
          </div>

          {/* Step content */}
          <div className="px-4 py-5 sm:p-6">{renderStep()}</div>
        </div>
      </div>
    </div>
  );
}
