import { useMutation } from "react-query";
import { memo } from "react";
import isEqual from "lodash/isEqual";
// Local
import { ExistingCustomer } from "../../models/customer";
import { useIncomingCallsStore } from "../../store/incoming-calls";
import { useActiveCallStore } from "../../store/active-call";
import AlertV2 from "../AlertV2";
import AlertV2Button from "../AlertV2Button";
import LoadingSpinner from "../LoadingSpinner";
import { logger } from "../../logging";
import { createToastMessageID } from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";
import * as strings from "../../strings";

interface Props {
  siteKey: string;
  uid: string;
  customer: ExistingCustomer | null;
}

/**
 * Memoized component for displaying incoming or active call alerts.
 *
 * Allows user to match a customer to an incoming call. Once the customer is
 * matched, the component renders the active call and updates the customer record
 * with the phone number.
 *
 * TODO: ^ docs prolly aren't final
 */
// RENAME & REFACTOR: BubbleUnmatchedCall
const CallAlert = memo(
  (props: Props) => {
    const addMessage = useToastMessageStore((state) => state.addToastMessage);

    const {
      currentCall: incomingCall,
      clearCall: clearIncomingCall,
      updateCallCustomer,
    } = useIncomingCallsStore();

    const { setActiveCall, addPhoneToCustomer } = useActiveCallStore();

    const mutateUpdateCall = useMutation(
      () => {
        if (!props.customer) throw new Error("No customer found");
        return updateCallCustomer(props.customer.id);
      },
      {
        onSuccess: () => {
          clearIncomingCall();
          setActiveCall(incomingCall);
          const existingPhone = props.customer?.phone;
          const nonEmpty =
            existingPhone &&
            existingPhone.length > 0 &&
            existingPhone[0].length > 0;
          addPhoneToCustomer({
            customerRefPath: props.customer!.refPath, // i wouldn't normally advocate for bang usage, but we wouldn't have gotten to the onSuccess callback if customer was null
            phoneAlreadyExists: nonEmpty === true,
            uid: props.uid,
          });

          addMessage({
            id: createToastMessageID(),
            message: strings.successfulUpdate(props.customer!.name),
            dialog: false,
            type: "success",
          });
        },
        onError: () => {
          logger.error(
            `Could not update call document for ${incomingCall?.caller}`,
          );
          clearIncomingCall();
        },
      },
    );

    const MatchCustomerAlertPrompt = props.customer && (
      <AlertV2
        variant="warning"
        title={
          <>
            Customer match needed for{" "}
            <span className="font-medium">{incomingCall?.caller}</span>
          </>
        }
        message={
          <span className="pr-4 text-base">
            Match <span className="font-medium">{props.customer.name}</span> to
            this call?
          </span>
        }
        onDismiss={clearIncomingCall}
        alignActions="left"
        actions={
          <>
            {mutateUpdateCall.isLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                <AlertV2Button
                  variant="warning"
                  onClick={() => mutateUpdateCall.mutateAsync()}
                >
                  Match
                </AlertV2Button>
                <AlertV2Button variant="warning" onClick={clearIncomingCall}>
                  Dismiss
                </AlertV2Button>
              </>
            )}
          </>
        }
      />
    );

    const SelectCustomerAlertPrompt = (
      <AlertV2
        variant="warning"
        title={
          <span className="pr-4">
            Select a customer to match to{" "}
            <span className="font-medium">{incomingCall?.caller}</span>
          </span>
        }
        onDismiss={clearIncomingCall}
      />
    );

    const MatchError = (
      <AlertV2
        variant="error"
        title="Could not update call document."
        message={
          <span className="pr-4">
            Dismiss this alert and reselect the call to try again. If the issue
            persists, please contact support.
          </span>
        }
        onDismiss={() => {
          mutateUpdateCall.reset();
          clearIncomingCall();
        }}
      />
    );

    return (
      <>
        {mutateUpdateCall.isError && (
          <div className="w-fit max-w-xl pb-2">{MatchError}</div>
        )}

        {incomingCall && props.customer ? (
          <div className="w-fit max-w-xl pb-2">{MatchCustomerAlertPrompt}</div>
        ) : null}

        {incomingCall && !props.customer ? (
          <div className="w-fit max-w-xl pb-2">{SelectCustomerAlertPrompt}</div>
        ) : null}
      </>
    );
  },
  (prev, next) => isEqual(prev.customer, next.customer),
);

export default CallAlert;
