// Libs
import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";

// Local
import { logger as devLogger } from "../../logging";
import { guardIsPlainObject } from "../../utils";

export function convertToReadableTimestamp(timestamp: unknown): string {
  const isFSTimestamp = timestamp instanceof Timestamp;

  // Could receive an object that has seconds and nanoseconds, but technically is
  // not a Firestore Timestamp object. Need to handle that case first.
  if (!isFSTimestamp && guardIsPlainObject(timestamp)) {
    if (
      typeof timestamp["seconds"] === "number" &&
      typeof timestamp["nanoseconds"] === "number"
    ) {
      timestamp = new Timestamp(timestamp["seconds"], timestamp["nanoseconds"]);
    }
  }

  if (timestamp instanceof Timestamp) {
    return DateTime.fromISO(timestamp.toDate().toISOString()).toFormat(
      "LL/dd/yy hh:mm a",
    );
  } else {
    devLogger.info(`Timestamp had an unexpected value: ${timestamp}.`);
    return "Unknown Timestamp";
  }
}

export function convertToReadableTimestampDate(timestamp: unknown): string {
  const isFSTimestamp = timestamp instanceof Timestamp;

  // Could receive an object that has seconds and nanoseconds, but technically is
  // not a Firestore Timestamp object. Need to handle that case first.
  if (!isFSTimestamp && guardIsPlainObject(timestamp)) {
    if (
      typeof timestamp["seconds"] === "number" &&
      typeof timestamp["nanoseconds"] === "number"
    ) {
      timestamp = new Timestamp(timestamp["seconds"], timestamp["nanoseconds"]);
    }
  }

  if (timestamp instanceof Timestamp) {
    return DateTime.fromISO(timestamp.toDate().toISOString()).toFormat(
      "LL/dd/yy",
    );
  } else {
    devLogger.info(`Timestamp had an unexpected value: ${timestamp}.`);
    return "Unknown";
  }
}

export function convertToReadableTimestampTime(timestamp: unknown): string {
  const isFSTimestamp = timestamp instanceof Timestamp;

  // Could receive an object that has seconds and nanoseconds, but technically is
  // not a Firestore Timestamp object. Need to handle that case first.
  if (!isFSTimestamp && guardIsPlainObject(timestamp)) {
    if (
      typeof timestamp["seconds"] === "number" &&
      typeof timestamp["nanoseconds"] === "number"
    ) {
      timestamp = new Timestamp(timestamp["seconds"], timestamp["nanoseconds"]);
    }
  }

  if (timestamp instanceof Timestamp) {
    return DateTime.fromISO(timestamp.toDate().toISOString()).toFormat(
      "hh:mm a",
    );
  } else {
    devLogger.info(`Timestamp had an unexpected value: ${timestamp}.`);
    return "Timestamp";
  }
}
