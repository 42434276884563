import HeadingOne from "../components/HeadingOne";

export default function NotFound404() {
  return (
    <div className="flex min-h-full flex-col bg-white pb-12 pt-16">
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-center text-indigo-600">
          <span className="sr-only">Question mark</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-16 w-16"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
            />
          </svg>
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="mb-2 text-sm font-semibold uppercase tracking-wide text-indigo-600">
              404 error
            </p>
            <HeadingOne fontSize="text-4xl sm:text-5xl">
              Page not found.
            </HeadingOne>
            <p className="mt-2 text-base text-gray-500">
              Sorry, we couldn't find the page you're looking for. Try
              refreshing the page or contacting support.
            </p>
            <div className="mt-6">
              {/* <a */}
              {/*   href={HOME_URL} */}
              {/*   className="text-base font-medium text-indigo-600 hover:text-indigo-500" */}
              {/* > */}
              {/*   Go back home<span aria-hidden="true"> &rarr;</span> */}
              {/* </a> */}
            </div>
          </div>
        </div>
      </main>
      <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
        <nav className="flex justify-center space-x-4">
          <a
            href="mailto:support@monomersoftware.com"
            className="text-sm font-medium text-gray-500 hover:text-gray-600"
          >
            Contact Support
          </a>
          <span className="inline-block" aria-hidden="true" />
        </nav>
      </footer>
    </div>
  );
}
