// #region Imports
// Libs
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Suspense, lazy, useEffect } from "react";
import * as sentry from "@sentry/react";

// Styles
import styles from "./App.module.css";
// Local
import NewTemplateContainer from "./Pages/NewTemplateContainer";
import ListTemplatesContainer from "./Pages/ListTemplatesContainer";
import LoginPage from "./Pages/LoginPage";
import AllChecklistsTasksContainer from "./Pages/AllChecklistsTasksContainer";
import { useRootUserStore } from "./store/root-user";
import TemplateDetailsContainer from "./Pages/TemplateDetailsContainer";
import EditItemContainer from "./Pages/EditItemContainer";
import EditChecklistContainer from "./Pages/EditChecklistContainer";
import TasksForTemplateContainer from "./Pages/TasksForTemplateContainer";
import { RouteAuthRequired } from "./components/RouteAuthRequired";
import { RouteAdminPermission } from "./components/RouteAdminPermission";
import AutoLogin from "./Pages/AutoLoginPage";
import NotFound404 from "./Pages/NotFound404";
import { useSiteKeyLocationsStore } from "./store/site-key-locations";
import TaskDetailsContainer from "./Pages/TaskDetailsContainer";
import Help from "./Pages/Help";
import { useUserPermissionsStore } from "./store/user-permissions";
import { useAuthStore } from "./store/firebase-auth";
import { useUserDisplayNamesStore } from "./store/user-display-names-map";
import UserManagementContainer from "./Pages/Admin/UserManagementContainer";
import { useSiteKeyCompaniesStore } from "./store/site-key-companies";
import AddUserContainer from "./Pages/Admin/AddUserContainer";
import EditUserContainer from "./Pages/Admin/EditUserContainer";
import Navbar from "./components/Navbar";
import { whiteLabelData } from "./white-label-data";
import { isWhiteLabel } from "./white-label-check";
import MySitesContainer from "./Pages/Admin/MySitesContainer";
import SiteDetailsContainer from "./Pages/Admin/SiteDetailsContainer";
import CustomFieldsContainer from "./Pages/Admin/CustomFieldsContainer";
import CompaniesListContainer from "./Pages/Admin/CompaniesListContainer";
import * as urls from "./urls";
import LocationListContainer from "./Pages/Admin/LocationsListContainer";
import CreateAccountContainer from "./Pages/CreateAccountContainer";
import CreateSiteContainer from "./Pages/CreateSite/CreateSiteContainer";
import NewUserContainer from "./Pages/NewUserContainer";
import ApplyToExistingSiteContainer from "./Pages/ApplyToExistingSiteContainer";
import Toast from "./components/Toast";
import { useToastMessageStore } from "./store/toast-messages";
import { useRootUserSiteKeyListStore } from "./store/root-user-site-key-list";
import ComplianceContainer from "./Pages/Compliance/ComplianceContainer";
import { ReactAppHome } from "./Pages/ReactAppHome";
import ReactAppHome2 from "./Pages/ReactAppHome2";
import CustomerListContainer from "./Pages/Customers/CustomerListContainer";
import ShowSingleCustomerContainer from "./Pages/Customers/ShowSingleCustomerContainer";
import PriceBookItemListContainer from "./Pages/Estimates/PriceBookItemListContainer";
import CreateEstimateContainer from "./Pages/Estimates/CreateEstimateContainer";
import WorkRecordAndTasksContainer from "./Pages/WorkRecordAndTasks/WorkRecordAndTasksContainer";
import ViewEstimateContainer from "./Pages/Estimates/ViewEstimateContainer";
import PaymentsContainer from "./Pages/Payments/PaymentsContainer";
import InvoiceListContainer from "./Pages/Invoices/InvoiceListContainer";
import InventoryObjectListContainer from "./Pages/Inventory/InventoryObjectListContainer";
import InventoryTransactionListContainer from "./Pages/Inventory/InventoryTransactionListContainer";
import { useMembershipTemplatesStore } from "./store/membership-templates";
import ReviewRequestContainer from "./Pages/Feedback/ReviewRequestContainer";
import FeedbackListContainer from "./Pages/Feedback/FeedbackListContainer";
import UserSettings from "./Pages/UserSettings";
import { useSiteKeyDocStore } from "./store/site-key-doc";
import NonAuthUserViewEstimateContainer from "./Pages/Estimates/NonAuthUserViewEstimateContainer";
import EstimateListContainer from "./Pages/Estimates/EstimateListContainer";
import { useTypesenseStore } from "./store/typesense";
import { useSiteKeyUsersStore } from "./store/site-key-users";
import PaymentListContainer from "./Pages/Payments/PaymentListContainer";
import MembershipTemplateListContainer from "./Pages/Memberships/MembershipTemplateListContainer";
import QuickbooksContainer from "./Pages/Quickbooks/QuickbooksContainer";
import ErrorBoundary from "./components/ErrorBoundary";
import InventoryLocationsListContainer from "./Pages/Inventory/InventoryLocationsListContainer";
import KPIsContainer from "./Pages/KPIs/KPIsContainer";
import LightspeedContainer from "./Pages/Lightspeed/LightspeedContainer";
import AddTemplateContainer from "./Pages/Memberships/AddTemplateContainer";
import EditTemplateContainer from "./Pages/Memberships/EditTemplateContainer";

// todo: replace with clipboard animation?
const LoadingComponentForPages = () => <div>Loading...</div>;

// Lazy loading large page components. Improves initial load times by reducing
// main bundle size. Starting import outsize of lazy() to preload the component
// for a better user experience.
const promiseDeletedWorkRecordListContainer = import(
  "./Pages/CraftList/DeletedWorkRecordListContainer"
);
const LazyDeletedWorkRecordListContainer = lazy(
  () => promiseDeletedWorkRecordListContainer,
);

const promiseDeletedTaskListContainer = import(
  "./Pages/TaskList/DeletedTaskListContainer"
);
const LazyDeletedTaskListContainer = lazy(
  () => promiseDeletedTaskListContainer,
);

const promiseTaskListContainer = import("./Pages/TaskList/TaskListContainer");
const LazyTaskListContainer = lazy(() => promiseTaskListContainer);

const promiseWorkRecordListContainer = import(
  "./Pages/CraftList/WorkRecordListContainer"
);
const LazyWorkRecordListContainer = lazy(() => promiseWorkRecordListContainer);

const promiseCreateTaskForCustomerContainer = import(
  "./Pages/Customers/CreateTaskForCustomerContainer"
);
const LazyCreateTaskForCustomerContainer = lazy(
  () => promiseCreateTaskForCustomerContainer,
);

const promiseSchedulingContainer = import(
  "./Pages/Scheduling/SchedulingContainer"
);
const LazySchedulingContainer = lazy(() => promiseSchedulingContainer);

const promiseSchedulingDetailContainer = import(
  "./Pages/Scheduling/SchedulingDetailContainer"
);
const LazySchedulingDetailContainer = lazy(
  () => promiseSchedulingDetailContainer,
);
// #endregion Imports

const appVersion = import.meta.env.PACKAGE_VERSION;
const gitCommit = import.meta.env.VITE_GIT_COMMIT;

// FEATURE FLAGS
const FEATURE_FLAG_NEW_USER =
  import.meta.env.VITE_APP_FIREBASE_PROJECT !== "scaffoldtracker";
const FEATURE_FLAG_HELP_SECTION =
  import.meta.env.VITE_APP_FIREBASE_PROJECT !== "scaffoldtracker";
const FEATURE_FLAG_APPLY_TO_EXISTING_SITE =
  import.meta.env.VITE_APP_FIREBASE_PROJECT !== "scaffoldtracker";
const FEATURE_FLAG_CREATE_ACCOUNT =
  import.meta.env.VITE_APP_FIREBASE_PROJECT !== "scaffoldtracker";
const FEATURE_FLAG_CREATE_TRIAL_ACCOUNT =
  import.meta.env.VITE_APP_FIREBASE_PROJECT !== "scaffoldtracker";
const FEATURE_FLAG_QUICKBOOKS =
  import.meta.env.VITE_APP_FIREBASE_PROJECT !== "scaffoldtracker";

if (!isWhiteLabel(urls.whiteLabel))
  throw new Error("Invalid white-label configuration!");

function App() {
  // Set page title based on white label
  if (!isWhiteLabel(urls.whiteLabel))
    throw new Error("Invalid white-label configuration!");
  document.title = whiteLabelData[urls.whiteLabel].pageTitle;
  const firebaseUser = useAuthStore((state) => state.firebaseUser);
  const rootUser = useRootUserStore((state) => state.rootUser);
  const fetchSiteKeyLocations = useSiteKeyLocationsStore(
    (state) => state.fetch,
  );
  const fetchSiteKeyUsers = useSiteKeyUsersStore((state) => state.fetch);
  const fetchRootUserSiteKeyList = useRootUserSiteKeyListStore(
    (state) => state.fetch,
  );

  const [fetchUserPermissions, userPermissionsDoc] = useUserPermissionsStore(
    (state) => [state.fetch, state.siteKeyUserPermissions],
  );
  const fetchUserDisplayNamesMap = useUserDisplayNamesStore(
    (state) => state.fetch,
  );

  const fetchSiteKeyCompanies = useSiteKeyCompaniesStore(
    (state) => state.fetch,
  );

  const fetchMembershipTemplates = useMembershipTemplatesStore(
    (state) => state.fetch,
  );

  const fetchTypesenseKey = useTypesenseStore(
    (state) => state.updateScopedSearchKey,
  );

  const toastMessageList = useToastMessageStore((state) => state.messagesList);
  const filterToastMessageList = toastMessageList.filter(
    (message) => message.dialog === false,
  );

  const [fetchSiteKeyDoc, siteKeyDoc] = useSiteKeyDocStore((state) => [
    state.fetch,
    state.siteKeyDoc,
  ]);

  // Identify the user for Sentry.
  sentry.setUser({
    id: firebaseUser?.uid,
    username: rootUser?.displayName,
    email: rootUser?.email,
  });

  // TODO: There's probably a better way to do this check.
  const defaultSiteKey =
    typeof rootUser?.defaultSiteKey === "string"
      ? rootUser.defaultSiteKey
      : undefined;

  // LOADING APP STATE
  // Fetch app state that depends on a default site key.
  useEffect(() => {
    if (
      typeof defaultSiteKey === "string" &&
      typeof firebaseUser?.uid === "string"
    ) {
      fetchSiteKeyLocations(defaultSiteKey);
      fetchUserPermissions(defaultSiteKey, firebaseUser?.uid);
      fetchUserDisplayNamesMap(defaultSiteKey);
      fetchRootUserSiteKeyList();
      fetchSiteKeyDoc(defaultSiteKey);
      fetchTypesenseKey(defaultSiteKey);
    }
  }, [
    defaultSiteKey,
    fetchSiteKeyLocations,
    fetchUserPermissions,
    firebaseUser?.uid,
    fetchUserDisplayNamesMap,
    fetchRootUserSiteKeyList,
    fetchSiteKeyDoc,
    fetchTypesenseKey,
  ]);

  useEffect(() => {
    if (typeof defaultSiteKey !== "string") return;

    // FIXME: Logic error?
    async function getStoresWithPermissions(siteKey: string): Promise<void> {
      if (siteKeyDoc && userPermissionsDoc) {
        fetchSiteKeyUsers(siteKeyDoc);
        fetchSiteKeyCompanies(siteKeyDoc, userPermissionsDoc);
        fetchMembershipTemplates(siteKeyDoc, userPermissionsDoc);
      }
    }

    getStoresWithPermissions(defaultSiteKey);
  }, [
    defaultSiteKey,
    fetchMembershipTemplates,
    fetchSiteKeyCompanies,
    fetchSiteKeyUsers,
    siteKeyDoc,
    userPermissionsDoc,
  ]);

  // #region Calculate whether or not to show the Checklists navbar
  // Here we check if we need to show the existing Checklists Nav Bar
  const routesWithNav = [
    urls.CHECKLISTS_URL,
    urls.TEMPLATES_URL,
    urls.TASKS_URL,
    urls.TEMPLATE_ITEMS_URL,
    urls.NEW_TEMPLATE_URL,
    urls.TASK_DETAILS_URL,
  ];

  const location = window.location;
  // This part is really only for local development and for when viewing the
  // `development--angry-varahamihira...` site directly.
  const isChecklistRelatedRoute: boolean = routesWithNav.some((value) => {
    return location.pathname.startsWith(value);
  });

  // This is specifically for showing a navbar within the Vue app. The important part.
  const isVueAppChecklistsRoute = location.href.includes(
    "destination=checklists",
  );

  const showNav = isChecklistRelatedRoute || isVueAppChecklistsRoute;
  // #endregion Calculate whether or not to show the Checklists navbar

  // Need to nullify some of the padding and margin stuff for these routes:
  const isComplianceRoute =
    location.pathname.startsWith(urls.COMPLIANCE_URL) ||
    location.href.includes("destination=compliance");
  const isSchedulingRoute = location.pathname.startsWith(urls.SCHEDULING_URL);

  // If we aren't serving this app via iFrames, then go to the new
  // ReactAppHome2 component, which includes the nav bar and other UI updates
  if (import.meta.env.VITE_APP_IFRAMES !== "true") {
    return (
      <div
        className={`min-h-full w-full ${
          showNav ? styles.appGrid : "grid grid-rows-1"
        }`}
      >
        <Toast toastList={filterToastMessageList} dialog={false} />
        <ReactAppHome2
          defaultSiteKey={defaultSiteKey}
          showChecklistsNav={showNav}
        />
        <span className="hidden">
          {`version: ${appVersion}
       commit: ${gitCommit}`}
        </span>
      </div>
    );
  }

  // For when we run the app with iFrames, ie for the Vue app or perhaps for
  // inserting into the mobile app
  return (
    <div
      // Hiding the nav reduces the number of grid items so I addded this conditional
      // to change the grid layout to compensate.
      className={`min-h-full w-full ${
        isSchedulingRoute ? "" : "max-w-screen-xl"
      } ${showNav ? styles.appGrid : "grid grid-rows-1"} ${
        isComplianceRoute ? "" : "mx-auto px-1 py-16 sm:px-4"
      }`}
    >
      <Toast toastList={filterToastMessageList} dialog={false} />
      <BrowserRouter>
        <Navbar showNav={showNav} />
        <main className={"flex h-full flex-col"}>
          <ErrorBoundary>
            <Routes>
              {/* Start Admin Routes */}
              <Route
                path={urls.ADMIN_LIST_LOCATIONS_URL}
                element={
                  <RouteAdminPermission defaultSiteKey={defaultSiteKey}>
                    {typeof defaultSiteKey === "string" ? (
                      <LocationListContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAdminPermission>
                }
              />
              <Route
                path={urls.ADMIN_LIST_COMPANIES_URL}
                element={
                  <RouteAdminPermission defaultSiteKey={defaultSiteKey}>
                    {typeof defaultSiteKey === "string" ? (
                      <CompaniesListContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAdminPermission>
                }
              />
              <Route
                path={`${urls.ADMIN_MY_SITES_URL}/:id/custom-fields/:workType`}
                element={
                  <RouteAdminPermission defaultSiteKey={defaultSiteKey}>
                    <CustomFieldsContainer />
                  </RouteAdminPermission>
                }
              />
              <Route
                path={`${urls.ADMIN_MY_SITES_URL}/:id`}
                element={
                  <RouteAdminPermission defaultSiteKey={defaultSiteKey}>
                    <SiteDetailsContainer />
                  </RouteAdminPermission>
                }
              />
              <Route
                path={urls.ADMIN_MY_SITES_URL}
                element={
                  <RouteAdminPermission defaultSiteKey={defaultSiteKey}>
                    <MySitesContainer />
                  </RouteAdminPermission>
                }
              />
              <Route
                path={`${urls.ADMIN_EDIT_USER_URL}/:id`}
                element={
                  <RouteAdminPermission defaultSiteKey={defaultSiteKey}>
                    {typeof defaultSiteKey === "string" ? (
                      <EditUserContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAdminPermission>
                }
              />
              <Route
                path={urls.ADMIN_ADD_USER_URL}
                element={
                  <RouteAdminPermission defaultSiteKey={defaultSiteKey}>
                    {typeof defaultSiteKey === "string" ? (
                      <AddUserContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAdminPermission>
                }
              />
              <Route
                path={urls.ADMIN_USER_MANAGEMENT_URL}
                element={
                  <RouteAdminPermission defaultSiteKey={defaultSiteKey}>
                    {typeof defaultSiteKey === "string" ? (
                      <UserManagementContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAdminPermission>
                }
              />
              <Route
                path={urls.ADMIN_USER_SETTINGS_URL}
                element={
                  <RouteAdminPermission defaultSiteKey={defaultSiteKey}>
                    {firebaseUser?.uid != null &&
                    siteKeyDoc != null &&
                    typeof defaultSiteKey === "string" ? (
                      <UserSettings
                        siteKey={siteKeyDoc}
                        userId={firebaseUser?.uid}
                      />
                    ) : (
                      <></>
                    )}
                  </RouteAdminPermission>
                }
              />
              {/* Finish Admin Routes */}
              {/* Start Auth Routes */}
              <Route
                path={`${urls.TEMPLATES_URL}/:id/edit`}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <EditChecklistContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={`${urls.TEMPLATES_URL}/:id`}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <TemplateDetailsContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={`${urls.TASKS_URL}/:id`}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <TasksForTemplateContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={`${urls.TEMPLATE_ITEMS_URL}/:id/edit`}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <EditItemContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={urls.TEMPLATES_URL}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <ListTemplatesContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={urls.NEW_TEMPLATE_URL}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <NewTemplateContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={urls.HOME_URL}
                element={
                  <RouteAuthRequired>
                    <ReactAppHome />
                  </RouteAuthRequired>
                }
              />
              <Route
                path={urls.CHECKLISTS_URL}
                element={
                  <RouteAuthRequired>
                    {siteKeyDoc ? (
                      <AllChecklistsTasksContainer siteKey={siteKeyDoc} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={`${urls.TASK_DETAILS_URL}/:id`}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <TaskDetailsContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={urls.CREATE_SITE_URL}
                element={
                  <RouteAuthRequired>
                    <CreateSiteContainer />
                  </RouteAuthRequired>
                }
              />
              <Route
                path={`${urls.WORK_RECORD_AND_TASKS_URL}/:workRecordID/:taskID?`}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <WorkRecordAndTasksContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={`${urls.PRICEBOOK_URL}`}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <PriceBookItemListContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={`${urls.CUSTOMERS_URL}${urls.CREATE_ESTIMATE_URL}`}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <CreateEstimateContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={`${urls.CUSTOMERS_URL}${urls.ESTIMATES_URL_AUTH}/:id`}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <ViewEstimateContainer
                        siteKey={defaultSiteKey}
                        renderedInDialog={false}
                      />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={`${urls.ESTIMATES_URL_NON_AUTH}/:id`}
                element={<NonAuthUserViewEstimateContainer />}
              />
              <Route
                path={`${urls.ESTIMATES_URL_AUTH}/:estimateStatusParams`}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <EstimateListContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={`${urls.CUSTOMERS_URL}${urls.CREATE_TASK_URL}/:customerID?/:craftRecordID?`}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <Suspense fallback={<LoadingComponentForPages />}>
                        <LazyCreateTaskForCustomerContainer
                          siteKey={defaultSiteKey}
                          assignedTo={null}
                        />
                      </Suspense>
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={`${urls.CUSTOMERS_URL}/:customerId`}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <ShowSingleCustomerContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={urls.CUSTOMERS_URL}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <CustomerListContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={urls.REPORT_TEMPLATES_URL}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <KPIsContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={`${urls.INVOICES_URL}/:id`}
                element={<PaymentsContainer />}
              />
              <Route
                path={urls.INVOICES_URL}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <InvoiceListContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={urls.PAYMENTS_URL}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <PaymentListContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={`${urls.MEMBERSHIP_TEMPLATES}${urls.CREATE_TEMPLATE}`}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <AddTemplateContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={`${urls.MEMBERSHIP_TEMPLATES}/:templateId`}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <EditTemplateContainer />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={urls.MEMBERSHIP_TEMPLATES}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <MembershipTemplateListContainer />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={urls.INVENTORY_OBJECTS_URL}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <InventoryObjectListContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={urls.INVENTORY_TRANSACTIONS_URL}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <InventoryTransactionListContainer
                        siteKey={defaultSiteKey}
                        taskID={null}
                        parentRecordID={null}
                        invoiceID={null}
                      />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={urls.INVENTORY_LOCATIONS_URL}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <InventoryLocationsListContainer
                        siteKey={defaultSiteKey}
                      />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={urls.SCHEDULING_URL}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <Suspense fallback={<LoadingComponentForPages />}>
                        <LazySchedulingContainer siteKey={defaultSiteKey} />
                      </Suspense>
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={urls.RESOURCES_URL}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <Suspense fallback={<LoadingComponentForPages />}>
                        <LazySchedulingDetailContainer
                          siteKey={defaultSiteKey}
                        />
                      </Suspense>
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={`${urls.WORK_RECORD_LIST_URL}/:craftTypeValue`}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <Suspense fallback={<LoadingComponentForPages />}>
                        <LazyWorkRecordListContainer siteKey={defaultSiteKey} />
                      </Suspense>
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={`${urls.DELETED_WORK_RECORD_LIST_URL}`}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <Suspense fallback={<LoadingComponentForPages />}>
                        <LazyDeletedWorkRecordListContainer
                          siteKey={defaultSiteKey}
                        />
                      </Suspense>
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={`${urls.TASK_LIST_URL}/:taskParams`}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <Suspense fallback={<LoadingComponentForPages />}>
                        <LazyTaskListContainer siteKey={defaultSiteKey} />
                      </Suspense>
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={`${urls.DELETED_TASK_LIST_URL}`}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <Suspense fallback={<LoadingComponentForPages />}>
                        <LazyDeletedTaskListContainer
                          siteKey={defaultSiteKey}
                        />
                      </Suspense>
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={urls.COMPLIANCE_URL}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <ComplianceContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              <Route
                path={urls.FEEDBACK}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <FeedbackListContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              {FEATURE_FLAG_QUICKBOOKS ? (
                <Route
                  path={urls.QUICKBOOKS_URL}
                  element={
                    <RouteAuthRequired>
                      {typeof defaultSiteKey === "string" ? (
                        <QuickbooksContainer siteKey={defaultSiteKey} />
                      ) : (
                        <></>
                      )}
                    </RouteAuthRequired>
                  }
                />
              ) : null}
              (
              <Route
                path={urls.LIGHTSPEED_URL}
                element={
                  <RouteAuthRequired>
                    {typeof defaultSiteKey === "string" ? (
                      <LightspeedContainer siteKey={defaultSiteKey} />
                    ) : (
                      <></>
                    )}
                  </RouteAuthRequired>
                }
              />
              )
              {FEATURE_FLAG_NEW_USER ? (
                <Route
                  path={urls.NEW_USER_URL}
                  element={
                    <RouteAuthRequired>
                      <NewUserContainer />
                    </RouteAuthRequired>
                  }
                />
              ) : null}
              {FEATURE_FLAG_APPLY_TO_EXISTING_SITE ? (
                <Route
                  path={urls.APPLY_URL}
                  element={
                    <RouteAuthRequired>
                      <ApplyToExistingSiteContainer />
                    </RouteAuthRequired>
                  }
                />
              ) : null}
              {/* Finish Auth Routes */}
              <Route
                path={`${urls.REVIEW_REQUEST}/:id`}
                element={<ReviewRequestContainer />}
              />
              {/* FEATURE FLAG! 🏴‍☠️ won't show up for builds that are connected to */}
              {/* the production database. */}
              {FEATURE_FLAG_CREATE_ACCOUNT ? (
                <Route
                  path={urls.CREATE_ACCOUNT_URL}
                  element={
                    <CreateAccountContainer destination={urls.APPLY_URL} />
                  }
                />
              ) : null}
              {FEATURE_FLAG_CREATE_TRIAL_ACCOUNT ? (
                <Route
                  path={urls.CREATE_TRIAL_ACCOUNT_URL}
                  element={
                    <CreateAccountContainer destination={urls.NEW_USER_URL} />
                  }
                />
              ) : null}
              {FEATURE_FLAG_HELP_SECTION ? (
                <Route path="/help" element={<Help />} />
              ) : null}
              <Route path={urls.LOGIN_URL} element={<LoginPage />} />
              <Route path={urls.AUTO_LOGIN_URL} element={<AutoLogin />} />
              {/* 404 Not Found Fallback Route */}
              <Route path="*" element={<NotFound404 />} />
            </Routes>
          </ErrorBoundary>
        </main>
      </BrowserRouter>
      <div className={"hidden"}>
        {`version: ${appVersion}
       commit: ${gitCommit}`}
      </div>
    </div>
  );
}

export default sentry.withProfiler(App);
