import React from "react";
import A2PBrandRegistrationPage from "./A2PBrandRegistrationPage";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { useEffect } from "react";

export default function A2PBrandRegistrationContainer() {
  const [siteKeyDoc, fetch] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.fetch,
  ]);

  useEffect(() => {
    if (siteKeyDoc?.id) {
      fetch(siteKeyDoc.id);
    }
  }, [fetch, siteKeyDoc?.id]);

  return <A2PBrandRegistrationPage />;
}
