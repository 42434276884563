//Libs

//Local
import { CraftRecord } from "../../models/craft-record";
import { Task } from "../../models/task";
import PillStatus from "../../components/PillStatus";
import { convertToReadableTimestampDate } from "../../assets/js/convertToReadableTimestamp";

interface Props {
  workRecord: CraftRecord;
  tasks: Task[];

  // components
  children: {
    workRecordPanel: React.ReactNode;
    tasksPanel: React.ReactNode;
    closeRecordBtn: React.ReactNode;
    deleteRecordBtn: React.ReactNode;
    dublicateJobBtn: React.ReactNode;
    feedbackBtn: React.ReactNode;
    jobPDFBtn: React.ReactNode;
    siteKeyLocationSection: React.ReactNode;
    jobCostingPanel: React.ReactNode;
  };
}

export default function WorkRecordAndTasksPage(props: Props): JSX.Element {
  return (
    <div className="mx-auto lg:max-w-7xl">
      <div className="mb-4 flex items-end justify-between gap-4">
        <h1 className="text-2xl font-semibold capitalize sm:text-3xl">
          {props.workRecord.title}
        </h1>
        <div className="shrink-0">{props.children.feedbackBtn}</div>
      </div>
      <div className="mb-6 justify-start md:flex">
        <PillStatus
          text={
            props.workRecord.open
              ? "Open"
              : `Closed on ${convertToReadableTimestampDate(
                  props.workRecord.timestampRecordClosed,
                )}`
          }
          color={props.workRecord.open ? "green" : "gray"}
          margin="mr-4 my-1 font-bold text-base"
        />
        {props.children.siteKeyLocationSection}

        <div className="grid min-w-max grid-cols-2 gap-4 justify-self-end sm:flex">
          {props.children.jobPDFBtn}
          {props.children.dublicateJobBtn}
          {props.children.closeRecordBtn}
          {props.children.deleteRecordBtn}
        </div>
      </div>

      <div className="flex flex-col gap-10">
        <div className="flex w-full flex-col gap-10 lg:flex-row lg:items-start">
          {props.children.workRecordPanel}
          <div className="flex flex-col gap-10">
            {props.children.tasksPanel}
            {props.children.jobCostingPanel}
          </div>
        </div>
      </div>
    </div>
  );
}
