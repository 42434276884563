//Libs
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useEffect, useState } from "react";

//Local
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import { useMembershipTemplatesStore } from "../../store/membership-templates";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import MembershipTemplateListPage from "./MembershipTemplateListPage";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import * as strings from "../../strings";
import { useNavToCreateEditMembershipTemplate } from "../../navigation";
import { ExistingMembershipTemplate } from "../../models/membership-template";
import { DbRead } from "../../database";

export default function MembershipTemplateListContainer() {
  const navToCreateEditTemplate = useNavToCreateEditMembershipTemplate();

  /* STORES */
  const [membershipTemplateList, membershipTemplateListIsLoading] =
    useMembershipTemplatesStore((state) => [
      state.membershipTemplates,
      state.loading,
    ]);
  const [siteKeyDoc, siteKeyDocIsLoading] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);

  const [templates, setTemplates] = useState<ExistingMembershipTemplate[]>([]);

  // we want to use the priceBookItem's unitPrice when displaying the template's
  // price, if there is a pbiID on the template. we're deprecating template.price.
  useEffect(() => {
    async function fetchPrice() {
      if (membershipTemplateList.length === 0) return;
      if (!siteKeyDoc) return;

      const promises = membershipTemplateList.map(async (t) => {
        if (!t.priceBookItemID) return t;
        const pbi = await DbRead.priceBookItems.get(
          siteKeyDoc.id,
          t.priceBookItemID,
        );
        if (!pbi) return t;
        return { ...t, price: pbi.unitPrice };
      });
      const updated = await Promise.all(promises);
      setTemplates(updated);
    }
    fetchPrice();
  }, [membershipTemplateList, siteKeyDoc]);

  /* COMPONENTS */
  const addTemplateButton = (
    <BaseButtonPrimary
      type="button"
      onClick={() => navToCreateEditTemplate()}
      className="w-full text-primary sm:w-auto"
    >
      <AddCircleIcon fontSize="small" className="mr-2" />
      {strings.CREATE_TEMPLATE}
    </BaseButtonPrimary>
  );

  /* RENDER LOADING */
  if (membershipTemplateListIsLoading || siteKeyDocIsLoading) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  return (
    <MembershipTemplateListPage
      membershipTemplateList={templates}
      currency={siteKeyDoc?.customizations.accounting?.currency ?? "USD"}
      addTemplateButton={addTemplateButton}
      onEditTemplate={(t) => navToCreateEditTemplate(t.id, t)}
    />
  );
}
