//Libs
import { z } from "zod";
import { DocumentData, DocumentSnapshot, Timestamp } from "firebase/firestore";

//Local
import { dropUndefined } from "../utils/dropUndefined";
import { guardIsPlainObject } from "../utils/isPlainObject";
import { NotFoundError } from "../error-classes";

// Base interfaces for both reason and source
interface CancellationReason {
  title: string;
  description: string | null;
  timestampCreated: Timestamp;
  createdBy: string;
  active: boolean;
  requireNotes: boolean;
}

interface CancellationSource {
  title: string;
  description: string | null;
  timestampCreated: Timestamp;
  createdBy: string;
  active: boolean;
}

export interface ExistingCancellationReason extends CancellationReason {
  id: string;
  refPath: string;
}

export interface ExistingCancellationSource extends CancellationSource {
  id: string;
  refPath: string;
}

/***********************/
// FOR THE API
/***********************/
type createWithoutTimestampsAndCreatedBy<T> = Omit<
  T,
  "timestampCreated" | "createdBy" | "active"
>;

// API interfaces for Reason
export interface CancellationReason_CreateAPI
  extends createWithoutTimestampsAndCreatedBy<CancellationReason> {
  siteKey: string;
}

export type CancellationReason_UpdateAPI = Omit<
  Partial<ExistingCancellationReason>,
  "timestampCreated"
>;

// API interfaces for Source
export interface CancellationSource_CreateAPI
  extends createWithoutTimestampsAndCreatedBy<CancellationSource> {
  siteKey: string;
}

export type CancellationSource_UpdateAPI = Omit<
  Partial<ExistingCancellationSource>,
  "timestampCreated"
>;

export const CancellationManager = {
  Reason: {
    convertForFirestore: convertReasonForFirestore,
    createFromFirestoreSnapshot: createReasonFromFirestoreSnapshot,
    parse: validateReason,
  },
  Source: {
    convertForFirestore: convertSourceForFirestore,
    createFromFirestoreSnapshot: createSourceFromFirestoreSnapshot,
    parse: validateSource,
  },
} as const;

/***********************/
// REASON FUNCTIONS
/***********************/
function convertReasonForFirestore(
  reason: ExistingCancellationReason | CancellationReason_UpdateAPI,
): DocumentData {
  const local = Object.assign({}, reason);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, refPath, ...rest } = local;
  return dropUndefined(rest);
}

function createReasonFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingCancellationReason {
  if (!snapshot.exists) {
    throw new NotFoundError("Document does not exist.");
  }

  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateReason(snapshot.data()),
  };
}

/***********************/
// SOURCE FUNCTIONS
/***********************/
function convertSourceForFirestore(
  source: ExistingCancellationSource | CancellationSource_UpdateAPI,
): DocumentData {
  const local = Object.assign({}, source);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, refPath, ...rest } = local;
  return dropUndefined(rest);
}

function createSourceFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingCancellationSource {
  if (!snapshot.exists) {
    throw new NotFoundError("Document does not exist.");
  }

  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateSource(snapshot.data()),
  };
}

/***********************/
// VALIDATION FUNCTIONS
/***********************/
// Reason validation
function validateReason(value: unknown): CancellationReason {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return cancellationReasonSchema.parse(value);
}

// Source validation
function validateSource(value: unknown): CancellationSource {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return cancellationSourceSchema.parse(value);
}

/***********************/
// SCHEMAS
/***********************/
// Base schema for both reason and source
const cancellationBaseSchema = z.object({
  title: z.string().min(1).max(200),
  description: z.string().min(0).max(500).nullable(),
  timestampCreated: z.instanceof(Timestamp),
  createdBy: z.string().min(1).max(200),
  active: z.boolean(),
  requireNotes: z.boolean(),
});

// Reason schemas
const cancellationReasonSchema = cancellationBaseSchema;

// Source schemas
const cancellationSourceSchema = cancellationBaseSchema.omit({
  requireNotes: true,
});
