//Libs
import { z } from "zod";
import { useEffect, useMemo, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { XMarkIcon } from "@heroicons/react/24/solid";

//Local
import { ExistingStiltInvoice } from "../../models/invoice";
import StyledMessage from "../StyledMessage";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import * as strings from "../../strings";
import { ErrorMessage } from "../ErrorMessage";
import BaseInputText from "../BaseInputText";
import BaseModal from "../BaseModal";
import BaseInputTextArea from "../BaseInputTextArea";
import { DbRead } from "../../database";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import HeadingOne from "../HeadingOne";

interface Props {
  //DATA
  isDialogOpen: boolean;
  invoiceDoc: ExistingStiltInvoice;
  issueDate: React.ReactNode;
  dueDate: React.ReactNode;
  paymentTerms: React.ReactNode;
  closeDialog: () => void;
  //FUNCTIONS
  handleSave: (formValues: EditInvoiceFormState) => Promise<void>;
}

export const EditInvoiceFormSchema = z.object({
  note: z.string().min(0).max(8000),
  internalNotes: z.string().max(8000).nullable(),
  poNumber: z.string().min(1).max(200).nullable(),
  email: z.string().min(0).max(200).nullable(),
});

export type EditInvoiceFormState = z.infer<typeof EditInvoiceFormSchema>;

export default function EditInvoiceDialog(props: Props) {
  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);
  const [displayError, setDisplayError] = useState<boolean>(false);
  const [isLoadingStiltonNotes, setIsLoadingStiltonNotes] = useState(false);
  const [stiltonNotes, setStiltonNotes] = useState<string | null>(null);

  const editInvoiceFormDefaultValue: EditInvoiceFormState = useMemo(() => {
    return {
      poNumber: props.invoiceDoc.poNumber,
      internalNotes: props.invoiceDoc.internalNotes,
      note: props.invoiceDoc.note,
      email: props.invoiceDoc.email,
    };
  }, [props.invoiceDoc]);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
    getValues,
  } = useForm<EditInvoiceFormState>({
    defaultValues: editInvoiceFormDefaultValue,
    resolver: zodResolver(EditInvoiceFormSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(editInvoiceFormDefaultValue);
  }, [editInvoiceFormDefaultValue, reset]);

  function closeAndReset() {
    reset();
    setDisplayError(false);
    props.closeDialog();
  }

  const errorMessage = (
    <ErrorMessage
      message="Value hasn't changed."
      clearMessage={() => setDisplayError(false)}
    />
  );

  const onSubmit: SubmitHandler<EditInvoiceFormState> = async (formValues) => {
    const valuesToSend: EditInvoiceFormState = {
      ...formValues,
      internalNotes:
        formValues.internalNotes === "" ? null : formValues.internalNotes,
      poNumber: formValues.poNumber === "" ? null : formValues.poNumber,
      email: formValues.email === "" ? null : formValues.email,
    };

    await props.handleSave(valuesToSend);
    // Close dialog if successful.
    closeAndReset();
  };

  const handleGetStiltonNotes = async () => {
    if (!siteKeyDoc) return;
    setIsLoadingStiltonNotes(true);
    try {
      const notes = await DbRead.stilton.getSummarizedInvoiceNotes({
        siteKeyID: siteKeyDoc.id,
        invoiceID: props.invoiceDoc.id,
      });
      setStiltonNotes(notes);
    } catch (error) {
      console.error("Error getting Stilton notes:", error);
    } finally {
      setIsLoadingStiltonNotes(false);
    }
  };

  const handleAcceptStiltonNotes = () => {
    if (stiltonNotes) {
      reset({ ...getValues(), note: stiltonNotes });
      setStiltonNotes(null);
    }
  };

  const editInvoiceHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <HeadingOne fontColor="text-white" fontSize="text-xl">
        {strings.buttons.EDIT_INVOICE}
      </HeadingOne>
      <button type="button" onClick={() => closeAndReset()}>
        <XMarkIcon
          aria-label="close new requirement form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={closeAndReset}
      open={props.isDialogOpen}
      title={editInvoiceHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="relative flex flex-col p-8 text-lg">
        <div className="mb-8 flex items-center justify-between">
          {props.issueDate}
          {props.dueDate}
        </div>
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-8"
        >
          {/* Field: PO Number */}
          <div>
            <Controller
              name="poNumber"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  text="PO Number"
                  inputName="poNumber"
                  admin={true}
                  required={false}
                  {...field}
                  value={field.value === null ? "" : field.value}
                />
              )}
            />
            {errors.poNumber?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.poNumber.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          {/* Field: Payment Terms Type */}
          {props.paymentTerms}

          {/* Field: Note */}
          <div>
            <div className="mb-2 flex items-center justify-between">
              <Controller
                name="note"
                control={control}
                render={({ field }) => (
                  <BaseInputTextArea
                    className="w-full"
                    text="Note"
                    placeholder={strings.PLACEHOLDER_VISIBLE_NOTE}
                    inputName="note"
                    admin={true}
                    required={false}
                    rows={3}
                    {...field}
                    value={field.value == null ? "" : field.value}
                  />
                )}
              />
              <button
                onClick={handleGetStiltonNotes}
                disabled={isLoadingStiltonNotes}
                className="ml-2 flex items-center gap-2 rounded-lg bg-purple-600 px-3 py-2 text-sm font-medium text-white transition-colors hover:bg-purple-700 disabled:cursor-default disabled:opacity-50"
              >
                <img
                  src="/white_hardhat_transparent_bg.svg"
                  alt="Stilton AI Icon"
                  className="h-4 w-4"
                />
                {isLoadingStiltonNotes ? (
                  <div className="relative flex h-4 w-4 items-center justify-center">
                    <div className="absolute h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent"></div>
                  </div>
                ) : (
                  strings.STILTON.SUMMARIZE
                )}
              </button>
            </div>

            {stiltonNotes && (
              <div className="mt-4 rounded-lg border border-purple-200 bg-purple-50 p-4">
                <h4 className="mb-2 font-semibold text-purple-900">
                  Stilton AI Suggested Notes:
                </h4>
                <p className="mb-4 text-sm text-gray-700">{stiltonNotes}</p>
                <div className="flex gap-2">
                  <button
                    onClick={handleAcceptStiltonNotes}
                    className="rounded-lg bg-purple-600 px-4 py-2 text-sm font-medium text-white hover:bg-purple-700"
                  >
                    Accept
                  </button>
                  <button
                    onClick={() => setStiltonNotes(null)}
                    className="rounded-lg bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300"
                  >
                    Dismiss
                  </button>
                </div>
              </div>
            )}

            {errors.note?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.note.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          {/* Field: Email */}
          <div>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <BaseInputTextArea
                  text="Email"
                  inputName="email"
                  admin={true}
                  required={false}
                  rows={1}
                  {...field}
                  value={field.value == null ? "" : field.value}
                />
              )}
            />
            {errors.email?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.email.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          {/* Field: Internal Notes */}
          <div>
            <Controller
              name="internalNotes"
              control={control}
              render={({ field }) => (
                <BaseInputTextArea
                  text="Internal Notes"
                  placeholder={strings.PLACEHOLDER_INTERNAL_NOTE}
                  inputName="internalNotes"
                  admin={true}
                  required={false}
                  rows={3}
                  {...field}
                  value={field.value == null ? "" : field.value}
                />
              )}
            />
            {errors.internalNotes?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.internalNotes.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          {/* Action Buttons */}
          <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row sm:col-span-3 sm:col-start-1">
            <BaseButtonSecondary
              type="button"
              className="w-full justify-center uppercase"
              onClick={closeAndReset}
            >
              {strings.buttons.CANCEL}
            </BaseButtonSecondary>

            <BaseButtonPrimary
              type="submit"
              formNoValidate
              disabled={isSubmitting}
              isBusy={isSubmitting}
              busyText={strings.buttons.BUSY_SAVING}
              className="w-full justify-center uppercase"
            >
              {strings.buttons.SAVE}
            </BaseButtonPrimary>
          </div>
        </form>
        {displayError ? (
          <span className="absolute bottom-10 left-1/2 w-3/4 -translate-x-1/2 sm:w-96">
            {errorMessage}
          </span>
        ) : null}
      </div>
    </BaseModal>
  );
}
