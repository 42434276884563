import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ArrowLeftIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import { SMS_DASHBOARD_URL } from "../../urls";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { TwilioSmsRegistrationRequest } from "../../models/twilio";
import { DbWrite } from "../../database";
import { logger } from "../../logging";
import BusinessRegistrationForm from "../../components/sms/A2PBrandRegistrationForm";
import { createToastMessageID } from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";

export default function A2PBrandRegistrationPage() {
  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState<
    Partial<TwilioSmsRegistrationRequest>
  >({});

  useEffect(() => {
    const fetchRegistrationStatus = async () => {
      if (!siteKeyDoc) return;

      try {
        const smsSettings = siteKeyDoc?.customizations?.sms;

        if (smsSettings?.a2pBrandId) {
          // const status = await DbRead.twilio.getA2PRegistrationStatus(
          //   siteKeyDoc.id,
          //   smsSettings.a2pBrandId
          // );
          // setRegistrationStatus(status);

          // Note: This would require backend support to include registration data in the response
          // For now, we'll just use any existing data from the site key doc
          if (siteKeyDoc?.customizations?.sms?.registrationData) {
            setFormData(siteKeyDoc.customizations.sms.registrationData);
          }
        }
      } catch (err) {
        logger.error("Error fetching A2P registration status:", err);
        addToastMessage({
          id: createToastMessageID(),
          message: "Failed to load registration status",
          dialog: false,
          type: "error",
        });
      }
    };

    fetchRegistrationStatus();
  }, [siteKeyDoc]);

  const handleSubmit = async (data: TwilioSmsRegistrationRequest) => {
    if (!siteKeyDoc) return;

    try {
      setIsSubmitting(true);

      // Register the business for SMS using the unified endpoint
      const result = await DbWrite.twilio.registerSmsService(
        siteKeyDoc.id,
        data,
      );

      if (result.success) {
        // Success toast message
        addToastMessage({
          id: createToastMessageID(),
          message: result.message,
          dialog: false,
          type: "success",
        });
      } else {
        // Error toast message
        addToastMessage({
          id: createToastMessageID(),
          message: result.message,
          dialog: false,
          type: "error",
        });
      }
    } catch (err) {
      logger.error("Error registering business for Twilio SMS:", err);
      addToastMessage({
        id: createToastMessageID(),
        message: "Failed to submit business information. Please try again.",
        dialog: false,
        type: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const registrationStatus: string = "not-started";

  return (
    <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
      <div className="mb-8 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <div className="flex items-center">
            <Link to={SMS_DASHBOARD_URL} className="mr-4">
              <ArrowLeftIcon className="h-5 w-5 text-gray-400 hover:text-gray-600" />
            </Link>
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              Business & Legal Information
            </h2>
          </div>
          <p className="mt-1 text-sm text-gray-500">
            This information is required for regulatory compliance to enable SMS
            messaging
          </p>
        </div>
      </div>

      {registrationStatus === "approved" ? (
        <div className="mb-8 rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">
                Business Information Approved
              </h3>
              <div className="mt-2 text-sm text-green-700">
                <p>
                  Your business information has been approved for SMS messaging.
                  Your campaign is being set up automatically.
                </p>
              </div>
              <div className="mt-4">
                <div className="-mx-2 -my-1.5 flex">
                  <Link
                    to={SMS_DASHBOARD_URL}
                    className="rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                  >
                    Return to Dashboard
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : registrationStatus === "approved" ? (
        <div className="mb-8 rounded-md bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">
                Business Information Pending
              </h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>
                  Your business information is currently under review. This
                  process typically takes 1-3 business days.
                </p>
              </div>
              <div className="mt-4">
                <div className="-mx-2 -my-1.5 flex">
                  <Link
                    to={SMS_DASHBOARD_URL}
                    className="rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50"
                  >
                    Return to Dashboard
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Business Information
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Please provide accurate information about your business for SMS
              compliance
            </p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <BusinessRegistrationForm
              onSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              initialData={formData}
            />
          </div>
        </div>
      )}
    </div>
  );
}
