//Libs

//Local
import { ComplianceCompany } from "../../models/compliance-types-and-statuses";
import * as strings from "../../strings";
import HeadingOne from "../HeadingOne";
import LoadingClipboardAnimation from "../LoadingClipBoardAnimation";

interface Props {
  company: ComplianceCompany | null;
  children: {
    goToMainView: React.ReactNode;
    ComplianceAccordionResponseList: React.ReactNode;
  };
}

export default function CompanyDashboard({ company, children }: Props) {
  if (company == null) return <LoadingClipboardAnimation />;

  return (
    <div className="w-full space-y-9 py-16 md:col-span-3">
      <div>
        {children.goToMainView}
        {/* Title */}
        <HeadingOne className="inline-block">{company.name}</HeadingOne>
        <p className="text-lg text-gray-700 ">{company.address}</p>
      </div>
      {/* Requirements Section */}
      <div className="space-y-5">
        <h2 className="inline-block text-2xl font-bold text-gray-700 sm:text-3xl">
          {strings.COMPLIANCE_REQUIREMENTS}
        </h2>
        {/* Requirements summary disclosures */}
        {children.ComplianceAccordionResponseList}
      </div>
    </div>
  );
}
