//Libs
import React, {
  Fragment,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import isEqual from "lodash/isEqual";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import AddBoxIcon from "@mui/icons-material/AddBox";

//Local
import * as strings from "../../strings";
import SearchBox from "../../components/SearchBox";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
// This is for tables that use percentage-based heights - ie `h-full min-h-[500px]`
import "../../assets/css/ag-grid-inject-height.css";
import { ExistingInventoryLocation } from "../../models/inventory-location";
import HeadingOne from "../../components/HeadingOne";

interface Props {
  /* DATA */
  inventoryLocationsList: ExistingInventoryLocation[];
  /* FUNCTIONS */
  onAddInventoryLocation: () => void;
  /* CHILDREN */
  children: {
    AddInventoryLocation: React.ReactNode;
  };
}

export default function InventoryLocationsListPage({
  inventoryLocationsList,
  onAddInventoryLocation,
  ...props
}: Props) {
  return (
    <div className={"ag-theme-alpine flex h-full min-h-[600px] flex-col"}>
      <HeadingOne className="mb-8 mt-4">
        {strings.INVENTORY_LOCATIONS}
      </HeadingOne>
      <InventoryLocationListTable
        inventoryLocationsList={inventoryLocationsList}
        onAddInventoryLocation={onAddInventoryLocation}
      />
      {props.children.AddInventoryLocation}
    </div>
  );
}

const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

const commonColProps = {
  // width: 150,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
};

interface InventoryLocationListTableProps {
  inventoryLocationsList: Props["inventoryLocationsList"];
  onAddInventoryLocation: Props["onAddInventoryLocation"];
}

/* TABLE COMPONENT */
const InventoryLocationListTable = React.memo(
  ({
    inventoryLocationsList,
    onAddInventoryLocation,
  }: InventoryLocationListTableProps) => {
    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<any>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        gridRef.current.api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    const onFilterTextBoxChanged = useCallback(() => {
      gridRef.current!.api.setQuickFilter(
        (document.getElementById("filter-text-box") as HTMLInputElement).value,
      );
    }, []);

    const renderObjectTitle = (params: ICellRendererParams): JSX.Element => {
      return <div className="capitalize">{params.data?.title}</div>;
    };

    const columnDefs: ColDef[] = [
      {
        headerName: "Title",
        field: "title",
        cellRenderer: renderObjectTitle,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
    ];

    return (
      <Fragment>
        <div className="flex flex-col-reverse items-center gap-4 sm:flex-row sm:items-end sm:justify-between">
          <SearchBox
            onInput={onFilterTextBoxChanged}
            widthClasses="w-full max-w-md sm:w-96"
          />
          <button
            type="button"
            onClick={onAddInventoryLocation}
            className="text-primary"
          >
            <AddBoxIcon
              aria-label="Add Inventory Transaction"
              sx={{ fontSize: 45 }}
            />
          </button>
        </div>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={commonColProps}
          className="mt-5 shadow"
          rowData={inventoryLocationsList}
          animateRows={true}
          rowSelection="single"
          cacheQuickFilter={true}
          columnDefs={columnDefs}
        />
      </Fragment>
    );
  },
  (previous, next) => {
    const isListTheSame = isEqual(
      previous.inventoryLocationsList,
      next.inventoryLocationsList,
    );
    return isListTheSame;
  },
);
