//Libs
import { useNavigate, useLocation } from "react-router-dom";

//Local
import {
  CREATE_ESTIMATE_URL,
  CUSTOMERS_URL,
  TASK_DETAILS_URL,
  MEMBERSHIP_TEMPLATES,
  CREATE_TEMPLATE,
  ESTIMATES_URL_AUTH,
  CUSTOMERS_CREATE_TASK_URL,
} from "./urls";
import * as urls from "./urls";
import { ExistingCustomer } from "./models/customer";
import { ExistingTask } from "./models/task";
import { ExistingCustomerLocation } from "./models/customer-location";
import { ExistingEstimate } from "./models/estimate";
import { ExistingStiltInvoice } from "./models/invoice";
import { ExistingMembershipTemplate } from "./models/membership-template";
import { ReportConfig, ReportSpec } from "./models/reports";

interface StiltNav {
  calls: {
    edit: (callID: string) => void;
  };
  customerPage: (customerID: string) => void;
  taskPage: (taskID: string) => void;
  createTaskForCustomer: (args: NavToCreateCustomerTask) => void;
  customerSearchPage: (args: { query: string }) => void;
  /**
   * Returns the query parameters in the URL as a dictionary.
   */
  useLocationState: () => Record<string, string | undefined>;
  notFoundPage: () => void;
  reportSpecPage: (args: {
    reportType: string;
    reportSpec: ReportSpec;
    reportConfig?: ReportConfig;
  }) => void;
}

/**
 * A custom hook to wrap the underlying router navigation.
 */
export function useStiltNav(): StiltNav {
  return {
    calls: {
      edit: useNavToCallEdit(),
    },
    customerPage: useNavToCustomer(),
    taskPage: useNavToChecklistDetails(),
    createTaskForCustomer: useNavToCreateCustomerTask(),
    customerSearchPage: useNavToCustomerSearch(),
    useLocationState: useLocationState,
    notFoundPage: useNotFoundPage(),
    reportSpecPage: useNavToReportSpecPage(),
  };
}

function isRecord(value: unknown): value is Record<string, unknown> {
  return typeof value === "object" && value !== null && !Array.isArray(value);
}

/**
 * React Router implementation to get query parameters in the URL.
 */
function useLocationState(): Record<string, string | undefined> {
  const location = useLocation();
  const state = location.state;
  if (isRecord(state)) {
    return Object.entries(state).reduce(
      (acc, [key, value]) => {
        if (typeof value === "string" || typeof value === "undefined") {
          acc[key] = value;
        }
        return acc;
      },
      {} as Record<string, string | undefined>,
    );
  } else {
    return {};
  }
}

function useNotFoundPage() {
  const navigate = useNavigate();
  return () => navigate(urls.NOT_FOUND_URL);
}

/**
 * Private function
 */
function useNavToCustomer() {
  const navigate = useNavigate();
  return (customerID: string) => navigate(`${CUSTOMERS_URL}/${customerID}`);
}

export function useNavToChecklistDetails() {
  const navigate = useNavigate();
  return (checklistTaskID: string, state?: Record<string, string>) =>
    navigate(`${TASK_DETAILS_URL}/${checklistTaskID}`, { state: state });
}

export function useNavToCustomerSearch() {
  const navigate = useNavigate();
  return (args: { query: string }) =>
    navigate(`${CUSTOMERS_URL}`, { state: { query: args.query } });
}
export function useNavToCallEdit() {
  const navigate = useNavigate();
  return (callId: string) => navigate(`${urls.CALLS_LIST_URL}/${callId}/edit`);
}

export interface NavToCreateEstimateLocationState {
  customerDoc: ExistingCustomer;
  taskDoc?: ExistingTask;
}

export function useNavToCreateEstimate() {
  const navigate = useNavigate();
  return (customerDoc: ExistingCustomer, taskDoc?: ExistingTask) =>
    navigate(`${CUSTOMERS_URL}${CREATE_ESTIMATE_URL}`, {
      state: {
        customerDoc: customerDoc,
        taskDoc: taskDoc,
      },
    });
}

export interface NavToViewEstimateLocationState {
  customerDoc: ExistingCustomer;
  customerLocationDoc: ExistingCustomerLocation;
  invoiceDoc: ExistingStiltInvoice | null;
}

export function useNavToViewEstimate() {
  const navigate = useNavigate();
  return (
    estimateID: string,
    customerDoc: ExistingCustomer,
    customerLocationDoc: ExistingCustomerLocation,
    invoiceDoc: ExistingStiltInvoice | null,
  ) =>
    navigate(`${CUSTOMERS_URL}${ESTIMATES_URL_AUTH}/${estimateID}`, {
      state: {
        customerDoc,
        customerLocationDoc,
        invoiceDoc,
      },
    });
}

export interface TaskCreationMembershipData {
  membershipID: string;
  membershipTemplateID: string;
}

interface NavToCreateCustomerTask {
  craftRecordID?: string | null;
  customerID?: string;
  customerLocation?: ExistingCustomerLocation;
  estimateDoc?: ExistingEstimate;
  membershipData?: TaskCreationMembershipData;
  locationID?: string;
  assetID?: string;
}

function useNavToCreateCustomerTask() {
  const navigate = useNavigate();
  return (args: NavToCreateCustomerTask) => {
    if (args.craftRecordID != null && args.customerID != null) {
      navigate(
        `${CUSTOMERS_CREATE_TASK_URL}/${args.customerID}/${args.craftRecordID}`,
        {
          state: {
            customerLocation: args.customerLocation,
            estimateDoc: args.estimateDoc,
            membershipData: args.membershipData,
            locationID: args.locationID,
            assetID: args.assetID,
          },
        },
      );
    } else if (args.customerID != null) {
      navigate(`${CUSTOMERS_CREATE_TASK_URL}/${args.customerID}`, {
        state: {
          customerLocation: args.customerLocation,
          estimateDoc: args.estimateDoc,
          membershipData: args.membershipData,
          locationID: args.locationID,
          assetID: args.assetID,
        },
      });
    } else {
      navigate(`${CUSTOMERS_CREATE_TASK_URL}`, {
        state: {
          customerLocation: args.customerLocation,
          estimateDoc: args.estimateDoc,
          membershipData: args.membershipData,
          locationID: args.locationID,
          assetID: args.assetID,
        },
      });
    }
  };
}

export interface NavToCreateEditMembershipTemplateLocationState {
  membershipTemplateDoc: ExistingMembershipTemplate;
}

export function useNavToCreateEditMembershipTemplate() {
  const navigate = useNavigate();
  return (
    templateID?: string,
    membershipTemplateDoc?: ExistingMembershipTemplate,
  ) => {
    if (templateID && membershipTemplateDoc) {
      navigate(`${MEMBERSHIP_TEMPLATES}/${templateID}`, {
        state: {
          membershipTemplateDoc,
        },
      });
    } else {
      navigate(`${MEMBERSHIP_TEMPLATES}${CREATE_TEMPLATE}`);
    }
  };
}

function useNavToReportSpecPage() {
  const navigate = useNavigate();
  return (args: {
    reportType: string;
    reportSpec: ReportSpec;
    reportConfig?: ReportConfig;
  }) => {
    if (args.reportConfig) {
      return navigate(`${urls.REPORTS_URL}/${args.reportType}`, {
        state: { reportSpec: args.reportSpec, reportConfig: args.reportConfig },
      });
    }

    return navigate(`${urls.REPORTS_URL}/${args.reportType}`, {
      state: { reportSpec: args.reportSpec },
    });
  };
}
