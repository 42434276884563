// Libs
import { useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import { useMutation } from "react-query";
import cloneDeep from "lodash/cloneDeep";
import React from "react";
import SyncIcon from "@mui/icons-material/Sync";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";

// Local
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import BaseButtonSecondary from "../BaseButtonSecondary";
import {
  ExistingStiltInvoice,
  StiltInvoice_UpdateAPI,
  StiltInvoiceManager,
  StiltLineItem,
} from "../../models/invoice";
import {
  ExistingStiltPayment,
  StiltLineItemFormData,
  UnauthedPaymentMade,
} from "../../models/stilt-payment";
import InvoiceSummary from "./InvoiceSummary";
import { DbRead, DbWrite, hitAcctSync } from "../../database";
import LoadingSpinner from "../LoadingSpinner";
import { createToastMessageID } from "../../utils";
import InvoiceHeader from "./InvoiceHeader";
import { ExistingTask } from "../../models/task";
import {
  ExistingCustomer,
  getBillingInfoFromCustomerAndLocations,
} from "../../models/customer";
import { ExistingCustomerLocation } from "../../models/customer-location";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import BaseButtonPrimary from "../BaseButtonPrimary";
import { ErrorMessage } from "../ErrorMessage";
import ButtonColored from "../ButtonColored";
import {
  convertToReadableTimestamp,
  convertToReadableTimestampDate,
} from "../../assets/js/convertToReadableTimestamp";
import { StyledTooltip } from "../StyledTooltip";
import { XIconWithRef } from "../XCloseButton";
import { PencilIconWithRef } from "../PencilEditButton";
import { logger } from "../../logging";
import { useToastMessageStore } from "../../store/toast-messages";
import MultipleEmailSelector from "../MultipleEmailSelector";
import { getEmailList } from "../../utils/getEmailList";
import StyledSwitchGroup from "../StyledSwitchGroup";
import { ExistingCraftRecord } from "../../models/craft-record";
import { CUSTOMERS_URL, WORK_RECORD_AND_TASKS_URL } from "../../urls";
import { generatePDF } from "../Invoices/generatePDF";
import { useUserPermissionsStore } from "../../store/user-permissions";
import {
  AccountingSyncStatus,
  getReadableAccountingSyncStatus,
} from "../../models/accounting-sync";
import CompareInvoiceDialog from "../Quickbooks/CompareInvoiceDialog";

interface Props {
  // DIALOG BASICS
  isOpen: boolean;
  onClose: () => void;

  // DATA
  siteKeyID: string;
  invoiceID: string;
  merchantName: string;
  merchantLogoURL: string | null;
  userIsSiteAdmin: boolean;
  userDisplayNamesMap: Record<string, string>;

  // FUNCTION
  handleRefund: (paymentID: string, refundAmount: number) => Promise<void>;
  handlePayment: (invoiceID: string) => void;
  editInvoice: () => void;
  sendEmail: (emailList: string[], includeJobPhotos: boolean) => Promise<void>;
  deleteInvoice: (siteKeyID: string, invoiceID: string) => Promise<void>;
  handleDeletePayment: (siteKeyID: string, paymentID: string) => Promise<void>;

  children: {
    EditInvoiceDialog: React.ReactNode;
    HandlePaymentDialog: React.ReactNode;
  };
}

export default function ViewInvoiceDialog(props: Props): JSX.Element {
  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);
  const addMessage = useToastMessageStore((state) => state.addToastMessage);
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSavingLineItems, setIsSavingLineItems] = useState(false);
  const [invoice, setInvoice] = useState<ExistingStiltInvoice | null>(null);
  const [payments, setPayments] = useState<ExistingStiltPayment[] | null>(null);
  const [unauthedPayments, setUnauthedPayments] = useState<
    UnauthedPaymentMade[] | null
  >(null);
  const [taskDoc, setTaskDoc] = useState<ExistingTask | null>(null);
  const [parentRecordDoc, setParentRecordDoc] =
    useState<ExistingCraftRecord | null>(null);
  const [customerDoc, setCustomerDoc] = useState<ExistingCustomer | null>(null);
  const [customerLocationDoc, setCustomerLocationDoc] =
    useState<ExistingCustomerLocation | null>(null);
  const [saveItemsErrorStr, setSaveItemsErrorStr] = useState<string | null>(
    null,
  );
  const [isSending, setIsSending] = useState(false);
  const [invoiceErrorStr, setInvoiceErrorStr] = useState<string | null>(null);
  const [editLineItems, setEditLineItems] = useState(false);
  const [temporaryInvoiceLineItemList, setTemporaryInvoiceLineItemList] =
    useState<StiltLineItemFormData[]>([]);
  const [originalInvoiceLineItemList, setOriginalInvoiceLineItemList] =
    useState<StiltLineItemFormData[]>([]);
  const [selectedEmailList, setSelectedEmailList] = useState<string[]>([]);
  const [emailErrorStr, setEmailErrorStr] = useState<string | null>(null);
  const [includeJobPhotos, setIncludeJobPhotos] = useState(true);
  const [isSavingGlobalDiscount, setIsSavingGlobalDiscount] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isCompareInvoiceDialogOpen, setIsCompareInvoiceDialogOpen] =
    useState(false);

  // Check if Quickbooks integration is enabled
  const isQuickbooksEnabled: boolean = React.useMemo(() => {
    return (
      siteKeyDoc?.customizations?.accounting?.pushToCodat ||
      siteKeyDoc?.customizations?.accounting?.pushToConductor ||
      siteKeyDoc?.customizations?.accounting?.pushToQBO
    );
  }, [siteKeyDoc]);

  useEffect(() => {
    if (invoice) {
      // Line Items
      setTemporaryInvoiceLineItemList(getStiltLineItemFormData(invoice));
      setOriginalInvoiceLineItemList(getStiltLineItemFormData(invoice));
      if (invoice.email && invoice.email !== "") {
        setSelectedEmailList([invoice.email]);
      }
    }
  }, [invoice]);

  useEffect(() => {
    if (siteKeyDoc) {
      setIncludeJobPhotos(
        siteKeyDoc.customizations.defaultIncludeJobPhotos ?? false,
      );
    }
  }, [siteKeyDoc]);

  useEffect(() => {
    async function fetchData() {
      if (!invoice) return;

      let task: ExistingTask | null = null;
      let parentRecord: ExistingCraftRecord | null = null;
      try {
        task = invoice.taskID
          ? await DbRead.tasks.getDocByID(props.siteKeyID, invoice.taskID)
          : null;
        parentRecord = invoice.craftRecordID
          ? await DbRead.parentRecords.get(
              props.siteKeyID,
              invoice.craftRecordID,
            )
          : null;
      } catch (error) {
        console.log("Error fetching task", error);
      }

      const morePromises = [
        DbRead.customers.get(props.siteKeyID, invoice.customerID),
        DbRead.customerLocations.getSingle(
          props.siteKeyID,
          invoice.customerLocationID,
        ),
      ] as const;
      const [customer, customerLocation] = await Promise.all(morePromises);

      // setIsLoading(false);
      setTaskDoc(task);
      setParentRecordDoc(parentRecord);
      setCustomerDoc(customer);
      setCustomerLocationDoc(customerLocation);
    }

    fetchData();
  }, [invoice, props.siteKeyID]);

  useEffect(() => {
    function getPayments() {
      const unsubscribe = DbRead.payments.subscribeAllByInvoiceID({
        siteKey: props.siteKeyID,
        invoiceID: props.invoiceID,
        onChange: setPayments,
      });
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getPayments();
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.invoiceID, props.siteKeyID]);

  useEffect(() => {
    if (!payments) return;

    const unauthedPaymentsMade: UnauthedPaymentMade[] = [];
    for (const p of payments) {
      const payment: UnauthedPaymentMade = {
        amount: p.amount,
        checkNumber: p.checkNumber,
        memo: null,
        paymentMethod: p.paymentMethod,
        cardType: p.cardType,
        lastFour: p.lastFour,
        nameOnCard: p.nameOnCard,
        paymentType: p.paymentType,
        timestampPaymentMade: p.timestampPaymentMade.toDate().toISOString(),
      };
      unauthedPaymentsMade.push(payment);
    }

    setUnauthedPayments(unauthedPaymentsMade);
  }, [payments]);

  useEffect(() => {
    function getInvoice() {
      const unsubscribe = DbRead.invoices.subscribe({
        siteKey: props.siteKeyID,
        invoiceID: props.invoiceID,
        onChange: setInvoice,
      });
      // return ƒn for cleanup
      return unsubscribe;
    }

    const unsubscribeFn = getInvoice();
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.invoiceID, props.siteKeyID]);

  useEffect(() => {
    if (!payments) return;
    if (!invoice) return;
    setIsLoading(false);
  }, [invoice, payments]);

  const emailList = getEmailList(invoice, customerDoc);

  const mutateUpdateInvoice = useMutation(
    async (args: { validPartialInvoiceData: StiltInvoice_UpdateAPI }) => {
      await DbWrite.invoices.update(args.validPartialInvoiceData);
    },
  );

  function closeDialog() {
    setInvoice(null);
    setPayments([]);
    setTaskDoc(null);
    setCustomerDoc(null);
    setCustomerLocationDoc(null);
    setEmailErrorStr(null);
    setSaveItemsErrorStr(null);
    setInvoiceErrorStr(null);
    props.onClose();
  }

  const displayEmailError = emailErrorStr && (
    <span className="mx-auto mt-4 block w-64">
      <ErrorMessage
        message={emailErrorStr}
        clearMessage={() => setEmailErrorStr(null)}
      />
    </span>
  );

  const displaySaveItemsError = saveItemsErrorStr && (
    <span className="mx-auto mt-4 block w-96 lg:w-2/3">
      <ErrorMessage
        message={saveItemsErrorStr}
        clearMessage={() => setSaveItemsErrorStr(null)}
      />
    </span>
  );

  const displayInvoiceError = invoiceErrorStr && (
    <span className="mx-auto mt-4 block w-fit max-w-sm">
      <ErrorMessage
        message={invoiceErrorStr}
        clearMessage={() => setInvoiceErrorStr(null)}
      />
    </span>
  );

  let subTotalWithoutGlobalDiscount = 0;
  let allDiscountsApplied = 0;
  if (invoice) {
    invoice.lineItems.forEach((i) => {
      subTotalWithoutGlobalDiscount += i.subTotal;
      // Add in line discounts
      allDiscountsApplied += i.quantity * i.unitPrice - i.subTotal;
    });
    // Add in global discount
    allDiscountsApplied += subTotalWithoutGlobalDiscount - invoice.subTotal;
  }

  async function handleLineItemsUpdated(lineItems: StiltLineItemFormData[]) {
    setTemporaryInvoiceLineItemList(lineItems);
  }

  async function updateGlobalDiscount(newGlobalDiscount: number) {
    if (!invoice) return;
    if (newGlobalDiscount === invoice.discount) {
      logger.debug("Discount value hasn't changed");
      return;
    }
    setIsSavingGlobalDiscount(true);

    const editInvoiceToValidate: Partial<ExistingStiltInvoice> = {
      discount: newGlobalDiscount,
      id: invoice.id,
      refPath: invoice.refPath,
    };

    /* validate values before sending to DB */
    const validatedEditInvoice = StiltInvoiceManager.parseUpdate(
      editInvoiceToValidate,
    );
    logger.info("validatedEditInvoice", validatedEditInvoice);
    try {
      await mutateUpdateInvoice.mutateAsync({
        validPartialInvoiceData: validatedEditInvoice,
      });

      logger.debug("Invoice has been updated successfully.");
      addMessage({
        id: createToastMessageID(),
        dialog: true,
        message: strings.successfulUpdate(
          `Global Discount for Invoice #${invoice.invoiceNumber}`,
        ),
        type: "success",
      });
    } catch (error) {
      logger.error(`An error occurred during updateGlobalDiscount`, error);
      addMessage({
        id: createToastMessageID(),
        dialog: true,
        message: strings.UNEXPECTED_ERROR,
        type: "error",
      });
    }
    setIsSavingGlobalDiscount(false);
  }

  async function updateInvoiceWithLineItemChanges() {
    for (const lineItem of temporaryInvoiceLineItemList) {
      if (lineItem.toBeEdited) {
        setSaveItemsErrorStr(
          "Please save any line items that are currently being edited by tapping on the green check mark for each line",
        );
        return;
      }
    }

    logger.debug(
      `Saving invoice with lineItems: ${temporaryInvoiceLineItemList}`,
    );
    /* validate values before sending to DB */
    const validatedEditInvoice = StiltInvoiceManager.parseUpdate({
      id: invoice?.id,
      refPath: invoice?.refPath,
      lineItems: getStiltLineItemsFromFormData(temporaryInvoiceLineItemList),
    });

    try {
      setIsSavingLineItems(true);
      logger.debug(`Updated Invoice: ${validatedEditInvoice}`);
      await mutateUpdateInvoice.mutateAsync({
        validPartialInvoiceData: validatedEditInvoice,
      });

      addMessage({
        id: createToastMessageID(),
        dialog: true,
        message: strings.successfulUpdate(`Line Item(s)`),
        type: "success",
      });
      setEditLineItems(false);
    } catch (error) {
      logger.error(
        `An error occurred during updateInvoiceWithLineItemChanges`,
        error,
      );
      addMessage({
        id: createToastMessageID(),
        dialog: true,
        message: strings.UNEXPECTED_ERROR,
        type: "error",
      });
    } finally {
      setIsSavingLineItems(false);
    }
  }

  /* HISTORY */

  function goToCustomerPage(customerId: string) {
    const url = `${CUSTOMERS_URL}/${customerId}`;
    return window.open(url, "_blank");
  }

  function goToWorkRecordAndTasksPage(
    craftRecordID: ExistingStiltInvoice["craftRecordID"],
  ) {
    const url = `${WORK_RECORD_AND_TASKS_URL}/${craftRecordID}`;
    return window.open(url, "_blank");
  }

  async function handleGetPDF(invoiceID: string) {
    if (siteKeyDoc) {
      await generatePDF.invoice(
        siteKeyDoc,
        [invoiceID],
        true,
        siteKeyDoc?.customizations.defaultIncludeJobPhotos ?? false,
        userPermissions,
      );
    }
  }

  const editLineItemsButton = (
    <div className="pb-4">
      {editLineItems ? (
        <div className="flex flex-col">
          <div className="flex justify-end gap-2">
            <BaseButtonPrimary
              type="button"
              onClick={() => updateInvoiceWithLineItemChanges()}
              isBusy={isSavingLineItems}
              busyText={strings.buttons.BUSY_SAVING}
            >
              {strings.buttons.SAVE_CHANGES}
            </BaseButtonPrimary>
            <StyledTooltip title="Close">
              <XIconWithRef
                onClick={() => {
                  setEditLineItems(false);
                  setTemporaryInvoiceLineItemList(
                    cloneDeep(originalInvoiceLineItemList),
                  );
                }}
              />
            </StyledTooltip>
          </div>
          {displaySaveItemsError}
        </div>
      ) : (
        <span className="flex justify-end">
          <StyledTooltip title="Edit Line Items">
            <PencilIconWithRef onClick={() => setEditLineItems(true)} />
          </StyledTooltip>
        </span>
      )}
    </div>
  );

  const invoiceSummary = invoice &&
    payments &&
    siteKeyDoc &&
    props.userIsSiteAdmin && (
      <InvoiceSummary
        siteKey={siteKeyDoc.id}
        timezone={siteKeyDoc.timezone ?? "America/New_York"}
        siteFilterPbiByLocationID={
          siteKeyDoc.customizations.filterPriceBookItemsByLocationID ?? false
        }
        totalTaxRate={customerLocationDoc?.totalTaxRate ?? 0}
        customer={customerDoc}
        currency={siteKeyDoc.customizations.accounting?.currency ?? "USD"}
        items={getStiltLineItemFormData(invoice)}
        totalTaxAmount={invoice.totalTaxAmount}
        totalTaxAmountPST={invoice.totalTaxAmountPST}
        totalTaxAmountGST={invoice.totalTaxAmountGST}
        subTotalWithoutGlobalDiscount={subTotalWithoutGlobalDiscount}
        subTotalWithGlobalDiscount={invoice.subTotal}
        allDiscountsApplied={allDiscountsApplied}
        totalAmount={invoice.totalAmount}
        amountDue={invoice.amountDue}
        handlePayment={() => props.handlePayment(invoice.id)}
        handleDeletePayment={(paymentID: string) =>
          props.handleDeletePayment(siteKeyDoc.id, paymentID)
        }
        note={invoice.note}
        merchantName={props.merchantName}
        discount={invoice.discount}
        internalNote={invoice.internalNotes}
        paymentsMade={payments}
        shouldShowAuthedVersion={props.userIsSiteAdmin}
        lineItemsUpdated={handleLineItemsUpdated}
        handleRefund={props.handleRefund}
        tipsEnabled={false}
        children={{
          editItemsButton: editLineItemsButton,
        }}
        tipAmount={0}
        allowEdit={editLineItems}
        isSavingGlobalDiscount={isSavingGlobalDiscount}
        updateGlobalDiscount={updateGlobalDiscount}
        locationID={invoice.locationID}
        userDisplayNamesMap={props.userDisplayNamesMap}
        onUpdateNote={async (note: string) => {
          const validatedEditInvoice = StiltInvoiceManager.parseUpdate({
            note,
            id: invoice.id,
            refPath: invoice.refPath,
          });

          await mutateUpdateInvoice.mutateAsync({
            validPartialInvoiceData: validatedEditInvoice,
          });

          addMessage({
            id: createToastMessageID(),
            dialog: true,
            message: strings.successfulUpdate(
              `Invoice #${invoice.invoiceNumber}`,
            ),
            type: "success",
          });
        }}
        siteKeyID={props.siteKeyID}
        invoiceID={invoice.id}
      />
    );

  const invoiceSummaryNonAdmin = invoice &&
    siteKeyDoc &&
    !props.userIsSiteAdmin && (
      <InvoiceSummary
        timezone={siteKeyDoc.timezone ?? "America/New_York"}
        totalTaxRate={customerLocationDoc?.totalTaxRate ?? 0}
        currency={siteKeyDoc.customizations.accounting?.currency ?? "USD"}
        items={getStiltLineItemFormData(invoice)}
        totalTaxAmount={invoice.totalTaxAmount}
        totalTaxAmountPST={invoice.totalTaxAmountPST}
        totalTaxAmountGST={invoice.totalTaxAmountGST}
        subTotalWithoutGlobalDiscount={subTotalWithoutGlobalDiscount}
        subTotalWithGlobalDiscount={invoice.subTotal}
        allDiscountsApplied={allDiscountsApplied}
        totalAmount={invoice.totalAmount}
        amountDue={invoice.amountDue}
        note={invoice.note}
        merchantName={props.merchantName}
        discount={invoice.discount}
        paymentsMade={unauthedPayments ?? []}
        shouldShowAuthedVersion={false}
        tipsEnabled={false}
        children={{
          editItemsButton: null,
        }}
        tipAmount={invoice.tipAmount ?? 0}
      />
    );

  const invoiceHeader = invoice && customerDoc && customerLocationDoc && (
    <InvoiceHeader
      dueDate={
        invoice.dueDate ? convertToReadableTimestampDate(invoice.dueDate) : null
      }
      issueDate={convertToReadableTimestampDate(invoice.issueDate)}
      poNumber={invoice.poNumber}
      jobNumber={parentRecordDoc?.craftDetails.jobNumber ?? null}
      invoiceNumber={invoice.invoiceNumber}
      invoiceStatus={invoice.status}
      amountDue={invoice.amountDue}
      invoicePaymentTerms={invoice.paymentTerms ?? null}
      merchantLogoURL={props.merchantLogoURL}
      timestampTaskCompleted={taskDoc?.timestampTaskCompleted ?? null}
      billingInfo={getBillingInfoFromCustomerAndLocations(customerDoc, [
        customerLocationDoc,
      ])}
      serviceInfo={{
        addressLine1: customerLocationDoc.addressLine1 ?? "",
        addressLine2: customerLocationDoc.addressLine2 ?? "",
        city: customerLocationDoc.city ?? "",
        state: customerLocationDoc.state ?? "",
        zipCode: customerLocationDoc.zipCode ?? "",
      }}
      sitePaymentTerms={siteKeyDoc?.customizations.paymentTerms}
      craftRecordID={invoice.craftRecordID}
      customerID={invoice.customerID}
      estimateID={invoice.estimateID}
      customerLocationID={invoice.customerLocationID}
      invoiceID={invoice.id}
      goToWorkRecordAndTasksPage={goToWorkRecordAndTasksPage}
      goToCustomerPage={goToCustomerPage}
      // goToViewEstimate={props.children.HandlePaymentDialog}
      getPDF={handleGetPDF}
    />
  );

  const sendEmailButton = (
    <BaseButtonPrimary
      type="button"
      isBusy={isSending}
      busyText={strings.buttons.BUSY_SENDING}
      onClick={async () => {
        if (selectedEmailList.length === 0) {
          setEmailErrorStr(strings.ERROR_EMAIL);
          return;
        }
        setIsSending(true);
        await props.sendEmail(selectedEmailList, includeJobPhotos);
        setIsSending(false);
        closeDialog();
      }}
      className="w-full uppercase xs:w-44"
    >
      <SendIcon className="mr-4" />
      Send Now
    </BaseButtonPrimary>
  );

  const includeJobPhotosSwitch = (
    <StyledSwitchGroup
      readableName={strings.INCLUDE_JOB_PHOTOS}
      onBlur={() => {}}
      onChange={() => setIncludeJobPhotos(!includeJobPhotos)}
      checked={includeJobPhotos}
      id="includeJobPhotos"
      name={"includeJobPhotos"}
    />
  );

  // Function to manually trigger sync
  const handleManualSync = async () => {
    if (!invoice) return;

    setIsSyncing(true);
    try {
      const refPathList = [invoice.refPath];
      const result = await hitAcctSync({
        siteKeyID: props.siteKeyID,
        refPathList,
      });

      if (result && result.length > 0) {
        addMessage({
          id: createToastMessageID(),
          message: `Failed to sync invoice ${invoice.invoiceNumber}. Please try again later.`,
          type: "error",
          dialog: true,
        });
      } else {
        addMessage({
          id: createToastMessageID(),
          message: `Successfully initiated sync for invoice ${invoice.invoiceNumber}.`,
          type: "success",
          dialog: true,
        });
      }
    } catch (error) {
      logger.error(`Error syncing invoice ${invoice.invoiceNumber}`, error);
      addMessage({
        id: createToastMessageID(),
        message: `Error syncing invoice ${invoice.invoiceNumber}. Please try again later.`,
        type: "error",
        dialog: true,
      });
    } finally {
      setIsSyncing(false);
    }
  };

  return (
    <BaseModal
      open={props.isOpen}
      closeModal={closeDialog}
      title={<></>}
      parentDivStyles="text-left max-w-2xl lg:max-w-4xl"
    >
      <div className="relative p-6 text-gray-900 md:p-8">
        {isLoading ? (
          <LoadingSpinner sizeClass="h-8 w-8" marginClass="mx-auto" />
        ) : (
          <>
            <div>
              {props.userIsSiteAdmin && isQuickbooksEnabled && (
                <QuickbooksSyncStatus
                  invoice={invoice}
                  isQuickbooksEnabled={isQuickbooksEnabled}
                  handleManualSync={handleManualSync}
                  isSyncing={isSyncing}
                  setIsCompareInvoiceDialogOpen={setIsCompareInvoiceDialogOpen}
                />
              )}
              {invoiceHeader}
              {props.userIsSiteAdmin ? invoiceSummary : invoiceSummaryNonAdmin}
            </div>

            <div className="flex flex-col items-end justify-end gap-2">
              <MultipleEmailSelector
                sendEmailButton={sendEmailButton}
                includeJobPhotosSwitch={includeJobPhotosSwitch}
                selectedEmailList={selectedEmailList}
                setSelectedEmailList={setSelectedEmailList}
                emailList={emailList}
                setErrorString={setEmailErrorStr}
              />
              {invoice?.timestampSentToCustomer && (
                <div className="px-0 italic">{`Last Sent to Customer: ${convertToReadableTimestamp(invoice.timestampSentToCustomer)}`}</div>
              )}
            </div>

            {displayEmailError}
            {displayInvoiceError}

            <div className="mt-16 flex flex-col items-center justify-between gap-4 sm:flex-row">
              <BaseButtonSecondary
                type="button"
                className="w-full uppercase sm:w-44"
                onClick={closeDialog}
              >
                {strings.buttons.CLOSE}
              </BaseButtonSecondary>
              <BaseButtonSecondary
                type="button"
                className="w-full uppercase sm:w-44"
                onClick={props.editInvoice}
              >
                {strings.buttons.EDIT_INVOICE}
              </BaseButtonSecondary>

              {props.userIsSiteAdmin ? (
                <ButtonColored
                  className="w-full uppercase sm:w-44"
                  kind="danger"
                  isBusy={isDeleting}
                  busyText={strings.buttons.BUSY_DELETING}
                  onClick={async () => {
                    if (payments && payments.length > 0) {
                      setInvoiceErrorStr(
                        "Cannot delete an invoice that has one or more payments associated with it.",
                      );
                      return;
                    }
                    try {
                      setIsDeleting(true);
                      await props.deleteInvoice(
                        props.siteKeyID,
                        props.invoiceID,
                      );
                      setIsDeleting(false);
                      closeDialog();
                    } catch (e) {
                      setIsDeleting(false);
                      setInvoiceErrorStr(strings.ERR_DELETE_INVOICE);
                    }
                  }}
                >
                  {strings.DELETE_INVOICE}
                </ButtonColored>
              ) : null}
            </div>
          </>
        )}
      </div>
      {props.children.EditInvoiceDialog}
      {props.children.HandlePaymentDialog}
      {props.userIsSiteAdmin && invoice && (
        <CompareInvoiceDialog
          isOpen={isCompareInvoiceDialogOpen}
          onClose={() => setIsCompareInvoiceDialogOpen(false)}
          siteKey={props.siteKeyID}
          stiltInvoice={invoice}
        />
      )}
    </BaseModal>
  );
}

// SECTION: helpers
export interface ViewInvoiceDialogProps {
  invoiceID: string;
  siteKeyID: string;
  merchantName: string;
  merchantLogoURL: string | null;
}

function getStiltLineItemFormData(
  invoice: ExistingStiltInvoice,
): StiltLineItemFormData[] {
  const lineItems: StiltLineItemFormData[] = [];
  for (const item of invoice.lineItems) {
    const paymentFormLineItem = {
      title: item.estimateItemTitle,
      description: item.estimateItemDescription ?? "",
      quantity: item.quantity,
      discount: item.discount,
      unitPrice: item.unitPrice,
      subTotal: item.subTotal,
      taxAmount: item.taxAmount,
      discountable: item.discountable,
      estimateItemID: item.estimateItemID,
      priceBookItemID: item.priceBookItemID,
      toBeEdited: false,
    };
    lineItems.push(paymentFormLineItem);
  }
  return lineItems;
}

function getStiltLineItemsFromFormData(
  lineItems: StiltLineItemFormData[],
): StiltLineItem[] {
  const items: StiltLineItem[] = [];
  for (const item of lineItems) {
    if (item.priceBookItemID) {
      const stiltLineItem: StiltLineItem = {
        priceBookItemID: item.priceBookItemID,
        estimateItemDescription: item.description,
        estimateItemTitle: item.title ?? "",
        estimateItemID: item.estimateItemID ?? null,
        quantity: item.quantity,
        discount: item.discount,
        taxAmount: item.taxAmount,
        unitPrice: item.unitPrice,
        subTotal: item.subTotal,
        discountable: item.discountable,
      };
      items.push(stiltLineItem);
    }
  }
  return items;
}

function QuickbooksSyncStatus({
  invoice,
  isQuickbooksEnabled,
  handleManualSync,
  isSyncing,
  setIsCompareInvoiceDialogOpen,
}: {
  invoice: ExistingStiltInvoice | null;
  isQuickbooksEnabled: boolean;
  handleManualSync: () => Promise<void>;
  isSyncing: boolean;
  setIsCompareInvoiceDialogOpen: (open: boolean) => void;
}): JSX.Element | null {
  if (!isQuickbooksEnabled || !invoice) return null;

  const isSynced =
    invoice.customData?.accountingRefID ||
    invoice.accountingSync?.syncStatus === AccountingSyncStatus.SYNCED;

  return (
    <div
      className={`ml-auto max-w-fit rounded-md border bg-gray-50 ${isSynced ? "mb-2 px-2 py-1" : "mb-4 p-1"}`}
    >
      <div className="flex items-center justify-end gap-2">
        <div
          className={`${!isSynced ? "xs:self-end" : "mt-0.5"} flex flex-col gap-x-2 gap-y-1 xs:flex-row md:gap-3`}
        >
          {invoice.accountingSync?.timestampLastSynced && (
            <p className="text-xs xs:text-sm">
              <span className="font-bold">Last synced:</span>{" "}
              {convertToReadableTimestamp(
                invoice.accountingSync.timestampLastSynced,
              )}
            </p>
          )}

          {invoice.accountingSync?.timestampNextSyncAttempt && (
            <p className="text-xs xs:text-sm">
              <span className="font-bold">Next sync attempt:</span>{" "}
              {convertToReadableTimestamp(
                invoice.accountingSync.timestampNextSyncAttempt,
              )}
            </p>
          )}
        </div>

        <StyledTooltip title={getSyncStatusText(invoice)}>
          <div>{renderSyncStatusIcon(invoice)}</div>
        </StyledTooltip>
        <img
          src="/quickbooks-2.svg"
          alt="QuickBooks"
          style={{ height: "24px" }}
        />
        {isSynced && (
          <StyledTooltip title="Compare Stilt Invoice to QuickBooks Invoice">
            <button
              onClick={() => setIsCompareInvoiceDialogOpen(true)}
              className="rounded-md border border-gray-300 p-1"
            >
              <CompareArrowsIcon className="mr-1" fontSize="small" />
            </button>
          </StyledTooltip>
        )}
      </div>

      {invoice.accountingSync?.syncStatus === AccountingSyncStatus.FAILED && (
        <p className="mx-1 mt-2 text-sm text-red-700 xs:mx-3">
          <span className="font-bold">Error:</span>{" "}
          {invoice.accountingSync?.statusMessage || "Unknown error"}
        </p>
      )}

      {!isSynced && (
        <div className="mb-2 ml-1 mt-3 flex flex-row items-center gap-2 xs:ml-3">
          <StyledTooltip title="Sync Invoice to QuickBooks" enterDelay={1000}>
            <BaseButtonPrimary
              onClick={handleManualSync}
              isBusy={isSyncing}
              busyText="Syncing..."
            >
              <SyncIcon className="mr-1" fontSize="small" />
              Sync
            </BaseButtonPrimary>
          </StyledTooltip>
          {invoice.accountingSync?.statusMessage ===
            strings.DUPLICATE_INVOICE_NUM && (
            <StyledTooltip title="Compare Stilt Invoice to QuickBooks Invoice">
              <BaseButtonSecondary
                onClick={() => setIsCompareInvoiceDialogOpen(true)}
              >
                <CompareArrowsIcon className="mr-1" fontSize="small" />
                Compare To QuickBooks
              </BaseButtonSecondary>
            </StyledTooltip>
          )}
        </div>
      )}
    </div>
  );
}

function renderSyncStatusIcon(invoice: ExistingStiltInvoice) {
  if (!invoice?.accountingSync?.syncStatus) {
    return <HourglassEmptyIcon color="disabled" />;
  }

  switch (invoice.accountingSync.syncStatus) {
    case AccountingSyncStatus.SYNCED:
      return <CheckCircleIcon style={{ color: "#4caf50" }} />;
    case AccountingSyncStatus.FAILED:
      return <ErrorIcon color="error" />;
    case AccountingSyncStatus.AWAITING_PUSH:
    case AccountingSyncStatus.AWAITING_CONFIRM:
      return <HourglassEmptyIcon style={{ color: "#ff9800" }} />;
    case AccountingSyncStatus.IS_WAITING_ON:
      return <HourglassEmptyIcon style={{ color: "#2196f3" }} />;
    default:
      return <HourglassEmptyIcon color="disabled" />;
  }
}

function getSyncStatusText(invoice: ExistingStiltInvoice) {
  if (!invoice?.accountingSync?.syncStatus) {
    return "Not synced";
  }
  return getReadableAccountingSyncStatus(invoice.accountingSync.syncStatus);
}
