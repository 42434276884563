import { useMemo, useState } from "react";
import { JobCostingBreakdown, JobCosts } from "../models/craft-record";
import * as strings from "../strings";
import {
  InformationCircleIcon,
  QuestionMarkCircleIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { StyledTooltip } from "./StyledTooltip";
import { DbWrite } from "../database";
import { createToastMessageID } from "../utils/createToastMessageID";
import { useToastMessageStore } from "../store/toast-messages";

interface Props {
  jobCosting: JobCostingBreakdown;
  className?: string;
  siteKey: string;
  craftRecordID: string;
}

interface GroupedCosts {
  [type: string]: {
    costs: JobCosts[];
    totalCost: number;
  };
}

export default function JobCostingPanel({
  jobCosting,
  className = "",
  siteKey,
  craftRecordID,
}: Props): JSX.Element {
  const [isUpdating, setIsUpdating] = useState(false);
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );
  const {
    totalRevenue,
    costs,
    totalCosts,
    grossProfit,
    grossProfitMargin,
    netProfit,
    netProfitMargin,
  } = jobCosting;

  // Format numbers as currency
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  // Format percentage
  const formatPercentage = (value: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(value);
  };

  // Group costs by type
  const groupedCosts = useMemo(() => {
    return costs.reduce((acc: GroupedCosts, cost) => {
      if (!acc[cost.type]) {
        acc[cost.type] = {
          costs: [],
          totalCost: 0,
        };
      }
      acc[cost.type].costs.push(cost);
      acc[cost.type].totalCost += cost.cost;
      return acc;
    }, {});
  }, [costs]);

  // Update handler function to use the custom toast system
  const handleUpdateJobCosting = async () => {
    try {
      setIsUpdating(true);
      await DbWrite.parentRecords.updateJobCosting(siteKey, craftRecordID);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate("Job costing"),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      console.error("Failed to update job costing:", error);
      addToastMessage({
        id: createToastMessageID(),
        message: "Failed to update job costing. Please try again.",
        dialog: false,
        type: "error",
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="mt-8 flex items-center justify-between">
        <h2 className="text-2xl">{strings.JOB_COSTS_AND_PROFITABILITY}</h2>
      </div>
      <hr className="my-2 block w-full border border-gray-100" />
      <div className={`rounded-lg bg-white p-2 shadow ${className}`}>
        <div className="grid grid-cols-1 gap-2">
          {/* Revenue Section */}
          <div className="rounded-lg bg-gray-50 px-4 py-2">
            <div className="flex items-center justify-between">
              <span className="flex items-center gap-2 font-medium">
                Total Revenue
                <StyledTooltip title="Sum of all invoice sub-totals for this job (excluding canceled and draft invoices). This does not include taxes.">
                  <InformationCircleIcon className="h-4 w-4 text-blue-400" />
                </StyledTooltip>
              </span>
              <span className="text-lg">{formatCurrency(totalRevenue)}</span>
            </div>
          </div>

          {/* Costs Section */}
          <div className="space-y-4 rounded-lg bg-gray-50 px-4 py-2">
            <h3 className="text-sm text-gray-600">Costs Breakdown</h3>
            <div className="space-y-2">
              {Object.entries(groupedCosts).map(
                ([type, { costs: typeCosts, totalCost }]) => (
                  <div key={type} className="border-b border-gray-200 pb-2">
                    <div className="mb-2 flex items-center justify-between text-gray-700">
                      <span className="font-medium capitalize">{type}</span>
                      <span className="font-medium">
                        {formatCurrency(totalCost)}
                      </span>
                    </div>
                    <div className="space-y-2 pl-4">
                      {typeCosts.map((cost, index) => (
                        <div
                          key={index}
                          className="flex items-center justify-between text-sm"
                        >
                          <span className="flex items-center gap-2 capitalize text-gray-600">
                            {cost.invoiceLineItem.estimateItemTitle}
                            {cost.invoiceLineItem.priceBookItemID ===
                              "mileage" && (
                              <StyledTooltip title="Automatically calculated as the round-trip distance to the customer's location from your business location">
                                <InformationCircleIcon className="h-4 w-4 text-blue-400" />
                              </StyledTooltip>
                            )}
                          </span>
                          <div className="text-right">
                            <span className="inline-block w-32">
                              {formatCurrency(cost.cost)}
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ),
              )}
              <div className="flex items-center justify-between pt-3 font-medium">
                <span>Total Costs</span>
                <span>{formatCurrency(totalCosts)}</span>
              </div>
            </div>
          </div>

          {/* Gross Profit Section */}
          <div
            className={`rounded-lg px-4 py-2 ${grossProfitMargin > 0 ? "bg-green-50" : "bg-red-50"}`}
          >
            <div className="text-sm text-gray-600">Gross Profit</div>
            <div className="mt-1 flex items-baseline justify-between">
              <span className="text-lg font-medium">
                {formatCurrency(grossProfit)}
              </span>
              <span
                className={`text-lg font-medium ${grossProfitMargin > 0 ? "text-green-600" : "text-red-600"}`}
              >
                {formatPercentage(grossProfitMargin)}
              </span>
            </div>
          </div>

          {/* Overhead Section */}
          <div className="rounded-lg bg-gray-50 px-4 py-2">
            <div className="text-sm text-gray-600">Overhead</div>
            <div className="mt-1 flex items-baseline justify-between">
              <span className="text-lg font-medium">
                {formatCurrency(jobCosting.overhead)}
              </span>
              <span className="text-lg font-medium">
                {formatPercentage(jobCosting.overheadRate)}
              </span>
            </div>
          </div>

          {/* Net Profit Section */}
          <div
            className={`rounded-lg bg-green-50 px-4 py-2 ${netProfitMargin > 0 ? "bg-green-50" : "bg-red-50"}`}
          >
            <div className="text-sm text-gray-600">Net Profit</div>
            <div className="mt-1 flex items-baseline justify-between">
              <span className="text-lg font-medium">
                {formatCurrency(netProfit)}
              </span>
              <span
                className={`text-lg font-medium ${netProfitMargin > 0 ? "text-green-600" : "text-red-600"}`}
              >
                {formatPercentage(netProfitMargin)}
              </span>
            </div>
          </div>
        </div>

        <div className="mt-4 flex items-center justify-end gap-2 text-sm text-gray-500">
          <span>
            Last calculated:{" "}
            {jobCosting.lastCalculated.toDate().toLocaleDateString()}
          </span>
          <button
            onClick={handleUpdateJobCosting}
            disabled={isUpdating}
            className="inline-flex items-center gap-1 rounded px-2 py-1 text-blue-600 hover:bg-blue-50 disabled:text-gray-400 disabled:hover:bg-transparent"
          >
            <ArrowPathIcon
              className={`h-4 w-4 ${isUpdating ? "animate-spin" : ""}`}
            />
            {isUpdating ? "Updating..." : "Update"}
          </button>
        </div>
      </div>
    </div>
  );
}
