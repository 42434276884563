import { useState } from "react";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import BaseInputText from "../../../components/BaseInputText";
import BaseButtonPrimary from "../../../components/BaseButtonPrimary";
import BaseButtonSecondary from "../../../components/BaseButtonSecondary";
import BaseInputCheckbox from "../../../components/BaseInputCheckbox";
import StyledMessage from "../../../components/StyledMessage";

export interface LocationsCSInterface {
  department: string;
  title: string;
  latitude: number;
  longitude: number;
}

interface LocationsStepProps {
  onComplete: (data: LocationsCSInterface[]) => void;
  initialData: LocationsCSInterface[];
  siteAddress: {
    formatted: string;
    latitude: number;
    longitude: number;
  };
}

interface ExtendedLocation extends LocationsCSInterface {
  differentAddress: boolean;
  address?: string;
}

export default function LocationsStep({
  onComplete,
  initialData,
  siteAddress,
}: LocationsStepProps) {
  const [locations, setLocations] = useState<ExtendedLocation[]>(
    initialData.length
      ? initialData.map((loc) => ({
          ...loc,
          differentAddress: false,
          latitude: loc.latitude || siteAddress.latitude,
          longitude: loc.longitude || siteAddress.longitude,
        }))
      : [
          {
            department: "Main Office",
            title: "Main Office",
            differentAddress: false,
            latitude: siteAddress.latitude,
            longitude: siteAddress.longitude,
          },
        ],
  );

  const [error, setError] = useState("");

  const addLocation = () => {
    const newLocationName = `Location ${locations.length + 1}`;
    setLocations([
      ...locations,
      {
        department: newLocationName,
        title: newLocationName,
        differentAddress: false,
        latitude: siteAddress.latitude,
        longitude: siteAddress.longitude,
      },
    ]);
  };

  const removeLocation = (index: number) => {
    setLocations(locations.filter((_, i) => i !== index));
  };

  const updateLocation = (
    index: number,
    field: keyof ExtendedLocation,
    value: any,
  ) => {
    const newLocations = [...locations];
    newLocations[index] = {
      ...newLocations[index],
      [field]: value,
    };
    setLocations(newLocations);
  };

  const handleSelectAddress = async (
    index: number,
    selectedAddress: string,
  ) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);

      const newLocations = [...locations];
      newLocations[index] = {
        ...newLocations[index],
        address: selectedAddress,
        latitude: latLng.lat,
        longitude: latLng.lng,
      };
      setLocations(newLocations);
      setError("");
    } catch (error) {
      setError("Error finding address. Please try again.");
    }
  };

  const handleContinue = () => {
    // Validate locations
    const hasEmptyFields = locations.some((loc) => !loc.department);
    if (hasEmptyFields) {
      setError("Please fill in all location details");
      return;
    }

    // Validate addresses for locations that need them
    const hasInvalidAddress = locations.some(
      (loc) => loc.differentAddress && !loc.address,
    );
    if (hasInvalidAddress) {
      setError(
        "Please enter addresses for all locations that are different from the main address",
      );
      return;
    }

    setError("");
    // Convert to LocationsCSInterface format before passing to onComplete
    const formattedLocations = locations.map((loc) => ({
      department: loc.department,
      title: loc.department,
      latitude: loc.latitude,
      longitude: loc.longitude,
    }));
    onComplete(formattedLocations);
  };

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-2xl font-bold text-gray-900">Add Your Locations</h2>
        <p className="mt-1 text-sm text-gray-500">
          We've added your main office at {siteAddress.formatted}. You can add
          additional locations/offices below.
        </p>
      </div>

      <div className="space-y-6">
        {locations.map((location, index) => (
          <div
            key={index}
            className="relative rounded-lg border border-gray-200 p-6"
          >
            <div className="space-y-4">
              <div className="flex items-start justify-between">
                <div className="w-full">
                  <BaseInputText
                    text="Location Name"
                    value={location.department}
                    onChange={(e) => {
                      updateLocation(index, "department", e.target.value);
                      updateLocation(index, "title", e.target.value);
                    }}
                    required
                    admin
                  />
                </div>
                {locations.length > 1 && (
                  <button
                    type="button"
                    onClick={() => removeLocation(index)}
                    className="ml-4 flex-shrink-0 text-gray-400 hover:text-red-500"
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                )}
              </div>

              {index > 0 && (
                <div>
                  <BaseInputCheckbox
                    label="This location has a different address"
                    checked={location.differentAddress}
                    onChange={(e) =>
                      updateLocation(
                        index,
                        "differentAddress",
                        e.target.checked,
                      )
                    }
                  />
                </div>
              )}

              {location.differentAddress && (
                <div className="mt-4">
                  <PlacesAutocomplete
                    value={location.address || ""}
                    onChange={(newAddress) =>
                      updateLocation(index, "address", newAddress)
                    }
                    onSelect={(address) => handleSelectAddress(index, address)}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <BaseInputText
                          {...getInputProps({
                            text: "Address",
                            required: true,
                            admin: true,
                          })}
                        />
                        <div
                          className={
                            suggestions.length > 0 || loading
                              ? "absolute z-10 mt-1 w-full rounded-md border bg-white shadow-lg"
                              : ""
                          }
                        >
                          {loading && (
                            <div className="p-2 text-gray-500">Loading...</div>
                          )}
                          {suggestions.map((suggestion, index) => (
                            <div
                              //   key={index}
                              {...getSuggestionItemProps(suggestion, {
                                className: `cursor-pointer p-2 text-sm ${
                                  suggestion.active
                                    ? "bg-primary text-white"
                                    : "text-gray-900"
                                }`,
                              })}
                            >
                              {suggestion.description}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
              )}
            </div>
          </div>
        ))}

        <div className="flex justify-center">
          <button
            type="button"
            onClick={addLocation}
            className="hover:text-primary-dark inline-flex items-center text-sm font-medium text-primary"
          >
            <PlusIcon className="mr-1 h-5 w-5" />
            Add Another Location
          </button>
        </div>

        {error && (
          <StyledMessage type="error">{{ message: error }}</StyledMessage>
        )}

        <div className="flex justify-between pt-6">
          <BaseButtonSecondary
            type="button"
            onClick={() => window.history.back()}
          >
            Back
          </BaseButtonSecondary>

          <BaseButtonPrimary type="button" onClick={handleContinue}>
            Continue
          </BaseButtonPrimary>
        </div>
      </div>
    </div>
  );
}
