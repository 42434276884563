import BaseInputText from "../../../../components/BaseInputText";
import BaseButtonPrimary from "../../../../components/BaseButtonPrimary";
import BaseButtonSecondary from "../../../../components/BaseButtonSecondary";
import StyledMessage from "../../../../components/StyledMessage";
import currencyFormatter from "../../../../currency";
import { useState } from "react";

interface RevenueInfo {
  annualDirectDebitVolume: number;
  annualCardsVolume: number;
  avgDirectDebitTransactionAmount: number;
  avgCardsTransactionAmount: number;
  maxTransactionAmount: number;
}

interface RevenueStepProps {
  initialData: RevenueInfo;
  onComplete: (data: RevenueInfo) => void;
}

export default function RevenueStep({
  initialData,
  onComplete,
}: RevenueStepProps) {
  const [formData, setFormData] = useState<RevenueInfo>(initialData);
  const [error, setError] = useState("");

  const handleCurrencyChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: keyof RevenueInfo,
  ) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    const numberValue = parseFloat(value) || 0;
    setFormData((prev) => ({
      ...prev,
      [field]: numberValue,
      // Update annual volumes when average amounts change
      ...(field === "avgDirectDebitTransactionAmount"
        ? { annualDirectDebitVolume: numberValue * 12 }
        : {}),
      ...(field === "avgCardsTransactionAmount"
        ? { annualCardsVolume: numberValue * 12 }
        : {}),
    }));
  };

  const validateForm = (): boolean => {
    if (formData.avgCardsTransactionAmount <= 0) {
      setError("Average card transaction amount must be greater than zero");
      return false;
    }
    if (formData.avgDirectDebitTransactionAmount <= 0) {
      setError("Average direct debit amount must be greater than zero");
      return false;
    }
    if (
      formData.maxTransactionAmount <= formData.avgCardsTransactionAmount ||
      formData.maxTransactionAmount <= formData.avgDirectDebitTransactionAmount
    ) {
      setError(
        "Maximum transaction amount must be greater than average amounts",
      );
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setError("");
      onComplete(formData);
    }
  };

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-2xl font-bold text-gray-900">Processing Volume</h2>
        <p className="mt-1 text-sm text-gray-500">
          Tell us about your expected transaction volumes.
        </p>
      </div>

      <div className="space-y-6">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <BaseInputText
            text="Average Card Transaction"
            value={
              formData.avgCardsTransactionAmount === 0
                ? ""
                : formData.avgCardsTransactionAmount.toString()
            }
            onChange={(e) =>
              handleCurrencyChange(e, "avgCardsTransactionAmount")
            }
            required
            admin
            type="number"
            step="0.01"
            min="0"
            placeholder="0.00"
          />

          <BaseInputText
            text="Annual Cards Volume"
            value={currencyFormatter(formData.annualCardsVolume, "USD")}
            disabled
            admin
          />
        </div>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <BaseInputText
            text="Average Direct Debit Transaction"
            value={
              formData.avgDirectDebitTransactionAmount === 0
                ? ""
                : formData.avgDirectDebitTransactionAmount.toString()
            }
            onChange={(e) =>
              handleCurrencyChange(e, "avgDirectDebitTransactionAmount")
            }
            required
            admin
            type="number"
            step="0.01"
            min="0"
            placeholder="0.00"
          />

          <BaseInputText
            text="Annual Direct Debit Volume"
            value={currencyFormatter(formData.annualDirectDebitVolume, "USD")}
            disabled
            admin
          />
        </div>

        <div>
          <BaseInputText
            text="Maximum Transaction Amount"
            value={
              formData.maxTransactionAmount === 0
                ? ""
                : formData.maxTransactionAmount.toString()
            }
            onChange={(e) => handleCurrencyChange(e, "maxTransactionAmount")}
            required
            admin
            type="number"
            step="0.01"
            min="0"
            placeholder="0.00"
          />
        </div>

        {error && (
          <StyledMessage type="error">{{ message: error }}</StyledMessage>
        )}

        <div className="flex justify-between pt-6">
          <BaseButtonSecondary
            type="button"
            onClick={() => window.history.back()}
          >
            Back
          </BaseButtonSecondary>

          <BaseButtonPrimary type="button" onClick={handleSubmit}>
            Continue
          </BaseButtonPrimary>
        </div>
      </div>
    </div>
  );
}
