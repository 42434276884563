//Libs
import React, {
  forwardRef,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { useMutation } from "react-query";
import { DocumentData, Timestamp } from "firebase/firestore";
import DatePicker from "react-datepicker";
import { DateTime } from "luxon";
import AddIcon from "@mui/icons-material/Add";
import { User } from "firebase/auth";
import EventIcon from "@mui/icons-material/Event";
import { useNavigate } from "react-router-dom";
import {
  ExclamationTriangleIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import StarsIcon from "@mui/icons-material/Stars";
import {
  CalendarProps,
  DayPilot,
  DayPilotCalendar,
  DayPilotScheduler,
  SchedulerProps,
} from "daypilot-pro-react";
import {
  ArrowLeft,
  ArrowRight,
  PivotTableChart,
  SettingsSuggest,
} from "@mui/icons-material";
import TodayIcon from "@mui/icons-material/Today";
import HideSourceIcon from "@mui/icons-material/HideSource";
import { ZodError } from "zod";

//Style
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/css/Calendar.css";

//Local
import SchedulingPage from "./SchedulingPage";
import { DbRead, DbWrite } from "../../database";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import {
  ExistingTask,
  ExistingTaskWithCustomerLocation,
} from "../../models/task";
import { useUserPermissionsStore } from "../../store/user-permissions";
import { logger, logger as devLogger } from "../../logging";
import SchedulingSearchBox from "../../components/scheduling/SchedulingSearchBox";
import {
  convertFSTimestampToLuxonDT,
  convertISOToFSTimestamp,
  convertLuxonDTToFSTimestamp,
  createToastMessageID,
  getTimeDifferenceInHours,
  isStringArray,
} from "../../utils";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import * as strings from "../../strings";
import { ToastType, useToastMessageStore } from "../../store/toast-messages";
import { diffObjects } from "../../assets/js/object-diff";
import {
  generateResourcesForDayPilotCalendar,
  generateResourcesForDayPilotScheduler,
} from "../../assets/js/scheduling/generateResources";
import {
  CalendarColumn,
  CalendarEvent,
  ColumnOption,
} from "../../models/scheduling-types";
import {
  determineIfTaskIsGeocoded,
  generateCalendarEvents,
} from "../../assets/js/scheduling/generateCalendarEvents";
import { useAuthStore } from "../../store/firebase-auth";
import MultipleUidDialog, {
  IMultipleUid_AssignUser,
} from "../../components/CustomFields/MultipleUidDialog";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { WORK_RECORD_AND_TASKS_URL, ZONES_URL } from "../../urls";
import AddStiltCalendarEventDialog from "../../components/scheduling/AddStiltCalendarEventDialog";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import { convertToDisplayTime } from "../../utils/convertToDisplayTime";
import {
  ExistingStiltCalendarEvent,
  StiltCalendarEvent,
  StiltCalendarEventManager,
} from "../../models/stilt-calendar-event";
import { startDateIsEarlierThanEndDate } from "../../utils/startDateIsEarlierThanEndDate";
import { generateStiltCalendarEvents } from "../../assets/js/scheduling/generateStiltCalendarEvents";
import MultipleSelectionDropdown from "../../components/MultipleSelectionDropdown";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import EditStiltCalendarEventDialog from "../../components/scheduling/EditStiltCalendarEventDialog";
import BaseModal from "../../components/BaseModal";
import CreateTaskForCustomerContainer from "../Customers/CreateTaskForCustomerContainer";
import { getPermissionsMap } from "../Admin/UserManagementContainer";
import { StyledTooltip } from "../../components/StyledTooltip";
import { useSiteKeyUsersStore } from "../../store/site-key-users";
import { getFontColorForBackground } from "../../utils/colors";
import currencyFormatter from "../../currency";
import { ExistingSiteKey } from "../../models/site-key";
import PillMetric from "../../components/PillMetric";
import { ExistingEstimate } from "../../models/estimate";
import ResourcesColumnDropdown from "../../components/ResourcesColumnDropdown";
import {
  AssignedRoute_UpdateAPI,
  ExistingAssignedRoute,
} from "../../models/assigned-route";
import { ExistingZone } from "../../models/zone";
import getCurrentJSDateWithoutSeconds from "../../assets/js/getCurrentJSDateWithoutSeconds";
import ChooseCreateJobOrEventDialog from "../../components/scheduling/ChooseCreateJobOrEventDialog";
import { allocateTask } from "../../assets/js/scheduling/allocateTask";
import { generateTotalRouteDistances } from "../../assets/js/scheduling/generateTotalRouteDistances";
import { ExistingSiteKeyUserPermissions } from "../../models/site-key-user-permissions";
import { ExistingVehicle } from "../../models/vehicle";
import Bubble = DayPilot.Bubble;
import { TaskDetailsDialogContainer } from "../../components/TaskDetailsDialog";
import { ExistingSiteKeyUserDoc } from "../../models/site-key-users";
import { parseSmartSchedulingFormValues } from "../../models/smart-scheduling-options";
import { OptimizedTasks } from "../../assets/js/scheduling/allocateResourcesToTasksVROOM";
import { ExistingAsset } from "../../models/asset";

interface Props {
  siteKey: string;
}

export default function SchedulingContainer({ siteKey }: Props) {
  const isMounted = useRef(true);

  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const addMessage = useToastMessageStore((state) => state.addToastMessage);
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );

  const siteKeyUsersList = useSiteKeyUsersStore(
    (state) => state.siteKeyUsersList,
  );

  const [siteKeyDoc] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);
  const [siteKeyLocationList] = useSiteKeyLocationsStore((state) => [
    state.siteKeyLocationList,
    state.getLocationTitle,
  ]);

  /* HISTORY */
  const navigate = useNavigate();
  // const navigationType = useNavigationType();

  // if user got to this page from back button, set selectedDate to the date saved on local storage
  // useEffect(() => {
  //   if (navigationType === "POP") {
  //     const stringSavedDate = localStorage.getItem(
  //       "schedulingContainerCurrentDate",
  //     );
  //
  //     if (stringSavedDate) {
  //       const JSDate = new Date(parseInt(stringSavedDate));
  //       const luxonDate = DateTime.fromJSDate(JSDate);
  //       setSelectedDate(luxonDate.toJSDate());
  //       localStorage.removeItem("schedulingContainerCurrentDate");
  //     }
  //   }
  // }, [navigationType]);

  /* QUERIES */
  const [selectedTask, setSelectedTask] = useState<ExistingTask | null>(null);

  /* STATES & VARIABLES */
  const [isLoading, setIsLoading] = useState(false);
  const [dontRenderDispatchBoard, setDontRenderDispatchBoard] = useState(false);
  const [scheduledAwaitingTaskList, setScheduledAwaitingTaskList] = useState<
    ExistingTaskWithCustomerLocation[]
  >([]);
  const [optimizedTasks, setOptimizedTasks] = useState<OptimizedTasks>({
    tasks: [],
    unassigned: [],
    vroomRoutesMap: {},
    vroomOutput: null,
    stats: {
      originalTotalDistanceMiles: 0,
      optimizedTotalDistanceMeters: 0,
      unassignedCount: 0,
      unassignedReasons: [],
    },
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [locationsDropdownValue, setLocationsDropdownValue] = useState<
    ColumnOption["value"][]
  >([]);

  const [permissionsMap, setPermissionsMap] = useState<
    Record<string, ExistingSiteKeyUserPermissions>
  >({});

  const [filteredUsersList, setFilteredUsersList] = useState<
    ExistingSiteKeyUserDoc[]
  >([]);

  const [resourcesDropdownValue, setResourcesDropdownValue] = useState<
    ColumnOption["value"] | null
  >("technicians");
  const [selectedDate, setSelectedDate] = useState<Date>(
    getCurrentJSDateWithoutSeconds(),
  );

  const [taskDetailsDialogOpen, setTaskDetailsDialogOpen] =
    useState<boolean>(false);
  const [withRescheduleTaskDialogOpen, setWithRescheduleTaskDialogOpen] =
    useState<boolean>(false);

  const [isOptimizing, setIsOptimizing] = useState<boolean>(false);
  const [
    isViewingTemporarilyOptimizedTasks,
    setIsViewingTemporarilyOptimizedTasks,
  ] = useState<boolean>(false);
  const [isSavingOptimizedTasks, setIsSavingOptimizedTasks] =
    useState<boolean>(false);

  const [totalsLoading, setTotalsLoading] = useState<boolean>(false);

  const [totals, setTotals] = useState<{ [key: string]: number }>({
    scheduledSales: 0,
    invoiceTotals: 0,
    paymentTotals: 0,
    tasks: 0,
  });

  const [addNewEventDialogOpen, setAddNewEventDialogOpen] =
    useState<boolean>(false);
  const [startDateNewCalendarEvent, setStartDateNewCalendarEvent] =
    useState<DateTime>(DateTime.now());
  const [endDateNewCalendarEvent, setEndDateNewCalendarEvent] =
    useState<DateTime>(DateTime.now());
  const [displayStartTime, setDisplayStartTime] = useState<string>("");
  const [displayEndTime, setDisplayEndTime] = useState<string>("");
  const [stiltCalendarEvents, setStiltCalendarEvents] = useState<
    ExistingStiltCalendarEvent[]
  >([]);
  const [selectedStiltEvent, setSelectedStiltEvent] =
    useState<ExistingStiltCalendarEvent | null>(null);
  const [editStiltEventDialogOpen, setEditStiltEventDialogOpen] =
    useState<boolean>(false);

  const [createTaskDialogOpen, setCreateTaskDialogOpen] =
    useState<boolean>(false);
  const startDateIsEarlier = startDateIsEarlierThanEndDate(
    startDateNewCalendarEvent,
    endDateNewCalendarEvent,
  );

  const [schedulerState, setSchedulerState] = useState<DayPilot.Scheduler>();
  const schedulerRef = useRef<DayPilotScheduler | null>(null);
  const calendarRef = useRef<DayPilotCalendar | null>(null);

  const [selectCreationChoiceDialogOpen, setSelectCreationChoiceDialogOpen] =
    useState<boolean>(false);
  const [resourceID, setResourceID] = useState<string | null>(null);
  const [eventDuration, setEventDuration] = useState<number | null>(null);
  const localStorageTableOrientation = localStorage.getItem(
    "tableOrientationIsHorizontal",
  );

  const [tableOrientationIsHorizontal, setTableOrientationIsHorizontal] =
    useState(
      localStorageTableOrientation
        ? JSON.parse(localStorageTableOrientation) === true
        : false,
    );

  const [vehicleList, setVehicleList] = useState<ExistingVehicle[]>([]);

  const [selectedVehicleID, setSelectedVehicleID] = useState<string | null>(
    null,
  );
  const [assignedToDialogOpen, setAssignedToDialogOpen] =
    useState<boolean>(false);

  const [dailyVehicleUsersMap, setDailyVehicleUsersMap] = useState<
    ExistingAssignedRoute["vehicleUsersMap"]
  >({});

  const dispatchBoardResourceTypeDefault: "technicians" | "vehicles" =
    siteKeyDoc?.customizations["dispatchBoardResourceTypeDefault"] ??
    "technicians";

  const [zoneList, setZoneList] = useState<ExistingZone[]>([]);

  const [isResourcesHidden, setIsResourcesHidden] = useState<boolean>(false);

  const [totalDistance, setTotalDistance] = useState<string>("false");

  const currentDateString = DateTime.fromJSDate(selectedDate).toISODate();

  const calculateDaypilotScrollTime = useCallback((): string => {
    if (!siteKeyDoc) return new Date().toISOString().split(".")[0];

    let todayISO: string;
    try {
      const today = DateTime.now().setZone(siteKeyDoc.timezone);
      if (!today.isValid) todayISO = DateTime.now().toISODate();
      else todayISO = today.toISODate();
    } catch (e) {
      todayISO = DateTime.now().toISODate();
    }

    let hour =
      typeof siteKeyDoc.customizations.dispatchBoardScrollHour === "number"
        ? siteKeyDoc.customizations.dispatchBoardScrollHour
        : DateTime.now().minus({ hours: 1 }).hour;

    if (hour < 0) hour = 0;
    if (hour > 23) hour = 23; // if this runs, dispatchBoardScrollHour needs some love

    const timePart = hour < 10 ? `0${hour}:00:00` : `${hour}:00:00`;

    // DayPilot's date string format - omit everything after seconds
    return `${todayISO}T${timePart}`;
  }, [siteKeyDoc]);

  const scrollTime = calculateDaypilotScrollTime();

  const resourcesColumnSelectorOptions: ColumnOption[] = [
    { value: "technicians", text: "Technicians" },
  ];

  const siteUsesVehicles =
    siteKeyDoc?.customizations.vehiclesEnabled === true &&
    vehicleList.length > 0;

  if (siteUsesVehicles) {
    resourcesColumnSelectorOptions.push({
      value: "vehicles",
      text: "Vehicles",
    });
  }

  const locationsColumnSelectorOptions: ColumnOption[] =
    getFormattedSiteLocations(siteKeyLocationList);

  const filteredSiteKeyLocations = getSiteKeyLocationSelected(
    siteKeyLocationList,
    locationsDropdownValue,
  );

  const getEstimateInvoicePaymentTotals = useCallback(
    async function getEstimateInvoicePaymentTotals(tasks: ExistingTask[]) {
      let scheduledSales = 0;
      let invoiceTotals = 0;
      let paymentTotals = 0;
      let taskTotals = 0;
      setTotalsLoading(true);

      // Filter tasks is locationID is in the locationsDropdownValue
      if (locationsDropdownValue.length > 0) {
        tasks = tasks.filter((task) =>
          locationsDropdownValue.includes(task.locationID),
        );
      }
      // Create an array of promises to process tasks in parallel
      const taskPromises = tasks.map(async (task) => {
        if (
          task.timestampScheduled &&
          sameDay(
            task.timestampScheduled.toDate(),
            DateTime.fromJSDate(selectedDate)
              .set({
                hour: 7,
                minute: 0,
                second: 0,
              })
              .toJSDate(),
          )
        ) {
          taskTotals += 1;

          // Get the estimates and invoices
          const [estimates, invoices] = await Promise.all([
            DbRead.estimates.getByTaskId(siteKey, task.id),
            DbRead.invoices.getByTaskId(siteKey, task.id),
          ]);

          scheduledSales += getScheduledSaleForTask(estimates);

          for (const invoice of invoices) {
            invoiceTotals += invoice.totalAmount;
            paymentTotals += invoice.totalAmount - invoice.amountDue;
          }
        }
      });

      // Wait for all tasks to complete
      await Promise.all(taskPromises);

      setTotalsLoading(false);
      const data = {
        scheduledSales: scheduledSales,
        invoiceTotals: invoiceTotals,
        paymentTotals: paymentTotals,
        tasks: taskTotals,
      };
      setTotals({ ...data });
    },
    [locationsDropdownValue, selectedDate, siteKey],
  );

  const [taskAssets, setTaskAssets] = useState<ExistingAsset[]>([]);

  useEffect(() => {
    async function loadTaskAssets() {
      if (!selectedTask) return;

      if (selectedTask.assetIDs) {
        try {
          // Use Promise.all to wait for all asset fetches
          const assetPromises = selectedTask.assetIDs.map((assetID) =>
            DbRead.assets.getByID(siteKey, assetID),
          );
          const fetchedAssets = await Promise.all(assetPromises);
          // Filter out any null results and assign to assets object
          setTaskAssets(fetchedAssets.filter((asset) => asset !== null));
        } catch (error) {
          console.error("Error loading asset:", error);
        }
      }
    }

    loadTaskAssets();
  }, [selectedTask, siteKey]);

  // Fetch the list of zones when this component loads.
  useEffect(() => {
    async function getZones() {
      // Query zones via realtime updates. Set the list of zones.
      return DbRead.zones.subscribeAll({
        siteKey: siteKey,
        onChange: (zoneList: ExistingZone[]) => {
          setZoneList(zoneList);
        },
        onError: (error) =>
          devLogger.error(`Error in getZones: ${error.message}`),
      });
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    const unsubscribePromise = getZones();
    // Return an anonymous ƒn for cleanup.
    return () => {
      unsubscribePromise.then((unsubscribe) => {
        if (unsubscribe) {
          unsubscribe();
        }
      });
    };
  }, [siteKey]);

  useEffect(() => {
    function getVehicles() {
      DbRead.vehicles.subscribeAll({
        siteKey: siteKey,
        onChange: (vehicles) => {
          setVehicleList(vehicles);
        },
        onError: (error) => {
          devLogger.error(`Error in getVehicles: ${error.message}`);
        },
      });
    }

    getVehicles();
  }, [siteKey]);

  useEffect(() => {
    function getTodaysTasks(): ExistingTask[] {
      return scheduledAwaitingTaskList.filter((task) => {
        const timestampScheduled = task.timestampScheduled;
        const dateTimeScheduled = timestampScheduled
          ? DateTime.fromMillis(timestampScheduled.toMillis())
          : null;
        return dateTimeScheduled
          ? dateTimeScheduled.toISODate() === currentDateString
          : false;
      });
    }

    async function getDistances() {
      if (isOptimizing) return;
      const totalDistance = await generateTotalRouteDistances({
        tasks: getTodaysTasks(),
        vehicleList:
          resourcesDropdownValue === "technicians" ? undefined : vehicleList,
        technicianList:
          resourcesDropdownValue === "technicians"
            ? filteredUsersList
            : undefined,
        permissionsMap:
          resourcesDropdownValue === "technicians" ? permissionsMap : undefined,
        siteKeyLocations: siteKeyLocationList,
      });
      setTotalDistance(totalDistance.toFixed(0));
    }

    getDistances();
  }, [
    currentDateString,
    scheduledAwaitingTaskList,
    vehicleList,
    isOptimizing,
    siteKeyLocationList,
    resourcesDropdownValue,
    filteredUsersList,
    permissionsMap,
  ]);

  useEffect(() => {
    function getDailyVehicleUsersMap() {
      // typeguard
      if (!userPermissions) {
        devLogger.debug("No userPermissions yet...");
        return undefined;
      }

      // Start loading.
      if (isMounted.current) setIsLoading(true);

      // Query for daily assignedRoute via realtime updates.
      const unsubscribeDailyAssignment =
        DbRead.assignedRoutes.subscribeDailyVehicleUsersMap({
          siteKey: siteKey,
          isoDate: DateTime.fromJSDate(selectedDate).toISODate(),
          onChange: setDailyVehicleUsersMap,
          onError: (error) => {
            devLogger.error(
              `Error in ${getDailyVehicleUsersMap.name}: ${error.message}`,
            );
          },
        });

      // If user hasn't navigated away from the page, stop rendering the loading icon.
      if (isMounted.current) {
        setIsLoading(false);
      }
      return unsubscribeDailyAssignment;
    }

    const unsubscribe = getDailyVehicleUsersMap();

    // Return an anonymous ƒn for cleanup.
    return () => {
      isMounted.current = false;
      if (unsubscribe) unsubscribe();
    };
  }, [selectedDate, siteKey, userPermissions]);

  useEffect(() => {
    if (!siteKeyDoc) return;

    if (
      dispatchBoardResourceTypeDefault === "vehicles" &&
      vehicleList.length === 0
    )
      return;

    setResourcesDropdownValue(dispatchBoardResourceTypeDefault);
  }, [dispatchBoardResourceTypeDefault, siteKeyDoc, vehicleList.length]);

  useEffect(() => {
    if (siteKeyUsersList.length === 0) return;

    assignedUserList.current = siteKeyUsersList
      // we don't want system users to be selectable in the multiple-uid dialog
      .filter((user) => user.systemUser !== true)
      .map((user) => ({
        uid: user.id,
        name: user.displayName,
        company: user.companyName,
        avatarUrl: user.userPhoto_URL,
        isAssigned: false,
        assignedVehicle: null,
      }));
  }, [siteKeyUsersList]);

  useEffect(() => {
    if (schedulerState) schedulerState.scrollTo(scrollTime);
  }, [schedulerState, scrollTime]);

  useEffect(() => {
    localStorage.setItem(
      "tableOrientationIsHorizontal",
      JSON.stringify(tableOrientationIsHorizontal),
    );
  }, [tableOrientationIsHorizontal]);

  useEffect(() => {
    function getCalendarEventListBySelectedDate() {
      // typeguard
      if (!userPermissions) {
        devLogger.debug("No userPermissions yet...");
        return undefined;
      }
      // Start loading.
      if (isMounted.current) setIsLoading(true);

      //setting the date selected to doesn't care about the hours selected but
      // check the entire day
      const startDate = DateTime.fromJSDate(selectedDate)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
        })
        .minus({ days: 30 });
      const endDate = DateTime.fromJSDate(selectedDate)
        .set({
          hour: 23,
          minute: 59,
          second: 59,
        })
        .plus({ days: 30 });

      // Query for all awaiting scheduled tasks via realtime updates.
      const unsubscribeCalendarEventList =
        DbRead.calendarEvents.subscribeByDate({
          siteKey: siteKey,
          permissions: userPermissions,
          userID: firebaseUser.uid,
          startDate: convertLuxonDTToFSTimestamp(startDate),
          endDate: convertLuxonDTToFSTimestamp(endDate),
          onChange: (events) => {
            if (dontRenderDispatchBoard) {
              return;
            }
            setStiltCalendarEvents(events);
          },
          onError: (error) =>
            devLogger.error(
              `Error in ${getCalendarEventListBySelectedDate.name}: ${error.message}`,
            ),
        });

      // If user hasn't navigated away from the page, stop rendering the loading icon.
      if (isMounted.current) {
        setIsLoading(false);
      }
      return unsubscribeCalendarEventList;
    }

    const unsubscribe = getCalendarEventListBySelectedDate();

    // Return an anonymous ƒn for cleanup.
    return () => {
      isMounted.current = false;

      if (unsubscribe) unsubscribe();
    };
  }, [
    firebaseUser.uid,
    selectedDate,
    siteKey,
    userPermissions,
    dontRenderDispatchBoard,
  ]);

  useEffect(() => {
    const startTime = convertToDisplayTime(
      startDateNewCalendarEvent.toJSDate(),
    );
    setDisplayStartTime(startTime);
    const endTime = convertToDisplayTime(endDateNewCalendarEvent.toJSDate());
    setDisplayEndTime(endTime);
  }, [endDateNewCalendarEvent, startDateNewCalendarEvent]);

  useEffect(() => {
    //if start date is older than end date, update end date with the current start date
    if (!startDateIsEarlier) {
      setEndDateNewCalendarEvent(
        startDateNewCalendarEvent.plus({ seconds: 1 }),
      );
    }
  }, [startDateIsEarlier, startDateNewCalendarEvent]);

  useEffect(() => {
    const filteredUsersList = siteKeyUsersList.filter(
      (user) => user.systemUser !== true,
    );
    setFilteredUsersList(filteredUsersList);
  }, [siteKeyUsersList]);

  useEffect(() => {
    if (filteredUsersList.length === 0) return;

    async function getUsersPermissionsMap() {
      const result: Record<string, ExistingSiteKeyUserPermissions> =
        await getPermissionsMap(filteredUsersList, siteKey);
      setPermissionsMap(result);
    }

    getUsersPermissionsMap();
  }, [siteKey, filteredUsersList]);

  useEffect(() => {
    function getScheduledAwaitingTaskList() {
      // typeguard
      if (!userPermissions) return;

      // Start loading.
      if (isMounted.current) setIsLoading(true);

      //setting the date selected to doesn't care about the hours selected but
      // check the entire day
      const startDate = DateTime.fromJSDate(selectedDate).set({
        hour: 0,
        minute: 0,
        second: 0,
      });
      const selectedDatePlus5 = DateTime.fromJSDate(selectedDate).plus({
        day: 5,
      });
      const endDate = selectedDatePlus5.set({
        hour: 23,
        minute: 59,
        second: 59,
      });

      // Query for all awaiting scheduled tasks via realtime updates.
      const unsubscribeScheduledAwaitingTaskList =
        DbRead.tasks.subscribeScheduledAwaitingTaskList({
          siteKey: siteKey,
          permissions: userPermissions,
          startDate: convertLuxonDTToFSTimestamp(startDate),
          endDate: convertLuxonDTToFSTimestamp(endDate),
          onChange: async (list) => {
            if (dontRenderDispatchBoard) {
              return;
            }
            if (isViewingTemporarilyOptimizedTasks) {
              return;
            }
            const tasksWithCustomerLocations: ExistingTaskWithCustomerLocation[] =
              [];
            const customerLocationIDs = list.flatMap(
              (task) => task.customerLocationID ?? [],
            );
            const uniqueCustomerLocationIDs = Array.from(
              new Set(customerLocationIDs),
            );
            // Promises all customerLocations
            const customerLocationPromises = uniqueCustomerLocationIDs.map(
              (clID) => DbRead.customerLocations.getSingle(siteKey, clID),
            );
            const customerLocations = await Promise.all(
              customerLocationPromises,
            );
            for (const task of list) {
              const taskWithCL: ExistingTaskWithCustomerLocation = task;
              // query customerLocation for each task
              if (task.customerLocationID) {
                taskWithCL.customerLocation = customerLocations.find(
                  (cl) => cl.id === task.customerLocationID,
                );
                tasksWithCustomerLocations.push(taskWithCL);
              }
            }
            setScheduledAwaitingTaskList(tasksWithCustomerLocations);
            getEstimateInvoicePaymentTotals(list);
          },
          onError: (error) =>
            devLogger.error(
              `Error in ${getScheduledAwaitingTaskList.name}: ${error.message}`,
            ),
        });

      // If user hasn't navigated away from the page, stop rendering the loading icon.
      if (isMounted.current) {
        setIsLoading(false);
      }
      return unsubscribeScheduledAwaitingTaskList;
    }

    const unsubscribe = getScheduledAwaitingTaskList();

    // Return an anonymous ƒn for cleanup.
    return () => {
      isMounted.current = false;
      if (unsubscribe) unsubscribe();
    };
  }, [
    getEstimateInvoicePaymentTotals,
    selectedDate,
    siteKey,
    userPermissions,
    dontRenderDispatchBoard,
    isViewingTemporarilyOptimizedTasks,
  ]);

  const resourcesForCalendar = generateResourcesForDayPilotCalendar(
    vehicleList,
    filteredUsersList,
    siteKeyLocationList,
    resourcesDropdownValue,
    permissionsMap,
    dailyVehicleUsersMap,
  );

  const filteredResourcesForCalendar =
    filterCalendarResourcesByLocationDropdownValues(
      resourcesForCalendar,
      filteredSiteKeyLocations,
      locationsDropdownValue,
    );

  const resourcesForScheduler = generateResourcesForDayPilotScheduler(
    vehicleList,
    filteredUsersList,
    filteredSiteKeyLocations,
    resourcesDropdownValue,
    permissionsMap,
  );

  const calendarEvents = generateCalendarEvents({
    siteKey: siteKeyDoc,
    siteKeyLocations: siteKeyLocationList,
    allocatedTasks: scheduledAwaitingTaskList,
    vehicleList: vehicleList,
    searchTerm: searchTerm,
    mode: resourcesDropdownValue,
    inactiveUserIDs: siteKeyDoc?.inactiveUsers ?? [],
    unapprovedUserIDs: siteKeyDoc?.unapprovedUsers ?? [],
    zoneList: zoneList,
  });

  if (stiltCalendarEvents.length !== 0) {
    const stiltEvents = generateStiltCalendarEvents({
      stiltCalendarEvents,
    });
    calendarEvents.push(...stiltEvents);
  }

  const mutateAddNewCalendarEvent = useMutation(
    async (args: { newCalendarEvent: StiltCalendarEvent }) => {
      await DbWrite.calendarEvents.add(siteKey, args.newCalendarEvent);
    },
  );

  const mutateEditStiltEvent = useMutation(
    async (args: { stiltEventID: string; updateEventData: DocumentData }) => {
      await DbWrite.calendarEvents.update(
        args.stiltEventID,
        siteKey,
        args.updateEventData,
      );
    },
  );

  const mutateDeleteStiltEvent = useMutation(
    async (args: { stiltEventID: string }) => {
      await DbWrite.calendarEvents.delete(args.stiltEventID, siteKey);
    },
  );

  /* FUNCTIONS */
  function resetCalendarEventDatesAndResource() {
    setStartDateNewCalendarEvent(DateTime.now().minus({ hours: 1 }));
    setEndDateNewCalendarEvent(DateTime.now());
    setResourceID(null);
  }

  async function handleUpdateTask(updateData: DocumentData, taskID: string) {
    await DbWrite.tasks.update(updateData, siteKey, taskID);
  }

  async function handleUpdateTSD(updateData: DocumentData, refPath: string) {
    await DbWrite.taskSpecificDetails.update(refPath, updateData);
  }

  async function handleUpdateTasksWithOptimizations() {
    // Loop through the scheduledAwaitingTaskList and update each task
    // The only changes should be to the task's assignedVehicleID, timestampScheduled, scheduledServiceWindowStart, and scheduledServiceWindowEnd
    setIsSavingOptimizedTasks(true);
    const updatePromises = scheduledAwaitingTaskList.map((task) => {
      const updateData = {
        timestampScheduled: task.timestampScheduled,
        timestampLastModified: Timestamp.now(),
        lastModifiedBy: firebaseUser.uid,
      };
      return handleUpdateTask(updateData, task.id);
    });
    await Promise.all(updatePromises);

    const updatePromisesTSD = scheduledAwaitingTaskList.map((task) => {
      // Only update the fields that should actually be updated from optimization
      const updateData: Record<string, any> = {
        refPath: task.refPath,
        assignedVehicleID: task.taskSpecificDetails.assignedVehicleID,
      };
      if (task.taskSpecificDetails.scheduledServiceWindowStart) {
        updateData.scheduledServiceWindowStart = convertFSTimestampToLuxonDT(
          task.taskSpecificDetails.scheduledServiceWindowStart,
        ).toISO();
      }
      if (task.taskSpecificDetails.scheduledServiceWindowEnd) {
        updateData.scheduledServiceWindowEnd = convertFSTimestampToLuxonDT(
          task.taskSpecificDetails.scheduledServiceWindowEnd,
        ).toISO();
      }
      return handleUpdateTSD(updateData, task.refPath);
    });
    await Promise.all(updatePromisesTSD);

    setIsSavingOptimizedTasks(false);
    setIsViewingTemporarilyOptimizedTasks(false);
  }

  async function handleAssignUsersToVehicle(selectedUserIDs: string[]) {
    if (!selectedVehicleID) return;
    //write on assignedRoute collection the vehicleID with the selectedUserIDs
    dailyVehicleUsersMap[selectedVehicleID] = selectedUserIDs;

    const dataToSend: AssignedRoute_UpdateAPI = {
      assignedRouteID: DateTime.fromJSDate(selectedDate).toISODate(),
      siteKey: siteKey,
      vehicleUsersMap: dailyVehicleUsersMap,
    };

    //when write to db nees to send a customID (the selectedDate.toIsoDate()) & the dailyAssignment record
    try {
      await DbWrite.assignedRoutes.update(dataToSend);
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(
          `Assigned Route for the vehicle: ${selectedVehicleID} for the day ${DateTime.fromJSDate(selectedDate).toISODate()}`,
        ),
        dialog: true,
        type: "success",
      });

      //reset to false the isAssigned prop
      resetAssignedUserList(assignedUserList.current);
    } catch (error) {
      devLogger.error(
        `An error occurred during handleAssignUsersToVehicle for updating assigned route`,
        error,
      );
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: true,
        type: "error",
      });
    }
  }

  function getLocationDoc(locationID: string): ExistingSiteKeyLocation {
    const found = siteKeyLocationList.find(
      (location) => location.id === locationID,
    );
    if (!found) throw new Error(`Location not found: ${locationID}`);
    return found;
  }

  function getTodaysStiltEvents(): ExistingStiltCalendarEvent[] {
    return stiltCalendarEvents.filter((event) => {
      const start = DateTime.fromMillis(event.start.toMillis());
      return start.toISODate() === currentDateString;
    });
  }

  async function optimize() {
    function getTodaysTasks(): ExistingTask[] {
      return scheduledAwaitingTaskList.filter((task) => {
        const timestampScheduled = task.timestampScheduled;
        const dateTimeScheduled = timestampScheduled
          ? DateTime.fromMillis(timestampScheduled.toMillis())
          : null;
        return dateTimeScheduled
          ? dateTimeScheduled.toISODate() === currentDateString
          : false;
      });
    }

    if (!siteKeyDoc) return;
    setIsOptimizing(true);
    try {
      // Geocode all tasks where geocoding is necessary
      const tasksToGeocode: string[] = [];
      scheduledAwaitingTaskList.forEach((task) => {
        if (!determineIfTaskIsGeocoded(siteKeyDoc, siteKeyLocationList, task)) {
          tasksToGeocode.push(task.id);
        }
      });

      if (tasksToGeocode.length > 0) {
        logger.debug("Geocoding tasks", tasksToGeocode);
        await DbWrite.tasks.geocode(siteKey, tasksToGeocode);
      }

      const result = await allocateTask({
        siteKeyID: siteKey,
        vehicleList: vehicleList,
        scheduledTaskList: getTodaysTasks(),
        currentDateString: currentDateString,
        siteKeyWorkTypes: siteKeyDoc.validCraftTypes,
        siteKeyTaskTypes: siteKeyDoc.validTaskTypes,
        getLocationDoc: getLocationDoc,
        // stiltEvents: getTodaysStiltEvents(),
        smartSchedulingSettings: parseSmartSchedulingFormValues(
          siteKeyDoc?.customizations.smartSchedulingSettings,
        ),
        originalTotalDistanceMiles: Number(totalDistance),
        lockAllVehicles: false,
      });
      devLogger.info("optimization result", result);
      setIsOptimizing(false);
      setIsViewingTemporarilyOptimizedTasks(true);
      setScheduledAwaitingTaskList(result.tasks);
      setOptimizedTasks(result);
    } catch (e) {
      if (e instanceof ZodError) {
        devLogger.info("🚨 ZOD ERROR:", e.flatten().fieldErrors);
      }
      devLogger.error("Error optimizing", e);
      setIsOptimizing(false);
      addMessage({
        id: createToastMessageID(),
        message: strings.ERR_OPTIMIZING,
        dialog: false,
        type: "error",
      });
    }
  }

  function onChangeDatePicker(date: Date | null): void {
    if (date) {
      const luxonDate = DateTime.fromJSDate(date);
      setSelectedDate(luxonDate.toJSDate());
    }
  }

  const assignedUserList = useRef<IMultipleUid_AssignUser[]>([]);

  async function calendarEventClicked(event: DayPilot.Event["data"]) {
    if (event.tags.taskID) {
      const clickedTask = scheduledAwaitingTaskList.find(
        (task) => task.id === event.tags.taskID,
      );
      if (clickedTask) {
        setSelectedTask(clickedTask);
        setTaskDetailsDialogOpen(true);
        setWithRescheduleTaskDialogOpen(false); // Reset this when opening normally
      }
    } else if (event.tags.stiltEventID) {
      const stiltEvent = stiltCalendarEvents.find(
        (stiltEvent) => stiltEvent.id === event.tags.stiltEventID,
      );
      if (stiltEvent) {
        setSelectedStiltEvent(stiltEvent);
        setEditStiltEventDialogOpen(true);
        setStartDateNewCalendarEvent(
          convertFSTimestampToLuxonDT(stiltEvent.start),
        );
        setEndDateNewCalendarEvent(convertFSTimestampToLuxonDT(stiltEvent.end));
      }
    }
  }

  async function updateStiltEvent(
    updateData: UpdateStiltEventType,
    stiltEventToUpdate: ExistingStiltCalendarEvent,
  ): Promise<void> {
    const stiltEventDiff = diffObjects(stiltEventToUpdate, updateData).diff;

    const validatedUpdateData =
      StiltCalendarEventManager.parseUpdate(stiltEventDiff);

    await mutateEditStiltEvent.mutateAsync({
      stiltEventID: stiltEventToUpdate.id,
      updateEventData: validatedUpdateData,
    });
  }

  // TODO: extract
  async function updateTask(args: {
    taskID: string;
    newStart: string;
    newEnd: string;
    newResourceID?: string;
    originalResourceID?: string;
  }): Promise<string> {
    const taskToBeUpdated = scheduledAwaitingTaskList.find(
      (task) => task.id === args.taskID,
    );
    if (!taskToBeUpdated) throw new Error("Task not found");

    const initialTask = {
      timestampScheduled: taskToBeUpdated.timestampScheduled,
    };
    const updateTask = {
      timestampScheduled: convertISOToFSTimestamp(args.newStart),
    };
    const taskDiff: DocumentData = diffObjects(initialTask, updateTask).diff;

    const updateData = {
      ...taskDiff,
      timestampLastModified: Timestamp.now(),
      lastModifiedBy: firebaseUser.uid,
    };

    // Conditionally add items to taskSpecificDetails. Not all of these fields
    // may exist for the site, so we should only add the ones that were already
    // there
    const originalTaskDetails = taskToBeUpdated.taskSpecificDetails;
    const updatedTaskSpecificDetails: { [key: string]: any } = {};
    if (originalTaskDetails["scheduledServiceWindowStart"]) {
      const lux = DateTime.fromISO(args.newStart);
      if (!lux.isValid) {
        throw new Error(
          `Unable to parse date given for scheduledServiceWindowStart. Invalid args.newStart: ${args.newStart}`,
        );
      }
      updatedTaskSpecificDetails["scheduledServiceWindowStart"] = lux.toISO({
        includeOffset: true,
        suppressMilliseconds: true,
      });
    }
    if (originalTaskDetails["scheduledServiceWindowEnd"]) {
      const lux = DateTime.fromISO(args.newEnd);
      if (!lux.isValid) {
        throw new Error(
          `Unable to parse date given for scheduledServiceWindowEnd. Invalid args.newEnd: ${args.newEnd}`,
        );
      }
      updatedTaskSpecificDetails["scheduledServiceWindowEnd"] = lux.toISO({
        includeOffset: true,
        suppressMilliseconds: true,
      });
    }
    if (originalTaskDetails["lockAssignedVehicle"]) {
      updatedTaskSpecificDetails["lockAssignedVehicle"] = true;
    }

    if (args.originalResourceID != null && args.newResourceID != null) {
      // ^ when updateTask is called from calendarEventResized and calendarEventMoved
      // ... are these ever undefined when i think they're strings?? TODO:
      if (resourcesDropdownValue === "technicians") {
        if (isStringArray(originalTaskDetails["assignedTo"])) {
          const assignedTo = originalTaskDetails["assignedTo"];
          const index = assignedTo.indexOf(args.originalResourceID);
          if (index > -1) assignedTo.splice(index, 1); // remove old tech (if applicable)
          assignedTo.push(args.newResourceID); // add new tech
          updatedTaskSpecificDetails["assignedTo"] = assignedTo;
        }
      } else if (resourcesDropdownValue === "vehicles" && siteUsesVehicles) {
        updatedTaskSpecificDetails["assignedVehicleID"] = args.newResourceID;
        if (
          dailyVehicleUsersMap[args.newResourceID] != null &&
          dailyVehicleUsersMap[args.newResourceID].length > 0
        ) {
          updatedTaskSpecificDetails["assignedTo"] =
            dailyVehicleUsersMap[args.newResourceID];
        } else {
          updatedTaskSpecificDetails["assignedTo"] = [];
        }
      }
    } else if (args.originalResourceID == null && args.newResourceID != null) {
      if (resourcesDropdownValue === "technicians") {
        /* this happens when the task start from unassigned position & get moved to a new resource */
        const assignedTo: string[] = originalTaskDetails[
          "assignedTo"
        ] as string[];
        assignedTo.push(args.newResourceID);
        updatedTaskSpecificDetails["assignedTo"] = assignedTo;
      } else if (resourcesDropdownValue === "vehicles" && siteUsesVehicles) {
        /* this happens when the task start from unassigned position & get moved to a new resource */
        updatedTaskSpecificDetails["assignedVehicleID"] = args.newResourceID;
        if (
          dailyVehicleUsersMap[args.newResourceID] != null &&
          dailyVehicleUsersMap[args.newResourceID].length > 0
        ) {
          updatedTaskSpecificDetails["assignedTo"] =
            dailyVehicleUsersMap[args.newResourceID];
        } else {
          updatedTaskSpecificDetails["assignedTo"] = [];
        }
      }
    }

    /** for comparing the original taskSpecificDetails to the ones constructed above. */
    const compareTaskDetails: { [key: string]: any } = {};
    if (originalTaskDetails["scheduledServiceWindowStart"]) {
      compareTaskDetails["scheduledServiceWindowStart"] =
        originalTaskDetails["scheduledServiceWindowStart"];
    }
    if (originalTaskDetails["scheduledServiceWindowEnd"]) {
      compareTaskDetails["scheduledServiceWindowEnd"] =
        originalTaskDetails["scheduledServiceWindowEnd"];
    }
    if (originalTaskDetails["assignedVehicleID"]) {
      compareTaskDetails["assignedVehicleID"] =
        originalTaskDetails["assignedVehicleID"];
    }
    if (originalTaskDetails["lockAssignedVehicle"]) {
      compareTaskDetails["lockAssignedVehicle"] =
        originalTaskDetails["lockAssignedVehicle"];
    }

    const tsdDiff: DocumentData = diffObjects(
      compareTaskDetails,
      updatedTaskSpecificDetails,
    ).diff;

    // if there were no changes, don't update TODO:
    await handleUpdateTask(updateData, taskToBeUpdated.id);
    // Task doc needs to update before TSD. That's why we're not using Promise.all
    // if there were no changes, don't update TODO:
    await handleUpdateTSD(tsdDiff, taskToBeUpdated.refPath);
    return taskToBeUpdated.title;
  }

  async function handleSaveNewCalendarEvent(
    formValues: Omit<StiltCalendarEvent, "start" | "end">,
  ): Promise<void> {
    if (!startDateIsEarlier) {
      addMessage({
        id: createToastMessageID(),
        message: strings.ERROR_START_DATE_OLDER,
        dialog: false,
        type: "error",
      });
      return;
    }
    const newCalendarEvent: StiltCalendarEvent = {
      ...formValues,
      start: convertLuxonDTToFSTimestamp(startDateNewCalendarEvent),
      end: convertLuxonDTToFSTimestamp(endDateNewCalendarEvent),
    };

    await mutateAddNewCalendarEvent.mutateAsync({
      newCalendarEvent,
    });
  }

  async function handleDeleteCalendarEvent() {
    if (!selectedStiltEvent) {
      throw new Error("handleDeleteCalendarEvent: No event selected");
    }

    await mutateDeleteStiltEvent.mutateAsync({
      stiltEventID: selectedStiltEvent.id,
    });
  }

  async function handleEditCalendarEvent(
    formValues: Omit<StiltCalendarEvent, "start" | "end">,
  ) {
    if (!selectedStiltEvent) {
      throw new Error("handleEditCalendarEvent: No event selected");
    }
    const updateData = {
      ...formValues,
      start: convertLuxonDTToFSTimestamp(startDateNewCalendarEvent),
      end: convertLuxonDTToFSTimestamp(endDateNewCalendarEvent),
    };

    await updateStiltEvent(updateData, selectedStiltEvent);
  }

  function sameDay(d1: Date, d2: Date) {
    return (
      d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
    );
  }

  /**
   * Calendar events (as displayed on UI) and StiltCalendarEvents are not of
   * a one-to-one relationship. This function returns the StiltCalendarEvent that
   * corresponds to the calendar event that the user interacted with.
   */
  function getOriginalStiltEvent(
    stiltEventID: string,
    originalResourceID: string,
  ): ExistingStiltCalendarEvent | null {
    const found = stiltCalendarEvents.find(
      (ev) =>
        ev.id === stiltEventID &&
        (ev.assignedTo.includes(originalResourceID) ||
          ev.assignedVehicleIDs?.includes(originalResourceID)),
    );

    return found ?? null;
  }

  /* COMPONENTS */
  const assignedToDialog = assignedUserList.current.length > 0 && (
    <MultipleUidDialog
      open={assignedToDialogOpen}
      closeDialog={() => {
        setAssignedToDialogOpen(false);
        setSelectedVehicleID(null);
        resetAssignedUserList(assignedUserList.current);
      }}
      userList={assignedUserList.current}
      handleSubmit={(event) => {
        const selectedList = event.filter((user) => user.isAssigned);
        const selectedUserIDs = selectedList.map((user) => user.uid);
        handleAssignUsersToVehicle(selectedUserIDs);
      }}
    />
  );

  const resourcesColumnDropdown = (
    <ResourcesColumnDropdown
      onSelection={(selectedValue: ColumnOption["value"]) =>
        setResourcesDropdownValue(selectedValue)
      }
      optionList={resourcesColumnSelectorOptions}
      initialValue={resourcesDropdownValue}
    />
  );

  const locationsColumnDropdown = locationsColumnSelectorOptions.length > 1 && (
    <MultipleSelectionDropdown
      onSelection={(multipleSelectionValue: ColumnOption["value"][]) =>
        setLocationsDropdownValue(multipleSelectionValue)
      }
      optionList={locationsColumnSelectorOptions}
      initialValue={locationsDropdownValue}
      selectionType="location"
    />
  );

  const searchBox = (
    <SchedulingSearchBox
      placeholder="Search Tasks..."
      handleSearchTerm={setSearchTerm}
    />
  );

  const totalDistancePill = (
    <PillMetric
      key="totalDistancePill"
      text="Miles"
      value={totalDistance}
      color="gray"
      isLoading={totalsLoading}
    />
  );

  const totalsMetrics = siteKeyDoc?.customizations?.estimatesEnabled &&
    siteKeyDoc?.customizations?.invoicesEnabled && (
      <Fragment>
        <div className="flex flex-row">
          <PillMetric
            key="totalTasks"
            text="Tasks"
            value={totals.tasks?.toString() ?? "0"}
            color="gray"
            // isLoading={totalsLoading}
          />
          <PillMetric
            key="totalEstimates"
            text="Estimates"
            value={currencyFormatter(
              totals.scheduledSales,
              siteKeyDoc?.customizations?.accounting?.currency ?? "USD",
            )}
            color="blue"
            // isLoading={totalsLoading}
          />
          <PillMetric
            key="totalInvoices"
            text="Invoiced"
            value={currencyFormatter(
              totals.invoiceTotals,
              siteKeyDoc?.customizations?.accounting?.currency ?? "USD",
            )}
            color="orange"
            // isLoading={totalsLoading}
          />
          <PillMetric
            key="totalPayments"
            text="Received"
            value={currencyFormatter(
              totals.paymentTotals,
              siteKeyDoc?.customizations?.accounting?.currency ?? "USD",
            )}
            color="green"
            // isLoading={totalsLoading}
          />
        </div>
      </Fragment>
    );

  const datePicker = (
    <div className="flex flex-row items-center">
      <StyledTooltip title="Previous Day">
        <button
          type="button"
          onClick={() => {
            const previousDay = DateTime.fromJSDate(selectedDate)
              .minus({ day: 1 })
              .set({ hour: 6 });
            setSelectedDate(previousDay.toJSDate());
          }}
          className="mr-2 h-10 rounded-md border border-gray-300 bg-white px-4 text-gray-800 shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2"
        >
          <ArrowLeft />
        </button>
      </StyledTooltip>
      {!sameDay(selectedDate, DateTime.now().toJSDate()) && (
        <StyledTooltip title="Today">
          <button
            type="button"
            onClick={() => {
              setSelectedDate(getCurrentJSDateWithoutSeconds());
            }}
            className="ml-1 mr-2 inline-flex h-10 items-center justify-center rounded-md border border-gray-300 bg-white p-2 text-gray-600 shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2"
          >
            <TodayIcon />
          </button>
        </StyledTooltip>
      )}
      <div className="w-60">
        <DatePicker
          selected={selectedDate}
          onChange={onChangeDatePicker}
          showTimeSelect={false}
          dateFormat="cccc - M/d/yyyy"
          customInput={<SchedulingButton />}
        />
      </div>
      <StyledTooltip title="Next Day">
        <button
          type="button"
          onClick={() => {
            const nextDay = DateTime.fromJSDate(selectedDate)
              .plus({ day: 1 })
              .set({ hour: 6 });
            setSelectedDate(nextDay.toJSDate());
          }}
          className="mx-2 h-10 rounded-md border border-gray-300 bg-white px-4 text-gray-800 shadow-sm transition-colors focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2"
        >
          <ArrowRight />
        </button>
      </StyledTooltip>
    </div>
  );

  const startDatePicker = (
    <div className="grid grid-cols-2 gap-4">
      <div className="flex items-center justify-between gap-4">
        <label className="whitespace-nowrap text-gray-500">Start Date: </label>
        <div>
          <DatePicker
            selected={startDateNewCalendarEvent.toJSDate()}
            onChange={(date: Date) => {
              const luxonDate = DateTime.fromJSDate(date);
              setStartDateNewCalendarEvent(luxonDate);
            }}
            showTimeSelect={true}
            customInput={<SchedulingButton />}
          />
        </div>
      </div>
      <div className="flex items-center justify-end gap-4 whitespace-nowrap">
        <label className="text-gray-500">Start Time: </label>
        <span>{displayStartTime}</span>
      </div>
    </div>
  );

  const endDatePicker = (
    <div className="grid grid-cols-2 gap-4">
      <div className="flex items-center justify-between gap-4">
        <label className="whitespace-nowrap text-gray-500">End Date: </label>
        <div>
          <DatePicker
            selected={endDateNewCalendarEvent.toJSDate()}
            onChange={(date: Date) => {
              const luxonDate = DateTime.fromJSDate(date);
              setEndDateNewCalendarEvent(luxonDate);
            }}
            showTimeSelect={true}
            customInput={<SchedulingButton />}
            minDate={startDateNewCalendarEvent.toJSDate()}
          />
        </div>
      </div>
      <div className="flex items-center justify-end gap-4 whitespace-nowrap">
        <label className="text-gray-500">End Time: </label>
        <span>{displayEndTime}</span>
      </div>
    </div>
  );

  const addStiltCalendarEventDialog = addNewEventDialogOpen && (
    <AddStiltCalendarEventDialog
      isOpen={addNewEventDialogOpen}
      onClose={() => {
        setAddNewEventDialogOpen(false);
        resetCalendarEventDatesAndResource();
      }}
      assignedTo={resourceID}
      assignedVehicleIDs={resourceID}
      resourcesMode={
        resourcesDropdownValue === "vehicles" && siteUsesVehicles
          ? "vehicles"
          : "technicians"
      }
      siteUsesVehicles={siteUsesVehicles}
      vehicleList={vehicleList}
      handleSave={handleSaveNewCalendarEvent}
      userList={assignedUserList.current}
    >
      {{
        StartDatePicker: startDatePicker,
        EndDatePicker: endDatePicker,
      }}
    </AddStiltCalendarEventDialog>
  );

  const editStiltCalendarEventDialog = editStiltEventDialogOpen &&
    selectedStiltEvent && (
      <EditStiltCalendarEventDialog
        isOpen={editStiltEventDialogOpen}
        stiltEvent={selectedStiltEvent}
        onClose={() => {
          setEditStiltEventDialogOpen(false);
          setSelectedStiltEvent(null);
          resetCalendarEventDatesAndResource();
        }}
        handleSave={handleEditCalendarEvent}
        handleDelete={handleDeleteCalendarEvent}
        userList={assignedUserList.current}
        vehicleList={vehicleList}
      >
        {{
          StartDatePicker: startDatePicker,
          EndDatePicker: endDatePicker,
        }}
      </EditStiltCalendarEventDialog>
    );

  // const saveAllocateVehiclesBtn = (
  //   <BaseButtonPrimary
  //     className="flex gap-2 uppercase"
  //     onClick={saveAssignedVehicleIDs}
  //     isBusy={isSavingAssignedVehicle}
  //     busyText={strings.buttons.BUSY_SAVING}
  //   >
  //     {strings.buttons.SAVE}
  //   </BaseButtonPrimary>
  // );

  const createTaskBtn = (
    <BaseButtonPrimary
      className="flex gap-2 uppercase"
      onClick={() => setCreateTaskDialogOpen(true)}
    >
      <AddIcon />
      {strings.CREATE_JOB}
    </BaseButtonPrimary>
  );

  const createTaskDialog = createTaskDialogOpen && (
    <BaseModal
      open={createTaskDialogOpen}
      closeModal={() => {}}
      allowOverflowY={true}
      title={
        <div className="flex items-center justify-between rounded-t-lg bg-primary p-6 text-xl font-semibold text-white md:px-8">
          <h1>{strings.CREATE_JOB}</h1>
          <button
            onClick={() => setCreateTaskDialogOpen(false)}
            className="focus-visible:outline focus-visible:outline-white"
          >
            <XMarkIcon aria-label="close dialog" className="h-6 w-6" />
          </button>
        </div>
      }
      parentDivStyles="text-left max-w-md md:max-w-5xl h-screen"
    >
      <div className="mt-8">
        <CreateTaskForCustomerContainer
          siteKey={siteKey}
          setCreateTaskDialogOpen={setCreateTaskDialogOpen}
          startDate={startDateNewCalendarEvent.toJSDate()}
          eventDuration={eventDuration}
          assignedTo={resourceID}
          isDialog={true}
        />
      </div>
    </BaseModal>
  );

  const addNewEventBtn = (
    <BaseButtonSecondary
      className="flex gap-2 uppercase"
      onClick={() => {
        setStartDateNewCalendarEvent(DateTime.now());
        setEndDateNewCalendarEvent(DateTime.now().plus({ hours: 1 }));
        setAddNewEventDialogOpen(true);
      }}
    >
      <EventIcon />
      {strings.buttons.NEW_EVENT}
    </BaseButtonSecondary>
  );

  const configureZonesBtn = (
    <BaseButtonSecondary
      className="flex gap-2 uppercase"
      onClick={() => navigate(`${ZONES_URL}`)}
    >
      <SettingsSuggest />
      {strings.ZONES}
    </BaseButtonSecondary>
  );

  const hideResourcesNotInUseBtn = (
    <StyledTooltip title="Hide vehicles/technicians with no tasks">
      <BaseButtonSecondary
        className={`${isResourcesHidden && "bg-yellow-400 hover:bg-yellow-200"}`}
        onClick={() => {
          setIsResourcesHidden(!isResourcesHidden);
          if (calendarRef != null) {
            calendarRef.current?.control.columns.filter(!isResourcesHidden);
          }
          if (schedulerRef != null) {
            schedulerRef.current?.control.rows.filter(!isResourcesHidden);
          }
        }}
      >
        <HideSourceIcon className="text-gray-600" />
      </BaseButtonSecondary>
    </StyledTooltip>
  );

  const selectCreationChoiceDialog = (
    <ChooseCreateJobOrEventDialog
      open={selectCreationChoiceDialogOpen}
      onClose={() => {
        setSelectCreationChoiceDialogOpen(false);
      }}
      onEventSelected={() => {
        setSelectCreationChoiceDialogOpen(false);
        setAddNewEventDialogOpen(true);
      }}
      onJobSelected={() => {
        setSelectCreationChoiceDialogOpen(false);
        setCreateTaskDialogOpen(true);
      }}
    />
  );

  // Display loading clipboard while data loads.
  if (isLoading || !siteKeyDoc) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  const calendarConfig: CalendarProps = {
    // columnWidthSpec: "Fixed" as any,
    // columnWidth: 100,
    bubble: stiltTaskBubble(siteKeyDoc),
    cellDuration: 60,
    // cellWidth: 120,
    cellHeight: 50,
    eventDeleteHandling: "Disabled" as any,
    headerHeight: 60,
    headerTextWrappingEnabled: false,
    heightSpec: "Parent100Pct" as any,
    startDate: currentDateString,
    timeRangeSelectedHandling: "Enabled" as any,
    useEventBoxes: "Never" as any,
    viewType: "Resources" as any,
    dynamicEventRendering: "Disabled" as any,
    eventRightClickHandling: "ContextMenu" as any,
    contextMenu: new DayPilot.Menu({
      items: [
        {
          text: "Open In New Tab",
          onClick: (menuItemClickArgs: DayPilot.MenuItemClickArgs) => {
            const task = menuItemClickArgs.source.data?.tags?.task;
            const craftRecordID = task?.craftRecordID.split("/").pop();
            if (craftRecordID) {
              // open in new tab
              window.open(
                `${WORK_RECORD_AND_TASKS_URL}/${craftRecordID}`,
                "_blank",
              );
            }
          },
        },
        {
          text: "Edit Date",
          onClick: (menuItemClickArgs: DayPilot.MenuItemClickArgs) => {
            const task = menuItemClickArgs.source.data?.tags?.task;
            if (task) {
              setSelectedTask(task);
              setTaskDetailsDialogOpen(true);
              setWithRescheduleTaskDialogOpen(true);
            }
          },
        },
      ],
    }),
    onColumnFilter: (args: DayPilot.CalendarColumnFilterArgs) => {
      args.visible = !args.column.events.isEmpty();
    },
    onBeforeHeaderRender: (args: DayPilot.CalendarBeforeHeaderRenderArgs) => {
      customizeHeader(
        args,
        calendarEvents,
        resourcesDropdownValue,
        DateTime.fromJSDate(selectedDate),
      );
    },
    onEventClicked: (args: DayPilot.CalendarEventClickedArgs) => {
      calendarEventClicked(args.e.data);
    },
    onEventMoved: async (args: DayPilot.CalendarEventMovedArgs) => {
      await calendarEventMoved({
        daypilot: args,
        getOriginalStiltEvent: getOriginalStiltEvent,
        updateTask: updateTask,
        updateStiltEvent: updateStiltEvent,
        addMessage: addMessage,
        resourcesMode: resourcesDropdownValue,
      });
      setDontRenderDispatchBoard(false);
    },
    onEventMoving: (_args: DayPilot.CalendarEventMovingArgs) => {
      setDontRenderDispatchBoard(true);
    },
    onBeforeEventRender: (args: DayPilot.CalendarBeforeEventRenderArgs) => {
      if (args.data.backColor) {
        args.data.fontColor = getFontColorForBackground(args.data.backColor);
      }
      if (args.data.dotColor) {
        if (!args.data.areas) {
          args.data.areas = [];
        }
        args.data.areas.push({
          right: 2,
          top: "calc(100% - 10px)",
          width: 8,
          height: 8,
          symbol: "icons/daypilot.svg#checkmark-2",
          backColor: args.data.dotColor,
          fontColor: "#ffffff",
          padding: 2,
          style: "border-radius: 50%",
        });
      }
      if (
        args.data.tags?.task?.taskSpecificDetails?.completionOptions ===
          "followUpNeeded" ||
        args.data.tags?.task?.taskSpecificDetails?.completionOptions ===
          "partsNeeded" ||
        args.data.tags?.task?.taskSpecificDetails?.completionOptions ===
          "quoteNeeded"
      ) {
        if (!args.data.areas) {
          args.data.areas = [];
        }
        args.data.areas.push({
          right: 5,
          top: "calc(100% - 20px)",
          width: 20,
          height: 20,
          image: "/person-alert-icon.svg",
        });
      }
    },
    onTimeRangeSelected: (daypilot: DayPilot.CalendarTimeRangeSelectedArgs) => {
      handleTimeRangeSelected({
        daypilot,
        setEndDateNewCalendarEvent,
        setStartDateNewCalendarEvent,
        setResourceID,
        setEventDuration,
        setSelectCreationChoiceDialogOpen,
      });
    },
    onEventResized: async (args: DayPilot.CalendarEventResizedArgs) => {
      await calendarEventResized({
        daypilot: args,
        getOriginalStiltEvent: getOriginalStiltEvent,
        updateTask: updateTask,
        updateStiltEvent: updateStiltEvent,
        addMessage: addMessage,
      });
    },
    onHeaderClicked: (args: DayPilot.CalendarHeaderClickedArgs) => {
      if (resourcesDropdownValue === "vehicles") {
        const selectedVehicle = args.column.id as string;
        setSelectedVehicleID(selectedVehicle);
        setAssignedToDialogOpen(true);
        const assignedUsersForSelectedVehicle =
          dailyVehicleUsersMap[selectedVehicle];

        for (const [vehicleID, assignedUsers] of Object.entries(
          dailyVehicleUsersMap,
        )) {
          if (selectedVehicle === vehicleID) continue;

          const vehicleDoc = vehicleList.filter(
            (vehicle) => vehicle.id === vehicleID,
          );
          assignedUserList.current.forEach((user) => {
            if (assignedUsers.includes(user.uid)) {
              user.assignedVehicle = vehicleDoc[0].title;
            }
          });
        }

        if (assignedUsersForSelectedVehicle?.length > 0) {
          assignedUserList.current.forEach((user) => {
            if (assignedUsersForSelectedVehicle.includes(user.uid)) {
              user.isAssigned = true;
            }
          });
        }
      }
    },
  };

  const nowISO = DateTime.now().toISO({
    includeOffset: false,
    suppressMilliseconds: true,
  });
  const cellWidth = 100;

  const calendarConfigScheduler: SchedulerProps = {
    businessBeginsHour: 6,
    businessEndsHour: 20,
    cellWidth: cellWidth,
    days: 7,
    eventStackingLineHeight: 85,
    eventTextWrappingEnabled: true,
    dynamicEventRendering: "Disabled" as any,
    heightSpec: "Parent100Pct" as any,
    scale: "Hour" as any,
    separators: [{ color: "red", location: nowISO }],
    startDate: currentDateString,
    timeHeaders: [
      { groupBy: "Day" as any, format: "dddd - M/d/yyyy" },
      { groupBy: "Cell" as any },
    ],
    treeEnabled: true,
    treePreventParentUsage: true,
    useEventBoxes: "Never" as any,
    onRowFilter: (args: DayPilot.SchedulerRowFilterArgs) => {
      args.visible = !args.row.events.isEmpty();
    },
    onEventClicked: (args: DayPilot.SchedulerEventClickedArgs) => {
      calendarEventClicked(args.e.data);
    },
    onEventMoved: async (args: DayPilot.SchedulerEventMovedArgs) => {
      await calendarEventMoved({
        daypilot: args,
        getOriginalStiltEvent: getOriginalStiltEvent,
        updateTask: updateTask,
        updateStiltEvent: updateStiltEvent,
        addMessage: addMessage,
        resourcesMode: resourcesDropdownValue,
      });
    },
    onBeforeCellRender: (args: DayPilot.SchedulerBeforeCellRenderArgs) => {
      if (args.cell.isParent) {
        args.cell.properties.backColor = "#dddddd";
      }
    },
    eventRightClickHandling: "ContextMenu" as any,
    contextMenu: new DayPilot.Menu({
      items: [
        {
          text: "Open In New Tab",
          onClick: (menuItemClickArgs: DayPilot.MenuItemClickArgs) => {
            const task = menuItemClickArgs.source.data?.tags?.task;
            const craftRecordID = task?.craftRecordID.split("/").pop();
            if (craftRecordID) {
              // open in new tab
              window.open(
                `${WORK_RECORD_AND_TASKS_URL}/${craftRecordID}`,
                "_blank",
              );
            }
          },
        },
        {
          text: "Edit Date",
          onClick: (menuItemClickArgs: DayPilot.MenuItemClickArgs) => {
            const task = menuItemClickArgs.source.data?.tags?.task;
            if (task) {
              setSelectedTask(task);
              setTaskDetailsDialogOpen(true);
              setWithRescheduleTaskDialogOpen(true);
            }
          },
        },
      ],
    }),
    onBeforeEventRender: (args: DayPilot.SchedulerBeforeEventRenderArgs) => {
      if (args.data.backColor) {
        args.data.fontColor = getFontColorForBackground(args.data.backColor);
      }
      if (args.data.dotColor) {
        if (!args.data.areas) {
          args.data.areas = [];
        }
        args.data.areas.push({
          right: 2,
          top: "calc(100% - 10px)",
          width: 8,
          height: 8,
          symbol: "icons/daypilot.svg#checkmark-2",
          backColor: args.data.dotColor,
          fontColor: "#ffffff",
          padding: 2,
          style: "border-radius: 50%",
        });
      }
      if (
        args.data.tags?.task?.taskSpecificDetails?.completionOptions ===
          "followUpNeeded" ||
        args.data.tags?.task?.taskSpecificDetails?.completionOptions ===
          "partsNeeded" ||
        args.data.tags?.task?.taskSpecificDetails?.completionOptions ===
          "quoteNeeded"
      ) {
        if (!args.data.areas) {
          args.data.areas = [];
        }
        args.data.areas.push({
          right: 5,
          top: "calc(100% - 20px)",
          width: 20,
          height: 20,
          image: "/person-alert-icon.svg",
        });
      }
    },
    bubble: stiltTaskBubble(siteKeyDoc),
    onTimeRangeSelected: (
      daypilot: DayPilot.SchedulerTimeRangeSelectedArgs,
    ) => {
      handleTimeRangeSelected({
        daypilot,
        setEndDateNewCalendarEvent,
        setStartDateNewCalendarEvent,
        setResourceID,
        setEventDuration,
        setSelectCreationChoiceDialogOpen,
      });
    },
    onEventResized: async (args: DayPilot.SchedulerEventResizedArgs) => {
      await calendarEventResized({
        daypilot: args,
        getOriginalStiltEvent: getOriginalStiltEvent,
        updateTask: updateTask,
        updateStiltEvent: updateStiltEvent,
        addMessage: addMessage,
      });
    },
  };

  /** vertical */
  const dayPilotCalendar = (
    <DayPilotCalendar
      {...calendarConfig}
      columns={filteredResourcesForCalendar}
      events={calendarEvents}
      ref={calendarRef}
    />
  );

  /** horizontal */
  const dayPilotScheduler = (
    <DayPilotScheduler
      {...calendarConfigScheduler}
      resources={resourcesForScheduler}
      events={calendarEvents}
      ref={schedulerRef}
      controlRef={setSchedulerState}
    />
  );

  const tableOrientationBtn = (
    <StyledTooltip title="Change Table Orientation">
      <BaseButtonSecondary
        type="button"
        onClick={() =>
          setTableOrientationIsHorizontal(!tableOrientationIsHorizontal)
        }
        className="rounded-md p-2"
      >
        <PivotTableChart></PivotTableChart>
      </BaseButtonSecondary>
    </StyledTooltip>
  );

  const optimizeBtn = resourcesDropdownValue === "vehicles" &&
    siteKeyDoc?.customizations?.allowManualFleetOptimization && (
      <StyledTooltip title={strings.OPTIMIZE_TASKS}>
        <BaseButtonPrimary
          onClick={optimize}
          isBusy={isOptimizing}
          busyText={strings.buttons.BUSY_OPTIMIZING}
        >
          <StarsIcon />
        </BaseButtonPrimary>
      </StyledTooltip>
    );

  const taskDetailsContainer = selectedTask && (
    <TaskDetailsDialogContainer
      withRescheduleTaskDialogOpen={withRescheduleTaskDialogOpen}
      selectedTaskID={selectedTask.id}
      vehicleList={vehicleList}
      assets={taskAssets}
      onAssetClick={() => {}}
      setTaskDetailsDialogOpen={(open: boolean) => {
        if (!open) {
          setSelectedTask(null);
          setWithRescheduleTaskDialogOpen(false);
        }
        setTaskDetailsDialogOpen(open);
      }}
      permissionsMap={permissionsMap}
      taskDetailsDialogOpen={taskDetailsDialogOpen}
    />
  );

  // optimizeResultsSection displayes a note to the user that the tasks are temporarily optimized
  // It will also include a button to "Save" the optimized tasks, which will call handleUpdateTasksWithOptimizations
  const optimizeResultsSection = isViewingTemporarilyOptimizedTasks && (
    <div className="flex w-full flex-col space-y-2 bg-yellow-50 px-4 py-2">
      <div className="flex flex-row">
        {(() => {
          console.log(
            "originalTotalDistanceMiles",
            optimizedTasks.stats?.originalTotalDistanceMiles,
          );
          const originalTotalDistanceMiles =
            optimizedTasks.stats?.originalTotalDistanceMiles ?? 0;
          const optimizedDistanceMeters =
            optimizedTasks.stats?.optimizedTotalDistanceMeters ?? 0;
          const optimizedDistanceMiles = optimizedDistanceMeters / 1609.34;
          const distanceSaved =
            Number(originalTotalDistanceMiles) - optimizedDistanceMiles;
          const percentSaved =
            (distanceSaved / Number(originalTotalDistanceMiles)) * 100;

          return (
            <div className="flex w-full items-center justify-between">
              <div className="font-bold text-green-600">
                Total Miles Driven reduced by {distanceSaved.toFixed(1)} mi (
                {percentSaved.toFixed(1)}%)
              </div>
            </div>
          );
        })()}
      </div>
      <hr className="border-gray-200" />

      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <InformationCircleIcon className="h-9 w-9 text-blue-500" />
          <span className="text-blue-500">{strings.OPTIMIZED_TASKS_NOTE}</span>
        </div>
        <div className="flex gap-2 pl-8 pr-2">
          <BaseButtonSecondary
            className="uppercase"
            onClick={() => setIsViewingTemporarilyOptimizedTasks(false)}
          >
            {strings.buttons.CANCEL}
          </BaseButtonSecondary>
          <BaseButtonPrimary
            className="uppercase"
            onClick={handleUpdateTasksWithOptimizations}
            isBusy={isSavingOptimizedTasks}
            busyText={strings.buttons.BUSY_SAVING}
          >
            {strings.SAVE}
          </BaseButtonPrimary>
        </div>
      </div>

      <div className="text-sm">
        {scheduledAwaitingTaskList.filter(
          (task) => !task.taskSpecificDetails?.assignedVehicleID,
        ).length > 0 && (
          <div className="mt-1">
            <div className="font-semibold text-amber-800">
              <ExclamationTriangleIcon className="mr-1 inline-block h-5 w-5 text-amber-500" />
              {
                scheduledAwaitingTaskList.filter(
                  (task) => !task.taskSpecificDetails?.assignedVehicleID,
                ).length
              }{" "}
              unassigned task(s):
            </div>
            {scheduledAwaitingTaskList
              .filter((task) => !task.taskSpecificDetails?.assignedVehicleID)
              .map((task, index) => {
                const unassignedReason = optimizedTasks.unassigned.find(
                  (u) => u.description === task.id,
                )?.reason;
                return (
                  <div key={index} className="text-red-600">
                    {task.title} -{" "}
                    {unassignedReason || "Could not be assigned to any vehicle"}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <Fragment>
      <SchedulingPage>
        {{
          SearchBox: searchBox,
          OptimizeResultsSection: optimizeResultsSection,
          TotalDistancePill: totalDistancePill,
          Totals: totalsMetrics,
          DayPilotCalendar: tableOrientationIsHorizontal
            ? dayPilotScheduler
            : dayPilotCalendar,
          SelectColumn: resourcesColumnDropdown,
          LocationsColumn: locationsColumnDropdown,
          OptimizeBtn: optimizeBtn,
          ZonesBtn: configureZonesBtn,
          HideResourcesBtn: hideResourcesNotInUseBtn,
          NewEventBtn: addNewEventBtn,
          CreateTaskBtn: createTaskBtn,
          DatePicker: datePicker,
          TableOrientationBtn: tableOrientationBtn,
        }}
      </SchedulingPage>
      {addStiltCalendarEventDialog}
      {editStiltCalendarEventDialog}
      {createTaskDialog}
      {selectCreationChoiceDialog}
      {assignedToDialog}
      {taskDetailsContainer}
    </Fragment>
  );
}

// SECTION: HELPERS

type UpdateStiltEventType = {
  start: Timestamp;
  end: Timestamp;
  assignedTo?: string[];
  assignedVehicleIDs?: string[];
};

type Ref = HTMLButtonElement;

export const SchedulingButton = forwardRef<
  Ref,
  React.ComponentPropsWithRef<"button">
>(({ value, onClick }, ref) => (
  <button
    className={`inline-flex items-center justify-center rounded-md border border-gray-400 bg-white px-3 py-2 transition-colors hover:border-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2`}
    onClick={onClick}
    ref={ref}
    id="dateAndTime"
    data-testid="dateTimeButton"
  >
    <CalendarMonthIcon className="mr-2 text-gray-600" />
    {value}
  </button>
));

function getFormattedSiteLocations(
  siteKeyLocationList: ExistingSiteKeyLocation[],
) {
  const columnOptions: ColumnOption[] = siteKeyLocationList.map(
    (siteLocation) => {
      return {
        text: siteLocation.title,
        value: siteLocation.id,
      };
    },
  );
  return columnOptions;
}

function getSiteKeyLocationSelected(
  siteKeyLocationList: ExistingSiteKeyLocation[],
  locationsDropdownValue: ColumnOption["value"][],
): ExistingSiteKeyLocation[] {
  if (locationsDropdownValue.length === 0 || siteKeyLocationList.length === 1) {
    return siteKeyLocationList;
  } else {
    const result: ExistingSiteKeyLocation[] = [];
    locationsDropdownValue.forEach((selectedLocation) => {
      const found = siteKeyLocationList.find(
        (siteLocation) => siteLocation.id === selectedLocation,
      );

      if (found) {
        result.push(found);
      }
    });
    return result;
  }
}

export function stiltTaskBubble(siteKey: ExistingSiteKey) {
  return new Bubble({
    animated: true,
    showAfter: 2,
    theme: "bubble_default",
    position: "Mouse",
    onLoad: async (args) => {
      args.async = true;
      const e = args.source;

      let htmlString = "";
      if (e.data.tags.craftRecordRefPath) {
        const craftRecordData = await DbRead.parentRecords.getByRefPath(
          e.data.tags.craftRecordRefPath,
        );
        htmlString +=
          "<b>Job Title: </b>" +
          (craftRecordData?.title ?? "--") +
          "<br/>" +
          "<b>Job Description: </b>" +
          (craftRecordData?.description ?? "--") +
          "<br/>" +
          "<b>Task Description: </b>" +
          (e.data.tags.task?.description ?? "--") +
          "<br/><br/>";
        if (e.data.tags.task.id) {
          const estimates = await DbRead.estimates.getByTaskId(
            e.data.tags.craftRecordRefPath.split("/")[1],
            e.data.tags.task.id,
          );
          estimates.sort(
            (a, b) =>
              a.timestampCreated.toMillis() - b.timestampCreated.toMillis(),
          );
          let estimateCount = 1;
          for (const estimate of estimates) {
            const estimateItems = await DbRead.estimateItems.getByEstimateId(
              e.data.tags.craftRecordRefPath.split("/")[1],
              estimate.id,
            );
            let estimateItemsString = "";
            estimateItems.forEach(
              (e) => (estimateItemsString += e.title + "<br/>"),
            );
            if (estimateCount === 1) {
              htmlString += "<b>Original Estimate";
            } else {
              htmlString += "<b>Estimate " + estimateCount;
            }
            htmlString +=
              ": " +
              currencyFormatter(
                estimate.customData.totalAmount ?? 0,
                siteKey.customizations?.accounting?.currency ?? "usd",
              ) +
              "</b>" +
              "<br/>" +
              "<b>Line Items: </b>" +
              "<br/>" +
              estimateItemsString +
              "<br/>";
            estimateCount += 1;
          }
        }
        if (craftRecordData?.customerID) {
          const customerData = await DbRead.customers.get(
            e.data.tags.craftRecordRefPath.split("/")[1],
            craftRecordData?.customerID,
          );

          htmlString +=
            "<b>Customer Name: </b>" +
            (customerData?.name ?? "--") +
            "<br/>" +
            "<b>Customer Phone: </b>" +
            (customerData?.phone?.join(", ") ?? "--") +
            "<br/>";
        }
      }

      args.html = htmlString;
      args.loaded();
    },
  });
}

function resetAssignedUserList(assignedUserList: IMultipleUid_AssignUser[]) {
  assignedUserList.forEach((user) => {
    user.isAssigned = false;
    user.assignedVehicle = null;
  });
}

function filterCalendarResourcesByLocationDropdownValues(
  resourcesForCalendar: CalendarColumn[],
  filteredSiteKeyLocations: ExistingSiteKeyLocation[],
  locationsDropdownValue: string[],
): CalendarColumn[] {
  if (locationsDropdownValue.length === 0) return resourcesForCalendar;

  const filteredColumn: CalendarColumn[] = [];
  const locationIDs = filteredSiteKeyLocations.map((s) => s.id);

  resourcesForCalendar.forEach((resource) => {
    if (resource.name === "Unassigned") {
      filteredColumn.push(resource);
    }

    if (locationIDs.includes(resource.locationID)) {
      filteredColumn.push(resource);
    }
  });

  return filteredColumn;
}

export function customizeHeader(
  args: DayPilot.CalendarBeforeHeaderRenderArgs,
  calendarEvents: CalendarEvent[],
  resourcesDropdownValue: string | null,
  selectedDate: DateTime,
) {
  args.header.areas = [];

  let portable: boolean = false;
  let dotColorList: string[] = [];
  const dotColorElements: DayPilot.AreaData[] = [];

  if (args.column.data.id != null) {
    if (resourcesDropdownValue === "vehicles") {
      const filteredEvents = calendarEvents.filter((event) => {
        // Return true if event.resrouce and args.column.data.id are the same and also if event.start is greater or equal to the selected date
        return (
          event.resource === (args.column.data.id as string) &&
          DateTime.fromISO(event.start).toISODate() === selectedDate.toISODate()
        );
      });

      portable = filteredEvents.some((event) => {
        return event.portable === true;
      });

      filteredEvents.forEach((event) => {
        if (event.dotColor && event.dotColor !== "#eeeeee") {
          dotColorList = [...new Set([...dotColorList, event.dotColor])];
        }
      });
    } else if (resourcesDropdownValue === "technicians") {
      const filteredEvents = calendarEvents.filter(
        (event) => event.resource === (args.column.data.id as string),
      );

      portable = filteredEvents.some((event) => {
        return event.portable === true;
      });

      filteredEvents.forEach((event) => {
        if (event.dotColor && event.dotColor !== "#eeeeee") {
          dotColorList = [...new Set([...dotColorList, event.dotColor])];
        }
      });
    }

    if (portable) {
      args.header.areas.push({
        left: "calc(50% - 30px)",
        top: 3,
        width: 50,
        fontColor: `var(--primary)`,
        text: "Portable",
        symbol: "",
        style:
          "font-size:12px;border-radius:9999px;background-color:rgb(34 211 238 / 1);padding-left:0.5rem;padding-right:0.5rem;",
      });
    }

    if (dotColorList.length > 0) {
      dotColorList.forEach((dotColor, idx) => {
        dotColorElements.push({
          right: 5 + 10 * idx,
          bottom: 3,
          width: 8,
          height: 8,
          background: dotColor,
          style: "border-radius:9999px;",
        });
      });
      args.header.areas.push(...dotColorElements);
    }
  }
}

function getScheduledSaleForTask(estimates: ExistingEstimate[]): number {
  if (estimates.length === 0) return 0;

  if (estimates.length === 1) {
    return typeof estimates[0].customData.totalAmount === "number"
      ? estimates[0].customData.totalAmount
      : 0;
  }

  const lockedEstimates = estimates.filter((e) => e.status === "locked");

  if (lockedEstimates.length === 1) {
    return typeof lockedEstimates[0].customData.totalAmount === "number"
      ? lockedEstimates[0].customData.totalAmount
      : 0;
  } else {
    // const xEstimates = cloneDeep(estimates);
    // i don't think we actually care if we mutate the estimates list...
    const sortedEstimates = estimates.sort((a, b) =>
      a.timestampCreated < b.timestampCreated ? 1 : -1,
    );

    return typeof sortedEstimates[0].customData.totalAmount === "number"
      ? sortedEstimates[0].customData.totalAmount
      : 0;
  }
}

function handleTimeRangeSelected(args: {
  daypilot:
    | DayPilot.SchedulerTimeRangeSelectedArgs
    | DayPilot.CalendarTimeRangeSelectedArgs;
  setSelectCreationChoiceDialogOpen: (val: boolean) => void;
  setEventDuration: (val: number) => void;
  setResourceID: (val: string) => void;
  setStartDateNewCalendarEvent: (date: DateTime) => void;
  setEndDateNewCalendarEvent: (date: DateTime) => void;
}): void {
  args.setSelectCreationChoiceDialogOpen(true);

  const start = args.daypilot.start.toString();
  const end = args.daypilot.end.toString();
  const timeDiff = getTimeDifferenceInHours({
    startDate: start,
    endDate: end,
  });

  args.setEventDuration(timeDiff);
  args.setResourceID(args.daypilot.resource.toString());
  args.setStartDateNewCalendarEvent(DateTime.fromISO(start));
  args.setEndDateNewCalendarEvent(DateTime.fromISO(end));
  args.daypilot.control.clearSelection();
}

async function calendarEventMoved(args: {
  daypilot: DayPilot.CalendarEventMovedArgs | DayPilot.SchedulerEventMovedArgs;
  resourcesMode: string | null;
  getOriginalStiltEvent: (
    stiltEventID: string,
    originalResourceID: string,
  ) => ExistingStiltCalendarEvent | null;
  updateTask: (args: {
    taskID: string;
    newStart: string;
    newEnd: string;
    newResourceID: string;
    originalResourceID: string;
  }) => Promise<string>;
  updateStiltEvent: (
    updateData: UpdateStiltEventType,
    stiltEventToUpdate: ExistingStiltCalendarEvent,
  ) => Promise<void>;
  addMessage: (message: ToastType) => void;
}): Promise<void> {
  const newStart = args.daypilot.newStart.toStringSortable();
  const newEnd = args.daypilot.newEnd.toStringSortable();
  const newResourceID: string = args.daypilot.newResource.toString();
  const originalResourceID: string = args.daypilot.e.data.tags.resourceID;

  let stiltEventID: string | null = null;
  let taskID: string | null = null;
  if (args.daypilot.e.data.tags.taskID) {
    taskID = args.daypilot.e.data.tags.taskID;
  } else {
    stiltEventID = args.daypilot.e.data.tags.stiltEventID;
  }

  if (taskID) {
    try {
      const taskTitle = await args.updateTask({
        taskID,
        newStart,
        newEnd,
        newResourceID,
        originalResourceID,
      });
      args.addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(`Task: ${taskTitle}`),
        dialog: false,
        type: "success",
      });
    } catch (e) {
      if (e instanceof Error) {
        devLogger.warn(e);
        devLogger.error(
          `calendarEventMoved (Task): ${taskID}, ${e.name}: ${e.message}`,
        );
      } else {
        devLogger.error(`calendarEventMoved (Task): ${taskID}`, e);
      }

      args.addMessage({
        id: createToastMessageID(),
        message: strings.failedUpdate(
          `task: ${args.daypilot.e.data.text ?? "[unknown task title]"}`,
        ),
        dialog: false,
        type: "error",
      });
    }
  } else if (stiltEventID) {
    const originalStiltEvent = args.getOriginalStiltEvent(
      stiltEventID,
      originalResourceID,
    );

    if (!originalStiltEvent) return;

    // SETUP TIMESTAMPS
    let startTimestamp: Timestamp;
    let endTimestamp: Timestamp;
    try {
      startTimestamp = convertISOToFSTimestamp(newStart);
    } catch (e) {
      startTimestamp = Timestamp.now();
    }
    try {
      endTimestamp = convertISOToFSTimestamp(newEnd);
      // handle the case of endTimestamp being earlier than startTimestamp
      if (startTimestamp.toMillis() > endTimestamp.toMillis()) {
        endTimestamp = Timestamp.fromMillis(
          startTimestamp.toMillis() + 3600000, // 1 hour later
        );
      }
    } catch (e) {
      endTimestamp = Timestamp.fromMillis(
        startTimestamp.toMillis() + 3600000, // 1 hour later
      );
    }

    const updateData: UpdateStiltEventType = {
      start: startTimestamp,
      end: endTimestamp,
    };

    // SETUP RESOURCE IDS
    let resourceIDs: string[];
    try {
      resourceIDs = getResourceIDsFromCalendarEventMoved({
        daypilot: args.daypilot,
        originalStiltEvent,
        resourcesMode: args.resourcesMode,
      });
    } catch (e) {
      args.addMessage({
        id: createToastMessageID(),
        message: strings.failedUpdate(
          `calendar event: ${originalStiltEvent.title}`,
        ),
        dialog: false,
        type: "error",
      });
      return;
    }
    if (args.resourcesMode === "technicians") {
      updateData.assignedTo = resourceIDs;
    }
    if (args.resourcesMode === "vehicles") {
      updateData.assignedVehicleIDs = resourceIDs;
    }

    try {
      await args.updateStiltEvent(updateData, originalStiltEvent);
      args.addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(
          `Calendar event: ${originalStiltEvent.title}`,
        ),
        dialog: false,
        type: "success",
      });
    } catch (e) {
      if (e instanceof Error) {
        devLogger.warn(e);
        devLogger.error(
          `calendarEventMoved: failed to update event ${stiltEventID}, ${e.name}: ${e.message}`,
        );
      } else {
        devLogger.error(
          `calendarEventMoved: failed to update event ${stiltEventID}`,
          e,
        );
      }
      args.addMessage({
        id: createToastMessageID(),
        message: strings.failedUpdate(
          `calendar event: ${originalStiltEvent.title}`,
        ),
        dialog: false,
        type: "error",
      });
    }
  } else {
    devLogger.error(
      "calendarEventMoved: expected a string for stiltEventID or for taskID",
    );
    args.addMessage({
      id: createToastMessageID(),
      message: strings.UNEXPECTED_ERROR,
      dialog: false,
      type: "error",
    });
  }
}

/**
 * A calendar event can be assigned to more than one resource. To avoid losing
 * resources associated with an event, we need to make use of the resources mode
 * and we need to do some comparisons between the OG Stilt event and the data
 * that daypilot gives us.
 *
 * @throws if resourcesMode isn't "technicians" or "vehicles"
 * @throws if mode is "vehicles" and original assignedVehicleIDs is undefined or zero-length
 * @throws if mode is "technicians" and original assignedTo is zero-length
 */
function getResourceIDsFromCalendarEventMoved(args: {
  daypilot: DayPilot.CalendarEventMovedArgs | DayPilot.SchedulerEventMovedArgs;
  resourcesMode: string | null;
  originalStiltEvent: StiltCalendarEvent;
}): string[] {
  const afterMovedResourceID = args.daypilot.newResource.toString();
  const beforeMovedResourceID = args.daypilot.e.data.tags.resourceID;

  let originalResourceIDs: string[];
  // Determine validity / value of originalResourceIDs --
  if (args.resourcesMode === "technicians") {
    originalResourceIDs = args.originalStiltEvent.assignedTo;
    if (originalResourceIDs.length === 0) {
      throw Error("No technician resourceIDs found");
    }
  } else if (args.resourcesMode === "vehicles") {
    const xoriginalResIDs = args.originalStiltEvent.assignedVehicleIDs;
    if (!xoriginalResIDs || xoriginalResIDs.length === 0) {
      throw Error("No vehicle resourceIDs found");
    }
    originalResourceIDs = xoriginalResIDs;
  } else {
    throw Error(`Unexpected resourcesMode: ${args.resourcesMode}`);
  }

  // Comparisons --
  if (originalResourceIDs.length === 1) return [afterMovedResourceID];
  if (afterMovedResourceID == beforeMovedResourceID) return originalResourceIDs;

  const updatedIDs = originalResourceIDs.filter(
    (id) => id !== beforeMovedResourceID,
  );
  updatedIDs.push(afterMovedResourceID);

  return [...new Set(updatedIDs)];
}

async function calendarEventResized(args: {
  daypilot:
    | DayPilot.CalendarEventResizedArgs
    | DayPilot.SchedulerEventResizedArgs;
  getOriginalStiltEvent: (
    stiltEventID: string,
    originalResourceID: string,
  ) => ExistingStiltCalendarEvent | null;
  updateTask: (args: {
    taskID: string;
    newStart: string;
    newEnd: string;
  }) => Promise<string>;
  updateStiltEvent: (
    updateData: UpdateStiltEventType,
    stiltEventToUpdate: ExistingStiltCalendarEvent,
  ) => Promise<void>;
  addMessage: (message: ToastType) => void;
}): Promise<void> {
  const newStart = args.daypilot.newStart.toString();
  const newEnd = args.daypilot.newEnd.toString();

  const taskID =
    typeof args.daypilot.e.data.tags.taskID === "string"
      ? args.daypilot.e.data.tags.taskID
      : null;
  const stiltEventID =
    typeof args.daypilot.e.data.tags.stiltEventID === "string"
      ? args.daypilot.e.data.tags.stiltEventID
      : null;

  if (taskID) {
    try {
      const taskTitle = await args.updateTask({ taskID, newStart, newEnd });
      args.addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(`Task: ${taskTitle}`),
        dialog: false,
        type: "success",
      });
    } catch (e) {
      if (e instanceof Error) {
        devLogger.warn(e);
        devLogger.error(
          `calendarEventResized (Task): ${taskID}, ${e.name}: ${e.message}`,
        );
      } else {
        devLogger.error(`calendarEventResized (Task): ${taskID}`, e);
      }

      args.addMessage({
        id: createToastMessageID(),
        message: strings.failedUpdate(
          `task: ${args.daypilot.e.data.text ?? "[unknown task title]"}`,
        ),
        dialog: false,
        type: "error",
      });
    }
  } else if (stiltEventID) {
    const originalStiltEvent = args.getOriginalStiltEvent(
      stiltEventID,
      args.daypilot.e.data.tags.resourceID,
    );

    if (!originalStiltEvent) return;

    // SETUP TIMESTAMPS
    let startTimestamp: Timestamp;
    let endTimestamp: Timestamp;
    try {
      startTimestamp = convertISOToFSTimestamp(newStart);
    } catch (e) {
      startTimestamp = Timestamp.now();
    }
    try {
      endTimestamp = convertISOToFSTimestamp(newEnd);
      // handle the case of endTimestamp being earlier than startTimestamp
      if (startTimestamp.toMillis() > endTimestamp.toMillis()) {
        endTimestamp = Timestamp.fromMillis(
          startTimestamp.toMillis() + 3600000, // 1 hour later
        );
      }
    } catch (e) {
      endTimestamp = Timestamp.fromMillis(
        startTimestamp.toMillis() + 3600000, // 1 hour later
      );
    }

    const updateData: UpdateStiltEventType = {
      start: startTimestamp,
      end: endTimestamp,
    };

    try {
      await args.updateStiltEvent(updateData, originalStiltEvent);
      args.addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(
          `Calendar event: ${originalStiltEvent.title}`,
        ),
        dialog: false,
        type: "success",
      });
    } catch (e) {
      if (e instanceof Error) {
        devLogger.warn(e);
        devLogger.error(
          `calendarEventResized: failed to update event ${stiltEventID}, ${e.name}: ${e.message}`,
        );
      } else {
        devLogger.error(
          `calendarEventResized: failed to update event ${stiltEventID}`,
          e,
        );
      }
      args.addMessage({
        id: createToastMessageID(),
        message: strings.failedUpdate(
          `calendar event: ${originalStiltEvent.title}`,
        ),
        dialog: false,
        type: "error",
      });
    }
  } else {
    devLogger.error(
      "calendarEventResized: expected a string for stiltEventID or for taskID",
    );
    args.addMessage({
      id: createToastMessageID(),
      message: strings.UNEXPECTED_ERROR,
      dialog: false,
      type: "error",
    });
  }
}

export function getImproperlyGeocodedTasks(
  tasks: ExistingTask[],
  siteKeyLocationList: ExistingSiteKeyLocation[],
): number {
  let improperlyGeocodedTasks = 0;
  tasks.forEach((task) => {
    if (!task.latitude && !task.longitude) {
      improperlyGeocodedTasks++;
    }

    const siteKeyLocation = siteKeyLocationList.find(
      (location) => location.id === task.locationID,
    );
    if (
      task.latitude === siteKeyLocation?.latitude &&
      task.longitude === siteKeyLocation?.longitude
    ) {
      improperlyGeocodedTasks++;
    }
  });
  return improperlyGeocodedTasks;
}
