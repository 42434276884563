// Libs
import { DocumentData, Timestamp } from "firebase/firestore";
import { diffObjects } from "../../../assets/js/object-diff";

// Local
import { ExistingCustomField } from "../../../models/custom-field";
import { Json } from "../../../models/json-type";
import {
  CraftRecordPersistenceTypes,
  ExistingTask,
} from "../../../models/task";
import { TaskStatus } from "../../../models/task-status";
import * as strings from "../../../strings";

interface Args {
  // DATA
  task: ExistingTask;
  originalTask: ExistingTask;
  customFields: ExistingCustomField[];
  showCraftPersistence: boolean;
  /** Won't be null if there are TSD that contain onTaskStatus[nextTaskStatus] */
  nextTaskStatus: TaskStatus | null;
  uid: string;

  // FUNCTIONS
  handleUpdateTask: (updateData: DocumentData, taskID: string) => Promise<void>;
  handleUpdateTSD: (updateData: DocumentData, refPath: string) => Promise<void>;
}

/**
 * Failure case: returns a string containing reason for failure
 * Failure due to TSD update rejection: returns object containing the error
 * Success case: returns `0`
 */
export async function handleSubmitTaskStatusChange(
  args: Args,
): Promise<string | 0 | { error: unknown }> {
  if (
    validatePersistenceSelection({
      showCraftPersistence: args.showCraftPersistence,
      currentPersistenceValue: args.task.craftRecordPersistence,
    }) === false
  ) {
    return strings.SELECT_WORK_REC_PERSISTENCE;
  }

  if (args.nextTaskStatus) {
    const missingRequired = checkMissingRequiredFields(
      args.task,
      args.nextTaskStatus,
      args.customFields,
    );
    if (missingRequired) {
      return `Missing required field${
        missingRequired.length > 1 ? "s" : ""
      }: ${missingRequired.join(", ")}`;
    }

    const missingBarrierComments = checkBarrierCommentsRequired({
      responseValues: args.task.taskSpecificDetails,
      originalValues: args.customFields,
    });
    if (missingBarrierComments) {
      return strings.BARRIER_COMMENTS_REQUIRED_WHEN_BARRIER_OTHER_VALUE_PRESENT;
    }

    // Special process for number-based fields.
    args.customFields.forEach((field) => {
      if (field.fieldType === "number" || field.fieldType === "currency") {
        // If not required & field is empty, reset it
        if (
          !field.required &&
          [undefined, "", null].includes(
            // @ts-ignore     TODO: is getting ts-ignore out feasible?
            args.task.taskSpecificDetails[field.id],
          )
        ) {
          args.task.taskSpecificDetails[field.id] = 0;
        }

        if (field.editable) {
          // Convert strings to numbers
          const currentResponse = args.task.taskSpecificDetails[field.id];
          if (typeof currentResponse === "string") {
            if (field.fieldType === "number") {
              args.task.taskSpecificDetails[field.id] = parseInt(
                currentResponse,
                10,
              );
            }
            if (field.fieldType === "currency") {
              args.task.taskSpecificDetails[field.id] =
                parseFloat(currentResponse);
            }
          }

          // Convert NaN to null
          const current = args.task.taskSpecificDetails[field.id];
          if (typeof current === "number" && isNaN(current)) {
            args.task.taskSpecificDetails[field.id] = null;
          }
        }
      }
    });
  }

  // Prep task & tsd for DB write / ƒn invocation.
  const xtask: ExistingTask = {
    ...args.task,
    timestampLastModified: Timestamp.now(),
    lastModifiedBy: args.uid,
  };

  if (args.nextTaskStatus) {
    xtask.taskStatus = args.nextTaskStatus;
  }

  const previousTaskStatus = args.originalTask.taskStatus;

  if (
    args.nextTaskStatus === TaskStatus.IN_PROGRESS &&
    previousTaskStatus !== TaskStatus.ON_HOLD
  ) {
    xtask.timestampTaskStarted = Timestamp.now();
  }

  if (
    args.nextTaskStatus === TaskStatus.COMPLETE ||
    (previousTaskStatus === TaskStatus.IN_PROGRESS &&
      args.nextTaskStatus === TaskStatus.AWAITING_REVIEW &&
      xtask.timestampTaskCompleted === null)
  ) {
    xtask.timestampTaskCompleted = Timestamp.now();
  }

  if (
    args.nextTaskStatus === TaskStatus.AWAITING &&
    previousTaskStatus !== TaskStatus.AWAITING
  ) {
    xtask.timestampAwaitingStart = Timestamp.now();
  }

  const { taskSpecificDetails, cancellationData, ...rest } = xtask;

  const taskUpdate = diffObjects(args.originalTask, rest).diff;

  const tsdUpdate = diffObjects(
    args.originalTask.taskSpecificDetails,
    taskSpecificDetails,
  ).diff;

  if (args.nextTaskStatus === TaskStatus.CANCELED) {
    taskUpdate.cancellationData = cancellationData;
  }

  // NOTE: try/catch is in the dialog component. not necessary here
  // Always update the task doc. At the very least, timestampLastModified and
  // lastModifiedBy have changed
  await args.handleUpdateTask(taskUpdate, args.originalTask.id);

  // Using try/catch so we can control what the failure message says, if it comes to that.
  if (Object.keys(tsdUpdate).length > 0) {
    try {
      await args.handleUpdateTSD(tsdUpdate, args.originalTask.refPath);
    } catch (e) {
      return { error: e };
    }
  }

  // return 0 in the case of success
  return 0;
}

// SECTION: Helper functions
/** Returns false if user needs to select a CraftRecordPersistenceType */
function validatePersistenceSelection(args: {
  showCraftPersistence: boolean;
  currentPersistenceValue: CraftRecordPersistenceTypes;
}): boolean {
  if (args.showCraftPersistence) {
    if (
      args.currentPersistenceValue === CraftRecordPersistenceTypes["PROMPT"]
    ) {
      return false;
    }
  }
  return true;
}

/**
 * Returns false if all required fields have been filled out. Otherwise, returns
 * titles of required fields that still need to be answered.
 *
 * Tested ✅
 */
export function checkMissingRequiredFields(
  task: ExistingTask,
  nextTaskStatus: TaskStatus, // null is no longer possible; narrowed before calling this ƒn
  customFields: ExistingCustomField[],
): string[] | false {
  const titles: string[] = [];

  for (let i = 0; i < customFields.length; i++) {
    const field = customFields[i];

    if (
      field.required &&
      field.editable &&
      // @ts-ignore     TODO: is getting ts-ignore out feasible?
      [undefined, "", null].includes(task.taskSpecificDetails[field.id]) &&
      field.onTaskStatus?.includes(nextTaskStatus)
    ) {
      titles.push(field.title);
    }
  }

  return titles.length > 0 ? titles : false;
}

/**
 * Returns true if user needs to fill out barrierComments field. Otherwise,
 * returns false
 *
 * Tested ✅
 */
export function checkBarrierCommentsRequired(args: {
  /** taskSpecificDetails */
  responseValues: Record<string, Json>;
  /** siteKey's custom fields */
  originalValues: ExistingCustomField[];
}): boolean {
  const barrierOtherString = "barrierOther";
  const barrierCommentsString = "barrierComments";

  if (!(barrierOtherString in args.responseValues)) {
    return false;
  }

  const barrierConfigs = args.originalValues.filter(
    (cf) => cf.id === barrierCommentsString || cf.id === barrierOtherString,
  );
  if (barrierConfigs.length !== 2) {
    return false;
  }

  const barrierCommentsCF = args.originalValues.find(
    (cf) => cf.id === barrierCommentsString,
  );
  const barrierOtherCF = args.originalValues.find(
    (cf) => cf.id === barrierOtherString,
  );

  // barrierOther is a string
  const barrierOtherResponseDifferentThanDV_String =
    typeof args.responseValues["barrierOther"] === "string" &&
    parseInt(args.responseValues["barrierOther"], 10) !==
      barrierOtherCF?.defaultValue;
  // barrierOther is a number
  const barrierOtherResponseDifferentThanDV_Number =
    typeof args.responseValues["barrierOther"] === "number" &&
    args.responseValues["barrierOther"] !== barrierOtherCF?.defaultValue;

  if (
    // if current barrierOther response value is different than siteKey
    // custom field's barrierOther defaultValue...
    (barrierOtherResponseDifferentThanDV_String ||
      barrierOtherResponseDifferentThanDV_Number) &&
    // and there's no barrierComments response value, or it hasn't been
    // changed from the default
    (!(barrierCommentsString in args.responseValues) ||
      args.responseValues["barrierComments"] ===
        barrierCommentsCF?.defaultValue)
  ) {
    return true;
  }

  return false;
}
