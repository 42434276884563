import { XMarkIcon } from "@heroicons/react/24/solid";
import { z } from "zod";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import { logger as devLogger } from "../../logging";
import { ErrorMessage } from "../ErrorMessage";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import StyledMessage from "../StyledMessage";
import BaseInputText from "../BaseInputText";
import BaseInputTextArea from "../BaseInputTextArea";
import BaseInputCheckbox from "../BaseInputCheckbox";
import ButtonColored from "../ButtonColored";
import {
  ExistingCancellationSource,
  CancellationSource_CreateAPI,
} from "../../models/cancellation-manager";

const formSchema = z.object({
  title: z.string().min(1).max(200),
  description: z.string().max(500).nullable(),
  active: z.boolean(),
});

type FormState = z.infer<typeof formSchema>;

interface Props {
  siteKey: string;
  isDialogOpen: boolean;
  closeDialog: () => void;
  selectedItem: ExistingCancellationSource | null;
  onCreate: (data: CancellationSource_CreateAPI) => Promise<void>;
  onUpdate: (data: ExistingCancellationSource) => Promise<void>;
  onDelete: (id: string) => Promise<void>;
}

export default function AddEditCancellationSourceDialog({
  siteKey,
  isDialogOpen,
  closeDialog,
  selectedItem,
  onCreate,
  onUpdate,
  onDelete,
}: Props) {
  const [displayError, setDisplayError] = useState<boolean>(false);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<FormState>({
    defaultValues: {
      title: selectedItem?.title ?? "",
      description: selectedItem?.description ?? "",
      active: selectedItem?.active ?? true,
    },
    resolver: zodResolver(formSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset({
      title: selectedItem?.title ?? "",
      description: selectedItem?.description ?? "",
      active: selectedItem?.active ?? true,
    });
  }, [selectedItem, reset]);

  const onSubmit: SubmitHandler<FormState> = async (formValues) => {
    try {
      if (selectedItem) {
        await onUpdate({
          ...selectedItem,
          ...formValues,
        });
      } else {
        await onCreate({
          title: formValues.title,
          description: formValues.description,
          siteKey,
        });
      }
      closeAndReset();
    } catch (error) {
      setDisplayError(true);
      devLogger.error(error);
    }
  };

  function closeAndReset() {
    reset();
    setDisplayError(false);
    closeDialog();
  }

  return (
    <BaseModal
      closeModal={closeAndReset}
      open={isDialogOpen}
      title={
        <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white">
          <h1 className="inline-flex items-center text-xl font-semibold">
            {selectedItem ? "Edit Source" : "Add New Source"}
          </h1>
          <button type="button" onClick={closeAndReset}>
            <XMarkIcon className="h-6 text-white" />
          </button>
        </div>
      }
      parentDivStyles="inline-block transform overflow-hidden max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="relative flex flex-col space-y-8 p-8 text-lg">
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
          <div>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  admin={true}
                  text="Title"
                  inputName="title"
                  required={true}
                  {...field}
                />
              )}
            />
            {errors.title?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.title.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          <div>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <BaseInputTextArea
                  admin={true}
                  id="description"
                  text="Description"
                  rows={3}
                  {...field}
                  value={field.value === null ? undefined : field.value}
                  onChange={(event) =>
                    field.onChange(event.target.value || null)
                  }
                />
              )}
            />
          </div>

          <div>
            <Controller
              name="active"
              control={control}
              render={({ field }) => (
                <BaseInputCheckbox
                  label="Active"
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )}
            />
          </div>

          <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
            {selectedItem && (
              <ButtonColored
                className="w-full justify-center uppercase"
                kind="danger"
                type="button"
                onClick={() => onDelete(selectedItem.id)}
              >
                {strings.buttons.DELETE}
              </ButtonColored>
            )}
            <BaseButtonSecondary
              type="button"
              className="w-full justify-center uppercase"
              onClick={closeAndReset}
            >
              {strings.buttons.CANCEL}
            </BaseButtonSecondary>

            <BaseButtonPrimary
              type="submit"
              formNoValidate
              disabled={isSubmitting}
              isBusy={isSubmitting}
              busyText={strings.buttons.BUSY_SAVING}
              className="w-full justify-center uppercase"
            >
              {strings.buttons.SAVE}
            </BaseButtonPrimary>
          </div>
        </form>

        {displayError && (
          <span className="absolute bottom-10 left-1/2 w-3/4 -translate-x-1/2 sm:w-96">
            <ErrorMessage
              message={strings.UNEXPECTED_ERROR}
              clearMessage={() => setDisplayError(false)}
            />
          </span>
        )}
      </div>
    </BaseModal>
  );
}
