import { useState, useEffect, useRef } from "react";
import { User } from "firebase/auth";
import { Search, Add, Person2Sharp, Groups2, Work } from "@mui/icons-material";
import * as MdIcons from "react-icons/md";
import {
  UserCircleIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/solid";

import { ExistingChatRoom, ChatRoomType } from "../../models/chat_room";
import { ExistingSiteKeyUserDoc } from "../../models/site-key-users";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { ColumnOption } from "../../models/scheduling-types";
import { StyledTooltip } from "../StyledTooltip";
import { logger } from "../../logging";
import { ExistingChatMessageReadStatus } from "../../models/chat_message_read_status";
import { MessageType } from "../../models/chat_message";
import ChatRoomUserSelectionDialog from "../ChatRoomUserSelectionDialog";
import { useUserPermissionsStore } from "../../store/user-permissions";

interface ChatRoomsListProps {
  chatRooms: ExistingChatRoom[];
  firebaseUser: User | null;
  userDisplayNamesMap: Record<string, string>;
  siteKeyUsersList: ExistingSiteKeyUserDoc[];
  onSelectRoom: (chatRoomID: string) => void;
  onStartNewChat: (selectedUserIds: string[], groupName?: string) => void;
  unreadMessageStatuses: ExistingChatMessageReadStatus[];
}

export default function ChatRoomsList({
  chatRooms,
  firebaseUser,
  userDisplayNamesMap,
  siteKeyUsersList,
  onSelectRoom,
  onStartNewChat,
  unreadMessageStatuses,
}: ChatRoomsListProps) {
  const [searchQuery, setSearchQuery] = useState("");
  const [showUsersDropdown, setShowUsersDropdown] = useState<boolean>(false);
  const [userDropdownValues, setUserDropdownValues] = useState<string[]>([]);
  const [animatedRoomId, setAnimatedRoomId] = useState<string | null>(null);
  const prevChatRoomsRef = useRef<ExistingChatRoom[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  // Get user permissions to check if user is a site admin
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );

  // Sort and filter chat rooms
  const sortedAndFilteredChatRooms = chatRooms
    .filter((room) => {
      try {
        // If no search query, show all rooms
        if (!searchQuery.trim()) {
          return true;
        }

        // Make sure room has valid userIds array
        if (!Array.isArray(room.userIds)) {
          return false;
        }

        const searchTermLower = searchQuery.toLowerCase();

        // Search in room name
        const roomName = getRoomName(room.id).toLowerCase();
        if (roomName.includes(searchTermLower)) {
          return true;
        }

        // Search in other user's name
        const otherUserID = room.userIds.find((id) => id !== firebaseUser?.uid);
        const otherUserName = otherUserID
          ? userDisplayNamesMap[otherUserID]?.toLowerCase()
          : "";

        // Search in last message text
        const lastMessageText = getLastMessageText(room).toLowerCase();

        return (
          otherUserName?.includes(searchTermLower) ||
          lastMessageText.includes(searchTermLower)
        );
      } catch (error) {
        logger.warn("Error filtering chat room", error);
        return false;
      }
    })
    .sort((a, b) => {
      // Sort by most recently modified
      return (
        b.timestampLastModified.toMillis() - a.timestampLastModified.toMillis()
      );
    });

  // Check for new messages and trigger animation
  useEffect(() => {
    if (prevChatRoomsRef.current.length > 0 && chatRooms.length > 0) {
      // Find rooms with updated lastMessage
      chatRooms.forEach((room) => {
        const prevRoom = prevChatRoomsRef.current.find(
          (pr) => pr.id === room.id,
        );

        if (prevRoom && room.lastMessage && prevRoom.lastMessage) {
          // Check if the lastMessage timestamp has changed
          if (
            room.lastMessage.timestampCreated.toMillis() >
            prevRoom.lastMessage.timestampCreated.toMillis()
          ) {
            // Trigger animation for this room
            setAnimatedRoomId(room.id);

            // Clear animation after 2 seconds
            setTimeout(() => {
              setAnimatedRoomId(null);
            }, 2000);
          }
        }
      });
    }

    // Update the previous chat rooms reference
    prevChatRoomsRef.current = [...chatRooms];
  }, [chatRooms]);

  // Ensure the list remains scrolled to the top when it loads or updates
  useEffect(() => {
    // Function to reset scroll positions
    const resetScrollPosition = () => {
      // Reset this component's scroll position
      if (containerRef.current) {
        containerRef.current.scrollTop = 0;
      }

      // Also reset parent scrollable containers (if any)
      let parent = containerRef.current?.parentElement;
      while (parent) {
        const overflowY = window.getComputedStyle(parent).overflowY;
        if (overflowY === "auto" || overflowY === "scroll") {
          parent.scrollTop = 0;
        }
        parent = parent.parentElement;
      }
    };

    // Reset immediately
    resetScrollPosition();

    // Also reset after a small delay to ensure DOM has fully updated
    const timeoutId = setTimeout(() => {
      resetScrollPosition();
    }, 50);

    return () => clearTimeout(timeoutId);
  }, [chatRooms, searchQuery]);

  // Get unread count for a specific room
  function getUnreadCountForRoom(chatRoomID: string): number {
    try {
      const readStatus = unreadMessageStatuses.find(
        (status) => status.chatRoomID === chatRoomID,
      );
      return readStatus?.unreadCount || 0;
    } catch (error) {
      logger.warn("Error getting unread count for room", error);
      return 0;
    }
  }

  // get room name, if the room is just me and one other user than room name should be the displayName of the other user
  function getRoomName(roomID: string): string {
    try {
      if (!firebaseUser) return "";

      const room = chatRooms.find((room) => room.id === roomID);

      if (!room) return "";

      // Make sure room has valid userIds array
      if (!Array.isArray(room.userIds)) {
        return room.name || "Chat Room";
      }

      // For job chat rooms, return the job title
      if (room.type === ChatRoomType.JOB) {
        return room.name || "Job Chat";
      }

      if (room.type === ChatRoomType.SMS) {
        return room.name;
      }

      if (room.userIds.length > 2) return room.name || "Group Chat";

      const otherUserID = room.userIds.find(
        (userID) => userID !== firebaseUser.uid,
      );
      if (!otherUserID) return room.name || "Chat";
      return userDisplayNamesMap[otherUserID] || "Unknown User";
    } catch (error) {
      logger.warn("Error getting room name", error);
      return "Chat Room";
    }
  }

  // Get the last message text to display in the chat room list
  function getLastMessageText(room: ExistingChatRoom): string {
    try {
      if (!room.lastMessage) return "";

      // Handle different message types
      switch (room.lastMessage.type) {
        case MessageType.TEXT:
          return room.lastMessage.text || "";
        case MessageType.IMAGE:
          return "📷 Image";
        case MessageType.VIDEO:
          return "🎥 Video";
        case MessageType.FILE:
          return "📎 File";
        case MessageType.AUDIO:
          return "🎵 Audio";
        case MessageType.SYSTEM:
          return "System message";
        default:
          return "";
      }
    } catch (error) {
      logger.warn("Error getting last message text", error);
      return "";
    }
  }

  // get icon that displays the other users userPhotoURL or a circle with their initials in it
  function getIconForRoom(roomID: string): JSX.Element {
    const room = chatRooms.find((r) => r.id === roomID);

    if (!room) {
      return <UserCircleIcon className="h-6 w-6 text-gray-500" />;
    }

    // Check if this is an SMS chat room
    if (room.type === ChatRoomType.SMS) {
      return (
        <div className="relative">
          <ChatBubbleLeftRightIcon className="h-6 w-6 text-blue-500" />
          {/* Small SMS indicator badge */}
          <span className="absolute -right-1 -top-1 rounded-full bg-blue-100 px-1 text-xs font-semibold text-blue-800">
            SMS
          </span>
        </div>
      );
    }

    if (room.type === ChatRoomType.JOB) {
      return <Work className="h-10 w-10 text-blue-500" />;
    }

    if (room.type === ChatRoomType.GROUP) {
      return <Groups2 className="h-10 w-10 text-gray-500" />;
    }

    if (room.userIds.length > 2) {
      return <Groups2 className="h-10 w-10 text-gray-500" />;
    }

    const otherUserID = room.userIds.find(
      (userID) => userID !== firebaseUser?.uid,
    );

    if (!otherUserID)
      return <Person2Sharp className="h-10 w-10 text-gray-500" />;

    const otherUser = siteKeyUsersList.find((user) => user.id === otherUserID);
    if (!otherUser?.userPhoto_URL)
      return <Person2Sharp className="h-10 w-10 text-gray-500" />;

    return (
      <img
        src={otherUser.userPhoto_URL ?? ""}
        alt="user"
        className="h-10 w-10 shrink-0 rounded-full object-cover"
      />
    );
  }

  const userChatSelectorOptions: ColumnOption[] = getFormattedSiteUsers(
    siteKeyUsersList.filter((user) => !user.systemUser),
    firebaseUser,
  );

  return (
    <div
      className="flex flex-col gap-1"
      ref={containerRef}
      data-testid="chat-rooms-component"
    >
      {/* Add CSS for the new message animation */}
      <style>
        {`
          @keyframes newMessageHighlight {
            0% {
              background-color: rgba(255, 255, 255, 1);
            }
            50% {
              background-color: rgba(219, 234, 254, 1);
            }
            100% {
              background-color: rgba(255, 255, 255, 1);
            }
          }
          
          .new-message-highlight {
            animation: newMessageHighlight 2s ease;
          }
        `}
      </style>

      {/* Search and New Chat Header */}
      <div className="flex items-center gap-2 px-2">
        <div className="relative flex-1">
          <Search className="absolute left-2 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search chats..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full rounded-md border border-gray-300 py-2 pl-9 pr-3 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary"
          />
        </div>
        <StyledTooltip title="New Chat">
          <button
            onClick={() => setShowUsersDropdown(true)}
            className="hover:bg-primary/90 rounded-full bg-primary p-2 text-white"
          >
            <Add />
          </button>
        </StyledTooltip>
      </div>

      {/* New Chat Selection Dialog */}
      <ChatRoomUserSelectionDialog
        title="New Chat"
        description="Select users to start a conversation with"
        open={showUsersDropdown}
        onClose={() => {
          setShowUsersDropdown(false);
          setUserDropdownValues([]);
        }}
        onConfirm={(groupName?: string) => {
          if (userDropdownValues.length > 0) {
            onStartNewChat(userDropdownValues, groupName);
          }
        }}
        options={userChatSelectorOptions}
        selectedValues={userDropdownValues}
        onSelectionChange={(values: string[]) => {
          setUserDropdownValues(values);
        }}
        confirmButtonText="Start Chat"
        usersList={siteKeyUsersList.filter((user) => !user.systemUser)}
        isSiteAdmin={userPermissions?.permissions.isSiteAdmin === true}
      />

      {/* Chat Room List */}
      {sortedAndFilteredChatRooms.length > 0 ? (
        sortedAndFilteredChatRooms.map((chatRoom) => (
          <div
            key={chatRoom.id}
            className={`flex min-h-[64px] w-full cursor-pointer items-center rounded-md bg-white px-3 py-1 transition-all hover:bg-gray-50 hover:shadow-md ${
              animatedRoomId === chatRoom.id ? "new-message-highlight" : ""
            }`}
          >
            <div
              className="flex w-full items-center"
              onClick={() => onSelectRoom(chatRoom.id)}
            >
              <div className="mr-3 flex-shrink-0">
                {getIconForRoom(chatRoom.id)}
              </div>
              <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
                <div
                  className="line-clamp-2 break-words font-medium"
                  title={getRoomName(chatRoom.id)}
                >
                  {getRoomName(chatRoom.id)}
                </div>
                <div className="flex flex-col leading-none">
                  {/* Last message text */}
                  <div
                    className="truncate text-xs text-gray-500"
                    title={getLastMessageText(chatRoom)}
                  >
                    {getLastMessageText(chatRoom)}
                  </div>

                  {chatRoom.type === ChatRoomType.GROUP && (
                    <div className="truncate text-xs text-gray-500">
                      {chatRoom.userIds
                        .filter((id) => id !== firebaseUser?.uid)
                        .slice(0, 3)
                        .map((id) => userDisplayNamesMap[id] || "Unknown")
                        .join(", ")}
                      {chatRoom.userIds.length > 4 &&
                        ` +${chatRoom.userIds.length - 4} more`}
                    </div>
                  )}
                  <div className="mt-0.5 text-xs text-gray-400">
                    {convertToReadableTimestamp(chatRoom.timestampLastModified)}
                  </div>
                </div>
              </div>
              <div className="ml-2 flex flex-shrink-0 items-center">
                {getUnreadCountForRoom(chatRoom.id) > 0 && (
                  <div className="mr-2 flex h-5 min-w-[20px] items-center justify-center rounded-full bg-red-500 px-1 text-xs font-medium text-white">
                    {getUnreadCountForRoom(chatRoom.id)}
                  </div>
                )}
                <MdIcons.MdChevronRight className="h-6 w-6 text-gray-400" />
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="mt-4 text-center text-gray-500">
          {searchQuery ? "No chats match your search" : "No chats yet"}
        </div>
      )}
    </div>
  );
}

function getFormattedSiteUsers(
  siteKeyUsersList: ExistingSiteKeyUserDoc[],
  firebaseUser: User | null,
): ColumnOption[] {
  if (!firebaseUser) return [];

  // remove the current user & the system users
  const filteredUsers = siteKeyUsersList.filter(
    (siteUser) =>
      siteUser.id !== firebaseUser.uid && siteUser.systemUser !== true,
  );

  const columnOptions: ColumnOption[] = filteredUsers.map((siteUser) => {
    return {
      text: siteUser.displayName,
      value: siteUser.id,
    };
  });
  return columnOptions;
}
