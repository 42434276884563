import React from "react";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";

const styles: React.CSSProperties = {
  display: "block",
  height: "28px",
  width: "28px",
  cursor: "pointer",
  padding: "4px",
};

type Props = {
  overrideIconStyles?: React.CSSProperties;
  /** if not provided: rounded-full text-gray-700 hover:bg-primaryOpacity90 */
  overrideAnchorClasses?: string;
  href: string;
  /** aria-label for the icon */
  ariaLabel: string;
} & React.ComponentPropsWithRef<"a">;

/** Renders an "open in new tab" icon (uses the anchor tag) */
export const ButtonIconOpenInNewTab = React.forwardRef<
  HTMLAnchorElement,
  Props
>(
  (
    { overrideIconStyles, overrideAnchorClasses, href, ariaLabel, ...rest },
    ref,
  ) => {
    return (
      <a
        {...rest}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        ref={ref}
        className={`${overrideAnchorClasses ?? "rounded-full text-gray-700 hover:bg-primaryOpacity90"}`}
      >
        <OpenInNewRoundedIcon
          aria-label={ariaLabel}
          style={{ ...styles, ...overrideIconStyles }}
        />
      </a>
    );
  },
);
