//Libs
import { Fragment, useLayoutEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/solid";

//Local
import { ColumnOption } from "../models/scheduling-types";

interface Props {
  optionList: ColumnOption[];
  onSelection: (multipleSelectionValue: ColumnOption["value"][]) => void;
  initialValue: ColumnOption["value"][];
  selectionType: string;
}

export default function MultipleSelectionDropdown({
  onSelection,
  optionList,
  initialValue,
  selectionType,
}: Props) {
  const [multipleSelection, setMultipleSelection] =
    useState<ColumnOption["value"][]>(initialValue);

  useLayoutEffect(() => {
    if (optionList.length === 1) {
      setMultipleSelection([optionList[0].value]);
    }
  }, [optionList]);

  return (
    <div className="z-20">
      <div className="w-40">
        <Listbox
          value={multipleSelection}
          onChange={(event) => {
            setMultipleSelection(event);
            onSelection(event);
          }}
          multiple
        >
          <div className="relative">
            <Listbox.Button className="relative h-10 w-full cursor-default rounded-md border border-primary bg-white py-2 pl-3 pr-10 text-left outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primaryLight sm:text-sm">
              <span className="block truncate">
                {" "}
                {multipleSelection.length !== 0
                  ? getSelectedString(multipleSelection, optionList)
                  : `Select one or more ${selectionType}`}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-700"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {optionList.map((option, optionIdx) => (
                  <Listbox.Option
                    key={optionIdx}
                    className={({ active, selected }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active || selected
                          ? "bg-primaryOpacity90 text-primary"
                          : "text-gray-700"
                      }`
                    }
                    value={option.value}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {option.text !== null
                            ? option.text
                            : "Select a location"}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    </div>
  );
}

function getSelectedString(
  multipleSelection: ColumnOption["value"][],
  optionList: ColumnOption[],
) {
  const selectionTextList: string[] = [];
  multipleSelection.forEach((value) => {
    const found = optionList.find((option) => option.value === value);
    if (found) {
      selectionTextList.push(found.text);
    }
  });

  return selectionTextList.join(", ");
}
