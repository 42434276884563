import { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { CompaniesCSInterface } from "../../CreateSite/CreateSiteContainer";
import BaseInputText from "../../../components/BaseInputText";
import BaseButtonPrimary from "../../../components/BaseButtonPrimary";
import {
  CraftTypeValues,
  getReadableCraftType,
} from "../../../models/craft-types";
import { NewSiteConfigState } from "../../../models/new-site-config";
import StyledMessage from "../../../components/StyledMessage";
import SiteMap from "../../../components/SiteMap";
import Marker from "../../../components/admin/Marker";
import { useRootUserStore } from "../../../store/root-user";

interface CompanyStepProps {
  onComplete: (data: {
    name: string;
    craftTypes: CraftTypeValues[];
    mainPointOfContact: string;
    address: {
      formatted: string;
      latitude: number;
      longitude: number;
    };
  }) => void;
  initialData: {
    name: string;
    craftTypes: CraftTypeValues[];
    mainPointOfContact: string;
    address: {
      formatted: string;
      latitude: number;
      longitude: number;
    };
  };
  templateList: NewSiteConfigState[];
}

export default function CompanyStep({
  onComplete,
  initialData,
  templateList,
}: CompanyStepProps) {
  const rootUser = useRootUserStore((state) => state.rootUser);

  const [company, setCompany] = useState<CompaniesCSInterface>({
    name: initialData.name,
    "main point of contact": rootUser?.displayName || "",
  });

  const [address, setAddress] = useState(initialData.address.formatted || "");
  const [position, setPosition] = useState<google.maps.LatLngLiteral>({
    lat: initialData.address.latitude || 27.950575,
    lng: initialData.address.longitude || -82.4571776,
  });

  const [selectedCraftTypes, setSelectedCraftTypes] = useState<
    CraftTypeValues[]
  >(initialData.craftTypes);

  const [error, setError] = useState("");

  // Get unique craft types from all templates
  const availableCraftTypes = Array.from(
    new Set(templateList.flatMap((template) => template.validCraftTypes)),
  ).filter((craftType) => typeof craftType === "number") as CraftTypeValues[];

  const handleSelectAddress = async (selectedAddress: string) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const latLng = await getLatLng(results[0]);

      setAddress(selectedAddress);
      setPosition(latLng);
      setError("");
    } catch (error) {
      setError("Error finding address. Please try again.");
    }
  };

  const handleCraftTypeToggle = (craftType: CraftTypeValues) => {
    setSelectedCraftTypes((prev) =>
      prev.includes(craftType)
        ? prev.filter((ct) => ct !== craftType)
        : [...prev, craftType],
    );
  };

  const handleContinue = () => {
    if (!company.name) {
      setError("Please enter your company name");
      return;
    }

    if (!address) {
      setError("Please enter your business address");
      return;
    }

    if (selectedCraftTypes.length === 0) {
      setError("Please select at least one work type");
      return;
    }

    setError("");
    onComplete({
      name: company.name,
      craftTypes: selectedCraftTypes,
      mainPointOfContact: rootUser?.displayName || "",
      address: {
        formatted: address,
        latitude: position.lat,
        longitude: position.lng,
      },
    });
  };

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-2xl font-bold text-gray-900">
          Tell Us About Your Company
        </h2>
        <p className="mt-1 text-sm text-gray-500">
          Let's get started with your basic company information.
        </p>
      </div>

      <div className="space-y-6">
        <div>
          <BaseInputText
            text="Company Name"
            value={company.name}
            onChange={(e) => setCompany({ ...company, name: e.target.value })}
            required
            admin
          />
        </div>

        <div>
          <PlacesAutocomplete
            value={address}
            onChange={setAddress}
            onSelect={handleSelectAddress}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <BaseInputText
                  {...getInputProps({
                    text: "Business Address",
                    required: true,
                    admin: true,
                  })}
                />
                <div
                  className={
                    suggestions.length > 0 || loading
                      ? "absolute z-10 mt-1 w-full rounded-md border bg-white shadow-lg"
                      : ""
                  }
                >
                  {loading && (
                    <div className="p-2 text-gray-500">Loading...</div>
                  )}
                  {suggestions.map((suggestion, index) => (
                    <div
                      //   key={index}
                      {...getSuggestionItemProps(suggestion, {
                        className: `cursor-pointer p-2 text-sm ${
                          suggestion.active
                            ? "bg-primary text-white"
                            : "text-gray-900"
                        }`,
                      })}
                    >
                      {suggestion.description}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </div>

        {address && (
          <div className="h-64 overflow-hidden rounded-lg">
            <SiteMap center={position} zoom={15}>
              <Marker
                position={position}
                getNewPosition={setPosition}
                getNewZoom={() => {}}
              />
            </SiteMap>
          </div>
        )}

        <div className="space-y-4">
          <label className="block text-sm font-medium text-gray-700">
            What types of work do you do?
          </label>
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-3 lg:grid-cols-4">
            {availableCraftTypes.map((craftType) => (
              <button
                key={craftType}
                type="button"
                onClick={() => handleCraftTypeToggle(craftType)}
                className={`flex items-center justify-center rounded-lg border p-4 text-center text-sm
                  ${
                    selectedCraftTypes.includes(craftType)
                      ? "bg-primary/10 border-primary text-primary"
                      : "border-gray-200 text-gray-500 hover:border-gray-300"
                  }`}
              >
                {getReadableCraftType(craftType)}
              </button>
            ))}
          </div>
        </div>

        {error && (
          <StyledMessage type="error">{{ message: error }}</StyledMessage>
        )}

        <div className="flex justify-end pt-6">
          <BaseButtonPrimary type="button" onClick={handleContinue}>
            Continue
          </BaseButtonPrimary>
        </div>
      </div>
    </div>
  );
}
