import { useNavigate } from "react-router-dom";
import { FirebaseError } from "firebase/app";

import * as strings from "../strings";
import { DbWrite } from "../database";
import SignUpPage from "./SignUpPage";
import { logger as devLogger, logger } from "../logging";
import { signIn } from "../init-firebase";
import { whiteLabelData } from "../white-label-data";
import { isWhiteLabel } from "../white-label-check";
import { whiteLabel } from "../urls";
import { useToastMessageStore } from "../store/toast-messages";
import { createToastMessageID } from "../utils";
import { BASE_APP_URL, ONBOARDING_URL } from "../urls";

export default function SignUpContainer() {
  const navigate = useNavigate();
  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  function goToLoginPage() {
    window.location.href = BASE_APP_URL;
  }

  // Set logo based on white label
  if (!isWhiteLabel(whiteLabel))
    throw new Error("Invalid white-label configuration!");
  const logo = whiteLabelData[whiteLabel].logo;

  async function handleSignUp(formValues: {
    email: string;
    password: string;
    confirmPassword: string;
  }) {
    try {
      // Create initial account with just email/password
      await DbWrite.onboarding.createInitialAccount({
        email: formValues.email,
        password: formValues.password,
        version: whiteLabel,
      });

      // Sign in the user
      const userCredential = await signIn(
        formValues.email,
        formValues.password,
      );

      if (userCredential.user == null) {
        devLogger.error("User not found after creation");
        addMessage({
          id: createToastMessageID(),
          message: strings.ERROR_USER_NOT_FOUND,
          dialog: false,
          type: "error",
        });
      } else {
        navigate(ONBOARDING_URL);
      }
    } catch (error) {
      logger.error(error);
      if (error instanceof FirebaseError) {
        if (error.code === "auth/email-already-in-use") {
          addMessage({
            id: createToastMessageID(),
            message: strings.EMAIL_ALREADY_IN_USE,
            dialog: false,
            type: "error",
          });
        } else {
          addMessage({
            id: createToastMessageID(),
            message: strings.UNEXPECTED_ERROR,
            dialog: false,
            type: "error",
          });
          devLogger.error("Firebase error during signup:", error);
        }
      } else {
        addMessage({
          id: createToastMessageID(),
          message: strings.UNEXPECTED_ERROR,
          dialog: false,
          type: "error",
        });
        devLogger.error("Error during signup:", error);
      }
    }
  }

  async function handleResendVerification(email: string) {
    await DbWrite.rootUser.sendVerificationLink({ version: whiteLabel });
  }

  async function handlePasswordReset(email: string) {
    await DbWrite.user.sendPasswordResetLink({ email, version: whiteLabel });
  }

  return (
    <SignUpPage
      handleSignUp={handleSignUp}
      goToLoginPage={goToLoginPage}
      handleResendVerification={handleResendVerification}
      handlePasswordReset={handlePasswordReset}
      logo={logo}
    />
  );
}
