import { useState, useEffect } from "react";

interface CarouselSlide {
  image: string;
  heading: string;
  description: string;
}

const carouselData: CarouselSlide[] = [
  {
    image:
      "https://stiltindustrial.com/wp-content/uploads/2023/10/Illustration-1-rev.svg",
    heading: "Field Service Management",
    description:
      "You’re probably a successful contractor with high aspirations and considering potential next steps; let Stilt be your guide.",
  },
  {
    image:
      "https://stiltindustrial.com/wp-content/uploads/2023/10/scale-your-business-with-stilt.svg",
    heading: "Scale Your Business Through Automation and Delegation",
    description:
      "Manage loyalty programs, customer-facing portals, price books, inventory, compliance, and more from a user-friendly one-stop-shop application.",
  },
  {
    image:
      "https://stiltindustrial.com/wp-content/uploads/2023/10/industrial-4.svg",
    heading: "Lighten Your Administrative Burden",
    description:
      "Pen and paper worked for your grandmother, but imagine if she had artificial intelligence… Stilt’s modernized architecture streamlines processes and ensures accuracy.",
  },
  {
    image:
      "https://stiltindustrial.com/wp-content/uploads/2023/10/industrial-1-2.svg",
    heading: "Know What You Have and Where it is, When You Need it Most",
    description:
      "QR codes and GPS coordinates for parts and assets help to accurately represent large businesses from the palm of your hand.",
  },
  {
    image:
      "https://stiltindustrial.com/wp-content/uploads/2023/12/center-1-1.svg",
    heading: "Best-in-Class Customizations",
    description:
      "Modify Stilt’s workflows, notifications, branding, and more to piece together the software you always wished you had.",
  },
  {
    image: "https://stiltindustrial.com/wp-content/uploads/2023/10/sd-3.svg",
    heading: "Know It All",
    description:
      "Never question your staff’s location or a job’s status. Stilt’s real-time insights and customizable notifications ensure you’re updated.",
  },
  {
    image:
      "https://stiltindustrial.com/wp-content/uploads/2023/10/make-money-moves.svg",
    heading: "Make Money Moves",
    description:
      "Stilt integrates with QuickBooks (and other common accounting platforms), handles US and Canadian transactions, streamlines invoicing, and offers best-in-class payment processing.",
  },
  {
    image:
      "https://stiltindustrial.com/wp-content/uploads/2023/10/quote-close-business-realtime.svg",
    heading: "Quote and Close Business in Real-Time, From the Field",
    description:
      "Price book integrations enable real-time job quoting and approval. Tracking upsell helps grow your business and reward high performers.",
  },
  {
    image:
      "https://stiltindustrial.com/wp-content/uploads/2023/10/modernized-crm.svg",
    heading: "Modernized Customer Relationship Management",
    description:
      "Automated workflows help proactively identify customers needing more assistance, gather and optimize their reviews, and follow-up on stale estimates.",
  },
  {
    image:
      "https://stiltindustrial.com/wp-content/uploads/2023/10/ai-powered-scheduling.svg",
    heading: "AI Powered Scheduling, Dispatching, and Technician Management",
    description:
      "Stilt uses the same artificial intelligence as Amazon and FedEx to help schedule, dispatch, and manage technicians, in real-time.",
  },
  {
    image:
      "https://stiltindustrial.com/wp-content/uploads/2023/10/service-dispatch-free-time.svg",
    heading: "Encourage Collaboration",
    description:
      "Real-time notifications, in-app communication tools, and more help you be an effective leader of your team (from anywhere!)",
  },
  {
    image: "https://stiltindustrial.com/wp-content/uploads/2023/10/biz-3.svg",
    heading: "Trustworthy Data = Fact Based Decision Making",
    description:
      "Customizable workflows build logic and automation into your processes, helping prevent the chances of errors and headaches of validating data.",
  },
];

export default function MarketingCarousel() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % carouselData.length);
    }, 7500);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="relative flex h-full w-full flex-col bg-primary">
      {/* SVG Image Container - Takes up top 60% of space */}
      <div className="flex h-[60%] items-center justify-center p-8">
        <img
          src={carouselData[currentSlide].image}
          alt={carouselData[currentSlide].heading}
          className="h-full max-h-[75%] w-auto object-contain"
        />
      </div>

      {/* Content Container - Takes up bottom 40% of space */}
      <div className="flex h-[40%] flex-col items-center justify-between bg-black/50 px-8 py-6 text-center text-white">
        <div className="flex flex-col space-y-4">
          <h2 className="text-2xl font-bold leading-tight lg:text-3xl">
            {carouselData[currentSlide].heading}
          </h2>
          <p className="max-w-2xl text-sm leading-relaxed lg:text-base">
            {carouselData[currentSlide].description}
          </p>
        </div>

        {/* Dots */}
        <div className="flex space-x-2 pt-4">
          {carouselData.map((_, index) => (
            <button
              key={index}
              className={`h-2 w-2 rounded-full transition-all ${
                index === currentSlide ? "w-4 bg-white" : "bg-white/50"
              }`}
              onClick={() => setCurrentSlide(index)}
              aria-label={`Go to slide ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
