//Libs
import { DocumentData, DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

//Local
import { NotFoundError } from "../error-classes";
import { dropUndefined, guardIsPlainObject } from "../utils";
import { logger } from "../logging";
import { zFallback } from "../utils/zod-fallback";

// Define a simplified LastChatMessage interface with only essential properties
export interface LastChatMessage {
  createdBy: string;
  timestampCreated: Timestamp;
  metadata: { [key: string]: any };
  type: string;
  text?: string | null;
}

// Chat room type enum
export enum ChatRoomType {
  DIRECT = "direct",
  GROUP = "group",
  CHANNEL = "channel",
  JOB = "job",
  SMS = "sms", // New type for SMS conversations with customers
}

export interface ChatRoom {
  timestampCreated: Timestamp;
  createdBy: string;
  imageUrl: string | null;
  lastMessage: LastChatMessage | null;
  metadata: { [key: string]: any };
  name: string;
  type: string; // Uses ChatRoomType enum values
  timestampLastModified: Timestamp;
  lastModifiedBy: string;
  userIds: string[];
  craftRecordID?: string | null;

  // New fields for SMS conversations
  customerID?: string | null; // Reference to the customer for SMS chats
  customerPhone?: string | null; // Customer's phone number for SMS

  // Enhanced SMS conversation tracking
  primaryAssignedUserId?: string | null; // User primarily responsible for this conversation
  assignmentHistory?: Array<{
    userId: string;
    assignedAt: Timestamp;
    assignedBy: string;
  }> | null; // Track assignment history

  // Field service specific context
  relatedTaskId?: string | null; // Reference to related appointment
  relatedCraftRecordId?: string | null; // Reference to related job
  serviceAddress?: string | null; // Service address for easy reference
  appointmentTime?: Timestamp | null; // Scheduled appointment time
}

export interface ExistingChatRoom extends ChatRoom {
  id: string;
  refPath: string;
}

export const ChatRoomManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingChatRoom object.
   */
  createFromFirestoreSnapshot: createChatRoomFromFirestoreSnapshot,
  /** Drop `id` and `refPath` before saving to the database */
  convertForFirestore: convertChatRoomForFirestore,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateChatRoom,
  /** Validate a ChatRoom doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateChatRoomWithFallbacks,
  /** Create a new job chat room */
  createJobChatRoom: createJobChatRoom,
  /** Create a new SMS chat room */
  createSMSChatRoom: createSMSChatRoom,
  /** Reassign an SMS chat room */
  reassignSMSChatRoom: reassignSMSChatRoom,
};

/**
 * Create a new job chat room
 */
function createJobChatRoom(
  craftRecordID: string,
  craftRecordTitle: string,
  userIds: string[],
  createdBy: string,
): ChatRoom {
  const now = Timestamp.now();

  const chatRoom: ChatRoom = {
    timestampCreated: now,
    createdBy: createdBy,
    lastModifiedBy: createdBy,
    imageUrl: null,
    lastMessage: null,
    metadata: {},
    name: `Job: ${craftRecordTitle}`,
    type: ChatRoomType.JOB,
    timestampLastModified: now,
    userIds: userIds,
    craftRecordID: craftRecordID,
  };

  return chatRoom;
}

// New function to create an SMS chat room
function createSMSChatRoom(
  customerID: string,
  customerName: string,
  customerPhone: string,
  userIds: string[],
  createdBy: string,
  primaryAssignedUserId?: string, // Add optional primary assigned user
): ChatRoom {
  const now = Timestamp.now();

  // Set primary assigned user, defaulting to creator if not specified
  const assignedUserId = primaryAssignedUserId || createdBy;

  // Create initial assignment history record
  const assignmentHistory = [
    {
      userId: assignedUserId,
      assignedAt: now,
      assignedBy: createdBy,
    },
  ];

  return {
    createdBy,
    timestampCreated: now,
    imageUrl: null, // Could use a custom SMS icon here
    lastMessage: null,
    metadata: {},
    name: `Customer: ${customerName}`, // Use customer name as chat room name
    type: ChatRoomType.SMS,
    timestampLastModified: now,
    lastModifiedBy: createdBy,
    userIds,
    craftRecordID: null,
    customerID,
    customerPhone,
    primaryAssignedUserId: assignedUserId,
    assignmentHistory,
  };
}

/**
 * Convert the Document Snapshot into a validated ExistingChatRoom object.
 */
function createChatRoomFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingChatRoom {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateChatRoomWithFallbacks(snapshotData),
  };
}

const chatRoomSchema = z.object({
  createdBy: z.string(),
  lastModifiedBy: z.string(),
  timestampCreated: z.instanceof(Timestamp),
  imageUrl: z.string().nullable(),
  lastMessage: z
    .object({
      createdBy: z.string(),
      timestampCreated: z.instanceof(Timestamp),
      metadata: z.record(z.any()),
      type: z.string(),
      text: z.string().nullable().optional(),
    })
    .nullable(),
  metadata: z.record(z.any()),
  name: z.string(),
  type: z.string(),
  timestampLastModified: z.instanceof(Timestamp),
  userIds: z.array(z.string()),
  craftRecordID: z.string().nullable().optional(),
  customerID: z.string().nullable().optional(),
  customerPhone: z.string().nullable().optional(),
  primaryAssignedUserId: z.string().nullable().optional(),
  assignmentHistory: z
    .array(
      z.object({
        userId: z.string(),
        assignedAt: z.instanceof(Timestamp),
        assignedBy: z.string(),
      }),
    )
    .nullable()
    .optional(),
  relatedTaskId: z.string().nullable().optional(),
  relatedCraftRecordId: z.string().nullable().optional(),
  serviceAddress: z.string().nullable().optional(),
  appointmentTime: z.instanceof(Timestamp).nullable().optional(),
});

function validateChatRoom(data: DocumentData): ChatRoom {
  const parsedData = chatRoomSchema.parse(data);
  return parsedData as ChatRoom;
}

const chatRoomSchemaWithFallbacks = z.object({
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "chatRoomSchemaWithFallbacks: 'timestampCreated'",
  ),
  createdBy: zFallback(
    z.string(),
    "",
    "chatRoomSchemaWithFallbacks: 'createdBy'",
  ),
  lastModifiedBy: zFallback(
    z.string(),
    "",
    "chatRoomSchemaWithFallbacks: 'lastModifiedBy'",
  ),
  imageUrl: zFallback(
    z.string().nullable(),
    null,
    "chatRoomSchemaWithFallbacks: 'imageUrl'",
  ),
  lastMessage: zFallback(
    z
      .object({
        createdBy: z.string().default(""),
        timestampCreated: z.instanceof(Timestamp).default(Timestamp.now()),
        metadata: z.record(z.any()).default({}),
        type: z.string().default(""),
        text: z.string().nullable().optional(),
      })
      .nullable(),
    null,
    "chatRoomSchemaWithFallbacks: 'lastMessage'",
  ),
  metadata: zFallback(
    z.record(z.any()),
    {},
    "chatRoomSchemaWithFallbacks: 'metadata'",
  ),
  name: zFallback(
    z.string(),
    "Chat Room",
    "chatRoomSchemaWithFallbacks: 'name'",
  ),
  type: zFallback(
    z.string(),
    ChatRoomType.DIRECT,
    "chatRoomSchemaWithFallbacks: 'type'",
  ),
  timestampLastModified: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "chatRoomSchemaWithFallbacks: 'timestampLastModified'",
  ),
  userIds: zFallback(
    z.array(z.string()),
    [],
    "chatRoomSchemaWithFallbacks: 'userIds'",
  ),
  craftRecordID: zFallback(
    z.string().nullable().optional(),
    null,
    "chatRoomSchemaWithFallbacks: 'craftRecordID'",
  ),
  customerID: zFallback(
    z.string().nullable(),
    null,
    "chatRoomSchemaWithFallbacks: 'customerID'",
  ),
  customerPhone: zFallback(
    z.string().nullable(),
    null,
    "chatRoomSchemaWithFallbacks: 'customerPhone'",
  ),
  primaryAssignedUserId: zFallback(
    z.string().nullable(),
    null,
    "chatRoomSchemaWithFallbacks: 'primaryAssignedUserId'",
  ),
  assignmentHistory: zFallback(
    z.array(
      z.object({
        userId: z.string(),
        assignedAt: z.instanceof(Timestamp),
        assignedBy: z.string(),
      }),
    ),
    [],
    "chatRoomSchemaWithFallbacks: 'assignmentHistory'",
  ),
  relatedTaskId: zFallback(
    z.string().nullable(),
    null,
    "chatRoomSchemaWithFallbacks: 'relatedTaskId'",
  ),
  relatedCraftRecordId: zFallback(
    z.string().nullable(),
    null,
    "chatRoomSchemaWithFallbacks: 'relatedCraftRecordId'",
  ),
  serviceAddress: zFallback(
    z.string().nullable(),
    null,
    "chatRoomSchemaWithFallbacks: 'serviceAddress'",
  ),
  appointmentTime: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "chatRoomSchemaWithFallbacks: 'appointmentTime'",
  ),
});

function validateChatRoomWithFallbacks(value: unknown): ChatRoom {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }

  try {
    // Parse with the fallback schema
    const result = chatRoomSchemaWithFallbacks.parse(value);

    if (Array.isArray(result.userIds)) {
      result.userIds = result.userIds.filter((id) => typeof id === "string");
    }

    // Ensure lastMessage is properly set
    if (result.lastMessage === undefined) {
      result.lastMessage = null;
    }

    return result as ChatRoom;
  } catch (error) {
    logger.warn("Error parsing chat room with fallbacks", error);

    // If even the fallback schema fails, return a completely default object
    return {
      createdBy: "",
      lastModifiedBy: "",
      timestampCreated: Timestamp.now(),
      imageUrl: null,
      lastMessage: null,
      metadata: {},
      name: "Chat Room",
      type: ChatRoomType.DIRECT,
      timestampLastModified: Timestamp.now(),
      userIds: [],
      craftRecordID: null,
    };
  }
}

function convertChatRoomForFirestore(chatRoom: ChatRoom): DocumentData {
  const local = Object.assign({}, chatRoom);
  const { ...rest } = local;
  return dropUndefined(rest);
}

// New function to reassign an SMS chat room
function reassignSMSChatRoom(
  chatRoom: ChatRoom,
  newPrimaryUserId: string,
  assignedBy: string,
): Partial<ChatRoom> {
  const now = Timestamp.now();

  // Create new assignment history entry
  const newAssignmentEntry = {
    userId: newPrimaryUserId,
    assignedAt: now,
    assignedBy,
  };

  // Create updated assignment history array
  const updatedAssignmentHistory = Array.isArray(chatRoom.assignmentHistory)
    ? [...chatRoom.assignmentHistory, newAssignmentEntry]
    : [newAssignmentEntry];

  // Ensure user has access to chat room
  const updatedUserIds = [...chatRoom.userIds];
  if (!updatedUserIds.includes(newPrimaryUserId)) {
    updatedUserIds.push(newPrimaryUserId);
  }

  // Return partial update for the chat room
  return {
    primaryAssignedUserId: newPrimaryUserId,
    assignmentHistory: updatedAssignmentHistory,
    userIds: updatedUserIds,
    timestampLastModified: now,
    lastModifiedBy: assignedBy,
  };
}

export {
  createChatRoomFromFirestoreSnapshot,
  validateChatRoom,
  validateChatRoomWithFallbacks,
  convertChatRoomForFirestore,
  createJobChatRoom,
  createSMSChatRoom,
  reassignSMSChatRoom,
};
