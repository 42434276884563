//Libs
import { DocumentData, DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

//Local
import { NotFoundError } from "../error-classes";
import { dropUndefined, guardIsPlainObject } from "../utils";
import { logger } from "../logging";
import { zFallback } from "../utils/zod-fallback";

// Message type enum to match Flutter app
export enum MessageType {
  AUDIO = "audio",
  CUSTOM = "custom",
  FILE = "file",
  IMAGE = "image",
  SYSTEM = "system",
  TEXT = "text",
  UNSUPPORTED = "unsupported",
  VIDEO = "video",
}

// Message status enum to match Flutter app
export enum MessageStatus {
  DELIVERED = "delivered",
  SENT = "sent",
  ERROR = "error",
  SEEN = "seen",
  SENDING = "sending",
}

export interface ChatMessage {
  createdBy: string;
  timestampCreated: Timestamp;
  metadata: { [key: string]: any };
  remoteId: string;
  repliedMessage: string;
  chatRoomID: string;
  showStatus: boolean;
  status: string; // Uses MessageStatus enum values
  type: string; // Uses MessageType enum values
  timestampLastModified: Timestamp;

  // Content fields
  text?: string | null;
  previewData?: { [key: string]: any } | null;

  // Media fields
  uri?: string | null;
  name?: string | null;
  size?: number | null;
  height?: number | null;
  width?: number | null;
  duration?: number | null; // Duration in milliseconds
  waveForm?: number[] | null;
  mimeType?: string | null;

  // Custom message fields
  customType?: string | null;
  customData?: string | null;

  // System message fields
  systemType?: string | null;
  systemData?: string | null;

  // SMS specific fields
  twilioMessageSid?: string | null; // Twilio message ID for tracking
  smsStatus?: string | null; // Status from Twilio (sent, delivered, failed, etc.)
  isFromCustomer?: boolean | null; // Whether the message is from the customer (incoming) or internal user (outgoing)
}

export interface ExistingChatMessage extends ChatMessage {
  id: string;
  refPath: string;
}

export interface NewChatMessage {
  text?: string;
  status?: string;
  type?: string;
  uri?: string;
  name?: string;
  size?: number;
  height?: number;
  width?: number;
  duration?: number;
  mimeType?: string;
  customType?: string;
  customData?: string;
  systemType?: string;
  systemData?: string;
}

export const ChatMessageManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingChatMessage object.
   */
  createFromFirestoreSnapshot: createChatMessageFromFirestoreSnapshot,
  /** Drop `id` and `refPath` before saving to the database */
  convertForFirestore: convertChatMessageForFirestore,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateChatMessage,
  /** Validate a ChatMessage doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateChatMessageWithFallbacks,
  /** Create a new message from a NewChatMessage request */
  createFromNewMessage: createFromNewMessage,
};

/**
 * Create a new message from a NewChatMessage request
 */
function createFromNewMessage(
  newMessage: NewChatMessage,
  createdBy: string,
  chatRoomID: string,
  messageType: string = MessageType.TEXT,
): ChatMessage {
  const now = Timestamp.now();

  return {
    createdBy,
    timestampCreated: now,
    metadata: {},
    remoteId: "",
    repliedMessage: "",
    chatRoomID,
    showStatus: true,
    status: newMessage.status || MessageStatus.SENT,
    type: messageType,
    timestampLastModified: now,
    text: newMessage.text || null,
    uri: newMessage.uri || null,
    name: newMessage.name || null,
    size: newMessage.size || null,
    height: newMessage.height || null,
    width: newMessage.width || null,
    duration: newMessage.duration || null,
    mimeType: newMessage.mimeType || null,
    customType: newMessage.customType || null,
    customData: newMessage.customData || null,
    systemType: newMessage.systemType || null,
    systemData: newMessage.systemData || null,
    twilioMessageSid: null,
    smsStatus: null,
    isFromCustomer: null,
  };
}

/**
 * Convert the Document Snapshot into a validated ExistingChatMessage object.
 */
function createChatMessageFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingChatMessage {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateChatMessageWithFallbacks(snapshotData),
  };
}

function convertChatMessageForFirestore(
  chatMessage: ChatMessage,
): DocumentData {
  const local = Object.assign({}, chatMessage);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { ...rest } = local;

  // Filter out null/undefined values
  return dropUndefined(rest);
}

/* Zod validation schemas */
function validateChatMessageWithFallbacks(value: unknown): ChatMessage {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }

  try {
    // Parse with the fallback schema
    const result = chatMessageSchemaWithFallbacks.parse(value);
    return result;
  } catch (error) {
    logger.warn("Error parsing chat message with fallbacks", error);

    // If even the fallback schema fails, return a completely default object
    return {
      createdBy: "",
      timestampCreated: Timestamp.now(),
      metadata: {},
      remoteId: "",
      repliedMessage: "",
      chatRoomID: "",
      showStatus: false,
      status: MessageStatus.SENT,
      type: MessageType.TEXT,
      timestampLastModified: Timestamp.now(),
      text: "",
      twilioMessageSid: null,
      smsStatus: null,
      isFromCustomer: null,
    };
  }
}

function validateChatMessage(value: unknown): ChatMessage {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = chatMessageSchema.parse(value);
  return result;
}

const chatMessageSchema = z.object({
  createdBy: z.string().max(100),
  timestampCreated: z.instanceof(Timestamp),
  metadata: z.record(z.any()),
  remoteId: z.string().max(100),
  repliedMessage: z.string().max(100),
  chatRoomID: z.string().max(100),
  showStatus: z.boolean(),
  status: z.string().max(100),
  type: z.string().max(100),
  timestampLastModified: z.instanceof(Timestamp),
  text: z.string().max(10000).nullable().optional(),
  previewData: z.record(z.any()).nullable().optional(),
  uri: z.string().max(1000).nullable().optional(),
  name: z.string().max(500).nullable().optional(),
  size: z.number().nullable().optional(),
  height: z.number().nullable().optional(),
  width: z.number().nullable().optional(),
  duration: z.number().nullable().optional(),
  waveForm: z.array(z.number()).nullable().optional(),
  mimeType: z.string().max(100).nullable().optional(),
  customType: z.string().max(100).nullable().optional(),
  customData: z.string().max(10000).nullable().optional(),
  systemType: z.string().max(100).nullable().optional(),
  systemData: z.string().max(10000).nullable().optional(),
  twilioMessageSid: z.string().max(100).nullable().optional(),
  smsStatus: z.string().max(100).nullable().optional(),
  isFromCustomer: z.boolean().nullable().optional(),
});

const chatMessageSchemaWithFallbacks = z.object({
  createdBy: zFallback(
    z.string().max(100),
    "",
    "chatMessageSchemaWithFallbacks: 'createdBy'",
  ),
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "chatMessageSchemaWithFallbacks: 'timestampCreated'",
  ),
  metadata: zFallback(
    z.record(z.any()),
    {},
    "chatMessageSchemaWithFallbacks: 'metadata'",
  ),
  remoteId: zFallback(
    z.string().max(100),
    "",
    "chatMessageSchemaWithFallbacks: 'remoteId'",
  ),
  repliedMessage: zFallback(
    z.string().max(100),
    "",
    "chatMessageSchemaWithFallbacks: 'repliedMessage'",
  ),
  chatRoomID: zFallback(
    z.string().max(100),
    "",
    "chatMessageSchemaWithFallbacks: 'chatRoomID'",
  ),
  showStatus: zFallback(
    z.boolean(),
    false,
    "chatMessageSchemaWithFallbacks: 'showStatus'",
  ),
  status: zFallback(
    z.string().max(100),
    MessageStatus.SENT,
    "chatMessageSchemaWithFallbacks: 'status'",
  ),
  type: zFallback(
    z.string().max(100),
    MessageType.TEXT,
    "chatMessageSchemaWithFallbacks: 'type'",
  ),
  timestampLastModified: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "chatMessageSchemaWithFallbacks: 'timestampLastModified'",
  ),
  text: zFallback(
    z.string().max(10000).nullable().optional(),
    null,
    "chatMessageSchemaWithFallbacks: 'text'",
  ),
  previewData: zFallback(
    z.record(z.any()).nullable().optional(),
    null,
    "chatMessageSchemaWithFallbacks: 'previewData'",
  ),
  uri: zFallback(
    z.string().max(1000).nullable().optional(),
    null,
    "chatMessageSchemaWithFallbacks: 'uri'",
  ),
  name: zFallback(
    z.string().max(500).nullable().optional(),
    null,
    "chatMessageSchemaWithFallbacks: 'name'",
  ),
  size: zFallback(
    z.number().nullable().optional(),
    null,
    "chatMessageSchemaWithFallbacks: 'size'",
  ),
  height: zFallback(
    z.number().nullable().optional(),
    null,
    "chatMessageSchemaWithFallbacks: 'height'",
  ),
  width: zFallback(
    z.number().nullable().optional(),
    null,
    "chatMessageSchemaWithFallbacks: 'width'",
  ),
  duration: zFallback(
    z.number().nullable().optional(),
    null,
    "chatMessageSchemaWithFallbacks: 'duration'",
  ),
  waveForm: zFallback(
    z.array(z.number()).nullable().optional(),
    null,
    "chatMessageSchemaWithFallbacks: 'waveForm'",
  ),
  mimeType: zFallback(
    z.string().max(100).nullable().optional(),
    null,
    "chatMessageSchemaWithFallbacks: 'mimeType'",
  ),
  customType: zFallback(
    z.string().max(100).nullable().optional(),
    null,
    "chatMessageSchemaWithFallbacks: 'customType'",
  ),
  customData: zFallback(
    z.string().max(10000).nullable().optional(),
    null,
    "chatMessageSchemaWithFallbacks: 'customData'",
  ),
  systemType: zFallback(
    z.string().max(100).nullable().optional(),
    null,
    "chatMessageSchemaWithFallbacks: 'systemType'",
  ),
  systemData: zFallback(
    z.string().max(10000).nullable().optional(),
    null,
    "chatMessageSchemaWithFallbacks: 'systemData'",
  ),
  twilioMessageSid: zFallback(
    z.string().max(100).nullable().optional(),
    null,
    "chatMessageSchemaWithFallbacks: 'twilioMessageSid'",
  ),
  smsStatus: zFallback(
    z.string().max(100).nullable().optional(),
    null,
    "chatMessageSchemaWithFallbacks: 'smsStatus'",
  ),
  isFromCustomer: zFallback(
    z.boolean().nullable().optional(),
    null,
    "chatMessageSchemaWithFallbacks: 'isFromCustomer'",
  ),
});
