// Libs
import { XCircleIcon, XMarkIcon } from "@heroicons/react/24/solid";
import React, { forwardRef, useEffect, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";

// Local
import * as strings from "../../strings";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseModal from "../BaseModal";
import StyledMessage from "../StyledMessage";
import { logger } from "../../logging";
import { ExistingTask } from "../../models/task";
import { ExistingCustomField } from "../../models/custom-field";
import { IMultipleUid_AssignUser } from "../CustomFields/MultipleUidDialog";
import { Json } from "../../models/json-type";
import {
  Input,
  convertResponsesToExpectedTypes,
  dropUnchangedFields,
  getDefaultValuesForExistingResponse,
} from "../CustomFields";
import { SELECT_ASSET, SELECT_MEMBERSHIP } from "../../strings";
import { DbRead } from "../../database";
import { ExistingMembership } from "../../models/membership";
import AssetEquipmentListCheckboxes from "../Memberships/AssetEquipmentListCheckboxes";
import { ExistingAsset } from "../../models/asset";
import MembershipTaskLinkingSection from "../Memberships/MembershipTaskLinkingSection";
import { ExistingCustomerContact } from "../../models/customer-contact";
import { ContactDropdown } from "../estimates/CustomerContactSection";

type TextareaProps = {
  text: string;
  value?: string;
} & React.ComponentPropsWithRef<"textarea">;
type Ref = HTMLTextAreaElement;
const LocalTextarea = forwardRef<Ref, TextareaProps>(
  (props, ref): JSX.Element => {
    return (
      <div className="relative">
        <textarea
          {...props}
          ref={ref}
          id="description"
          rows={2}
          className="block w-full rounded border border-black p-4 text-gray-700 focus:border-primaryLight focus:outline-none focus:ring focus:ring-primaryLight sm:text-sm"
          value={props.value}
        />
        <label
          htmlFor="description"
          className="absolute -top-3 left-3 bg-white px-2"
        >
          {props.text}
        </label>
      </div>
    );
  },
);

// TODO: cap description content at whatever the limit is

interface Props {
  // DIALOG BASICS
  isOpen: boolean;
  onClose: () => void;

  // DATA
  // taskDescription: ExistingTask["description"]; // available to edit
  // taskSpecificDetails: ExistingTask["taskSpecificDetails"]; // available to edit
  task: ExistingTask;
  siteKeyCustomFields: ExistingCustomField[];
  siteKey: string;
  membershipsEnabled: boolean;
  assetsEnabled: boolean;
  userList: IMultipleUid_AssignUser[];
  userDisplayNamesMap: Record<string, string>;
  customerContacts: ExistingCustomerContact[] | null;

  // FUNCTIONS
  handleEditTask: (values: Record<string, Json>) => Promise<void>;
}

export default function EditTaskDialog(props: Props): JSX.Element {
  // Filter out serviceWindow fields
  const skCustomFields = props.siteKeyCustomFields.filter(
    (cf) =>
      cf.id !== "scheduledServiceWindowStart" &&
      cf.id !== "scheduledServiceWindowEnd",
  );

  const defaultValues: Record<string, Json | Date> = {};
  skCustomFields.forEach((cf) => {
    const dv = getDefaultValuesForExistingResponse({
      type: cf.fieldType,
      existingResponse: props.task.taskSpecificDetails[cf.id],
      userDisplayNamesMap: props.userDisplayNamesMap,
      userList: props.userList,
      selectionOptions:
        cf.fieldType === "selection" ? cf.selectionOptions : null,
    });

    if (cf.fieldType === "hours-minutes" && Array.isArray(dv)) {
      defaultValues[`${cf.id}_hoursValue`] = dv[0];
      defaultValues[`${cf.id}_minutesValue`] = dv[1];
    } else {
      defaultValues[cf.id] = dv;
    }
  });

  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
    reset,
  } = useForm({
    defaultValues: defaultValues as Record<string, any>,
  });

  function closeDialog() {
    props.onClose();
    reset(defaultValues);
  }

  const [description, setDescription] = useState(props.task.description);
  const [membershipIDs, setMembershipIDs] = useState<string[] | undefined>(
    props.task.membershipIDs,
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [membershipsForCustomer, setMembershipsForCustomer] = useState<
    ExistingMembership[]
  >([]);

  const [assetsForCustomer, setAssetsForCustomer] = useState<ExistingAsset[]>(
    [],
  );
  const [assetIDs, setAssetIDs] = useState<string[] | undefined>(
    props.task.assetIDs,
  );
  const [primaryContactPhone, setPrimaryContactPhone] =
    useState<ExistingCustomerContact | null>(null);
  const [primaryContactEmail, setPrimaryContactEmail] =
    useState<ExistingCustomerContact | null>(null);

  // Fetch the list of payments when this component loads.
  useEffect(() => {
    async function getMembershipsForCustomer() {
      if (!props.task.customerID) return undefined;

      logger.debug("getPayments useEffect called ");
      // Query payments via realtime updates. Set the list of invoices.
      const unsubscribeAllPaymentsInDateRange =
        DbRead.memberships.subscribeByCustomerID({
          siteKey: props.siteKey,
          customerID: props.task.customerID,
          onChange: setMembershipsForCustomer,
          onError: (error) =>
            logger.error(
              `Error in getMembershipsForCustomer: ${error.message}`,
            ),
        });
      return unsubscribeAllPaymentsInDateRange;
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    const unsubscribePromise = getMembershipsForCustomer();
    // Return an anonymous ƒn for cleanup.
    return () => {
      unsubscribePromise.then((unsubscribe) => {
        if (unsubscribe) {
          unsubscribe();
        }
      });
    };
  }, [props.siteKey, props.task.customerID]);

  // Fetch the asset documents for a customer
  useEffect(() => {
    async function getAssetsForCustomerLocation() {
      if (!props.task.customerLocationID) return undefined;

      logger.debug("getAssetsForCustomer useEffect called ");
      // Query payments via realtime updates. Set the list of invoices.
      const unsubscribeAllAssetsByCustomerLocationID =
        DbRead.assets.subscribeByCustomerLocationID({
          siteKey: props.siteKey,
          customerLocationID: props.task.customerLocationID,
          onChange: setAssetsForCustomer,
          onError: (error) =>
            logger.error(`Error in getAssetsForCustomer: ${error.message}`),
        });
      return unsubscribeAllAssetsByCustomerLocationID;
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    const unsubscribePromise = getAssetsForCustomerLocation();
    // Return an anonymous ƒn for cleanup.
    return () => {
      unsubscribePromise.then((unsubscribe) => {
        if (unsubscribe) {
          unsubscribe();
        }
      });
    };
  }, [props.siteKey, props.task.customerLocationID]);

  useEffect(() => {
    const prefEmail = props.customerContacts?.find(
      (contact) =>
        contact.type === "email" &&
        contact.id === props.task.primaryContactEmail,
    );
    if (prefEmail) {
      setPrimaryContactEmail(prefEmail);
    }
    const prefPhone = props.customerContacts?.find(
      (contact) =>
        contact.type !== "email" &&
        contact.id === props.task.primaryContactPhone,
    );
    if (prefPhone) {
      setPrimaryContactPhone(prefPhone);
    }
  }, [props.task, props.customerContacts]);

  const phoneCustomerContacts: ExistingCustomerContact[] =
    props.customerContacts?.filter((contact) => contact.type !== "email") ?? [];

  const emailCustomerContacts: ExistingCustomerContact[] =
    props.customerContacts?.filter((contact) => contact.type === "email") ?? [];

  const onEditTask: SubmitHandler<FieldValues> = async (values) => {
    setIsSubmitting(true);

    const updatedValues = dropUnchangedFields({
      dirtyFields,
      formValues: values,
    });

    if (description && description.length === 0) {
      setDescription(null);
    }

    const convertedValues = convertResponsesToExpectedTypes({
      customFields: skCustomFields,
      values: updatedValues,
      userList: props.userList,
    });

    const xvalues: any = {
      description,
      primaryContactEmail: primaryContactEmail?.id,
      primaryContactPhone: primaryContactPhone?.id,
      ...convertedValues,
    };
    if (membershipIDs) {
      xvalues.membershipIDs = membershipIDs;
      xvalues.isMembershipTask = membershipIDs && membershipIDs.length > 0;
    }

    if (assetIDs) {
      xvalues.assetIDs = assetIDs;
    }

    logger.info("onEditTask values", xvalues);
    try {
      await props.handleEditTask(xvalues);
      setIsSubmitting(false);
      props.onClose();
    } catch (e) {
      logger.error("error while executing props.handleEditTask:", e);
      setShowErrorMessage(true);
      setIsSubmitting(false);
    }
  };

  const dialogTitle = (
    <div className="flex items-center justify-between rounded-t-lg bg-primary p-6 text-xl font-semibold text-white md:px-8">
      <h1>{strings.EDIT_TASK}</h1>
      <button
        onClick={closeDialog}
        className="focus-visible:outline focus-visible:outline-white"
      >
        <XMarkIcon aria-label="close dialog" className="h-6 w-6" />
      </button>
    </div>
  );

  return (
    <BaseModal
      open={props.isOpen}
      closeModal={closeDialog}
      title={dialogTitle}
      parentDivStyles="text-left max-w-3xl"
    >
      <form onSubmit={handleSubmit(onEditTask)}>
        <div className="space-y-12 px-6 py-8 md:px-8">
          {/* DESCRIPTION / NOTES */}
          <LocalTextarea
            text={strings.NOTES}
            placeholder={strings.PLACEHOLDER_VISIBLE_NOTE}
            value={description ? description : undefined}
            onChange={(ev) => setDescription(ev.target.value)}
          />

          {/* CUSTOM FIELDS */}
          <div className="space-y-8 md:grid md:grid-cols-2 md:items-center md:gap-x-16 md:gap-y-8 md:space-y-0">
            {skCustomFields.map((cf) => (
              <span key={cf.id} className="block">
                <Input
                  userList={props.userList}
                  customField={cf}
                  control={control}
                  defaultValue={defaultValues[cf.id]}
                  isRequired={false}
                  handleSubmitMultipleUID={() => {}}
                />
              </span>
            ))}
          </div>

          {props.membershipsEnabled && (
            // Allow user to checkbox multiple memberships that might apply to this task
            <div className="space-y-4">
              <h2 className="text-lg font-semibold text-primary">
                {SELECT_MEMBERSHIP}:
              </h2>
              <MembershipTaskLinkingSection
                membershipsForCustomer={membershipsForCustomer.filter(
                  (m) => m.customerLocationID === props.task.customerLocationID,
                )}
                selectedMembershipIDs={membershipIDs}
                setMembershipIDs={setMembershipIDs}
                selectedMembershipIDsForRenewal={[]}
                setMembershipIDsForRenewal={null}
              />
            </div>
          )}

          {props.assetsEnabled && (
            // Allow user to checkbox multiple memberships that might apply to this task
            <div className="space-y-4">
              <h2 className="text-lg font-semibold text-primary">
                {SELECT_ASSET}:
              </h2>
              <AssetEquipmentListCheckboxes
                assetsForCustomer={assetsForCustomer}
                assetIDs={assetIDs}
                setAssetIDs={setAssetIDs}
              />
            </div>
          )}

          <div className="mx-auto mt-4 w-64 text-sm">
            {showErrorMessage && (
              <StyledMessage type="error">
                {{
                  message: strings.ERR_UPDATE_TASK,
                  icon: (
                    <XCircleIcon
                      className="h-8 w-8 text-red-900"
                      aria-hidden="true"
                    />
                  ),
                }}
              </StyledMessage>
            )}
          </div>

          <div className="space-y-2">
            <h2 className="text-xl font-bold text-primary">Primary Contact</h2>
            <div className="flex flex-wrap gap-4">
              <ContactDropdown
                customerContactList={phoneCustomerContacts}
                selectedContact={primaryContactPhone}
                setSelectedContact={setPrimaryContactPhone}
                label="Phone"
              />
              <ContactDropdown
                customerContactList={emailCustomerContacts}
                selectedContact={primaryContactEmail}
                setSelectedContact={setPrimaryContactEmail}
                label="Email"
              />
            </div>
          </div>

          {/* ACTION BUTTONS */}
          <div className="mt-4 flex w-full flex-col items-center justify-between gap-6 xs:flex-row">
            <BaseButtonSecondary
              type="button"
              className="w-full justify-center uppercase xs:w-52"
              onClick={closeDialog}
            >
              {strings.buttons.CANCEL}
            </BaseButtonSecondary>

            <BaseButtonPrimary
              type="submit"
              disabled={isSubmitting}
              isBusy={isSubmitting}
              busyText={strings.buttons.BUSY_SAVING}
              className="w-full justify-center uppercase xs:w-52"
            >
              {strings.buttons.SAVE}
            </BaseButtonPrimary>
          </div>
        </div>
      </form>
    </BaseModal>
  );
}
