import { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import BaseInputText from "../../../../components/BaseInputText";
import BaseButtonPrimary from "../../../../components/BaseButtonPrimary";
import StyledMessage from "../../../../components/StyledMessage";
import BaseInputSelect from "../../../../components/BaseInputSelect";
import { formatPhoneNumber } from "../../../../utils/formatters";

interface BusinessInfo {
  businessName: string;
  legalName: string;
  ownershipStructureType:
    | "LLC"
    | "Corporation"
    | "Partnership"
    | "SoleProprietorship";
  street1: string;
  street2?: string;
  city: string;
  state: string;
  zipCode: string;
  countryCode: string;
  descriptorPhone: string;
  taxId: string;
  webSite?: string;
  email: string;
  description: string;
  //   merchantCategoryCode: string;
  currencyCode: string;
  registrationYear: string;
  timeZoneCode: string;
}

interface BusinessInfoStepProps {
  initialData: BusinessInfo;
  onComplete: (data: BusinessInfo) => void;
}

const BUSINESS_TYPES = [
  { value: "Corporation", label: "Corporation" },
  { value: "LLC", label: "Limited Liability Company (LLC)" },
  { value: "Partnership", label: "Partnership" },
  { value: "SoleProprietorship", label: "Sole Proprietorship" },
];

const BUSINESS_DESCRIPTIONS = [
  "HVAC Services",
  "Electrical Services",
  "General Contractor Services",
  "Cleaning Services",
  "Other",
] as const;

export default function BusinessInfoStep({
  initialData,
  onComplete,
}: BusinessInfoStepProps) {
  const [formData, setFormData] = useState<BusinessInfo>(initialData);
  const [error, setError] = useState("");

  const handleAddressSelect = async (selectedAddress: string) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const addressComponents = results[0].address_components;

      const street = [
        (addressComponents.find((c) => c.types.includes("street_number")) || {})
          .long_name,
        (addressComponents.find((c) => c.types.includes("route")) || {})
          .long_name,
      ]
        .filter(Boolean)
        .join(" ");

      const city =
        (addressComponents.find((c) => c.types.includes("locality")) || {})
          .long_name || "";
      const state =
        (
          addressComponents.find((c) =>
            c.types.includes("administrative_area_level_1"),
          ) || {}
        ).short_name || "";
      const zipCode =
        (addressComponents.find((c) => c.types.includes("postal_code")) || {})
          .long_name || "";

      setFormData((prev) => ({
        ...prev,
        street1: street || "",
        city,
        state,
        zipCode,
      }));
      setError("");
    } catch (error) {
      setError("Error finding address. Please try again.");
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatPhoneNumber(e.target.value);
    setFormData((prev) => ({
      ...prev,
      descriptorPhone: formatted,
    }));
  };

  const handleLegalNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const legalName = e.target.value;
    setFormData((prev) => ({
      ...prev,
      legalName,
      businessName: prev.businessName || legalName, // Set DBA to legal name if no DBA exists
    }));
  };

  const handleBusinessNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const dbaName = e.target.value;
    setFormData((prev) => ({
      ...prev,
      businessName: dbaName || prev.legalName, // Use legal name if DBA is empty
    }));
  };

  const validateForm = (): boolean => {
    if (!formData.legalName) {
      setError("Legal business name is required");
      return false;
    }
    if (!formData.taxId) {
      setError("Tax ID is required");
      return false;
    }
    if (!formData.ownershipStructureType) {
      setError("Business type is required");
      return false;
    }
    if (
      !formData.street1 ||
      !formData.city ||
      !formData.state ||
      !formData.zipCode
    ) {
      setError("Complete business address is required");
      return false;
    }
    if (!formData.descriptorPhone) {
      setError("Business phone is required");
      return false;
    }
    if (!formData.email) {
      setError("Business email is required");
      return false;
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setError("Please enter a valid email address");
      return false;
    }

    // Tax ID validation
    const taxIdRegex = /^\d{9}$/;
    if (!taxIdRegex.test(formData.taxId.replace(/\D/g, ""))) {
      setError("Please enter a valid 9-digit Tax ID");
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setError("");
      onComplete(formData);
    }
  };

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-2xl font-bold text-gray-900">
          Business Information
        </h2>
        <p className="mt-1 text-sm text-gray-500">
          Tell us about your business. This information will be used to set up
          your merchant account.
        </p>
      </div>

      <div className="space-y-6">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <BaseInputText
            text="Legal Business Name"
            value={formData.legalName}
            onChange={handleLegalNameChange}
            required
            admin
          />

          <BaseInputText
            text="DBA Name (if different)"
            value={formData.businessName}
            onChange={handleBusinessNameChange}
            admin
          />
        </div>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <BaseInputText
            text="Tax ID (EIN)"
            value={formData.taxId}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, taxId: e.target.value }))
            }
            required
            admin
            placeholder="XX-XXXXXXX"
          />

          <BaseInputSelect
            text="Business Type"
            value={formData.ownershipStructureType}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                ownershipStructureType: e.target.value as
                  | "LLC"
                  | "Corporation"
                  | "Partnership"
                  | "SoleProprietorship",
              }))
            }
            required
            admin
          >
            <option value="">Select a business type</option>
            {BUSINESS_TYPES.map((type) => (
              <option key={type.value} value={type.value}>
                {type.label}
              </option>
            ))}
          </BaseInputSelect>
        </div>

        <div className="space-y-4">
          <label className="block text-sm font-medium text-gray-700">
            Business Address
          </label>
          <PlacesAutocomplete
            value={formData.street1}
            onChange={(address) =>
              setFormData((prev) => ({
                ...prev,
                street1: address,
              }))
            }
            onSelect={handleAddressSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div>
                <BaseInputText
                  {...getInputProps({
                    text: "Street Address",
                    required: true,
                    admin: true,
                  })}
                />
                <div
                  className={
                    suggestions.length > 0 || loading
                      ? "absolute z-10 mt-1 w-full rounded-md border bg-white shadow-lg"
                      : ""
                  }
                >
                  {loading && (
                    <div className="p-2 text-gray-500">Loading...</div>
                  )}
                  {suggestions.map((suggestion, index) => {
                    const suggestionProps = getSuggestionItemProps(suggestion, {
                      className: `cursor-pointer p-2 text-sm ${
                        suggestion.active
                          ? "bg-primary text-white"
                          : "text-gray-900"
                      }`,
                    });
                    return (
                      <div {...suggestionProps} key={`suggestion-${index}`}>
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
            <BaseInputText
              text="City"
              value={formData.city}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  city: e.target.value,
                }))
              }
              required
              admin
            />

            <BaseInputText
              text="State"
              value={formData.state}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  state: e.target.value,
                }))
              }
              required
              admin
            />

            <BaseInputText
              text="ZIP Code"
              value={formData.zipCode}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  zipCode: e.target.value,
                }))
              }
              required
              admin
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          <BaseInputText
            text="Business Phone"
            value={formData.descriptorPhone}
            onChange={handlePhoneChange}
            required
            admin
            placeholder="(XXX) XXX-XXXX"
          />

          <BaseInputText
            text="Business Email"
            value={formData.email}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, email: e.target.value }))
            }
            required
            admin
            type="email"
          />
        </div>

        <div>
          <BaseInputText
            text="Website"
            value={formData.webSite}
            onChange={(e) =>
              setFormData((prev) => ({ ...prev, webSite: e.target.value }))
            }
            admin
            placeholder="https://"
          />
        </div>

        <BaseInputSelect
          text="Business Description"
          value={formData.description}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              description: e.target.value,
            }))
          }
          required
          admin
        >
          <option value="">Select business type</option>
          {BUSINESS_DESCRIPTIONS.map((desc) => (
            <option key={desc} value={desc}>
              {desc}
            </option>
          ))}
        </BaseInputSelect>

        {error && (
          <StyledMessage type="error">{{ message: error }}</StyledMessage>
        )}

        <div className="flex justify-end pt-6">
          <BaseButtonPrimary type="button" onClick={handleSubmit}>
            Continue
          </BaseButtonPrimary>
        </div>
      </div>
    </div>
  );
}
