//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import React, { useCallback, useLayoutEffect, useRef, useState } from "react";
import {
  ColDef,
  GetQuickFilterTextParams,
  GridApi,
  ICellRendererParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import isEqual from "lodash/isEqual";

//Local
import * as strings from "../../strings";
import BaseModal from "../BaseModal";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseInputNumber from "../BaseInputNumber";
import { getLocationTitle } from "../../Pages/Inventory/InventoryObjectListPage";
import { ExistingInventoryLocation } from "../../models/inventory-location";
import {
  ExistingInventoryObjectForTable,
  getReadableInventoryObjectStatus,
} from "../../models/inventory-object";
import {
  InventoryTransaction,
  OInventoryTransactionTypes,
} from "../../models/inventory-transaction";
import SearchBox from "../SearchBox";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import { Timestamp } from "firebase/firestore";
import { useAuthStore } from "../../store/firebase-auth";
import { User } from "firebase/auth";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import HeadingOne from "../HeadingOne";

interface Props {
  /* DATA */
  isDialogOpen: boolean;
  inventoryObjectTableData: ExistingInventoryObjectForTable[];
  inventoryLocationList: ExistingInventoryLocation[];
  inventoryTransactionList: InventoryTransaction[];
  /* FUNCTIONS */
  closeDialog: () => void;
  handleSaveInventoryTransaction: (
    inventoryTransaction: InventoryTransaction[],
  ) => void;
  setInventoryTransactionList: React.Dispatch<
    React.SetStateAction<InventoryTransaction[]>
  >;
}

export default function SelectInventoryObject({
  closeDialog,
  inventoryLocationList,
  inventoryObjectTableData,
  isDialogOpen,
  handleSaveInventoryTransaction: handleSaveInventoryObject,
  inventoryTransactionList: inventoryTransactionList,
  setInventoryTransactionList: setInventoryTransactionList,
  ...props
}: Props) {
  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const [siteKeyDoc, siteKeyDocIsLoading] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);

  function saveAndClose() {
    handleSaveInventoryObject(inventoryTransactionList);
    closeDialog();
  }

  const handleTemporaryEstimateItemList = useCallback(
    function (
      formAmountValue: number,
      inventoryObjectTableData: ExistingInventoryObjectForTable,
    ) {
      if (!siteKeyDoc) return;

      let amount = formAmountValue;

      if (isNaN(amount)) {
        amount = 0;
      }

      if (isNaN(amount)) {
        amount = 0;
      }

      const indexOfInventoryTransaction = inventoryTransactionList.findIndex(
        (inventoryTransaction) =>
          inventoryTransaction.inventoryObjectID ===
          inventoryObjectTableData.id,
      );

      if (indexOfInventoryTransaction >= 0) {
        setInventoryTransactionList((oldInventoryTransactionList) => {
          oldInventoryTransactionList[indexOfInventoryTransaction].value =
            amount;
          return [...oldInventoryTransactionList];
        });
      } else {
        console.log(inventoryLocationList, inventoryObjectTableData);
        const newInventoryTransaction: InventoryTransaction = {
          parentRecordID: null,
          taskID: null,
          timestampCreated: Timestamp.now(),
          createdBy: firebaseUser.uid,
          companyID: siteKeyDoc.managingCompanyID,
          inventoryObjectID: inventoryObjectTableData.id,
          inventoryLocationID: inventoryLocationList[0].id,
          referenceTransactionID: null,
          type: OInventoryTransactionTypes.CONSUMED_FROM_STOCK,
          value: amount,
        };

        setInventoryTransactionList((oldInventoryTransactionList) => [
          ...oldInventoryTransactionList,
          newInventoryTransaction,
        ]);
      }
    },
    [inventoryTransactionList, setInventoryTransactionList],
  );

  const selectInventoryObjectHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <HeadingOne fontColor="text-white" fontSize="text-xl">
        {strings.SELECT_INVENTORY_OBJECT}
      </HeadingOne>
      <button type="button" onClick={() => closeDialog()}>
        <XMarkIcon
          aria-label="close new requirement form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  const renderAmountCell = useCallback(
    function (params: ICellRendererParams) {
      return (
        <form autoComplete="off" className="my-2">
          <BaseInputNumber
            type="number"
            min={0}
            className={`block w-20 min-w-0 flex-1 rounded outline-none focus:border focus:border-primaryLight focus:ring-1 focus:ring-primaryLight sm:text-sm`}
            defaultValue={getAmountValue(inventoryTransactionList, params)}
            onBlur={(event) => {
              event.preventDefault();
              handleTemporaryEstimateItemList(
                parseFloat(event.target.value),
                params.data,
              );
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                handleTemporaryEstimateItemList(
                  parseFloat((event.target as HTMLInputElement).value),
                  params.data,
                );
              }
            }}
          />
        </form>
      );
    },
    [handleTemporaryEstimateItemList, inventoryTransactionList],
  );

  return (
    <BaseModal
      closeModal={() => saveAndClose()}
      open={isDialogOpen}
      title={selectInventoryObjectHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-lg rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="relative flex flex-col space-y-8 p-8 text-lg">
        <div className={"ag-theme-alpine flex h-[500px] flex-col"}>
          <InventoryObjectTable
            inventoryObjectTableData={inventoryObjectTableData}
            inventoryLocationList={inventoryLocationList}
            renderAmountCell={renderAmountCell}
          />
        </div>
        <div className="flex flex-col justify-between gap-4 sm:flex-row-reverse">
          <BaseButtonPrimary
            type="button"
            onClick={() => saveAndClose()}
            className="w-full text-primary sm:max-w-xs"
          >
            Save and close
          </BaseButtonPrimary>
          <BaseButtonSecondary
            className="w-full sm:max-w-xs"
            onClick={() => closeDialog()}
          >
            Close
          </BaseButtonSecondary>
        </div>
      </div>
    </BaseModal>
  );
}

const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

const commonColProps = {
  minWidth: 100,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
  cellStyle: {
    display: "flex",
    alignItems: "center",
  },
};

interface InventoryObjectTableProps {
  inventoryObjectTableData: Props["inventoryObjectTableData"];
  inventoryLocationList: Props["inventoryLocationList"];
  renderAmountCell: (params: ICellRendererParams) => JSX.Element;
}

/* TABLE */
const InventoryObjectTable = React.memo(
  ({
    inventoryObjectTableData,
    inventoryLocationList,
    renderAmountCell,
    ...props
  }: InventoryObjectTableProps) => {
    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<any>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        const api: GridApi = gridRef.current.api;
        api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    const onSearchBoxChanged = useCallback(() => {
      gridRef.current!.api.setQuickFilter(
        (document.getElementById("search-box") as HTMLInputElement).value,
      );
    }, []);

    const renderStatus = (params: ICellRendererParams): JSX.Element => (
      <div className="capitalize">
        {getReadableInventoryObjectStatus(params.data.status)}
      </div>
    );

    const renderTitle = (params: ICellRendererParams): JSX.Element => {
      return <div className="whitespace-normal capitalize">{params.value}</div>;
    };

    const renderLocationTitle = (params: ICellRendererParams): JSX.Element => {
      const location = inventoryLocationList.find(
        (inventoryLocation) =>
          inventoryLocation.id === params.data.inventoryLocationID,
      );
      return <div className="capitalize">{location?.title}</div>;
    };

    const columnDefs: ColDef[] = [
      {
        cellRenderer: renderAmountCell,
        suppressMovable: true,
        suppressHeaderMenuButton: true,
        sortable: false,
        autoHeight: true,
        minWidth: 250,
      },
      {
        headerName: "Title",
        field: "title",
        cellRenderer: renderTitle,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        autoHeight: true,
        minWidth: 200,
      },
      {
        headerName: "Inventory Location",
        field: "inventoryLocationID",
        cellRenderer: renderLocationTitle,
        getQuickFilterText: (params: GetQuickFilterTextParams) => {
          return getLocationTitle(params, inventoryLocationList);
        },
      },
      {
        headerName: "Qty Available",
        field: "available",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Qty Awaiting Pickup",
        field: "awaitingPickup",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Qty In Use",
        field: "inUse",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Qty Reserved",
        field: "reserved",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Status",
        field: "status",
        cellRenderer: renderStatus,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
    ];

    return (
      <div className={"ag-theme-alpine flex h-[500px] flex-col"}>
        <SearchBox
          onInput={onSearchBoxChanged}
          id="search-box"
          widthClasses="w-full max-w-md sm:w-96"
        />
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={commonColProps}
          className="mt-5 shadow"
          rowData={inventoryObjectTableData}
          animateRows={true}
          rowSelection="single"
          cacheQuickFilter={true}
          columnDefs={columnDefs}
          rowStyle={{ isolation: "isolate" }}
        />
      </div>
    );
  },
  (previous, next) => {
    const isInventoryObjectListTheSame = isEqual(
      previous.inventoryObjectTableData,
      next.inventoryObjectTableData,
    );

    return isInventoryObjectListTheSame;
  },
);

export function getAmountValue(
  inventoryTransactionList: InventoryTransaction[],
  params: ICellRendererParams,
) {
  let value: number | string = "";
  if (inventoryTransactionList.length !== 0) {
    const currentInventoryTransaction = inventoryTransactionList.find(
      (inventoryTransaction) =>
        inventoryTransaction.inventoryObjectID === params?.data.id,
    );
    if (currentInventoryTransaction != null) {
      if (
        currentInventoryTransaction.value !== 0 &&
        currentInventoryTransaction.value != null
      ) {
        value = currentInventoryTransaction.value;
      } else {
        value = "";
      }
    } else {
      value = "";
    }
  } else {
    value = "";
  }
  return value;
}
