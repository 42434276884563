//Libs
import {
  CellContextMenuEvent,
  ColDef,
  GridApi,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { memo, useCallback, useLayoutEffect, useRef, useState } from "react";

//Local
import { ExistingEstimate } from "../../models/estimate";
import { ExistingTask } from "../../models/task";
import { getEstimateStatus } from "../../assets/js/estimateFunctions";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import { getReadableTaskType } from "../../models/task-types";
import { ExistingEstimateItem } from "../../models/estimate-item";
import isEqual from "lodash/isEqual";
import {
  ExistingStiltInvoice,
  getReadableStiltInvoiceStatus,
} from "../../models/invoice";
import { DollarCurrency } from "../../currency";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
import { CUSTOMERS_URL, ESTIMATES_URL_AUTH } from "../../urls";

interface CustomerEstimateListTableProps {
  currency: string;
  estimateItemList: ExistingEstimateItem[];
  estimateTableData: ExistingEstimate[];
  customerTaskList?: ExistingTask[];
  invoiceList: ExistingStiltInvoice[];
  renderIconCell: (params: ICellRendererParams) => JSX.Element;
  goToViewEstimate: (estimate: ExistingEstimate) => void;
}

const commonColProps = {
  width: 150,
  flex: 1,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass:
    "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",
  cellStyle: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
};

const CustomerEstimateListTable = memo(
  ({
    estimateTableData,
    customerTaskList,
    invoiceList,
    renderIconCell,
    goToViewEstimate,
  }: CustomerEstimateListTableProps) => {
    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<AgGridReact>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        const api: GridApi = gridRef.current.api;
        api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    const renderTaskType = useCallback(
      function (params: ICellRendererParams) {
        if (customerTaskList?.length !== 0) {
          const currentTask = customerTaskList?.find(
            (customerTask) => customerTask.id === params.data.taskID,
          );
          return (
            <div className="flex gap-2">
              <span className="capitalize">
                {currentTask
                  ? getReadableTaskType(currentTask.taskType)
                  : "No Task Associated"}
              </span>
            </div>
          );
        } else {
          return <div>No Task Associated</div>;
        }
      },
      [customerTaskList],
    );

    const renderInvoiceStatus = (params: ICellRendererParams): JSX.Element => {
      const invoicesForEstimate = invoiceList.filter(
        (invoice) => invoice.estimateID === params.data.id,
      );

      let currentInvoice: ExistingStiltInvoice | null = null;
      if (invoicesForEstimate.length === 1) {
        currentInvoice = invoicesForEstimate[0];
      }
      if (invoicesForEstimate.length > 1) {
        const nonCanceledInvoices = invoicesForEstimate.filter(
          (invoice) => invoice.status !== "canceled",
        );
        if (nonCanceledInvoices.length > 0) {
          currentInvoice = nonCanceledInvoices[0];
        }
      }

      let customBg = "bg-primary text-primaryButtonText";
      switch (params.data.status) {
        case "paid":
          customBg = "bg-greenPass text-white";
          break;
        case "partiallyPaid":
          customBg = "bg-orangeError text-white";
          break;
      }

      return (
        <div className="flex items-center">
          {currentInvoice ? (
            <div
              className={`m-1 rounded-full px-2.5 pb-1 pt-0.5 text-xs font-medium ${customBg} capitalize`}
            >
              {getReadableStiltInvoiceStatus(currentInvoice.status)}
            </div>
          ) : null}
        </div>
      );
    };

    const columnDefs: ColDef[] = [
      {
        field: "estimateNumber",
        headerName: "#",
        cellRenderer: (params: ICellRendererParams) =>
          params.data.estimateNumber ?? "",
        maxWidth: 80,
      },
      {
        field: "timestampCreated",
        headerName: "Creation Date",
        cellRenderer: (params: ICellRendererParams) =>
          getReadableTimestamp(params.data.timestampCreated),
        valueFormatter: (params: ValueFormatterParams) =>
          getReadableTimestamp(params.data.timestampCreated),
        tooltipValueGetter: () => "View Estimate",
        onCellClicked: (event) => goToViewEstimate(event.data),
      },
      {
        field: "status",
        headerName: "Status",
        cellRenderer: (params: ICellRendererParams) =>
          getEstimateStatus(params.data.status),
        valueFormatter: (params: ValueFormatterParams) =>
          getEstimateStatus(params.data.status),
        onCellClicked: (event) => goToViewEstimate(event.data),
      },
      {
        field: "notes",
        headerName: "Notes",
        valueFormatter: (params: ValueFormatterParams) => params.value,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        onCellClicked: (event) => goToViewEstimate(event.data),
      },
      {
        headerName: "Total Amount",
        cellRenderer: (params: ICellRendererParams) =>
          params.data.customData.totalAmount
            ? DollarCurrency.format(params.data.customData.totalAmount)
            : "",
        onCellClicked: (event) => goToViewEstimate(event.data),
      },
      {
        headerName: "Invoice Status",
        cellRenderer: renderInvoiceStatus,
        onCellClicked: (event) => goToViewEstimate(event.data),
      },
      {
        cellRenderer: renderIconCell,
        suppressMovable: true,
        suppressHeaderMenuButton: true,
        suppressAutoSize: true,
        suppressSizeToFit: true,
        sortable: false,
        flex: 0,
        autoHeight: true,
        cellStyle: { padding: "5px 0px" },
      },
    ];

    if (customerTaskList) {
      columnDefs.splice(-1, 0, {
        field: "taskID",
        headerName: "Task",
        cellRenderer: renderTaskType,
        onCellClicked: (event) => goToViewEstimate(event.data),
      });
    }

    return (
      <div className="ag-theme-alpine h-[300px] pb-6">
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          defaultColDef={commonColProps}
          className="mt-5 shadow"
          rowData={estimateTableData}
          animateRows={true}
          rowSelection="single"
          columnDefs={columnDefs}
          onCellContextMenu={(event: CellContextMenuEvent<any, any>) => {
            const url = `${CUSTOMERS_URL}${ESTIMATES_URL_AUTH}/${event.data.id}`;
            return window.open(url, "_blank");
          }}
        />
      </div>
    );
  },
  (previous, next) => {
    const isEstimateListTheSame = isEqual(
      previous.estimateTableData,
      next.estimateTableData,
    );

    const isIconCellTheSame = isEqual(
      previous.renderIconCell,
      next.renderIconCell,
    );

    const isEstimateItemListTheSame = isEqual(
      previous.estimateItemList,
      next.estimateItemList,
    );
    return (
      isEstimateListTheSame && isIconCellTheSame && isEstimateItemListTheSame
    );
  },
);

export default CustomerEstimateListTable;

function getReadableTimestamp(timestamp: unknown): string {
  if (timestamp != null) {
    return convertToReadableTimestamp(timestamp);
  } else {
    return "";
  }
}
