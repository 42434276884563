//Libs
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import * as MdIcons from "react-icons/md";
import { LuClock4 } from "react-icons/lu";
import { BiCalendarEvent } from "react-icons/bi";
import { HiBellAlert } from "react-icons/hi2";
import { RiCheckDoubleLine } from "react-icons/ri";

//Local
import * as urls from "../urls";
import { useSiteKeyDocStore } from "../store/site-key-doc";
import { OTaskStatus } from "../models/task-status";
import { OTaskTypes } from "../models/task-types";
import { useEffect, useState } from "react";
import { logger } from "../logging";
import { DbRead } from "../database";
import { useUserPermissionsStore } from "../store/user-permissions";
import LoadingClipboardAnimation from "../components/LoadingClipBoardAnimation";
import { STILTON } from "../strings";
import StiltonActivityOverview from "../components/Stilton/StiltonActivityOverview";

export default function Dashboard() {
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const [siteKeyDoc, siteKeyLoading] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);

  const [taskCountState, setTaskCountState] = useState<{
    inProgress: number;
    awaitingParts: number;
  }>({
    inProgress: 0,
    awaitingParts: 0,
  });

  const [loadingOverviewState, setLoadingOverviewState] = useState<{
    yesterday: boolean;
    today: boolean;
  }>({
    yesterday: false,
    today: false,
  });

  const [yesterdaysOverviewText, setYesterdaysOverviewText] = useState<
    string | null
  >(null);
  const [todaysOverviewText, setTodaysOverviewText] = useState<string | null>(
    null,
  );

  useEffect(() => {
    function getInProgressTasks() {
      if (!siteKeyDoc) return undefined;
      if (!userPermissions) return undefined;

      // Query for all awaiting scheduled tasks via realtime updates.
      const unsubInProgress = DbRead.tasks.subscribeByTaskStatus({
        siteKey: siteKeyDoc.id,
        permissions: userPermissions,
        taskStatus: [OTaskStatus.IN_PROGRESS],
        startDate: null,
        endDate: null,
        dateParam: null,
        onChange: (tasks) => {
          setTaskCountState((prev) => ({
            ...prev,
            inProgress: tasks.length,
          }));
        },
        onError: (error) =>
          logger.error(`Error in ${getInProgressTasks.name}: ${error.message}`),
      });
      return unsubInProgress;
    }

    const unsubscribe = getInProgressTasks();
    return () => unsubscribe && unsubscribe();
  }, [siteKeyDoc, userPermissions]);

  useEffect(() => {
    function getAwaitingPartsTasks() {
      if (!siteKeyDoc) return undefined;
      if (!userPermissions) return undefined;

      // Query for all awaiting scheduled tasks via realtime updates.
      const unsubInProgress = DbRead.tasks.subscribeByTaskStatus({
        siteKey: siteKeyDoc.id,
        permissions: userPermissions,
        taskStatus: [OTaskStatus.AWAITING_PARTS, OTaskStatus.PARTS_ORDERED],
        startDate: null,
        endDate: null,
        dateParam: null,
        onChange: (tasks) =>
          setTaskCountState((prev) => ({
            ...prev,
            awaitingParts: tasks.length,
          })),
        onError: (error) =>
          logger.error(
            `Error in ${getAwaitingPartsTasks.name}: ${error.message}`,
          ),
      });
      return unsubInProgress;
    }

    const unsubscribe = getAwaitingPartsTasks();
    return () => unsubscribe && unsubscribe();
  }, [siteKeyDoc, userPermissions]);

  if (siteKeyLoading || !siteKeyDoc) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  const handleGetYesterdaysOverview = async () => {
    if (!siteKeyDoc) return;
    setLoadingOverviewState((prev) => ({
      ...prev,
      yesterday: true,
    }));
    try {
      const overview = await DbRead.stilton.getYesterdaysActivityOverview({
        siteKeyID: siteKeyDoc.id,
        customerID: "ALL", // Using "ALL" to get overview for all customers
      });
      setYesterdaysOverviewText(overview);
    } catch (error) {
      logger.error("Failed to fetch yesterday's overview:", error);
    } finally {
      setLoadingOverviewState((prev) => ({
        ...prev,
        yesterday: false,
      }));
    }
  };

  const handleGetTodaysOverview = async () => {
    if (!siteKeyDoc) return;
    setLoadingOverviewState((prev) => ({
      ...prev,
      today: true,
    }));
    try {
      const overview = await DbRead.stilton.getTodaysActivityOverview({
        siteKeyID: siteKeyDoc.id,
        customerID: "ALL", // Using "ALL" to get overview for all customers
      });
      setTodaysOverviewText(overview);
    } catch (error) {
      logger.error("Failed to fetch today's overview:", error);
    } finally {
      setLoadingOverviewState((prev) => ({
        ...prev,
        today: false,
      }));
    }
  };

  const TaskCategories = (arg: { useSmall?: boolean }) => {
    const { useSmall } = arg;
    return (
      <>
        <TaskCategory
          path={`${urls.TASK_LIST_URL}/All`}
          Icon={MdIcons.MdFormatListBulleted}
          title="All Open Tasks"
          size={useSmall ? "small" : "normal"}
        />
        <TaskCategory
          path={`${urls.TASK_LIST_URL}/Scheduled`}
          Icon={BiCalendarEvent}
          title="Scheduled"
          size={useSmall ? "small" : "normal"}
        />
        <TaskCategory
          path={`${urls.TASK_LIST_URL}/In Progress`}
          Icon={LuClock4}
          count={taskCountState.inProgress}
          title="In Progress"
          size={useSmall ? "small" : "normal"}
        />
        <TaskCategory
          path={`${urls.TASK_LIST_URL}/Urgent`}
          Icon={HiBellAlert}
          title="Urgent"
          size={useSmall ? "small" : "normal"}
        />
        {siteKeyDoc?.validTaskTypes.includes(OTaskTypes.INSPECTION) && (
          <TaskCategory
            path={`${urls.TASK_LIST_URL}/Inspection`}
            Icon={MdIcons.MdCheckCircle}
            title="Inspections"
            size={useSmall ? "small" : "normal"}
          />
        )}
        {siteKeyDoc?.validTaskStatusCodes.includes(
          OTaskStatus.AWAITING_REVIEW,
        ) && (
          <TaskCategory
            path={`${urls.TASK_LIST_URL}/Awaiting Review`}
            Icon={MdIcons.MdOutlineTimelapse}
            title="Awaiting Review"
            size={useSmall ? "small" : "normal"}
          />
        )}
        {siteKeyDoc?.validTaskStatusCodes.includes(
          OTaskStatus.AWAITING_PARTS,
        ) && (
          <TaskCategory
            path={`${urls.TASK_LIST_URL}/Awaiting Parts`}
            Icon={MdIcons.MdOutlineTimelapse}
            count={taskCountState.awaitingParts}
            title="Awaiting Parts"
            size={useSmall ? "small" : "normal"}
          />
        )}
        <TaskCategory
          path={`${urls.TASK_LIST_URL}/Backlog`}
          Icon={MdIcons.MdOutlineTimelapse}
          title="Backlog"
          size={useSmall ? "small" : "normal"}
        />
        <TaskCategory
          path={`${urls.TASK_LIST_URL}/Completed`}
          Icon={RiCheckDoubleLine}
          title="Completed"
          size={useSmall ? "small" : "normal"}
        />
      </>
    );
  };

  // Check if Stilton is enabled for the site, if not, use "original" TaskCategories
  // and don't show Stilton components
  const isStiltonEnabled = siteKeyDoc?.customizations?.stilton?.openAIKey;
  if (!isStiltonEnabled) {
    return (
      <div className="container mx-auto max-w-7xl">
        <div className="grid grid-cols-1 gap-6 px-4 xs:grid-cols-2 xs:px-2 sm:px-0 md:grid-cols-3 lg:grid-cols-4">
          <TaskCategories />
        </div>
      </div>
    );
  }

  return (
    <div className="flex max-w-screen-xl flex-col gap-4">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        {/* Stilton - Yesterday's Activity Overview Section */}
        <div className="h-96 max-h-[30vh] md:h-[700px] md:max-h-[60vh]">
          <StiltonActivityOverview
            headerText={`${STILTON.NAME} - ${STILTON.ACTIVITY_YESTERDAY}`}
            isLoadingOverview={loadingOverviewState.yesterday}
            onRefreshOverview={handleGetYesterdaysOverview}
            overviewContent={yesterdaysOverviewText}
          />
        </div>

        {/* Stilton - Today's Activity Overview Section */}
        <div className="h-96 max-h-[30vh] md:h-[700px] md:max-h-[60vh]">
          <StiltonActivityOverview
            headerText={`${STILTON.NAME} - ${STILTON.ACTIVITY_TODAY}`}
            isLoadingOverview={loadingOverviewState.today}
            onRefreshOverview={handleGetTodaysOverview}
            overviewContent={todaysOverviewText}
          />
        </div>
      </div>

      {/* Task Categories Section */}
      <div className="grid grid-cols-1 gap-4 xxs:grid-cols-2 xs:grid-cols-3 md:grid-cols-4 lg:grid-cols-6">
        <TaskCategories useSmall />
      </div>
    </div>
  );
}

type TaskCategoryProps = {
  path: string;
  Icon: React.ElementType;
  /** if omitted, "normal" is used */
  size?: "small" | "normal";
  count?: number;
  title: string;
};

function TaskCategory(props: TaskCategoryProps) {
  const Icon = props.Icon;
  const size = props.size ?? "normal";
  return (
    <div className="relative">
      <IconContext.Provider value={{ color: "#757575" }}>
        <Link
          to={props.path}
          className={`${size === "small" ? "py-3" : "py-6"} flex flex-col items-center justify-center rounded-lg border border-gray-200 bg-white shadow-md hover:bg-gray-100`}
        >
          <Icon
            className={`inline-block ${size === "small" ? "h-10 w-10" : "h-16 w-16"}`}
          />
          {props.count ? (
            <span
              className={`${size === "small" ? "text-base" : "text-xl"} absolute right-0.5 top-0.5 rounded-full bg-red-500 px-2 py-1 font-bold leading-none text-white`}
            >
              {props.count}
            </span>
          ) : null}
          <hr
            className={`${size === "small" ? "my-3" : "my-6"} w-full border border-gray-200`}
          />
          <h5
            className={`${size === "normal" && "my-2"} px-3 text-center text-lg font-bold tracking-tight text-gray-900`}
          >
            {props.title}
          </h5>
        </Link>
      </IconContext.Provider>
    </div>
  );
}
