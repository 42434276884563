//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

//Local
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseButtonSecondary from "../BaseButtonSecondary";
import { DollarCurrency } from "../../currency";
import HeadingOne from "../HeadingOne";

export interface MultiPayCreditCardDialogProps {
  /* DATA */
  isDialogOpen: boolean;
  amountDue: number;
  /* FUNCTIONS */
  closeDialog: () => void;
  onSubmitPayment: () => Promise<void>;
  children: {
    multiInvoiceList: React.ReactNode;
    multiInvoiceMessage: React.ReactNode;
  };
}

export default function MultiPayCreditCardDialog(
  props: MultiPayCreditCardDialogProps,
) {
  const [
    isBusyGettingCreditCardPaymentLink,
    setIsBusyGettingCreditCardPaymentLink,
  ] = useState<boolean>(false);

  const header = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <HeadingOne fontColor="text-white" fontSize="text-xl">
        {strings.MULTI_PAY_CC_HEADER}
      </HeadingOne>
      <button type="button" onClick={() => props.closeDialog()}>
        <XMarkIcon
          aria-label="close pay multiple invoices by credit card dialog"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  function closeAndReset() {
    props.closeDialog();
  }

  return (
    <BaseModal
      closeModal={closeAndReset}
      open={props.isDialogOpen}
      title={header}
      parentDivStyles="transform max-w-screen-md rounded-lg bg-white text-left align-middle shadow-xl transition-all overflow-visible"
    >
      <div className="relative flex flex-col space-y-8 px-8 pb-8 pt-2 text-lg">
        <div className="text-xl font-light">
          Amount Due:{" "}
          <span className="text-2xl font-bold">
            {DollarCurrency.format(props.amountDue)}
          </span>
        </div>

        {/* Invoices */}
        <div className="relative flex flex-wrap items-center gap-4">
          {props.children.multiInvoiceList}
          {props.children.multiInvoiceMessage}
        </div>

        {/* Action Buttons */}
        <div className="col-span-2 col-start-1 flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
          <BaseButtonSecondary
            type="button"
            className="w-full justify-center uppercase"
            onClick={closeAndReset}
          >
            {strings.buttons.CANCEL}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            formNoValidate
            isBusy={isBusyGettingCreditCardPaymentLink}
            busyText={strings.buttons.BUSY_GENERATING}
            className="w-full justify-center uppercase"
            onClick={async () => {
              setIsBusyGettingCreditCardPaymentLink(true);
              await props.onSubmitPayment();
              setIsBusyGettingCreditCardPaymentLink(false);
            }}
          >
            {strings.buttons.NEXT}
          </BaseButtonPrimary>
        </div>
      </div>
    </BaseModal>
  );
}
