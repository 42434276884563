import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/24/solid";

interface SearchableDropdownProps {
  items: { id: string; title: string; description?: string | null }[];
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  label?: string;
  required?: boolean;
}

export default function SearchableDropdown({
  items,
  value,
  onChange,
  placeholder,
  label,
  required = false,
}: SearchableDropdownProps): JSX.Element {
  const [query, setQuery] = useState("");
  const selectedItem = items.find((item) => item.id === value);
  const displayValue = selectedItem?.title || placeholder;

  const filteredItems = items.filter((item) => {
    const searchStr = query.toLowerCase();
    return (
      item.title.toLowerCase().includes(searchStr) ||
      (item.description?.toLowerCase() || "").includes(searchStr)
    );
  });

  return (
    <Listbox value={value} onChange={onChange}>
      <div className="relative mt-1">
        <div className="mb-1 text-sm font-medium text-gray-700">
          {label}
          {required && <span className="ml-1 text-red-500">*</span>}
        </div>
        <Listbox.Button className="relative h-10 w-full cursor-pointer rounded-md border border-primary bg-white py-2 pl-3 pr-10 text-left outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primaryLight">
          <span className="block truncate">{displayValue}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon
              className="h-5 w-5 text-gray-700"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-[100] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <div className="sticky top-0 z-[101] bg-white px-3 py-2">
              <input
                type="text"
                className="w-full rounded-md border border-gray-300 px-3 py-1 text-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary"
                placeholder="Search..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
            {filteredItems.map((item) => (
              <Listbox.Option
                key={item.id}
                className={({ active }) =>
                  `relative cursor-default select-none py-1 pl-3 pr-9 ${
                    active
                      ? "bg-primaryOpacity90 text-primary"
                      : "text-gray-900"
                  }`
                }
                value={item.id}
              >
                {({ selected, active }) => (
                  <>
                    <div>
                      <span
                        className={`block truncate font-semibold text-black`}
                      >
                        {item.title}
                      </span>
                      {item.description && (
                        <span
                          className={`block truncate text-sm text-gray-500`}
                        >
                          {item.description}
                        </span>
                      )}
                    </div>

                    {selected ? (
                      <span
                        className={`absolute inset-y-0 right-0 flex items-center pr-3 ${
                          active ? "text-primary" : "text-primary"
                        }`}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
}
