import { CheckIcon } from "@heroicons/react/24/solid";

interface Step {
  id: string;
  name: string;
  description: string;
}

interface OnboardingProgressProps {
  currentStep: string;
  steps: readonly Step[];
}

export default function OnboardingProgress({
  currentStep,
  steps,
}: OnboardingProgressProps) {
  const getCurrentStepIndex = () => {
    return steps.findIndex((step) => step.id === currentStep);
  };

  const getStepStatus = (stepId: string) => {
    const currentIndex = getCurrentStepIndex();
    const stepIndex = steps.findIndex((step) => step.id === stepId);

    if (stepIndex < currentIndex) return "complete";
    if (stepIndex === currentIndex) return "current";
    return "upcoming";
  };

  return (
    <nav aria-label="Progress">
      <ol role="list" className="space-y-4 md:flex md:space-x-2 md:space-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="md:flex-1">
            <div
              className={`group flex flex-col border-l-4 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4 
                ${
                  getStepStatus(step.id) === "complete"
                    ? "border-primary"
                    : getStepStatus(step.id) === "current"
                      ? "border-primary"
                      : "border-gray-200"
                }`}
            >
              <span className="text-sm font-medium">
                <span className="flex items-center">
                  {getStepStatus(step.id) === "complete" ? (
                    <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-primary">
                      <CheckIcon
                        className="h-4 w-4 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  ) : getStepStatus(step.id) === "current" ? (
                    <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-2 border-primary">
                      <span className="h-2.5 w-2.5 rounded-full bg-primary" />
                    </span>
                  ) : (
                    <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full border-2 border-gray-300">
                      <span className="text-gray-500">{stepIdx + 1}</span>
                    </span>
                  )}
                  <span
                    className={`ml-3 text-sm font-medium ${
                      getStepStatus(step.id) === "complete" ||
                      getStepStatus(step.id) === "current"
                        ? "text-primary"
                        : "text-gray-500"
                    }`}
                  >
                    {step.name}
                  </span>
                </span>
              </span>
              <span
                className={`ml-9 text-sm ${
                  getStepStatus(step.id) === "complete" ||
                  getStepStatus(step.id) === "current"
                    ? "text-gray-700"
                    : "text-gray-500"
                }`}
              >
                {step.description}
              </span>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
}

// Define step configurations
export const SITE_ONBOARDING_STEPS = [
  { id: "company", name: "Company", description: "Business details" },
  { id: "locations", name: "Locations", description: "Office locations" },
  { id: "review", name: "Review", description: "Confirm details" },
] as const;

export const MERCHANT_ONBOARDING_STEPS = [
  { id: "business", name: "Business", description: "" },
  { id: "revenue", name: "Revenue", description: "" },
  { id: "owner", name: "Officer", description: "" },
  { id: "owners", name: "Owners", description: "" },
  { id: "banking", name: "Banking", description: "" },
  { id: "review", name: "Review", description: "" },
] as const;
