//Libs
import { Timestamp } from "firebase/firestore";

//Local
import { convertToReadableTimestamp } from "./convertToReadableTimestamp";
import { flattenObj } from "./flatten";
import { ExistingAsset } from "../../models/asset";
import { DbRead } from "../../database";

export async function formatAssetDataForExcelExport(
  assetList: ExistingAsset[],
  customers: Record<string, string>,
  siteKey: string,
): Promise<Record<string, any>[]> {
  async function renderAddress(customerLocationID: string | undefined) {
    if (customerLocationID == null) {
      return "";
    } else {
      const customerLocation = await DbRead.customerLocations.getSingle(
        siteKey,
        customerLocationID,
      );
      return `${customerLocation.addressLine1} ${customerLocation.addressLine2}, ${customerLocation.city}, ${customerLocation.state} ${customerLocation.zipCode}`;
    }
  }

  const result: Record<string, any>[] = [];
  for (const asset of assetList) {
    const address = await renderAddress(asset.customerLocationID);
    const data = {
      ...flattenObj(asset),
      customerName: customers[asset.customerID || ""] || "",
      address: address,
      installationDate: asset.installationDate
        ? convertToReadableTimestamp(asset.installationDate)
        : "",
      manufacturerWarrantyStart: asset.manufacturerWarrantyStart
        ? convertToReadableTimestamp(asset.manufacturerWarrantyStart)
        : "",
      manufacturerWarrantyEnd: asset.manufacturerWarrantyEnd
        ? convertToReadableTimestamp(asset.manufacturerWarrantyEnd)
        : "",
      serviceWarrantyStart: asset.serviceWarrantyStart
        ? convertToReadableTimestamp(asset.serviceWarrantyStart)
        : "",
      serviceWarrantyEnd: asset.serviceWarrantyEnd
        ? convertToReadableTimestamp(asset.serviceWarrantyEnd)
        : "",
    };
    result.push(data);
  }

  return result;
}
