// Libs
import create from "zustand";

// Local
import { logger } from "../logging";
import { ExistingStiltPhoneCall } from "../models/calls";
import { DbWrite } from "../database";

interface IncomingCallsStore {
  /**
   * A way to mark a particular call Firestore document as the current call. This
   * is only tracked locally on the client and not persisted to the database.
   */
  currentCall: ExistingStiltPhoneCall | null;
  /**
   * Set the current call locally.
   */
  setCurrentCall: (call: ExistingStiltPhoneCall | null) => void;
  /**
   * Clear the current call locally.
   */
  clearCall: () => void;
  /**
   * A convenience method that wraps the more general database update function.
   * Updates the customerID and agentID of the current call ID in the database.
   */
  updateCallCustomer: (customerID: string) => Promise<void>;
}

// Export a hook to access data globally and define initial values.
export const useIncomingCallsStore = create<IncomingCallsStore>((set, get) => {
  return {
    currentCall: null,
    setCurrentCall: (call) => {
      logger.info("setting incoming call", call);
      set({ currentCall: call });
    },
    clearCall: () => {
      logger.info("clearing incoming call");
      set({ currentCall: null });
    },
    updateCallCustomer: async (customerID) => {
      const call = get().currentCall;
      if (!call) {
        logger.error(
          `incoming-calls.ts: Expected call to exist. Unable to update customer ${customerID}`,
        );
        return;
      }

      const currentCallID = call.id;
      const callRefPath = call.refPath;
      const siteKey = callRefPath.split("/")[1];

      await DbWrite.calls.updateCustomerAndAgent({
        callDocID: currentCallID,
        siteKey: siteKey,
        customerID: customerID,
      });

      set({ currentCall: null });
      logger.info(
        `updated call doc, cleared store's current call ${currentCallID}`,
      );
    },
  };
});
