import { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import BaseInputText from "../../../../components/BaseInputText";
import BaseButtonPrimary from "../../../../components/BaseButtonPrimary";
import BaseButtonSecondary from "../../../../components/BaseButtonSecondary";
import StyledMessage from "../../../../components/StyledMessage";
import { formatPhoneNumber, formatSSN } from "../../../../utils/formatters";

interface Owner {
  firstName: string;
  lastName: string;
  title:
    | "Owner"
    | "Partner"
    | "Director"
    | "President"
    | "CEO"
    | "CFO"
    | "Chairman"
    | "Comptroller"
    | "General Manager"
    | "Office Manager"
    | "Treasurer"
    | "Vice President";
  street1: string;
  street2?: string;
  city: string;
  state: string;
  zipCode: string;
  countryCode: string;
  email: string;
  birthDate: string;
  socialSecurity: string;
  phone: string;
  stakePercentage: number;
  driverLicense?: string;
  driverLicenseState?: string;
  driverLicenseCountryCode: string;
}

interface OwnerFormProps {
  initialData?: Owner;
  remainingStake: number;
  onSubmit: (data: Owner) => void;
  onCancel: () => void;
}

const TITLES = [
  "Owner",
  "Partner",
  "Director",
  "President",
  "CEO",
  "CFO",
  "Chairman",
  "Comptroller",
  "General Manager",
  "Office Manager",
  "Treasurer",
  "Vice President",
] as const;

const DEFAULT_OWNER: Owner = {
  firstName: "",
  lastName: "",
  title: "Owner",
  street1: "",
  city: "",
  state: "",
  zipCode: "",
  countryCode: "US",
  email: "",
  birthDate: "",
  socialSecurity: "",
  phone: "",
  stakePercentage: 0,
  driverLicenseCountryCode: "US",
};

export default function OwnerForm({
  initialData,
  remainingStake,
  onSubmit,
  onCancel,
}: OwnerFormProps) {
  const [formData, setFormData] = useState<Owner>(initialData || DEFAULT_OWNER);
  const [error, setError] = useState("");

  const handleAddressSelect = async (selectedAddress: string) => {
    try {
      const results = await geocodeByAddress(selectedAddress);
      const addressComponents = results[0].address_components;

      const street = [
        (addressComponents.find((c) => c.types.includes("street_number")) || {})
          .long_name,
        (addressComponents.find((c) => c.types.includes("route")) || {})
          .long_name,
      ]
        .filter(Boolean)
        .join(" ");

      const city =
        (addressComponents.find((c) => c.types.includes("locality")) || {})
          .long_name || "";
      const state =
        (
          addressComponents.find((c) =>
            c.types.includes("administrative_area_level_1"),
          ) || {}
        ).short_name || "";
      const zipCode =
        (addressComponents.find((c) => c.types.includes("postal_code")) || {})
          .long_name || "";

      setFormData((prev) => ({
        ...prev,
        street1: street || "",
        city,
        state,
        zipCode,
      }));
      setError("");
    } catch (error) {
      setError("Error finding address. Please try again.");
    }
  };

  const handleSSNChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 9);
    const formatted = formatSSN(value);
    setFormData((prev) => ({
      ...prev,
      socialSecurity: formatted,
    }));
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatPhoneNumber(e.target.value);
    setFormData((prev) => ({
      ...prev,
      phone: formatted,
    }));
  };

  const validateForm = (): boolean => {
    if (!formData.firstName || !formData.lastName) {
      setError("Full name is required");
      return false;
    }

    if (
      formData.stakePercentage <= 0 ||
      formData.stakePercentage > remainingStake
    ) {
      setError(`Ownership percentage must be between 1 and ${remainingStake}%`);
      return false;
    }

    const ssnDigits = formData.socialSecurity.replace(/\D/g, "");
    if (ssnDigits.length !== 9) {
      setError("Please enter a valid Social Security Number");
      return false;
    }

    if (!formData.birthDate) {
      setError("Date of birth is required");
      return false;
    }

    if (
      !formData.street1 ||
      !formData.city ||
      !formData.state ||
      !formData.zipCode
    ) {
      setError("Complete address is required");
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setError("Please enter a valid email address");
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setError("");
      onSubmit(formData);
    }
  };

  return (
    <div className="space-y-6 rounded-lg border bg-gray-50 p-6">
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <BaseInputText
          text="First Name"
          value={formData.firstName}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, firstName: e.target.value }))
          }
          required
          admin
        />

        <BaseInputText
          text="Last Name"
          value={formData.lastName}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, lastName: e.target.value }))
          }
          required
          admin
        />
      </div>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <select
          className="block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary"
          value={formData.title}
          onChange={(e) => {
            const selectedTitle = e.target.value as (typeof TITLES)[number];
            setFormData((prev) => ({ ...prev, title: selectedTitle }));
          }}
          required
        >
          <option value="">Select Title</option>
          {TITLES.map((title) => (
            <option key={title} value={title}>
              {title}
            </option>
          ))}
        </select>

        <BaseInputText
          text="Ownership Percentage"
          type="number"
          value={formData.stakePercentage.toString()}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              stakePercentage: Math.min(Number(e.target.value), remainingStake),
            }))
          }
          required
          admin
          min="0"
          max={remainingStake.toString()}
        />
      </div>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <BaseInputText
          text="Social Security Number"
          value={formData.socialSecurity}
          onChange={handleSSNChange}
          required
          admin
          placeholder="XXX-XX-XXXX"
        />

        <BaseInputText
          text="Date of Birth"
          type="date"
          value={formData.birthDate}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              birthDate: e.target.value,
            }))
          }
          required
          admin
          max={new Date().toISOString().split("T")[0]}
        />
      </div>

      <div className="space-y-4">
        <label className="block text-sm font-medium text-gray-700">
          Home Address
        </label>
        <PlacesAutocomplete
          value={formData.street1}
          onChange={(address) =>
            setFormData((prev) => ({
              ...prev,
              street1: address,
            }))
          }
          onSelect={handleAddressSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <BaseInputText
                {...getInputProps({
                  text: "Street Address",
                  required: true,
                  admin: true,
                })}
              />
              <div
                className={
                  suggestions.length > 0 || loading
                    ? "absolute z-10 mt-1 w-full rounded-md border bg-white shadow-lg"
                    : ""
                }
              >
                {loading && <div className="p-2 text-gray-500">Loading...</div>}
                {suggestions.map((suggestion, index) => {
                  const suggestionProps = getSuggestionItemProps(suggestion, {
                    className: `cursor-pointer p-2 text-sm ${
                      suggestion.active
                        ? "bg-primary text-white"
                        : "text-gray-900"
                    }`,
                  });
                  return (
                    <div {...suggestionProps} key={`suggestion-${index}`}>
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </PlacesAutocomplete>

        <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
          <BaseInputText
            text="City"
            value={formData.city}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                city: e.target.value,
              }))
            }
            required
            admin
          />

          <BaseInputText
            text="State"
            value={formData.state}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                state: e.target.value,
              }))
            }
            required
            admin
          />

          <BaseInputText
            text="ZIP Code"
            value={formData.zipCode}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                zipCode: e.target.value,
              }))
            }
            required
            admin
          />
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <BaseInputText
          text="Phone Number"
          value={formData.phone}
          onChange={handlePhoneChange}
          required
          admin
          placeholder="(XXX) XXX-XXXX"
        />

        <BaseInputText
          text="Email Address"
          value={formData.email}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, email: e.target.value }))
          }
          required
          admin
          type="email"
        />
      </div>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <BaseInputText
          text="Driver's License Number"
          value={formData.driverLicense || ""}
          onChange={(e) =>
            setFormData((prev) => ({ ...prev, driverLicense: e.target.value }))
          }
          admin
        />

        <BaseInputText
          text="Driver's License State"
          value={formData.driverLicenseState || ""}
          onChange={(e) =>
            setFormData((prev) => ({
              ...prev,
              driverLicenseState: e.target.value,
            }))
          }
          admin
        />
      </div>

      {error && (
        <StyledMessage type="error">{{ message: error }}</StyledMessage>
      )}

      <div className="flex justify-end space-x-3">
        <BaseButtonSecondary type="button" onClick={onCancel}>
          Cancel
        </BaseButtonSecondary>
        <BaseButtonPrimary type="button" onClick={handleSubmit}>
          {initialData ? "Update Owner" : "Add Owner"}
        </BaseButtonPrimary>
      </div>
    </div>
  );
}
