import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
// Local
import { useActiveCallStore } from "../../store/active-call";
import { DbRead } from "../../database";
import { ExistingCustomer } from "../../models/customer";
import { logger } from "../../logging";
import BubbleActiveCall from "./BubbleActiveCall";
import { StyledTooltip } from "../StyledTooltip";
import { ButtonViewCustomer } from "../ButtonViewCustomer";
import { ButtonIconCreateJob } from "../ButtonIconCreateJob";
import { ButtonIconOpenInNewTab } from "../ButtonIconOpenInNewTab";
import { CUSTOMERS_CREATE_TASK_URL, CUSTOMERS_URL } from "../../urls";
import { useStiltNav } from "../../navigation";
import { useSearchParams } from "react-router-dom";

interface Props {
  siteKey: string;
}

export default function GlobalCallManager({ siteKey }: Props): JSX.Element {
  const {
    activeCall,
    clearActiveCall,
    fetch: fetchActiveCall,
  } = useActiveCallStore();
  const [searchParams] = useSearchParams();
  const paramsCallID = searchParams.get("activeCall");

  const [customer, setCustomer] = useState<ExistingCustomer | null>(null);
  const stiltNav = useStiltNav();

  useEffect(() => {
    if (!paramsCallID) return;
    fetchActiveCall(siteKey, paramsCallID);
  }, [fetchActiveCall, paramsCallID, siteKey]);

  useEffect(() => {
    function subscribeToCustomer() {
      if (!activeCall || typeof activeCall.customerID !== "string") {
        setCustomer(null);
        return undefined;
      }
      const listener = DbRead.customers.subscribe({
        siteKey,
        customerID: activeCall.customerID,
        onChange: setCustomer,
        onError: (error) => {
          logger.error("Error fetching customer in GlobalCallManager:", error);
          setCustomer(null);
        },
      });
      return listener;
    }
    const unsubscribe = subscribeToCustomer();
    return () => unsubscribe && unsubscribe();
  }, [activeCall, siteKey]);

  // Clear the active call when the call ends --
  // maybe this should be done in the store..
  const callDocID = activeCall?.id;
  useEffect(() => {
    function subscribeToActiveCall() {
      if (!callDocID) return undefined;
      const unsubscribeFn = DbRead.calls.subscribe({
        siteKey: siteKey,
        docID: callDocID,
        onChange: (call) => {
          if (
            call?.callStatus === "canceled" ||
            call?.callStatus === "completed" ||
            call?.callStatus === "failed" ||
            call?.callStatus === "no-answer" ||
            call?.callStatus === "cleared"
          ) {
            clearActiveCall();
          }
        },
        onError: logger.error,
      });
      return unsubscribeFn;
    }
    const unsubscribe = subscribeToActiveCall();
    return () => unsubscribe && unsubscribe();
  }, [callDocID, clearActiveCall, siteKey]);

  if (!activeCall || !customer) return <></>;

  const portalContent = (
    <div className="fixed left-5 top-20 z-[100] h-0 w-0">
      <BubbleActiveCall>
        <p className="truncate text-center">{customer.name}</p>
        <span className="block text-center text-sm font-medium">
          {activeCall.caller || "[unknown number]"}
        </span>

        {/* ACTION ICON-BUTTONS */}
        <div className="flex items-center justify-around gap-5">
          <div className="flex items-end gap-1">
            <StyledTooltip title="View Customer">
              <ButtonViewCustomer
                onClick={() => stiltNav.customerPage(customer.id)}
                overrideButtonClasses="rounded-full text-sky-700 bg-sky-50 transition-colors border border-sky-800/60 hover:bg-white"
                overrideIconStyles={{
                  padding: "4px",
                  height: "30px",
                  width: "30px",
                }}
              />
            </StyledTooltip>
            <StyledTooltip title="Open customer page in new tab">
              <ButtonIconOpenInNewTab
                href={`${CUSTOMERS_URL}/${customer.id}?activeCall=${activeCall.id}`}
                ariaLabel="View customer in new tab"
                overrideAnchorClasses="rounded-full text-sky-700 bg-sky-50 transition-colors border border-sky-800/20 hover:bg-white"
                overrideIconStyles={{
                  padding: "2px",
                  height: "20px",
                  width: "20px",
                }}
              />
            </StyledTooltip>
          </div>

          <div className="flex items-end gap-1">
            <StyledTooltip title="Create Job">
              <ButtonIconCreateJob
                onClick={() => {
                  stiltNav.createTaskForCustomer({
                    customerID: customer.id,
                  });
                }}
                overrideButtonClasses="rounded-full text-sky-700 bg-sky-50 transition-colors border border-sky-800/60 hover:bg-white"
                overrideIconStyles={{ height: "32px", width: "32px" }}
              />
            </StyledTooltip>
            <StyledTooltip title="Open create job page in new tab">
              <ButtonIconOpenInNewTab
                href={`${CUSTOMERS_CREATE_TASK_URL}/${customer.id}?activeCall=${activeCall.id}`}
                ariaLabel="Create job in new tab"
                overrideAnchorClasses="rounded-full text-sky-700 bg-sky-50 transition-colors border border-sky-800/20 hover:bg-white"
                overrideIconStyles={{
                  padding: "2px",
                  height: "20px",
                  width: "20px",
                }}
              />
            </StyledTooltip>
          </div>
        </div>
      </BubbleActiveCall>
    </div>
  );

  return createPortal(portalContent, document.body);
}
