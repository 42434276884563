//Libs
import {
  CellContextMenuEvent,
  ColDef,
  GetQuickFilterTextParams,
  GridApi,
  ICellRendererParams,
  RowClickedEvent,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useLayoutEffect, useRef, useState } from "react";
import React from "react";
import isEqual from "lodash/isEqual";

//Local
import { ExistingTask } from "../../models/task";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import SearchBox from "../SearchBox";
import { WORK_RECORD_AND_TASKS_URL } from "../../urls";
import { ExistingCraftRecord } from "../../models/craft-record";

// Styles
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";

interface CustomerJobListTableProps {
  jobsTableData: ExistingCraftRecord[];
  goToWorkRecordAndTasksPage: (
    craftRecordID: ExistingTask["craftRecordID"],
  ) => void;
}

const commonColProps: ColDef = {
  width: 150,
  flex: 1,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass:
    "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",
  cellStyle: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
};

const CustomerJobListTable = React.memo(
  ({
    jobsTableData,
    goToWorkRecordAndTasksPage,
  }: CustomerJobListTableProps) => {
    const [gridReady, setGridReady] = useState(false);
    const gridRef = useRef<AgGridReact>(null);

    const onGridReady = useCallback(() => {
      setGridReady(true);
    }, []);

    useLayoutEffect(() => {
      if (gridRef.current && gridReady) {
        const api: GridApi = gridRef.current.api;
        api?.sizeColumnsToFit();
      }
    }, [gridReady]);

    const onFilterTextBoxChanged = useCallback(() => {
      gridRef.current?.api?.setGridOption(
        "quickFilterText",
        (document.getElementById("filter-text-box") as HTMLInputElement).value,
      );
    }, []);

    const renderTitleAndDescription = (
      params: ICellRendererParams,
    ): JSX.Element => {
      return (
        <div className="my-2 flex items-center">
          <div className="flex flex-col space-y-2">
            <span className="block whitespace-normal text-base font-medium capitalize leading-5 text-primary">
              {params.data.title}
            </span>
            <span className="block whitespace-normal text-sm leading-5 text-gray-400">
              {params.data.description}
            </span>
          </div>
        </div>
      );
    };

    const tableColumnsDefs: ColDef[] = [
      {
        headerName: "Title",
        cellRenderer: renderTitleAndDescription,
        getQuickFilterText: (params: GetQuickFilterTextParams) => {
          return params.data.title + " " + params.data.description;
        },
        comparator(_valueA, _valueB, nodeA, nodeB) {
          const titleA = nodeA.data.title;
          const titleB = nodeB.data.title;
          if (titleA === titleB) return 0;
          return titleA > titleB ? 1 : -1;
        },
        autoHeight: true,
        minWidth: 300,
        flex: 2,
      },
      {
        field: "timestampRecordCreated",
        headerName: "Created",
        cellRenderer: (params: ICellRendererParams) =>
          getReadableTimestamp(params.data.timestampRecordCreated),
        getQuickFilterText: (params: GetQuickFilterTextParams) =>
          getReadableTimestamp(params.data.timestampRecordCreated),
      },
      {
        field: "numOpenTasks",
        headerName: "Open Tasks",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        field: "numClosedTasks",
        headerName: "Closed Tasks",
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
    ];

    return (
      <div>
        <div className="flex flex-col-reverse items-center gap-4 sm:flex-row sm:items-end sm:justify-between">
          <SearchBox onInput={onFilterTextBoxChanged} />
        </div>
        <div className="ag-theme-alpine h-[300px]">
          <AgGridReact
            reactiveCustomComponents
            ref={gridRef}
            onGridReady={onGridReady}
            defaultColDef={commonColProps}
            className="mt-5 shadow"
            rowData={jobsTableData.sort((a, b) => {
              return (
                a.timestampRecordCreated.toMillis() -
                b.timestampRecordCreated.toMillis()
              );
            })}
            animateRows={true}
            rowSelection="single"
            columnDefs={tableColumnsDefs}
            onRowClicked={(event: RowClickedEvent) => {
              goToWorkRecordAndTasksPage(event.data.id);
            }}
            onCellContextMenu={(event: CellContextMenuEvent<any, any>) => {
              const craftRecordID = event.data.craftRecordID.split("/")[3];
              const url = `${WORK_RECORD_AND_TASKS_URL}/${craftRecordID}`;
              return window.open(url, "_blank");
            }}
          />
        </div>
      </div>
    );
  },
  (previous, next) => {
    const isListTheSame = isEqual(previous.jobsTableData, next.jobsTableData);

    return isListTheSame;
  },
);

export default CustomerJobListTable;

function getReadableTimestamp(timestamp: unknown): string {
  if (timestamp != null) {
    return convertToReadableTimestamp(timestamp);
  } else {
    return "";
  }
}
