import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Divider,
} from "@mui/material";
import { DateTime } from "luxon";
import { ExistingStiltInvoice, StiltInvoiceStatus } from "../../models/invoice";
import { QBOInvoiceResponse } from "../../Pages/Quickbooks/QuickbooksContainer";
import { DbRead, DbWrite, hitAcctSync } from "../../database";
import { useToastMessageStore } from "../../store/toast-messages";
import { convertFSTimestampToLuxonDT, createToastMessageID } from "../../utils";
import { useAuthStore } from "../../store/firebase-auth";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import * as strings from "../../strings";
import BaseModal from "../BaseModal";
import { AccountingSyncStatus } from "../../models/accounting-sync";

interface CompareInvoiceDialogProps {
  isOpen: boolean;
  onClose: () => void;
  siteKey: string;
  stiltInvoice: ExistingStiltInvoice;
}

export default function CompareInvoiceDialog({
  isOpen,
  onClose,
  siteKey,
  stiltInvoice,
}: CompareInvoiceDialogProps) {
  const [qboInvoice, setQboInvoice] = useState<QBOInvoiceResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRetrying, setIsRetrying] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const addMessage = useToastMessageStore((state) => state.addToastMessage);
  const firebaseUser = useAuthStore((state) => state.firebaseUser);

  useEffect(() => {
    async function fetchQBOInvoice() {
      if (!stiltInvoice || !stiltInvoice.invoiceNumber) return;

      setIsLoading(true);
      try {
        const quickbooksInvoice =
          await DbRead.invoices.getQuickbooksInvoiceByInvoiceNumber(
            siteKey,
            stiltInvoice.invoiceNumber,
          );

        setQboInvoice(quickbooksInvoice);
      } catch (error) {
        console.error("Error fetching QBO invoice:", error);
        addMessage({
          id: createToastMessageID(),
          dialog: true,
          message: "Failed to fetch QuickBooks invoice data",
          type: "error",
        });
      } finally {
        setIsLoading(false);
      }
    }

    if (isOpen) {
      fetchQBOInvoice();
    }
  }, [isOpen, siteKey, stiltInvoice, addMessage]);

  const handleRetrySync = async () => {
    setIsRetrying(true);
    try {
      const refPathList = [stiltInvoice.refPath];
      const result = await hitAcctSync({ siteKeyID: siteKey, refPathList });
      console.log("result", result);
      if (result && result.length > 0) {
        addMessage({
          id: createToastMessageID(),
          dialog: true,
          message: "Failed to retry sync",
          type: "error",
        });
      } else {
        console.log("success");
        addMessage({
          id: createToastMessageID(),
          dialog: true,
          message: "Sync retry initiated successfully",
          type: "success",
        });
      }
    } catch (error) {
      console.error("Error retrying sync:", error);
      addMessage({
        id: createToastMessageID(),
        dialog: true,
        message: "Failed to retry sync",
        type: "error",
      });
    } finally {
      setIsRetrying(false);
      onClose();
    }
  };

  const handleConfirmSameInvoice = async () => {
    if (!qboInvoice || !firebaseUser) return;

    setIsConfirming(true);
    try {
      await DbWrite.invoices.confirmSameQBOInvoice({
        uid: firebaseUser.uid,
        siteKeyID: siteKey,
        stiltInvoiceID: stiltInvoice.id,
        qboInvoiceID: qboInvoice.id,
      });
      addMessage({
        id: createToastMessageID(),
        dialog: true,
        message: "Invoices confirmed as matching",
        type: "success",
      });
      onClose();
    } catch (error) {
      console.error("Error confirming invoices:", error);
      addMessage({
        id: createToastMessageID(),
        dialog: true,
        message: "Failed to confirm invoices",
        type: "error",
      });
    } finally {
      setIsConfirming(false);
    }
  };

  const formatDate = (dateString: string) => {
    try {
      return DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_SHORT);
    } catch (e) {
      return dateString;
    }
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(amount);
  };

  return (
    <BaseModal
      open={isOpen}
      closeModal={onClose}
      title={<></>}
      parentDivStyles="text-left max-w-2xl lg:max-w-screen-xl"
    >
      <DialogTitle
        id="compare-invoice-dialog-title"
        className="text-center text-xl font-bold"
      >
        Compare Invoice: {stiltInvoice?.invoiceNumber}
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box
                  sx={{
                    backgroundColor: "#f5f5f5",
                    borderRadius: "8px",
                    padding: "16px",
                    height: "100%",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  }}
                >
                  <Box display="flex" alignItems="center" mb={2}>
                    <img
                      src="/black_yellow_hardhat_transparent_bg.svg"
                      alt="Stilt Logo"
                      style={{ height: "40px", marginRight: "12px" }}
                    />
                    <Typography variant="h6" fontWeight="bold">
                      Stilt Invoice
                    </Typography>
                  </Box>
                  <Divider sx={{ mb: 2 }} />
                  <Box
                    mb={2}
                    sx={{
                      backgroundColor: "white",
                      padding: "12px",
                      borderRadius: "4px",
                    }}
                  >
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      <strong>Invoice #:</strong> {stiltInvoice?.invoiceNumber}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      <strong>Customer:</strong> {stiltInvoice?.name}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      <strong>Date:</strong>{" "}
                      {convertFSTimestampToLuxonDT(
                        stiltInvoice.issueDate,
                      ).toLocaleString(DateTime.DATE_SHORT)}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      <strong>Due Date:</strong>{" "}
                      {stiltInvoice.dueDate
                        ? convertFSTimestampToLuxonDT(
                            stiltInvoice.dueDate,
                          ).toLocaleString(DateTime.DATE_SHORT)
                        : "N/A"}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      <strong>Total:</strong>{" "}
                      {formatCurrency(stiltInvoice.totalAmount)}
                    </Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                      <strong>Balance:</strong>{" "}
                      {formatCurrency(stiltInvoice.amountDue)}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Status:</strong>{" "}
                      <span
                        style={{
                          backgroundColor:
                            stiltInvoice.status === StiltInvoiceStatus.PAID
                              ? "#e6f4ea"
                              : "#fce8e6",
                          color:
                            stiltInvoice.status === StiltInvoiceStatus.PAID
                              ? "#137333"
                              : "#c5221f",
                          padding: "2px 8px",
                          borderRadius: "4px",
                          fontWeight: "bold",
                        }}
                      >
                        {stiltInvoice.status}
                      </span>
                    </Typography>
                  </Box>
                  <TableContainer component={Paper} sx={{ mb: 2 }}>
                    <Table size="small">
                      <TableHead sx={{ backgroundColor: "#f0f0f0" }}>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Description
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            Qty
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            Unit Price
                          </TableCell>
                          <TableCell align="right" sx={{ fontWeight: "bold" }}>
                            Amount
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {stiltInvoice?.lineItems?.map((item, index) => (
                          <TableRow
                            key={`stilt-${index}`}
                            sx={{
                              "&:nth-of-type(odd)": {
                                backgroundColor: "#fafafa",
                              },
                            }}
                          >
                            <TableCell>
                              <Typography variant="body2" fontWeight="bold">
                                {item.estimateItemTitle}
                              </Typography>
                              <Typography variant="caption" display="block">
                                {item.estimateItemDescription}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              {item.quantity || 1}
                            </TableCell>
                            <TableCell align="right">
                              {item.unitPrice
                                ? formatCurrency(item.unitPrice)
                                : "N/A"}
                            </TableCell>
                            <TableCell align="right">
                              {formatCurrency(item.subTotal)}
                            </TableCell>
                          </TableRow>
                        ))}
                        {stiltInvoice.totalTaxAmount > 0 && (
                          <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                            <TableCell colSpan={3} align="right">
                              <strong>Tax:</strong>
                            </TableCell>
                            <TableCell align="right">
                              {formatCurrency(stiltInvoice.totalTaxAmount)}
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow sx={{ backgroundColor: "#f0f0f0" }}>
                          <TableCell colSpan={3} align="right">
                            <strong>Total:</strong>
                          </TableCell>
                          <TableCell align="right">
                            <strong>
                              {formatCurrency(stiltInvoice.totalAmount)}
                            </strong>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {stiltInvoice.accountingSync?.syncStatus !==
                    AccountingSyncStatus.SYNCED && (
                    <div className="flex flex-col gap-2 py-2">
                      <BaseButtonPrimary
                        onClick={handleRetrySync}
                        disabled={isRetrying}
                        isBusy={isRetrying}
                      >
                        {stiltInvoice.accountingSync?.statusMessage ===
                        strings.DUPLICATE_INVOICE_NUM
                          ? `Create New Invoice In Quickbooks as ${stiltInvoice.invoiceNumber}-s`
                          : "Retry Sync"}
                      </BaseButtonPrimary>
                      {qboInvoice && (
                        <BaseButtonPrimary
                          onClick={handleConfirmSameInvoice}
                          isBusy={isConfirming}
                        >
                          Confirm Same Invoice
                        </BaseButtonPrimary>
                      )}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box
                  sx={{
                    backgroundColor: "#f8f8f8",
                    borderRadius: "8px",
                    padding: "16px",
                    height: "100%",
                    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                  }}
                >
                  <Box display="flex" alignItems="center" mb={2}>
                    <img
                      src="/quickbooks-2.svg"
                      alt="QuickBooks Logo"
                      style={{ height: "40px", marginRight: "12px" }}
                    />
                    <Typography variant="h6" fontWeight="bold">
                      QuickBooks Invoice
                    </Typography>
                  </Box>
                  <Divider sx={{ mb: 2 }} />
                  {qboInvoice ? (
                    <>
                      <Box
                        mb={2}
                        sx={{
                          backgroundColor: "white",
                          padding: "12px",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography variant="body2" sx={{ mb: 1 }}>
                          <strong>Invoice #:</strong> {qboInvoice.invoiceNumber}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                          <strong>Customer:</strong> {qboInvoice.customerName}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                          <strong>Date:</strong> {formatDate(qboInvoice.date)}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                          <strong>Due Date:</strong>{" "}
                          {formatDate(qboInvoice.dueDate)}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                          <strong>Total:</strong>{" "}
                          {formatCurrency(qboInvoice.total)}
                        </Typography>
                        <Typography variant="body2" sx={{ mb: 1 }}>
                          <strong>Balance:</strong>{" "}
                          {formatCurrency(qboInvoice.balance)}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Status:</strong>{" "}
                          <span
                            style={{
                              backgroundColor:
                                qboInvoice.status === "PAID"
                                  ? "#e6f4ea"
                                  : "#fce8e6",
                              color:
                                qboInvoice.status === "PAID"
                                  ? "#137333"
                                  : "#c5221f",
                              padding: "2px 8px",
                              borderRadius: "4px",
                              fontWeight: "bold",
                            }}
                          >
                            {qboInvoice.status}
                          </span>
                        </Typography>
                      </Box>
                      <TableContainer component={Paper}>
                        <Table size="small">
                          <TableHead sx={{ backgroundColor: "#f0f0f0" }}>
                            <TableRow>
                              <TableCell sx={{ fontWeight: "bold" }}>
                                Description
                              </TableCell>
                              <TableCell
                                align="right"
                                sx={{ fontWeight: "bold" }}
                              >
                                Qty
                              </TableCell>
                              <TableCell
                                align="right"
                                sx={{ fontWeight: "bold" }}
                              >
                                Unit Price
                              </TableCell>
                              <TableCell
                                align="right"
                                sx={{ fontWeight: "bold" }}
                              >
                                Amount
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {qboInvoice.lineItems.map((item, index) => (
                              <TableRow
                                key={`qbo-${index}`}
                                sx={{
                                  "&:nth-of-type(odd)": {
                                    backgroundColor: "#fafafa",
                                  },
                                }}
                              >
                                <TableCell>
                                  <Typography variant="body2" fontWeight="bold">
                                    {item.itemName}
                                  </Typography>
                                  <Typography variant="caption" display="block">
                                    {item.description}
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  {item.quantity || 1}
                                </TableCell>
                                <TableCell align="right">
                                  {item.unitPrice
                                    ? formatCurrency(item.unitPrice)
                                    : "N/A"}
                                </TableCell>
                                <TableCell align="right">
                                  {formatCurrency(item.amount)}
                                </TableCell>
                              </TableRow>
                            ))}
                            {qboInvoice.taxTotal && qboInvoice.taxTotal > 0 && (
                              <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                                <TableCell colSpan={3} align="right">
                                  <strong>Tax:</strong>
                                </TableCell>
                                <TableCell align="right">
                                  {formatCurrency(qboInvoice.taxTotal)}
                                </TableCell>
                              </TableRow>
                            )}
                            <TableRow sx={{ backgroundColor: "#f0f0f0" }}>
                              <TableCell colSpan={3} align="right">
                                <strong>Total:</strong>
                              </TableCell>
                              <TableCell align="right">
                                <strong>
                                  {formatCurrency(qboInvoice.total)}
                                </strong>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      height="70%"
                      p={4}
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        border: "1px dashed #ccc",
                      }}
                    >
                      <img
                        src="/quickbooks-2.svg"
                        alt="QuickBooks Logo"
                        style={{
                          height: "60px",
                          opacity: 0.5,
                          marginBottom: "16px",
                        }}
                      />
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        align="center"
                        sx={{ fontWeight: "medium" }}
                      >
                        No matching invoice found in QuickBooks with invoice
                        number <strong>{stiltInvoice?.invoiceNumber}</strong>
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Box mt={4}>
              <Divider />
              <Box display="flex" justifyContent="flex-end" mt={2} gap={2}>
                <BaseButtonSecondary onClick={onClose}>
                  Close
                </BaseButtonSecondary>
              </Box>
            </Box>
          </>
        )}
      </DialogContent>
    </BaseModal>
  );
}
