//Libs
import cloneDeep from "lodash/cloneDeep";
import { DateTime } from "luxon";

//Local
import { ExistingTask } from "../../../models/task";
import { ExistingVehicle } from "../../../models/vehicle";
import {
  allocateResourcesToTasksVROOM,
  OptimizedTasks,
} from "./allocateResourcesToTasksVROOM";
import { ExistingSiteKeyLocation } from "../../../models/site-key-location";
import { ExistingStiltCalendarEvent } from "../../../models/stilt-calendar-event";
import {
  getTaskSpecificDetailsString,
  TaskTypes,
} from "../../../models/task-types";
import { Timestamp } from "firebase/firestore";
import {
  SmartSchedulingFormValues,
  SmartSchedulingOption,
} from "../../../models/smart-scheduling-options";
import { DbRead } from "../../../database";

export async function allocateTask(args: {
  siteKeyID: string;
  vehicleList: ExistingVehicle[];
  scheduledTaskList: ExistingTask[];
  currentDateString: string;
  siteKeyWorkTypes: number[];
  siteKeyTaskTypes: number[];
  getLocationDoc: (locationID: string) => ExistingSiteKeyLocation;
  // stiltEvents: ExistingStiltCalendarEvent[];
  smartSchedulingSettings: SmartSchedulingFormValues;
  originalTotalDistanceMiles: number;
  lockAllVehicles: boolean;
}): Promise<OptimizedTasks> {
  if (args.vehicleList.length === 0 || args.scheduledTaskList.length === 0) {
    return {
      vroomOutput: null,
      vroomRoutesMap: {},
      tasks: [],
      unassigned: [],
      stats: {
        originalTotalDistanceMiles: args.originalTotalDistanceMiles,
        unassignedCount: 0,
        unassignedReasons: [],
      },
    };
  }

  const allTasks: ExistingTask[] = cloneDeep(args.scheduledTaskList);
  // Filter only tasks on the day we want to see
  let filteredTasks = allTasks.filter((task) => {
    const timestampScheduled = task.timestampScheduled;
    const dateTimeScheduled = timestampScheduled
      ? DateTime.fromMillis(timestampScheduled.toMillis())
      : null;
    return dateTimeScheduled
      ? dateTimeScheduled.toISODate() === args.currentDateString
      : false;
  });

  // If lockVehicles is true, set lockAssignedVehicle to true for all tasks
  if (args.lockAllVehicles === true) {
    filteredTasks = filteredTasks.map((task) => ({
      ...task,
      taskSpecificDetails: {
        ...task.taskSpecificDetails,
        lockAssignedVehicle: true,
      },
    }));
  }

  // Get calendar events that overlap the date range
  const stiltEvents =
    await DbRead.calendarEvents.getAllThatOverlapWithDateRange(
      args.siteKeyID,
      DateTime.fromISO(args.currentDateString).startOf("day"),
      DateTime.fromISO(args.currentDateString).endOf("day"),
    );

  return await allocateResourcesToTasksVROOM({
    tasks: filteredTasks,
    vehicles: args.vehicleList,
    siteKeyWorkTypes: args.siteKeyWorkTypes,
    siteKeyTaskTypes: args.siteKeyTaskTypes,
    getLocationDoc: args.getLocationDoc,
    stiltEvents: stiltEvents,
    day: DateTime.fromISO(args.currentDateString),
    smartSchedulingSettings: args.smartSchedulingSettings,
    originalTotalDistanceMiles: args.originalTotalDistanceMiles,
  });
}

export async function findSmartSchedulingOptions(args: {
  vehicleList: ExistingVehicle[];
  scheduledTaskList: ExistingTask[];
  currentDateString: string;
  siteKeyWorkTypes: number[];
  siteKeyTaskTypes: number[];
  getLocationDoc: (locationID: string) => ExistingSiteKeyLocation;
  stiltEvents: ExistingStiltCalendarEvent[];
  latitude: number;
  longitude: number;
  locationID: string;
  craftType: number;
  taskType: TaskTypes;
  plannedHours: number | null;
  smartSchedulingSettings: SmartSchedulingFormValues;
}): Promise<SmartSchedulingOption[]> {
  if (args.vehicleList.length === 0) {
    return [];
  }

  const allTasks: ExistingTask[] = cloneDeep(args.scheduledTaskList);
  // Filter only tasks on the day we want to see
  let filteredTasks = allTasks.filter((task) => {
    const timestampScheduled = task.timestampScheduled;
    const dateTimeScheduled = timestampScheduled
      ? DateTime.fromMillis(timestampScheduled.toMillis())
      : null;
    return dateTimeScheduled
      ? dateTimeScheduled.toISODate() === args.currentDateString
      : false;
  });

  // Filter vehicles by locationID
  const vehicles = args.vehicleList.filter(
    (vehicle) => vehicle.locationID === args.locationID,
  );

  // Filter by locationID
  filteredTasks = filteredTasks.filter(
    (task) => task.locationID === args.locationID,
  );

  // Set lockAssignedVehicle to true for all filteredTasks
  filteredTasks = filteredTasks.map((task) => ({
    ...task,
    taskSpecificDetails: {
      ...task.taskSpecificDetails,
      lockAssignedVehicle: true,
      schedulingPriority: 10,
    },
  }));

  const taskTypeString = getTaskSpecificDetailsString(args.taskType);
  const serviceWindows =
    args.smartSchedulingSettings.defaultServiceWindowsByTaskType[
      taskTypeString
    ] || [];

  const vroomPromises = [];

  // First we need to run the optimization with all vehicles locked, and no new proposed tasks, and see if any tasks are unassigned.
  const vroomOutput = await allocateResourcesToTasksVROOM({
    tasks: filteredTasks,
    vehicles: vehicles,
    siteKeyWorkTypes: args.siteKeyWorkTypes,
    siteKeyTaskTypes: args.siteKeyTaskTypes,
    getLocationDoc: args.getLocationDoc,
    stiltEvents: args.stiltEvents,
    day: DateTime.fromISO(args.currentDateString),
    smartSchedulingSettings: args.smartSchedulingSettings,
    originalTotalDistanceMiles: 0,
  });

  const unassignedTaskIDs = vroomOutput.unassigned.map((t) => t.description);

  // Then we will add a single proposed task to the list from a set of possible service windows
  for (const serviceWindow of serviceWindows) {
    const proposedTask = {
      ...allTasks[0],
      id: `service-window-${serviceWindow.startHour}-${serviceWindow.endHour}`,
      title: "Proposed Task",
      timestampScheduled: Timestamp.fromMillis(
        DateTime.fromISO(args.currentDateString)
          .set({ hour: serviceWindow.startHour })
          .toSeconds() * 1000,
      ),
      locationID: args.locationID,
      latitude: args.latitude,
      longitude: args.longitude,
      craftType: args.craftType,
      taskType: args.taskType,
      taskSpecificDetails: {
        scheduledServiceWindowStart: {
          seconds: DateTime.fromISO(args.currentDateString)
            .set({ hour: serviceWindow.startHour })
            .toSeconds(),
        },
        scheduledServiceWindowEnd: {
          seconds: DateTime.fromISO(args.currentDateString)
            .set({ hour: serviceWindow.endHour })
            .toSeconds(),
        },
        plannedHours: args.plannedHours,
        schedulingPriority: 1,
      },
    };

    vroomPromises.push(
      allocateResourcesToTasksVROOM({
        tasks: [...filteredTasks, proposedTask],
        vehicles: vehicles,
        siteKeyWorkTypes: args.siteKeyWorkTypes,
        siteKeyTaskTypes: args.siteKeyTaskTypes,
        getLocationDoc: args.getLocationDoc,
        stiltEvents: args.stiltEvents,
        day: DateTime.fromISO(args.currentDateString),
        smartSchedulingSettings: args.smartSchedulingSettings,
        originalTotalDistanceMiles: 0,
      }),
    );
  }

  try {
    // Wait for all promises to resolve
    const allocatedAssetsArray = await Promise.all(vroomPromises);

    const smartSchedulingOptions: SmartSchedulingOption[] = [];

    // Handle the results for each run
    allocatedAssetsArray.forEach((allocatedAssets, index) => {
      const serviceWindow = serviceWindows[index];

      const smartSchedulingOption = {
        startHour: serviceWindow.startHour,
        endHour: serviceWindow.endHour,
        vehicle: "",
        humanReadableString: serviceWindow.humanReadableString,
        totalDistance: allocatedAssets.vroomOutput?.summary.distance ?? 0,
      };

      const unassignedTaskIDsWithProposedTask = allocatedAssets.unassigned.map(
        (t) => t.description,
      );
      // Check if the two arrays have the same elements

      if (
        unassignedTaskIDsWithProposedTask.length === unassignedTaskIDs.length &&
        unassignedTaskIDsWithProposedTask.every((id) =>
          unassignedTaskIDs.includes(id),
        )
      ) {
        const taskID = `service-window-${serviceWindow.startHour}-${serviceWindow.endHour}`;
        const vehicleForThisTask = allocatedAssets.tasks.find(
          (t) => t.id === taskID,
        )?.taskSpecificDetails.assignedVehicleID;
        if (vehicleForThisTask) {
          smartSchedulingOption.vehicle =
            args.vehicleList.find((v) => v.id === vehicleForThisTask)?.id ?? "";
        }
        smartSchedulingOptions.push(smartSchedulingOption);
      }
    });
    return smartSchedulingOptions;
  } catch (error) {
    console.error("Error in running allocation tasks:", error);
    return [];
  }
}
