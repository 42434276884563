//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import { ExistingCustomer } from "../../models/customer";
import BaseModal from "../BaseModal";
import {
  buttons,
  CONFIRM_DELETE_CUSTOMER,
  DELETE_CUSTOMER,
} from "../../strings";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import HeadingOne from "../HeadingOne";

export interface ConfirmDeleteCustomerDialogProps {
  isOpen: boolean;
  onClose: () => void;
  handleConfirmDelete: () => Promise<void>;
  isSubmitting: boolean;
  customer: ExistingCustomer;
}

export default function ConfirmDeleteCustomerDialog(
  props: ConfirmDeleteCustomerDialogProps,
) {
  return (
    <BaseModal
      open={props.isOpen}
      closeModal={() => {}}
      allowOverflowY={true}
      title={
        <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
          <HeadingOne fontColor="text-white" fontSize="text-xl">
            {DELETE_CUSTOMER}
          </HeadingOne>
          <button type="button" onClick={() => props.onClose()}>
            <XMarkIcon
              aria-label="confirm delete equipment"
              className="h-6 text-white"
            />
          </button>
        </div>
      }
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="relative flex flex-col space-y-8 p-8 text-lg">
        <div className="font-bold">{props.customer.name}</div>
        <div className="font-bold">{CONFIRM_DELETE_CUSTOMER}</div>

        <div className="mt-4 flex w-full flex-col items-center justify-between gap-6 xs:flex-row">
          <BaseButtonSecondary
            type="button"
            className="w-full justify-center uppercase xs:w-52"
            onClick={props.onClose}
          >
            {buttons.NO}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            type="submit"
            disabled={props.isSubmitting}
            isBusy={props.isSubmitting}
            onClick={props.handleConfirmDelete}
            className="w-full justify-center uppercase xs:w-52"
          >
            {buttons.YES}
          </BaseButtonPrimary>
        </div>
      </div>
    </BaseModal>
  );
}
