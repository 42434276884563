// Lib
import type { Timestamp } from "firebase/firestore";
import groupBy from "lodash/groupBy";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Local
import BaseModal from "./BaseModal";
import IncomingCallAlert from "./IncomingCallAlert";

type PartialCall = {
  id: string;
  caller: string;
  callStatus: string;
  timestampCreated: Timestamp;
  customerID: string;
  fullName?: string;
};
interface Props {
  open: boolean;
  closeModal: () => void;
  /**
   * A subset of ExistingStiltPhoneCall, with only the fields needed for this component.
   */
  callList: PartialCall[];
  /**
   * Action to clear the call status for a given call.
   */
  onClearCallStatus: (callID: string) => void;
  /**
   * Action to navigate to the customer page.
   */
  onGoToCustomer: (customerID: string) => void;
  onGoToCreateNewTaskPage: (callID: string) => void;

  /**
   * Passed down to the call list menu item to set the current call.
   */
  onUpdateCustomer: (callID: string) => void;
}

/**
 * A modal that displays a list of incoming calls.
 *
 * Sorting is handled outside of this component. Grouping is handled internally by callStatus.
 */
export default function IncomingCallListModal(props: Props): JSX.Element {
  const callListByStatus = useMemo(
    () => groupBy(props.callList, "callStatus"),
    [props.callList],
  );
  // TODO: better enum of available statuses
  const { ringing, "in-progress": inProgress, ...other } = callListByStatus;
  return (
    <BaseModal
      open={props.open}
      title="Recent Calls"
      closeModal={props.closeModal}
    >
      <div className="-mx-2 max-h-[400px] overflow-y-auto px-1 sm:mx-0">
        {ringing && (
          <IncomingCallListSection
            status="Ringing"
            callList={ringing}
            onClearCallStatus={props.onClearCallStatus}
            onGoToCustomer={props.onGoToCustomer}
            onGoToCreateNewTaskPage={props.onGoToCreateNewTaskPage}
            onUpdateCustomer={props.onUpdateCustomer}
          />
        )}
        {inProgress && (
          <IncomingCallListSection
            status="In Progress"
            callList={inProgress}
            onClearCallStatus={props.onClearCallStatus}
            onGoToCustomer={props.onGoToCustomer}
            onGoToCreateNewTaskPage={props.onGoToCreateNewTaskPage}
            onUpdateCustomer={props.onUpdateCustomer}
          />
        )}
        {Object.keys(other).map((status) => (
          <IncomingCallListSection
            key={status}
            status={status}
            callList={other[status]}
            onClearCallStatus={props.onClearCallStatus}
            onGoToCustomer={props.onGoToCustomer}
            onGoToCreateNewTaskPage={props.onGoToCreateNewTaskPage}
            onUpdateCustomer={props.onUpdateCustomer}
          />
        ))}
      </div>
    </BaseModal>
  );
}

type SectionProps = {
  /**
   * The callStatus
   */
  status: string;
} & Pick<
  Props,
  | "callList"
  | "onClearCallStatus"
  | "onGoToCustomer"
  | "onGoToCreateNewTaskPage"
  | "onUpdateCustomer"
>;

/**
 * A Reusable section of the incoming call list for different call statuses.
 */
function IncomingCallListSection(props: SectionProps): JSX.Element {
  return (
    <>
      <div className="mt-4 flex items-center gap-4 text-base font-semibold text-gray-500">
        <div className="capitalize">{props.status}</div>
        <div className="h-0.5 w-full flex-1 bg-gray-500"></div>
      </div>
      {props.callList.map((call) => {
        return (
          <IncomingCallListItem
            key={call.id}
            call={call}
            onClearCallStatus={props.onClearCallStatus}
            onGoToCreateNewTaskPage={props.onGoToCreateNewTaskPage}
            onGoToCustomer={props.onGoToCustomer}
            onUpdateCustomer={props.onUpdateCustomer}
          />
          // TODO: add CSR photo and name (text-xs) if status is "in-progress"
        );
      })}
    </>
  );
}

/**
 * Needed a component to use local item specific useState.
 */
function IncomingCallListItem(props: {
  call: PartialCall;
  onClearCallStatus: (callID: string) => void;
  onGoToCustomer: (customerID: string) => void;
  onGoToCreateNewTaskPage: (callID: string) => void;
  onUpdateCustomer: (callID: string) => void;
}): JSX.Element {
  const { call } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const callTimeDisplayed = call.timestampCreated
    ? DateTime.fromMillis(call.timestampCreated.toMillis()).toLocaleString(
        DateTime.TIME_SIMPLE,
      )
    : "";
  return (
    <div
      key={`${call.id}`}
      className="mt-2 flex items-center justify-between gap-2"
    >
      <IncomingCallAlert
        phone={call.caller ?? ""}
        name={call.fullName}
        callStatus={call.callStatus ?? "fallback"}
        rootClasses={"text-gray-900 truncate"}
        onGoToCustomer={() => props.onGoToCustomer(call.customerID)}
        onGoToCreateNewTaskPage={() => props.onGoToCreateNewTaskPage(call.id)}
      />
      <div className="flex flex-shrink gap-2">
        <div className="flex min-w-[10ch] flex-col items-end justify-center truncate">
          <div className="whitespace-nowrap capitalize">{call.callStatus}</div>
          <div className="whitespace-nowrap">{callTimeDisplayed}</div>
        </div>
        <div className="relative hidden w-10 items-center justify-center md:flex">
          <IconButton
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
            aria-label="additional options"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                props.onClearCallStatus(call.id);
              }}
            >
              Clear call status
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                props.onUpdateCustomer(call.id);
              }}
            >
              Update customer
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
}
