// Libs
import { z } from "zod";
import { DocumentData, DocumentSnapshot, Timestamp } from "firebase/firestore";

// Local
import { CraftTypes } from "./craft-types";
import { jsonSchema } from "./json-type";
import { TaskStatus } from "./task-status";
import { OTaskTypes, TaskTypes } from "./task-types";
import { NotFoundError } from "../error-classes";
import { zFallback } from "../utils/zod-fallback";
import { dropUndefined, guardIsPlainObject } from "../utils";
import { ExistingCustomerLocation } from "./customer-location";

// #region SECTION: Schemas / Interfaces / Types
export enum CraftRecordPersistenceTypes {
  CLOSE = "close",
  PROMPT = "prompt",
  KEEP = "keep",
}

export interface CancellationData {
  cancellationReasonID: string;
  cancellationReason: string;
  cancellationSourceID: string;
  cancellationSource: string;
  notes: string;
  canceledBy: string;
  timestampCanceled: Timestamp;
}

const cancellationDataSchema = z.object({
  cancellationReasonID: z.string().min(1).max(200),
  cancellationReason: z.string().min(1).max(500),
  cancellationSourceID: z.string().min(1).max(200),
  cancellationSource: z.string().min(1).max(200),
  notes: z.string().min(0).max(500).nullable(),
  canceledBy: z.string().min(1).max(200),
  timestampCanceled: z.instanceof(Timestamp),
});
/**
 * Use when validating something outgoing
 * For writing to the DB or reading from the user
 */
const taskSchema = z.object({
  assignedCompanyID: z.string().min(1).max(200),
  /** THIS IS THE WHOLE PATH TO THE CRAFT RECORD DOCUMENT. */
  craftRecordID: z.string().min(1).max(1000),
  craftRecordPersistence: z.nativeEnum(CraftRecordPersistenceTypes),
  craftType: z.nativeEnum(CraftTypes),
  createdBy: z.string().min(1).max(200),
  crewCount: z.number().int(),
  description: z.string().max(3000).nullable(),
  durations: z.record(jsonSchema),
  holdDurations: z.record(jsonSchema),
  lastModifiedBy: z.string().min(1).max(200),
  locationID: z.string().min(1).max(200),
  nextOpportunity: z.boolean(),
  notifyCompanyOnCreation: z.boolean(),
  taskSpecificDetails: z.record(jsonSchema),
  taskStatus: z.nativeEnum(TaskStatus),
  taskType: z.nativeEnum(OTaskTypes),
  thumbnailURL: z.string().max(2000).nullable(),
  timestampAwaitingStart: z.instanceof(Timestamp).nullable(),
  timestampCreated: z.instanceof(Timestamp),
  timestampLastModified: z.instanceof(Timestamp),
  timestampScheduled: z.instanceof(Timestamp).nullable(),
  timestampTaskCompleted: z.instanceof(Timestamp).nullable(),
  timestampTaskStarted: z.instanceof(Timestamp).nullable(),
  title: z.string().min(1).max(200),
  urgent: z.boolean(),
  workOrder: z.string().max(200).nullable(),

  sendBookingConfirmationEmail: z.boolean().optional(),
  sendJobReminderEmail: z.boolean().optional(),

  primaryContactPhone: z.string().optional(),
  primaryContactEmail: z.string().optional(),

  membershipIDs: z.array(z.string()).optional(),
  assetIDs: z.array(z.string()).optional(),
  isMembershipTask: z.boolean().optional(),
  customerID: z.string().min(0).max(1000).optional(),
  customerLocationID: z.string().min(0).max(1000).optional(),
  latitude: z.number().optional(),
  longitude: z.number().optional(),

  completionNotes: z.string().nullable().optional(),
  completionOptions: z.string().nullable().optional(),
  internalJobNotes: z.string().nullable().optional(),
  reviewSolicitation: z.boolean().optional(),

  cancellationData: cancellationDataSchema.optional(),
});

export type Task = z.infer<typeof taskSchema>;

// Separate type/interface for 'new' and Existing
export interface ExistingTask extends Task {
  id: string;
  refPath: string;
}

export interface ExistingTaskWithCustomerLocation extends ExistingTask {
  customerLocation?: ExistingCustomerLocation;
}

type withoutTimestamps = Omit<
  Task,
  | "timestampAwaitingStart"
  | "timestampCreated"
  | "timestampLastModified"
  | "timestampScheduled"
  | "timestampTaskCompleted"
  | "timestampTaskStarted"
>;

export interface Task_CreateForCustomer extends withoutTimestamps {
  timestampAwaitingStart: number | null;
  timestampCreated: number;
  timestampLastModified: number;
  timestampScheduled: number | null;
  timestampTaskCompleted: number | null;
  timestampTaskStarted: number | null;
}

/** For reading from the database. */
const SchemaTaskWithFallbacks = z.object({
  assignedCompanyID: z.string().min(1).max(200),
  craftRecordID: z.string().min(1).max(1000),
  craftRecordPersistence: zFallback(
    z.nativeEnum(CraftRecordPersistenceTypes),
    CraftRecordPersistenceTypes["PROMPT"],
    "SchemaTaskWithFallbacks: 'craftRecordPersistence'",
  ),
  craftType: z.nativeEnum(CraftTypes),
  // NOTE: if this causes problems, remove the fallback.
  createdBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "SchemaTaskWithFallbacks: 'createdBy'",
  ),
  crewCount: zFallback(
    z.number().int(),
    0,
    "SchemaTaskWithFallbacks: 'crewCount'",
  ),
  description: zFallback(
    z.string().max(3000).nullable(),
    null,
    "SchemaTaskWithFallbacks: 'description'",
  ),
  durations: zFallback(
    z.record(jsonSchema),
    {},
    "SchemaTaskWithFallbacks: 'durations'",
  ),
  holdDurations: zFallback(
    z.record(jsonSchema),
    {},
    "SchemaTaskWithFallbacks: 'holdDurations'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  lastModifiedBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "SchemaTaskWithFallbacks: 'lastModifiedBy'",
  ),
  locationID: z.string().min(1).max(200),
  nextOpportunity: zFallback(
    z.boolean(),
    false,
    "SchemaTaskWithFallbacks: 'nextOpportunity'",
  ),
  notifyCompanyOnCreation: zFallback(
    z.boolean(),
    true,
    "SchemaTaskWithFallbacks: 'notifyCompanyOnCreation'",
  ),
  taskSpecificDetails: zFallback(
    z.record(jsonSchema),
    {},
    "SchemaTaskWithFallbacks: 'taskSpecificDetails'",
  ),
  taskStatus: z.nativeEnum(TaskStatus),
  taskType: z.nativeEnum(TaskTypes),
  thumbnailURL: zFallback(
    z.string().max(2000).nullable(),
    null,
    "SchemaTaskWithFallbacks: 'thumbnailURL'",
  ),
  timestampAwaitingStart: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "SchemaTaskWithFallbacks: 'timestampAwaitingStart'",
  ),
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "SchemaTaskWithFallbacks: 'timestampCreated'",
  ),
  timestampLastModified: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "SchemaTaskWithFallbacks: 'timestampLastModified'",
  ),
  timestampScheduled: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "SchemaTaskWithFallbacks: 'timestampScheduled'",
  ),
  timestampTaskCompleted: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "SchemaTaskWithFallbacks: 'timestampTaskCompleted'",
  ),
  timestampTaskStarted: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "SchemaTaskWithFallbacks: 'timestampTaskStarted'",
  ),
  title: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "SchemaTaskWithFallbacks: 'title'",
  ),
  urgent: zFallback(z.boolean(), false, "SchemaTaskWithFallbacks: 'urgent'"),
  workOrder: zFallback(
    z.string().max(200).nullable(),
    "",
    "SchemaTaskWithFallbacks: 'workOrder'",
  ),
  customerID: zFallback(
    z.string().min(0).max(1000).optional(),
    undefined,
    "SchemaTaskWithFallbacks: 'customerID'",
  ),
  customerLocationID: zFallback(
    z.string().min(0).max(1000).optional(),
    undefined,
    "SchemaTaskWithFallbacks: 'customerLocationID'",
  ),
  latitude: zFallback(
    z.number().optional(),
    undefined,
    "SchemaTaskWithFallbacks: 'latitude'",
  ),
  longitude: zFallback(
    z.number().optional(),
    undefined,
    "SchemaTaskWithFallbacks: 'longitude'",
  ),
  sendBookingConfirmationEmail: zFallback(
    z.boolean().optional(),
    false,
    "SchemaTaskWithFallbacks: 'sendBookingConfirmationEmail'",
  ),
  sendJobReminderEmail: zFallback(
    z.boolean().optional(),
    false,
    "SchemaTaskWithFallbacks: 'sendJobReminderEmail'",
  ),
  primaryContactPhone: zFallback(
    z.string().optional(),
    undefined,
    "SchemaTaskWithFallbacks: 'primaryContactPhone'",
  ),
  primaryContactEmail: zFallback(
    z.string().optional(),
    undefined,
    "SchemaTaskWithFallbacks: 'primaryContactEmail'",
  ),

  membershipIDs: z.array(z.string()).optional(),
  assetIDs: z.array(z.string()).optional(),
  isMembershipTask: z.boolean().optional(),

  completionNotes: z.string().nullable().optional(),
  completionOptions: z.string().nullable().optional(),
  internalJobNotes: z.string().nullable().optional(),
  reviewSolicitation: z.boolean().optional(),

  cancellationData: cancellationDataSchema.optional(),
});

export const existingTaskSchema = taskSchema.extend({
  id: z.string().min(1).max(200),
  refPath: z.string().min(1).max(400),
});
// #endregion

// #region SECTION: Functions
/** Utilities for interacting with Task objects. */
export const TaskRecordManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingTask object.
   * Fallback values will be used for appropriate fields, if necessary.
   * @throws NotFoundError if the snapshot does not exist.
   */
  createFromFirestoreSnapshot: createTaskRecordFromSnapshot,
  /** Drop `id` and `refPath` before saving to the database. */
  convertForFirestore: convertTaskRecordForFirestore,
  /** Drop undefined values from a new task doc before database write. */
  convertNewForFirestore: convertNewTaskForFirestore,
  /** Validate a Task doc. Use for writing to the database or reading from the user. */
  parse: validateTask,
  /** Validate a Task doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateTaskWithFallbacks,
  /** Validate a Task doc. Use for writing to the database through CF */
  parseCreateForCustomer: validateTask_CreateForCustomer,
};

/**
 * Convert the Document Snapshot into a validated ExistingTask object.
 * Fallback values will be used for appropriate fields, if necessary.
 * @throws NotFoundError if the snapshot does not exist.
 */
function createTaskRecordFromSnapshot(
  snapshot: DocumentSnapshot,
): ExistingTask {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateTaskWithFallbacks(snapshotData),
  };
}

/** Drop `id` and `refPath` before saving to the database. Drop undefined values */
function convertTaskRecordForFirestore(task: ExistingTask): DocumentData {
  // Copy before modifying. Don't want to modify original object.
  const local = Object.assign({}, task);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, refPath, ...rest } = local;
  const result = dropUndefined(rest);
  return result;
}

/** Drop undefined values from a new task doc before database write. */
function convertNewTaskForFirestore(
  task: Task | Task_CreateForCustomer,
): DocumentData {
  // Copy before modifying. Don't want to modify original object.
  const local = Object.assign({}, task);
  return dropUndefined(local);
}

/** Rejects if it fails validation. */
function validateTask(task: unknown): Task {
  if (!guardIsPlainObject(task)) {
    throw new Error(`Task is not an object: ${task}`);
  }
  return taskSchema.parse(task);
}

/** For validating stuff coming in from the database. */
function validateTaskWithFallbacks(task: unknown): Task {
  if (!guardIsPlainObject(task)) {
    throw new Error(`Task is not an object: ${task}`);
  }
  return SchemaTaskWithFallbacks.parse(task);
}

function validateTask_CreateForCustomer(task: unknown): Task_CreateForCustomer {
  if (!guardIsPlainObject(task)) {
    throw new Error(`Task is not an object: ${task}`);
  }
  return taskSchema_CreateForCustomer.parse(task);
}

const withoutTimestampsSchema = taskSchema.omit({
  timestampAwaitingStart: true,
  timestampCreated: true,
  timestampLastModified: true,
  timestampScheduled: true,
  timestampTaskCompleted: true,
  timestampTaskStarted: true,
});

const taskSchema_CreateForCustomer = withoutTimestampsSchema.extend({
  timestampAwaitingStart: z.number().nullable(),
  timestampCreated: z.number(),
  timestampLastModified: z.number(),
  timestampScheduled: z.number().nullable(),
  timestampTaskCompleted: z.number().nullable(),
  timestampTaskStarted: z.number().nullable(),
});
// #endregion

export function getJobDescription(
  openTasks: ExistingTask[],
  completedTasks: ExistingTask[],
): string {
  if (openTasks.length > 1) {
    return `${openTasks.length} open tasks`;
  } else if (openTasks.length === 1) {
    return `${openTasks.length} open task`;
  } else if (completedTasks.length > 1) {
    return `${completedTasks.length} completed tasks`;
  } else if (completedTasks.length === 1) {
    return `${completedTasks.length} completed task`;
  } else {
    return `0 tasks`;
  }
}
