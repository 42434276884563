//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";

//Local
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseButtonSecondary from "../BaseButtonSecondary";
import HeadingOne from "../HeadingOne";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleConfirmDeleteWR: () => Promise<void>;
  isSubmitting: boolean;
}

export default function ConfirmDeleteWRDialog(props: Props) {
  return (
    <BaseModal
      open={props.isOpen}
      closeModal={props.onClose}
      allowOverflowY={true}
      title={
        <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
          <HeadingOne fontColor="text-white" fontSize="text-xl">
            {strings.CONFIRM_DELETE_WR}
          </HeadingOne>
          <button type="button" onClick={() => props.onClose()}>
            <XMarkIcon
              aria-label="close new requirement form"
              className="h-6 text-white"
            />
          </button>
        </div>
      }
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="relative flex flex-col space-y-8 p-8 text-lg">
        <div>
          Deleting this work record will also delete its linked tasks. Would you
          like to proceed?
        </div>
        <div className="mt-4 flex w-full flex-col items-center justify-between gap-6 xs:flex-row">
          <BaseButtonSecondary
            type="button"
            className="w-full justify-center uppercase xs:w-52"
            onClick={props.onClose}
          >
            {strings.buttons.NO}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            type="submit"
            disabled={props.isSubmitting}
            isBusy={props.isSubmitting}
            busyText={strings.buttons.BUSY_DELETING}
            onClick={() => props.handleConfirmDeleteWR()}
            className="w-full justify-center uppercase xs:w-52"
          >
            {strings.buttons.YES}
          </BaseButtonPrimary>
        </div>
      </div>
    </BaseModal>
  );
}
