import React, { useState } from "react";
import BaseButtonSecondary from "../../../components/BaseButtonSecondary";

interface ReviewURL {
  platform: string;
  url: string;
}

interface Props {
  value: Record<string, string>;
  onChange?: (urls: Record<string, string>) => void;
}

export default function ReviewURLsEditor({ value = {}, onChange }: Props) {
  const [urls, setUrls] = useState<ReviewURL[]>(
    Object.entries(value).map(([platform, url]) => ({ platform, url })),
  );

  const addNewRow = () => {
    setUrls([...urls, { platform: "", url: "" }]);
  };

  const removeRow = (index: number) => {
    setUrls(urls.filter((_, i) => i !== index));
  };

  const updateRow = (index: number, field: keyof ReviewURL, value: string) => {
    const newUrls = [...urls];
    newUrls[index] = { ...newUrls[index], [field]: value };
    setUrls(newUrls);

    // Convert to record and trigger onChange
    if (onChange) {
      const urlRecord = newUrls.reduce(
        (acc, { platform, url }) => {
          if (platform && url) acc[platform] = url;
          return acc;
        },
        {} as Record<string, string>,
      );
      onChange(urlRecord);
    }
  };

  return (
    <div className="space-y-2">
      {urls.map((url, index) => (
        <div key={index} className="flex gap-2">
          <input
            type="text"
            className="block w-1/3 rounded border border-black p-2 text-gray-700 focus:ring"
            placeholder="Platform (e.g., Google)"
            value={url.platform}
            onChange={(e) => updateRow(index, "platform", e.target.value)}
          />
          <input
            type="url"
            className="block w-full rounded border border-black p-2 text-gray-700 focus:ring"
            placeholder="Review URL"
            value={url.url}
            onChange={(e) => updateRow(index, "url", e.target.value)}
          />
          <button
            type="button"
            onClick={() => removeRow(index)}
            className="rounded border border-red-500 px-2 py-1 text-red-500 hover:bg-red-50"
          >
            Remove
          </button>
        </div>
      ))}
      <BaseButtonSecondary
        type="button"
        onClick={addNewRow}
        className="rounded border border-black px-3 py-1 hover:bg-gray-50"
      >
        Add Review URL
      </BaseButtonSecondary>
    </div>
  );
}
