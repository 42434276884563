interface Props {
  text: string;
  count: number;
  color: "green" | "red" | "orange" | "blue" | "gray";
  margin?: string;
}

export default function PillCount(props: Props) {
  const colors = getColorClasses(props.color);
  return (
    <div
      className={`${colors.background} ${props.margin} flex max-w-fit items-center justify-center rounded-full py-1 pl-1 pr-3 text-sm font-medium text-gray-900`}
    >
      <span
        className={`${colors.countBackground} mr-1.5 rounded-full px-2 py-0.5 text-base`}
      >
        {props.count}
      </span>
      {props.text}
    </div>
  );
}

function getColorClasses(color: Props["color"]): Record<string, string> {
  let styles: Record<string, string> = {};
  switch (color) {
    case "blue": {
      styles = {
        background: "bg-blue-200",
        countBackground: "bg-blue-400",
      };
      return styles;
    }

    case "gray": {
      styles = {
        background: "bg-gray-200",
        countBackground: "bg-gray-400",
      };
      return styles;
    }

    case "green": {
      styles = {
        background: "bg-green-300",
        countBackground: "bg-green-500",
      };
      return styles;
    }

    case "orange": {
      styles = {
        background: "bg-orange-200",
        countBackground: "bg-orange-400",
      };
      return styles;
    }

    case "red": {
      styles = {
        background: "bg-red-200",
        countBackground: "bg-red-400",
      };
      return styles;
    }
  }
}
