import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import ExclamationTriangleIcon from "@heroicons/react/24/outline/ExclamationTriangleIcon";
import QuestionMarkOutlined from "@mui/icons-material/QuestionMarkOutlined";
import {
  memo,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  GridReadyEvent,
  ICellRendererParams,
  ColDef,
  ValueFormatterParams,
  GridApi,
  CellClickedEvent,
  RowClassRules,
  RowClassParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import isEqual from "lodash/isEqual";

import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-alpine.css";
// Local
import * as strings from "../../strings";
import BaseModal from "../BaseModal";
import { CardOnFile, ExistingCustomer } from "../../models/customer";
import { createToastMessageID } from "../../utils/createToastMessageID";
import { useToastMessageStore } from "../../store/toast-messages";
import { TrashIconWithSpinner } from "../ButtonDeleteTrash";
import { StyledTooltip } from "../StyledTooltip";
import { guardIsPlainObject } from "../../utils/isPlainObject";
import { logger } from "../../logging";
import { isCardExpired } from "../../utils/isCardExpired";
import HeadingOne from "../HeadingOne";

interface Props {
  isDialogOpen: boolean;
  customer: ExistingCustomer;

  closeDialog: () => void;
  updatePrimaryCard: (card: CardOnFile) => void;
  deleteCard: (card: CardOnFile) => void;
  unsetPrimaryCard: () => void;
}

const commonColProps = {
  // width: 150,
  flex: 1,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass:
    "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase",
};

export default function ManageCardsOnFileDialog(props: Props) {
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );
  const [gridReady, setGridReady] = useState(false);
  const gridRef = useRef<AgGridReact>(null);
  const onGridReady = useCallback(() => {
    setGridReady(true);
  }, []);

  const primaryCard = props.customer.cardsOnFile?.find((c) => c.isPrimary);

  useLayoutEffect(() => {
    if (!gridReady) return;
    if (gridRef.current) {
      const api: GridApi = gridRef.current.api;
      api?.sizeColumnsToFit();
    }
  }, [gridReady]);

  function handleDeleteCard(data: unknown): void {
    if (
      !guardIsPlainObject(data) ||
      typeof data.type !== "string" ||
      typeof data.lastFour !== "number" ||
      typeof data.expiry !== "string" ||
      typeof data.token !== "string" ||
      typeof data.name !== "string"
    ) {
      return;
    }

    const validCard = {
      type: data.type,
      lastFour: data.lastFour,
      expiry: data.expiry,
      token: data.token,
      name: data.name,
    };
    try {
      props.deleteCard(validCard);
      addToastMessage({
        id: createToastMessageID(),
        type: "success",
        message: strings.DELETE_SAVED_CARD_SUCCESS,
        dialog: true,
      });
    } catch (e) {
      logger.error(e);
      addToastMessage({
        id: createToastMessageID(),
        type: "error",
        message: strings.DELETE_SAVED_CARD_ERR,
        dialog: true,
      });
    }
  }

  function handleUpdatePrimary(ev: CellClickedEvent): void {
    if (
      !ev.data ||
      typeof ev.data.type !== "string" ||
      typeof ev.data.lastFour !== "number" ||
      typeof ev.data.expiry !== "string" ||
      typeof ev.data.token !== "string" ||
      typeof ev.data.name !== "string"
    ) {
      addToastMessage({
        id: createToastMessageID(),
        type: "error",
        message: strings.SELECT_ROW_ERR,
        dialog: true,
      });
      return;
    }
    if (ev.data.isPrimary === true) return; // no need to update.

    const validCard = {
      type: ev.data.type,
      lastFour: ev.data.lastFour,
      expiry: ev.data.expiry,
      token: ev.data.token,
      name: ev.data.name,
    };
    try {
      props.updatePrimaryCard(validCard);
      addToastMessage({
        id: createToastMessageID(),
        type: "success",
        message: strings.PRIMARY_CARD_UPDATED,
        dialog: true,
      });
    } catch (e) {
      logger.error(e);
      addToastMessage({
        id: createToastMessageID(),
        type: "error",
        message: strings.PRIMARY_CARD_UPDATE_ERR,
        dialog: true,
      });
    }
  }

  function handleUnsetPrimary(): void {
    try {
      props.unsetPrimaryCard();
      addToastMessage({
        id: createToastMessageID(),
        type: "success",
        message: strings.PRIMARY_CARD_UPDATED,
        dialog: true,
      });
    } catch (e) {
      logger.error(e);
      addToastMessage({
        id: createToastMessageID(),
        type: "error",
        message: strings.PRIMARY_CARD_UPDATE_ERR,
        dialog: true,
      });
    }
  }

  const header = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white">
      <HeadingOne fontColor="text-white" fontSize="text-xl">
        {strings.TITLECASE_MANAGE_CARDS_ON_FILE}
      </HeadingOne>
      <button type="button" onClick={props.closeDialog}>
        <XMarkIcon
          aria-label={`close ${strings.MANAGE_CARDS_ON_FILE} dialog`}
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  const rowClassRules = useMemo(() => {
    return {
      "ag-custom-selected": (params: RowClassParams) => params.data.isPrimary,
    };
  }, []);

  return (
    <BaseModal
      closeModal={props.closeDialog}
      open={props.isDialogOpen}
      title={header}
      parentDivStyles="text-left max-w-5xl"
    >
      <div className="px-8 pb-8">
        <div className="flex items-center justify-between gap-2">
          <p className="grow text-sm font-medium text-gray-500">
            {strings.CUSTOMER}: {props.customer.name}
          </p>
          <span className="shrink-0">
            <StyledTooltip title="Click a row to update the primary card for this customer. If applicable, the primary card is used for automatic payments (i.e., membership renewals).">
              <QuestionMarkOutlined className="rounded-full border border-gray-400 p-0.5 text-gray-400" />
            </StyledTooltip>
          </span>
        </div>

        <h3 className="mt-1 flex items-center text-lg font-medium">
          <span className="mr-1 inline-block font-normal text-gray-500">
            Primary Card:
          </span>
          {getFormattedPrimaryCard(primaryCard ?? null)}
          {primaryCard && (
            <StyledTooltip title="Unset the primary card. (This will prevent the processing of any automatic payments scheduled for this customer: it will not delete the customer's card.)">
              <button
                type="button"
                onClick={handleUnsetPrimary}
                className="ml-2"
              >
                <XMarkIcon
                  aria-label={`unset primary card`}
                  className="h-5 rounded-full border border-gray-400 p-0.5 text-gray-700 hover:border-gray-600"
                />
              </button>
            </StyledTooltip>
          )}
        </h3>

        <div className="ag-theme-alpine flex h-[300px] flex-col">
          <CardsOnFileTable
            cardsOnFile={props.customer.cardsOnFile ?? []}
            deleteCard={handleDeleteCard}
            gridRef={gridRef}
            onGridReady={onGridReady}
            onCellClicked={(ev: CellClickedEvent) => {
              if (ev.colDef.field === "actions") return; // user intent: delete card.
              handleUpdatePrimary(ev);
            }}
            rowClassRules={rowClassRules}
          />
        </div>
      </div>
    </BaseModal>
  );
}

function getFormattedPrimaryCard(primaryCard: CardOnFile | null): string {
  if (!primaryCard) return "None selected";
  return `${primaryCard.name}, ${primaryCard.type}, ${primaryCard.expiry}, ${primaryCard.lastFour.toString().padStart(4, "0")}`;
}

interface TableProps {
  cardsOnFile: CardOnFile[];
  deleteCard: (data: unknown) => void;

  gridRef: React.MutableRefObject<any>;
  onGridReady: (event: GridReadyEvent) => void;
  onCellClicked: (event: CellClickedEvent) => void;
  rowClassRules: RowClassRules;
}

const CardsOnFileTable = memo(
  (props: TableProps) => {
    const renderExpiry = (params: ICellRendererParams): JSX.Element => {
      const isExpired = isCardExpired(params.data.expiry);

      if (!isExpired) return <span>{params.data.expiry}</span>;
      return (
        <span className="flex items-center">
          <StyledTooltip title="Expired">
            <ExclamationTriangleIcon className="h-6 text-red-700" />
          </StyledTooltip>
          <span className="ml-1">{params.data.expiry}</span>
        </span>
      );
    };

    const deleteCard = props.deleteCard;
    const renderIconCell = useCallback(
      function (params: ICellRendererParams) {
        return (
          <TrashIconWithSpinner
            onDelete={async () => deleteCard(params.data)}
          />
        );
      },
      [deleteCard],
    );

    const columnDefs: ColDef[] = [
      {
        headerName: "Name on Card",
        field: "name",
        flex: 2,
        valueFormatter: (params: ValueFormatterParams) => params.value,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        field: "type",
        headerName: "Type",
        valueFormatter: (params: ValueFormatterParams) => params.value,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "Expiration Date",
        field: "expiry",
        valueFormatter: (params: ValueFormatterParams) => params.value,
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
        cellRenderer: renderExpiry,
      },
      {
        headerName: "Last Four",
        field: "lastFour",
        valueFormatter: (params: ValueFormatterParams) => {
          return params.value.toString().padStart(4, "0");
        },
        tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
      },
      {
        headerName: "",
        field: "actions", // this is used within the onCellClicked handler, to
        // prevent selecting a primary card when the intent is to delete the card
        cellRenderer: renderIconCell,
        suppressMovable: true,
        suppressHeaderMenuButton: true,
        sortable: false,
        flex: 0,
        width: 80,
        cellStyle: {
          height: "100%",
          display: "flex",
          alignItems: "center",
        },
      },
    ];

    return (
      <AgGridReact
        reactiveCustomComponents
        ref={props.gridRef}
        onGridReady={props.onGridReady}
        defaultColDef={commonColProps}
        className="mt-5 shadow"
        rowData={props.cardsOnFile}
        animateRows={true}
        columnDefs={columnDefs}
        rowSelection="single"
        onCellClicked={props.onCellClicked}
        suppressCellFocus
        suppressRowDeselection
        rowClassRules={props.rowClassRules}
      />
    );
  },
  (previous, next) => isEqual(previous.cardsOnFile, next.cardsOnFile),
);
