interface Props {
  /** default is `text-3xl sm:text-4xl` */
  fontSize?: string;
  /** default is `font-semibold` */
  fontWeight?: string;
  /** default is `text-primary` */
  fontColor?: string;
  /** do not include font size, font weight, or font color classes here */
  className?: string;
  children: React.ReactNode;
}

/**
 * Defaults to `text-3xl sm:text-4xl`, `font-semibold`, and `text-primary`. These can be overridden.
 *
 * className is also available
 */
export default function HeadingOne(props: Props) {
  return (
    <h1
      className={`${props.fontColor ?? "text-primary"} ${
        props.fontSize ?? "text-3xl sm:text-4xl"
      } ${props.fontWeight ?? "font-semibold"} ${props.className ?? ""}`}
    >
      {props.children}
    </h1>
  );
}
