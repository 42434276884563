//Libs
import axios from "axios";

//Local
import { ExistingTask } from "../../../models/task";
import { logger as devLogger } from "../../../logging";

export async function calculateTotalRouteDistance(
  tasks: ExistingTask[],
  startLatitude: number,
  startLongitude: number,
): Promise<number> {
  if (tasks.length > 0) {
    const start = [startLongitude, startLatitude];
    const end = start;
    let coordinates = "";
    coordinates = `${coordinates}${start[0]},${start[1]};`;
    tasks.forEach((task) => {
      coordinates = `${coordinates}${task.longitude},${task.latitude};`;
    });
    coordinates = `${coordinates}${end[0]},${end[1]}`;

    const fullApiRoute = `https://routing.stilt-web.com/osrm/route/v1/driving/${coordinates}`;
    try {
      const response = await axios.get(fullApiRoute);
      const distance = response["data"]["routes"][0]["distance"];
      if (distance && typeof distance === "number") {
        return distance * 0.000621371;
      } else {
        return 0;
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        devLogger.error(error);
        return 0;
      }
      devLogger.error(error);
      return 0;
    }
  }
  return 0;
}
