//Libs
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { DbRead } from "../../database";
import { Column, Workbook } from "exceljs";
import { saveAs } from "file-saver";
import { formatAssetDataForExcelExport } from "../../assets/js/formatAssetDataForExcelExport";
import { StyledTooltip } from "../../components/StyledTooltip";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useAuthStore } from "../../store/firebase-auth";
import { useUserDisplayNamesStore } from "../../store/user-display-names-map";

//Local
import AssetsEquipmentPage from "./AssetsEquipmentPage";
import { CUSTOMERS_URL, WORK_RECORD_AND_TASKS_URL } from "../../urls";
import { ExistingStiltInvoice } from "../../models/invoice";
import { ExistingAsset } from "../../models/asset";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import * as strings from "../../strings";
import AddAssetDialog from "../../components/AssetsEquipment/AddAssetDialog";
import { ExistingCustomer } from "../../models/customer";

interface Props {
  siteKey: string;
}

export default function AssetsEquipmentContainer({ siteKey }: Props) {
  const [assetDocs, setAssetDocs] = useState<ExistingAsset[]>([]);

  const [selectedAsset, setSelectedAsset] = useState<ExistingAsset | null>(
    null,
  );

  const [assetDetailsDialogOpen, setAssetDetailsDialogOpen] =
    useState<boolean>(false);
  /* QUERIES */

  // Get customers for each asset
  const [customers, setCustomers] = useState<ExistingCustomer[]>([]);

  useEffect(() => {
    async function getCustomerData() {
      const customerPromises = assetDocs
        .filter((asset) => asset.customerID)
        .map(async (asset) => {
          return await DbRead.customers.get(
            siteKey,
            asset.customerID as string,
          );
        });
      const customers = await Promise.all(customerPromises);
      setCustomers(customers);
    }
    getCustomerData();
  }, [assetDocs]);

  // Fetch the list of invoices when this component loads.
  useEffect(() => {
    function getAssets() {
      return DbRead.assets.subscribeAll({
        siteKey: siteKey,
        onChange: setAssetDocs,
      });
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    const unsubscribePromise = getAssets();
    return () => unsubscribePromise && unsubscribePromise();
  }, [siteKey]);

  /* HISTORY */
  const navigate = useNavigate();

  function goToCustomerPage(customerId: string) {
    navigate(`${CUSTOMERS_URL}/${customerId}`);
  }

  function goToWorkRecordAndTasksPage(
    craftRecordID: ExistingStiltInvoice["craftRecordID"],
  ) {
    navigate(`${WORK_RECORD_AND_TASKS_URL}/${craftRecordID}`);
  }

  function handleOpenViewAssetDetailsDialog(asset: ExistingAsset): void {
    setSelectedAsset(asset);
    setAssetDetailsDialogOpen(true);
  }

  const createAssetEquipmentButton = (
    <BaseButtonPrimary
      type="button"
      onClick={() => {
        setSelectedAsset(null);
        setAssetDetailsDialogOpen(true);
      }}
      className="col-span-2 w-fit justify-self-end uppercase tracking-wider text-primary"
    >
      <AddCircleIcon fontSize="small" className="mr-2" />
      {strings.CREATE_ASSET_EQUIPMENT}
    </BaseButtonPrimary>
  );

  const assetDialog = (
    <AddAssetDialog
      key={`${selectedAsset?.id}-${assetDetailsDialogOpen}`}
      asset={selectedAsset}
      siteKey={siteKey}
      isDialogOpen={assetDetailsDialogOpen}
      closeDialog={() => {
        setAssetDetailsDialogOpen(false);
        setSelectedAsset(null);
      }}
      isSubmitting={false}
      customerID={selectedAsset?.customerID ?? null}
      customerLocationID={selectedAsset?.customerLocationID ?? null}
      locationID={selectedAsset?.locationID ?? null}
    ></AddAssetDialog>
  );

  const [isBusy, setIsBusy] = useState(false);
  const firebaseUser = useAuthStore((state) => state.firebaseUser);
  const userDisplayNamesMap = useUserDisplayNamesStore(
    (state) => state.userDisplayNames,
  );

  async function saveSpreadSheet() {
    setIsBusy(true);
    const fileName = strings.ASSETS_EQUIPMENT;

    const columns = [
      { key: "customerName", header: "Customer", width: 20 },
      { key: "address", header: "Address", width: 25 },
      { key: "title", header: "Title", width: 25 },
      { key: "description", header: "Description", width: 25 },
      { key: "manufacturer", header: "Manufacturer", width: 15 },
      { key: "equipmentType", header: "Equipment Type", width: 20 },
      { key: "serialNumber", header: "Serial Number", width: 15 },
      { key: "modelNumber", header: "Model Number", width: 15 },
      { key: "installationDate", header: "Installation Date", width: 20 },
      {
        key: "manufacturerWarrantyStart",
        header: "Mfg Warranty Start",
        width: 20,
      },
      { key: "manufacturerWarrantyEnd", header: "Mfg Warranty End", width: 20 },
      {
        key: "serviceWarrantyStart",
        header: "Service Warranty Start",
        width: 20,
      },
      { key: "serviceWarrantyEnd", header: "Service Warranty End", width: 20 },
    ] as Column[];

    const wb = new Workbook();
    const sheet = wb.addWorksheet(fileName);

    sheet.columns = columns;

    // Create a map of customer IDs to names
    const customerNamesMap: Record<string, string> = {};
    customers.forEach((customer) => {
      customerNamesMap[customer.id] = customer.name;
    });

    const formattedData = await formatAssetDataForExcelExport(
      assetDocs,
      customerNamesMap,
      siteKey,
    );

    sheet.addRows(formattedData);

    // Insert the user's report name as the title row (first row)
    const customTitle = `${
      userDisplayNamesMap[firebaseUser?.uid ?? ""]
    } [${fileName}]`;
    sheet.insertRow(1, [customTitle]);

    // updated the font for first row.
    sheet.getRow(1).font = { bold: true };
    sheet.getRow(2).font = { bold: true };

    try {
      // write the content using writeBuffer
      const buf = await wb.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${customTitle}.xlsx`);
    } catch (error) {
      console.error("Something Went Wrong", error);
    } finally {
      setIsBusy(false);
      // removing worksheet's instance to create new one
      wb.removeWorksheet(fileName);
    }
  }

  const actionButtons = (
    <StyledTooltip title={`Export to Excel`}>
      <button onClick={saveSpreadSheet} className="rounded-md bg-gray-200 p-2">
        {isBusy && <LoadingSpinner></LoadingSpinner>}
        {!isBusy && <FileDownloadIcon fontSize="medium" className="shrink-0" />}
      </button>
    </StyledTooltip>
  );

  return (
    <Fragment>
      <AssetsEquipmentPage
        assets={assetDocs}
        customers={customers}
        CreateAssetEquipmentButton={createAssetEquipmentButton}
        goToCustomerPage={goToCustomerPage}
        goToWorkRecordAndTasksPage={goToWorkRecordAndTasksPage}
        handleOpenViewAssetDetailsDialog={handleOpenViewAssetDetailsDialog}
        ActionButtons={actionButtons}
      >
        {{}}
      </AssetsEquipmentPage>
      {/*{handlePaymentLinkDialog}*/}
      {/*{manualPaymentDialog}*/}
      {assetDialog}
    </Fragment>
  );
}
