// MembershipDetailsPage.tsx
import React from "react";
import {
  ExistingMembership,
  getReadableMembershipStatus,
} from "../../models/membership";
import { ExistingStiltInvoice } from "../../models/invoice";
import MembershipTasksOverview from "../../components/Memberships/MembershipTasksOverview";
import { ExistingMembershipTemplate } from "../../models/membership-template";
import { MembershipDetails } from "../../components/Memberships/MembershipDetails";
import AlertV2 from "../../components/AlertV2";
import { CustomerDetails } from "../../components/estimates/CustomerDetails";
import { ExistingCustomer } from "../../models/customer";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import NavButtonBack from "../../components/NavButtonBack";

interface Props {
  // Define all the necessary props here
  membershipDoc: ExistingMembership;
  membershipInvoice: ExistingStiltInvoice | null;
  membershipTemplate: ExistingMembershipTemplate;
  customerDoc: ExistingCustomer;
  openEditMembershipDialog: () => void;
  openInvoiceDialog: (invoiceID: string) => void;
  children: {
    actionsDropdown: React.ReactNode;
    membershipTimeline: React.ReactNode;
    nextTaskTextElement: React.ReactNode;
    nextInvoiceText: React.ReactNode;
    editMembershipDialog: React.ReactNode;
    sendCustomEmailDialog: React.ReactNode;
    manageCardsOnFileDialog: React.ReactNode;
  };
}

export default function MembershipDetailsPage(props: Props) {
  let customBg = "bg-primary text-primaryButtonText";
  switch (props.membershipDoc?.status) {
    case "active":
      customBg = "bg-greenPass text-white";
      break;
    case "awaitingPayment":
      customBg = "bg-orangeError text-white";
      break;
    case "canceled":
      customBg = "bg-red-400 text-white";
      break;
  }

  const viewInvoiceBtn = props.membershipInvoice ? (
    <BaseButtonSecondary
      onClick={() => {
        if (props.membershipInvoice)
          props.openInvoiceDialog(props.membershipInvoice.id);
      }}
      className="md:absolute md:right-4 md:top-1/2 md:-translate-y-1/2"
    >
      {`View Invoice${props.membershipInvoice.invoiceNumber ? ` ${props.membershipInvoice.invoiceNumber}` : ""}`}
    </BaseButtonSecondary>
  ) : null;

  return (
    <div className="space-y-2 p-4 text-lg">
      <div className="flex flex-col items-start justify-between gap-2 sm:flex-row">
        <NavButtonBack />
        <div className="w-full">
          <h1 className="text-xl font-semibold">
            {props.membershipTemplate.title}
          </h1>
          <div
            className={`w-fit rounded-full px-2.5 py-1 text-sm font-medium ${customBg} capitalize`}
          >
            {getReadableMembershipStatus(props.membershipDoc.status)}
          </div>
        </div>
        <div className="ml-auto w-full text-right">
          <div className="flex items-end justify-end">
            {props.children.actionsDropdown}
          </div>
          {props.children.nextTaskTextElement}
          {props.children.nextInvoiceText}
        </div>
      </div>
      <div className="border-b border-gray-300"></div>
      {props.membershipDoc.status === "awaitingPayment" && (
        <div className="relative flex flex-col items-start gap-2 sm:flex-row sm:items-center md:block">
          <AlertV2
            variant="warning"
            title={
              props.membershipInvoice?.invoiceNumber
                ? `This membership will remain in Awaiting Payment status until invoice ${props.membershipInvoice.invoiceNumber} is paid.`
                : "This membership will remain in Awaiting Payment status until the invoice is paid."
            }
            alignActions="right"
          />
          {viewInvoiceBtn}
        </div>
      )}
      <div className="space-y-4 md:flex md:flex-row md:justify-between md:space-x-4 md:space-y-0">
        <div className="w-full space-y-4 lg:contents lg:space-x-4 lg:space-y-0">
          <CustomerDetails
            customer={props.customerDoc}
            openEditCustomerDialog={null}
            openCallDialog={null}
            shouldConstrainWidth={false}
          />
          <div className="w-fit">
            <MembershipDetails
              membership={props.membershipDoc}
              openEditMembershipDialog={props.openEditMembershipDialog}
            />
          </div>
        </div>
        <div className="w-fit self-start rounded border border-gray-300 px-3 py-2 shadow">
          {props.membershipTemplate && (
            <MembershipTasksOverview
              membership={props.membershipDoc}
              membershipTemplate={props.membershipTemplate}
            />
          )}
        </div>
      </div>
      <div className="border-b border-gray-300"></div>
      {props.children.sendCustomEmailDialog}
      {props.children.membershipTimeline}
      {props.children.editMembershipDialog}
      {props.children.manageCardsOnFileDialog}
    </div>
  );
}
