//Libs

//Local
import { ExistingSiteKeyLocation } from "../../../models/site-key-location";
import { ExistingTask } from "../../../models/task";
import { VroomJob, VroomManager } from "../../../models/vroom";
import { getIntegerFromCoordinate } from "../../../utils";
import { convertObjectToFSTimestamp } from "../../../utils/convertObjectToFSTimestamp";
import {
  getTaskSpecificDetailsString,
  TaskTypesValues,
} from "../../../models/task-types";
import { SmartSchedulingFormValues } from "../../../models/smart-scheduling-options";

export const VROOM_TASK_CONFIG = {
  DEFAULT_SERVICE_WINDOW_PERCENTAGE: 0.75,
};

export function convertTasksToVroomJobs(args: {
  tasks: ExistingTask[];
  getLocationDoc: (locationID: string) => ExistingSiteKeyLocation;
  portableSkill: number;
  vehicleIDsAsSkills: Record<string, number>;
  smartSchedulingSettings: SmartSchedulingFormValues;
}): VroomJob[] {
  const vroomJobs: VroomJob[] = [];
  for (let index = 0; index < args.tasks.length; index++) {
    const task = args.tasks[index];
    const longitude = task.longitude;
    const latitude = task.latitude;

    if (typeof longitude !== "number" || typeof latitude !== "number") {
      throw Error("Coordinates are not proper numbers");
    }

    const scheduledServiceWindowStart =
      task.taskSpecificDetails["scheduledServiceWindowStart"];
    const scheduledServiceWindowEnd =
      task.taskSpecificDetails["scheduledServiceWindowEnd"];

    const startSeconds = convertObjectToFSTimestamp(
      scheduledServiceWindowStart,
    ).seconds;
    const endSeconds = convertObjectToFSTimestamp(
      scheduledServiceWindowEnd,
    ).seconds;

    const skills = VroomManager.getJobSkills([task.craftType], [task.taskType]);
    // must use the longitude of the location doc, can't use the task's.
    const locationDoc = args.getLocationDoc(task.locationID);
    const locationSkill = getIntegerFromCoordinate(locationDoc.longitude);
    skills.push(locationSkill);

    // if task has lockAssignedVehicleID, add it as a skill
    if (
      task.taskSpecificDetails.lockAssignedVehicle === true &&
      typeof task.taskSpecificDetails.assignedVehicleID === "string" &&
      args.vehicleIDsAsSkills[task.taskSpecificDetails.assignedVehicleID]
    ) {
      skills.push(
        args.vehicleIDsAsSkills[task.taskSpecificDetails.assignedVehicleID],
      );
    }

    // if (
    //   typeof task.taskSpecificDetails.portableJob === "boolean" &&
    //   task.taskSpecificDetails.portableJob === true
    // ) {
    //   skills.push(args.portableSkill);
    // }

    // console.log(`TASK ${task.title}\nSKILLS`, skills); // REMOVE:

    let serviceDuration = 0;
    if (
      typeof task.taskSpecificDetails.actualTime === "number" &&
      task.taskSpecificDetails.actualTime > 0.15
    ) {
      serviceDuration = task.taskSpecificDetails.actualTime * 60 * 60;
    } else if (
      typeof task.taskSpecificDetails.plannedHours === "number" &&
      task.taskSpecificDetails.plannedHours > 0
    ) {
      serviceDuration = task.taskSpecificDetails.plannedHours * 60 * 60;
    } else {
      serviceDuration = getServiceDurationForTask(
        task.taskType,
        endSeconds / 60 / 60 - startSeconds / 60 / 60,
        args.smartSchedulingSettings,
      );
    }

    const priority = task.taskSpecificDetails.schedulingPriority ?? 1;

    const vroomJob: VroomJob = {
      id: index,
      skills: skills,
      location: [longitude, latitude],
      time_windows: [[startSeconds, endSeconds]],
      service: Math.ceil(serviceDuration),
      description: task.id,
      priority: Number(priority),
    };
    vroomJobs.push(vroomJob);
  }
  return vroomJobs;
}

function getServiceDurationForTask(
  taskType: TaskTypesValues,
  serviceWindowDurationHours: number,
  smartSchedulingSettings: SmartSchedulingFormValues,
): number {
  const taskTypeString = getTaskSpecificDetailsString(taskType);
  const defaultDurationMinutes =
    smartSchedulingSettings?.defaultWorkDurationByTaskType?.[taskTypeString];

  if (defaultDurationMinutes) {
    return defaultDurationMinutes * 60;
  }

  // Use config value instead of hard-coded 0.75
  return (
    serviceWindowDurationHours *
    VROOM_TASK_CONFIG.DEFAULT_SERVICE_WINDOW_PERCENTAGE *
    60 *
    60
  );
}
