// Libs
import { useCallback, useEffect, useState } from "react";
import { User } from "firebase/auth";
import { useMutation } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

// Local
import { useAuthStore } from "../../store/firebase-auth";
import { useMembershipTemplatesStore } from "../../store/membership-templates";
import { useToastMessageStore } from "../../store/toast-messages";
import { useTypesenseStore } from "../../store/typesense";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { useUserPermissionsStore } from "../../store/user-permissions";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import { DbRead, DbWrite } from "../../database";
import { logger } from "../../logging";
import { createToastMessageID } from "../../utils";
import { MEMBERSHIP_TEMPLATES } from "../../urls";
import * as strings from "../../strings";
import {
  ExistingMembershipTemplate,
  MembershipTemplate_UpdateAPI,
  MembershipTemplateManager,
  TaskGeneration,
} from "../../models/membership-template";
import EditTemplatePage from "./EditTemplatePage";
import { getTemplateFormSections } from "./template-utils";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import AddEditTaskGenerationDialog from "./AddEditTaskGenerationDialog";
import { typesensePriceBookItemsQuery } from "../../utils/typesenseQueries";
import { ExistingPriceBookItem } from "../../models/price-book-item";
import { diffObjects } from "../../assets/js/object-diff";
import {
  DialogStates,
  TemplateFormState,
  TemporaryTaskGeneration,
} from "./types";

export default function EditTemplateContainer() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const templateFromState: ExistingMembershipTemplate | null = state
    ? state.membershipTemplateDoc
    : null;

  // if user direct-navs to a template URL, we won't have the template in location
  // state. need to get the ID via params and then fetch the template
  type UrlParams = { templateId: string };
  const data = useParams<UrlParams>();
  const templateID = data.templateId;
  if (typeof templateID !== "string") {
    throw new Error(`templateID was not a string: ${templateID}`);
  }

  const firebaseUser = useAuthStore((state) => state.firebaseUser) as User;
  const addMessage = useToastMessageStore((state) => state.addToastMessage);
  const [fetchMembershipTemplateList, membershipTemplates] =
    useMembershipTemplatesStore((state) => [
      state.fetch,
      state.membershipTemplates,
    ]);
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const [typesenseSearchKey, typesenseLoading] = useTypesenseStore((state) => [
    state.scopedSearchKey,
    state.loading,
  ]);
  const [siteKeyDoc, siteKeyDocIsLoading] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.loading,
  ]);
  const siteKeyLocationList = useSiteKeyLocationsStore(
    (state) => state.siteKeyLocationList,
  );

  // States
  const [typesensePBIs, setTypesensePBIs] = useState<ExistingPriceBookItem[]>(
    [],
  );
  const [dialogStates, setDialogStates] = useState<DialogStates>({
    pbi: false,
    renewalPBI: false,
    taskGen: false,
  });
  const [pbi, setPBI] = useState<ExistingPriceBookItem | null>(null);
  const [renewalPBI, setRenewalPBI] = useState<ExistingPriceBookItem | null>(
    null,
  );
  const [taskGenList, setTaskGenList] = useState<TemporaryTaskGeneration[]>([]);
  const [taskGenDoc, setTaskGenDoc] = useState<TemporaryTaskGeneration | null>(
    null,
  );
  const [template, setTemplate] = useState<ExistingMembershipTemplate | null>(
    templateFromState,
  );

  // Effects and callbacks
  useEffect(() => {
    if (templateFromState) return;
    const template = membershipTemplates.find((t) => t.id === templateID);
    if (template) setTemplate(template);
  }, [templateFromState, membershipTemplates, templateID]);
  // ^ alternatively, could --
  // useEffect(() => {
  //   function getTemplate() {
  //     // fetch template if it wasn't passed in location state
  //     if (templateFromState) return undefined;
  //     if (!siteKeyDoc || !templateID) return undefined;
  //     const unsubscribe = DbRead.membershipTemplates.subscribe({
  //       siteKey: siteKeyDoc.id,
  //       templateID: templateID,
  //       onChange: setTemplate,
  //       onError: logger.error,
  //     });
  //     return unsubscribe;
  //   }
  //   const unsubFn = getTemplate();
  //   return () => unsubFn && unsubFn();
  // }, [siteKeyDoc, templateFromState, templateID]);

  useEffect(() => {
    async function getPBItems() {
      if (!typesenseSearchKey) return;
      const pbItems = await typesensePriceBookItemsQuery(
        typesenseSearchKey,
        "",
      );
      setTypesensePBIs(pbItems);
    }
    getPBItems();
  }, [typesenseSearchKey]);

  // typesense returns up to 250 items in the initial query. fetch specific items if needed
  useEffect(() => {
    async function setPBIs() {
      if (typesensePBIs.length === 0) return;
      if (!siteKeyDoc || !typesenseSearchKey || !template) return;

      if (template.priceBookItemID) {
        const foundPBI = typesensePBIs.find(
          (pbi) => pbi.id === template.priceBookItemID,
        );
        if (foundPBI) {
          setPBI(foundPBI);
        } else {
          const item = await DbRead.priceBookItems.get(
            siteKeyDoc.id,
            template.priceBookItemID,
          );
          if (item) setPBI(item);
        }
      }
      // same process for renewal PBI
      if (template.renewalPriceBookItemID) {
        const foundPBI = typesensePBIs.find(
          (pbi) => pbi.id === template.renewalPriceBookItemID,
        );
        if (foundPBI) {
          setRenewalPBI(foundPBI);
        } else {
          const item = await DbRead.priceBookItems.get(
            siteKeyDoc.id,
            template.renewalPriceBookItemID,
          );
          if (item) setRenewalPBI(item);
        }
      }
    }
    setPBIs();
  }, [siteKeyDoc, template, typesensePBIs, typesenseSearchKey]);

  useEffect(() => {
    if (!template) return;
    const taskGenList: TemporaryTaskGeneration[] = template.taskGeneration.map(
      (task) => ({
        ...task,
        id: uuidv4(),
      }),
    );
    setTaskGenList(taskGenList);
  }, [template]);

  const onFilterTextBoxChanged = useCallback(
    async (searchTerm: string) => {
      if (!typesenseSearchKey) return;
      const pbItems = await typesensePriceBookItemsQuery(
        typesenseSearchKey,
        searchTerm,
      );
      setTypesensePBIs(pbItems);
    },
    [typesenseSearchKey],
  );

  // Mutations
  const mutateEditTemplate = useMutation(
    async (validTemplate: MembershipTemplate_UpdateAPI) => {
      await DbWrite.membershipTemplates.update(validTemplate);
    },
  );

  // Functions
  function goToTemplatesPage() {
    navigate(`${MEMBERSHIP_TEMPLATES}`);
    resetPricebookItems();
    setTaskGenList([]);
  }

  function resetPricebookItems() {
    setPBI(null);
    setRenewalPBI(null);
  }

  async function handleEditTemplate(formValues: TemplateFormState) {
    if (!template || !firebaseUser) return;

    if (!pbi) {
      addMessage({
        id: createToastMessageID(),
        message: strings.SELECT_PBI,
        dialog: false,
        type: "error",
      });
      return;
    }

    // turns out, the ids only matter BEFORE handleEditTemplate is called.
    const tasksSansIDs: TaskGeneration[] = [];
    // BOOMER LOOP BABY
    for (const task of taskGenList) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id: _, ...rest } = task;
      tasksSansIDs.push(rest);
    }

    const updatedTemplate: Partial<ExistingMembershipTemplate> = {
      ...formValues,
      priceBookItemID: pbi.id,
      price: pbi.unitPrice,
      renewalPriceBookItemID: renewalPBI?.id ?? null,
      taskGeneration: tasksSansIDs,
      automaticallyGenerateTasks:
        tasksSansIDs.length === 0
          ? false
          : formValues.automaticallyGenerateTasks,
    };
    // construct the comparison object
    const originalFormValues: TemplateFormState = {
      title: template.title,
      description: template.description,
      invoiceMethod: template.invoiceMethod,
      invoiceFrequency: template.invoiceFrequency,
      discount: template.discount,
      automaticallyGenerateTasks: template.automaticallyGenerateTasks,
      defaultDuration: template.defaultDuration ?? 12,
      automaticallyPayInvoice: template.automaticallyPayInvoice,
      automaticallySendInvoice: template.automaticallySendInvoice,
    };
    const ogValues = {
      ...originalFormValues,
      priceBookItemID: template.priceBookItemID,
      price: template.price,
      renewalPriceBookItemID: template.renewalPriceBookItemID,
      taskGeneration: template.taskGeneration,
    };

    const changedValues = diffObjects(ogValues, updatedTemplate).diff;
    if (Object.keys(changedValues).length === 0) return;

    changedValues.id = template.id;
    changedValues.refPath = template.refPath;
    changedValues.lastModifiedBy = firebaseUser.uid;

    const validTemplate = MembershipTemplateManager.parseUpdate(changedValues);
    try {
      await mutateEditTemplate.mutateAsync(validTemplate);
      goToTemplatesPage();
      if (siteKeyDoc && userPermissions) {
        fetchMembershipTemplateList(siteKeyDoc, userPermissions);
      }
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(
          validTemplate.title ?? template.title,
        ),
        dialog: false,
        type: "success",
      });
    } catch (error) {
      logger.error(error);
      addMessage({
        id: createToastMessageID(),
        message: strings.failedUpdate(template.title),
        dialog: false,
        type: "error",
      });
    }
  }

  const { pbiComponent, renewalPBIComponent, taskGenComponent } =
    getTemplateFormSections({
      pbi,
      renewalPBI,
      dialogStates,
      setDialogStates,
      onFilterTextBoxChanged,
      typesensePBIs,
      siteKeyDoc,
      setPBI,
      setRenewalPBI,
      taskGenerationList: taskGenList,
      setTaskGenerationList: setTaskGenList,
      setTaskGenerationDoc: setTaskGenDoc,
    });

  // Components
  const taskGenDialog = siteKeyDoc && (
    <AddEditTaskGenerationDialog
      isDialogOpen={dialogStates.taskGen}
      closeDialog={() => {
        setDialogStates((prev) => ({ ...prev, taskGen: false }));
        setTaskGenDoc(null);
      }}
      taskGenerationDoc={taskGenDoc}
      siteKeyDoc={siteKeyDoc}
      siteKeyLocationList={siteKeyLocationList}
      handleAddNewTask={(task) => {
        setTaskGenList((prev) => [...prev, { ...task, id: uuidv4() }]);
        setTaskGenDoc(null);
      }}
      handleEditTask={(editTask) => {
        setTaskGenList((prev) =>
          prev.map((task) => (task.id === editTask.id ? editTask : task)),
        );
        setTaskGenDoc(null);
      }}
    />
  );

  if (typesenseLoading || siteKeyDocIsLoading || !template) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  return (
    <>
      <EditTemplatePage
        template={template}
        onSubmit={handleEditTemplate}
        onCancel={goToTemplatesPage}
      >
        {{
          pbiSection: pbiComponent,
          renewalPBISection: renewalPBIComponent,
          taskGenSection: taskGenComponent,
        }}
      </EditTemplatePage>
      {taskGenDialog}
    </>
  );
}
