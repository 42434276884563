//Libs
import React from "react";

const styles: React.CSSProperties = {
  display: "block",
  height: "38px",
  width: "38px",
  cursor: "pointer",
  padding: "6px",
};

type Props = {
  /** default is mr-1 */ // this component actually shouldn't have a defined margin. but i'm not gonna look at every place we use this to determine the appropriate changes.
  overrideMargin?: string;
  /** default is hover:bg-primaryOpacity90 */
  hoverColor?: string;
  /** override or add to the usual inlined styles */
  overrideStyles?: React.CSSProperties;
} & React.ComponentPropsWithRef<"button">;

export const PencilIconWithRef = React.forwardRef<HTMLButtonElement, Props>(
  ({ overrideMargin, overrideStyles, hoverColor, ...props }, ref) => {
    return (
      <button
        ref={ref}
        {...props}
        className={`${overrideMargin || "mr-1"} ${hoverColor || "hover:bg-primaryOpacity90"} rounded-full text-gray-700`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          style={{ ...styles, ...overrideStyles }}
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
        </svg>
      </button>
    );
  },
);
