import {
  Fragment,
  memo,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import {
  ColDef,
  ICellRendererParams,
  RowClickedEvent,
} from "ag-grid-community";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import PersonIcon from "@mui/icons-material/Person";

import {
  ExistingCancellationReason,
  ExistingCancellationSource,
} from "../../models/cancellation-manager";
import * as strings from "../../strings";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import { Tabs, Tab } from "@mui/material";
import HeadingOne from "../../components/HeadingOne";

interface Props {
  reasonsList: ExistingCancellationReason[];
  sourcesList: ExistingCancellationSource[];
  activeTab: "reasons" | "sources";
  onTabChange: (tab: "reasons" | "sources") => void;
  onAddNew: () => void;
  onEdit: (
    item: ExistingCancellationReason | ExistingCancellationSource,
  ) => void;
}

const headerStyles =
  "px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase";

const commonColProps = {
  minWidth: 150,
  flex: 1,
  resizable: true,
  filter: true,
  sortable: true,
  headerClass: headerStyles,
};

export default function CancellationManagementPage({
  reasonsList,
  sourcesList,
  activeTab,
  onTabChange,
  onAddNew,
  onEdit,
}: Props) {
  return (
    <div>
      <HeadingOne>{strings.CANCELLATION_MANAGEMENT}</HeadingOne>

      <div className="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm transition-all">
          <div className="flex items-center gap-2">
            <div className="rounded-full bg-blue-100 p-1.5">
              <CancelIcon className="text-blue-600" fontSize="small" />
            </div>
            <h2 className="text-lg font-semibold text-blue-600">
              Cancellation Reasons
            </h2>
          </div>
          <p className="mt-2 text-gray-600">
            Specific reasons why a task was cancelled. Examples might include:
          </p>
          <ul className="mt-1 list-inside list-disc text-gray-600">
            <li>Customer not home</li>
            <li>Truck broke down</li>
            <li>Customer says price is too high</li>
            <li>Bad weather</li>
          </ul>
        </div>

        <div className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm transition-all">
          <div className="flex items-center gap-2">
            <div className="rounded-full bg-blue-100 p-1.5">
              <PersonIcon className="text-blue-600" fontSize="small" />
            </div>
            <h2 className="text-lg font-semibold text-blue-600">
              Cancellation Sources
            </h2>
          </div>
          <p className="mt-2 text-gray-600">
            The source that initiated the cancellation. Examples might include:
          </p>
          <ul className="mt-1 list-inside list-disc text-gray-600">
            <li>Customer</li>
            <li>Technician</li>
            <li>Salesperson</li>
            <li>Internal</li>
          </ul>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-between border-b border-gray-200">
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => onTabChange(newValue)}
        >
          <Tab label="Reasons" value="reasons" />
          <Tab label="Sources" value="sources" />
        </Tabs>

        <BaseButtonPrimary
          type="button"
          onClick={onAddNew}
          className="mb-1 text-primary sm:w-48"
        >
          <AddCircleIcon fontSize="small" className="mr-2" />
          {activeTab === "reasons"
            ? strings.ADD_NEW_REASON
            : strings.ADD_NEW_SOURCE}
        </BaseButtonPrimary>
      </div>

      <div className={"ag-theme-alpine my-4 flex h-[600px] flex-col"}>
        {activeTab === "reasons" ? (
          <CancellationTable
            items={reasonsList}
            onEdit={onEdit}
            type="reasons"
          />
        ) : (
          <CancellationTable
            items={sourcesList}
            onEdit={onEdit}
            type="sources"
          />
        )}
      </div>
    </div>
  );
}

interface TableProps {
  items: (ExistingCancellationReason | ExistingCancellationSource)[];
  type: "reasons" | "sources";
  onEdit: Props["onEdit"];
}

const CancellationTable = memo(({ items, type, onEdit }: TableProps) => {
  const [gridReady, setGridReady] = useState(false);
  const gridRef = useRef<any>(null);

  const onGridReady = useCallback(() => {
    setGridReady(true);
  }, []);

  useLayoutEffect(() => {
    if (gridRef.current && gridReady) {
      gridRef.current.api?.sizeColumnsToFit();
    }
  }, [gridReady]);

  const renderActiveStatus = (params: ICellRendererParams): JSX.Element => {
    return (
      <div className="capitalize">{params.value ? "Active" : "Inactive"}</div>
    );
  };

  const columnDefs: ColDef[] = [
    {
      field: "title",
      headerName: "Title",
      tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
    },
    {
      field: "description",
      headerName: "Description",
      tooltipValueGetter: (params) => params.valueFormatted ?? params.value,
    },
    {
      field: "active",
      headerName: "Status",
      cellRenderer: renderActiveStatus,
    },
  ];

  return (
    <Fragment>
      <AgGridReact
        reactiveCustomComponents
        ref={gridRef}
        onGridReady={onGridReady}
        defaultColDef={commonColProps}
        className="mt-5 shadow"
        rowData={items}
        animateRows={true}
        rowSelection="single"
        rowHeight={50}
        cacheQuickFilter={true}
        columnDefs={columnDefs}
        onRowClicked={(event: RowClickedEvent) => onEdit(event.data)}
      />
    </Fragment>
  );
});
