//Libs
// import { useState } from "react";
// import SearchIcon from "@mui/icons-material/Search";

//Local

interface Props {
  handleSearchTerm: (newTerm: string) => void;
  placeholder: string;
  /** pass true to return the search box with no margin classes attached to it. */
  removeMargin?: boolean;
}

export default function SchedulingSearchBox({
  handleSearchTerm,
  placeholder,
  removeMargin,
}: Props) {
  // const [inputValue, setInputValue] = useState<string>("");

  function onSearch(searchTerm: string) {
    handleSearchTerm(searchTerm);
  }

  return (
    <input
      className={`${removeMargin ? "" : "mr-2"} w-full overflow-hidden rounded-md border border-primary px-2.5 py-1.5 text-base outline-none placeholder:text-gray-500 focus:border-primaryLight focus:ring-1 focus:ring-primaryLight`}
      type="text"
      placeholder={placeholder}
      onChange={(event) => {
        onSearch(event.target.value);
      }}
    />
  );
}
