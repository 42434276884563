import { Fragment, useState } from "react";
import { useUserPermissionsStore } from "../../store/user-permissions";
import { DbRead, DbWrite } from "../../database";
import { logger } from "../../logging";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import { useEffect } from "react";
import {
  ExistingCancellationReason,
  ExistingCancellationSource,
  CancellationReason_CreateAPI,
  CancellationSource_CreateAPI,
} from "../../models/cancellation-manager";
import { createToastMessageID } from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";
import * as strings from "../../strings";
import CancellationManagementPage from "./CancellationManagementPage";
import AddEditCancellationReasonDialog from "../../components/Cancellations/AddEditCancellationReasonDialog";
import AddEditCancellationSourceDialog from "../../components/Cancellations/AddEditCancellationSourceDialog";

interface Props {
  siteKey: string;
}

export default function CancellationManagementContainer({ siteKey }: Props) {
  // Stores
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  // State
  const [reasonsList, setReasonsList] = useState<ExistingCancellationReason[]>(
    [],
  );
  const [sourcesList, setSourcesList] = useState<ExistingCancellationSource[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState<"reasons" | "sources">("reasons");

  // Dialog state
  const [reasonDialogOpen, setReasonDialogOpen] = useState(false);
  const [sourceDialogOpen, setSourceDialogOpen] = useState(false);
  const [selectedReason, setSelectedReason] =
    useState<ExistingCancellationReason | null>(null);
  const [selectedSource, setSelectedSource] =
    useState<ExistingCancellationSource | null>(null);

  // Load data
  useEffect(() => {
    DbRead.cancellationReasons.subsrcibeAll({
      siteKey,
      onChange: setReasonsList,
      onError: (error) => {
        logger.error("Error subscribing to cancellation reasons:", error);
        addMessage({
          id: createToastMessageID(),
          message: strings.UNEXPECTED_ERROR,
          type: "error",
          dialog: false,
        });
      },
    });
    DbRead.cancellationSources.subsrcibeAll({
      siteKey,
      onChange: setSourcesList,
      onError: (error) => {
        logger.error("Error subscribing to cancellation sources:", error);
        addMessage({
          id: createToastMessageID(),
          message: strings.UNEXPECTED_ERROR,
          type: "error",
          dialog: false,
        });
      },
    });

    setIsLoading(false);

    return;
  }, [siteKey, addMessage]);

  // Handlers
  function handleAddNew() {
    if (activeTab === "reasons") {
      setSelectedReason(null);
      setReasonDialogOpen(true);
    } else {
      setSelectedSource(null);
      setSourceDialogOpen(true);
    }
  }

  function handleEdit(
    item: ExistingCancellationReason | ExistingCancellationSource,
  ) {
    if (activeTab === "reasons") {
      setSelectedReason(item as ExistingCancellationReason);
      setReasonDialogOpen(true);
    } else {
      setSelectedSource(item as ExistingCancellationSource);
      setSourceDialogOpen(true);
    }
  }

  async function handleDelete(id: string) {
    // Implement delete logic here
    // You'll need to add delete methods to DbWrite first
    logger.debug("Delete not yet implemented for id:", id);
  }

  if (isLoading) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  return (
    <Fragment>
      <CancellationManagementPage
        reasonsList={reasonsList}
        sourcesList={sourcesList}
        activeTab={activeTab}
        onTabChange={setActiveTab}
        onAddNew={handleAddNew}
        onEdit={handleEdit}
      />

      {reasonDialogOpen && (
        <AddEditCancellationReasonDialog
          siteKey={siteKey}
          isDialogOpen={reasonDialogOpen}
          closeDialog={() => {
            setReasonDialogOpen(false);
            setSelectedReason(null);
          }}
          selectedItem={selectedReason}
          onCreate={(data) =>
            DbWrite.cancellationReasons.create({ siteKey, reason: data })
          }
          onUpdate={(data) =>
            DbWrite.cancellationReasons.update({ siteKey, reason: data })
          }
          onDelete={handleDelete}
        />
      )}

      {sourceDialogOpen && (
        <AddEditCancellationSourceDialog
          siteKey={siteKey}
          isDialogOpen={sourceDialogOpen}
          closeDialog={() => {
            setSourceDialogOpen(false);
            setSelectedSource(null);
          }}
          selectedItem={selectedSource}
          onCreate={(data) =>
            DbWrite.cancellationSources.create({ siteKey, source: data })
          }
          onUpdate={(data) =>
            DbWrite.cancellationSources.update({ siteKey, source: data })
          }
          onDelete={handleDelete}
        />
      )}
    </Fragment>
  );
}
