import { useState } from "react";
import BaseButtonSecondary from "../../../components/BaseButtonSecondary";

interface PaymentTerm {
  title: string;
  daysUntilDue: number;
}

interface Props {
  value: Record<string, PaymentTerm>;
  onChange?: (terms: Record<string, PaymentTerm>) => void;
}

const DEFAULT_TERMS: Record<string, PaymentTerm> = {
  onReceipt: {
    title: "Due on receipt",
    daysUntilDue: 0,
  },
  net30: {
    title: "Net 30",
    daysUntilDue: 30,
  },
  net60: {
    title: "Net 60",
    daysUntilDue: 60,
  },
};

export default function PaymentTermsEditor({
  value = DEFAULT_TERMS,
  onChange,
}: Props) {
  const [terms, setTerms] = useState<Record<string, PaymentTerm>>(value);

  const generateKey = (title: string, daysUntilDue: number): string => {
    const baseKey = title
      .toLowerCase()
      .replace(/[^a-z0-9]/g, "")
      .trim();
    return baseKey || `net${daysUntilDue}`;
  };

  const addNewTerm = () => {
    const newKey = generateKey("New Term", 0);
    const newTerms = {
      ...terms,
      [newKey]: { title: "", daysUntilDue: 0 },
    };
    setTerms(newTerms);
    onChange?.(newTerms);
  };

  const removeTerm = (key: string) => {
    const newTerms = { ...terms };
    delete newTerms[key];
    setTerms(newTerms);
    onChange?.(newTerms);
  };

  const updateTerm = (
    key: string,
    field: keyof PaymentTerm,
    value: string | number,
  ) => {
    const updatedTerm = { ...terms[key], [field]: value };
    const newKey =
      field === "title"
        ? generateKey(value as string, updatedTerm.daysUntilDue)
        : key;

    const newTerms = { ...terms };
    delete newTerms[key];
    newTerms[newKey] = updatedTerm;

    setTerms(newTerms);
    onChange?.(newTerms);
  };

  return (
    <div className="space-y-2">
      {/* Column Headers */}
      <div className="flex gap-2 px-2">
        <div className="w-1/2 font-medium text-gray-700">Title</div>
        <div className="w-1/3 font-medium text-gray-700">Days Until Due</div>
      </div>

      {/* Payment Terms */}
      {Object.entries(terms).map(([key, term]) => (
        <div key={key} className="flex gap-2">
          <input
            type="text"
            className="block w-1/2 rounded border border-black p-2 text-gray-700 focus:ring"
            placeholder="Term Title"
            value={term.title}
            onChange={(e) => updateTerm(key, "title", e.target.value)}
          />
          <input
            type="number"
            className="block w-1/3 rounded border border-black p-2 text-gray-700 focus:ring"
            placeholder="Days"
            value={term.daysUntilDue}
            min={0}
            onChange={(e) =>
              updateTerm(key, "daysUntilDue", parseInt(e.target.value) || 0)
            }
          />
          <button
            type="button"
            onClick={() => removeTerm(key)}
            className="rounded border border-red-500 px-2 py-1 text-red-500 hover:bg-red-50"
          >
            Remove
          </button>
        </div>
      ))}
      <BaseButtonSecondary
        type="button"
        onClick={addNewTerm}
        className="rounded border border-black px-3 py-1 hover:bg-gray-50"
      >
        Add Payment Term
      </BaseButtonSecondary>
    </div>
  );
}
