import create from "zustand";

interface ActiveChatRoomState {
  activeChatRoomId: string | null;
  activeCraftRecordId: string | null;
  activeCustomerId: string | null;
  isOpen: boolean;
  isHandlingJobChat: boolean;
  isHandlingCustomerChat: boolean;
  setActiveChatRoom: (chatRoomID: string) => void;
  setActiveCraftRecordId: (craftRecordId: string) => void;
  setActiveCustomerId: (customerId: string) => void;
  clearActiveChatRoom: () => void;
  clearActiveCraftRecordId: () => void;
  clearActiveCustomerId: () => void;
  openChat: () => void;
  closeChat: () => void;
  handleJobChat: () => void;
  handleCustomerChat: () => void;
  stopHandlingJobChat: () => void;
  stopHandlingCustomerChat: () => void;
}

export const useActiveChatRoomStore = create<ActiveChatRoomState>((set) => ({
  activeChatRoomId: null,
  activeCraftRecordId: null,
  activeCustomerId: null,
  isOpen: false,
  isHandlingJobChat: false,
  isHandlingCustomerChat: false,
  setActiveChatRoom: (chatRoomID: string) =>
    set(() => ({ activeChatRoomId: chatRoomID, isOpen: true })),
  clearActiveChatRoom: () => set(() => ({ activeChatRoomId: null })),
  setActiveCraftRecordId: (craftRecordId: string) =>
    set(() => ({ activeCraftRecordId: craftRecordId })),
  clearActiveCraftRecordId: () => set(() => ({ activeCraftRecordId: null })),
  setActiveCustomerId: (customerId: string) =>
    set(() => ({ activeCustomerId: customerId })),
  clearActiveCustomerId: () => set(() => ({ activeCustomerId: null })),
  openChat: () => set(() => ({ isOpen: true })),
  closeChat: () => set(() => ({ isOpen: false })),
  handleJobChat: () => set(() => ({ isHandlingJobChat: true })),
  stopHandlingJobChat: () => set(() => ({ isHandlingJobChat: false })),
  handleCustomerChat: () => set(() => ({ isHandlingCustomerChat: true })),
  stopHandlingCustomerChat: () =>
    set(() => ({ isHandlingCustomerChat: false })),
}));
