import { Timestamp } from "firebase/firestore";
// Local
import { logger } from "../logging";
import {
  ExistingMembership,
  Membership,
  MembershipStatus,
} from "../models/membership";
import {
  ExistingMembershipTemplate,
  InvoiceFrequency,
  InvoiceMethod,
} from "../models/membership-template";
import { DbRead } from "../database";
import { getMonthsBetween } from "./getMonthsBetween";

// REUSABLE HELPER FUNCTIONS FOR TEMPLATES AND MEMBERSHIPS.
// gazz that doesn't quite fit elsewhere.

export const durationIntervals = ["Months", "Years"] as const;
export type DurationInterval = (typeof durationIntervals)[number];

/** Convert a duration from one interval to another */
export function convertDuration(
  duration: number,
  fromInterval: DurationInterval,
  toInterval: DurationInterval,
): number {
  if (fromInterval === "Months" && toInterval === "Years") {
    return duration / 12;
  }
  if (fromInterval === "Years" && toInterval === "Months") {
    return duration * 12;
  }
  return duration;
}

/** Convert invoice frequency to number of months. Returns Infinity if the frequency is indefinite. */
export function getInvoiceFrequencyInMonths(
  frequency: InvoiceFrequency,
): number {
  switch (frequency) {
    case "annual":
      return 12;
    case "semiannual":
      return 6;
    case "quarterly":
      return 3;
    case "monthly":
      return 1;
    case "indefinite":
      return Infinity;
    default:
      const _exhaustiveCheck: never = frequency;
      logger.error(`Unhandled invoice frequency: ${_exhaustiveCheck}`);
      return 0;
  }
}

// TODO: make this synchronous. pass in template-referenced pbi unitPrice, if it exists.
/**
 * the intersection of the membership and its template yield a variety of
 * possible combinations. this function returns the current configuration/state
 * of the given membership.
 */
export async function getMembershipState(
  membership: ExistingMembership,
  template: ExistingMembershipTemplate,
  /** `SiteKeyCustomizations.sendAutomatedReceiptToCustomersForMemberships`
   *
   * will be used if `membership.automaticallySendReceipt` is not a boolean.
   */
  defaultEmailDuesCollectedReceipt: boolean,
): Promise<MembershipConfig> {
  const autoEmailReceipt =
    typeof membership.automaticallySendReceipt === "boolean"
      ? membership.automaticallySendReceipt
      : defaultEmailDuesCollectedReceipt;

  let autoEmailInvoice = false;
  if (typeof membership.automaticallySendInvoice === "boolean") {
    autoEmailInvoice = membership.automaticallySendInvoice;
  } else if (typeof template.automaticallySendInvoice === "boolean") {
    autoEmailInvoice = template.automaticallySendInvoice;
  }

  let autoPayInvoice = false;
  if (typeof membership.automaticallyPayInvoice === "boolean") {
    autoPayInvoice = membership.automaticallyPayInvoice;
  } else if (typeof template.automaticallyPayInvoice === "boolean") {
    autoPayInvoice = template.automaticallyPayInvoice;
  }

  let autoGenerateTasks = false;
  if (typeof membership.automaticallyGenerateTasks === "boolean") {
    autoGenerateTasks = membership.automaticallyGenerateTasks;
  } else if (typeof template.automaticallyGenerateTasks === "boolean") {
    autoGenerateTasks = template.automaticallyGenerateTasks;
  }

  let price: number;
  if (membership.overrideData?.invoice) {
    price = membership.overrideData.invoice.totalAmount;
  } else if (template.priceBookItemID) {
    const siteKey = membership.refPath.split("/")[1];
    const pbi = await DbRead.priceBookItems.get(
      siteKey,
      template.priceBookItemID,
    );
    if (pbi) {
      price = pbi.unitPrice;
    } else {
      logger.error(
        `PBI not found for template @ ${template.refPath}. Using template.price`,
      );
      price = template.price;
    }
  } else {
    price = template.price;
  }

  let duration: number;
  if (membership.membershipStartDate && membership.membershipEndDate) {
    const result = getMonthsBetween(
      membership.membershipStartDate,
      membership.membershipEndDate,
    );
    if (result) {
      duration = result;
    } else {
      logger.error(
        `${membership.refPath}: Error getting months between membership start and end. Defaulting to template's defaultDuration`,
      );
      duration =
        typeof template.defaultDuration === "number"
          ? template.defaultDuration
          : 12;
    }
  } else {
    duration =
      typeof template.defaultDuration === "number"
        ? template.defaultDuration
        : 12;
  }

  const config: MembershipConfig = {
    id: membership.id,
    invoiceMethod: template.invoiceMethod,
    invoiceFrequency: template.invoiceFrequency,
    status: membership.status as MembershipStatus,
    nextInvoiceDate: membership.nextInvoiceDate,
    membershipEndDate: membership.membershipEndDate,
    membershipStartDate: membership.membershipStartDate,
    overrideData: membership.overrideData,

    automaticallySendReceipt: autoEmailReceipt,
    automaticallySendInvoice: autoEmailInvoice,
    automaticallyPayInvoice: autoPayInvoice,
    automaticallyGenerateTasks: autoGenerateTasks,
    price: price,
    duration: duration,
  };
  return config;
}

export type MembershipConfig = {
  /** membership.id */
  id: string;
  /** template.invoiceMethod */
  invoiceMethod: InvoiceMethod;
  /** template.invoiceFrequency */
  invoiceFrequency: InvoiceFrequency;
  /** membership.status */
  status: MembershipStatus;
  /** __value in months. -1 means indefinite__
   *
   * if membership start and end dates are defined, `duration` will be calculated
   * as the difference between the two dates, in months.
   *
   * otherwise, `duration` will be the value of `template.defaultDuration`.
   * if template.defaultDuration is not defined, `duration` will default to 12.
   */
  duration: number;
  /** membership.nextInvoiceDate */
  nextInvoiceDate: Timestamp | null;
  /** membership.membershipEndDate */
  membershipEndDate: Timestamp | null;
  /** membership.membershipStartDate */
  membershipStartDate: Timestamp | null;
  /**
   * if the membership has an override invoice, this will be that totalAmount.
   *
   * if there's no override invoice, looks to the template's priceBookItemID.
   * if present, will fetch that PBI and use the unitPrice. if not present,
   * uses template.price.
   */
  // TODO: think about this one. i hate that this func will have to be async cus of this..
  price: number;
  /** if `membership.automaticallySendReceipt` is not a boolean, falls back
   * to `defaultEmailDuesCollectedReceipt`.
   *
   * is only applicable if invoiceMethod is automatic. */
  automaticallySendReceipt: boolean;
  /** if `membership.automaticallySendInvoice` is not a boolean, falls back
   * to `template.automaticallySendInvoice`.
   *
   * is only applicable if invoiceMethod is automatic. */
  automaticallySendInvoice: boolean;
  /** if `membership.automaticallyPayInvoice` is not a boolean, falls back
   * to `template.automaticallyPayInvoice`.
   *
   * is only applicable if invoiceMethod is automatic. */
  automaticallyPayInvoice: boolean;
  /** if `membership.automaticallyGenerateTasks` is not a boolean, falls back
   * to `template.automaticallyGenerateTasks`.
   *
   * is only applicable if the template has tasks. (taskGeneration list) */
  automaticallyGenerateTasks: boolean;
  /** membership.overrideData */
  overrideData: Membership["overrideData"];
};
