//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";

//Local
import * as strings from "../../strings";
import BaseModal from "../BaseModal";
import BaseInputText from "../BaseInputText";
import StyledMessage from "../StyledMessage";
import BaseInputTextArea from "../BaseInputTextArea";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseInputCheckbox from "../BaseInputCheckbox";
import {
  ExistingStiltCalendarEvent,
  StiltCalendarEvent,
} from "../../models/stilt-calendar-event";
import ButtonColored from "../ButtonColored";
import { IMultipleUid_AssignUser } from "../CustomFields/MultipleUidDialog";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import { ExistingVehicle } from "../../models/vehicle";
import {
  getAssignedDefaults,
  StiltCalendarEventFormSchema,
  StiltCalendarEventFormState,
} from "./AddStiltCalendarEventDialog";
import { logger } from "../../logging";
import HeadingOne from "../HeadingOne";

interface Props {
  /* DATA */
  isOpen: boolean;
  stiltEvent: ExistingStiltCalendarEvent;
  userList: IMultipleUid_AssignUser[];
  vehicleList: ExistingVehicle[];
  /* FUNCTIONS */
  onClose: () => void;
  handleSave: (
    formValues: Omit<StiltCalendarEvent, "start" | "end">,
  ) => Promise<void>;
  handleDelete: () => Promise<void>;
  children: {
    StartDatePicker: React.ReactNode;
    EndDatePicker: React.ReactNode;
  };
}

export default function EditStiltCalendarEventDialog(props: Props) {
  const xusers = [...props.userList];
  xusers.sort((a, b) => a.name.localeCompare(b.name)); // sort method mutates original
  const xvehicles = [...props.vehicleList];
  xvehicles.sort((a, b) => a.title.localeCompare(b.title));

  const addMessage = useToastMessageStore((state) => state.addToastMessage);
  const [isDeleting, setIsDeleting] = useState(false);

  const resourcesMode =
    props.stiltEvent.assignedVehicleIDs &&
    props.stiltEvent.assignedVehicleIDs.length > 0
      ? "vehicles"
      : "technicians";

  const defaultValues: StiltCalendarEventFormState = {
    title: props.stiltEvent.title,
    description: props.stiltEvent.description,
    assignedTo:
      resourcesMode === "technicians"
        ? getAssignedDefaults({
            list: xusers,
            type: resourcesMode,
            selected: props.stiltEvent.assignedTo,
          })
        : [],
    assignedVehicleIDs:
      resourcesMode === "vehicles"
        ? getAssignedDefaults({
            list: xvehicles,
            type: resourcesMode,
            selected: props.stiltEvent.assignedVehicleIDs ?? [],
          })
        : [],
    // NOTE: resourcesMode should never be displayed on the UI in this component.
    // it's used to make sure the user has at least one resource selected -- look @ the refine method in the schema
    resourcesMode: resourcesMode,
  };

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
  } = useForm<StiltCalendarEventFormState>({
    defaultValues: defaultValues,
    resolver: zodResolver(StiltCalendarEventFormSchema),
    mode: "onChange",
  });

  function closeAndReset() {
    reset();
    props.onClose();
  }

  const onSubmit: SubmitHandler<StiltCalendarEventFormState> = async (
    formValues,
  ) => {
    const {
      assignedTo,
      assignedVehicleIDs,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      resourcesMode: _rm,
      ...rest
    } = formValues;

    const selectedUsers = assignedTo.filter(
      (uid): uid is string => uid != null,
    );
    const selectedVehicles = assignedVehicleIDs.filter(
      (vid): vid is string => vid != null,
    );

    let values: Omit<StiltCalendarEvent, "start" | "end">;
    if (resourcesMode === "technicians") {
      values = {
        ...rest,
        assignedTo: selectedUsers,
        // assignedVehicleIDs: [],
      };
    } else {
      values = {
        ...rest,
        assignedTo: [],
        assignedVehicleIDs: selectedVehicles,
      };
    }

    try {
      await props.handleSave(values);
      closeAndReset();
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate(`Calendar event: ${rest.title}`),
        dialog: false,
        type: "success",
      });
    } catch (e) {
      if (e instanceof Error) {
        logger.warn(e);
        logger.error(`handleEditCalendarEvent: ${e.name} ${e.message}`);
      } else {
        logger.error(`handleEditCalendarEvent`, e);
      }
      addMessage({
        id: createToastMessageID(),
        message: strings.failedUpdate(`calendar event: ${rest.title}`),
        dialog: true,
        type: "error",
      });
    }
  };

  async function onDelete() {
    setIsDeleting(true);
    try {
      await props.handleDelete();
      setIsDeleting(false);
      closeAndReset();
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulDelete(
          `Calendar event: ${props.stiltEvent.title}`,
        ),
        dialog: false,
        type: "success",
      });
    } catch (e) {
      if (e instanceof Error) {
        logger.warn(e);
        logger.error(`handleDeleteCalendarEvent: ${e.name} ${e.message}`);
      } else {
        logger.error(`handleDeleteCalendarEvent`, e);
      }
      setIsDeleting(false);
      addMessage({
        id: createToastMessageID(),
        message: strings.ERR_DELETE_CALENDAR_EVENT,
        dialog: true,
        type: "error",
      });
    }
  }

  const editStiltCalendarEventHeader = (
    <div className="flex w-full items-start justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <HeadingOne fontColor="text-white" fontSize="text-xl">
        {strings.EDIT_STILT_CALENDAR_EVENT}
      </HeadingOne>
      <button type="button" onClick={() => props.onClose()}>
        <XMarkIcon
          aria-label="close edit stilt calendar event form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={props.onClose}
      open={props.isOpen}
      title={editStiltCalendarEventHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="flex flex-col space-y-8 p-8 text-lg">
        <form autoComplete="off" className="w-full space-y-8">
          {/* Field: Title */}
          <div>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  text="Title"
                  inputName="title"
                  admin={true}
                  required={true}
                  {...field}
                />
              )}
            />
            {errors.title?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.title.message }}
                </StyledMessage>
              </div>
            )}
          </div>
          {/* Field: Description */}
          <div>
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <BaseInputTextArea
                  id="description"
                  admin={true}
                  text="Description"
                  rows={3}
                  {...field}
                  value={
                    field.value === null
                      ? undefined
                      : field.value /* because value may be null */
                  }
                  onChange={(event) => {
                    // Store empty values as null instead of undefined
                    field.onChange(event.target.value ?? null);
                  }}
                  className="mt-1 flex rounded-md shadow-sm sm:mt-0"
                />
              )}
            />
            {errors.description?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.description.message }}
                </StyledMessage>
              </div>
            )}
          </div>

          {/* ASSIGNED TO */}
          {resourcesMode === "technicians" && (
            <div className="relative rounded border border-black p-6">
              <label className="absolute -top-3 left-3 bg-white px-2">
                {strings.ASSIGN_USERS}{" "}
                <span className="text-lg font-medium text-redFail">*</span>
              </label>
              <div className="grid xs:grid-cols-2">
                {xusers.map((user, index) => (
                  <Controller
                    key={user.uid}
                    control={control}
                    name={`assignedTo.${index}`}
                    render={({ field }) => (
                      <BaseInputCheckbox
                        label={user.name}
                        {...field}
                        // value={user.uid}
                        defaultChecked={field.value === user.uid}
                        onChange={(event) => {
                          field.onChange(
                            event.target.checked ? user.uid : undefined,
                          );
                        }}
                      />
                    )}
                  />
                ))}
              </div>
              {errors.assignedTo?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.assignedTo.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          )}

          {/* ASSIGNED VEHICLE ID */}
          {resourcesMode === "vehicles" && (
            <div className="relative rounded border border-black p-6">
              <label className="absolute -top-3 left-3 bg-white px-2">
                {strings.ASSIGN_VEHICLES}{" "}
                <span className="text-lg font-medium text-redFail">*</span>
              </label>
              <div className="grid xs:grid-cols-2">
                {xvehicles.map((vehicle, index) => (
                  <Controller
                    key={vehicle.id}
                    control={control}
                    name={`assignedVehicleIDs.${index}`}
                    render={({ field }) => (
                      <BaseInputCheckbox
                        label={vehicle.title}
                        {...field}
                        defaultChecked={field.value === vehicle.id}
                        onChange={(event) => {
                          field.onChange(
                            event.target.checked ? vehicle.id : undefined,
                          );
                        }}
                      />
                    )}
                  />
                ))}
              </div>
              {errors.assignedVehicleIDs?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.assignedVehicleIDs.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          )}
        </form>

        {props.children.StartDatePicker}
        {props.children.EndDatePicker}

        {/* Action Buttons */}
        <div className="flex w-full flex-col items-center justify-between gap-4 xs:flex-row">
          {
            <ButtonColored
              className="w-full justify-center uppercase"
              kind="danger"
              onClick={onDelete}
              isBusy={isDeleting}
              busyText={strings.buttons.BUSY_DELETING}
            >
              {strings.DELETE_EVENT}
            </ButtonColored>
          }

          <BaseButtonSecondary
            type="button"
            className="w-full justify-center uppercase"
            onClick={closeAndReset}
          >
            {strings.buttons.CANCEL}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            type="submit"
            formNoValidate
            disabled={isSubmitting}
            isBusy={isSubmitting}
            busyText={strings.buttons.BUSY_SAVING}
            className="w-full justify-center uppercase"
            onClick={handleSubmit(onSubmit)}
          >
            {strings.buttons.SAVE}
          </BaseButtonPrimary>
        </div>
      </div>
    </BaseModal>
  );
}

export function getCheckedAssignedToValues(
  originallyCheckedAssignedTo: string[],
  userList: IMultipleUid_AssignUser[],
): string[] {
  // pretty sure i can just use flatMap here
  const result = userList
    .map((user) =>
      originallyCheckedAssignedTo.includes(user.uid) ? user.uid : undefined,
    )
    .filter<string>(
      (assignedTo): assignedTo is string => typeof assignedTo === "string",
    );

  return result;
}
