import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { signUserOut } from "../init-firebase";
import BaseButtonPrimary from "../components/BaseButtonPrimary";

interface Props {
  path: string;
}

export default function PermissionDenied(props: Props) {
  return (
    <div className="flex min-h-full flex-col bg-white pb-12 pt-16">
      <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-center text-orange-700">
          <span className="sr-only">Exclamation point</span>
          <ExclamationTriangleIcon aria-hidden="true" className="h-20 w-20" />
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-sm font-semibold uppercase tracking-wide text-orange-700">
              403 error
            </p>
            <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              Access denied.
            </h1>
            <p className="mt-2 text-base text-gray-500">
              Sorry, it looks like you don’t have permission to access the path{" "}
              <span className="font-medium text-orange-700">{props.path}</span>.
              <br /> Please refer to your administrator or contact support
            </p>
            {/* Log Out Button */}
            <BaseButtonPrimary
              onClick={() => {
                signUserOut();
              }}
            >
              Log Out
            </BaseButtonPrimary>

            <div className="mt-6">
              {/* <a */}
              {/*   href={HOME_URL} */}
              {/*   className="text-base font-medium text-indigo-600 hover:text-indigo-500" */}
              {/* > */}
              {/*   Go back home<span aria-hidden="true"> &rarr;</span> */}
              {/* </a> */}
            </div>
          </div>
        </div>
      </main>
      <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
        <nav className="flex justify-center space-x-4">
          <a
            href="mailto:support@monomersoftware.com"
            className="text-sm font-medium text-gray-500 hover:text-gray-600"
          >
            Contact Support
          </a>
          <span className="inline-block" aria-hidden="true" />
        </nav>
      </footer>
    </div>
  );
}
