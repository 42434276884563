//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react/jsx-runtime";
import { useState } from "react";

//Local
import BaseModal from "./BaseModal";
import BaseButtonSecondary from "./BaseButtonSecondary";
import { buttons } from "../strings";
import BaseButtonPrimary from "./BaseButtonPrimary";
import { StiltInvoiceStatus } from "../models/invoice";
import StyledSwitchGroup from "./StyledSwitchGroup";
import * as strings from "../strings";

export interface ConfirmationDialogForInvoiceActionsProps {
  invoiceActionType: string;
  isOpen: boolean;
  onClose: () => void;
  handleConfirmAction: (
    status: StiltInvoiceStatus | null,
    includeJobPhotos: boolean,
    displayPaymentLink: boolean,
  ) => Promise<void> | void;
  isSubmitting: boolean;
  title: string;
  body: string;
  pdfBatchActionButtons: boolean;
  pendingInvoiceIDsLength: number | null;
}

export default function ConfirmationDialogForInvoiceActions(
  props: ConfirmationDialogForInvoiceActionsProps,
) {
  const [includeJobPhotos, setIncludeJobPhotos] = useState<boolean>(false);
  const [displayPaymentLink, setDisplayPaymentLink] = useState<boolean>(false);

  return (
    <BaseModal
      open={props.isOpen}
      closeModal={() => {}}
      allowOverflowY={true}
      title={
        <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
          <h1 className="inline-flex items-center text-xl font-semibold ">
            {props.title}
          </h1>
          <button type="button" onClick={() => props.onClose()}>
            <XMarkIcon
              aria-label="confirm delete equipment"
              className="h-6 text-white"
            />
          </button>
        </div>
      }
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="relative flex flex-col space-y-8 p-8 text-lg">
        <div>{props.body}</div>
        {props.invoiceActionType === "generatePDF" && (
          <Fragment>
            {props.pdfBatchActionButtons && (
              <div className="flex w-full justify-between gap-6">
                <StyledSwitchGroup
                  readableName={strings.DISPLAY_PAYMENT_LINK}
                  onBlur={() => {}}
                  onChange={() => setDisplayPaymentLink(!displayPaymentLink)}
                  checked={displayPaymentLink}
                  id="displayPaymentLink"
                  name={"displayPaymentLink"}
                />
                <StyledSwitchGroup
                  readableName={strings.INCLUDE_JOB_PHOTOS}
                  onBlur={() => {}}
                  onChange={() => setIncludeJobPhotos(!includeJobPhotos)}
                  checked={includeJobPhotos}
                  id="includeJobPhotos"
                  name={"includeJobPhotos"}
                />
              </div>
            )}
            <div className="mt-4 flex w-full flex-col items-center justify-between gap-6">
              <BaseButtonSecondary
                type="button"
                className="justify-center uppercase xs:w-52 sm:w-full"
                onClick={() => {
                  props.handleConfirmAction(
                    StiltInvoiceStatus.PENDING,
                    includeJobPhotos,
                    displayPaymentLink,
                  );
                  setIncludeJobPhotos(false);
                  setDisplayPaymentLink(false);
                }}
              >
                {buttons.KEEP_PENDING}
              </BaseButtonSecondary>

              {props.pendingInvoiceIDsLength &&
                props.pendingInvoiceIDsLength > 0 && (
                  <BaseButtonPrimary
                    type="submit"
                    disabled={props.isSubmitting}
                    isBusy={props.isSubmitting}
                    onClick={() => {
                      props.handleConfirmAction(
                        StiltInvoiceStatus.SUBMITTED,
                        includeJobPhotos,
                        displayPaymentLink,
                      );
                      setIncludeJobPhotos(false);
                      setDisplayPaymentLink(false);
                    }}
                    className="justify-center uppercase xs:w-52 sm:w-full"
                  >
                    {buttons.CONFIRM_SUBMITTED}
                  </BaseButtonPrimary>
                )}

              <BaseButtonSecondary
                type="button"
                className="justify-center uppercase xs:w-52 sm:w-full"
                onClick={props.onClose}
              >
                {buttons.CLOSE}
              </BaseButtonSecondary>
            </div>
          </Fragment>
        )}
        {props.invoiceActionType !== "generatePDF" && (
          <div className="mt-4 flex w-full flex-col items-center justify-between gap-6 xs:flex-row">
            <BaseButtonSecondary
              type="button"
              className="w-full justify-center uppercase xs:w-52"
              onClick={props.onClose}
            >
              {buttons.NO}
            </BaseButtonSecondary>

            <BaseButtonPrimary
              type="submit"
              disabled={props.isSubmitting}
              isBusy={props.isSubmitting}
              onClick={() => {
                props.handleConfirmAction(
                  null,
                  includeJobPhotos,
                  displayPaymentLink,
                );
                setIncludeJobPhotos(false);
                setDisplayPaymentLink(false);
              }}
              className="w-full justify-center uppercase xs:w-52"
            >
              {buttons.YES}
            </BaseButtonPrimary>
          </div>
        )}
      </div>
    </BaseModal>
  );
}
