//Libs
import React from "react";

//Local
import styles from "../assets/css/InputNumber.module.css";

type Ref = HTMLInputElement;

export type Props = {
  className?: string;
  admin?: boolean;
  text?: string;
  inputName?: string;
  required?: boolean;
  /** only applicable when `admin` is true. */
  overrideBG?: string;
} & React.ComponentPropsWithRef<"input">;

const BaseInputNumber = React.forwardRef<Ref, Props>(
  (
    { className, text, inputName, admin, overrideBG, ...props },
    ref,
  ): JSX.Element => {
    if (admin) {
      return (
        <div className={`${className} relative`}>
          <input
            {...props}
            ref={ref}
            value={props.value}
            type="number"
            id={inputName}
            onWheel={(e) => (e.target as HTMLInputElement).blur()}
            className={`${styles.noSpinner} ${overrideBG ?? "bg-white"} block w-full min-w-0 flex-1 rounded border border-black p-4 text-gray-700 outline-none focus:ring focus:ring-primaryLight sm:text-sm`}
          />
          <label
            htmlFor={inputName}
            className={`absolute -top-3 left-3 px-2 ${
              overrideBG ?? "bg-white"
            }`}
          >
            {text}{" "}
            {props.required ? (
              <span className="text-lg font-medium text-redFail">*</span>
            ) : (
              ""
            )}
          </label>
        </div>
      );
    } else {
      return (
        <div className={className}>
          <input
            {...props}
            ref={ref}
            value={props.value}
            type="number"
            aria-labelledby={inputName}
            onWheel={(e) => (e.target as HTMLInputElement).blur()}
            className={`${styles.noSpinner} block w-full min-w-0 flex-1 rounded border border-gray-300 p-2 outline-none focus:border-primaryLight focus:ring-2 focus:ring-primaryLight sm:text-sm`}
          />
        </div>
      );
    }
  },
);

export default BaseInputNumber;
