import { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import cloneDeep from "lodash/cloneDeep";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@heroicons/react/24/outline/CheckIcon";
import { zodResolver } from "@hookform/resolvers/zod";

// Local
import { StiltLineItem } from "../../models/invoice";
import { StiltLineItemFormData } from "../../models/stilt-payment";
import { InvoiceOverrideData } from "../../models/membership";
import { ExistingCustomer } from "../../models/customer";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseInputTextArea from "../BaseInputTextArea";
import currencyFormatter, { formatAsPercent } from "../../currency";
import { StyledTooltip } from "../StyledTooltip";
import {
  InvOverrideSubsetFormSchema,
  InvOverrideSubsetFormState,
} from "./formTypes";
import EditLineItem from "./EditLineItem";
import { PencilIconWithRef } from "../PencilEditButton";
import AddNewInvoiceItemSelection from "../Invoices/AddNewInvoiceItemSelection";
import { TrashButton } from "../TrashButton";
import { typesensePriceBookItemsQuery } from "../../utils/typesenseQueries";
import { useTypesenseStore } from "../../store/typesense";
import { ExistingPriceBookItem } from "../../models/price-book-item";
import { XIconWithRef } from "../XCloseButton";
import StyledMessage from "../StyledMessage";
import * as strings from "../../strings";
import ButtonColored from "../ButtonColored";
import BaseInputNumber from "../BaseInputNumber";
import { getInvoiceLineItemTotal } from "../../assets/js/estimateFunctions";
import { DbRead } from "../../database";
import { getRoundedCurrency } from "../../utils";

interface Props {
  siteKey: string;
  totalTaxRate: number;
  currency: string;
  siteFilterPbiByLocationID: boolean;
  locationID: string | null;
  customer: ExistingCustomer | null;
  overrideInvoice: InvoiceOverrideData | undefined;
  /** true if there isn't an invoice override yet */
  isEditing: boolean;
  setInvoiceOverride: (inv: InvoiceOverrideData) => void;
  handleRemoveInvoice: () => void;
}

export function MembershipInvoiceEditor(props: Props) {
  const [lineItems, setLineItems] = useState<StiltLineItemFormData[]>(
    props.overrideInvoice?.lineItems.map((item) => ({
      title: item.estimateItemTitle,
      description: item.estimateItemDescription || "",
      quantity: item.quantity,
      discount: item.discount,
      taxAmount: item.taxAmount,
      unitPrice: item.unitPrice,
      subTotal: item.subTotal,
      discountable: item.discountable,
      taxable: item.taxable,
      priceBookItemID: item.priceBookItemID,
      estimateItemID: item.estimateItemID,
    })) || [],
  );

  const defaultValues = useMemo(() => {
    return {
      lineItems: props.overrideInvoice?.lineItems || [],
      note: props.overrideInvoice?.note ?? null,
      internalNotes: props.overrideInvoice?.internalNotes ?? null,
      discount: props.overrideInvoice?.discount ?? 0,
    };
  }, [props.overrideInvoice]);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<InvOverrideSubsetFormState>({
    defaultValues: defaultValues,
    resolver: zodResolver(InvOverrideSubsetFormSchema),
  });

  const watchDiscount = watch("discount");
  const watchedDiscountAsNumber =
    typeof watchDiscount === "string" ? 0 : watchDiscount;

  // keep lineItems (local state) in sync with RHF's lineItems state (so we can
  // utilize RHF validation, ensuring there's at least one line item) --
  useEffect(() => {
    if (!lineItems.length) {
      setValue("lineItems", []);
    } else {
      const list: StiltLineItem[] = [];
      for (const item of lineItems) list.push(convertToStiltLineItem(item));
      setValue("lineItems", list);
    }
  }, [lineItems, setValue]);

  const [isEditingInvoice, setIsEditingInvoice] = useState(props.isEditing);
  const [isAddingItems, setIsAddingItems] = useState(false);
  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [isEditingDiscount, setIsEditingDiscount] = useState(false);
  const [pbis, setPBIs] = useState<ExistingPriceBookItem[]>([]);
  // "total" applies regardless of currency. gst and pst only apply to CA
  const [taxTotals, setTaxTotals] = useState({
    total: 0,
    gst: 0,
    pst: 0,
  });
  const typesenseSearchKey = useTypesenseStore(
    (state) => state.scopedSearchKey,
  );

  // Calculated values
  const subTotal = getRoundedCurrency(
    lineItems.reduce((total, item) => total + item.subTotal, 0),
  );
  /** line items discount amount. does not include global discount */
  const itemsDiscount = getRoundedCurrency(
    lineItems.reduce(
      (total, item) => total + (item.subTotal * item.discount) / 100,
      0,
    ),
  );
  /** includes global discount. total total total discount amount */
  const totalDiscount = getRoundedCurrency(
    itemsDiscount + (subTotal * watchedDiscountAsNumber) / 100,
  );

  const totalAmount = getRoundedCurrency(
    subTotal + taxTotals.total - totalDiscount,
  );

  useEffect(() => {
    async function getTaxTotals() {
      let gstTotal = 0;
      let pstTotal = 0;
      let total = 0;
      for (const item of lineItems) {
        if (!item.priceBookItemID) continue;
        if (item.priceBookItemID === "import") continue;

        let pbItem: ExistingPriceBookItem | null =
          pbis.find((pbi) => pbi.id === item.priceBookItemID) ?? null;

        if (!pbItem) {
          pbItem = await DbRead.priceBookItems.get(
            props.siteKey,
            item.priceBookItemID,
          );
        }
        const { taxAmountItemGST, taxAmountItemPST, taxAmountItem } =
          getInvoiceLineItemTotal({
            item: item,
            currency: props.currency,
            totalTaxRate: props.totalTaxRate,
            globalDiscount: watchedDiscountAsNumber,
            customer: props.customer,
            discountable: pbItem?.discountable ?? true,
            taxable: pbItem?.taxable ?? true,
          });
        gstTotal += taxAmountItemGST ?? 0;
        pstTotal += taxAmountItemPST ?? 0;
        total += taxAmountItem ?? 0;
      }
      setTaxTotals({
        total: getRoundedCurrency(total),
        gst: getRoundedCurrency(gstTotal),
        pst: getRoundedCurrency(pstTotal),
      });
    }
    getTaxTotals();
  }, [
    lineItems,
    pbis,
    props.currency,
    props.customer,
    props.siteKey,
    props.totalTaxRate,
    watchedDiscountAsNumber,
  ]);

  useEffect(() => {
    async function initPBIs() {
      if (!typesenseSearchKey) return;
      const pbItems = await typesensePriceBookItemsQuery(
        typesenseSearchKey,
        "",
      );
      if (props.siteFilterPbiByLocationID && props.locationID) {
        const filtered = pbItems.filter(
          (pbi) =>
            pbi.locationID === props.locationID || pbi.locationID === null,
        );
        setPBIs(filtered);
      } else {
        setPBIs(pbItems);
      }
    }
    initPBIs();
  }, [props.locationID, props.siteFilterPbiByLocationID, typesenseSearchKey]);

  const onFilterTextBoxChanged = useCallback(
    async (searchTerm: string) => {
      if (!typesenseSearchKey) return;
      const pbItems = await typesensePriceBookItemsQuery(
        typesenseSearchKey,
        searchTerm,
      );
      if (props.siteFilterPbiByLocationID && props.locationID) {
        const filtered = pbItems.filter(
          (pbi) =>
            pbi.locationID === props.locationID || pbi.locationID === null,
        );
        setPBIs(filtered);
      } else {
        setPBIs(pbItems);
      }
    },
    [props.locationID, props.siteFilterPbiByLocationID, typesenseSearchKey],
  );

  // LINE ITEM FUNCTIONS
  function convertToEditableItem(index: number): void {
    setLineItems((prev) => {
      prev[index].toBeEdited = true;
      return [...prev];
    });
  }

  function deleteItem(index: number): void {
    setLineItems((prev) => prev.filter((_, i) => i !== index));
  }

  async function updateItem(index: number, item: StiltLineItemFormData) {
    const updatedItem = await updateItemTotals(item);
    setLineItems((prev) => {
      prev[index] = updatedItem;
      return [...prev];
    });
  }

  /** triggers from AddNewInvoiceItemSelection - the searchable dropdown */
  function addItem(newItem: StiltLineItemFormData) {
    setLineItems((prev) => [newItem, ...prev]);
  }

  async function updateItemTotals(
    item: StiltLineItemFormData,
  ): Promise<StiltLineItemFormData> {
    if (!item.priceBookItemID) return item;
    if (item.priceBookItemID === "import") return item;

    let pbItem: ExistingPriceBookItem | null =
      pbis.find((pbi) => pbi.id === item.priceBookItemID) ?? null;

    if (!pbItem) {
      pbItem = await DbRead.priceBookItems.get(
        props.siteKey,
        item.priceBookItemID,
      );
    }
    const { taxAmountItem, subtotalItemWithLineDiscount } =
      getInvoiceLineItemTotal({
        item: item,
        currency: props.currency,
        totalTaxRate: props.totalTaxRate,
        globalDiscount: watchedDiscountAsNumber,
        customer: props.customer,
        discountable: pbItem?.discountable ?? true,
        taxable: pbItem?.taxable ?? true,
      });
    item.subTotal = subtotalItemWithLineDiscount;
    item.taxAmount = taxAmountItem;
    return item;
  }
  // (end line item functions)

  /** set editing states back to false */
  function resetEditingState() {
    setIsEditingInvoice(false);
    setIsAddingItems(false);
    setIsEditingNotes(false);
    setIsEditingDiscount(false);
  }

  // Send valid invoiceOverride to parent
  function handleSaveInvoice(data: InvOverrideSubsetFormState) {
    resetEditingState();

    const items: StiltLineItem[] = [];
    for (const li of lineItems) items.push(convertToStiltLineItem(li)); // one-line boomer loop HHHHHHHHH

    const inv: InvoiceOverrideData = {
      lineItems: items,
      note:
        typeof data.note === "string" && data.note.trim().length
          ? data.note
          : null,
      internalNotes:
        typeof data.internalNotes === "string" &&
        data.internalNotes.trim().length
          ? data.internalNotes
          : null,
      discount: data.discount === "" ? 0 : data.discount,
      totalTaxAmount: taxTotals.total,
      subTotal: subTotal,
      totalAmount: totalAmount,
    };
    if (taxTotals.gst > 0) inv.totalTaxAmountGST = taxTotals.gst;
    if (taxTotals.pst > 0) inv.totalTaxAmountPST = taxTotals.pst;

    props.setInvoiceOverride(inv);
  }

  /** Restore invoice to the state it was in before user interaction */
  function cancelEdit() {
    resetEditingState();
    setValue("note", props.overrideInvoice?.note || null);
    setValue("internalNotes", props.overrideInvoice?.internalNotes || null);
    setValue("discount", props.overrideInvoice?.discount || 0);
    if (
      !props.overrideInvoice?.lineItems ||
      !props.overrideInvoice.lineItems.length
    ) {
      setLineItems([]);
    } else {
      setLineItems(
        cloneDeep(
          props.overrideInvoice.lineItems.map((item) => ({
            title: item.estimateItemTitle,
            description: item.estimateItemDescription || "",
            quantity: item.quantity,
            discount: item.discount,
            taxAmount: item.taxAmount,
            unitPrice: item.unitPrice,
            subTotal: item.subTotal,
            discountable: item.discountable,
            taxable: item.taxable,
            priceBookItemID: item.priceBookItemID,
            estimateItemID: item.estimateItemID,
          })),
        ),
      );
    }
  }

  function removeInvoice() {
    props.handleRemoveInvoice();
    resetEditingState();
    setLineItems([]);
    setValue("note", null);
    setValue("internalNotes", null);
    setValue("discount", 0);
  }

  return (
    <div className="mb-1 w-full rounded-md border border-gray-300 bg-white p-4 pt-3">
      <div className="flex flex-wrap items-center justify-between gap-2">
        <h3 className="text-base font-semibold">Customize Invoice</h3>
        {/* OVERALL EDIT/VIEW MODE */}
        {isEditingInvoice ? (
          <div className="flex gap-2">
            <StyledTooltip title="Done Customizing">
              <button
                type="button"
                onClick={handleSubmit(handleSaveInvoice)}
                className="rounded-full bg-primary px-2 text-primaryButtonText transition-colors hover:bg-primaryDark hover:text-secondaryButtonText focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2"
              >
                <CheckIcon strokeWidth={3} className="h-6 w-6" />
              </button>
            </StyledTooltip>

            <StyledTooltip title="Cancel Changes">
              <XIconWithRef
                onClick={cancelEdit}
                className="border border-gray-500"
              />
            </StyledTooltip>
          </div>
        ) : (
          <div className="flex gap-2">
            <BaseButtonSecondary
              type="button"
              onClick={() => setIsEditingInvoice(true)}
              textColor="text-black"
              borderColor="border-gray-700"
            >
              Edit Invoice
            </BaseButtonSecondary>

            {props.overrideInvoice && (
              <StyledTooltip title="If the invoice is removed, this membership will use the template's price book item when generating invoices.">
                <ButtonColored
                  kind="danger"
                  type="button"
                  onClick={removeInvoice}
                >
                  Remove
                </ButtonColored>
              </StyledTooltip>
            )}
          </div>
        )}
      </div>
      <hr className="my-2 block w-full border border-gray-100" />

      {/* NOTES */}
      <div className="flex flex-col md:flex-row-reverse md:gap-2">
        {isEditingInvoice && !isEditingNotes && (
          <BaseButtonSecondary
            type="button"
            onClick={() => setIsEditingNotes(true)}
            textColor="text-black"
            borderColor="border-gray-700"
            className="shrink-0 self-end md:self-start"
          >
            {/* <PencilIcon
            className="-ml-1 mr-2 text-gray-600"
            style={{ height: "1.1rem", width: "1.1rem" }}
          /> */}
            Edit Notes
          </BaseButtonSecondary>
        )}

        <div
          className={`${isEditingInvoice && isEditingNotes ? "mt-4" : ""} flex grow flex-col gap-4 sm:flex-row lg:gap-7`}
        >
          <Controller
            name="note"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div className="w-full">
                {isEditingInvoice && isEditingNotes ? (
                  <BaseInputTextArea
                    text="Invoice Note"
                    placeholder={strings.PLACEHOLDER_VISIBLE_NOTE}
                    rows={4}
                    inputName="invoiceNote"
                    admin={true}
                    required={false}
                    {...field}
                    value={field.value === null ? "" : field.value}
                  />
                ) : (
                  <span>
                    <p>Invoice Note</p>
                    {field.value ? (
                      <p className="text-sm text-gray-600">{field.value}</p>
                    ) : (
                      <p className="text-sm text-gray-400">
                        No invoice notes provided.
                      </p>
                    )}
                  </span>
                )}
                {error && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: error.message }}
                    </StyledMessage>
                  </div>
                )}
              </div>
            )}
          />

          <Controller
            name="internalNotes"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div className="w-full">
                {isEditingInvoice && isEditingNotes ? (
                  <BaseInputTextArea
                    text="Internal Note"
                    placeholder={strings.PLACEHOLDER_INTERNAL_NOTE}
                    rows={4}
                    inputName="internalNotes"
                    admin={true}
                    required={false}
                    {...field}
                    value={field.value === null ? "" : field.value}
                  />
                ) : (
                  <span>
                    <p>Internal Note</p>
                    {field.value ? (
                      <p className="text-sm text-gray-600">{field.value}</p>
                    ) : (
                      <p className="text-sm text-gray-400">
                        No internal notes provided.
                      </p>
                    )}
                  </span>
                )}
                {error && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: error.message }}
                    </StyledMessage>
                  </div>
                )}
              </div>
            )}
          />
        </div>
      </div>

      {/* LINE ITEMS */}
      <hr className="my-4 block w-full border border-gray-100" />
      <div className="mb-4">
        <div className="mb-2 flex flex-wrap items-start justify-between gap-2">
          <h4>Line Items</h4>
          {errors.lineItems && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: errors.lineItems.message }}
              </StyledMessage>
            </div>
          )}
          {!isAddingItems && isEditingInvoice && (
            <BaseButtonSecondary
              type="button"
              onClick={() => setIsAddingItems(true)}
              textColor="text-black"
              borderColor="border-gray-700"
            >
              <AddIcon
                className="-ml-1 mr-1 text-gray-700"
                style={{ height: "1.2rem", width: "1.2rem" }}
              />
              Add Line Items
            </BaseButtonSecondary>
          )}

          {isAddingItems && isEditingInvoice && (
            <BaseButtonSecondary
              type="button"
              onClick={() => setIsAddingItems(false)}
              textColor="text-black"
              borderColor="border-gray-700"
            >
              Done Adding Line Items
            </BaseButtonSecondary>
          )}
        </div>

        {isEditingInvoice && isAddingItems && (
          <div className="mb-7">
            <AddNewInvoiceItemSelection
              onSearch={onFilterTextBoxChanged}
              PBItemQueryResultList={pbis}
              customer={props.customer}
              globalDiscount={watchedDiscountAsNumber}
              totalTaxRate={props.totalTaxRate}
              handleAddItem={addItem}
              currency={props.currency}
            />
          </div>
        )}

        <ul className="">
          {lineItems.map((item, i) => {
            // there will always be a priceBookItemID - but if the same PBI is
            // selected multiple times, we'll have duplicate keys
            // using the index from the map method's callback isn't good practice..
            // if this becomes a problem, look into alternatives.
            const key = `${item.priceBookItemID}_${i}`;

            if (item.toBeEdited && props.siteKey) {
              return (
                <li key={key} className="mt-6">
                  <EditLineItem
                    lineItem={item}
                    customer={props.customer}
                    globalDiscount={watchedDiscountAsNumber}
                    totalTaxRate={props.totalTaxRate}
                    siteKey={props.siteKey}
                    currency={props.currency}
                    handleSave={async (u) => await updateItem(i, u)}
                    handleDeleteItem={() => deleteItem(i)}
                  />

                  <hr className="my-4 block w-full border border-gray-100" />
                </li>
              );
            }

            return (
              <li
                key={key}
                className="my-2 flex items-center justify-between rounded-md border border-gray-200 bg-white p-2 sm:py-2.5 sm:pl-3.5"
              >
                <LineItem
                  isEditingInvoice={isEditingInvoice}
                  item={item}
                  index={i}
                  convertToEditableItem={convertToEditableItem}
                  deleteItem={deleteItem}
                  currency={props.currency}
                />
              </li>
            );
          })}
        </ul>
      </div>

      {/* SUMMARY */}
      <div className="space-y-2 rounded-md bg-gray-100 px-4 py-3 text-base">
        <article className="flex flex-col items-end">
          {isEditingInvoice && !isEditingDiscount && (
            <BaseButtonSecondary
              type="button"
              textColor="text-black"
              borderColor="border-gray-700"
              onClick={() => setIsEditingDiscount(true)}
            >
              {watchedDiscountAsNumber ? "Edit Discount" : "Add Discount"}
            </BaseButtonSecondary>
          )}

          {isEditingInvoice && isEditingDiscount && (
            <>
              <Controller
                name="discount"
                control={control}
                render={({ field }) => (
                  <>
                    <div className="flex items-center justify-end">
                      <BaseInputNumber
                        type="number"
                        min={0}
                        max={100}
                        // step="0.01"
                        className={`block w-14 rounded outline-none focus:ring-1 focus:ring-primaryLight sm:text-sm`}
                        {...field}
                        onChange={(ev) => {
                          const n = ev.target.valueAsNumber;
                          const isValid = !isNaN(n) && n >= 0 && n <= 100;
                          field.onChange(isValid ? n : "");
                        }}
                      />
                      <span className="ml-1 mr-3 text-lg">%</span>

                      <StyledTooltip title="Done">
                        <button
                          type="button"
                          onClick={() => setIsEditingDiscount(false)}
                          className="rounded-full border border-gray-500 bg-white p-1 text-gray-700 transition-colors hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-primaryLight focus:ring-offset-2"
                        >
                          <CheckIcon strokeWidth={2} className="h-6 w-6" />
                        </button>
                      </StyledTooltip>
                    </div>
                  </>
                )}
              />
            </>
          )}
          {errors.discount && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: `Discount: ${errors.discount.message}` }}
              </StyledMessage>
            </div>
          )}
        </article>

        <article className="flex flex-wrap justify-between gap-x-2">
          {/* {isEditingInvoice && (
            <hr className="mb-1 block w-full border border-white" />
          )} */}
          <span>{strings.SUBTOTAL}</span>
          <span>{currencyFormatter(subTotal, props.currency)}</span>
        </article>

        {/* additional horizontal rule if there's a discount */}
        {totalDiscount > 0 && (
          <hr className="block w-full border border-white" />
        )}
        <div className="space-y-0.5 text-right text-sm">
          {watchedDiscountAsNumber > 0 && (
            <article className="">
              {strings.DISCOUNT}: {watchedDiscountAsNumber}%
            </article>
          )}
          {totalDiscount > 0 && (
            <article className="text-green-600">
              {strings.TOTAL_DISCOUNT_AMOUNT}:{" "}
              {currencyFormatter(totalDiscount, props.currency)}
            </article>
          )}
        </div>

        {totalDiscount > 0 && (
          <article className="flex flex-wrap justify-between gap-x-2">
            <span>{strings.SUBTOTAL_WITH_DISCOUNT}</span>
            <span>
              {currencyFormatter(subTotal - totalDiscount, props.currency)}
            </span>
          </article>
        )}

        {totalDiscount > 0 && (
          <hr className="block w-full border border-white" />
        )}
        <article className="flex flex-wrap justify-between gap-x-2">
          <span>Tax</span>
          <span>
            {currencyFormatter(taxTotals.total, props.currency)} (
            {formatAsPercent(props.totalTaxRate)})
          </span>
        </article>
        <div className="space-y-0.5 text-right text-sm italic">
          {taxTotals.pst > 0 && (
            <span className="block">
              {strings.PST}: {currencyFormatter(taxTotals.pst, props.currency)}
            </span>
          )}
          {taxTotals.gst > 0 && (
            <span className="block">
              {strings.GST}: {currencyFormatter(taxTotals.gst, props.currency)}
            </span>
          )}
        </div>

        <hr className="block w-full border-2 border-white" />
        <article className="flex flex-wrap justify-between gap-x-2 text-lg font-medium">
          <span>{strings.TOTAL_AMOUNT}</span>
          <span>{currencyFormatter(totalAmount, props.currency)}</span>
        </article>
      </div>
    </div>
  );
}

/** display an invoice line item, with delete and edit icons */
function LineItem(props: {
  /** can't edit line items until we're in edit invoice mode */
  isEditingInvoice: boolean;
  item: StiltLineItemFormData;
  index: number;
  currency: string;
  convertToEditableItem: (index: number) => void;
  deleteItem: (index: number) => void;
}) {
  const subtotalItemWithLineDiscount =
    props.item.quantity *
    props.item.unitPrice *
    (1 - (props.item.discount ?? 0) / 100);
  const totalWithoutDiscount = props.item.quantity * props.item.unitPrice;

  return (
    <>
      <div
        className={`flex w-full flex-wrap justify-end gap-2 sm:grid ${
          props.isEditingInvoice
            ? "sm:grid-cols-[5fr,2fr,1fr]"
            : "sm:grid-cols-[5fr,2fr] sm:pr-2"
        }`}
      >
        {/* TITLE, DESCRIPTION?, DISCOUNTABLE?, IMPORTED ITEM? */}
        <div className="mr-auto flex items-start">
          <div className="flex flex-col">
            <span className="block whitespace-normal text-base font-medium leading-5">
              {props.item.title && <div>{props.item.title}</div>}
            </span>
            <span className="block whitespace-normal text-sm leading-5 text-gray-400">
              <p className={`${props.item.title && "mt-1"} text-sm`}>
                {props.item.description}
              </p>
            </span>
            {props.item.discountable === false && (
              <p className={`text-sm italic`}>* Non-discountable</p>
            )}
            {props.item.priceBookItemID === "import" && (
              <p className="text-sm italic text-red-400">
                Imported item. Please replace with the correct pricebook item.
              </p>
            )}
          </div>
        </div>

        {/* AMOUNTS/DISCOUNTS */}
        <div className="flex items-start justify-self-end xs:items-center">
          <div className="flex flex-col items-end">
            {props.item.discount &&
            props.item.discount > 0 &&
            props.item.discountable !== false ? (
              <div className="flex items-center gap-2">
                <span className="line-through">
                  {currencyFormatter(totalWithoutDiscount, props.currency)}
                </span>
                <span className="text-lg font-bold">
                  {currencyFormatter(
                    subtotalItemWithLineDiscount,
                    props.currency,
                  )}
                </span>
              </div>
            ) : (
              <div className="text-lg font-bold">
                {currencyFormatter(totalWithoutDiscount, props.currency)}
              </div>
            )}
            {props.item.discount &&
            props.item.discount > 0 &&
            props.item.discountable !== false ? (
              <div className="mt- text-right text-sm text-gray-600">
                {props.item.quantity} @{" "}
                {currencyFormatter(props.item.unitPrice, props.currency)} +{" "}
                {props.item.discount}% discount
              </div>
            ) : (
              <div className="mt- text-sm text-gray-600">
                {props.item.quantity} @{" "}
                {currencyFormatter(props.item.unitPrice, props.currency)}
              </div>
            )}
          </div>
        </div>

        {/* ACTION BUTTONS */}
        {props.isEditingInvoice && (
          <div className="flex items-center gap-2 justify-self-end xs:flex-col sm:flex-row">
            {props.item.priceBookItemID !== "import" && (
              <span className="h-8 w-8 rounded-full border border-gray-300 bg-white hover:bg-gray-50">
                <StyledTooltip title="Edit Item">
                  <PencilIconWithRef
                    onClick={() => props.convertToEditableItem(props.index)}
                    overrideMargin="mr-0"
                    hoverColor="hover:bg-gray-100"
                    overrideStyles={{
                      width: "1.9rem",
                      height: "1.9rem",
                      padding: "4px",
                    }}
                  />
                </StyledTooltip>
              </span>
            )}
            <span className="h-8 w-8 rounded-full border border-gray-300 bg-white hover:bg-gray-50">
              <StyledTooltip title="Remove">
                <TrashButton
                  height="1.9rem"
                  width="1.9rem"
                  padding="4px"
                  overrideMargin="mx-0"
                  color="text-red-700"
                  hoverColor="hover:bg-red-50"
                  onDelete={() => props.deleteItem(props.index)}
                />
              </StyledTooltip>
            </span>
          </div>
        )}
      </div>
    </>
  );
}

function convertToStiltLineItem(item: StiltLineItemFormData): StiltLineItem {
  const x: StiltLineItem = {
    estimateItemTitle: item.title || "",
    estimateItemDescription: item.description || null,
    priceBookItemID: item.priceBookItemID || "custom",
    estimateItemID: item.estimateItemID || null,
    quantity: item.quantity,
    discount: item.discount,
    taxAmount: item.taxAmount,
    unitPrice: item.unitPrice,
    subTotal: item.subTotal,
  };
  if (typeof item.taxable === "boolean") x.taxable = item.taxable;
  if (typeof item.discountable === "boolean") {
    x.discountable = item.discountable;
  }
  return x;
}
