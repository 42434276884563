import { useState } from "react";
import BaseModal from "../BaseModal";
import BaseInputText from "../BaseInputText";
import BaseButtonPrimary from "../BaseButtonPrimary";
import StyledMessage from "../StyledMessage";
import { DbWrite } from "../../database";
import { logger } from "../../logging";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { phoneUtils } from "../../utils";

interface Props {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  siteKey: string;
  number: {
    id: string;
    phoneNumber: string;
    description: string;
  };
}

export default function EditNumberDialog({
  open,
  onClose,
  onSave,
  siteKey,
  number,
}: Props) {
  const [description, setDescription] = useState(number.description);
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSave = async () => {
    setIsSaving(true);
    setErrorMessage(null);
    try {
      await DbWrite.twilio.updatePhoneNumber(siteKey, number.id, {
        description,
      });
      onSave();
      onClose();
    } catch (error) {
      logger.error(error);
      setErrorMessage("Failed to update phone number details");
    } finally {
      setIsSaving(false);
    }
  };

  const header = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white">
      <h1 className="text-xl font-semibold">Edit Tracking Number</h1>
      <button type="button" onClick={onClose}>
        <XMarkIcon className="h-6 text-white" />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={onClose}
      open={open}
      title={header}
      parentDivStyles="inline-block transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl"
    >
      <div className="px-4 pb-4 pt-5 sm:p-6">
        <div className="space-y-6">
          <div className="text-lg font-medium">
            {phoneUtils.display(number.phoneNumber)}
          </div>

          {errorMessage && (
            <StyledMessage type="error">
              {{ message: errorMessage }}
            </StyledMessage>
          )}

          <div className="space-y-4">
            <BaseInputText
              admin={true}
              inputName="description"
              text="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Add details about how this number will be used"
            />
            <BaseButtonPrimary
              onClick={handleSave}
              isBusy={isSaving}
              busyText="Saving..."
            >
              Save Changes
            </BaseButtonPrimary>
          </div>
        </div>
      </div>
    </BaseModal>
  );
}
