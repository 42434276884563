import { Fragment, useEffect, useState } from "react";
import LoadingClipboardAnimation from "../../components/LoadingClipBoardAnimation";
import SiteConfigurationPage from "./SiteConfigurationPage";
import { DbRead } from "../../database";
import { ConfigSetting } from "../../models/site-configuration";

interface Props {
  siteKey: string;
}

export interface SectionMetadata {
  title: string;
  sortOrder: number;
  description?: string;
}

export type ConfigTitlesMap = Record<string, SectionMetadata>;

export default function SiteConfigurationContainer({ siteKey }: Props) {
  const [configData, setConfigData] = useState<Record<
    string,
    Record<string, ConfigSetting<any>>
  > | null>(null);
  const [configTitles, setConfigTitles] = useState<ConfigTitlesMap | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchConfig() {
      const response = await DbRead.siteKey.getSiteConfiguration();
      setConfigData(response.config);
      setConfigTitles(response.titles);
      setIsLoading(false);
    }

    fetchConfig();
  }, [siteKey]);

  if (isLoading) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <LoadingClipboardAnimation />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <Fragment>
      <SiteConfigurationPage
        configData={configData}
        configTitles={configTitles}
      />
    </Fragment>
  );
}
