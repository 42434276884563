import { Timestamp } from "firebase/firestore";
import { guardIsPlainObject } from "./isPlainObject";

/** @throws Error if given non-object */
export function convertObjectToFSTimestamp(value: unknown): Timestamp {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  } else {
    let seconds: number = 0;
    let nanoseconds: number = 0;

    if (value["seconds"]) {
      seconds = value["seconds"] as number;
    }
    if (value["_seconds"]) {
      seconds = value["_seconds"] as number;
    }

    if (value["nanoseconds"]) {
      nanoseconds = value["nanoseconds"] as number;
    }
    if (value["_nanoseconds"]) {
      nanoseconds = value["_nanoseconds"] as number;
    }
    const result = new Timestamp(seconds, nanoseconds);

    return result;
  }
}
