// Libs
import { Fragment, forwardRef, useEffect, useMemo, useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DatePicker from "react-datepicker";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon, CheckIcon } from "@heroicons/react/24/solid";
import { z } from "zod";

import "react-datepicker/dist/react-datepicker.css";

// Local
import * as strings from "../../../strings";
import {
  OTaskStatus,
  getReadableTaskStatus,
} from "../../../models/task-status";
import { convertFromMilitaryTime } from "../../../utils/convertFromMilitaryTime";
import { SiteKey } from "../../../models/site-key";

export const scheduleByPrioritySchema = z.union([
  z.literal(strings.URGENT),
  z.literal(strings.AWAITING_SCHEDULE),
  z.literal(strings.NEXT_OPPORTUNITY),
  z.literal(strings.AWAITING_PARTS),
  z.literal(strings.AWAITING_ESTIMATE),
  z.literal(strings.AWAITING_APPROVAL),
]);
export type ScheduleByPriorityType = z.infer<typeof scheduleByPrioritySchema>;

interface Props {
  // state + funcs to set state
  dateAndTime: Date;
  setDateAndTime: (dateTime: Date) => void;
  duration: number;
  setDuration: (duration: number) => void;

  siteKeyDoc: SiteKey;
  optionalText?: string;
  emailReminder?: React.ReactNode;
  handleClickScheduledByPriority: (
    value: ScheduleByPriorityType | null,
  ) => void;
  /** component to be displayed during the create task process, if applicable */
  assignedToField?: JSX.Element | null;
  plannedHoursField?: JSX.Element;
  overlappingTasksUserName?: string[];
}

export function SchedulingSection(props: Props): JSX.Element {
  let durations = [1, 2, 3, 4, 5, 6, 7, 8];
  if (props.siteKeyDoc.customizations.serviceWindowIncrements === 30) {
    durations = [
      0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8,
    ];
  }

  const scheduleByPriority = useMemo(() => {
    return [
      strings.URGENT,
      strings.AWAITING_SCHEDULE,
      strings.NEXT_OPPORTUNITY,
    ];
  }, []);
  // We're adding these if they're in the siteKey doc's validTaskStatusCodes array.
  const scheduleOptionsToContingentlyAdd = useMemo(() => {
    return [
      OTaskStatus.AWAITING_PARTS,
      OTaskStatus.AWAITING_ESTIMATE,
      OTaskStatus.AWAITING_APPROVAL,
    ];
  }, []);

  useEffect(() => {
    scheduleOptionsToContingentlyAdd.forEach((option) => {
      if (props.siteKeyDoc.validTaskStatusCodes.includes(option)) {
        const taskStatusString = getReadableTaskStatus(option);
        scheduleByPriority.push(taskStatusString);
      }
    });
  }, [
    props.siteKeyDoc.validTaskStatusCodes,
    scheduleByPriority,
    scheduleOptionsToContingentlyAdd,
  ]);

  const [displayTime, setDisplayTime] = useState("");

  const initialVal = strings.SELECT;
  const [selectedPriority, setSelectedPriority] = useState<
    ScheduleByPriorityType | "Select"
  >(initialVal);

  // Used to grey out the calendar stuff because a datetime won't apply if one
  // of these is selected
  const noCalendarDate =
    selectedPriority === strings.AWAITING_SCHEDULE ||
    selectedPriority === strings.AWAITING_PARTS ||
    selectedPriority === strings.AWAITING_ESTIMATE ||
    selectedPriority === strings.AWAITING_APPROVAL ||
    selectedPriority === strings.NEXT_OPPORTUNITY;

  useEffect(() => {
    convertToDisplayTime(props.dateAndTime);
  }, [props.dateAndTime]);

  /** Sets the time to display to the user. (just the time, not the date) */
  function convertToDisplayTime(date: Date): void {
    const hour = date.getHours(); // 0 - 23
    const [convertedHour, amPM] = convertFromMilitaryTime(hour);
    const minute = date.getMinutes(); // 0 - 59
    const formattedMinute = minute.toString().padStart(2, "0");

    setDisplayTime(`${convertedHour}:${formattedMinute} ${amPM}`);
  }

  function onChangeDatePicker(date: Date | null): void {
    if (date) {
      props.setDateAndTime(date);
      convertToDisplayTime(date);

      if (selectedPriority !== "Select") {
        setSelectedPriority("Select");
        onClickScheduledByPriority(null);
      }
    }
  }

  function onClickScheduledByPriority(value: ScheduleByPriorityType | null) {
    props.handleClickScheduledByPriority(value);
  }

  return (
    <Fragment>
      {props.optionalText && (
        <div className="pb-4 text-xl font-bold text-primary">
          {props.optionalText}
        </div>
      )}
      <div className="isolate mt-6 grid gap-6 lg:grid-cols-7 lg:gap-x-2">
        <div className="lg:col-span-3">
          {/* SERVICE DATE AND SERVICE START TIME */}
          <label
            // corresponding ID is on the SchedulingButton
            htmlFor="dateAndTime"
            className="mb-2 inline-block text-xl text-primary"
          >
            {strings.SERVICE_DATE_TIME}
          </label>
          <div className="relative z-30 flex flex-wrap items-center gap-4">
            {/* Tablet/Desktop version */}
            <div className="hidden max-w-fit xs:block">
              <DatePicker
                selected={props.dateAndTime}
                onChange={onChangeDatePicker}
                showTimeSelect
                customInput={<SchedulingButton noDate={noCalendarDate} />}
                // Disable days before today
                minDate={new Date()}
              />
            </div>
            {/* Mobile version */}
            <div className="max-w-fit xs:hidden">
              <DatePicker
                selected={props.dateAndTime}
                onChange={onChangeDatePicker}
                showTimeInput
                customInput={<SchedulingButton noDate={noCalendarDate} />}
                // Disable days before today
                minDate={new Date()}
              />
            </div>
            {/* DISPLAY SELECTED TIME */}
            <div
              className={`transition-colors ${
                noCalendarDate ? "text-gray-400" : "text-gray-600"
              }`}
            >
              <AccessTimeIcon className="mr-2" />
              {noCalendarDate ? "N/A" : displayTime}
            </div>
          </div>
        </div>

        <div className="lg:col-span-3 lg:col-start-1">
          {/* SERVICE WINDOW DURATION */}
          <label
            htmlFor="windowDuration"
            className="mb-2 inline-block text-xl text-primary"
          >
            {strings.SERVICE_WINDOW_DURATION}
          </label>
          <div className="relative z-20">
            <div className="w-36">
              <Listbox
                value={props.duration}
                onChange={(event) => props.setDuration(event)}
              >
                <div className="relative mt-1">
                  <Listbox.Button
                    id="windowDuration"
                    className="relative h-10 w-full cursor-pointer rounded-md border border-gray-400 bg-white py-2 pl-3 text-left text-gray-800 transition-colors hover:border-gray-800"
                  >
                    <span className="block truncate">
                      {props.duration === 1
                        ? "1 hour"
                        : `${props.duration} hours`}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon
                        className="h-5 w-5 text-gray-700"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none">
                      {durations.map((dur, idx) => (
                        <Listbox.Option
                          key={idx}
                          className={({ active, selected }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active || selected
                                ? "bg-primaryOpacity90 text-primary"
                                : "text-gray-700"
                            }`
                          }
                          value={dur}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {dur === 1 ? "1 hour" : `${dur} hours`}
                              </span>
                              {selected ? (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
          </div>
        </div>

        <h3 className="mb-2 mt-8 text-2xl font-bold text-gray-500 lg:col-span-1 lg:col-start-4 lg:row-start-1 lg:mt-0 lg:justify-self-center">
          Or...
        </h3>

        <div className="lg:col-span-3 lg:col-start-5 lg:row-start-1 lg:ml-6">
          <label
            htmlFor="selectByPriority"
            className="mb-2 inline-block text-xl text-primary"
          >
            {strings.SELECT_FROM_THE_FOLLOWING}
          </label>
          <div className="relative z-10">
            <div className="w-full xs:w-72">
              <Listbox
                value={selectedPriority}
                onChange={(val) => {
                  if (val !== "Select") {
                    setSelectedPriority(val);
                    onClickScheduledByPriority(val);
                  }

                  if (val === "Urgent") {
                    props.setDateAndTime(new Date());
                  }
                }}
              >
                <div className="relative mt-1">
                  <Listbox.Button
                    id="selectByPriority"
                    className="relative h-10 w-full cursor-pointer rounded-md border border-gray-400 bg-white py-2 pl-3 text-left text-gray-800 transition-colors hover:border-gray-800"
                  >
                    <span className="block truncate">{selectedPriority}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon
                        className="h-5 w-5 text-gray-700"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>
                  <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none">
                      {scheduleByPriority.map((item, idx) => (
                        <Listbox.Option
                          key={idx}
                          className={({ active, selected }) =>
                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                              active || selected
                                ? "bg-primaryOpacity90 text-primary"
                                : "text-gray-700"
                            }`
                          }
                          value={item}
                        >
                          {({ selected }) => (
                            <>
                              <span
                                className={`block truncate ${
                                  selected ? "font-medium" : "font-normal"
                                }`}
                              >
                                {item}
                              </span>
                              {selected && (
                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              )}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </Listbox>
            </div>
          </div>
        </div>
        {props.emailReminder && (
          <div className="flex items-end lg:col-span-2 lg:col-start-5 lg:ml-6">
            {props.emailReminder}
          </div>
        )}

        {props.assignedToField && (
          <div className="mt-6 max-w-md rounded-md border border-gray-400 transition-colors hover:border-gray-800 lg:col-span-4 lg:col-start-1 lg:row-start-3">
            {props.assignedToField}
          </div>
        )}

        {props.plannedHoursField && (
          <div className="mt-6 max-w-md  transition-colors hover:border-gray-800 lg:col-span-2 lg:col-start-5 lg:row-start-3">
            {props.plannedHoursField}
          </div>
        )}

        {props.assignedToField &&
          props.overlappingTasksUserName &&
          props.overlappingTasksUserName.length > 0 && (
            <div className="col-span-7 col-start-1 italic text-red-700">
              Warning: {props.overlappingTasksUserName.join(",")} has another
              task or event that conflicts with this time
            </div>
          )}
        <p className="mt-4 text-center text-sm font-bold text-gray-500 lg:col-span-7">
          <RenderTaskScheduleText
            type={selectedPriority}
            datetime={props.dateAndTime}
          />
        </p>
      </div>
    </Fragment>
  );
}

type ButtonProps = {
  noDate: boolean;
} & React.ComponentPropsWithRef<"button">;
type Ref = HTMLButtonElement;

const SchedulingButton = forwardRef<Ref, ButtonProps>(
  ({ value, onClick, noDate }, ref) => (
    <button
      className={`${
        noDate && "text-gray-300"
      } inline-flex items-center justify-center rounded-md border border-gray-400 bg-white px-3 py-2 transition-colors hover:border-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2`}
      onClick={onClick}
      ref={ref}
      id="dateAndTime"
      data-testid="dateTimeButton"
    >
      <CalendarMonthIcon className="mr-2 text-gray-600" />
      {/* if they haven't selected anything yet, value is an empty string */}
      {value != null && typeof value != "number" && value.length !== 0
        ? value
        : strings.SELECT}
    </button>
  ),
);

function parseDateTime(date: Date): string {
  // DATE
  // slice - remove the day of the week
  const xdate = date.toDateString().slice(4);

  // TIME
  const hour = date.getHours(); // 0 - 23
  const [convertedHour, amPM] = convertFromMilitaryTime(hour);
  const minute = date.getMinutes(); // 0 - 59
  const formattedMinute = minute.toString().padStart(2, "0");
  const formattedTime = `${convertedHour}:${formattedMinute} ${amPM}`;

  return `${xdate} ${formattedTime}`;
}

function RenderTaskScheduleText(props: {
  type: ScheduleByPriorityType | "Select";
  datetime: Date;
}): JSX.Element {
  const displayDate = parseDateTime(props.datetime);

  switch (props.type) {
    case "Select": {
      return (
        <>
          This task is being <span className="text-primary">Scheduled</span> for{" "}
          <span className="text-primary">{displayDate}</span>
        </>
      );
    }
    case "Urgent": {
      return (
        <>
          This task is being <span className="text-primary">Scheduled</span>{" "}
          <span className="bg-orange-100 px-1 py-0.5 text-orange-700">
            Urgent
          </span>{" "}
          for <span className="text-primary">{displayDate}</span>
        </>
      );
    }
    case "Awaiting Schedule":
    case "Awaiting Parts":
    case "Awaiting Estimate":
    case "Awaiting Approval":
    case "Next Opportunity": {
      return (
        <>
          This task is being <span className="text-primary">Marked</span> as{" "}
          <span className="text-primary">{props.type}</span>
        </>
      );
    }
    default: {
      const _exhaustivenessCheck: never = props.type;
      return _exhaustivenessCheck;
    }
  }
}
