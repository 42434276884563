//Libs
import { DocumentData, DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

//Local
import { NotFoundError } from "../error-classes";
import { dropUndefined, guardIsPlainObject } from "../utils";
import { zFallback } from "../utils/zod-fallback";
import { InvoiceFrequency } from "./membership-template";
import { Task } from "./task";
import { StiltLineItem, StiltLineItemSchema } from "./invoice";

export const membershipStatus = [
  "active",
  "draft",
  "awaitingPayment",
  "canceled",
  "expired",
  "suspended",
  "renewed",
] as const;
// Create the types from the TS array 'membershipStatus'.
export type MembershipStatusValues = (typeof membershipStatus)[number];

export function isMembershipStatus(
  value: unknown,
): value is MembershipStatusValues {
  return membershipStatus.includes(value as any);
}

export enum MembershipStatus {
  Active = "active",
  Draft = "draft",
  AwaitingPayment = "awaitingPayment",
  Canceled = "canceled",
  Expired = "expired",
  Suspended = "suspended",
  Renewed = "renewed",
}

export function getReadableMembershipStatus(value: string): string {
  switch (value) {
    case "draft":
      return "Draft";
    case "active":
      return "Active";
    case "awaitingPayment":
      return "Awaiting Payment";
    case "canceled":
      return "Canceled";
    case "expired":
      return "Expired";
    case "suspended":
      return "Suspended";
    case "renewed":
      return "Renewed";
    default:
      return "UNKNOWN";
  }
}

export interface Membership {
  createdBy: string;
  customerID: string;
  customerLocationID: string | null;
  customerName: string;
  lastModifiedBy: string;
  timestampCreated: Timestamp;
  timestampLastModified: Timestamp;
  locationID: string | null;

  lastCompletedTaskDate: Timestamp | null;
  lastPaymentAmount: number | null;
  lastPaymentDate: Timestamp | null;
  membershipEndDate: Timestamp | null;
  membershipStartDate: Timestamp | null;
  membershipTemplateID: string;
  nextInvoiceDate: Timestamp | null;
  nextScheduledTaskDate: Timestamp | null;
  notes: string | null;
  status: MembershipStatusValues;
  assetIDs?: string[];
  tasks?: Record<string, Task>;
  automaticallyGenerateTasks?: boolean;
  /**
   * For invoices/payments that are automatically generated/paid (`template.invoiceMethod === "automatic"`).
   * Determines if the customer receives an automatic email receipt on successful payment.
   */
  automaticallySendReceipt?: boolean;
  /** @deprecated - use `template.invoiceMethod` */
  automaticallyRenewMembership?: boolean;
  /**
   * Applicable to memberships where template.invoiceMethod = automatic.
   *
   * - TRUE -> the payment will be automatically processed when the invoice is due.
   * - FALSE -> the payment will not be automatically processed when the invoice is due.
   * - NOT SET -> the template's value (same property name) will be used.
   *
   * UI = "automatically charge primary card on file"
   */
  automaticallyPayInvoice?: boolean;
  /**
   * Applicable if automaticallyPayInvoice is false. Applicable if automaticallyPayInvoice
   * is missing, but template.automaticallyPayInvoice is false or unset.
   */
  automaticallySendInvoice?: boolean;
  renewedToMembershipID?: string;
  renewedFromMembershipID?: string;
  /**
   * This is used for "Membership Issues" - to differentiate between a membership
   * that just needs dues collected, versus a membership that needs to be renewed.
   */
  isPendingRenewal?: boolean;
  suspensionData?: {
    message?: string;
    timestampSuspended?: Timestamp;
    pendingInvoiceID?: string;
    suspendedBy?: string;
  };
  /**
   * Exists to track the invoices that have been automatically generated for this
   * membership, but have not yet been paid. (So we can avoid creating duplicate
   * invoices, while also _not_ preventing a legitimate 2nd, 3rd, etc. invoice
   * from being created.)
   */
  // old data is removed by monitorInvoices
  pendingInvoiceData?: {
    invoiceID: string;
    timestampCreated: Timestamp;
  }[];
  /**
   * The invoice object is used when the Stilt-customer needs more control over
   * invoices that are automatically generated, i.e., when the template's PBI is
   * not indicative of the actual price of membership dues.
   *
   * This allows the Stilt-customer to build out a bespoke invoice, with line items
   * of their choosing, to be used for a particular membership, for the duration
   * of that membership.
   *
   * The email field is separated from the invoice object so that the invoice can be
   * emailed to a different address, without having to also create a custom invoice.
   */
  overrideData?: {
    invoice?: InvoiceOverrideData;
    email?: string;
  };
}

/** Very limited subset of StiltInvoice. */
export type InvoiceOverrideData = {
  note: string | null;
  internalNotes: string | null;
  lineItems: StiltLineItem[];
  // the presence of the tax fields allows us to skip fetching each lineItem.priceBookItemID on the backend
  totalTaxAmount: number;
  totalTaxAmountPST?: number;
  totalTaxAmountGST?: number;
  subTotal: number;
  totalAmount: number;
  discount: number;
};

export interface ExistingMembership extends Membership {
  id: string;
  refPath: string;
}

export interface EstimateItemMembershipData {
  membershipTemplateID: string;
  isSameTask: boolean;
  notes: string | null;
  selectedStartDate: Timestamp | null;
  renewedFromMembershipID?: string;
}

/***********************/
// FOR THE API
/***********************/
type withoutTimestamps = Omit<
  Membership,
  | "timestampCreated"
  | "timestampLastModified"
  | "membershipStartDate"
  | "lastTaskDate"
  | "nextTaskDate"
  | "lastRenewalAmount"
>;

// Timestamps are added on the backend.
export interface Membership_CreateAPI extends withoutTimestamps {
  siteKey: string;
  frequency: InvoiceFrequency;
}

// timestampLastModified is added on the backend. (timestampCreated won't change)
export type Membership_UpdateAPI = Omit<
  Partial<ExistingMembership>,
  "timestampCreated" | "timestampLastModified"
>;

export type Membership_DeleteAPI = ExistingMembership["id"];

/** Utilities for interacting with Membership objects  */
export const MembershipManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingMembership object.
   */
  createFromFirestoreSnapshot: createMembershipFromFirestoreSnapshot,
  /** Drop undefined before saving to the database. */
  convertForFirestore: convertMembershipForFirestore,
  /** Drop `id` and `refPath` before saving to the database. Drop undefined */
  convertExistingForFirestore: convertExistingMembershipForFirestore,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateMembership,
  /** Validate a Membership doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateMembershipWithFallbacks,
  /** Validate the given membership properties */
  parsePartial: parsePartial,
};

/**
 * Convert the Document Snapshot into a validated ExistingMembership object.
 */
function createMembershipFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingMembership {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateMembershipWithFallbacks(snapshotData),
  };
}

function convertMembershipForFirestore(
  membership: Membership | Partial<Membership>,
): DocumentData {
  const localItem = Object.assign({}, membership);
  return dropUndefined(localItem);
}

function convertExistingMembershipForFirestore(
  membership: ExistingMembership | Partial<ExistingMembership>,
): DocumentData {
  const localItem = Object.assign({}, membership);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, refPath, ...rest } = localItem;
  return dropUndefined(rest);
}

/* Zod validation schemas */
function validateMembershipWithFallbacks(value: unknown): Membership {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = membershipSchemaWithFallbacks.parse(value);
  return result;
}

function validateMembership(value: unknown): Membership {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = membershipSchema.parse(value);
  return result;
}

function parsePartial(value: unknown): z.infer<typeof partialSchema> {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return partialSchema.parse(value);
}

const membershipSchemaWithFallbacks = z.object({
  customerID: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "membershipSchemaWithFallbacks: 'customerID'",
  ),
  customerLocationID: zFallback(
    z.string().min(1).max(200).nullable(),
    "unknown",
    "membershipSchemaWithFallbacks: 'customerLocationID'",
  ),
  customerName: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "membershipSchemaWithFallbacks: 'customerName'",
  ),
  locationID: zFallback(
    z.string().min(1).max(200).nullable(),
    null,
    "membershipSchemaWithFallbacks: 'locationID'",
  ),
  membershipTemplateID: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "membershipSchemaWithFallbacks: 'membershipTemplateID'",
  ),
  status: zFallback(
    z.enum(membershipStatus),
    "canceled",
    "membershipSchemaWithFallbacks: 'status'",
  ),
  notes: zFallback(
    z.string().max(1000).nullable(),
    null,
    "membershipSchemaWithFallbacks: 'notes'",
  ),
  lastRenewalDate: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "membershipSchemaWithFallbacks: 'lastRenewalDate'",
  ),
  lastCompletedTaskDate: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "membershipSchemaWithFallbacks: 'lastCompletedTaskDate'",
  ),
  lastPaymentDate: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "membershipSchemaWithFallbacks: 'lastPaymentDate'",
  ),
  lastPaymentAmount: zFallback(
    z.number().nullable(),
    null,
    "membershipSchemaWithFallbacks: 'lastPaymentAmount'",
  ),
  nextInvoiceDate: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "membershipSchemaWithFallbacks: 'nextInvoiceDate'",
  ),
  nextScheduledTaskDate: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "membershipSchemaWithFallbacks: 'nextScheduledTaskDate'",
  ),
  membershipStartDate: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "membershipSchemaWithFallbacks: 'membershipStartDate'",
  ),
  membershipEndDate: zFallback(
    z.instanceof(Timestamp).nullable(),
    null,
    "membershipSchemaWithFallbacks: 'membershipEndDate'",
  ),
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "membershipSchemaWithFallbacks: 'timestampCreated'",
  ),
  timestampLastModified: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "membershipSchemaWithFallbacks: 'timestampLastModified'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  createdBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "membershipSchemaWithFallbacks: 'createdBy'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  lastModifiedBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "membershipSchemaWithFallbacks: 'lastModifiedBy'",
  ),
  assetIDs: zFallback(
    z.array(z.string()).optional(),
    [],
    "membershipSchemaWithFallbacks: 'assetIDs'",
  ),
  tasks: zFallback(
    z.any().optional(),
    undefined,
    "membershipSchemaWithFallbacks: 'tasks'",
  ),
  automaticallyGenerateTasks: zFallback(
    z.boolean().optional(),
    undefined,
    "membershipSchemaWithFallbacks: 'automaticallyGenerateTasks'",
  ),
  automaticallySendReceipt: zFallback(
    z.boolean().optional(),
    undefined,
    "membershipSchemaWithFallbacks: 'automaticallySendReceipt'",
  ),
  automaticallyRenewMembership: zFallback(
    z.boolean().optional(),
    undefined,
    "membershipSchemaWithFallbacks: 'automaticallyRenewMembership'",
  ),
  automaticallyPayInvoice: zFallback(
    z.boolean().optional(),
    undefined,
    "membershipSchemaWithFallbacks: 'automaticallyPayInvoice'",
  ),
  automaticallySendInvoice: zFallback(
    z.boolean().optional(),
    undefined,
    "membershipSchemaWithFallbacks: 'automaticallySendInvoice'",
  ),
  renewedToMembershipID: z.string().optional(),
  renewedFromMembershipID: z.string().optional(),
  isPendingRenewal: zFallback(
    z.boolean().optional(),
    undefined,
    "membershipSchemaWithFallbacks: 'isPendingRenewal'",
  ),
  suspensionData: zFallback(
    z
      .object({
        message: zFallback(
          z.string().min(1).max(1000).optional(),
          undefined,
          "membershipSchemaWithFallbacks: 'suspensionData.message'",
        ),
        timestampSuspended: zFallback(
          z.instanceof(Timestamp).optional(),
          undefined,
          "membershipSchemaWithFallbacks: 'suspensionData.timestampSuspended'",
        ),
        pendingInvoiceID: zFallback(
          z.string().min(1).max(200).optional(),
          undefined,
          "membershipSchemaWithFallbacks: 'suspensionData.pendingInvoiceID'",
        ),
        suspendedBy: zFallback(
          z.string().min(1).max(200).optional(),
          undefined,
          "membershipSchemaWithFallbacks: 'suspensionData.suspendedBy'",
        ),
      })
      .optional(),
    undefined,
    "membershipSchemaWithFallbacks: 'suspensionData'",
  ),
  pendingInvoiceData: zFallback(
    z
      .array(
        z.object({
          invoiceID: z.string().min(1).max(200),
          timestampCreated: z.instanceof(Timestamp),
        }),
      )
      .optional(),
    undefined,
    "membershipSchemaWithFallbacks: 'pendingInvoiceData'",
  ),
  overrideData: zFallback(
    z
      .object({
        invoice: z
          .object({
            note: zFallback(
              z.string().min(1).max(8000).nullable(),
              null,
              "membershipSchemaWithFallbacks: 'overrideData.invoice.note'",
            ),
            internalNotes: zFallback(
              z.string().min(1).max(8000).nullable(),
              null,
              "membershipSchemaWithFallbacks: 'overrideData.invoice.internalNotes'",
            ),
            lineItems: z.array(StiltLineItemSchema),
            totalTaxAmount: z.number(),
            totalTaxAmountPST: zFallback(
              z.number().optional(),
              undefined,
              "membershipSchemaWithFallbacks: 'overrideData.invoice.totalTaxAmountPST'",
            ),
            totalTaxAmountGST: zFallback(
              z.number().optional(),
              undefined,
              "membershipSchemaWithFallbacks: 'overrideData.invoice.totalTaxAmountGST'",
            ),
            subTotal: z.number(),
            totalAmount: z.number(),
            discount: zFallback(
              z.number(),
              0,
              "membershipSchemaWithFallbacks: 'overrideData.invoice.discount'",
            ),
          })
          .optional(),
        email: zFallback(
          z.string().min(1).max(200).optional(),
          undefined,
          "membershipSchemaWithFallbacks: 'overrideData.email'",
        ),
      })
      .optional(),
    undefined,
    "membershipSchemaWithFallbacks: 'overrideData'",
  ),
});

export const InvoiceOverrideSchema = z.object({
  note: z.string().min(1).max(8000).nullable(),
  internalNotes: z.string().min(1).max(8000).nullable(),
  lineItems: z.array(StiltLineItemSchema).min(1),
  totalTaxAmount: z.number(),
  totalTaxAmountPST: z.number().optional(),
  totalTaxAmountGST: z.number().optional(),
  subTotal: z.number(),
  totalAmount: z.number(),
  discount: z.number(),
});

const membershipSchema = z.object({
  createdBy: z.string().min(1).max(200),
  customerID: z.string().min(1).max(200),
  customerLocationID: z.string().min(1).max(200).nullable(),
  customerName: z.string().min(1).max(200),
  lastCompletedTaskDate: z.instanceof(Timestamp).nullable(),
  lastModifiedBy: z.string().min(1).max(200),
  lastPaymentAmount: z.number().nullable(),
  lastPaymentDate: z.instanceof(Timestamp).nullable(),
  locationID: z.string().min(1).max(200).nullable(),
  membershipEndDate: z.instanceof(Timestamp).nullable(),
  membershipStartDate: z.instanceof(Timestamp).nullable(),
  membershipTemplateID: z.string().min(1).max(200),
  nextInvoiceDate: z.instanceof(Timestamp).nullable(),
  nextScheduledTaskDate: z.instanceof(Timestamp).nullable(),
  notes: z.string().max(1000).nullable(),
  status: z.enum(membershipStatus),
  timestampCreated: z.instanceof(Timestamp),
  timestampLastModified: z.instanceof(Timestamp),
  assetIDs: z.array(z.string()).optional(),
  tasks: z.any().optional(),
  automaticallyGenerateTasks: z.boolean().optional(),
  automaticallySendReceipt: z.boolean().optional(),
  automaticallyRenewMembership: z.boolean().optional(),
  automaticallyPayInvoice: z.boolean().optional(),
  automaticallySendInvoice: z.boolean().optional(),
  renewedToMembershipID: z.string().optional(),
  renewedFromMembershipID: z.string().optional(),
  isPendingRenewal: z.boolean().optional(),
  suspensionData: z
    .object({
      message: z.string().min(1).max(1000).optional(),
      timestampSuspended: z.instanceof(Timestamp).optional(),
      pendingInvoiceID: z.string().min(1).max(200).optional(),
      suspendedBy: z.string().min(1).max(200).optional(),
    })
    .optional(),
  pendingInvoiceData: z
    .array(
      z.object({
        invoiceID: z.string().min(1).max(200),
        timestampCreated: z.instanceof(Timestamp),
      }),
    )
    .optional(),
  overrideData: z
    .object({
      invoice: InvoiceOverrideSchema.optional(),
      email: z.string().min(1).max(200).optional(),
    })
    .optional(),
});

const partialSchema = deepPartialify(membershipSchema);

/**
 * this is stolen from some dude on the internet. (@largis21)
 * deep in the comments -- https://github.com/colinhacks/zod/issues/2854
 *
 * BEWARE USING THIS.
 *
 * alternative? --
 * https://gist.github.com/jaens/7e15ae1984bb338c86eb5e452dee3010
 */

// This is stolen from the zod repo
//
// z.ZodObject.deepPartial is deprecated, but there is no good alternative yet
// Deprectation issue: https://github.com/colinhacks/zod/issues/2106
// Also related: https://github.com/colinhacks/zod/issues/2854

type ZodDeepPartial<T extends z.ZodTypeAny> =
  T extends z.ZodObject<z.ZodRawShape>
    ? z.ZodObject<
        {
          [k in keyof T["shape"]]: z.ZodOptional<ZodDeepPartial<T["shape"][k]>>;
        },
        T["_def"]["unknownKeys"],
        T["_def"]["catchall"]
      >
    : T extends z.ZodArray<infer Type, infer Card>
      ? z.ZodArray<ZodDeepPartial<Type>, Card>
      : T extends z.ZodOptional<infer Type>
        ? z.ZodOptional<ZodDeepPartial<Type>>
        : T extends z.ZodNullable<infer Type>
          ? z.ZodNullable<ZodDeepPartial<Type>>
          : T extends z.ZodTuple<infer Items>
            ? {
                [k in keyof Items]: Items[k] extends z.ZodTypeAny
                  ? ZodDeepPartial<Items[k]>
                  : never;
              } extends infer PI
              ? PI extends z.ZodTupleItems
                ? z.ZodTuple<PI>
                : never
              : never
            : T;

function deepPartialify<T extends z.ZodTypeAny>(schema: T): ZodDeepPartial<T> {
  return _deepPartialify(schema);
}

function _deepPartialify(schema: z.ZodTypeAny): any {
  if (schema instanceof z.ZodObject) {
    const newShape: any = {};

    for (const key in schema.shape) {
      const fieldSchema = schema.shape[key];
      newShape[key] = z.ZodOptional.create(_deepPartialify(fieldSchema));
    }
    return new z.ZodObject({
      ...schema._def,
      shape: () => newShape,
    }) as any;
  } else if (schema instanceof z.ZodArray) {
    return new z.ZodArray({
      ...schema._def,
      type: _deepPartialify(schema.element),
    });
  } else if (schema instanceof z.ZodOptional) {
    return z.ZodOptional.create(_deepPartialify(schema.unwrap()));
  } else if (schema instanceof z.ZodNullable) {
    return z.ZodNullable.create(_deepPartialify(schema.unwrap()));
  } else if (schema instanceof z.ZodTuple) {
    return z.ZodTuple.create(
      schema.items.map((item: any) => _deepPartialify(item)),
    );
  } else {
    return schema;
  }
}
