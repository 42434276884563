import { useState } from "react";
import BaseButtonPrimary from "../../../../components/BaseButtonPrimary";
import BaseButtonSecondary from "../../../../components/BaseButtonSecondary";
import StyledMessage from "../../../../components/StyledMessage";
import OwnerForm from "./OwnerForm";

interface Owner {
  firstName: string;
  lastName: string;
  title:
    | "Owner"
    | "Partner"
    | "Director"
    | "President"
    | "CEO"
    | "CFO"
    | "Chairman"
    | "Comptroller"
    | "General Manager"
    | "Office Manager"
    | "Treasurer"
    | "Vice President";
  street1: string;
  street2?: string;
  city: string;
  state: string;
  zipCode: string;
  countryCode: string;
  email: string;
  birthDate: string;
  socialSecurity: string;
  phone: string;
  stakePercentage: number;
  driverLicense?: string;
  driverLicenseState?: string;
  driverLicenseCountryCode: string;
}

interface OwnersStepProps {
  initialData: Owner[];
  officerData: {
    firstName: string;
    lastName: string;
    title:
      | "Owner"
      | "Partner"
      | "Director"
      | "President"
      | "CEO"
      | "CFO"
      | "Chairman"
      | "Comptroller"
      | "General Manager"
      | "Office Manager"
      | "Treasurer"
      | "Vice President";
    street1: string;
    street2?: string;
    city: string;
    state: string;
    zipCode: string;
    countryCode: string;
    email: string;
    birthDate: string;
    socialSecurity: string;
    phone: string;
    driverLicense?: string;
    driverLicenseState?: string;
    driverLicenseCountryCode: string;
  };
  onComplete: (data: Owner[]) => void;
}

export default function OwnersStep({
  initialData,
  officerData,
  onComplete,
}: OwnersStepProps) {
  const [owners, setOwners] = useState<Owner[]>(initialData);
  const [error, setError] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const totalStake = owners.reduce(
    (sum, owner) => sum + owner.stakePercentage,
    0,
  );

  const handleAddOwner = (owner: Owner) => {
    if (totalStake + owner.stakePercentage > 100) {
      setError("Total ownership percentage cannot exceed 100%");
      return;
    }

    setOwners((prev) => [...prev, owner]);
    setShowForm(false);
  };

  const handleEditOwner = (owner: Owner, index: number) => {
    const newTotal =
      totalStake - owners[index].stakePercentage + owner.stakePercentage;
    if (newTotal > 100) {
      setError("Total ownership percentage cannot exceed 100%");
      return;
    }

    setOwners((prev) => {
      const updated = [...prev];
      updated[index] = owner;
      return updated;
    });
    setEditingIndex(null);
  };

  const handleRemoveOwner = (index: number) => {
    setOwners((prev) => prev.filter((_, i) => i !== index));
  };

  const handleCopyOfficer = () => {
    const newOwner: Owner = {
      ...officerData,
      stakePercentage: 100,
    };

    setOwners([newOwner]);
    setError("");
  };

  const validateOwners = (): boolean => {
    if (owners.length === 0) {
      setError("At least one owner is required");
      return false;
    }
    if (totalStake !== 100) {
      setError("Total ownership must equal 100%");
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (validateOwners()) {
      setError("");
      onComplete(owners);
    }
  };

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-2xl font-bold text-gray-900">Business Owners</h2>
        <p className="mt-1 text-sm text-gray-500">
          Add all owners with 25% or more ownership stake.
        </p>
      </div>

      <div className="space-y-6">
        {owners.length === 0 ? (
          <div className="rounded-lg border border-gray-200 p-6 text-center">
            <p className="mb-4 text-gray-600">No owners added yet.</p>
            <div className="space-x-4">
              <button
                onClick={() => setShowForm(true)}
                className="hover:bg-primary-dark inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white"
              >
                Add New Owner
              </button>
              <button
                onClick={handleCopyOfficer}
                className="inline-flex items-center rounded-md border border-primary px-4 py-2 text-sm font-medium text-primary hover:bg-primary hover:text-white"
              >
                Copy Officer as Owner
              </button>
            </div>
          </div>
        ) : (
          <div className="divide-y rounded-lg border border-gray-200">
            {owners.map((owner, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-4"
              >
                <div>
                  <p className="font-medium">{`${owner.firstName} ${owner.lastName}`}</p>
                  <p className="text-sm text-gray-500">{`${owner.stakePercentage}% ownership`}</p>
                </div>
                <div className="space-x-2">
                  <button
                    onClick={() => setEditingIndex(index)}
                    className="hover:text-primary-dark text-primary"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleRemoveOwner(index)}
                    className="text-red-600 hover:text-red-700"
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}

        {showForm || editingIndex !== null ? (
          <OwnerForm
            initialData={
              editingIndex !== null ? owners[editingIndex] : undefined
            }
            remainingStake={
              100 -
              totalStake +
              (editingIndex !== null ? owners[editingIndex].stakePercentage : 0)
            }
            onSubmit={
              editingIndex !== null
                ? (owner) => handleEditOwner(owner, editingIndex)
                : handleAddOwner
            }
            onCancel={() => {
              setShowForm(false);
              setEditingIndex(null);
            }}
          />
        ) : (
          <button
            onClick={() => setShowForm(true)}
            className="hover:bg-primary-dark inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white"
          >
            Add Owner
          </button>
        )}

        {error && (
          <StyledMessage type="error">{{ message: error }}</StyledMessage>
        )}

        <div className="flex justify-between pt-6">
          <BaseButtonSecondary
            type="button"
            onClick={() => window.history.back()}
          >
            Back
          </BaseButtonSecondary>

          <BaseButtonPrimary
            type="button"
            onClick={handleSubmit}
            disabled={owners.length === 0}
          >
            Continue
          </BaseButtonPrimary>
        </div>
      </div>
    </div>
  );
}
