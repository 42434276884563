import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Close, Check, Search, Edit } from "@mui/icons-material";
import BaseButtonPrimary from "./BaseButtonPrimary";
import BaseButtonSecondary from "./BaseButtonSecondary";
import { ColumnOption } from "../models/scheduling-types";
import { ExistingSiteKeyUserDoc } from "../models/site-key-users";

interface ChatRoomUserSelectionDialogProps {
  title: string;
  description?: string;
  open: boolean;
  onClose: () => void;
  onConfirm: (groupName?: string) => void;
  options: ColumnOption[];
  selectedValues: string[];
  onSelectionChange: (values: string[]) => void;
  confirmButtonText?: string;
  usersList: ExistingSiteKeyUserDoc[];
  showSelectedSection?: boolean;
  currentParticipants?: string[];
  isGroupChat?: boolean;
  isJobChat?: boolean;
  isSiteAdmin?: boolean;
}

export default function ChatRoomUserSelectionDialog({
  title,
  description,
  open,
  onClose,
  onConfirm,
  options,
  selectedValues,
  onSelectionChange,
  confirmButtonText = "Confirm",
  usersList,
  showSelectedSection = true,
  currentParticipants = [],
  isGroupChat = false,
  isJobChat = false,
  isSiteAdmin = false,
}: ChatRoomUserSelectionDialogProps) {
  const [searchQuery, setSearchQuery] = useState("");
  const [groupName, setGroupName] = useState("Group Chat");

  // Determine if this is a group chat (more than one user selected)
  const isCreatingGroupChat = selectedValues.length > 1 || isGroupChat;

  // Check if user can create a group chat (must be site admin or job chat)
  const canCreateGroupChat = isSiteAdmin || isJobChat;

  // Filter options based on search query
  const filteredOptions = options.filter((option) =>
    String(option.text).toLowerCase().includes(searchQuery.toLowerCase()),
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="user-selection-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle
        id="user-selection-dialog-title"
        className="flex items-center justify-between"
      >
        <span>{title}</span>
        <button
          onClick={onClose}
          className="rounded-full p-1 hover:bg-gray-100"
        >
          <Close className="h-6 w-6" />
        </button>
      </DialogTitle>

      <DialogContent>
        {description && (
          <p className="mb-3 text-sm text-gray-600">{description}</p>
        )}

        {/* Search input */}
        <div className="relative mb-4">
          <Search className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search users..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full rounded-md border border-gray-300 py-2 pl-10 pr-3 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary"
          />
        </div>

        {/* Current participants section (if applicable) */}
        {currentParticipants.length > 0 && (
          <div className="mb-4">
            <h4 className="mb-2 text-sm font-medium text-gray-600">
              Current Participants:
            </h4>
            <div className="flex flex-wrap gap-2">
              {currentParticipants.map((userId) => {
                const user = usersList.find((u) => u.id === userId);
                return (
                  <div
                    key={userId}
                    className="flex items-center gap-1 rounded-full bg-gray-100 px-3 py-1 text-xs"
                  >
                    {user?.userPhoto_URL ? (
                      <img
                        src={user.userPhoto_URL}
                        alt={user.displayName}
                        className="h-5 w-5 rounded-full"
                      />
                    ) : (
                      <div className="flex h-5 w-5 items-center justify-center rounded-full bg-gray-300 text-xs">
                        {user?.displayName?.charAt(0) || "?"}
                      </div>
                    )}
                    <span>{user?.displayName || userId}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/* User selection with avatars */}
        <div className="mb-4 max-h-60 overflow-y-auto rounded-md border border-gray-200 p-2">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => {
              const isSelected = selectedValues.includes(
                option.value as string,
              );
              const isCurrentParticipant = currentParticipants.includes(
                option.value as string,
              );
              const user = usersList.find((u) => u.id === option.value);

              // Skip users who are already participants if they're shown in the current participants section
              if (isCurrentParticipant && currentParticipants.length > 0) {
                return null;
              }

              return (
                <div
                  key={option.value}
                  className={`flex cursor-pointer items-center justify-between rounded-md p-2 transition-colors ${
                    isSelected ? "bg-primary/10" : "hover:bg-gray-100"
                  }`}
                  onClick={() => {
                    onSelectionChange(
                      selectedValues.includes(option.value as string)
                        ? selectedValues.filter((v) => v !== option.value)
                        : [...selectedValues, option.value as string],
                    );
                  }}
                >
                  <div className="flex items-center gap-3">
                    {user?.userPhoto_URL ? (
                      <img
                        src={user.userPhoto_URL}
                        alt={String(option.text)}
                        className="h-8 w-8 rounded-full object-cover"
                      />
                    ) : (
                      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-300 text-gray-700">
                        {String(option.text)?.charAt(0) || "?"}
                      </div>
                    )}
                    <span className="font-medium">{option.text}</span>
                  </div>

                  {isSelected && (
                    <div className="flex h-6 w-6 items-center justify-center rounded-full bg-primary text-white">
                      <Check className="h-4 w-4" aria-hidden="true" />
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div className="py-4 text-center text-gray-500">
              {searchQuery
                ? "No users match your search"
                : "No users available"}
            </div>
          )}
        </div>

        {/* Selected users preview */}
        {showSelectedSection && selectedValues.length > 0 && (
          <div className="mb-4">
            <p className="mb-2 text-sm font-medium text-gray-600">
              Selected ({selectedValues.length}):
            </p>
            <div className="flex flex-wrap gap-2">
              {selectedValues.map((userId) => {
                const user = usersList.find((u) => u.id === userId);
                const userName =
                  options.find((o) => o.value === userId)?.text || "User";

                return (
                  <div
                    key={userId}
                    className="bg-primary/10 flex items-center gap-1 rounded-full px-3 py-1 text-sm text-primary"
                  >
                    {user?.userPhoto_URL ? (
                      <img
                        src={user.userPhoto_URL}
                        alt={userName as string}
                        className="h-5 w-5 rounded-full"
                      />
                    ) : (
                      <div className="bg-primary/20 flex h-5 w-5 items-center justify-center rounded-full text-xs text-primary">
                        {String(userName).charAt(0)}
                      </div>
                    )}
                    <span>{userName}</span>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        onSelectionChange(
                          selectedValues.filter((v) => v !== userId),
                        );
                      }}
                      className="hover:bg-primary/20 ml-1 rounded-full"
                    >
                      <Close className="h-4 w-4" />
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {/* Group name input field (only shown when creating a group chat) */}
        {isCreatingGroupChat && !isJobChat && canCreateGroupChat && (
          <div className="mb-4">
            <label
              htmlFor="group-name"
              className="mb-1 block text-sm font-medium text-gray-700"
            >
              Group Chat Name
            </label>
            <div className="relative">
              <input
                id="group-name"
                type="text"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
                placeholder="Enter a name for this group chat"
                className="w-full rounded-md border border-gray-300 py-2 pl-3 pr-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary"
              />
              <Edit className="absolute right-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
            </div>
          </div>
        )}

        {/* Show warning message for non-admin users trying to select multiple users */}
        {isCreatingGroupChat && !canCreateGroupChat && (
          <div className="mb-4 rounded-md bg-yellow-50 p-3 text-sm text-yellow-700">
            <p>
              Only site administrators can create group chats. Please select a
              single user for direct messaging.
            </p>
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <BaseButtonSecondary onClick={onClose}>Cancel</BaseButtonSecondary>
        <BaseButtonPrimary
          onClick={() => onConfirm(isCreatingGroupChat ? groupName : undefined)}
          disabled={selectedValues.length === 0}
        >
          {confirmButtonText}
        </BaseButtonPrimary>
      </DialogActions>
    </Dialog>
  );
}
