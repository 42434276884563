//Libs
import { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/solid";

//Local
import { ColumnOption } from "../models/scheduling-types";

interface Props {
  optionList: ColumnOption[];
  onSelection: (selectedValue: ColumnOption["value"]) => void;
  initialValue: ColumnOption["value"] | null;
}

export default function ResourcesColumnDropdown({
  onSelection,
  optionList,
  initialValue,
}: Props) {
  const [selected, setSelected] = useState<ColumnOption["value"]>(
    initialValue ?? "technicians",
  );

  useEffect(() => {
    if (initialValue) {
      setSelected(initialValue);
    }
  }, [initialValue]);

  return (
    <div className="z-10">
      <div className="w-40">
        <Listbox
          value={selected}
          onChange={(event) => {
            setSelected(event);
            onSelection(event);
          }}
        >
          <div className="relative">
            <Listbox.Button className="relative h-10 w-full cursor-default rounded-md border border-primary bg-white py-2 pl-3 pr-10 text-left outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primaryLight sm:text-sm">
              <span className="block truncate">
                {" "}
                {selected !== null
                  ? (optionList.find((a) => a.value === selected)?.text ??
                    "Select a resource")
                  : "Select a resource"}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-700"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                {optionList.map((option, optionIdx) => (
                  <Listbox.Option
                    key={optionIdx}
                    className={({ active, selected }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active || selected
                          ? "bg-primaryOpacity90 text-primary"
                          : "text-gray-700"
                      }`
                    }
                    value={option.value}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {option !== null ? option.text : "Select a resource"}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    </div>
  );
}
