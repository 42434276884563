import { useState } from "react";
import { ConfigSetting } from "../../models/site-configuration";
import ConfigurationSection from "./ConfigurationSection";
import { ConfigTitlesMap, SectionMetadata } from "./SiteConfigurationContainer";
import HeadingOne from "../../components/HeadingOne";

interface Props {
  configData: Record<string, Record<string, ConfigSetting<any>>> | null;
  configTitles: ConfigTitlesMap | null;
}

export default function SiteConfigurationPage({
  configData,
  configTitles,
}: Props) {
  // Get sorted sections based on sortOrder
  const sortedSections = configTitles
    ? Object.entries(configTitles)
        .sort(([, a], [, b]) => a.sortOrder - b.sortOrder)
        .map(([key]) => key)
    : [];

  const [selectedSection, setSelectedSection] = useState<string | null>(
    sortedSections[0] || null,
  );

  if (!configData || !configTitles) return null;

  return (
    <div>
      <HeadingOne>Site Configuration</HeadingOne>
      <div className="mt-8 flex">
        {/* Left sidebar */}
        <div className="w-48 shrink-0 border-r px-0">
          <nav className="space-y-1">
            {sortedSections.map((sectionKey) => {
              // Only show sections that exist in configData
              if (!configData[sectionKey]) return null;

              const metadata = configTitles[sectionKey];

              return (
                <button
                  key={sectionKey}
                  onClick={() => setSelectedSection(sectionKey)}
                  className={`w-full px-3 py-2 text-left text-sm font-medium ${
                    selectedSection === sectionKey
                      ? "bg-primary text-white"
                      : "text-gray-600 hover:bg-gray-50"
                  }`}
                  title={metadata.description}
                >
                  {metadata.title}
                </button>
              );
            })}
          </nav>
        </div>

        {/* Main content area */}
        <div className="flex-1 pl-6">
          {selectedSection && configData[selectedSection] && (
            <ConfigurationSection
              sectionKey={selectedSection}
              sectionName={configTitles[selectedSection].title}
              sectionDescription={configTitles[selectedSection].description}
              sectionData={configData[selectedSection]}
            />
          )}
        </div>
      </div>
    </div>
  );
}
