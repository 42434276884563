//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
// import EmailIcon from "@mui/icons-material/Email";
// import SmsIcon from "@mui/icons-material/Sms";
import { Timestamp } from "firebase/firestore";
import { Fragment, useEffect, useState } from "react";
import SendIcon from "@mui/icons-material/Send";

//Local
import * as strings from "../../strings";
import BaseModal from "../BaseModal";
import { convertToReadableTimestamp } from "../../assets/js/convertToReadableTimestamp";
import BaseButtonPrimary from "../BaseButtonPrimary";
import { ErrorMessage } from "../ErrorMessage";
import BaseInputTextArea from "../BaseInputTextArea";
import MultipleEmailSelector from "../MultipleEmailSelector";
import BaseInputText from "../BaseInputText";
import StyledSwitchGroup from "../StyledSwitchGroup";
import BaseButtonSecondary from "../BaseButtonSecondary";
import HeadingOne from "../HeadingOne";

interface Props {
  /* DATA */
  defaultIncludeJobPhotos: boolean;
  isDialogOpen: boolean;
  timestampSentToCustomer: Timestamp | null;
  customerEmailList: string[] | null;
  merchantName: string;
  emailBody?: string | null;
  emailSubject?: string | null;
  title: string;
  /* FUNCTIONS */
  closeDialog: () => void;
  sendEstimateOrPartsInfoViaEmail?: (
    emailAddressList: string[],
    emailBody: string,
    emailSubject: string,
  ) => void;
  sendInvoiceEmail?: (emailList: string[], includeJobPhotos: boolean) => void;
  sendEmailReceipt?: (emailList: string[]) => void;
  // sendEstimateViaSMS?: () => void;
}

export default function HandleSendEmailDialog(props: Props) {
  const [selectedEmailList, setSelectedEmailList] = useState<string[]>([]);
  const [errorString, setErrorString] = useState<string | null>(null);
  const [isBusy, setIsBusy] = useState(false);

  //this is used only when send Invoices via Email
  const [includeJobPhotos, setIncludeJobPhotos] = useState<boolean>(true);

  // these are used when sending Estimate or Parts Info via email
  const [emailBody, setEmailBody] = useState<string>(props.emailBody ?? "");
  const [emailSubject, setEmailSubject] = useState<string>(
    props.emailSubject ?? "",
  );

  useEffect(() => {
    if (props.emailBody) {
      setEmailBody(props.emailBody);
    }
    if (props.emailSubject) {
      setEmailSubject(props.emailSubject);
    }
  }, [props.emailBody, props.emailSubject]);

  function closeAndReset() {
    setSelectedEmailList([]);
    setErrorString(null);
    props.closeDialog();
  }

  const dialogHeader = (
    <div className="flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <HeadingOne fontColor="text-white" fontSize="text-xl">
        {props.title}
      </HeadingOne>
      <button type="button" onClick={closeAndReset}>
        <XMarkIcon aria-label="close email dialog" className="h-6 text-white" />
      </button>
    </div>
  );

  const displayError = errorString && (
    <span className="mx-auto mt-4">
      <ErrorMessage
        message={errorString}
        clearMessage={() => setErrorString(null)}
      />
    </span>
  );

  const sendEmailButton = (
    <BaseButtonPrimary
      type="button"
      busyText={strings.buttons.BUSY_SENDING}
      isBusy={isBusy}
      onClick={async () => {
        if (selectedEmailList.length === 0) {
          setErrorString(strings.ERROR_EMAIL);
          return;
        }

        setIsBusy(true);
        if (props.sendEstimateOrPartsInfoViaEmail) {
          if (emailSubject.trim().length === 0) {
            setErrorString(strings.ERROR_SUBJECT);
            setIsBusy(false);
            return;
          }
          props.sendEstimateOrPartsInfoViaEmail(
            selectedEmailList,
            emailBody,
            emailSubject,
          );
        } else if (props.sendInvoiceEmail) {
          props.sendInvoiceEmail(selectedEmailList, includeJobPhotos);
        } else if (props.sendEmailReceipt) {
          props.sendEmailReceipt(selectedEmailList);
        }

        setIsBusy(false);
        setSelectedEmailList([]);
        closeAndReset();
      }}
    >
      <SendIcon className="h-full" />
    </BaseButtonPrimary>
  );

  const includeJobPhotosSwitch = (
    <StyledSwitchGroup
      readableName={strings.INCLUDE_JOB_PHOTOS}
      onBlur={() => {}}
      onChange={() => setIncludeJobPhotos(!includeJobPhotos)}
      checked={includeJobPhotos}
      id="includeJobPhotos"
      name={"includeJobPhotos"}
    />
  );

  return (
    <BaseModal
      closeModal={closeAndReset}
      open={props.isDialogOpen}
      title={dialogHeader}
      parentDivStyles="inline-block transform max-w-screen-sm rounded-lg bg-white text-left align-middle shadow-xl transition-all overflow-visible"
    >
      <div className="relative flex flex-col justify-center space-y-4 p-8 text-lg sm:gap-8 sm:space-y-0">
        {props.timestampSentToCustomer ? (
          <div className="col-span-2 justify-self-center">
            <span className="text-gray-400">Last sent on: </span>
            {convertToReadableTimestamp(props.timestampSentToCustomer)}
          </div>
        ) : null}
        <MultipleEmailSelector
          sendEmailButton={sendEmailButton}
          selectedEmailList={selectedEmailList}
          setSelectedEmailList={setSelectedEmailList}
          emailList={props.customerEmailList ? props.customerEmailList : []}
          setErrorString={setErrorString}
          includeJobPhotosSwitch={
            props.sendInvoiceEmail ? includeJobPhotosSwitch : null
          }
        />
        {props.sendEstimateOrPartsInfoViaEmail && (
          <Fragment>
            <BaseInputText
              text="Email Subject"
              admin={true}
              required={true}
              value={emailSubject ?? ""}
              onChange={(event) => setEmailSubject(event.target.value)}
              className="w-full"
            />
            <BaseInputTextArea
              text="Email Body"
              admin={true}
              required={true}
              value={emailBody ?? ""}
              onChange={(event) => setEmailBody(event.target.value)}
              className="w-full"
              rows={6}
            ></BaseInputTextArea>
          </Fragment>
        )}
        <BaseButtonSecondary
          type="button"
          onClick={closeAndReset}
          className="w-full"
        >
          CLOSE WITHOUT SENDING
        </BaseButtonSecondary>

        {displayError}
      </div>
    </BaseModal>
  );
}
