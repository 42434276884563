//Libs
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { createRef } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { CSSTransition, TransitionGroup } from "react-transition-group";

//Local
import { ToastType, useToastMessageStore } from "../store/toast-messages";
import { logger } from "../logging";

type Props = {
  toastList: ToastType[];
  dialog: boolean;
};

function getTypeToastStyle(type: ToastType["type"]): Record<string, any> {
  let typeToastStyle: Record<string, any> = {};
  switch (type) {
    case "success":
      typeToastStyle = {
        title: "Success",
        backgroundColor: "bg-green-100",
        textColor: "text-green-800",
        icon: (
          <CheckCircleIcon
            aria-label="success message"
            className="h-5 w-5"
            aria-hidden="true"
          />
        ),
      };
      return typeToastStyle;

    case "error":
      typeToastStyle = {
        title: "Error",
        backgroundColor: "bg-red-100",
        textColor: "text-red-900",
        icon: (
          <XCircleIcon
            aria-label="error message"
            className="h-5 w-5"
            aria-hidden="true"
          />
        ),
      };
      return typeToastStyle;

    case "info":
      typeToastStyle = {
        title: "Info",
        backgroundColor: "bg-blue-50",
        textColor: "text-blue-900",
        icon: (
          <InformationCircleIcon
            aria-label="info message"
            className="h-5 w-5"
            aria-hidden="true"
          />
        ),
      };
      return typeToastStyle;

    case "warning":
      typeToastStyle = {
        title: "Warning",
        backgroundColor: "bg-orange-50",
        textColor: "text-orange-700",
        icon: (
          <ExclamationCircleIcon
            aria-label="warning message"
            className="h-5 w-5"
            aria-hidden="true"
          />
        ),
      };
      return typeToastStyle;

    default: {
      const _exhaustiveCheck: never = type;
      return _exhaustiveCheck;
    }
  }
}

export default function Toast({ toastList, dialog }: Props) {
  const deleteToastMessage = useToastMessageStore(
    (state) => state.deleteToastMessage,
  );

  const position =
    "bottom-3 right-3 transition ease-in-out transform delay-700";

  return (
    <div
      className={`fixed box-border text-sm ${position} ${dialog ? "z-[100]" : "z-50"}`}
    >
      <TransitionGroup>
        {toastList.map((toast) => {
          const nodeRef = createRef<HTMLDivElement>();
          return (
            <CSSTransition
              key={toast.id}
              nodeRef={nodeRef}
              timeout={{
                enter: 300,
                exit: 300,
              }}
              // prefix for transition classes
              classNames="fade"
            >
              <div
                ref={nodeRef}
                key={toast.id}
                className={`${position} ${
                  getTypeToastStyle(toast.type).backgroundColor
                } ${
                  getTypeToastStyle(toast.type).textColor
                } relative mb-4 flex w-96 justify-between rounded p-2 pb-4 shadow-md transition-all duration-700 ease-in-out`}
              >
                <div className="flex">
                  <div className="float-left mr-2 flex-shrink-0">
                    {getTypeToastStyle(toast.type).icon}
                  </div>
                  <div className={`${getTypeToastStyle(toast.type).textColor}`}>
                    <p className="mb-2 w-full text-left text-base font-bold">
                      {getTypeToastStyle(toast.type).title}
                    </p>
                    <p className="whitespace-normal">{toast.message}</p>
                  </div>
                </div>

                <div className="min-w-fit flex-col">
                  <div className="flex justify-end space-x-2">
                    {toast.type === "success" ? (
                      <CountdownCircleTimer
                        isPlaying
                        duration={5}
                        colors="#166534"
                        size={20}
                        strokeWidth={3}
                      />
                    ) : null}
                    <div className="flex-shrink-0">
                      <button
                        data-testid="close"
                        type="button"
                        onClick={() => deleteToastMessage(toast.id)}
                      >
                        <XMarkIcon
                          className="h-5 w-5"
                          aria-label="close message"
                        />
                      </button>
                    </div>
                  </div>
                  {toast.action && toast.actionText ? (
                    <button
                      onClick={() => {
                        if (toast.action) {
                          toast.action();
                        } else {
                          logger.warn("Expected a toast action to be defined");
                        }
                        deleteToastMessage(toast.id);
                      }}
                      className={`border ${
                        getTypeToastStyle(toast.type).borderColor
                      } py-0,5 mt-2 rounded px-2`}
                    >
                      {toast.actionText}
                    </button>
                  ) : null}
                </div>
              </div>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div>
  );
}
