//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";

//Local
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import HeadingOne from "../HeadingOne";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  // Components
  selectCustomerButton: React.ReactNode;
  displayCustomerData: React.ReactNode;
  selectCustomerLocationGroup: React.ReactNode;
  scheduling: React.ReactNode;
  actionButtons: React.ReactNode;
  selectTasks: React.ReactNode;
  additionalItemsSection: React.ReactNode;
  infoBox: React.ReactNode;
  siteLocationsDropdown: React.ReactNode;
  errorMessage: React.ReactNode;
  children: {
    SelectCustomerDialog: React.ReactNode;
  };
}

export default function DuplicateJobDialog({
  isOpen,
  onClose,
  ...props
}: Props) {
  const duplicateJobHeader = (
    <div className="flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <HeadingOne
        fontColor="text-white"
        fontSize="text-xl"
        className="capitalize"
      >
        {strings.DUPLICATE_JOB}
      </HeadingOne>
      <button onClick={() => onClose()}>
        <XMarkIcon aria-label="close company form" className="h-6 text-white" />
      </button>
    </div>
  );

  return (
    <BaseModal
      open={isOpen}
      closeModal={onClose}
      allowOverflowY={true}
      title={duplicateJobHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-lg rounded-lg bg-white text-left shadow-xl transition-all"
    >
      <div className="flex flex-col space-y-8 p-8 text-lg">
        <div className="flex flex-col gap-4 sm:grid sm:grid-cols-2">
          {/* SITE LOCATION */}
          {props.siteLocationsDropdown}
          {/* CUSTOMER */}
          <section>
            <div className="relative grid grid-cols-1 space-y-4 rounded border border-black p-6">
              <label className="absolute -top-3 left-3 bg-white px-2 xs:col-span-2 sm:col-span-1 md:col-span-2">
                {strings.CUSTOMER}
                <span className="text-lg font-medium text-redFail">*</span>
              </label>
              {props.selectCustomerButton}
              {props.displayCustomerData}
            </div>
          </section>
          {/* CUSTOMER LOCATIONS */}
          <section className="">
            <div className="relative grid h-full grid-cols-1 space-y-4 rounded border border-black p-6">
              <label className="absolute -top-3 left-3 bg-white px-2 xs:col-span-2 sm:col-span-1 md:col-span-2">
                {strings.CUSTOMER_LOCATIONS}
                <span className="text-lg font-medium text-redFail">*</span>
              </label>
              {props.selectCustomerLocationGroup}
            </div>
          </section>

          {/* DUPLICATE TASKS */}
          <div className="relative grid grid-cols-1 rounded border border-black p-6">
            <label className="absolute -top-3 left-3 bg-white px-2 xs:col-span-2 sm:col-span-1 md:col-span-2">
              {strings.TASKS_TO_DUPLICATE}
            </label>
            {props.selectTasks}
          </div>

          {/* DUPLICATE ADDITIONAL ITEMS */}
          <div className="relative grid grid-cols-1 rounded border border-black p-6">
            <label className="absolute -top-3 left-3 bg-white px-2 xs:col-span-2 sm:col-span-1 md:col-span-2">
              {strings.ADDITIONAL_ITEMS}
            </label>
            {props.additionalItemsSection}
          </div>

          {/* SCHEDULING */}
          <section className="col-span-2">
            <div className="relative grid grid-cols-1 space-y-4 rounded border border-black p-6">
              <label className="absolute -top-3 left-3 bg-white px-2 xs:col-span-2 sm:col-span-1 md:col-span-2">
                {strings.SCHEDULING}
                <span className="text-lg font-medium text-redFail">*</span>
              </label>
              {props.scheduling}
            </div>
          </section>

          {/* INFO BOX */}
          <section className="col-span-2">{props.infoBox}</section>
        </div>

        {props.errorMessage}

        {/* ACTION BUTTONS */}
        {props.actionButtons}
      </div>
      {props.children.SelectCustomerDialog}
    </BaseModal>
  );
}
