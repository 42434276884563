//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { Fragment, useMemo } from "react";
import WarningIcon from "@mui/icons-material/Warning";

//Local
import BaseModal from "../BaseModal";
import * as strings from "../../strings";
import { ExistingCustomer } from "../../models/customer";
import BaseButtonSecondary from "../BaseButtonSecondary";
import { CustomerDetails } from "../estimates/CustomerDetails";
import { BiRightArrowAlt } from "react-icons/bi";
import HeadingOne from "../HeadingOne";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  selectedCustomer: ExistingCustomer | null;
  mergeToCustomer: ExistingCustomer;
  setSelectCustomerDialogOpen: () => void;
  actionButtons: React.ReactNode;
  children: {
    SelectCustomerDialog: React.ReactNode;
  };
}

export default function MergeCustomerDialog({
  isOpen,
  onClose,
  selectedCustomer,
  mergeToCustomer,
  actionButtons,
  ...props
}: Props) {
  const mergeCustomerHeader = (
    <div className="flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <HeadingOne fontColor="text-white" fontSize="text-xl">
        {strings.buttons.MERGE_CUSTOMER}
      </HeadingOne>
      <button onClick={() => onClose()}>
        <XMarkIcon aria-label="close company form" className="h-6 text-white" />
      </button>
    </div>
  );

  // Check if either customer has an open balance
  const hasOpenBalance = useMemo(() => {
    if (!selectedCustomer) return false;

    return (
      (selectedCustomer.balance !== undefined &&
        selectedCustomer.balance !== 0) ||
      (mergeToCustomer.balance !== undefined && mergeToCustomer.balance !== 0)
    );
  }, [selectedCustomer, mergeToCustomer]);

  // Create a warning message if there's an open balance
  const openBalanceWarning = hasOpenBalance && (
    <div className="flex items-center rounded-md bg-red-100 p-4 text-red-700">
      <WarningIcon className="mr-2 h-6 w-6" />
      <span>{strings.ERR_MERGE_CUSTOMER_WITH_BALANCE}</span>
    </div>
  );

  return (
    <Fragment>
      <BaseModal
        open={isOpen}
        closeModal={() => {}}
        allowOverflowY={true}
        title={mergeCustomerHeader}
        parentDivStyles="inline-block transform overflow-hidden  max-w-screen-md rounded-lg bg-white text-left shadow-xl transition-all"
      >
        <div className="flex flex-col space-y-8 p-8 text-lg">
          <div className="text-lg font-light text-gray-700">
            Select a customer to merge into this one.
          </div>
          <BaseButtonSecondary
            type="button"
            onClick={props.setSelectCustomerDialogOpen}
            className="w-full text-primary xs:w-auto"
          >
            <CheckCircle fontSize="small" className="mr-2" />
            {strings.SELECT_CUSTOMER}
          </BaseButtonSecondary>
          {selectedCustomer && (
            <Fragment>
              <div className="text-lg font-light text-gray-700">
                The following customer will be merged into{" "}
                <span className="text-xl font-extrabold text-primary">
                  {mergeToCustomer.name}
                </span>{" "}
                including all locations, tasks, estimates, invoices, and
                payments. Customer notes and location notes will not be merged.
                If you need to keep any customer or location notes, please copy
                those manually before merging.
              </div>
              <div className="flex flex-row items-center justify-between">
                <CustomerDetails
                  customer={selectedCustomer}
                  openEditCustomerDialog={null}
                  openCallDialog={null}
                />
                <BiRightArrowAlt className="h-9 w-9"></BiRightArrowAlt>
                <CustomerDetails
                  customer={mergeToCustomer}
                  openEditCustomerDialog={null}
                  openCallDialog={null}
                />
              </div>
              {openBalanceWarning}
            </Fragment>
          )}
          {actionButtons}
        </div>
        {props.children.SelectCustomerDialog}
      </BaseModal>
    </Fragment>
  );
}
