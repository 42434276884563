import { User } from "firebase/auth";
import { MessageType } from "../../models/chat_message";
import { ExistingChatMessage } from "../../models/chat_message";
import { ExistingSiteKeyUserDoc } from "../../models/site-key-users";

interface ChatBubbleProps {
  message: ExistingChatMessage;
  isGrouped: boolean;
  isFirstInGroup: boolean;
  isLastInGroup: boolean;
  showUserInfo: boolean;
  firebaseUser: User | null;
  userDisplayNamesMap: Record<string, string>;
  siteKeyUsersList: ExistingSiteKeyUserDoc[];
}

export default function ChatBubble({
  message,
  isGrouped,
  isFirstInGroup,
  isLastInGroup,
  showUserInfo,
  firebaseUser,
  userDisplayNamesMap,
  siteKeyUsersList,
}: ChatBubbleProps) {
  const isFromMe = message.createdBy === firebaseUser?.uid;

  // Determine if this is an SMS message
  const isSmsMessage = message.isFromCustomer === true;
  const isCustomerMessage = isSmsMessage && message.isFromCustomer === true;

  // Choose bubble styling based on message type
  let bubbleClassName = "rounded-lg p-3 ";
  if (isFromMe) {
    bubbleClassName += "bg-blue-500 text-white ";
  } else if (isCustomerMessage) {
    // Special styling for SMS messages from customers
    bubbleClassName += "bg-green-500 text-white border border-green-500 ";
  } else {
    bubbleClassName += "bg-gray-200 text-gray-800 ";
  }

  let textColor = "text-gray-500";
  if (isCustomerMessage) {
    textColor = "text-green-800";
  } else if (isFromMe) {
    textColor = "text-blue-100";
  } else {
    textColor = "text-gray-500";
  }

  const isSystemMessage = message.type === MessageType.SYSTEM;
  const senderName = userDisplayNamesMap[message.createdBy] || "Unknown User";
  const senderPhoto = siteKeyUsersList.find(
    (u) => u.id === message.createdBy,
  )?.userPhoto_URL;

  // Format timestamp
  const timestamp = message.timestampCreated
    ? new Date(message.timestampCreated.seconds * 1000).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })
    : "";

  if (isSystemMessage) {
    return (
      <div className="my-1.5 flex justify-center">
        <div className="rounded-full bg-gray-100 px-3 py-0.5 text-xs text-gray-500">
          {message.text}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`mb-0.5 flex ${isFromMe ? "justify-end" : "justify-start"}`}
    >
      {!isFromMe && showUserInfo && isFirstInGroup && (
        <div className="mr-1.5 mt-1 h-6 w-6 flex-shrink-0">
          {senderPhoto ? (
            <img
              src={senderPhoto}
              alt={senderName}
              className="h-6 w-6 rounded-full"
            />
          ) : (
            <div className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-300 text-xs">
              {senderName.charAt(0)}
            </div>
          )}
        </div>
      )}
      {!isFromMe && !isFirstInGroup && showUserInfo && (
        <div className="mr-1.5 w-6"></div>
      )}
      <div
        className={`flex max-w-[65%] flex-col ${
          isFromMe ? "items-end" : "items-start"
        }`}
      >
        {!isFromMe && showUserInfo && isFirstInGroup && (
          <div className="mb-0.5 ml-1 text-xs font-medium text-gray-700">
            {senderName}
          </div>
        )}
        <div
          className={`relative rounded-lg px-2.5 py-1.5 ${bubbleClassName} ${
            isGrouped && !isFirstInGroup && !isLastInGroup
              ? isFromMe
                ? "rounded-tr-sm"
                : "rounded-tl-sm"
              : ""
          } ${
            isGrouped && !isLastInGroup
              ? isFromMe
                ? "rounded-br-sm"
                : "rounded-bl-sm"
              : ""
          }`}
        >
          {message.uri && (
            <div className="mb-1">
              <a href={message.uri} target="_blank" rel="noopener noreferrer">
                <img
                  src={message.uri}
                  alt="Shared image"
                  className="max-h-60 rounded"
                />
              </a>
            </div>
          )}
          <div className="whitespace-pre-wrap break-words text-sm">
            {message.text}
          </div>
          <div className={`mt-0.5 text-right text-xs ${textColor}`}>
            {timestamp}
          </div>
        </div>

        {/* Show SMS status for outgoing SMS messages */}
        {isSmsMessage && !isCustomerMessage && (
          <div className="mt-1 text-xs text-gray-500">
            {message.smsStatus ? (
              <span>SMS: {message.smsStatus}</span>
            ) : (
              <span>SMS: sending...</span>
            )}
          </div>
        )}

        {/* Show SMS indicator for customer messages */}
        {isCustomerMessage && (
          <div className="mt-1 text-xs font-medium text-green-800">
            <span>via SMS</span>
          </div>
        )}
      </div>
    </div>
  );
}
