import React from "react";
import SmsDashboardPage from "./SmsDashboardPage";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { useEffect, useState } from "react";
import SelectPhoneNumberDialog from "../../components/sms/SelectPhoneNumberDialog";
import { logger } from "../../logging";
import { DbWrite } from "../../database";

export default function SmsDashboardContainer() {
  const [siteKeyDoc, fetch] = useSiteKeyDocStore((state) => [
    state.siteKeyDoc,
    state.fetch,
  ]);
  const [isSelectPhoneNumberDialogOpen, setIsSelectPhoneNumberDialogOpen] =
    useState(false);
  const [isSettingUpTwilio, setIsSettingUpTwilio] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (siteKeyDoc?.id) {
      fetch(siteKeyDoc.id);
    }
  }, [fetch, siteKeyDoc?.id]);

  const handleSetupTwilio = async () => {
    if (!siteKeyDoc) return;

    setIsSettingUpTwilio(true);
    setErrorMessage(null);
    try {
      // // Check if Twilio account is already set up, if not create one
      // const twilioAccount = await DbRead.twilio.getAccount(siteKeyDoc.id);
      // if (!twilioAccount) {
      //   await DbWrite.twilio.createSubAccount(siteKeyDoc.id);
      // }

      // // Enable SMS for the account
      // await DbWrite.siteKey.editCustomizations(siteKeyDoc.id, {
      //   customizations: {
      //     sms: {
      //       smsEnabled: true,
      //     },
      //   },
      // });

      // Open phone number selection dialog
      setIsSelectPhoneNumberDialogOpen(true);
    } catch (error) {
      logger.error(error);
      setErrorMessage("Failed to set up SMS integration");
    } finally {
      setIsSettingUpTwilio(false);
    }
  };

  const handlePhoneNumberSelected = async (
    phoneNumberId: string,
    phoneNumber: string,
    description: string,
  ) => {
    if (!siteKeyDoc) return;

    try {
      // Update SMS settings with selected phone number
      await DbWrite.siteKey.editCustomizations(siteKeyDoc.id, {
        customizations: {
          sms: {
            smsEnabled: true,
            phoneNumberId,
          },
        },
      });

      setIsSelectPhoneNumberDialogOpen(false);

      // Refresh the site key doc to get updated settings
      fetch(siteKeyDoc.id);
    } catch (error) {
      logger.error(error);
      setErrorMessage("Failed to save phone number selection");
    }
  };

  const handleCloseDialog = () => {
    setIsSelectPhoneNumberDialogOpen(false);
  };

  return (
    <>
      <SmsDashboardPage
        onSetupSmsIntegration={handleSetupTwilio}
        errorMessage={errorMessage}
      />
      {siteKeyDoc && (
        <SelectPhoneNumberDialog
          open={isSelectPhoneNumberDialogOpen}
          onClose={handleCloseDialog}
          onSelect={handlePhoneNumberSelected}
          siteKey={siteKeyDoc.id}
        />
      )}
    </>
  );
}
