//Libs
import { DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

//Local
import { guardIsPlainObject } from "../utils";
import { NotFoundError } from "../error-classes";
import { zFallback } from "../utils/zod-fallback";
import { CraftTypes } from "./craft-types";
import { OTaskTypes, TaskTypesValues } from "./task-types";

export interface Vehicle {
  title: string;
  description: string | null;
  customID: string | null;
  locationID: string;
  latitude: number;
  longitude: number;
  qrCode: string | null;
  thumbnailURL: string | null;
  defaultSiteKeyUserID: string[];
  timestampCreated: Timestamp;
  timestampLastModified: Timestamp;
  createdBy: string;
  lastModifiedBy: string;
  deleted: boolean;
  assetID?: string;
  craftTypes: CraftTypes[];
  taskTypes: TaskTypesValues[];
  maxTasksPerDay?: number;
  groupName?: string;
  startTime?: string;
  endTime?: string;
  omitFromOptimization?: boolean;
}

export interface ExistingVehicle extends Vehicle {
  id: string;
  refPath: string;
}

/***********************/
// FOR THE API
/***********************/
type withoutTimestamps = Omit<
  Vehicle,
  "timestampCreated" | "timestampLastModified"
>;

// Timestamps are added on the backend.
export interface Vehicle_CreateAPI extends withoutTimestamps {
  siteKey: string;
}

// timestampLastModified is added on the backend. (timestampCreated won't change)
export type Vehicle_UpdateAPI = Omit<
  Partial<ExistingVehicle>,
  "timestampCreated" | "timestampLastModified"
>;

// #region SECTION: Functions
export const VehicleManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingVehicle object.
   */
  createFromFirestoreSnapshot: createVehicleFromFirestoreSnapshot,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateVehicle,
  /** Validate a Vehicle doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateVehicleWithFallbacks,
  /** Validate a new price book item. For the create endpoint. */
  parseCreate: validateVehicle_Create,
  /** Validate an existing price book item. For the update endpoint. */
  parseUpdate: validateVehicle_Update,
} as const;

/** Drop `id` and `refPath` before saving to the database. Drop undefined values */
function createVehicleFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingVehicle {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateVehicleWithFallbacks(snapshotData),
  };
}

/* Zod validation schemas */
function validateVehicle(value: unknown): Vehicle {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = vehicleSchema.parse(value);
  return result;
}

function validateVehicleWithFallbacks(value: unknown): Vehicle {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = vehicleSchemaWithFallbacks.parse(value);
  return result;
}

function validateVehicle_Create(value: unknown): Vehicle_CreateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return vehicleSchema_CreateAPI.parse(value);
}

function validateVehicle_Update(value: unknown): Vehicle_UpdateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return vehicleSchema_UpdateAPI.parse(value);
}
// #endregion

// #region SECTION: Schemas
// Used when writing to the DB or reading from the user.
const vehicleSchema = z.object({
  title: z.string().min(1).max(200),
  description: z.string().max(500).nullable(),
  customID: z.string().min(1).max(200).nullable(),
  locationID: z.string().min(1).max(200),
  latitude: z.number(),
  longitude: z.number(),
  qrCode: z.string().min(1).max(7089).nullable(),
  thumbnailURL: z.string().min(1).max(1000).nullable(),
  defaultSiteKeyUserID: z.array(z.string().max(200)),
  timestampCreated: z.instanceof(Timestamp),
  timestampLastModified: z.instanceof(Timestamp),
  createdBy: z.string().min(1).max(200),
  lastModifiedBy: z.string().min(1).max(200),
  deleted: z.boolean(),
  assetID: z.string().min(1).max(200).optional(),
  craftTypes: z.array(z.nativeEnum(CraftTypes)),
  taskTypes: z.array(z.nativeEnum(OTaskTypes)),
  maxTasksPerDay: z.number().optional(),
  groupName: z.string().optional(),
  startTime: z
    .string()
    .regex(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)
    .optional(),
  endTime: z
    .string()
    .regex(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)
    .optional(),
  omitFromOptimization: z.boolean().optional(),
});

// Used when validating data coming from the database.
const vehicleSchemaWithFallbacks = z.object({
  title: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "vehicleSchemaWithFallbacks: 'title'",
  ),
  description: zFallback(
    z.string().min(1).max(500).nullable(),
    null,
    "vehicleSchemaWithFallbacks: 'description'",
  ),
  customID: zFallback(
    z.string().min(1).max(200).nullable(),
    null,
    "vehicleSchemaWithFallbacks: 'customID'",
  ),
  locationID: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "vehicleSchemaWithFallbacks: 'locationID'",
  ),
  latitude: z.number(),
  longitude: z.number(),
  qrCode: zFallback(
    z.string().min(1).max(7089).nullable(),
    null,
    "vehicleSchemaWithFallbacks: 'qrCode'",
  ),
  thumbnailURL: zFallback(
    z.string().min(1).max(1000).nullable(),
    null,
    "vehicleSchemaWithFallbacks: 'thumbnailURL'",
  ),
  defaultSiteKeyUserID: zFallback(
    z.array(z.string().min(1).max(200)),
    [],
    "vehicleSchemaWithFallbacks: 'defaultSiteKeyUserID'",
  ),
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "vehicleSchemaWithFallbacks: 'timestampCreated'",
  ),
  timestampLastModified: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "vehicleSchemaWithFallbacks: 'timestampLastModified'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  createdBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "vehicleSchemaWithFallbacks: 'createdBy'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  lastModifiedBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "vehicleSchemaWithFallbacks: 'lastModifiedBy'",
  ),
  deleted: zFallback(
    z.boolean(),
    false,
    "vehicleSchemaWithFallbacks: 'deleted'",
  ),
  assetID: zFallback(
    z.string().min(1).max(200).optional(),
    undefined,
    "vehicleSchemaWithFallbacks: 'assetID'",
  ),
  craftTypes: zFallback(
    z.array(z.nativeEnum(CraftTypes)),
    [],
    "vehicleSchemaWithFallbacks: 'craftTypes'",
  ),
  taskTypes: zFallback(
    z.array(z.nativeEnum(OTaskTypes)),
    [],
    "vehicleSchemaWithFallbacks: 'taskTypes'",
  ),
  maxTasksPerDay: zFallback(
    z.number().optional(),
    undefined,
    "vehicleSchemaWithFallbacks: 'maxTasksPerDay'",
  ),
  groupName: zFallback(
    z.string().optional(),
    undefined,
    "vehicleSchemaWithFallbacks: 'groupName'",
  ),
  startTime: zFallback(
    z
      .string()
      .regex(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)
      .optional(),
    undefined,
    "vehicleSchemaWithFallbacks: 'startTime'",
  ),
  endTime: zFallback(
    z
      .string()
      .regex(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)
      .optional(),
    undefined,
    "vehicleSchemaWithFallbacks: 'endTime'",
  ),
  omitFromOptimization: zFallback(
    z.boolean().optional(),
    undefined,
    "vehicleSchemaWithFallbacks: 'omitFromOptimization'",
  ),
});

/***********************/
// FOR THE API
/***********************/
const withoutTimestampsSchema = vehicleSchema.omit({
  timestampCreated: true,
  timestampLastModified: true,
});

// Used for interacting with the Create endpoint.
const vehicleSchema_CreateAPI = withoutTimestampsSchema.extend({
  siteKey: z.string().min(1).max(400),
});

// Used for interacting with the Update endpoint
const vehicleSchema_UpdateAPI = withoutTimestampsSchema
  .extend({
    id: z.string().min(1).max(200),
    refPath: z.string().min(1).max(400),
  })
  .partial();
// #endregion
