import {
  Control,
  Controller,
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormSetValue,
} from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { Timestamp } from "firebase/firestore";
import QuestionMarkOutlined from "@mui/icons-material/QuestionMarkOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PostAddIcon from "@mui/icons-material/PostAdd";
import CheckIcon from "@heroicons/react/24/solid/CheckIcon";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";

// Local
import BaseInputSelect from "../BaseInputSelect";
import BaseInputTextArea from "../BaseInputTextArea";
import BaseInputText from "../BaseInputText";
import BaseInputDate from "../BaseInputDate";
import StyledMessage from "../StyledMessage";
import StyledSwitchGroup from "../StyledSwitchGroup";
import { StyledTooltip } from "../StyledTooltip";
import {
  membershipStatus,
  getReadableMembershipStatus,
  InvoiceOverrideData,
} from "../../models/membership";
import * as strings from "../../strings";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import { MembershipFormState } from "./formTypes";
import {
  ExistingMembershipTemplate,
  getReadableInvoiceMethod,
  InvoiceFrequency,
  InvoiceMethod,
} from "../../models/membership-template";
import currencyFormatter from "../../currency";
import {
  convertDuration,
  getInvoiceFrequencyInMonths,
} from "../../utils/templateAndMembershipUtils";
import { logger } from "../../logging";
import { ExistingPriceBookItem } from "../../models/price-book-item";
import { DbRead } from "../../database";
import { convertToReadableTimestampDate } from "../../assets/js/convertToReadableTimestamp";
import {
  convertFSTimestampToLuxonDT,
  convertLuxonDTToFSTimestamp,
} from "../../utils";
import { PencilIconWithRef } from "../PencilEditButton";
import { TrashButton } from "../TrashButton";
import { MembershipInvoiceEditor } from "./MembershipInvoiceEditor";
import { ExistingCustomer } from "../../models/customer";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import StiltLink from "../StiltLink";
import { MEMBERSHIP_TEMPLATES } from "../../urls";

interface Props {
  // RHF
  rhfHandleSubmit: UseFormHandleSubmit<MembershipFormState>;
  isSubmitting: boolean;
  control: Control<MembershipFormState>;
  errors: FieldErrors<MembershipFormState>;
  setValue: UseFormSetValue<MembershipFormState>;

  // OURS
  formType: "create" | "edit";
  siteKey: string;
  watchedFields: {
    autoGenerateTasks: boolean;
    autoSendReceipt: boolean;
    autoEmailInvoice: boolean;
    autoPayInvoice: boolean;
    membershipStartDate: Timestamp | null;
    membershipEndDate: Timestamp | null;
    nextInvoiceDate: Timestamp | null;
    overrideEmail: string | undefined;
    overrideInvoice: InvoiceOverrideData | undefined;
  };
  onSubmit: SubmitHandler<MembershipFormState>;
  closeAndReset: () => void;
  template: ExistingMembershipTemplate | null;
  customer: ExistingCustomer | null;
  locationID: string | null;
  customerTaxRate: number;
  currency: string;
  children: {
    /** in the create component, this is how the customer is selected and displayed.
     * in the edit component, it just displays the customer details. */
    customerSection: React.ReactNode;
    customerLocationSection: React.ReactNode;
    /** only present if there are multiple site locations */
    selectSiteLocationElement: React.ReactNode;
    /** a dropdown, only present on create form */
    selectTemplateElement: React.ReactNode;
    /** only present if assets are enabled and available for the customer */
    assetSection: React.ReactNode;
  };
}

export function AddEditMembershipForm(props: Props) {
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [showInvoiceSection, setShowInvoiceSection] = useState(false);

  const siteKeyData = useSiteKeyDocStore((state) => state.siteKeyDoc);

  // we aren't fetching the PBI in any of the parent components. so we'll do it here
  const [pbi, setPBI] = useState<ExistingPriceBookItem | null>(null);

  useEffect(() => {
    async function getPBI() {
      if (props.template && props.template.priceBookItemID) {
        const pbi = await DbRead.priceBookItems.get(
          props.siteKey,
          props.template.priceBookItemID,
        );
        setPBI(pbi);
      }
    }
    getPBI();
  }, [props.siteKey, props.template]);

  const atLeastOneAutoOption =
    props.template &&
    (props.template.invoiceMethod === "automatic" ||
      props.template.taskGeneration.length !== 0);

  /** automatic invoicing is enabled, and there's an end date, or there _will_ be an end date at some point. */
  const membershipWillAutoRenew =
    props.template?.invoiceMethod === "automatic" &&
    (props.watchedFields.membershipEndDate instanceof Timestamp ||
      props.template.defaultDuration !== -1);

  /** customer's "default" email address. to be used for invoicing if it's not overridden */
  // non-empty string or null
  const defaultEmail =
    props.customer &&
    props.customer.email &&
    props.customer.email.length > 0 &&
    typeof props.customer.email[0] === "string" &&
    props.customer.email[0].length > 0
      ? props.customer.email[0]
      : null;

  function handleSetInvoice(data: InvoiceOverrideData) {
    props.setValue("overrideInvoice", data);
  }

  function handleRemoveInvoice() {
    props.setValue("overrideInvoice", undefined);
    setShowInvoiceSection(false);
  }

  function handleSubmit(data: MembershipFormState) {
    if (typeof data.notes === "string" && !data.notes.trim().length) {
      data.notes = null;
    }
    if (
      typeof data.overrideEmail === "string" &&
      !data.overrideEmail.trim().length
    ) {
      data.overrideEmail = undefined;
    }
    props.onSubmit(data);
  }

  return (
    <form
      onSubmit={props.rhfHandleSubmit(handleSubmit)}
      autoComplete="off"
      className="px-4 pb-4 md:px-8 md:pb-6"
    >
      <div className="mb-8 flex flex-col items-start justify-between gap-4 md:flex-row">
        {props.children.customerSection}

        {/* TEMPLATE DETAILS */}
        {props.template && (
          <div className="relative mx-auto mt-2 w-full rounded bg-gray-100 p-3">
            <h4 className="absolute inset-x-0 -top-3 mx-auto max-w-fit truncate rounded border-2 border-gray-200 bg-white px-2 text-base font-semibold uppercase text-primary">
              <StiltLink
                to={`${MEMBERSHIP_TEMPLATES}/${props.template.id}`}
                className="text-blue-600 underline"
              >
                {props.template.title}
              </StiltLink>
            </h4>
            <ul className="mx-auto mb-1.5 mt-3 flex max-w-fit flex-col items-center justify-around gap-x-4 gap-y-1 rounded border border-gray-200 bg-white px-1.5 py-1 text-sm font-semibold uppercase text-gray-500 xs:flex-row xs:flex-wrap">
              <li>
                invoice method:{" "}
                {getReadableInvoiceMethod(props.template.invoiceMethod)}
              </li>
              <li>invoice frequency: {props.template.invoiceFrequency}</li>
              <li>
                default duration:{" "}
                {getReadableDefaultDuration(
                  props.template.defaultDuration ?? 12,
                )}
              </li>
            </ul>
            {/* ⬆️ template overview. immutable (@ membership level) */}
            <ul className="ml-4 list-circle text-sm">
              {/* TODO: display the duration if template.defaultDuration has been overridden */}

              {/* TODO: revised invoicing text if there's overrideData.invoice
              ... this isn't done ⬇️ */}
              {/* Check if there's an override invoice */}
              {props.watchedFields.overrideInvoice ? (
                <li className="font-semibold text-primary">
                  This membership has a custom invoice
                </li>
              ) : (
                <InvoicingDetails
                  template={{
                    invoiceMethod: props.template.invoiceMethod,
                    invoiceFrequency: props.template.invoiceFrequency,
                    defaultDuration: props.template.defaultDuration ?? 12,
                  }}
                  startDate={props.watchedFields.membershipStartDate}
                  endDate={props.watchedFields.membershipEndDate}
                  nextInvoiceDate={props.watchedFields.nextInvoiceDate}
                  formattedInvAmount={currencyFormatter(
                    pbi?.unitPrice ?? props.template.price,
                    props.currency,
                  )}
                />
              )}

              {/* based on selections for the "Automatic Options" toggle section */}
              {props.template.invoiceMethod === "automatic" && (
                <>
                  {props.watchedFields.autoPayInvoice && (
                    <>
                      <li>
                        The customer's primary card on file will be used to
                        automatically process membership payments.
                      </li>
                      <li className="ml-4">
                        Invoices will not be emailed to the customer.
                      </li>
                    </>
                  )}
                  {!props.watchedFields.autoPayInvoice && (
                    <>
                      <li>No payments will be automatically processed.</li>
                      <li className="ml-4">
                        {props.watchedFields.autoEmailInvoice
                          ? "Invoices will automatically be emailed to the customer."
                          : "Invoices will not be emailed to the customer. This will need to be done manually."}
                      </li>
                    </>
                  )}
                  <li className="ml-4">
                    {props.watchedFields.autoSendReceipt
                      ? "Receipts will automatically be emailed to the customer."
                      : "Receipts will not be emailed to the customer. This will need to be done manually."}
                  </li>
                </>
              )}

              {props.template.taskGeneration.length !== 0 && (
                <li>
                  {props.watchedFields.autoGenerateTasks
                    ? `${props.template.taskGeneration.length} tasks will be automatically generated and distributed evenly throughout the membership period.`
                    : `${props.template.taskGeneration.length} tasks will need to be generated manually for this membership.`}
                </li>
              )}
              {props.template.taskGeneration.length === 0 &&
                props.template.invoiceMethod === "withTask" && (
                  <li>
                    {props.template.title} does not have any template tasks to
                    generate during the membership term.
                  </li>
                )}
            </ul>
          </div>
        )}
      </div>

      <div className="space-y-8 md:grid md:grid-cols-2 md:gap-6 md:space-y-0">
        <div>
          {props.children.customerLocationSection}
          {/* since customerLocationID is editable, we'll look for errors here (as opposed to within both parent components). */}
          {props.errors.customerLocationID?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: props.errors.customerLocationID.message }}
              </StyledMessage>
            </div>
          )}
        </div>
        {props.children.selectTemplateElement}
        {props.children.selectSiteLocationElement}
        {/* ^ selectSiteLocationElement is editable in both, but displaying any error is handled by the parent components cus we're not using RHF for this element, so it would've required passing in a few additional props */}
        {/* TODO: move selectSiteLocationElement into this component.. probably */}
        {/* STATUS */}
        <div>
          <Controller
            name="status"
            control={props.control}
            render={({ field }) => (
              <BaseInputSelect
                id="status"
                inputName="status"
                text="Membership Status"
                admin
                required
                {...field}
                value={field.value ?? ""}
                onChange={(event) => {
                  field.onChange(
                    event.target.value === "" ? null : event.target.value,
                  );
                }}
              >
                <option value="" disabled>
                  Select a Status
                </option>
                {membershipStatus.map((status) => (
                  <option key={status} value={status}>
                    {getReadableMembershipStatus(status)}
                  </option>
                ))}
              </BaseInputSelect>
            )}
          />
          {props.errors.status?.message && (
            <div className="mt-2 text-sm">
              <StyledMessage type="error">
                {{ message: props.errors.status.message }}
              </StyledMessage>
            </div>
          )}
        </div>

        {/* NOTES FIELD AND AUTOMATIC OPTION TOGGLES */}
        <div className="col-span-2 col-start-1 flex flex-col gap-x-4 gap-y-8 sm:flex-row sm:items-start lg:gap-x-7">
          <Controller
            name="notes"
            control={props.control}
            render={({ field }) => (
              <div className="grow">
                <BaseInputTextArea
                  text="Notes"
                  rows={4}
                  inputName="notes"
                  admin={true}
                  required={false}
                  {...field}
                  value={field.value === null ? "" : field.value}
                />
                {props.errors.notes?.message && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: props.errors.notes.message }}
                    </StyledMessage>
                  </div>
                )}
              </div>
            )}
          />

          {/* TOGGLES - AUTOMATIC OPTIONS */}
          {props.template && atLeastOneAutoOption && (
            <div className="relative mx-auto max-w-fit rounded border border-gray-300 bg-gray-50 p-3 lg:max-w-[25rem] lg:grow">
              <h4 className="absolute -top-3.5 left-1/2 -translate-x-1/2 text-nowrap rounded bg-gradient-to-b from-white to-gray-50 px-2 text-base text-primary">
                Automatic Options
              </h4>
              <div className="mt-2 flex flex-wrap items-end justify-evenly gap-2 text-sm sm:flex-col sm:justify-start lg:flex-row lg:justify-around">
                {props.template.taskGeneration.length !== 0 && (
                  <Controller
                    name="automaticallyGenerateTasks"
                    control={props.control}
                    render={({ field }) => (
                      <div className="flex items-center gap-1">
                        <div className="rounded-md border border-gray-300 bg-white px-2 py-1 transition-colors hover:border-gray-500">
                          <StyledSwitchGroup
                            size="small"
                            readableName="Generate Tasks"
                            onBlur={field.onBlur}
                            onChange={field.onChange}
                            ref={field.ref}
                            checked={field.value ?? false}
                            id="automaticallyGenerateTasks"
                            name={field.name}
                          />
                        </div>
                        <StyledTooltip
                          title={
                            props.watchedFields.autoGenerateTasks
                              ? "Toggle off to disable automatic task generation."
                              : "Toggle on to enable automatic task generation."
                          }
                        >
                          <QuestionMarkOutlined
                            style={{ width: "1.2rem", height: "1.2rem" }}
                            className="rounded-full border border-gray-500 p-0.5 text-gray-500 transition-colors hover:border-gray-700 hover:text-gray-700"
                          />
                        </StyledTooltip>
                      </div>
                    )}
                  />
                )}

                {props.template.invoiceMethod === "automatic" && (
                  <>
                    <Controller
                      name="automaticallySendReceipt"
                      control={props.control}
                      render={({ field }) => (
                        <div className="flex items-center gap-1">
                          <div className="rounded-md border border-gray-300 bg-white px-2 py-1 transition-colors hover:border-gray-500">
                            <StyledSwitchGroup
                              size="small"
                              readableName="Email Receipts"
                              onBlur={field.onBlur}
                              onChange={field.onChange}
                              ref={field.ref}
                              checked={field.value ?? false}
                              id="automaticallySendReceipt"
                              name={field.name}
                            />
                          </div>
                          <StyledTooltip
                            title={
                              props.watchedFields.autoSendReceipt
                                ? "Toggle off to prevent automatically emailing receipts to the customer."
                                : "Toggle on to automatically email receipts to the customer."
                            }
                          >
                            <QuestionMarkOutlined
                              style={{ width: "1.2rem", height: "1.2rem" }}
                              className="rounded-full border border-gray-500 p-0.5 text-gray-500 transition-colors hover:border-gray-700 hover:text-gray-700"
                            />
                          </StyledTooltip>
                        </div>
                      )}
                    />

                    <Controller
                      name="automaticallyPayInvoice"
                      control={props.control}
                      render={({ field }) => (
                        <div className="flex items-center gap-1">
                          <div className="rounded-md border border-gray-300 bg-white px-2 py-1 transition-colors hover:border-gray-500">
                            <StyledSwitchGroup
                              size="small"
                              readableName="Charge Card"
                              onBlur={field.onBlur}
                              onChange={(ev) => {
                                field.onChange(ev); // ev is a boolean
                                if (ev) {
                                  props.setValue(
                                    "automaticallySendInvoice",
                                    false,
                                  );
                                }
                              }}
                              ref={field.ref}
                              checked={field.value ?? false}
                              id="automaticallyPayInvoice"
                              name={field.name}
                            />
                          </div>
                          <StyledTooltip
                            title={
                              props.watchedFields.autoPayInvoice
                                ? "Toggle off to prevent charging the customer's card when an invoice is generated for the membership."
                                : "Toggle on to automatically charge the customer's card when an invoice is generated for the membership."
                            }
                          >
                            <QuestionMarkOutlined
                              style={{ width: "1.2rem", height: "1.2rem" }}
                              className="rounded-full border border-gray-500 p-0.5 text-gray-500 transition-colors hover:border-gray-700 hover:text-gray-700"
                            />
                          </StyledTooltip>
                        </div>
                      )}
                    />

                    <Controller
                      name="automaticallySendInvoice"
                      control={props.control}
                      render={({ field }) => (
                        <div className="flex items-center gap-1">
                          <div
                            className={`rounded-md border bg-white px-2 py-1 transition-colors ${
                              props.watchedFields.autoPayInvoice
                                ? ""
                                : "border-gray-300 hover:border-gray-500"
                            }`}
                          >
                            <StyledSwitchGroup
                              size="small"
                              disabled={props.watchedFields.autoPayInvoice}
                              readableName="Email Invoice"
                              onBlur={field.onBlur}
                              onChange={field.onChange}
                              ref={field.ref}
                              checked={field.value ?? false}
                              id="automaticallySendInvoice"
                              name={field.name}
                            />
                          </div>
                          <StyledTooltip
                            title={getEmailInvTooltip(
                              props.watchedFields.autoPayInvoice,
                              props.watchedFields.autoEmailInvoice,
                            )}
                          >
                            <QuestionMarkOutlined
                              style={{ width: "1.2rem", height: "1.2rem" }}
                              className="rounded-full border border-gray-500 p-0.5 text-gray-500 transition-colors hover:border-gray-700 hover:text-gray-700"
                            />
                          </StyledTooltip>
                        </div>
                      )}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </div>

        {/* INVOICE CUSTOMIZATIONS */}
        {/* TODO: hide this section if user is not site admin (but first check if that's been done somewhere higher up) */}
        {props.template && props.template.invoiceMethod === "automatic" && (
          <div className="relative col-span-2 col-start-1 mx-auto max-w-fit rounded border border-gray-300 bg-gray-50 p-3">
            <h4 className="absolute -top-3.5 left-1/2 -translate-x-1/2 text-nowrap rounded bg-gradient-to-b from-white to-gray-50 px-2 text-base text-primary">
              Invoice Customizations
            </h4>
            <p className="mt-2 text-[0.9375rem] leading-[1.375rem]">
              These override settings are provided to allow you to customize the
              invoice and contact information for this membership.{" "}
              {props.formType === "edit" && (
                <span className="font-semibold">
                  Updates will not be preserved unless the overall "save" button
                  is clicked.
                </span>
              )}
            </p>

            <div className="grid grid-cols-1 items-start gap-4 md:grid-cols-2">
              {/* INVOICE */}
              <div className="mt-2 flex flex-col items-center gap-2 self-end">
                {props.watchedFields.overrideInvoice ? (
                  <>
                    <p className="text-sm">
                      The custom invoice will be used whenever the membership is
                      up for dues collection during the term
                      {membershipWillAutoRenew &&
                        ", and for processing the membership renewal at the end of the term"}
                      .
                    </p>
                    <BaseButtonSecondary
                      type="button"
                      onClick={() => setShowInvoiceSection(!showInvoiceSection)}
                      textColor="text-black"
                      borderColor="border-gray-700"
                    >
                      <PostAddIcon className="mr-1.5" />
                      {!showInvoiceSection ? "View Invoice" : "Hide Invoice"}
                    </BaseButtonSecondary>
                  </>
                ) : (
                  <>
                    <p className="text-sm">
                      If this membership needs to be invoiced differently than
                      other memberships that use the {props.template.title}{" "}
                      membership type, you can create a bespoke invoice.
                    </p>
                    <p className="text-sm">
                      This invoice "template" will be used whenever the
                      membership is up for dues collection during the term
                      {membershipWillAutoRenew &&
                        ", and for processing the membership renewal at the end of the term"}
                      .
                    </p>
                    <BaseButtonSecondary
                      type="button"
                      onClick={() => setShowInvoiceSection(!showInvoiceSection)}
                      textColor="text-black"
                      borderColor="border-gray-700"
                    >
                      <PostAddIcon className="mr-1.5" />
                      {!showInvoiceSection
                        ? "Create Bespoke Invoice"
                        : "Hide Invoice"}
                    </BaseButtonSecondary>
                  </>
                )}
              </div>

              {/* EMAIL */}
              <div className="row-start-1 mt-2 border-2 border-dashed border-gray-300 p-2 md:col-start-2">
                <div className="flex flex-col items-center gap-2">
                  {/* EMAIL IS SET */}
                  {props.watchedFields.overrideEmail && (
                    <>
                      <p className="text-sm">
                        The default email address
                        {defaultEmail && ` (${defaultEmail})`} has been
                        overridden.
                      </p>

                      {!showEmailInput && (
                        <CustomEmailWithOptions
                          overrideEmail={props.watchedFields.overrideEmail}
                          setValue={props.setValue}
                          setShowEmailInput={setShowEmailInput}
                        />
                      )}
                    </>
                  )}

                  {/* NO EMAIL SET */}
                  {!props.watchedFields.overrideEmail && (
                    <>
                      <p className="text-sm">
                        The default email address
                        {defaultEmail && ` (${defaultEmail})`} can be overridden
                        here. It will be used on invoices for this membership.
                        {membershipWillAutoRenew &&
                          " The membership renewal notice will also be sent to this email address."}
                      </p>

                      {!showEmailInput && (
                        <BaseButtonSecondary
                          type="button"
                          onClick={() => setShowEmailInput(true)}
                          textColor="text-black"
                          borderColor="border-gray-700"
                        >
                          <EmailIcon fontSize="small" className="mr-2" />
                          Set Custom Email
                        </BaseButtonSecondary>
                      )}
                    </>
                  )}

                  {showEmailInput && (
                    <EmailInputForm
                      control={props.control}
                      onComplete={() => setShowEmailInput(false)}
                    />
                  )}
                </div>

                {/* error message needs to be here, outside of conditional
                rendering. if it only shows when the email input form is showing,
                any error message would be inadvertently hidden from the user */}
                {props.errors.overrideEmail?.message && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: props.errors.overrideEmail.message }}
                    </StyledMessage>
                  </div>
                )}
              </div>

              {/* EXPANDED INVOICE */}
              {showInvoiceSection && (
                <div className="md:col-span-2 md:row-start-2">
                  <MembershipInvoiceEditor
                    siteKey={props.siteKey}
                    totalTaxRate={props.customerTaxRate}
                    siteFilterPbiByLocationID={
                      siteKeyData?.customizations
                        .filterPriceBookItemsByLocationID ?? false
                    }
                    locationID={props.locationID}
                    currency={props.currency}
                    customer={props.customer}
                    isEditing={!props.watchedFields.overrideInvoice}
                    overrideInvoice={props.watchedFields.overrideInvoice}
                    setInvoiceOverride={handleSetInvoice}
                    handleRemoveInvoice={handleRemoveInvoice}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {props.children.assetSection && (
        <div className="mt-4 max-w-fit space-y-2 rounded-md border border-gray-500 px-3 py-2">
          {props.children.assetSection}
        </div>
      )}

      {/* DATE FIELDS */}
      <DateFields formType={props.formType} control={props.control} />

      {/* Action Buttons */}
      <div className="flex w-full flex-col items-center justify-between gap-4 pt-8 xs:flex-row">
        <BaseButtonSecondary
          type="button"
          className="w-full justify-center uppercase"
          onClick={props.closeAndReset}
        >
          {strings.buttons.CANCEL}
        </BaseButtonSecondary>

        <BaseButtonPrimary
          type="submit"
          formNoValidate
          isBusy={props.isSubmitting}
          busyText={strings.buttons.BUSY_SAVING}
          className="w-full justify-center uppercase"
        >
          {strings.buttons.SAVE}
        </BaseButtonPrimary>
      </div>
    </form>
  );
}

// SECTION: HELPERS

// TODO: don't show next invoice date field if difference btwn membershipStart and
// membershipEnd is "the same as" the template's invoiceFrequency
// TODO: don't show task date fields if automatic task generation is enabled??

function DateFields(props: {
  formType: "create" | "edit";
  control: Control<MembershipFormState>;
}) {
  const endDateTooltipRef = useRef<HTMLDivElement>(null);
  return (
    <div className="mt-8 flex flex-wrap gap-x-6 gap-y-10 md:justify-between">
      <div className="space-y-8">
        <Controller
          key="membershipStartDate"
          name="membershipStartDate"
          control={props.control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <BaseInputDate
                inputName="membershipStart"
                displayText={strings.MEMBERSHIP_START_DATE}
                required
                addWrapperStyles="mt-2"
                // overrideWidth="max-w-fit md:max-w-none md:w-52 lg:w-60"
                overrideWidth="w-48 lg:w-52"
                value={field.value?.toDate().toISOString() ?? null}
                onChange={(date) => {
                  if (date) field.onChange(Timestamp.fromDate(date));
                }}
              />
              {error?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: error.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          )}
        />

        <Controller
          key="membershipEndDate"
          name="membershipEndDate"
          control={props.control}
          render={({ field, fieldState: { error } }) => (
            <div className="relative">
              {/* <StyledTooltip title="If an end date is not selected, it will be based on the selected membership type's default duration."> */}
              <StyledTooltip title="If an end date is not selected, the server will set the end date based on the membership start date and the membership type's default duration.">
                <div
                  ref={endDateTooltipRef}
                  // className="absolute -top-6 right-12 px-1"
                  className="absolute -top-6 right-1 px-1"
                >
                  <QuestionMarkOutlined
                    style={{ width: "1.2rem", height: "1.2rem" }}
                    className="rounded-full border border-gray-500 p-0.5 text-gray-500 transition-colors hover:border-gray-700 hover:text-gray-700"
                  />
                </div>
              </StyledTooltip>
              <BaseInputDate
                inputName="membershipEnd"
                displayText={strings.MEMBERSHIP_END_DATE}
                addWrapperStyles="mt-2"
                overrideWidth="w-48 lg:w-52"
                value={field.value?.toDate().toISOString() ?? null}
                onChange={(date) => {
                  if (date) field.onChange(Timestamp.fromDate(date));
                  // else field.onChange(null); // TODO: when back on main (see BaseInputDate.tsx)
                }}
              />
              {error?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: error.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          )}
        />
      </div>
      <div className="space-y-8">
        <Controller
          key="lastCompletedTaskDate"
          name="lastCompletedTaskDate"
          control={props.control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <BaseInputDate
                inputName="lastCompletedTask"
                displayText={strings.LAST_COMPLETED_TASK_DATE}
                addWrapperStyles="mt-2"
                overrideWidth="w-48 lg:w-52"
                value={field.value?.toDate().toISOString() ?? null}
                onChange={(date) => {
                  if (date) field.onChange(Timestamp.fromDate(date));
                }}
              />
              {error?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: error.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          )}
        />

        <Controller
          key="nextScheduledTaskDate"
          name="nextScheduledTaskDate"
          control={props.control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <BaseInputDate
                inputName="nextScheduledTask"
                displayText={strings.NEXT_SCHEDULED_TASK_DATE}
                addWrapperStyles="mt-2"
                overrideWidth="w-48 lg:w-52"
                value={field.value?.toDate().toISOString() ?? null}
                onChange={(date) => {
                  if (date) field.onChange(Timestamp.fromDate(date));
                }}
              />
              {error?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: error.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          )}
        />
      </div>
      <div className="space-y-8">
        <Controller
          key="lastPaymentDate"
          name="lastPaymentDate"
          control={props.control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <BaseInputDate
                inputName="lastPayment"
                displayText={strings.LAST_PAYMENT_DATE}
                addWrapperStyles="mt-2"
                overrideWidth="w-48 lg:w-52"
                value={field.value?.toDate().toISOString() ?? null}
                onChange={(date) => {
                  if (date) field.onChange(Timestamp.fromDate(date));
                }}
              />
              {error?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: error.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          )}
        />

        <Controller
          key="nextInvoiceDate"
          name="nextInvoiceDate"
          control={props.control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <BaseInputDate
                inputName="nextInvoice"
                required={props.formType === "create"}
                displayText={strings.NEXT_INVOICE_DATE}
                addWrapperStyles="mt-2"
                overrideWidth="w-48 lg:w-52"
                value={field.value?.toDate().toISOString() ?? null}
                onChange={(date) => {
                  if (date) field.onChange(Timestamp.fromDate(date));
                }}
              />
              {error?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: error.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          )}
        />
      </div>
    </div>
  );
}

/** get tooltip text for the "email invoice automatically" toggle */
function getEmailInvTooltip(autoPay: boolean, autoEmail: boolean): string {
  if (autoPay) {
    return "This setting is disabled because the customer's primary card on file will be charged automatically when a membership invoice is generated.";
  }
  if (autoEmail) {
    return "Toggle off to prevent automatically emailing membership invoices to the customer.";
  }
  return "Toggle on to automatically email membership invoices to the customer.";
}

/** returns list items (`<li>`). describes invoicing and renewals. depends heavily on invoice method, invoice frequency, and the membership's duration. */
function InvoicingDetails(args: {
  template: {
    invoiceMethod: InvoiceMethod;
    invoiceFrequency: InvoiceFrequency;
    defaultDuration: number;
  };
  startDate: Timestamp | null;
  endDate: Timestamp | null;
  nextInvoiceDate: Timestamp | null;
  formattedInvAmount: string;
}): JSX.Element {
  if (args.template.invoiceMethod !== "automatic") {
    return (
      <>
        <li>
          Invoices will need to be generated manually for this membership.
        </li>
        <li>The membership will not automatically renew.</li>
      </>
    );
  }

  let invoiceStr: string;
  let nextDateStr: string | null = null;
  let renewalStr: string;

  /** date for display, or "not yet selected" */
  const nextInvDate = args.nextInvoiceDate
    ? convertToReadableTimestampDate(args.nextInvoiceDate)
    : "not yet selected";

  const frequencyInMonths = getInvoiceFrequencyInMonths(
    args.template.invoiceFrequency,
  );
  // first check for start/end date (template.defaultDuration may have been overridden)
  if (args.startDate && args.endDate) {
    const startDate = args.startDate.toDate();
    const endDate = args.endDate.toDate();
    const durationInMonths = Math.round(
      (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24 * 30),
    );

    // INVOICE (and `nextDateStr`)
    if (
      frequencyInMonths === Infinity ||
      (frequencyInMonths > 0 &&
        (durationInMonths === frequencyInMonths ||
          durationInMonths < frequencyInMonths))
    ) {
      invoiceStr = `A one-time invoice for ${args.formattedInvAmount}`;
      if (args.nextInvoiceDate && args.nextInvoiceDate.toDate() < new Date()) {
        invoiceStr += ` was generated on ${nextInvDate}.`;
      } else if (
        args.nextInvoiceDate &&
        args.nextInvoiceDate.toDate() === new Date()
      ) {
        invoiceStr += ` will be generated today.`;
      } else {
        invoiceStr += ` will be generated on the next invoice date (${nextInvDate}).`;
      }
    } else {
      invoiceStr = `An invoice for ${args.formattedInvAmount} will be generated ${getInvFrequencyText(args.template.invoiceFrequency)}.`;
      if (args.nextInvoiceDate) {
        nextDateStr = `The next invoice will be generated on ${nextInvDate}.`;
      } else {
        nextDateStr = "The next invoice date has not yet been selected.";
      }
    }
  } else {
    // otherwise: default duration
    const defaultDuration = args.template.defaultDuration;
    if (
      defaultDuration !== -1 &&
      frequencyInMonths > 0 &&
      (defaultDuration === frequencyInMonths ||
        defaultDuration < frequencyInMonths)
    ) {
      invoiceStr = `A one-time invoice for ${args.formattedInvAmount}`;
      if (args.nextInvoiceDate && args.nextInvoiceDate.toDate() < new Date()) {
        invoiceStr += ` was generated on ${nextInvDate}.`;
      } else if (
        args.nextInvoiceDate &&
        args.nextInvoiceDate.toDate() === new Date()
      ) {
        invoiceStr += ` will be generated today.`;
      } else {
        invoiceStr += ` will be generated on the next invoice date (${nextInvDate}).`;
      }
    } else {
      invoiceStr = `An invoice for ${args.formattedInvAmount} will be generated ${getInvFrequencyText(args.template.invoiceFrequency)}.`;
      if (args.nextInvoiceDate) {
        nextDateStr = `The next invoice will be automatically generated on ${nextInvDate}.`;
      } else {
        nextDateStr = "The next invoice date has not yet been selected.";
      }
    }
  }

  // RENEWAL
  if (args.endDate) {
    renewalStr = `The membership will renew on ${convertToReadableTimestampDate(args.endDate)}.`;
  } else {
    if (args.template.defaultDuration === -1) {
      renewalStr =
        "The membership will not automatically renew unless an end date is manually selected, overriding the default duration.";
    } else {
      if (args.startDate) {
        const startDateLux = convertFSTimestampToLuxonDT(args.startDate);
        const renewalDateLux = startDateLux.plus({
          months: args.template.defaultDuration,
        });
        const renewalDate = convertLuxonDTToFSTimestamp(renewalDateLux);
        renewalStr = `The membership will renew on ${convertToReadableTimestampDate(renewalDate)}.`;
      } else {
        renewalStr = `The membership will use the default duration and start date to determine the renewal date.`;
      }
    }
  }

  return (
    <>
      <li>{invoiceStr}</li>
      {nextDateStr && <li className="ml-4">{nextDateStr}</li>}
      <li>{renewalStr}</li>
    </>
  );

  // const x = (
  //   <>
  //     <li>
  //       An invoice for {args.formattedInvAmount} will be automatically generated{" "}
  //       {getInvFrequencyText(args.template.invoiceFrequency)}.
  //     </li>
  //     <li className="ml-4">
  //       The next invoice will be generated on {nextInvDate}.
  //     </li>
  //   </>
  // );
  // return x;
}

function getInvFrequencyText(frequency: InvoiceFrequency): string {
  switch (frequency) {
    case "annual":
      return "annually";
    case "semiannual":
      return "every 6 months";
    case "quarterly":
      return "every 3 months";
    case "monthly":
      return "monthly";
    case "indefinite":
      return "once";
    default: {
      const exhaustiveCheck: never = frequency;
      logger.error(`unhandled invoice frequency: ${exhaustiveCheck}`);
      return "[unknown]";
    }
  }
}

function getReadableDefaultDuration(dd: number): string {
  if (dd === -1) return "indefinite";

  const durationInYears = convertDuration(dd, "Months", "Years");
  if (Number.isInteger(durationInYears)) {
    if (durationInYears === 1) return "1 year";
    return `${durationInYears} years`;
  }
  return `${dd} months`;
}

function EmailInputForm(props: {
  control: Control<MembershipFormState>;
  onComplete: () => void;
}) {
  return (
    <Controller
      name="overrideEmail"
      control={props.control}
      render={({ field }) => (
        <div className="flex w-full items-center gap-1.5">
          <BaseInputText
            id="custom-email"
            text="Custom Email Address"
            placeholder="Enter email address"
            {...field}
            value={field.value ?? ""}
            admin={false}
            className="grow"
          />
          <span className="flex items-center gap-1">
            <button
              type="button"
              className="rounded-full border border-gray-300 bg-white hover:bg-green-50"
              onClick={() => {
                if (field.value) field.onChange(field.value);
                props.onComplete();
              }}
            >
              <CheckIcon
                aria-label="confirm button"
                className="block h-8 w-8 p-1 text-green-700"
              />
            </button>

            <button
              type="button"
              className="rounded-full border border-gray-300 bg-white hover:bg-red-50"
              onClick={() => {
                field.onChange(undefined);
                props.onComplete();
              }}
            >
              <XMarkIcon
                aria-label="cancel button"
                className="block h-8 w-8 p-1 text-red-700"
              />
            </button>
          </span>
        </div>
      )}
    />
  );
}

/** displays the custom email with edit/remove options */
function CustomEmailWithOptions(props: {
  overrideEmail: string;
  setValue: UseFormSetValue<MembershipFormState>;
  setShowEmailInput: (show: boolean) => void;
}) {
  return (
    <div className="flex items-center justify-center gap-2">
      <div className="flex items-center gap-2 rounded bg-white px-2 py-1 text-gray-600 md:max-w-72 lg:max-w-80">
        <EmailIcon fontSize="small" className="text-gray-400" />
        <span className="truncate text-sm font-semibold">
          {props.overrideEmail}
        </span>
      </div>
      <div className="flex gap-2">
        <span className="h-8 w-8 rounded-full border border-gray-300 bg-white hover:bg-gray-50">
          <StyledTooltip title="Edit">
            <PencilIconWithRef
              onClick={() => props.setShowEmailInput(true)}
              overrideMargin="mr-0"
              overrideStyles={{
                width: "1.9rem",
                height: "1.9rem",
                padding: "4px",
              }}
            />
          </StyledTooltip>
        </span>
        <span className="h-8 w-8 rounded-full border border-gray-300 bg-white hover:bg-gray-50">
          <StyledTooltip title="Remove">
            <TrashButton
              height="1.9rem"
              width="1.9rem"
              padding="4px"
              overrideMargin="mx-0"
              color="text-red-700"
              hoverColor="hover:bg-red-50"
              onDelete={() => {
                props.setValue("overrideEmail", undefined);
                props.setShowEmailInput(false);
              }}
            />
          </StyledTooltip>
        </span>
      </div>
    </div>
  );
}
