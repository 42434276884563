import { useState } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { useRootUserStore } from "../../../store/root-user";
import BusinessInfoStep from "./Steps/BusinessInfoStep";
import RevenueStep from "./Steps/RevenueStep";
import OwnerInfoStep from "./Steps/OwnerInfoStep";
import ReviewStep from "./Steps/ReviewStep";
import BankingStep from "./Steps/BankingStep";
import OnboardingProgress from "../Components/OnboardingProgress";
import { MERCHANT_ONBOARDING_STEPS } from "../Components/OnboardingProgress";
import { MerchantCreationRequest } from "../MerchantApplication";
import OwnersStep from "./Steps/OwnersStep";
import ProgressStep from "./Steps/ProgressStep";

export type MerchantOnboardingStep =
  | "business"
  | "revenue"
  | "owner"
  | "owners"
  | "banking"
  | "review"
  | "progress";

interface MerchantApplicationData {
  business: {
    businessName: string; // DBA name
    legalName: string;
    ownershipStructureType:
      | "LLC"
      | "Corporation"
      | "Partnership"
      | "SoleProprietorship";
    street1: string;
    street2?: string;
    city: string;
    state: string;
    zipCode: string;
    countryCode: string;
    descriptorPhone: string;
    taxId: string;
    webSite?: string;
    email: string;
    description: string;
    // merchantCategoryCode: string;
    currencyCode: string;
    registrationYear: string;
    timeZoneCode: string;
  };
  estimates: {
    annualDirectDebitVolume: number;
    annualCardsVolume: number;
    avgDirectDebitTransactionAmount: number;
    avgCardsTransactionAmount: number;
    maxTransactionAmount: number;
  };
  deposit: {
    bankName: string;
    holderName: string;
    routingNumber: string;
    accountNumber: string;
    accountType: "C" | "S";
  };
  officer: {
    title:
      | "Owner"
      | "Partner"
      | "Director"
      | "President"
      | "CEO"
      | "CFO"
      | "Chairman"
      | "Comptroller"
      | "General Manager"
      | "Office Manager"
      | "Treasurer"
      | "Vice President";
    firstName: string;
    lastName: string;
    street1: string;
    street2?: string;
    city: string;
    state: string;
    zipCode: string;
    countryCode: string;
    birthDate: string; // YYYYMMDD
    socialSecurity: string;
    phone: string;
    email: string;
    driverLicense?: string;
    driverLicenseState?: string;
    driverLicenseCountryCode: string;
  };
  owners: Array<{
    firstName: string;
    lastName: string;
    title:
      | "Owner"
      | "Partner"
      | "Director"
      | "President"
      | "CEO"
      | "CFO"
      | "Chairman"
      | "Comptroller"
      | "General Manager"
      | "Office Manager"
      | "Treasurer"
      | "Vice President";
    street1: string;
    street2?: string;
    city: string;
    state: string;
    zipCode: string;
    countryCode: string;
    email: string;
    birthDate: string;
    socialSecurity: string;
    phone: string;
    stakePercentage: number;
    driverLicense?: string;
    driverLicenseState?: string;
    driverLicenseCountryCode: string;
  }>;
  termsAccepted: boolean;
}

export default function MerchantOnboardingContainer() {
  const rootUser = useRootUserStore((state) => state.rootUser);
  const apiKey = import.meta.env.VITE_APP_GOOGLE_MAP_KEY;

  const [currentStep, setCurrentStep] =
    useState<MerchantOnboardingStep>("business");

  const [onboardingData, setOnboardingData] = useState<MerchantApplicationData>(
    {
      business: {
        businessName: "",
        legalName: "",
        ownershipStructureType: "LLC",
        street1: "",
        city: "",
        state: "",
        zipCode: "",
        countryCode: "US",
        descriptorPhone: "",
        taxId: "",
        email: "",
        description: "",
        // merchantCategoryCode: "7399", // Default general services
        currencyCode: "USD",
        registrationYear: new Date().getFullYear().toString(),
        timeZoneCode: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
      estimates: {
        annualDirectDebitVolume: 0,
        annualCardsVolume: 0,
        avgDirectDebitTransactionAmount: 0,
        avgCardsTransactionAmount: 0,
        maxTransactionAmount: 0,
      },
      deposit: {
        bankName: "",
        holderName: "",
        routingNumber: "",
        accountNumber: "",
        accountType: "C",
      },
      officer: {
        title: "Owner",
        firstName: "",
        lastName: "",
        street1: "",
        city: "",
        state: "",
        zipCode: "",
        countryCode: "US",
        birthDate: "",
        socialSecurity: "",
        phone: "",
        email: "",
        driverLicenseCountryCode: "US",
      },
      owners: [],
      termsAccepted: false,
    },
  );

  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [submissionMessage, setSubmissionMessage] = useState("");

  const renderStep = () => {
    switch (currentStep) {
      case "business":
        return (
          <Wrapper apiKey={apiKey} libraries={["places"]}>
            <BusinessInfoStep
              initialData={onboardingData.business}
              onComplete={(data) => {
                setOnboardingData((prev) => ({
                  ...prev,
                  business: data,
                }));
                setCurrentStep("revenue");
              }}
            />
          </Wrapper>
        );
      case "revenue":
        return (
          <RevenueStep
            initialData={onboardingData.estimates}
            onComplete={(data) => {
              setOnboardingData((prev) => ({
                ...prev,
                estimates: data,
              }));
              setCurrentStep("owner");
            }}
          />
        );
      case "owner":
        return (
          <Wrapper apiKey={apiKey} libraries={["places"]}>
            <OwnerInfoStep
              initialData={onboardingData.officer}
              onComplete={(data) => {
                setOnboardingData((prev) => ({
                  ...prev,
                  officer: data,
                }));
                setCurrentStep("owners");
              }}
            />
          </Wrapper>
        );
      case "owners":
        return (
          <Wrapper apiKey={apiKey} libraries={["places"]}>
            <OwnersStep
              initialData={onboardingData.owners}
              officerData={onboardingData.officer}
              onComplete={(data) => {
                setOnboardingData((prev) => ({
                  ...prev,
                  owners: data,
                }));
                setCurrentStep("banking");
              }}
            />
          </Wrapper>
        );
      case "banking":
        return (
          <BankingStep
            initialData={onboardingData.deposit}
            onComplete={(data) => {
              setOnboardingData((prev) => ({
                ...prev,
                deposit: data,
              }));
              setCurrentStep("review");
            }}
          />
        );
      case "review":
        return (
          <ReviewStep
            data={onboardingData}
            onComplete={(success, message) => {
              setSubmissionSuccess(success);
              setSubmissionMessage(message);
              setCurrentStep("progress");
            }}
          />
        );
      case "progress":
        return (
          <ProgressStep
            success={submissionSuccess}
            message={submissionMessage}
          />
        );
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="mx-auto max-w-3xl px-4 py-16 sm:px-6 lg:px-8">
        <div className="overflow-hidden rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:p-6">
            <OnboardingProgress
              currentStep={currentStep}
              steps={MERCHANT_ONBOARDING_STEPS}
            />
          </div>

          <div className="px-4 py-5 sm:p-6">{renderStep()}</div>
        </div>
      </div>
    </div>
  );
}
