//Libs
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useState } from "react";

//Local
import { ExistingMembershipTemplate } from "../../models/membership-template";
import * as strings from "../../strings";
import BaseModal from "../BaseModal";
import BaseInputCheckbox from "../BaseInputCheckbox";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseButtonSecondary from "../BaseButtonSecondary";
import { logger as devLogger } from "../../logging";
import { DollarCurrency } from "../../currency";
import BaseInputNumber from "../BaseInputNumber";
import { repeatStringNumTimes } from "../../assets/js/memberships/repeatStringNumTimes";
import HeadingOne from "../HeadingOne";

interface Props {
  /* DATA */
  isDialogOpen: boolean;
  membershipTemplates: ExistingMembershipTemplate[];
  membershipIDsCount: Record<string, number>;
  temporaryMemberships: Record<string, any>[];
  /* FUNCTIONS */
  closeDialog: () => void;
  setNewMembershipIds: React.Dispatch<React.SetStateAction<string[]>>;
  setTemporaryMemberships: React.Dispatch<
    React.SetStateAction<Record<string, any>[]>
  >;
}

export default function MembershipsAndDiscountsDialog({
  closeDialog,
  isDialogOpen,
  membershipIDsCount,
  membershipTemplates,
  setNewMembershipIds,
  setTemporaryMemberships,
  temporaryMemberships,
}: Props) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const templateDiscounts: ExistingMembershipTemplate[] =
    membershipTemplates.filter((template) => template.price == null);
  const templateMemberships: ExistingMembershipTemplate[] =
    membershipTemplates.filter((template) => template.price != null);

  function onSubmit() {
    setIsSubmitting(true);
    let temporaryTemplateIDs: string[] = [];

    temporaryMemberships.forEach((membership) => {
      const stringArray = repeatStringNumTimes(
        membership.templateID,
        membership.quantity,
      );
      if (stringArray != null) {
        temporaryTemplateIDs = temporaryTemplateIDs.concat(...stringArray);
      }
    });

    const membershipTemplateIDs = [...temporaryTemplateIDs];

    try {
      setNewMembershipIds(membershipTemplateIDs);
      // Close dialog if successful.
      closeDialog();
    } catch (error) {
      devLogger.error(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  function handleTemporaryMemberships(
    formQuantity: number,
    templateID: string,
  ) {
    let quantity = formQuantity;

    if (isNaN(quantity)) {
      quantity = 0;
    }

    const indexOfTempMembership = temporaryMemberships.findIndex(
      (tempMembership) => tempMembership.templateID === templateID,
    );

    if (indexOfTempMembership >= 0) {
      setTemporaryMemberships((oldTempMembership) => {
        oldTempMembership[indexOfTempMembership].quantity = quantity;
        return [...oldTempMembership];
      });
    } else {
      setTemporaryMemberships((oldTempMembership) => [
        ...oldTempMembership,
        { quantity: quantity, templateID: templateID },
      ]);
    }
  }

  const membershipTemplatesHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <HeadingOne fontColor="text-white" fontSize="text-xl">
        {strings.MEMBERSHIPS}
      </HeadingOne>
      <button type="button" onClick={() => closeDialog()}>
        <XMarkIcon
          aria-label="close new requirement form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={closeDialog}
      open={isDialogOpen}
      title={membershipTemplatesHeader}
      parentDivStyles="inline-block transform overflow-hidden  max-w-screen-md rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <div className="flex flex-col space-y-8 p-8 text-lg">
        {/* DISCOUNTS */}
        {templateDiscounts.length !== 0 ? (
          <div>
            <div className="pb-4 text-xl font-bold text-primary">
              {strings.DISCOUNTS}
            </div>
            <div className="grid grid-cols-2">
              {templateDiscounts.map((template) => (
                <div
                  key={template.id}
                  className="flex flex-col gap-2 rounded-md border p-4"
                >
                  <BaseInputCheckbox
                    label={template.title}
                    value={template.id}
                    defaultChecked={getStatusForCheckbox(
                      membershipIDsCount,
                      template.id,
                    )}
                    onChange={(event) => {
                      handleTemporaryMemberships(
                        event.target.checked === true ? 1 : 0,
                        template.id,
                      );
                    }}
                  />
                  <ul>
                    <li
                      key={`discounts.${template.id}`}
                      className="flex gap-3 text-base text-gray-400"
                    >
                      Discount:
                      <span>{template.discount.toFixed(2)}%</span>
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          </div>
        ) : null}

        {/* MEMBERSHIPS */}
        {templateMemberships.length !== 0 ? (
          <div>
            <div className="pb-4 text-xl font-bold text-primary">
              {strings.MEMBERSHIPS}
            </div>
            <div className="grid grid-cols-2 gap-4">
              {templateMemberships.map((template, index) => (
                <div
                  key={template.id}
                  className="flex flex-col gap-2 rounded-md border p-4"
                >
                  <div className="flex items-center gap-4">
                    <BaseInputNumber
                      defaultValue={getQuantityValue(
                        membershipIDsCount,
                        template.id,
                      )}
                      type="number"
                      min={0}
                      className={`block w-20 min-w-0 rounded outline-none focus:border focus:border-primaryLight focus:ring-1 focus:ring-primaryLight sm:text-sm`}
                      onBlur={(event) => {
                        event.preventDefault();
                        handleTemporaryMemberships(
                          parseFloat(event.target.value),
                          template.id,
                        );
                      }}
                      onKeyDown={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          handleTemporaryMemberships(
                            parseFloat(
                              (event.target as HTMLInputElement).value,
                            ),
                            template.id,
                          );
                        }
                      }}
                    />
                    <span className="text-base font-bold capitalize text-primary">
                      {template.title}
                    </span>
                  </div>
                  <ul>
                    <li
                      key={`memberships.discount.${index}`}
                      className="flex gap-3 text-base text-gray-400"
                    >
                      Discount:
                      <span>{template.discount.toFixed(2)}%</span>
                    </li>
                    {template.price ? (
                      <li
                        key={`memberships.price.${index}`}
                        className="flex gap-3 text-base text-gray-400"
                      >
                        Price:
                        <span>{DollarCurrency.format(template.price)}</span>
                      </li>
                    ) : null}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        ) : null}

        {/* Action Buttons */}
        <div className="mt-4 flex w-full items-center justify-between gap-4">
          <BaseButtonSecondary
            type="button"
            className="w-full justify-center uppercase"
            onClick={() => {
              closeDialog();
            }}
          >
            {strings.buttons.CANCEL}
          </BaseButtonSecondary>

          <BaseButtonPrimary
            disabled={isSubmitting}
            isBusy={isSubmitting}
            busyText={strings.buttons.BUSY_SAVING}
            type="submit"
            onClick={onSubmit}
            className="w-full justify-center uppercase"
            formNoValidate
          >
            {strings.buttons.SAVE}
          </BaseButtonPrimary>
        </div>
      </div>
    </BaseModal>
  );
}

function getQuantityValue(
  membershipIDsCount: Record<string, number>,
  templateID: string,
) {
  let value: number = 0;

  if (Object.values(membershipIDsCount).length !== 0) {
    value = membershipIDsCount[templateID];
  } else {
    value = 0;
  }
  return value;
}

function getStatusForCheckbox(
  membershipIDsCount: Record<string, number>,
  templateID: string,
) {
  let status: boolean = false;

  if (Object.values(membershipIDsCount).length !== 0) {
    status = membershipIDsCount[templateID] === 1 ? true : false;
  } else {
    status = false;
  }
  return status;
}
