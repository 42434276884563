import { useEffect, useState } from "react";
import { DbRead } from "../../../database";

interface PaymentTerm {
  title: string;
  daysUntilDue: number;
}

interface Props {
  value?: string;
  onChange?: (value: string) => void;
}

export default function PaymentTermSelector({ value, onChange }: Props) {
  const [paymentTerms, setPaymentTerms] = useState<Record<string, PaymentTerm>>(
    {},
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchPaymentTerms() {
      try {
        const response = await DbRead.siteKey.getSiteConfiguration();
        // Assuming payment terms are stored in a section called 'paymentTerms'
        const terms = response.config.paymentTerms.defaultValue || {};
        console.log("Payment terms:", terms);
        setPaymentTerms(terms);
      } catch (error) {
        console.error("Failed to fetch payment terms:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchPaymentTerms();
  }, []);

  if (isLoading) {
    return <div className="text-gray-500">Loading payment terms...</div>;
  }

  return (
    <select
      value={value}
      onChange={(e) => onChange?.(e.target.value)}
      className="block w-full rounded border border-primaryLight p-2 text-gray-700 shadow-sm outline-none focus:ring focus:ring-primaryLight sm:text-sm"
    >
      <option value="">Select a payment term</option>
      {Object.entries(paymentTerms).map(([key, term]) => (
        <option key={key} value={key}>
          {term.title} ({term.daysUntilDue} days)
        </option>
      ))}
    </select>
  );
}
