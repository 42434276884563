//Libs
import { z } from "zod";
import { DocumentData, DocumentSnapshot, Timestamp } from "firebase/firestore";

//Local
import {
  convertISOToFSTimestamp,
  dropUndefined,
  guardIsPlainObject,
} from "../utils";
import { NotFoundError } from "../error-classes";
import {
  AccountingSyncStiltDoc,
  AccountingSyncStiltDocSchema,
} from "./accounting-sync";

export enum StiltInvoiceStatus {
  PENDING = "pending",
  DRAFT = "draft",
  CANCELED = "canceled",
  PAID = "paid",
  SUBMITTED = "submitted",
  PARTIALLY_PAID = "partiallyPaid",
}
/** prepended with O to denote "object" (as opposed to the Enum version) */
export const OStiltInvoiceStatus = {
  PENDING: "pending",
  DRAFT: "draft",
  CANCELED: "canceled",
  PAID: "paid",
  SUBMITTED: "submitted",
  PARTIALLY_PAID: "partiallyPaid",
} as const;

export type StiltInvoiceStatusValues =
  (typeof OStiltInvoiceStatus)[keyof typeof OStiltInvoiceStatus];

export function getReadableStiltInvoiceStatus(value: string): string {
  switch (value) {
    case "draft":
      return "Draft";
    case "canceled":
      return "Canceled";
    case "paid":
      return "Paid";
    case "submitted":
      return "Submitted";
    case "partiallyPaid":
      return "Partially paid";
    case "pending":
      return "Pending";
    default:
      return "UNKNOWN";
  }
}

/** Typeguard to determine if the given value is one of theStiltInvoiceStatusValues */
export function isValidStiltInvoiceStatusValue(
  value: string,
): value is StiltInvoiceStatusValues {
  const statusValues = Object.values(OStiltInvoiceStatus);
  return statusValues.includes(value as any);
}

export interface StiltInvoice {
  // Coming from Stilt
  timestampCreated: Timestamp;
  createdBy: string;
  timestampLastModified: Timestamp;
  lastModifiedBy: string;
  customerID: string;
  customerLocationID: string;
  locationID: string;
  invoiceNumber: string | null;
  paymentTerms: string | null;
  taskID: string | null;
  craftRecordID: string | null;
  estimateID: string | null;
  lastPaymentTimestamp: Timestamp | null;
  timestampSentToCustomer: Timestamp | null;
  internalNotes: string | null;

  // Invoice data, coming from Stilt
  billToCustomerID: string;
  billToCustomerLocationID: string;
  poNumber: string | null;
  name: string;
  firstName: string | null;
  lastName: string | null;
  merchantName: string;
  email: string | null;
  phone: string | null;
  addressLine1: string;
  addressLine2: string | null;
  addressCity: string;
  addressState: string;
  addressZip: string;
  addressCountry: string;
  issueDate: Timestamp;
  dueDate: Timestamp | null;
  subTotal: number;
  totalAmount: number;
  totalTaxAmount: number;
  totalTaxAmountPST?: number;
  totalTaxAmountGST?: number;
  discount: number;
  amountDue: number;
  note: string;
  lineItems: StiltLineItem[];
  status: StiltInvoiceStatusValues;
  customData: { [key: string]: any };
  tipAmount?: number;
  accountingSync?: AccountingSyncStiltDoc;
  isServerGenerated?: boolean;
}

export interface StiltLineItem {
  priceBookItemID: string;
  estimateItemDescription: string | null;
  estimateItemTitle: string;
  estimateItemID: null | string;
  quantity: number;
  discount: number;
  taxAmount: number;
  unitPrice: number;
  subTotal: number;
  discountable?: boolean;
  taxable?: boolean;
  membershipID?: string;
}

export interface ExistingStiltInvoice extends StiltInvoice {
  id: string;
  refPath: string;
}

export interface TemplatePaymentTerm {
  title: string;
  daysUntilDue: number;
}

/***********************/
// FOR THE API
/***********************/
type withoutTimestamps = Omit<
  StiltInvoice,
  "timestampCreated" | "timestampLastModified" | "dueDate" | "issueDate"
>;

// Timestamps are added on the backend.
export interface StiltInvoice_CreateAPI extends withoutTimestamps {
  siteKey: string;
  companyID: string | null;
  connectionID: string | null;
}

export interface StiltInvoiceWithCodatData extends StiltInvoice_CreateAPI {
  companyID: string | null;
  connectionID: string | null;
}

// timestampLastModified is added on the backend. (timestampCreated won't change)
export type StiltInvoice_UpdateAPI = Omit<
  Partial<ExistingStiltInvoice>,
  "timestampCreated" | "timestampLastModified" | "dueDate" | "issueDate"
> & { dueDate?: string | null; issueDate?: string };

// #region SECTION: Functions
/** Utilities for interacting with StiltInvoice objects  */
export const StiltInvoiceManager = {
  /** Drop `id` and `refPath` before saving to the database. Drop undefined */
  convertForFirestore: convertStiltInvoiceForFirestore,
  createFromFirestoreSnapshot: createFromFirestoreSnapshot,
  /** Drop `id` and `refPath` before saving to the database. Drop undefined */
  convertForFirestore_Update,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateStiltInvoice,
  parseCreate: validateStiltInvoice_Create,
  parseUpdate: validateStiltInvoice_Update,
  convertFromCF: createFromCFResponse,
} as const;

/** Drop `id` and `refPath` before saving to the database. Drop undefined values */
function convertStiltInvoiceForFirestore(
  stiltInvoice: ExistingStiltInvoice,
): DocumentData {
  const local = Object.assign({}, stiltInvoice);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, refPath, ...rest } = local;

  const result = dropUndefined(rest);
  return { ...result };
}

// function createFromCFResponse(
//   snapshot: DocumentSnapshot
// ): ExistingStiltInvoice {
//   if (!snapshot.exists) {
//     throw new NotFoundError("Document does not exist.");
//   }

//   const snapshotData = snapshot.data()

//   return {
//     id: snapshot.id,
//     refPath: snapshot.ref.path,
//     ...convertAndValidate(snapshotData)
//   };
// }

function createFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingStiltInvoice {
  if (!snapshot.exists) {
    throw new NotFoundError("Document does not exist.");
  }
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateStiltInvoice(snapshot.data()),
  };
}

function convertForFirestore_Update(
  pbItem: StiltInvoice_UpdateAPI,
): DocumentData {
  const local = Object.assign({}, pbItem);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { id, refPath, ...rest } = local;
  const result = dropUndefined(rest);
  return { ...result };
}

/* Zod validation schemas */
function createFromCFResponse(value: unknown): ExistingStiltInvoice {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }

  const valueAfterConversion = {
    ...value,
    timestampCreated: convertISOToFSTimestamp(value.timestampCreated),
    timestampLastModified: convertISOToFSTimestamp(value.timestampLastModified),
    lastPaymentTimestamp: value.lastPaymentTimestamp
      ? convertISOToFSTimestamp(value.lastPaymentTimestamp)
      : null,
    dueDate: value.dueDate ? convertISOToFSTimestamp(value.dueDate) : null,
    issueDate: convertISOToFSTimestamp(value.issueDate),
  };

  const result = stiltInvoiceSchema
    .extend({
      id: z.string().min(1).max(200),
      refPath: z.string().min(1).max(400),
    })
    .parse(valueAfterConversion);
  return result;
}

function validateStiltInvoice(value: unknown): StiltInvoice {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = stiltInvoiceSchema.parse(value);
  return result;
}

function validateStiltInvoice_Create(value: unknown): StiltInvoice_CreateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return stiltInvoiceSchema_CreateAPI.parse(value);
}

function validateStiltInvoice_Update(value: unknown): StiltInvoice_UpdateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return stiltInvoiceSchema_UpdateAPI.parse(value);
}
// #endregion

// #region SECTION: Schemas

export const StiltLineItemSchema = z.object({
  priceBookItemID: z.string().min(1).max(200),
  estimateItemDescription: z.string().min(0).max(4000).nullable(),
  estimateItemTitle: z.string().min(0).max(2000),
  estimateItemID: z.string().min(1).max(200).nullable(),
  quantity: z.number(),
  discount: z.number(),
  taxAmount: z.number(),
  unitPrice: z.number(),
  subTotal: z.number(),
  discountable: z.boolean().optional(),
  taxable: z.boolean().optional(),
  membershipID: z.string().min(1).max(200).optional(),
});

// Used when writing to the DB or reading from the user.
const stiltInvoiceSchema = z.object({
  timestampCreated: z.instanceof(Timestamp),
  timestampLastModified: z.instanceof(Timestamp),
  lastPaymentTimestamp: z.instanceof(Timestamp).nullable(),
  timestampSentToCustomer: z.instanceof(Timestamp).nullable(),
  createdBy: z.string().min(1).max(200),
  lastModifiedBy: z.string().min(1).max(200),
  customerID: z.string().min(1).max(200),
  customerLocationID: z.string().min(1).max(200),
  locationID: z.string().min(1).max(200),
  invoiceNumber: z.string().min(1).max(200).nullable(),
  paymentTerms: z.string().min(1).max(200).nullable(),
  taskID: z.string().min(1).max(200).nullable(),
  craftRecordID: z.string().min(1).max(200).nullable(),
  estimateID: z.string().min(1).max(200).nullable(),
  status: z.nativeEnum(StiltInvoiceStatus),
  name: z.string().min(0).max(200),
  firstName: z.string().min(0).max(200).nullable(),
  lastName: z.string().min(0).max(200).nullable(),
  merchantName: z.string().min(0).max(200),
  email: z.string().min(0).max(200).nullable(),
  phone: z.string().min(0).max(200).nullable(),
  addressLine1: z.string().min(0).max(200),
  addressLine2: z.string().min(0).max(200).nullable(),
  addressCity: z.string().min(0).max(200),
  addressState: z.string().min(0).max(200),
  addressZip: z.string().min(0).max(200),
  addressCountry: z.string().min(0).max(200),
  issueDate: z.instanceof(Timestamp),
  dueDate: z.instanceof(Timestamp).nullable(),
  subTotal: z.number(),
  totalAmount: z.number(),
  discount: z.number(),
  totalTaxAmount: z.number(),
  totalTaxAmountPST: z.number().optional(),
  totalTaxAmountGST: z.number().optional(),
  amountDue: z.number(),
  internalNotes: z.string().max(8000).nullable(),
  note: z.string().min(0).max(8000),
  billToCustomerID: z.string().min(1).max(200),
  billToCustomerLocationID: z.string().min(1).max(200),
  poNumber: z.string().min(0).max(200).nullable(),
  lineItems: z.array(StiltLineItemSchema).min(0),
  customData: z.record(z.any()),
  tipAmount: z.number().optional(),
  accountingSync: AccountingSyncStiltDocSchema.optional(),
  isServerGenerated: z.boolean().optional(),
});

/***********************/
// FOR THE API
/***********************/
const withoutTimestampsSchema = stiltInvoiceSchema.omit({
  timestampCreated: true,
  timestampLastModified: true,
});

// Used for interacting with the Create endpoint.
const stiltInvoiceSchema_CreateAPI = withoutTimestampsSchema.extend({
  siteKey: z.string().min(1).max(400),
  connectionID: z.string().min(1).max(400).nullable(),
  companyID: z.string().min(1).max(400).nullable(),
});

// Used for interacting with the Update endpoint
const stiltInvoiceSchema_UpdateAPI = withoutTimestampsSchema
  .extend({
    id: z.string().min(1).max(200),
    refPath: z.string().min(1).max(400),
    dueDate: z.string().min(1).max(200).nullable().optional(),
    issueDate: z.string().min(1).max(200).optional(),
  })
  .partial();

export function getInvoiceSectionString(
  workRecordInvoiceList: ExistingStiltInvoice[],
): string {
  const notCanceledInvoices = workRecordInvoiceList.filter(
    (i) => i.status !== "canceled",
  );
  const canceledInvoices = workRecordInvoiceList.filter(
    (i) => i.status === "canceled",
  );

  if (notCanceledInvoices.length === 0 && canceledInvoices.length === 0) {
    return "0 Invoices";
  }

  if (canceledInvoices.length === 0) {
    if (notCanceledInvoices.length === 1) {
      return "1 Invoice";
    }
    return `${notCanceledInvoices.length} Invoices`;
  }

  if (notCanceledInvoices.length === 0) {
    return "1 Canceled Invoice";
  }

  if (notCanceledInvoices.length > 0) {
    if (notCanceledInvoices.length + canceledInvoices.length === 1) {
      return `${
        notCanceledInvoices.length + canceledInvoices.length
      } Invoice / ${canceledInvoices.length} Canceled`;
    }
    return `${
      notCanceledInvoices.length + canceledInvoices.length
    } Invoices / ${canceledInvoices.length} Canceled`;
  }
  return `${
    notCanceledInvoices.length + canceledInvoices.length
  } Invoices / ${canceledInvoices.length} Canceled`;
}
