//Libs
import { z } from "zod";
import { useEffect, useMemo, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { XMarkIcon } from "@heroicons/react/24/solid";

//Local
import StyledMessage from "../StyledMessage";
import BaseButtonSecondary from "../BaseButtonSecondary";
import BaseButtonPrimary from "../BaseButtonPrimary";
import * as strings from "../../strings";
import { ErrorMessage } from "../ErrorMessage";
import BaseInputText from "../BaseInputText";
import BaseModal from "../BaseModal";
import { ExistingCustomer } from "../../models/customer";
import { ExistingCustomerLocation } from "../../models/customer-location";
import { Timestamp } from "firebase/firestore";
import { Asset, AssetManager, ExistingAsset } from "../../models/asset";
import CustomerTableDialog from "../customers/CustomerTableDialog";
import { CustomerLocationSection } from "../customers/CreateTask";
import { DbRead, DbWrite } from "../../database";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import ButtonColored from "../ButtonColored";
import { logger } from "../../logging";
import ConfirmDeleteEquipmentDialog from "./ConfirmDeleteEquipmentDialog";
import { SiteKeyLocationSection } from "../../components/SiteKeyLocationSection";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import BaseInputDate from "../BaseInputDate";
import { Link } from "react-router-dom";
import * as urls from "../../urls";
import { BusinessSharp, PersonSharp } from "@mui/icons-material";
import ConfirmationDialog from "../ConfirmationDialog";

interface Props {
  isDialogOpen: boolean;
  closeDialog: () => void;
  isSubmitting: boolean;
  siteKey: string;

  // Can be supplied to pre-fill the form
  asset: ExistingAsset | null;
  locationID: string | null;
  customerID: string | null;
  customerLocationID: string | null;
}

const NewAssetFormSchema = z.object({
  title: z.string().min(0).max(200),
  description: z.string().min(0).max(500).nullable().optional(),
  manufacturer: z.string().min(0).max(400).optional(),
  equipmentType: z.string().min(0).max(400).optional(),
  serialNumber: z.string().min(0).max(400).optional(),
  modelNumber: z.string().min(0).max(400).optional(),
  installationDate: z.instanceof(Timestamp).optional(),
  manufacturerWarrantyStart: z.instanceof(Timestamp).optional(),
  manufacturerWarrantyEnd: z.instanceof(Timestamp).optional(),
  serviceWarrantyStart: z.instanceof(Timestamp).optional(),
  serviceWarrantyEnd: z.instanceof(Timestamp).optional(),
});
export type NewAssetFormState = z.infer<typeof NewAssetFormSchema>;

export default function AddAssetDialog(props: Props) {
  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  const [asset, setAsset] = useState<ExistingAsset | null>(props.asset);

  const [customerLocations, setCustomerLocations] = useState<
    ExistingCustomerLocation[]
  >([]);

  const [selectedCustomer, setSelectedCustomer] =
    useState<ExistingCustomer | null>(null);
  const [selectedCustomerLocation, setSelectedCustomerLocation] =
    useState<ExistingCustomerLocation | null>(null);

  const [displayError, setDisplayError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("Unkown Error");
  const [dialogStates, setDialogStates] = useState<Record<string, boolean>>({
    selectCustomerOpen: false,
    confirmUnassignedOpen: false,
    confirmDeleteEquipmentOpen: false,
  });

  const siteKeyLocations = useSiteKeyLocationsStore(
    (state) => state.siteKeyLocationList,
  );

  const [selectedSiteKeyLocationID, setSelectedSiteKeyLocationID] = useState<
    string | null
  >(props.locationID ?? props.asset?.locationID ?? null);

  const [pendingSubmission, setPendingSubmission] = useState<any>(null);
  const [confirmationDialogMessage, setConfirmationDialogMessage] =
    useState<React.ReactNode>(null);

  // Get the customer locations for the selected customer
  useEffect(() => {
    function getCustomerLocations() {
      if (!selectedCustomer) return undefined;
      const unsubscribe = DbRead.customerLocations.subscribeByCustomerID({
        siteKey: props.siteKey,
        customerID: selectedCustomer.id,
        onChange: setCustomerLocations,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getCustomerLocations();
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey, selectedCustomer]);

  // Get the customer doc
  useEffect(() => {
    function getCustomer() {
      const customerID = props.customerID ?? props.asset?.customerID;
      if (!customerID) return;
      const unsubscribe = DbRead.customers.subscribe({
        siteKey: props.siteKey,
        customerID: customerID,
        onChange: setSelectedCustomer,
      });
      return unsubscribe;
    }

    const unsubscribeFn = getCustomer();
    return () => unsubscribeFn && unsubscribeFn();
  }, [props.siteKey, props.customerID, props.asset?.customerID]);

  useEffect(() => {
    if (!props.customerLocationID && !props.asset?.customerLocationID)
      return undefined;

    const customerLocationID =
      props.customerLocationID ?? props.asset?.customerLocationID;

    setSelectedCustomerLocation(
      customerLocations.find(
        (location) => location.id === customerLocationID,
      ) ?? null,
    );
  }, [
    props.siteKey,
    props.customerLocationID,
    props.asset?.customerLocationID,
    customerLocations,
  ]);

  const selectCustomerDialog = (
    <CustomerTableDialog
      isOpen={dialogStates.selectCustomerOpen}
      onClose={() => {
        setDialogStates({
          ...dialogStates,
          selectCustomerOpen: false,
        });
      }}
      siteKey={props.siteKey}
      onSelectCustomer={(c: ExistingCustomer) => {
        setSelectedCustomer(c);
        setDialogStates({
          ...dialogStates,
          selectCustomerOpen: false,
        });
      }}
      addCustomerButton={null}
    />
  );

  const newAssetDefaultValues: Pick<
    Asset,
    | "title"
    | "description"
    | "manufacturer"
    | "equipmentType"
    | "serialNumber"
    | "modelNumber"
    | "installationDate"
    | "manufacturerWarrantyStart"
    | "manufacturerWarrantyEnd"
    | "serviceWarrantyStart"
    | "serviceWarrantyEnd"
  > = useMemo(() => {
    return {
      title: asset?.title ?? "",
      description: asset?.description ?? "",
      manufacturer: asset?.manufacturer ?? "",
      equipmentType: asset?.equipmentType ?? "",
      serialNumber: asset?.serialNumber ?? "",
      modelNumber: asset?.modelNumber ?? "",
      installationDate: asset?.installationDate ?? undefined,
      manufacturerWarrantyStart: asset?.manufacturerWarrantyStart ?? undefined,
      manufacturerWarrantyEnd: asset?.manufacturerWarrantyEnd ?? undefined,
      serviceWarrantyStart: asset?.serviceWarrantyStart ?? undefined,
      serviceWarrantyEnd: asset?.serviceWarrantyEnd ?? undefined,
    };
  }, [asset]);

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<
    Pick<
      Asset,
      | "title"
      | "description"
      | "manufacturer"
      | "equipmentType"
      | "serialNumber"
      | "modelNumber"
      | "installationDate"
      | "manufacturerWarrantyStart"
      | "manufacturerWarrantyEnd"
      | "serviceWarrantyStart"
      | "serviceWarrantyEnd"
    >
  >({
    defaultValues: newAssetDefaultValues,
    resolver: zodResolver(NewAssetFormSchema),
    mode: "onChange",
  });

  useEffect(() => {
    setAsset(props.asset);
    reset();
  }, [props.asset, reset]);

  useEffect(() => {
    reset(newAssetDefaultValues);
  }, [newAssetDefaultValues, reset]);

  /* fn that handle all the states that needs to be reset when the section is closed */
  function closeAndReset() {
    reset();
    setDisplayError(false);
    setSelectedCustomer(null);
    setSelectedCustomerLocation(null);
    setSelectedSiteKeyLocationID(null);
    setConfirmationDialogMessage(null);
    setPendingSubmission(null);
    setDialogStates({
      ...dialogStates,
      selectCustomerOpen: false,
      confirmUnassignedOpen: false,
      confirmDeleteEquipmentOpen: false,
    });
    props.closeDialog();
  }

  const errorMessageComponent = (
    <ErrorMessage
      message={errorMessage}
      clearMessage={() => setDisplayError(false)}
    />
  );

  const confirmDeleteEquipmentDialog = asset && (
    <ConfirmDeleteEquipmentDialog
      isOpen={dialogStates.confirmDeleteEquipmentOpen}
      onClose={() =>
        setDialogStates({
          ...dialogStates,
          confirmDeleteEquipmentOpen: false,
        })
      }
      handleConfirmDelete={handleDeleteEquipment}
      isSubmitting={false}
    />
  );

  async function handleDeleteEquipment() {
    if (!asset) return;

    try {
      await DbWrite.assets.delete(props.siteKey, asset.id);
    } catch (error) {
      logger.error(`error while running handleDeleteEquipment: `, error);
      addMessage({
        id: createToastMessageID(),
        message: strings.ERR_DELETE_EQUIPMENT,
        dialog: false,
        type: "error",
      });
    } finally {
      setDialogStates({
        ...dialogStates,
        confirmDeleteEquipmentOpen: false,
      });
      closeAndReset();
    }
  }

  const selectCustomerButton = (
    <BaseButtonSecondary
      type="button"
      onClick={() =>
        setDialogStates({
          ...dialogStates,
          selectCustomerOpen: true,
        })
      }
      className="w-full text-primary xs:w-auto"
    >
      <PersonSharp fontSize="small" className="mr-2" />
      {strings.SELECT_CUSTOMER}
    </BaseButtonSecondary>
  );

  const customerLocationSection = (
    <div className="relative z-20 w-full">
      <h6 className="mb-0 max-w-fit text-base font-semibold text-primary">
        {strings.CUSTOMER_LOCATION}
      </h6>
      {customerLocations === null || customerLocations.length === 0 ? (
        <div>
          No locations to select from, please select a customer or add a
          location for this customer.
        </div>
      ) : (
        <CustomerLocationSection
          allowSelection={true}
          selectedCustomer={selectedCustomer}
          customerLocationOptions={customerLocations}
          selectedCustomerLocation={selectedCustomerLocation}
          setSelectedCustomerLocation={(location) => {
            setSelectedCustomerLocation(location);
          }}
          membershipTemplateList={[]}
          customerLocationMemberships={[]}
        />
      )}
    </div>
  );

  const siteKeyLocationSection = siteKeyLocations.length > 1 && (
    <div className="relative z-50">
      <h6 className="mb-0 max-w-fit text-base font-semibold text-primary">
        {strings.SELECT_BUSINESS_LOCATION}
      </h6>
      <SiteKeyLocationSection
        siteKeyLocationList={siteKeyLocations}
        selectedSiteKeyLocation={selectedSiteKeyLocationID}
        setSelectedSiteKeyLocation={(location) => {
          setSelectedSiteKeyLocationID(location);
        }}
      />
    </div>
  );

  const onSubmit: SubmitHandler<
    Pick<
      Asset,
      | "title"
      | "description"
      | "manufacturer"
      | "equipmentType"
      | "serialNumber"
      | "modelNumber"
      | "installationDate"
      | "manufacturerWarrantyStart"
      | "manufacturerWarrantyEnd"
      | "serviceWarrantyStart"
      | "serviceWarrantyEnd"
    >
  > = async (formValues) => {
    // Use supplied IDs from props first, then selected values if available
    const customerID = selectedCustomer?.id ?? null;
    const customerLocationID = selectedCustomerLocation?.id ?? null;
    const locationID = selectedSiteKeyLocationID ?? null;

    // If no IDs are present, show confirmation dialog
    if (!customerID || !customerLocationID || !locationID) {
      // Build the message based on the missing data
      const missingData = [];
      if (!customerID) missingData.push("Customer");
      if (!customerLocationID) missingData.push("Customer Location");
      if (!locationID) missingData.push("Business Location");

      const message = (
        <div>
          You are about to {asset ? "update" : "create"} an asset without the
          following data:
          <ul className="list-inside list-disc py-4">
            {missingData.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
          Are you sure you want to continue?
        </div>
      );

      setPendingSubmission(formValues);
      setDialogStates({
        ...dialogStates,
        confirmUnassignedOpen: true,
      });
      setConfirmationDialogMessage(message);
      return;
    }

    await handleSubmission(
      formValues,
      customerID,
      customerLocationID,
      locationID,
    );
  };

  // Separate the submission logic
  const handleSubmission = async (
    formValues: NewAssetFormState,
    customerID: string | null,
    customerLocationID: string | null,
    locationID: string | null,
  ) => {
    const assetData: Asset = {
      title: formValues.title,
      description: formValues.description,
      customID: asset?.customID ?? null,
      locationID: locationID,
      latitude: asset?.latitude ?? selectedCustomerLocation?.latitude ?? null,
      longitude: asset?.latitude ?? selectedCustomerLocation?.longitude ?? null,
      qrCode: asset?.qrCode ?? null,
      thumbnailURL: asset?.thumbnailURL ?? null,
      manufacturer: formValues.manufacturer,
      equipmentType: formValues.equipmentType,
      serialNumber: formValues.serialNumber,
      modelNumber: formValues.modelNumber,
      installationDate: formValues.installationDate,
      manufacturerWarrantyStart: formValues.manufacturerWarrantyStart,
      manufacturerWarrantyEnd: formValues.manufacturerWarrantyEnd,
      serviceWarrantyStart: formValues.serviceWarrantyStart,
      serviceWarrantyEnd: formValues.serviceWarrantyEnd,
      customerID: customerID ?? undefined,
      customerLocationID: customerLocationID ?? undefined,
    };

    try {
      if (asset) {
        const validatedData = AssetManager.convertForFirestore(assetData);
        await DbWrite.assets.update(props.siteKey, {
          id: asset.id,
          refPath: asset.refPath,
          ...validatedData,
        });
        addMessage({
          id: createToastMessageID(),
          message: strings.successfulUpdate("Asset/Equipment"),
          dialog: false,
          type: "success",
        });
      } else {
        await DbWrite.assets.add(
          props.siteKey,
          AssetManager.convertForFirestore(assetData),
        );
        addMessage({
          id: createToastMessageID(),
          message: strings.successfulAdd("New Asset/Equipment"),
          dialog: false,
          type: "success",
        });
      }
      props.closeDialog();
    } catch (error) {
      logger.error(`error while submitting asset: `, error);
      setDisplayError(true);
    }
  };

  const newAssetHeader = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white ">
      <h1 className="inline-flex items-center text-xl font-semibold ">
        {asset ? strings.EDIT_ASSET : strings.NEW_ASSET}
      </h1>
      <button type="button" onClick={closeAndReset}>
        <XMarkIcon
          aria-label="close new attribute form"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  return (
    <>
      <BaseModal
        closeModal={() => {}}
        open={props.isDialogOpen}
        title={newAssetHeader}
        parentDivStyles="inline-block transform overflow-hidden max-w-4xl rounded-lg bg-white text-left align-middle shadow-xl transition-all"
      >
        <div className="space-y-4 px-6">
          <form onSubmit={handleSubmit(onSubmit)}>
            {selectedCustomer === null && selectCustomerButton}
            {selectedCustomer && (
              <div className="flex flex-row items-start">
                {selectedCustomer.type === "commercial" ? (
                  <BusinessSharp className="w-9 px-1" />
                ) : (
                  <PersonSharp className="w-9 px-1" />
                )}
                <Link
                  to={`${urls.CUSTOMERS_URL}/${selectedCustomer.id}`}
                  className="text-blue-500 underline"
                >
                  {selectedCustomer.name}
                </Link>
              </div>
            )}

            <div className="mt-4 space-y-4 md:grid md:grid-cols-2 md:gap-4 md:space-y-0">
              {siteKeyLocationSection}
              {customerLocationSection}

              <div>
                <Controller
                  name="title"
                  control={control}
                  render={({ field }) => (
                    <BaseInputText
                      text="Title"
                      inputName="title"
                      admin={true}
                      required={false}
                      {...field}
                      value={field.value === null ? "" : field.value}
                    />
                  )}
                />
                {errors.title?.message && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: errors.title.message }}
                    </StyledMessage>
                  </div>
                )}
              </div>

              <div>
                <Controller
                  name="equipmentType"
                  control={control}
                  render={({ field }) => (
                    <BaseInputText
                      text="Equipment Type"
                      inputName="equipmentType"
                      admin={true}
                      required={false}
                      {...field}
                      value={field.value === null ? "" : field.value}
                    />
                  )}
                />
              </div>

              <div className="col-span-2">
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <BaseInputText
                      text="Description"
                      inputName="description"
                      admin={true}
                      required={false}
                      {...field}
                      value={field.value === null ? "" : field.value}
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  name="manufacturer"
                  control={control}
                  render={({ field }) => (
                    <BaseInputText
                      text="Manufacturer"
                      inputName="manufacturer"
                      admin={true}
                      required={false}
                      {...field}
                      value={field.value === null ? "" : field.value}
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  name="modelNumber"
                  control={control}
                  render={({ field }) => (
                    <BaseInputText
                      text="Model Number"
                      inputName="modelNumber"
                      admin={true}
                      required={false}
                      {...field}
                      value={field.value === null ? "" : field.value}
                    />
                  )}
                />
              </div>

              <div>
                <Controller
                  name="serialNumber"
                  control={control}
                  render={({ field }) => (
                    <BaseInputText
                      text="Serial Number"
                      inputName="serialNumber"
                      admin={true}
                      required={false}
                      {...field}
                      value={field.value === null ? "" : field.value}
                    />
                  )}
                />
              </div>
            </div>

            {/* Date Fields */}
            <div className="grid grid-cols-1 gap-6 pt-4 md:grid-cols-2 md:gap-y-6">
              <div className="col-span-1 mb-0 md:col-span-2 md:mb-2">
                <Controller
                  key="installationDate"
                  name="installationDate"
                  control={control}
                  render={({ field }) => (
                    <BaseInputDate
                      inputName="installationDate"
                      displayText="Installation Date"
                      addWrapperStyles="mt-2"
                      overrideWidth="w-48 lg:w-52"
                      value={field.value?.toDate().toISOString() ?? null}
                      onChange={(date) => {
                        if (date) field.onChange(Timestamp.fromDate(date));
                      }}
                    />
                  )}
                />
              </div>

              <Controller
                key="manufacturerWarrantyStart"
                name="manufacturerWarrantyStart"
                control={control}
                render={({ field }) => (
                  <BaseInputDate
                    inputName="manufacturerWarrantyStart"
                    displayText="Manufacturer Warranty Start"
                    addWrapperStyles="mt-2"
                    overrideWidth="w-48 lg:w-52"
                    value={field.value?.toDate().toISOString() ?? null}
                    onChange={(date) => {
                      if (date) field.onChange(Timestamp.fromDate(date));
                    }}
                  />
                )}
              />

              <Controller
                key="manufacturerWarrantyEnd"
                name="manufacturerWarrantyEnd"
                control={control}
                render={({ field }) => (
                  <BaseInputDate
                    inputName="manufacturerWarrantyEnd"
                    displayText="Manufacturer Warranty End"
                    addWrapperStyles="mt-2"
                    overrideWidth="w-48 lg:w-52"
                    value={field.value?.toDate().toISOString() ?? null}
                    onChange={(date) => {
                      if (date) field.onChange(Timestamp.fromDate(date));
                    }}
                  />
                )}
              />

              <Controller
                key="serviceWarrantyStart"
                name="serviceWarrantyStart"
                control={control}
                render={({ field }) => (
                  <BaseInputDate
                    inputName="serviceWarrantyStart"
                    displayText="Service Warranty Start"
                    addWrapperStyles="mt-2"
                    overrideWidth="w-48 lg:w-52"
                    value={field.value?.toDate().toISOString() ?? null}
                    onChange={(date) => {
                      if (date) field.onChange(Timestamp.fromDate(date));
                    }}
                  />
                )}
              />

              <Controller
                key="serviceWarrantyEnd"
                name="serviceWarrantyEnd"
                control={control}
                render={({ field }) => (
                  <BaseInputDate
                    inputName="serviceWarrantyEnd"
                    displayText="Service Warranty End"
                    addWrapperStyles="mt-2"
                    overrideWidth="w-48 lg:w-52"
                    value={field.value?.toDate().toISOString() ?? null}
                    onChange={(date) => {
                      if (date) field.onChange(Timestamp.fromDate(date));
                    }}
                  />
                )}
              />
            </div>

            <div className="mt-4 flex flex-col items-end">
              {displayError ? errorMessageComponent : null}
            </div>

            {/* Action Buttons */}
            <div className="flex w-full flex-col items-center justify-end gap-4 pb-8 xs:flex-row">
              <BaseButtonSecondary
                type="button"
                className="w-full justify-center uppercase xs:w-auto"
                onClick={closeAndReset}
              >
                {strings.buttons.CANCEL}
              </BaseButtonSecondary>

              {asset && (
                <ButtonColored
                  type="button"
                  onClick={() =>
                    setDialogStates({
                      ...dialogStates,
                      confirmDeleteEquipmentOpen: true,
                    })
                  }
                  busyText={strings.buttons.BUSY_SAVING}
                  className="w-full justify-center uppercase xs:w-auto"
                  kind="danger"
                >
                  {strings.buttons.DELETE_EQUIPMENT}
                </ButtonColored>
              )}

              <BaseButtonPrimary
                type="submit"
                formNoValidate
                disabled={props.isSubmitting}
                isBusy={props.isSubmitting}
                busyText={strings.buttons.BUSY_SAVING}
                className="w-full justify-center uppercase xs:w-auto"
                data-testid="save item"
              >
                {strings.buttons.SAVE}
              </BaseButtonPrimary>
            </div>
          </form>
        </div>
      </BaseModal>
      {selectCustomerDialog}
      {confirmDeleteEquipmentDialog}
      <ConfirmationDialog
        body={confirmationDialogMessage}
        title="Confirm Missing Data"
        isOpen={dialogStates.confirmUnassignedOpen}
        onClose={() =>
          setDialogStates({
            ...dialogStates,
            confirmUnassignedOpen: false,
          })
        }
        isSubmitting={false}
        handleConfirmAction={async () => {
          if (pendingSubmission) {
            // Keep any IDs that were already populated
            const customerID = props.customerID ?? selectedCustomer?.id ?? null;
            const customerLocationID =
              props.customerLocationID ?? selectedCustomerLocation?.id ?? null;
            const locationID =
              props.locationID ?? selectedSiteKeyLocationID ?? null;

            await handleSubmission(
              pendingSubmission,
              customerID,
              customerLocationID,
              locationID,
            );
            setPendingSubmission(null);
            setDialogStates({
              ...dialogStates,
              confirmUnassignedOpen: false,
            });
          }
        }}
      />
    </>
  );
}
