//Libs
import { useEffect, useMemo, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { deleteField, Timestamp } from "firebase/firestore";
import isEqual from "lodash/isEqual";

//Local
import * as strings from "../../strings";
import BaseModal from "../BaseModal";
import { ExistingCustomer } from "../../models/customer";
import { ExistingCustomerLocation } from "../../models/customer-location";
import { CustomerDetails } from "../estimates/CustomerDetails";
import { CustomerLocationSection } from "../customers/CreateTask";
import { DbRead, DbWrite } from "../../database";
import {
  ExistingMembership,
  InvoiceOverrideData,
  isMembershipStatus,
  Membership,
  MembershipManager,
  MembershipStatus,
} from "../../models/membership";
import { useAuthStore } from "../../store/firebase-auth";
import { ExistingMembershipTemplate } from "../../models/membership-template";
import AssetEquipmentListCheckboxes from "./AssetEquipmentListCheckboxes";
import { ExistingAsset } from "../../models/asset";
import { logger } from "../../logging";
import { useSiteKeyLocationsStore } from "../../store/site-key-locations";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID, guardIsPlainObject } from "../../utils";
import { diffObjects } from "../../assets/js/object-diff";
import { ExistingSiteKeyLocation } from "../../models/site-key-location";
import { AddEditMembershipForm } from "./AddEditMembershipForm";
import BaseInputSelect from "../BaseInputSelect";
import StyledMessage from "../StyledMessage";
import { MembershipFormState, MembershipFormSchema } from "./formTypes";

interface Props {
  isDialogOpen: boolean;
  closeDialog: () => void;

  siteKey: string;
  assetsEnabled: boolean;
  customer: ExistingCustomer;
  customerLocation: ExistingCustomerLocation | null;
  membership: ExistingMembership;
  template: ExistingMembershipTemplate;
  currency: string;
}

export default function EditMembershipDialog(props: Props) {
  const addMessage = useToastMessageStore((state) => state.addToastMessage);

  const firebaseUser = useAuthStore((state) => state.firebaseUser);

  const [siteKeyLocationList, isLoadingSKLocs] = useSiteKeyLocationsStore(
    (state) => [state.siteKeyLocationList, state.loading],
  );

  const [cusLocOptions, setCusLocOptions] = useState<
    ExistingCustomerLocation[]
  >([]);
  const [selectedCusLoc, setSelectedCusLoc] =
    useState<ExistingCustomerLocation | null>(props.customerLocation);

  const [assetOptions, setAssetOptions] = useState<ExistingAsset[]>([]);
  const [assetIDs, setAssetIDs] = useState<string[] | undefined>(
    props.membership.assetIDs ?? [],
  );

  // this is only used for site location (technically that's not true.. shh it's fine)
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedSiteKeyLoc, setSelectedSiteKeyLoc] =
    useState<ExistingSiteKeyLocation | null>(null);

  const defaultValues = useMemo(
    (): MembershipFormState => ({
      lastCompletedTaskDate: props.membership.lastCompletedTaskDate,
      lastPaymentAmount: props.membership.lastPaymentAmount,
      lastPaymentDate: props.membership.lastPaymentDate,
      membershipEndDate: props.membership.membershipEndDate,
      membershipStartDate: props.membership.membershipStartDate,
      nextInvoiceDate: props.membership.nextInvoiceDate,
      nextScheduledTaskDate: props.membership.nextScheduledTaskDate,
      notes: props.membership.notes,
      status: isMembershipStatus(props.membership.status)
        ? props.membership.status
        : MembershipStatus.Draft,
      automaticallyGenerateTasks:
        typeof props.membership.automaticallyGenerateTasks === "boolean"
          ? props.membership.automaticallyGenerateTasks
          : (props.template.automaticallyGenerateTasks ?? false),
      automaticallySendReceipt:
        props.membership.automaticallySendReceipt ?? false,
      automaticallySendInvoice:
        typeof props.membership.automaticallySendInvoice === "boolean"
          ? props.membership.automaticallySendInvoice
          : (props.template.automaticallySendInvoice ?? false),
      automaticallyPayInvoice:
        typeof props.membership.automaticallyPayInvoice === "boolean"
          ? props.membership.automaticallyPayInvoice
          : (props.template.automaticallyPayInvoice ?? false),
      customerLocationID: props.membership.customerLocationID,
      overrideEmail: props.membership.overrideData?.email,
      overrideInvoice: props.membership.overrideData?.invoice,

      // these two are not editable, but in order to use the same form schema as
      // CreateMembershipDialog uses, we need to include them here:
      membershipTemplateID: props.membership.membershipTemplateID,
      customerID: props.membership.customerID,
      // also not editable
      formType: "edit",
    }),
    [props.membership, props.template],
  );

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    handleSubmit,
    setValue,
    watch,
  } = useForm<MembershipFormState>({
    defaultValues: defaultValues,
    resolver: zodResolver(MembershipFormSchema),
    mode: "onChange",
  });

  const watchTaskGenToggle = watch("automaticallyGenerateTasks");
  const watchAutoPayToggle = watch("automaticallyPayInvoice");
  const watchEmailInvoiceToggle = watch("automaticallySendInvoice");
  const watchEmailReceiptToggle = watch("automaticallySendReceipt");
  const watchStartDate = watch("membershipStartDate");
  const watchEndDate = watch("membershipEndDate");
  const watchNextInvoiceDate = watch("nextInvoiceDate");
  const watchOverrideEmail = watch("overrideEmail");
  const watchOverrideInvoice = watch("overrideInvoice");

  useEffect(() => {
    if (isLoadingSKLocs) return;
    setSelectedSiteKeyLoc(
      siteKeyLocationList.find(
        (loc) => loc.id === props.membership.locationID,
      ) ?? null,
    );
  }, [isLoadingSKLocs, props.membership.locationID, siteKeyLocationList]);

  useEffect(() => {
    function getAssetsForCustomerLocation() {
      if (!selectedCusLoc) return undefined;
      const unsubscribeFn = DbRead.assets.subscribeByCustomerLocationID({
        siteKey: props.siteKey,
        customerLocationID: selectedCusLoc.id,
        onChange: setAssetOptions,
        onError: logger.error,
      });
      return unsubscribeFn;
    }
    const unsubscribe = getAssetsForCustomerLocation();
    return () => unsubscribe && unsubscribe();
  }, [props.siteKey, selectedCusLoc]);

  useEffect(() => {
    function getCusLocOptions() {
      const unsubscribeFn = DbRead.customerLocations.subscribeByCustomerID({
        siteKey: props.siteKey,
        customerID: props.customer.id,
        onChange: setCusLocOptions,
        onError: logger.error,
      });
      return unsubscribeFn;
    }
    const unsubscribe = getCusLocOptions();
    return () => unsubscribe && unsubscribe();
  }, [props.customer.id, props.siteKey]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  function closeAndReset() {
    setErrorMessage(null);
    reset();
    props.closeDialog();
  }

  const customerLocationSection = (
    <CustomerLocationSection
      allowSelection={true}
      selectedCustomer={props.customer}
      customerLocationOptions={cusLocOptions}
      selectedCustomerLocation={selectedCusLoc}
      setSelectedCustomerLocation={(location) => {
        setSelectedCusLoc(location);
        setValue("customerLocationID", location?.id ?? null);
      }}
      openEditExistingCustomerLocation={undefined}
      membershipTemplateList={[]}
      customerLocationMemberships={[]}
      useBaseInputSelect
    />
  );

  const onSubmit: SubmitHandler<MembershipFormState> = async (formValues) => {
    logger.info("EditMembershipDialog onSubmit values", formValues);
    if (!firebaseUser) {
      throw Error("EditMembershipDialog onSubmit: firebaseUser is null");
    }
    if (!formValues.customerLocationID) {
      logger.error(`EditMembershipDialog onSubmit: missing customerLocationID`);
      // because of the zod refine we're using on MembershipFormSchema, this situation can't happen.
      // so having the error message appear under the site location select element is good enough
      setErrorMessage("Select a customer location");
      return;
    }
    if (!selectedSiteKeyLoc) {
      logger.error(`EditMembershipDialog onSubmit: missing locationID`);
      setErrorMessage("Select a site location");
      return;
    }
    // convert defaultValues and formValues to the format expected for the firestore update.
    const {
      overrideEmail: formEmail,
      overrideInvoice: formInvoice,
      ...formRest
    } = formValues;
    const {
      overrideEmail: ogEmail,
      overrideInvoice: ogInvoice,
      ...ogRest
    } = defaultValues;
    // overrideData needs some special attention for when values need to be
    // removed from the DB. essentially, if diffObjects gives us a key where
    // the value is undefined, we'll need to set that key to deleteField().
    const formOverrideData = {
      overrideData: {
        email: formEmail,
        invoice: formInvoice,
      },
    };
    const originalOverrideData = {
      overrideData: {
        email: ogEmail,
        invoice: ogInvoice,
      },
    };
    const theFormValues = { ...formRest, ...formOverrideData };
    const theDefaultValues = { ...ogRest, ...originalOverrideData };

    const diff = diffObjects(theDefaultValues, theFormValues).diff;
    logger.info("EditMembershipDialog onSubmit diff", diff);

    // don't sleep on the assetIDs and locationID
    const changedAssets =
      assetOptions.length && !isEqual(assetIDs, props.membership.assetIDs);
    const changedLocation =
      selectedSiteKeyLoc.id !== props.membership.locationID;

    if (Object.keys(diff).length === 0 && !changedAssets && !changedLocation) {
      return;
    }
    const update: Record<string, unknown> = {
      ...diff,
      lastModifiedBy: firebaseUser.uid,
      timestampLastModified: Timestamp.now(),
    };
    if (changedAssets) update.assetIDs = assetIDs;
    if (changedLocation) update.locationID = selectedSiteKeyLoc.id;

    try {
      const validUpdate = MembershipManager.parsePartial(update);
      // this needs to be done after parsePartial, otherwise we risk validation failing
      // when it "shouldn't" -- the `deleteField()` type isn't allowed in our zod schema
      const mergeOverrideUpdate = getOverrideDataUpdate(
        originalOverrideData.overrideData,
        formOverrideData.overrideData,
      );
      if (validUpdate.overrideData) {
        validUpdate.overrideData = {
          ...validUpdate.overrideData,
          ...mergeOverrideUpdate.overrideData,
        };
      } else if (mergeOverrideUpdate.overrideData) {
        validUpdate.overrideData = mergeOverrideUpdate.overrideData;
      }
      logger.info("EditMembershipDialog DB update", validUpdate);
      await DbWrite.memberships.update(
        props.siteKey,
        props.membership.id,
        MembershipManager.convertForFirestore(
          validUpdate as Partial<Membership>, // as much as i hate doing this, gotta. deepPartial usage gazzed it.
        ),
      );
      addMessage({
        id: createToastMessageID(),
        message: strings.successfulUpdate("Membership"),
        dialog: false,
        type: "success",
      });
      closeAndReset();
    } catch (e) {
      logger.error(e);
      addMessage({
        id: createToastMessageID(),
        message: strings.failedUpdate("membership"),
        dialog: true,
        type: "error",
      });
    }
  };

  const customerSection = (
    <div className="-mt-4 md:mt-0 md:min-w-48 md:max-w-96">
      <CustomerDetails
        customer={props.customer}
        openEditCustomerDialog={null}
        openCallDialog={null}
        shouldConstrainWidth={false}
      />
    </div>
  );

  const header = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white">
      <h1 className="inline-flex items-center text-xl font-semibold">
        {strings.EDIT_MEMBERSHIP}
      </h1>
      <button type="button" onClick={closeAndReset}>
        <XMarkIcon
          aria-label="close edit membership dialog"
          className="h-6 text-white"
        />
      </button>
    </div>
  );

  const assetSection = props.assetsEnabled && assetOptions.length > 0 && (
    // Allow user to specify one or more assets that might apply to this membership
    <>
      <h6 className="text-base font-semibold text-primary">
        {strings.SELECT_ASSET}:
      </h6>
      <AssetEquipmentListCheckboxes
        assetsForCustomer={assetOptions}
        assetIDs={assetIDs}
        setAssetIDs={setAssetIDs}
      />
    </>
  );

  const selectSiteLocationElement = siteKeyLocationList.length > 1 && (
    <div>
      <BaseInputSelect
        id="locationID"
        inputName="locationID"
        text="Site Location"
        admin
        required
        value={selectedSiteKeyLoc?.id ?? ""}
        onChange={(event) => {
          const loc = siteKeyLocationList.find(
            (l) => l.id === event.target.value,
          );
          setSelectedSiteKeyLoc(loc ?? null);
        }}
      >
        <option value="" disabled>
          Select Site Location
        </option>

        {siteKeyLocationList.map((skl) => (
          <option key={skl.id} value={skl.id}>
            {skl.title}
          </option>
        ))}
      </BaseInputSelect>
      {errorMessage && (
        <div className="mt-2 text-sm">
          <StyledMessage type="error">
            {{ message: errorMessage }}
          </StyledMessage>
        </div>
      )}
    </div>
  );

  return (
    <BaseModal
      closeModal={() => {}} // prevent unintentionally closing dialog
      open={props.isDialogOpen}
      title={header}
      allowOverflowY={true}
      parentDivStyles="inline-block transform overflow-hidden max-w-4xl rounded-lg bg-white text-left align-middle shadow-xl transition-all"
    >
      <AddEditMembershipForm
        formType="edit"
        siteKey={props.siteKey}
        rhfHandleSubmit={handleSubmit}
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        closeAndReset={closeAndReset}
        control={control}
        errors={errors}
        template={props.template}
        setValue={setValue}
        currency={props.currency}
        customer={props.customer}
        customerTaxRate={selectedCusLoc?.totalTaxRate ?? 0}
        locationID={selectedSiteKeyLoc?.id ?? null}
        watchedFields={{
          autoGenerateTasks: watchTaskGenToggle,
          autoSendReceipt: watchEmailReceiptToggle,
          autoEmailInvoice: watchEmailInvoiceToggle,
          autoPayInvoice: watchAutoPayToggle,
          membershipStartDate: watchStartDate,
          membershipEndDate: watchEndDate,
          nextInvoiceDate: watchNextInvoiceDate,
          overrideEmail: watchOverrideEmail,
          overrideInvoice: watchOverrideInvoice,
        }}
      >
        {{
          customerSection: customerSection,
          customerLocationSection: customerLocationSection,
          selectTemplateElement: null,
          selectSiteLocationElement: selectSiteLocationElement,
          assetSection: props.assetsEnabled ? assetSection : null,
        }}
      </AddEditMembershipForm>
    </BaseModal>
  );
}

/**
 * deals only with the "edge cases" of when something needs to be removed from the DB.
 * should be merged with onSubmit's "normal" overrideData update.
 */
function getOverrideDataUpdate(
  originalOverride: { invoice?: InvoiceOverrideData; email?: string },
  updatedOverride: { invoice?: InvoiceOverrideData; email?: string },
): { overrideData: Record<string, unknown> } {
  const update: Record<string, any> = {};

  if (
    typeof originalOverride?.email === "string" &&
    updatedOverride?.email === undefined
  ) {
    update.email = deleteField();
  }

  if (
    guardIsPlainObject(originalOverride?.invoice) &&
    updatedOverride?.invoice === undefined
  ) {
    update.invoice = deleteField();
  } else {
    if (
      typeof originalOverride?.invoice?.totalTaxAmountGST === "number" &&
      updatedOverride?.invoice?.totalTaxAmountGST === undefined
    ) {
      if (!update.invoice) update.invoice = {};
      update.invoice.totalTaxAmountGST = deleteField();
    }
    if (
      typeof originalOverride?.invoice?.totalTaxAmountPST === "number" &&
      updatedOverride?.invoice?.totalTaxAmountPST === undefined
    ) {
      if (!update.invoice) update.invoice = {};
      update.invoice.totalTaxAmountPST = deleteField();
    }
  }
  return { overrideData: update };
}
