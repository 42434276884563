//Libs
import { DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

//Local
import { NotFoundError } from "../error-classes";
import { zFallback } from "../utils/zod-fallback";
import { guardIsPlainObject } from "../utils";
import {
  AccountingSyncStiltDoc,
  AccountingSyncStiltDocSchema,
  AccountingSyncStiltDocSchemaWithFallbacks,
} from "./accounting-sync";

export const priceBookItemTypeValues = [
  "service",
  "material",
  "equipment",
  "labor",
  "other",
] as const;
// Create the types from the TS array 'priceBookItemTypeValues'.
export type PriceBookItemTypes = (typeof priceBookItemTypeValues)[number];

export interface PriceBookItem {
  title: string;
  description: string | null;
  units: string;
  unitPrice: number; //float
  cost: number | null; //float
  locationID: string | null;
  discountableForMemberships: boolean;
  type: PriceBookItemTypes | null;
  tags: string[];
  customData: { [key: string]: any };
  taxable: boolean;
  discountable: boolean;
  editable: boolean;
  timestampCreated: Timestamp;
  timestampLastModified: Timestamp;
  createdBy: string;
  lastModifiedBy: string;
  deleted: boolean;
  accountingSync?: AccountingSyncStiltDoc;
  categoryID?: string;
  commissions?: { [key: string]: any };
  isInventoryObject?: boolean;
  inventoryObject?: { [key: string]: any };
}

export interface ExistingPriceBookItem extends PriceBookItem {
  id: string;
  refPath: string;
}

/***********************/
// FOR THE API
/***********************/
type withoutTimestamps = Omit<
  PriceBookItem,
  "timestampCreated" | "timestampLastModified"
>;

// Timestamps are added on the backend.
export interface PBItem_CreateAPI extends withoutTimestamps {
  siteKey: string;
}

// timestampLastModified is added on the backend. (timestampCreated won't change)
export type PBItem_UpdateAPI = Omit<
  Partial<ExistingPriceBookItem>,
  "timestampCreated" | "timestampLastModified"
>;

export type AddNewPBItem = Omit<
  PriceBookItem,
  "timestampCreated" | "timestampLastModified" | "createdBy" | "lastModifiedBy"
>;

// #region SECTION: Functions
/** Utilities for interacting with PriceBookItem objects  */
export const PriceBookItemManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingPriceBookItem object.
   */
  createFromFirestoreSnapshot: createPriceBookItemFromFirestoreSnapshot,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validatePriceBookItem,
  /** Validate a PriceBookItem doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validatePriceBookItemWithFallbacks,
  /** Validate a new price book item. For the create endpoint. */
  parseCreate: validatePBItem_Create,
  /** Validate an existing price book item. For the update endpoint. */
  parseUpdate: validatePBItem_Update,
  fromTypesense: fromTypesense,
};

/**
 * Convert the Document Snapshot into a validated ExistingPriceBookItem object.
 */
function createPriceBookItemFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingPriceBookItem {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validatePriceBookItemWithFallbacks(snapshotData),
  };
}

function fromTypesense(hit: any): ExistingPriceBookItem {
  return {
    id: hit.document.id,
    refPath: `siteKeys/${hit.document.siteKey}/priceBookItems/${hit.document.id}`,
    cost: hit.document.cost ?? null,
    createdBy: hit.document.createdBy,
    customData: hit.document.customData,
    deleted: hit.document.deleted,
    description: hit.document.description ?? null,
    discountable: hit.document.discountable,
    discountableForMemberships: hit.document.discountableForMemberships,
    editable: hit.document.editable,
    lastModifiedBy: hit.document.lastModifiedBy,
    locationID: hit.document.locationID ?? null,
    tags: hit.document.tags,
    taxable: hit.document.taxable,
    timestampCreated: hit.document.timestampCreated,
    timestampLastModified: hit.document.timestampLastModified,
    title: hit.document.title,
    type: hit.document.type ?? null,
    unitPrice: hit.document.unitPrice,
    units: hit.document.units,
    categoryID: hit.document.categoryID,
    commissions: hit.document.commissions,
    inventoryObject: hit.document.inventoryObject,
    isInventoryObject: hit.document.isInventoryObject,
  };
}

/* Zod validation schemas */
function validatePriceBookItemWithFallbacks(value: unknown): PriceBookItem {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = priceBookItemSchemaWithFallbacks.parse(value);
  return result;
}

function validatePriceBookItem(value: unknown): PriceBookItem {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = priceBookItemSchema.parse(value);
  return result;
}

function validatePBItem_Create(value: unknown): PBItem_CreateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return pbItemSchema_CreateAPI.parse(value);
}

function validatePBItem_Update(value: unknown): PBItem_UpdateAPI {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  return pbItemSchema_UpdateAPI.parse(value);
}

// #endregion

// #region SECTION: Schemas
// Used when validating data coming from the database.
const priceBookItemSchemaWithFallbacks = z.object({
  title: zFallback(
    z.string().min(1).max(500),
    "unknown",
    "priceBookItemSchemaWithFallbacks: 'title'",
  ),
  description: zFallback(
    z.string().max(4000).nullable(),
    null,
    "priceBookItemSchemaWithFallbacks: 'description'",
  ),
  units: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "priceBookItemSchemaWithFallbacks: 'units'",
  ),
  unitPrice: z.number(), //no fallback because the price must be present
  cost: zFallback(
    z.number().nullable(),
    null,
    "priceBookItemSchemaWithFallbacks: 'cost'",
  ),
  locationID: zFallback(
    z.string().min(1).max(200).nullable(),
    "unknown",
    "priceBookItemSchemaWithFallbacks: 'locationID'",
  ),
  discountableForMemberships: zFallback(
    z.boolean(),
    false,
    "priceBookItemSchemaWithFallbacks: 'discountableForMemberships'",
  ),
  type: zFallback(
    z.enum(priceBookItemTypeValues).nullable(),
    null,
    "priceBookItemSchemaWithFallbacks: 'type'",
  ),
  tags: zFallback(
    z.array(z.string().min(1).max(200)),
    [],
    "priceBookItemSchemaWithFallbacks: 'tags'",
  ),
  customData: zFallback(
    z.record(z.any()),
    {},
    "priceBookItemSchemaWithFallbacks: 'customData'",
  ),
  taxable: zFallback(
    z.boolean(),
    false,
    "priceBookItemSchemaWithFallbacks: 'taxable'",
  ),
  editable: zFallback(
    z.boolean(),
    false,
    "priceBookItemSchemaWithFallbacks: 'editable'",
  ),
  discountable: zFallback(
    z.boolean(),
    false,
    "priceBookItemSchemaWithFallbacks: 'discountable'",
  ),
  timestampCreated: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "priceBookItemSchemaWithFallbacks: 'timestampCreated'",
  ),
  timestampLastModified: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "priceBookItemSchemaWithFallbacks: 'timestampLastModified'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  createdBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "priceBookItemSchemaWithFallbacks: 'createdBy'",
  ),
  // NOTE: if this causes problems, remove the fallback.
  lastModifiedBy: zFallback(
    z.string().min(1).max(200),
    "unknown",
    "priceBookItemSchemaWithFallbacks: 'lastModifiedBy'",
  ),
  deleted: zFallback(
    z.boolean(),
    false,
    "priceBookItemSchemaWithFallbacks: 'deleted'",
  ),
  accountingSync: AccountingSyncStiltDocSchemaWithFallbacks.optional(),
  categoryID: z.string().min(1).max(200).optional(),
  commissions: z.record(z.any()).optional(),
  isInventoryObject: z.boolean().optional(),
  inventoryObject: z.record(z.any()).optional(),
});

// Used when writing to the DB or reading from the user.
const priceBookItemSchema = z.object({
  title: z.string().min(1).max(500),
  description: z.string().max(2000).nullable(),
  units: z.string().min(1).max(200),
  unitPrice: z.number(),
  cost: z.number().nullable(),
  locationID: z.string().min(1).max(200).nullable(),
  discountableForMemberships: z.boolean(),
  type: z.enum(priceBookItemTypeValues).nullable(),
  tags: z.array(z.string().min(1).max(200)),
  customData: z.record(z.any()),
  taxable: z.boolean(),
  editable: z.boolean(),
  discountable: z.boolean(),
  timestampCreated: z.instanceof(Timestamp),
  timestampLastModified: z.instanceof(Timestamp),
  createdBy: z.string().min(1).max(200),
  lastModifiedBy: z.string().min(1).max(200),
  deleted: z.boolean(),
  accountingSync: AccountingSyncStiltDocSchema.optional(),
  categoryID: z.string().min(1).max(200).optional(),
  commissions: z.record(z.any()).optional(),
  isInventoryObject: z.boolean().optional(),
  inventoryObject: z.record(z.any()).optional(),
});

/***********************/
// FOR THE API
/***********************/
const withoutTimestampsSchema = priceBookItemSchema.omit({
  timestampCreated: true,
  timestampLastModified: true,
});

// Used for interacting with the Create endpoint.
const pbItemSchema_CreateAPI = withoutTimestampsSchema.extend({
  siteKey: z.string().min(1).max(400),
});

// Used for interacting with the Update endpoint
const pbItemSchema_UpdateAPI = withoutTimestampsSchema
  .extend({
    id: z.string().min(1).max(200),
    refPath: z.string().min(1).max(400),
  })
  .partial();
// #endregion
