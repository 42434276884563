import { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import BaseModal from "../BaseModal";
import BaseButtonPrimary from "../BaseButtonPrimary";
import BaseButtonSecondary from "../BaseButtonSecondary";
import StyledMessage from "../StyledMessage";
import { DbRead } from "../../database";
import { logger } from "../../logging";
import { phoneUtils } from "../../utils";
import { ExistingSitePhoneNumber } from "../../models/phone-numbers";
import PurchaseNumberDialog from "../phones/PurchaseNumberDialog";

interface Props {
  open: boolean;
  onClose: () => void;
  onSelect: (
    phoneNumberId: string,
    phoneNumber: string,
    description: string,
  ) => void;
  siteKey: string;
}

export default function SelectPhoneNumberDialog({
  open,
  onClose,
  onSelect,
  siteKey,
}: Props) {
  const [phoneNumbers, setPhoneNumbers] = useState<ExistingSitePhoneNumber[]>(
    [],
  );
  const [selectedNumberId, setSelectedNumberId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isPurchaseDialogOpen, setIsPurchaseDialogOpen] = useState(false);

  useEffect(() => {
    const fetchPhoneNumbers = async () => {
      setIsLoading(true);
      setErrorMessage(null);
      try {
        const numbers = await DbRead.phones.listNumbers(siteKey);
        setPhoneNumbers(numbers);
      } catch (error) {
        logger.error(error);
        setErrorMessage("Failed to load phone numbers");
      } finally {
        setIsLoading(false);
      }
    };

    if (open) {
      fetchPhoneNumbers();
    }
  }, [siteKey, open]);

  const handleSelect = () => {
    if (!selectedNumberId) {
      setErrorMessage("Please select a phone number");
      return;
    }

    const selectedNumber = phoneNumbers.find((n) => n.id === selectedNumberId);
    if (selectedNumber) {
      onSelect(
        selectedNumberId,
        selectedNumber.phoneNumber,
        selectedNumber.description,
      );
    }
  };

  const handlePurchaseComplete = async () => {
    setIsPurchaseDialogOpen(false);

    // Refresh the phone numbers list
    try {
      const numbers = await DbRead.phones.listNumbers(siteKey);
      setPhoneNumbers(numbers);
    } catch (error) {
      logger.error(error);
      setErrorMessage("Failed to refresh phone numbers");
    }
  };

  const header = (
    <div className="mb-4 flex w-full items-center justify-between rounded-t-lg bg-primary p-8 text-left text-white">
      <h1 className="text-xl font-semibold">Select Phone Number for SMS</h1>
      <button type="button" onClick={onClose}>
        <XMarkIcon className="h-6 text-white" />
      </button>
    </div>
  );

  return (
    <BaseModal
      closeModal={onClose}
      open={open}
      title={header}
      parentDivStyles="inline-block transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl"
    >
      <div className="px-4 pb-4 pt-5 sm:p-6">
        <div className="space-y-6">
          <p className="text-sm text-gray-600">
            Select an existing phone number to use for SMS messaging or purchase
            a new one. The phone number you select will be used for all SMS
            communications with your customers.
          </p>

          {errorMessage && (
            <StyledMessage type="error">
              {{ message: errorMessage }}
            </StyledMessage>
          )}

          {isLoading ? (
            <div className="py-4 text-center">Loading phone numbers...</div>
          ) : phoneNumbers.length === 0 ? (
            <div className="rounded-lg border border-gray-300 p-4 text-center">
              <p className="mb-4">You don't have any phone numbers yet.</p>
              <BaseButtonPrimary
                type="button"
                onClick={() => setIsPurchaseDialogOpen(true)}
              >
                Purchase a New Number
              </BaseButtonPrimary>
            </div>
          ) : (
            <div className="space-y-4">
              <div className="max-h-80 overflow-y-auto rounded-lg border border-gray-300">
                <div className="divide-y divide-gray-300">
                  {phoneNumbers.map((number) => (
                    <div
                      key={number.id}
                      className={`flex cursor-pointer items-center justify-between p-4 hover:bg-gray-50 ${
                        selectedNumberId === number.id ? "bg-blue-50" : ""
                      }`}
                      onClick={() => setSelectedNumberId(number.id)}
                    >
                      <div>
                        <div className="font-medium">
                          {phoneUtils.display(number.phoneNumber)}
                        </div>
                        {number.description && (
                          <div className="text-sm text-gray-600">
                            {number.description}
                          </div>
                        )}
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="phoneNumber"
                          checked={selectedNumberId === number.id}
                          onChange={() => setSelectedNumberId(number.id)}
                          className="h-4 w-4 text-blue-600"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex items-center justify-between">
                <BaseButtonSecondary
                  type="button"
                  onClick={() => setIsPurchaseDialogOpen(true)}
                >
                  Purchase a New Number
                </BaseButtonSecondary>
                <BaseButtonPrimary
                  type="button"
                  onClick={handleSelect}
                  disabled={!selectedNumberId}
                >
                  Select Number
                </BaseButtonPrimary>
              </div>
            </div>
          )}
        </div>
      </div>

      {isPurchaseDialogOpen && (
        <PurchaseNumberDialog
          open={isPurchaseDialogOpen}
          onClose={() => setIsPurchaseDialogOpen(false)}
          onPurchaseComplete={handlePurchaseComplete}
          siteKey={siteKey}
        />
      )}
    </BaseModal>
  );
}
