import React, { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { ChatBubble, Close } from "@mui/icons-material";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { useActiveChatRoomStore } from "../../store/active-chat-room";
import { useUnreadMessageCountsStore } from "../../store/unread-message-counts";
import { useAuthStore } from "../../store/firebase-auth";
import ChatRooms from "./ChatRooms";
import { ExistingChatMessage } from "../../models/chat_message";
import { ExistingChatRoom } from "../../models/chat_room";
import { DbRead } from "../../database";
import { logger } from "../../logging";

// Create a portal container for the chat widget
const ChatWidgetPortal: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(
    null,
  );

  useEffect(() => {
    // Check if the portal container already exists
    let container = document.getElementById("chat-widget-portal");

    // If not, create it
    if (!container) {
      container = document.createElement("div");
      container.id = "chat-widget-portal";
      container.style.position = "fixed";
      container.style.top = "0";
      container.style.left = "0";
      container.style.width = "100%";
      container.style.height = "100%";
      container.style.pointerEvents = "none"; // Don't block events on the container
      container.style.zIndex = "9999"; // Very high z-index
      document.body.appendChild(container);
    }

    setPortalContainer(container);

    // Clean up on unmount
    return () => {
      // Don't remove the container on unmount, as it might be used by other instances
      // Just clean up our specific content
    };
  }, []);

  if (!portalContainer) return null;

  return createPortal(
    <div
      style={{
        pointerEvents: "auto", // Allow events on the chat widget
        position: "absolute",
        bottom: 0,
        right: "1rem",
        zIndex: 9999,
      }}
    >
      {children}
    </div>,
    portalContainer,
  );
};

interface JobChatButtonProps {
  craftRecordId: string;
  siteKey: string;
  handleJobChat: () => void;
}

function JobChatButton({
  craftRecordId,
  siteKey,
  handleJobChat,
}: JobChatButtonProps) {
  const [chatRoom, setChatRoom] = useState<ExistingChatRoom | null>(null);
  const [messages, setMessages] = useState<ExistingChatMessage[]>([]);

  useEffect(() => {
    let isMounted = true;

    async function fetchChatRoom() {
      try {
        const rooms = await DbRead.chatRooms.getByCraftRecordID({
          siteKey,
          craftRecordID: craftRecordId,
        });

        if (isMounted && rooms.length > 0) {
          setChatRoom(rooms[0]); // Take the first room if multiple exist
        }
      } catch (error) {
        logger.error("Error fetching job chat rooms:", error);
      }
    }

    fetchChatRoom();

    return () => {
      isMounted = false;
    };
  }, [craftRecordId, siteKey]);

  useEffect(() => {
    if (!chatRoom) return;

    const unsubscribe = DbRead.chatMessages.subscribeByRoomID({
      siteKey,
      roomID: chatRoom.id,
      onChange: (newMessages) => {
        setMessages(newMessages);
      },
      onError: (error) => {
        logger.error("Error fetching messages:", error);
      },
    });

    return () => unsubscribe();
  }, [chatRoom, siteKey]);

  const buttonText = chatRoom
    ? `${messages.filter((message) => message.type !== "system").length} messages about this job`
    : "Chat about this job";

  const buttonColor = chatRoom ? "bg-red-500" : "bg-blue-500";

  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        handleJobChat();
      }}
      className={`mb-4 mr-1 rounded-full bg-blue-500 px-3 py-1 text-xs font-medium text-white hover:bg-blue-600 ${buttonColor}`}
    >
      {buttonText}
    </button>
  );
}

interface CustomerChatButtonProps {
  customerId: string;
  siteKey: string;
  handleCustomerChat: () => void;
}

function CustomerChatButton({
  customerId,
  siteKey,
  handleCustomerChat,
}: CustomerChatButtonProps) {
  const [selectedChatRoom, setSelectedChatRoom] =
    useState<ExistingChatRoom | null>(null);
  const [chatRooms, setChatRooms] = useState<ExistingChatRoom[]>([]);
  const [messages, setMessages] = useState<ExistingChatMessage[]>([]);

  useEffect(() => {
    let isMounted = true;

    async function fetchChatRoom() {
      try {
        const rooms = await DbRead.chatRooms.getByCustomerID({
          siteKey,
          customerID: customerId,
        });

        if (isMounted && rooms.length > 0) {
          setChatRooms(rooms); // Take the first room if multiple exist
          setSelectedChatRoom(rooms[0]);
        }
      } catch (error) {
        logger.error("Error fetching customer chat rooms:", error);
      }
    }

    fetchChatRoom();

    return () => {
      isMounted = false;
    };
  }, [customerId, siteKey]);

  useEffect(() => {
    if (!chatRooms.length || !selectedChatRoom) return;

    const unsubscribe = DbRead.chatMessages.subscribeByRoomID({
      siteKey,
      roomID: selectedChatRoom.id,
      onChange: (newMessages) => {
        setMessages(newMessages);
      },
      onError: (error) => {
        logger.error("Error fetching messages:", error);
      },
    });

    return () => unsubscribe();
  }, [selectedChatRoom, siteKey]);

  let buttonText = "Chat with this customer";
  if (chatRooms.length === 1 && selectedChatRoom) {
    buttonText = `${messages.filter((message) => message.type !== "system").length} messages`;
  }
  if (chatRooms.length > 1) {
    buttonText = "Multiple chats with this customer";
  }

  const buttonColor = selectedChatRoom ? "bg-red-500" : "bg-blue-500";

  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        handleCustomerChat();
      }}
      className={`mb-4 mr-1 rounded-full bg-blue-500 px-3 py-1 text-xs font-medium text-white hover:bg-blue-600 ${buttonColor}`}
    >
      {buttonText}
    </button>
  );
}

interface ChatWidgetProps {
  siteKey: string;
}

export default function ChatWidget({ siteKey }: ChatWidgetProps) {
  const [isHeaderPulsing, setIsHeaderPulsing] = useState(false);
  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);
  const chatContentRef = useRef<HTMLDivElement>(null);

  // Animation state
  const [isPulsing, setIsPulsing] = useState(false);
  const prevUnreadCountRef = useRef(0);

  // Get the firebase user
  const firebaseUser = useAuthStore((state) => state.firebaseUser);

  // Get total unread count directly from the store
  const totalUnreadCount = useUnreadMessageCountsStore(
    (state) => state.totalUnreadCount,
  );
  const subscribeToUnreadCounts = useUnreadMessageCountsStore(
    (state) => state.subscribeToUnreadCounts,
  );

  // Use the active chat room store
  const {
    activeChatRoomId,
    activeCraftRecordId,
    activeCustomerId,
    isOpen,
    openChat,
    closeChat,
    handleJobChat,
    handleCustomerChat,
  } = useActiveChatRoomStore();

  // Subscribe to unread counts when component mounts
  useEffect(() => {
    if (!firebaseUser) return;

    // Set up subscription to unread counts
    const unsubscribe = subscribeToUnreadCounts(siteKey, firebaseUser.uid);

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [siteKey, firebaseUser, subscribeToUnreadCounts]);

  // Trigger animation when unread count increases
  useEffect(() => {
    if (totalUnreadCount > prevUnreadCountRef.current) {
      // Only animate if there's an increase in unread messages
      setIsPulsing(true);
      setIsHeaderPulsing(true);

      // Stop animation after 2 seconds
      setTimeout(() => {
        setIsPulsing(false);
        setIsHeaderPulsing(false);
      }, 2000);
    }

    // Update the previous count reference
    prevUnreadCountRef.current = totalUnreadCount;
  }, [totalUnreadCount]);

  // Scroll to bottom when chat is opened
  useEffect(() => {
    if (isOpen) {
      // Use setTimeout to ensure the DOM has updated
      setTimeout(() => {
        const messagesContainer = chatContentRef.current;
        // Only auto-scroll to bottom if a specific chat room is selected (not the chat list)
        const chatRoomsElement = messagesContainer?.querySelector(
          '[data-testid="chat-rooms-component"]',
        );
        const chatRoomViewElement = messagesContainer?.querySelector(
          '[data-testid="chat-room-view-component"]',
        );

        if (messagesContainer && chatRoomViewElement) {
          // Only scroll if we're viewing a specific chat room, not the chat list
          messagesContainer.scrollTop = messagesContainer.scrollHeight;
        } else if (messagesContainer && chatRoomsElement) {
          // If we're viewing the chat rooms list, scroll to top
          messagesContainer.scrollTop = 0;
        }
      }, 300);
    }
  }, [isOpen]);

  // Prevent scroll propagation to parent page
  useEffect(() => {
    const chatContent = chatContentRef.current;

    if (!chatContent) return;

    const handleWheel = (e: WheelEvent) => {
      // Only prevent default if the scroll would cause the content to scroll
      const { scrollTop, scrollHeight, clientHeight } = chatContent;
      const isScrollingUp = e.deltaY < 0;
      const isScrollingDown = e.deltaY > 0;

      // If scrolling up and already at the top, or scrolling down and already at the bottom,
      // let the parent handle the scroll
      if (
        (isScrollingUp && scrollTop <= 0) ||
        (isScrollingDown && scrollTop + clientHeight >= scrollHeight)
      ) {
        return;
      }

      // Otherwise prevent the event from propagating to parent
      e.stopPropagation();
    };

    chatContent.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      chatContent.removeEventListener("wheel", handleWheel);
    };
  }, [isOpen]);

  if (!siteKeyDoc?.customizations.chatEnabled) {
    return null;
  }

  // Render the chat widget content
  const chatWidgetContent = (
    <div
      className="z-[60]"
      onClick={(e) => {
        // This prevents the click from reaching the document body
        // which would otherwise close the dialog
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      {/* Add CSS for the pulse animation */}
      <style>
        {`
          @keyframes pulse {
            0% {
              box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.7);
              transform: scale(1);
            }
            70% {
              box-shadow: 0 0 0 10px rgba(59, 130, 246, 0);
              transform: scale(1.05);
            }
            100% {
              box-shadow: 0 0 0 0 rgba(59, 130, 246, 0);
              transform: scale(1);
            }
          }
          
          .pulse {
            animation: pulse 1s infinite;
          }
          
          @keyframes headerPulse {
            0% {
              background-color: rgba(243, 244, 246, 1);
            }
            50% {
              background-color: rgba(219, 234, 254, 1);
            }
            100% {
              background-color: rgba(243, 244, 246, 1);
            }
          }
          
          .header-pulse {
            animation: headerPulse 1s infinite;
          }
        `}
      </style>

      {/* Chat Panel */}
      {isOpen && (
        <div className="mb-4 flex h-[500px] w-[350px] flex-col overflow-hidden rounded-lg border border-gray-200 bg-white shadow-lg transition-all duration-300 ease-in-out">
          {/* Chat Header */}
          <div
            className={`flex items-center justify-between bg-gray-100 p-3 ${
              isHeaderPulsing ? "header-pulse" : ""
            }`}
          >
            <h3 className="text-lg font-medium">Chat</h3>
            <div className="flex items-center space-x-2">
              <Close
                className="h-5 w-5 cursor-pointer hover:text-gray-600"
                onClick={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  closeChat();
                }}
              />
            </div>
          </div>

          {/* Chat Content */}
          <div
            ref={chatContentRef}
            className="h-[calc(100%-48px)] overflow-y-auto"
          >
            <ChatRooms siteKey={siteKey} initialRoomId={activeChatRoomId} />
          </div>
        </div>
      )}

      {/* Toggle Button */}
      {!isOpen && (
        <div className="flex items-center">
          {activeCraftRecordId && (
            <JobChatButton
              craftRecordId={activeCraftRecordId}
              siteKey={siteKey}
              handleJobChat={handleJobChat}
            />
          )}
          {siteKeyDoc?.customizations?.phones?.twilio?.smsNumber &&
            activeCustomerId && (
              <CustomerChatButton
                customerId={activeCustomerId}
                siteKey={siteKey}
                handleCustomerChat={handleCustomerChat}
              />
            )}
          <button
            onClick={(e) => {
              // Prevent the event from propagating to the document
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              // Open the chat
              openChat();
            }}
            className={`hover:bg-primary/90 mb-4 rounded-full bg-primary p-3 text-white shadow-lg transition-colors ${
              isPulsing ? "pulse" : ""
            }`}
          >
            <div className="relative">
              <ChatBubble />
              {totalUnreadCount > 0 && (
                <div className="absolute -right-2 -top-2 flex h-5 min-w-[20px] items-center justify-center rounded-full bg-red-500 px-1 text-xs font-medium text-white">
                  {totalUnreadCount > 99 ? "99+" : totalUnreadCount}
                </div>
              )}
            </div>
          </button>
        </div>
      )}
    </div>
  );

  // Render the chat widget in a portal to ensure it's outside the dialog's DOM hierarchy
  return <ChatWidgetPortal>{chatWidgetContent}</ChatWidgetPortal>;
}
