//Libs
import { ChatBubble, Email, Phone, PhoneAndroid } from "@mui/icons-material";
import { List } from "@mui/material";
import { PlusCircleIcon } from "@heroicons/react/24/solid";

//Local
import { ExistingCustomerLocation } from "../../models/customer-location";
import * as strings from "../../strings";
import { ExistingCustomerContact } from "../../models/customer-contact";
import { StyledTooltip } from "../StyledTooltip";
import { TrashIconWithSpinner } from "../ButtonDeleteTrash";
import { phoneUtils } from "../../utils/phoneUtils";
import { PencilIconWithRef } from "../PencilEditButton";

export function CustomerContacts({
  customerContacts,
  customerLocations,
  onContactDeleted,
  onContactEdited,
  onContactAdded,
  onChatWithContact,
}: {
  customerContacts: ExistingCustomerContact[];
  customerLocations: ExistingCustomerLocation[];
  onContactDeleted: (contactID: string) => Promise<void>;
  onContactEdited: (customerContact: ExistingCustomerContact) => void;
  onContactAdded: () => void;
  onChatWithContact?: (phoneNumber: string) => void;
}) {
  return (
    <div className="pt-4 md:pt-1">
      <div className="mb-2 flex flex-row justify-start">
        <div className="mr-4 text-lg font-bold text-primary md:text-xl">
          {strings.CUSTOMER_CONTACTS}
        </div>
        <PlusCircleIcon
          aria-label="add customer contact"
          className="h-7 w-7 shrink-0 hover:text-primaryDark md:h-9 md:w-9"
          onClick={() => {
            onContactAdded();
          }}
        />
        <div className="pb-4 text-xl font-bold text-primary"></div>
      </div>

      <div className="space-y-1">
        <List
          sx={{
            width: "100%",
            position: "relative",
            overflow: "auto",
            scrollbarWidth: "1px",
            maxHeight: 300,
            bgcolor: "background.paper",
            "& ul": { padding: 0 },
          }}
          subheader={<li />}
        >
          {/* Customer Location Contacts */}
          {customerContacts != null
            ? customerContacts.map((contact) => {
                return (
                  <div
                    key={contact.id}
                    className="my-1 flex flex-row justify-between rounded-md border bg-white px-1 py-1"
                  >
                    <div className="flex w-full flex-col justify-center">
                      {contact.notes && (
                        <span className="text-md mb-2 ml-2 block whitespace-normal leading-5">
                          {contact.notes}
                        </span>
                      )}
                      <div className="ml-2 flex flex-row items-start text-sm text-gray-600">
                        {contact.type === "phone" && <Phone />}
                        {contact.type === "mobilePhone" && <PhoneAndroid />}
                        {contact.type === "email" && <Email />}
                        <span className="ml-2">
                          {contact.type === "email"
                            ? contact.value
                            : phoneUtils.display(contact.value)}
                        </span>
                      </div>
                      {contact.customerLocationID && (
                        <span className="ml-2 mt-1 flex justify-start text-sm font-bold text-gray-600">
                          {customerLocations.filter(
                            (cL) => cL.id === contact.customerLocationID,
                          )[0]?.addressLine1 ?? ""}
                          {customerLocations.filter(
                            (cL) => cL.id === contact.customerLocationID,
                          )[0]?.addressLine2 ?? ""}
                        </span>
                      )}
                    </div>
                    <div className="flex items-center justify-between">
                      {/*<StyledTooltip title="Opt-In" enterDelay={1200}>*/}
                      {/*  {contact.optIn ? <Notifications*/}
                      {/*    className="mx-2"*/}
                      {/*    color="success"*/}
                      {/*    // onDelete={async () => onContactDeleted(contact.id)}*/}
                      {/*  /> : <NotificationsOff*/}
                      {/*    className="mx-2"*/}
                      {/*    color="disabled"*/}
                      {/*    // onDelete={async () => onContactDeleted(contact.id)}*/}
                      {/*  />}*/}
                      {/*</StyledTooltip>*/}
                      {onChatWithContact && contact.type !== "email" && (
                        <StyledTooltip
                          title="Chat with this contact"
                          enterDelay={1200}
                        >
                          <ChatBubble
                            className="mx-0 px-0"
                            onClick={() => onChatWithContact(contact.value)}
                            style={{
                              cursor: "pointer",
                            }}
                          />
                        </StyledTooltip>
                      )}
                      <StyledTooltip title="Edit Contact" enterDelay={1200}>
                        <PencilIconWithRef
                          className="mx-0 px-0"
                          onClick={() => onContactEdited(contact)}
                        />
                      </StyledTooltip>
                      <StyledTooltip title="Delete Contact" enterDelay={1200}>
                        <TrashIconWithSpinner
                          className="mx-0 px-0"
                          onDelete={async () => onContactDeleted(contact.id)}
                        />
                      </StyledTooltip>
                    </div>
                  </div>
                );
              })
            : null}
        </List>
      </div>
    </div>
  );
}
