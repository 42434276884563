// Libs
import { z } from "zod";
import { DocumentData } from "firebase/firestore";

// Local
import NavButtonBack from "../components/NavButtonBack";
import { ExistingCraftRecord } from "../models/craft-record";
import { ExistingSiteKeyLocation } from "../models/site-key-location";
import { timestampNow } from "../database";
import ChecklistBodyForm from "../components/ChecklistBodyForm";
import { diffObjects } from "../assets/js/object-diff";
import HeadingOne from "../components/HeadingOne";

interface Props {
  siteKey: string;
  checklist: ExistingCraftRecord;
  locationsList: ExistingSiteKeyLocation[];
  onEditChecklist: (
    data: Record<string, any>,
    craftDetailsData: Record<string, any>,
  ) => any;
  uid: string | null;
  onGoBack: () => void;
}

export default function EditChecklistPage({
  checklist,
  locationsList,
  uid,
  onEditChecklist,
  onGoBack,
}: Props) {
  if (!checklist) {
    console.error("No Item here!");
    return null;
  }

  const ChecklistFormSchema = z.object({
    title: z.string().min(1).max(200),
    description: z.string().min(1).max(200).nullable(),
    locationID: z.string().min(1).max(200),
    requireApprovals: z.boolean(),
  });
  type ChecklistFormState = z.infer<typeof ChecklistFormSchema>;

  const initialFormValues: ChecklistFormState = {
    title: checklist.title,
    description: checklist.description,
    locationID: checklist.locationID,
    requireApprovals: getInitialRequireApprovals(),
  };

  // This function retrieves the boolean values of Require Approvals checkbox
  function getInitialRequireApprovals(): boolean {
    const craftDetails = checklist.craftDetails;
    const requireApprovals = craftDetails["requireApprovals"];
    const result =
      typeof requireApprovals === "boolean" ? requireApprovals : false;
    return result;
  }

  async function handleEdit(values: ChecklistFormState): Promise<void> {
    if (uid == null) {
      console.error(`User ID was not found. Value: ${uid}`);
      return;
    }

    // separate the main values from the Craft Details
    const { title, description, locationID, ...craftDetails } = values;

    // create an object with the main values just divided
    const mainValues = {
      title: title,
      description: description,
      locationID: locationID,
    };
    //
    // create an object with the initial main values retrieved from DB
    const initialMainValues = {
      title: initialFormValues.title,
      description: initialFormValues.description,
      locationID: initialFormValues.locationID,
    };
    // send the two object with main values, initial and current, in a function that compare them and return an object with only the different values
    const diffMainValues = diffObjects(initialMainValues, mainValues).diff;
    // join the different values with other two properties that needs to be updated with the edit
    const editChecklist: DocumentData = {
      ...diffMainValues,
      lastModifiedBy: uid,
      timestampLastModified: timestampNow(),
    };
    // create an object with the initial values of Craft Details
    const initialCraftDetails = {
      requireApprovals: initialFormValues.requireApprovals,
    };
    // compare the initial craft details with the current ones and return an object with the values that are changed
    const editCraftDetails: DocumentData = diffObjects(
      initialCraftDetails,
      craftDetails,
    ).diff;
    // Write to Firestore
    // Call Firestore method to write data to database.
    await onEditChecklist(editChecklist, editCraftDetails);

    onGoBack();
  }

  return (
    <div className="mx-2">
      <div>
        <NavButtonBack />
        <HeadingOne>Edit Template</HeadingOne>
      </div>
      <ChecklistBodyForm
        locationsList={locationsList}
        initialFormValues={initialFormValues}
        onFormSubmit={handleEdit}
        onCancel={onGoBack}
      />
    </div>
  );
}
