import { useState } from "react";
import { Control } from "react-hook-form";
// Local
import NavButtonBack from "../../components/NavButtonBack";
import { ExistingMembershipTemplate } from "../../models/membership-template";
import { EDIT_TEMPLATE, buttons } from "../../strings";
import { AddEditTemplateForm } from "./AddEditTemplateForm";
import { TemplateFormState } from "./types";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import HeadingOne from "../../components/HeadingOne";

interface Props {
  template: ExistingMembershipTemplate;
  onSubmit: (formValues: TemplateFormState) => Promise<void>;
  onCancel: () => void;
  children: {
    pbiSection: React.ReactNode;
    renewalPBISection: React.ReactNode;
    taskGenSection: (control: Control<TemplateFormState>) => React.ReactNode;
  };
}

export default function EditTemplatePage(props: Props) {
  const [isBusy, setIsBusy] = useState(false);

  return (
    <div className="relative mx-auto mb-10 w-full md:mx-0 md:max-w-[1280px]">
      <div className="fixed bottom-4 right-20 z-10 sm:bottom-auto sm:right-8 sm:top-24">
        <BaseButtonPrimary
          type="submit"
          form="editTemplateForm"
          formNoValidate
          disabled={isBusy}
          isBusy={isBusy}
          busyText={buttons.BUSY_SAVING}
          className="w-40 uppercase"
        >
          Save Template
        </BaseButtonPrimary>
      </div>

      <div className="mb-8 mt-4 flex flex-wrap items-center gap-y-2">
        <NavButtonBack onClick={props.onCancel} />
        <HeadingOne className="leading-3">{EDIT_TEMPLATE}</HeadingOne>
      </div>

      <AddEditTemplateForm
        id="editTemplateForm"
        onSubmit={props.onSubmit}
        setIsBusy={setIsBusy}
        defaultValues={{
          title: props.template.title,
          description: props.template.description,
          invoiceMethod: props.template.invoiceMethod,
          invoiceFrequency: props.template.invoiceFrequency,
          automaticallyGenerateTasks:
            props.template.automaticallyGenerateTasks ?? false,
          discount: props.template.discount,
          defaultDuration:
            typeof props.template.defaultDuration === "number"
              ? props.template.defaultDuration
              : 12, // will become a required field.. just isn't one yet
          automaticallyPayInvoice:
            props.template.automaticallyPayInvoice ?? false,
          automaticallySendInvoice:
            props.template.automaticallySendInvoice ?? false,
        }}
      >
        {{
          pbiSection: props.children.pbiSection,
          renewalPBISection: props.children.renewalPBISection,
          taskGenSection: props.children.taskGenSection,
        }}
      </AddEditTemplateForm>
    </div>
  );
}
