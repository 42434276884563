import ButtonConnectQB from "../../components/ButtonConnectQB";
import { ConfigSetting } from "../../models/site-configuration";
import ReviewURLsEditor from "./CustomComponents/ReviewURLsEditor";
import PaymentTermsEditor from "./CustomComponents/PaymentTermsEditor";
import PaymentTermSelector from "./CustomComponents/PaymentTermSelector";
import { DbWrite } from "../../database";
import { useState } from "react";
import { SiQuickbooks } from "react-icons/si";
import ButtonColored from "../../components/ButtonColored";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import { StyledTooltip } from "../../components/StyledTooltip";
import * as strings from "../../strings";
import { createToastMessageID } from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";
import { logger } from "../../logging";

interface Props {
  sectionKey: string;
  sectionName: string;
  sectionDescription?: string;
  sectionData: Record<string, ConfigSetting<any> | Record<string, any>>;
}

export default function ConfigurationSection({
  sectionKey,
  sectionName,
  sectionDescription,
  sectionData,
}: Props) {
  const [isBusyConnectingToQBO, setIsBusyConnectingToQBO] = useState(false);
  const [isBusyDisconnectingQBO, setIsBusyDisconnectingQBO] = useState(false);
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );

  // Helper function to check if an object is a ConfigSetting
  const isConfigSetting = (obj: any): obj is ConfigSetting<any> => {
    return (
      obj &&
      typeof obj === "object" &&
      "type" in obj &&
      "title" in obj &&
      "description" in obj
    );
  };

  // Recursive function to render fields or nested objects
  const renderConfigField = (
    fieldName: string,
    data: ConfigSetting<any> | Record<string, any>,
    path: string = fieldName,
  ) => {
    // If it's a ConfigSetting, render the field
    if (isConfigSetting(data)) {
      // Skip hidden fields entirely
      if (data.hidden) return null;

      return (
        <div
          key={path}
          className="relative grid grid-cols-1 items-start gap-4 md:grid-cols-2"
        >
          <div className="col-span-1">
            <div className="font-medium text-gray-700">{data.title}</div>
            <p className="mb-2 text-sm text-gray-500 md:mb-0">
              {data.description}
            </p>
          </div>
          <div className="col-span-1">{renderField(path, data)}</div>
          <hr className="col-span-1 my-2 md:col-span-2" />
        </div>
      );
    }

    // If it's an object but not a ConfigSetting, recursively render its contents
    if (typeof data === "object" && data !== null) {
      return (
        <div key={path} className="space-y-2">
          {/* Optionally add a subheading for nested sections */}
          <h3 className="text-lg font-medium text-gray-900">{fieldName}</h3>
          {Object.entries(data).map(([key, value]) =>
            renderConfigField(key, value, `${path}.${key}`),
          )}
        </div>
      );
    }

    return null;
  };

  const renderField = (fieldName: string, fieldConfig: ConfigSetting<any>) => {
    // Handle custom fields based on identifier
    if (fieldConfig.type === "custom") {
      switch (fieldConfig.identifier) {
        case "feedbackReviewURLs":
          return (
            <ReviewURLsEditor
              value={fieldConfig.defaultValue || {}}
              onChange={(newValue) => {
                // Handle the change if needed
                console.log("Review URLs updated:", newValue);
              }}
            />
          );

        case "paymentTerms":
          return (
            <PaymentTermsEditor
              value={fieldConfig.defaultValue || {}}
              onChange={(newValue) => {
                console.log("Payment terms updated:", newValue);
              }}
            />
          );

        case "paymentTermsSelection":
          return (
            <PaymentTermSelector
              value={fieldConfig.defaultValue}
              onChange={(newValue) => {
                console.log("Payment term selected:", newValue);
              }}
            />
          );

        // Add more custom components here as needed
        default:
          return null;
      }
    }

    const commonProps = {
      id: fieldName,
      name: fieldName,
      "aria-label": fieldConfig.title,
    };

    switch (fieldConfig.type) {
      case "string":
        return (
          <input
            type="text"
            {...commonProps}
            className="block w-full min-w-0 flex-1 rounded border border-black p-2 text-gray-700 focus:ring"
            defaultValue={fieldConfig.defaultValue}
          />
        );

      case "number":
        return (
          <input
            type="number"
            {...commonProps}
            defaultValue={fieldConfig.defaultValue}
            min={fieldConfig.validation?.minValue}
            max={fieldConfig.validation?.maxValue}
            className="block w-full min-w-0 flex-1 rounded border border-black p-2 text-gray-700 focus:ring"
          />
        );

      case "boolean":
        return (
          <input
            type="checkbox"
            {...commonProps}
            className="h-4 w-4 accent-current focus-visible:outline focus-visible:outline-1 focus-visible:outline-current"
            defaultChecked={fieldConfig.defaultValue}
          />
        );

      case "select":
        return (
          <select
            className="block w-full rounded border border-primaryLight p-2 text-gray-700 shadow-sm outline-none focus:ring focus:ring-primaryLight sm:text-sm"
            {...commonProps}
            defaultValue={fieldConfig.defaultValue}
          >
            {fieldConfig.selectionOptions?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );

      case "multiselect":
        return (
          <div className="rounded border border-primaryLight p-2">
            {/* Select All Option */}
            <label className="flex items-center p-2 hover:bg-gray-50">
              <input
                type="checkbox"
                className="h-4 w-4 accent-current focus-visible:outline focus-visible:outline-1 focus-visible:outline-current"
                onChange={(e) => {
                  // Get all checkboxes in this group
                  const checkboxes = e.currentTarget
                    .closest("div")
                    ?.querySelectorAll<HTMLInputElement>(
                      'input[type="checkbox"]',
                    );
                  if (checkboxes) {
                    // Skip the first checkbox (select all) when setting values
                    Array.from(checkboxes)
                      .slice(1)
                      .forEach((checkbox) => {
                        checkbox.checked = e.target.checked;
                      });
                  }
                }}
              />
              <span className="ml-2 text-sm font-medium">Select All</span>
            </label>

            <div className="mt-1 border-t border-gray-200">
              {fieldConfig.selectionOptions?.map((option) => (
                <label
                  key={option.value}
                  className="flex items-center p-2 hover:bg-gray-50"
                >
                  <input
                    type="checkbox"
                    name={fieldName}
                    value={option.value}
                    className="h-4 w-4 accent-current focus-visible:outline focus-visible:outline-1 focus-visible:outline-current"
                    defaultChecked={
                      Array.isArray(fieldConfig.defaultValue) &&
                      fieldConfig.defaultValue.includes(option.value)
                    }
                  />
                  <span className="ml-2 text-sm">{option.label}</span>
                </label>
              ))}
            </div>
          </div>
        );

      case "color":
        return (
          <div className="flex items-center gap-2">
            <input
              type="color"
              {...commonProps}
              className="h-10 w-20 rounded border border-black p-1"
              defaultValue={fieldConfig.defaultValue || "#FFFFFF"}
              onChange={(e) => {
                // Update the text input when color is changed
                const textInput = e.currentTarget
                  .nextSibling as HTMLInputElement;
                if (textInput) {
                  textInput.value = e.target.value.toUpperCase();
                }
              }}
            />
            <input
              type="text"
              {...commonProps}
              className="block w-32 rounded border border-black p-2 text-gray-700 focus:ring"
              defaultValue={fieldConfig.defaultValue || "#FFFFFF"}
              pattern="^#[0-9A-Fa-f]{6}$"
              onChange={(e) => {
                // Update the color picker when hex value is changed
                const colorInput = e.currentTarget
                  .previousSibling as HTMLInputElement;
                if (colorInput && e.target.value.match(/^#[0-9A-Fa-f]{6}$/)) {
                  colorInput.value = e.target.value.toUpperCase();
                }
              }}
            />
          </div>
        );

      default:
        return null;
    }
  };

  async function handleClickConnect(): Promise<void> {
    let authURI: string;
    try {
      setIsBusyConnectingToQBO(true);
      authURI = await DbWrite.qbo.connect(sectionKey);
    } catch (e) {
      console.error("QBO connection failure: rejection in getAuthURI", e);
      return;
    } finally {
      setIsBusyConnectingToQBO(false);
    }

    // Launch Popup using the JS window Object
    const parameters = `location=1,width=800,height=650,left=${(screen.width - 800) / 2},top=${(screen.height - 650) / 2}`;
    window.open(authURI, "connectPopup", parameters);
  }

  async function handleDisconnectQBO(): Promise<void> {
    try {
      setIsBusyDisconnectingQBO(true);
      await DbWrite.qbo.disconnect(sectionKey);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.DISCONNECTED,
        dialog: false,
        type: "success",
      });
    } catch (e) {
      logger.error("QBO disconnect failure", e);
      addToastMessage({
        id: createToastMessageID(),
        message: strings.QB_CANT_DISCONNECT,
        dialog: false,
        type: "error",
      });
    } finally {
      setIsBusyDisconnectingQBO(false);
    }
  }

  // const hasQBOConnection = sectionData.customizations?.accounting?.qboRealmID;
  const hasQBOConnection = false;

  return (
    <div>
      <div className="mb-2 flex items-center justify-between">
        <h2 className="text-2xl font-medium">{sectionName}</h2>
        {sectionKey === "quickbooks" && (
          <div className="flex gap-4">
            {hasQBOConnection ? (
              <>
                <StyledTooltip title="If you see error messages such as 'unable to refresh access token,' try reconnecting. Disconnecting first is not necessary.">
                  <BaseButtonSecondary
                    isBusy={isBusyConnectingToQBO}
                    busyText="Reconnect"
                    onClick={handleClickConnect}
                    type="button"
                  >
                    <SiQuickbooks />
                    <span className="ml-2">{strings.RECONNECT}</span>
                  </BaseButtonSecondary>
                </StyledTooltip>
                <StyledTooltip title="Disconnecting will stop Stilt from pushing more data to QuickBooks. No data will be lost. Stilt will continue syncing your data when the connection is established again.">
                  <ButtonColored
                    kind="danger"
                    isBusy={isBusyDisconnectingQBO}
                    busyText="Disconnect"
                    onClick={handleDisconnectQBO}
                  >
                    <SiQuickbooks />
                    <span className="ml-2">{strings.DISCONNECT}</span>
                  </ButtonColored>
                </StyledTooltip>
              </>
            ) : (
              <ButtonConnectQB
                isBusy={isBusyConnectingToQBO}
                onClick={handleClickConnect}
                type="button"
              />
            )}
          </div>
        )}
      </div>
      {sectionDescription && (
        <p className="mb-6 text-sm text-gray-600">{sectionDescription}</p>
      )}
      <hr className="my-4" />
      <div className="space-y-2">
        {Object.entries(sectionData).map(([fieldName, fieldData]) =>
          renderConfigField(fieldName, fieldData),
        )}
      </div>
    </div>
  );
}
