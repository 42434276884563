// Libs
import { useEffect, useState } from "react";
import { Timestamp } from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

// Local
import { DbRead, DbWrite } from "../../database";
import {
  ChatRoom,
  ChatRoomManager,
  ExistingChatRoom,
  ChatRoomType,
  LastChatMessage,
} from "../../models/chat_room";
import { useAuthStore } from "../../store/firebase-auth";
import {
  ChatMessageManager,
  ExistingChatMessage,
  MessageType,
} from "../../models/chat_message";
import { useUserDisplayNamesStore } from "../../store/user-display-names-map";
import { useSiteKeyUsersStore } from "../../store/site-key-users";
import { useUserPermissionsStore } from "../../store/user-permissions";
import { logger } from "../../logging";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import * as strings from "../../strings";
import { useUnreadMessageCountsStore } from "../../store/unread-message-counts";
import { ExistingChatMessageReadStatus } from "../../models/chat_message_read_status";

// Components
import ChatRoomsList from "./ChatRoomsList";
import ChatRoomView from "./ChatRoomView";
import { useActiveChatRoomStore } from "../../store/active-chat-room";

interface Props {
  // DATA
  siteKey: string;
  initialRoomId?: string | null;
}

export default function ChatRooms(props: Props) {
  const [uploadingImages, setUploadingImages] = useState<{
    [key: string]: { progress: number; file: File };
  }>({});

  const firebaseUser = useAuthStore((state) => state.firebaseUser);
  const userDisplayNamesMap = useUserDisplayNamesStore(
    (state) => state.userDisplayNames,
  );
  const siteKeyUsersList = useSiteKeyUsersStore(
    (state) => state.siteKeyUsersList,
  );
  const addToastMessage = useToastMessageStore(
    (state) => state.addToastMessage,
  );

  // Get unread counts from the store
  const unreadCounts = useUnreadMessageCountsStore(
    (state) => state.unreadCounts,
  );

  const [myChatRooms, setMyChatRooms] = useState<ExistingChatRoom[]>([]);
  const [customerChatRooms, setCustomerChatRooms] = useState<
    ExistingChatRoom[]
  >([]);
  const [selectedRoomID, setSelectedRoomID] = useState<string | null>(null);
  const [messagesForRoom, setMessagesForRoom] = useState<
    ExistingChatMessage[] | null
  >(null);

  // Use unread counts from the store
  const [unreadMessageStatuses, setUnreadMessageStatuses] = useState<
    ExistingChatMessageReadStatus[]
  >([]);

  // Get user permissions and check if user is site admin
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );

  // Update local unreadMessageStatuses when store changes
  useEffect(() => {
    setUnreadMessageStatuses(unreadCounts);
  }, [unreadCounts]);

  // Set the selected room ID when initialRoomId changes
  useEffect(() => {
    if (props.initialRoomId) {
      setSelectedRoomID(props.initialRoomId);
    }
  }, [props.initialRoomId]);

  // Subscribe to chat rooms
  useEffect(() => {
    function getMyChatRooms() {
      if (!firebaseUser) return undefined;

      return DbRead.chatRooms.subscribeByUserID({
        siteKey: props.siteKey,
        userID: firebaseUser.uid,
        onChange: (rooms) => {
          try {
            // Filter out any invalid rooms before setting state
            const validRooms = rooms.filter((room) => {
              try {
                // Basic validation of essential properties
                return (
                  room && Array.isArray(room.userIds) && room.userIds.length > 0
                );
              } catch (error) {
                logger.warn("Invalid chat room filtered out", error);
                return false;
              }
            });
            setMyChatRooms(validRooms);
          } catch (error) {
            logger.error("Error processing chat rooms", error);
            // Set to empty array to avoid breaking the UI
            setMyChatRooms([]);
          }
        },
        onError: (error) => {
          logger.error("Error fetching chat rooms", error);
          setMyChatRooms([]);
        },
      });
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    const unsubscribePromise = getMyChatRooms();
    return () => unsubscribePromise && unsubscribePromise();
  }, [firebaseUser, props.siteKey]);

  const activeCustomerId = useActiveChatRoomStore(
    (state: { activeCustomerId: string | null }) => state.activeCustomerId,
  );

  const clearActiveChatRoom = useActiveChatRoomStore(
    (state: { clearActiveChatRoom: () => void }) => state.clearActiveChatRoom,
  );

  useEffect(() => {
    function getCustomerChatRooms() {
      if (!firebaseUser) return undefined;
      if (!activeCustomerId) return undefined;

      return DbRead.chatRooms.subscribeByCustomerID({
        siteKey: props.siteKey,
        customerID: activeCustomerId,
        onChange: (rooms) => {
          setCustomerChatRooms(rooms);
        },
      });
    }

    const unsubscribePromise = getCustomerChatRooms();
    return () => unsubscribePromise && unsubscribePromise();
  }, [firebaseUser, props.siteKey, activeCustomerId]);

  // Subscribe to chat messages for the selected room
  useEffect(() => {
    function getChatMessages() {
      if (!firebaseUser) return undefined;
      if (!selectedRoomID) return undefined;

      return DbRead.chatMessages.subscribeByRoomID({
        siteKey: props.siteKey,
        roomID: selectedRoomID,
        onChange: (messages) => {
          try {
            // Filter out any invalid messages before setting state
            const validMessages = messages.filter((message) => {
              try {
                // Basic validation of essential properties - allow image messages with empty text
                return (
                  message &&
                  typeof message.createdBy === "string" &&
                  (typeof message.text === "string" ||
                    message.type === MessageType.IMAGE)
                );
              } catch (error) {
                logger.warn("Invalid chat message filtered out", error);
                return false;
              }
            });
            setMessagesForRoom(validMessages);
          } catch (error) {
            logger.error("Error processing chat messages", error);
            // Set to empty array to avoid breaking the UI
            setMessagesForRoom(null);
          }
        },
        onError: (error) => {
          logger.error("Error fetching chat messages", error);
          setMessagesForRoom(null);
        },
      });
    }

    // Store the returned 'unsubscribe' ƒn into the unsubscribePromise variable.
    const unsubscribePromise = getChatMessages();
    return () => unsubscribePromise && unsubscribePromise();
  }, [selectedRoomID, firebaseUser, props.siteKey]);

  // Function to start a new chat
  async function startNewChat(selectedUserIds: string[], groupName?: string) {
    try {
      if (!firebaseUser) {
        addToastMessage({
          id: createToastMessageID(),
          message: "You must be logged in to start a chat",
          type: "error",
          dialog: false,
        });
        return;
      }

      if (selectedUserIds.length === 0) {
        addToastMessage({
          id: createToastMessageID(),
          message: strings.NO_USER_SELECTED,
          type: "warning",
          dialog: false,
        });
        return;
      }

      // Include current user in the chat
      const allUserIds = Array.from(
        new Set([...selectedUserIds, firebaseUser.uid]),
      );
      const isGroupChat = allUserIds.length > 2;

      // Prevent non-admin users from creating group chats
      if (isGroupChat && userPermissions?.permissions.isSiteAdmin !== true) {
        addToastMessage({
          id: createToastMessageID(),
          message: "Only site administrators can create group chats",
          type: "warning",
          dialog: false,
        });
        return;
      }

      // Check if room already exists
      let existingRoom: ExistingChatRoom | undefined;

      if (!isGroupChat) {
        // For direct chats, check if a room with these exact users exists
        existingRoom = myChatRooms.find(
          (room) =>
            room.type === ChatRoomType.DIRECT &&
            room.userIds.length === 2 &&
            room.userIds.includes(firebaseUser.uid) &&
            room.userIds.includes(selectedUserIds[0]),
        );
      } else {
        // For group chats, check if a room with these exact users exists
        existingRoom = myChatRooms.find((room) => {
          // Must be a group chat with the exact same number of users
          if (
            room.type !== ChatRoomType.GROUP ||
            room.userIds.length !== allUserIds.length
          ) {
            return false;
          }

          // Check if all users in allUserIds are in this room's userIds
          // Sort both arrays for consistent comparison
          const sortedRoomUserIds = [...room.userIds].sort();
          const sortedAllUserIds = [...allUserIds].sort();

          // Compare each user ID to ensure exact match
          return sortedRoomUserIds.every(
            (userId, index) => userId === sortedAllUserIds[index],
          );
        });
      }

      if (existingRoom) {
        // Use existing room
        setSelectedRoomID(existingRoom.id);
      } else {
        // Create a name for the chat room
        const chatRoomName = isGroupChat
          ? groupName || "Group Chat"
          : userDisplayNamesMap[selectedUserIds[0]] || "Chat";

        // Create a new chat room
        const newChatRoom: ChatRoom = {
          createdBy: firebaseUser.uid,
          lastModifiedBy: firebaseUser.uid,
          name: chatRoomName,
          type: isGroupChat ? ChatRoomType.GROUP : ChatRoomType.DIRECT,
          userIds: allUserIds,
          timestampCreated: Timestamp.now(),
          timestampLastModified: Timestamp.now(),
          metadata: {},
          lastMessage: null,
          imageUrl: null,
          craftRecordID: null,
        };

        // Save the chat room to the database
        const newRoomRef = await DbWrite.chatRoom.add(
          props.siteKey,
          newChatRoom,
        );

        if (newRoomRef) {
          // Set the selected room ID to the new room
          setSelectedRoomID(newRoomRef.id);
        }
      }
    } catch (error) {
      logger.warn("Error creating chat room", error);
      addToastMessage({
        id: createToastMessageID(),
        message: "Failed to create chat room",
        type: "error",
        dialog: false,
      });
    }
  }

  // Function to add a message to the current chat
  async function handleAddMessage(textContent: string) {
    try {
      if (!firebaseUser || !selectedRoomID || !textContent) return;

      // Create a new message
      const newMessage = ChatMessageManager.createFromNewMessage(
        { text: textContent },
        firebaseUser.uid,
        selectedRoomID,
        MessageType.TEXT,
      );

      // Add the message to the database
      await DbWrite.chatMessage.add(props.siteKey, selectedRoomID, newMessage);
    } catch (error) {
      logger.warn("Error adding message", error);
    }
  }

  // Function to handle image uploads
  async function handleImageUpload(file: File) {
    try {
      if (!firebaseUser || !selectedRoomID) return;

      // Generate a unique ID for the image
      const imageId = `${Date.now()}-${Math.random().toString(36).substring(2, 9)}`;

      // Add to uploading images state
      setUploadingImages((prev) => ({
        ...prev,
        [imageId]: { progress: 0, file },
      }));

      // Get the storage reference
      const storage = getStorage();
      const storageRef = ref(
        storage,
        `chat_images/${props.siteKey}/${selectedRoomID}/${imageId}`,
      );

      // Upload the image
      const uploadTask = uploadBytesResumable(storageRef, file);

      // Listen for state changes, errors, and completion of the upload
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get upload progress
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          // Update progress in state
          setUploadingImages((prev) => ({
            ...prev,
            [imageId]: { ...prev[imageId], progress },
          }));
        },
        (error) => {
          // Handle unsuccessful uploads
          logger.warn("Error uploading image", error);

          // Remove from uploading images
          setUploadingImages((prev) => {
            const newState = { ...prev };
            delete newState[imageId];
            return newState;
          });
        },
        async () => {
          try {
            // Upload completed successfully, get the download URL
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            // Get image dimensions
            const dimensions = await getImageDimensions(file);

            // Create a new message
            const newMessage = ChatMessageManager.createFromNewMessage(
              {
                uri: downloadURL,
                name: file.name,
                size: file.size,
                height: dimensions?.height,
                width: dimensions?.width,
                mimeType: file.type,
              },
              firebaseUser.uid,
              selectedRoomID,
              MessageType.IMAGE,
            );

            // Add the message to the database
            await DbWrite.chatMessage.add(
              props.siteKey,
              selectedRoomID,
              newMessage,
            );
            // Remove from uploading images
            setUploadingImages((prev) => {
              const newState = { ...prev };
              delete newState[imageId];
              return newState;
            });
          } catch (error) {
            logger.warn("Error processing uploaded image", error);

            // Remove from uploading images
            setUploadingImages((prev) => {
              const newState = { ...prev };
              delete newState[imageId];
              return newState;
            });
          }
        },
      );
    } catch (error) {
      logger.warn("Error starting image upload", error);
    }
  }

  // Helper function to get image dimensions
  function getImageDimensions(
    file: File,
  ): Promise<{ width: number; height: number } | null> {
    return new Promise((resolve, reject) => {
      const img = new globalThis.Image();
      img.onload = () => {
        resolve({
          width: img.width,
          height: img.height,
        });
      };
      img.onerror = () => {
        reject(new Error("Failed to load image"));
      };
      img.src = URL.createObjectURL(file);
    });
  }

  // Combine chatRooms to make a unique list of chat rooms based on the room ID
  const chatRooms: ExistingChatRoom[] = [...myChatRooms];
  customerChatRooms.forEach((room) => {
    if (!chatRooms.find((r) => r.id === room.id)) {
      chatRooms.push(room);
    }
  });

  return (
    <div className="relative flex h-full w-full flex-col space-y-1 p-1">
      <style>
        {`
        [contenteditable][data-placeholder]:empty:before {
          content: attr(data-placeholder);
          color: #aaa;
          cursor: text;
        }
        `}
      </style>
      <div className="relative flex h-full w-full flex-col gap-2">
        {selectedRoomID && chatRooms && messagesForRoom !== null ? (
          <ChatRoomView
            room={chatRooms.find((r) => r.id === selectedRoomID)!}
            messages={messagesForRoom}
            siteKey={props.siteKey}
            firebaseUser={firebaseUser}
            userDisplayNamesMap={userDisplayNamesMap}
            siteKeyUsersList={siteKeyUsersList}
            onBackClick={() => {
              clearActiveChatRoom();
              setSelectedRoomID(null);
            }}
            uploadingImages={uploadingImages}
            handleImageUpload={handleImageUpload}
            handleAddMessage={handleAddMessage}
            markRoomAsRead={() => {
              if (firebaseUser && selectedRoomID) {
                DbWrite.chatMessageReadStatus
                  .markRoomAsRead(
                    props.siteKey,
                    firebaseUser.uid,
                    selectedRoomID,
                  )
                  .catch((error) => {
                    logger.error("Error marking room as read", error);
                  });
              }
            }}
            unreadMessageStatuses={unreadMessageStatuses}
          />
        ) : (
          <ChatRoomsList
            chatRooms={chatRooms}
            firebaseUser={firebaseUser}
            userDisplayNamesMap={userDisplayNamesMap}
            siteKeyUsersList={siteKeyUsersList}
            onSelectRoom={(chatRoomID) => setSelectedRoomID(chatRoomID)}
            onStartNewChat={startNewChat}
            unreadMessageStatuses={unreadMessageStatuses}
          />
        )}
      </div>
    </div>
  );
}
