import HeadingOne from "../../components/HeadingOne";

interface Props {
  title: string;
  children: {
    tableControlsSlot: React.ReactNode;
    tableSlot: React.ReactNode;
  };
}

export default function PhoneNumbersPage(props: Props): JSX.Element {
  return (
    <>
      <HeadingOne>{props.title}</HeadingOne>
      <div className="mt-4 max-w-4xl">
        <p className="text-lg text-gray-600">
          Track your marketing campaigns by using dedicated phone numbers. When
          customers call these numbers, you'll know exactly which campaign or
          advertisement they're responding to. Stilt keeps track of jobs that
          are booked through these numbers so you can see the total revenue
          generated from each number.
        </p>
      </div>

      <div className="mt-8">{props.children.tableControlsSlot}</div>
      <div className="ag-theme-alpine mt-8 h-[600px]">
        {props.children.tableSlot}
      </div>
    </>
  );
}
