// Libs
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

// Local
import {
  UnauthedPaymentMade,
  StiltLineItemFormData,
  ExistingStiltPayment,
} from "../../models/stilt-payment";
import * as strings from "../../strings";
import BaseButtonSecondary from "../BaseButtonSecondary";
import { logger } from "../../logging";
import { ErrorMessage } from "../ErrorMessage";
import StyledMessage from "../StyledMessage";
import currencyFormatter, { formatAsPercent } from "../../currency";
import { StyledTooltip } from "../StyledTooltip";
import { PencilIconWithRef } from "../PencilEditButton";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/solid";
import EditInvoiceItemLine from "../Invoices/EditInvoiceItemLine";
import { ExistingPriceBookItem } from "../../models/price-book-item";
import { useTypesenseStore } from "../../store/typesense";
import { typesensePriceBookItemsQuery } from "../../utils/typesenseQueries";
import AddNewInvoiceItemSelection from "../Invoices/AddNewInvoiceItemSelection";
import { ExistingCustomer } from "../../models/customer";
import { FaDollarSign } from "react-icons/fa";
import BaseButtonPrimary from "../BaseButtonPrimary";
import { GiConfirmed } from "react-icons/gi";
import DeletePaymentDialog from "../DeletePaymentDialog";
import BaseInputNumber from "../BaseInputNumber";
import { ExistingStiltInvoice } from "../../models/invoice";
import { XIconWithRef } from "../XCloseButton";
import PaymentLineFormatted from "./PaymentLineFormatted";
import { convertISOToFSTimestamp } from "../../utils";
import { TrashButton } from "../TrashButton";
import LoadingSpinner from "../LoadingSpinner";
import BaseInputTextArea from "../BaseInputTextArea";
import { DbRead } from "../../database";

/** Add your prop here if it should only be available to authenticated site admins */
type AuthenticatedUser = {
  /** **Is the user a logged in site admin?**
   *
   * This is an important discriminator: InvoiceSummary is used on both authed and non-authed routes. */
  shouldShowAuthedVersion: true;
  paymentsMade: ExistingStiltPayment[];
  handleRefund:
    | ((paymentID: string, refundAmount: number) => Promise<void>)
    | null;
  handleDeletePayment: ((paymentID: string) => Promise<void>) | null;
  handlePayment: (() => void) | null;
  siteFilterPbiByLocationID: boolean;
  siteKey: string | null;
  customer: ExistingCustomer | null;
  internalNote: string | null;
  allowEdit: boolean;
  isSavingGlobalDiscount: boolean;
  locationID: string | null;
  updateGlobalDiscount: ((newGlobalDiscount: number) => Promise<void>) | null;
  lineItemsUpdated: ((lineItems: StiltLineItemFormData[]) => void) | null;
  userDisplayNamesMap: Record<string, string>;
  onUpdateNote?: (note: string) => Promise<void>;
  siteKeyID?: string;
  invoiceID?: string;
};

/** Add your prop here if it should only be available to unauthenticated users. (YAGNI) */
type UnauthenticatedUser = {
  /** **Is the user a logged in site admin?**
   *
   * This is an important discriminator: InvoiceSummary is used on both authed and non-authed routes. */
  shouldShowAuthedVersion: false;
  paymentsMade: UnauthedPaymentMade[];
};

/** Add your prop here if it should be available to both authed and unauthed users. */
type Props = {
  currency: string;
  timezone: string;
  totalTaxRate: number;
  items: StiltLineItemFormData[];
  totalTaxAmount: number;
  totalTaxAmountPST?: number;
  totalTaxAmountGST?: number;
  subTotalWithoutGlobalDiscount: number;
  subTotalWithGlobalDiscount: number;
  tipAmount: number;
  totalAmount: number;
  amountDue: number;
  note: string | null;
  merchantName: string | null;
  discount: number;
  allDiscountsApplied: number;
  tipsEnabled: boolean;
  // COMPONENTS
  children: {
    tipsSection?: React.ReactNode;
    editItemsButton?: React.ReactNode;
  };
} & (AuthenticatedUser | UnauthenticatedUser);

export default function InvoiceSummary(props: Props): JSX.Element {
  const buttonElement = useRef<HTMLButtonElement>(null);
  const [temporaryInvoiceLineItemList, setTemporaryInvoiceLineItemList] =
    useState<StiltLineItemFormData[]>(props.items);
  const [pbItemsFromTypesense, setPbItemsFromTypesense] = useState<
    ExistingPriceBookItem[]
  >([]);

  const [editGlobalDiscountOpen, setEditGlobalDiscountOpen] =
    useState<boolean>(false);
  const [globalDiscount, setGlobalDiscount] = useState<
    ExistingStiltInvoice["discount"]
  >(props.discount);

  const typesenseSearchKey = useTypesenseStore(
    (state) => state.scopedSearchKey,
  );

  const locationID = props.shouldShowAuthedVersion ? props.locationID : null;
  const filterPbiByLoc = props.shouldShowAuthedVersion
    ? props.siteFilterPbiByLocationID
    : false;
  const lineItemsUpdated =
    props.shouldShowAuthedVersion && props.lineItemsUpdated;

  const [isEditingNotes, setIsEditingNotes] = useState(false);
  const [isLoadingStiltonNotes, setIsLoadingStiltonNotes] = useState(false);
  const [stiltonNotes, setStiltonNotes] = useState<string | null>(null);
  const [tempNotes, setTempNotes] = useState<string>(props.note ?? "");

  useEffect(() => {
    async function getPBItems() {
      if (!typesenseSearchKey) return;
      const pbItems = await typesensePriceBookItemsQuery(
        typesenseSearchKey,
        "",
      );
      if (
        props.shouldShowAuthedVersion &&
        filterPbiByLoc &&
        locationID != null
      ) {
        const filterPBItems = pbItems.filter(
          (item) => item.locationID === locationID || item.locationID === null,
        );
        setPbItemsFromTypesense(filterPBItems);
      } else {
        setPbItemsFromTypesense(pbItems);
      }
    }

    getPBItems();
  }, [
    filterPbiByLoc,
    locationID,
    props.shouldShowAuthedVersion,
    typesenseSearchKey,
  ]);

  useEffect(() => {
    if (lineItemsUpdated) {
      lineItemsUpdated(temporaryInvoiceLineItemList);
    }
  }, [lineItemsUpdated, temporaryInvoiceLineItemList]);

  const onFilterTextBoxChanged = useCallback(
    async (searchTerm: string) => {
      if (!typesenseSearchKey) return;

      const pbItems = await typesensePriceBookItemsQuery(
        typesenseSearchKey,
        searchTerm,
      );
      if (
        props.shouldShowAuthedVersion &&
        filterPbiByLoc &&
        locationID != null
      ) {
        const filterPBItems = pbItems.filter(
          (item) => item.locationID === locationID || item.locationID === null,
        );
        setPbItemsFromTypesense(filterPBItems);
      } else {
        setPbItemsFromTypesense(pbItems);
      }
    },
    [
      filterPbiByLoc,
      locationID,
      props.shouldShowAuthedVersion,
      typesenseSearchKey,
    ],
  );

  function handleTurnItemToBeEditable(index: number): void {
    if (index >= 0) {
      setTemporaryInvoiceLineItemList((oldList) => {
        oldList[index].toBeEdited = true;
        return [...oldList];
      });
    }
  }

  function handleDeleteItem(index: number): void {
    if (index >= 0) {
      setTemporaryInvoiceLineItemList((oldList) => {
        const newList = [...oldList];
        newList.splice(index, 1);
        return [...newList];
      });
    }
  }

  function handleAddItem(newItem: StiltLineItemFormData) {
    temporaryInvoiceLineItemList.push(newItem);
    setTemporaryInvoiceLineItemList(temporaryInvoiceLineItemList);
  }

  function handleEditLineItem(index: number, item: StiltLineItemFormData) {
    temporaryInvoiceLineItemList[index] = item;
    setTemporaryInvoiceLineItemList([...temporaryInvoiceLineItemList]);
  }

  // const nonEmptyNote = props.note && props.note.trim().length > 0;
  let allPaymentAmounts = 0;
  for (const paymentMade of props.paymentsMade) {
    allPaymentAmounts += paymentMade.amount;
  }

  const handleGetStiltonNotes = async () => {
    if (!props.shouldShowAuthedVersion) return;
    if (!props.siteKeyID || !props.invoiceID) return;
    setIsLoadingStiltonNotes(true);
    try {
      const notes = await DbRead.stilton.getSummarizedInvoiceNotes({
        siteKeyID: props.siteKeyID,
        invoiceID: props.invoiceID,
      });
      setStiltonNotes(notes);
    } catch (error) {
      console.error("Error getting Stilton notes:", error);
    } finally {
      setIsLoadingStiltonNotes(false);
    }
  };

  const handleAcceptStiltonNotes = async () => {
    if (!props.shouldShowAuthedVersion || !props.onUpdateNote) return;
    if (stiltonNotes) {
      await props.onUpdateNote(stiltonNotes);
      setStiltonNotes(null);
      setTempNotes(stiltonNotes);
    }
  };

  return (
    <div className="mx-3 mb-8 flex items-start justify-center gap-16 xl:mx-auto xl:max-w-5xl">
      <div className="mt-12 grow whitespace-pre-wrap text-gray-800">
        {!props.shouldShowAuthedVersion && (
          <Fragment>
            <div className="mb-0 text-left text-lg font-extrabold">Notes</div>
            <hr className="my-2 block w-full border border-gray-200" />
            {props.note}
          </Fragment>
        )}
        {props.shouldShowAuthedVersion && props.onUpdateNote && (
          <div className="mt-4">
            <div className="mb-2 flex items-center justify-between">
              <h3 className="text-lg font-semibold">Notes</h3>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => setIsEditingNotes(true)}
                  className="p-1 text-gray-600 hover:text-gray-900"
                >
                  <PencilIcon className="h-5 w-5" />
                </button>
                <button
                  onClick={handleGetStiltonNotes}
                  disabled={isLoadingStiltonNotes}
                  className="flex items-center gap-2 rounded-lg bg-purple-600 px-3 py-2 text-sm font-medium text-white transition-colors hover:bg-purple-700 disabled:cursor-default disabled:opacity-50"
                >
                  <img
                    src="/white_hardhat_transparent_bg.svg"
                    alt="Stilton AI Icon"
                    className="h-4 w-4"
                  />
                  {isLoadingStiltonNotes ? (
                    <div className="relative flex h-4 w-4 items-center justify-center">
                      <div className="absolute h-4 w-4 animate-spin rounded-full border-2 border-white border-t-transparent"></div>
                    </div>
                  ) : (
                    strings.STILTON.SUMMARIZE
                  )}
                </button>
              </div>
            </div>
            {stiltonNotes && (
              <div className="my-4 rounded-lg border border-purple-200 bg-purple-50 p-4">
                <h4 className="mb-2 font-semibold text-purple-900">
                  Stilton AI Suggested Notes:
                </h4>
                <p className="mb-4 text-sm text-gray-700">{stiltonNotes}</p>
                <div className="flex gap-2">
                  <button
                    onClick={handleAcceptStiltonNotes}
                    className="rounded-lg bg-purple-600 px-4 py-2 text-sm font-medium text-white hover:bg-purple-700"
                  >
                    Accept
                  </button>
                  <button
                    onClick={() => setStiltonNotes(null)}
                    className="rounded-lg bg-gray-200 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-300"
                  >
                    Dismiss
                  </button>
                </div>
              </div>
            )}
            {isEditingNotes ? (
              <div className="space-y-2">
                <BaseInputTextArea
                  text=""
                  admin={true}
                  value={tempNotes}
                  onChange={(e) => setTempNotes(e.target.value)}
                  rows={3}
                  className="w-full"
                />
                <div className="flex gap-2">
                  <BaseButtonPrimary
                    onClick={async () => {
                      if (!props.shouldShowAuthedVersion || !props.onUpdateNote)
                        return;
                      await props.onUpdateNote(tempNotes);
                      setIsEditingNotes(false);
                    }}
                    className="rounded-lg bg-blue-600 px-3 py-1 text-white hover:bg-blue-700"
                  >
                    {strings.buttons.SAVE_CHANGES}
                  </BaseButtonPrimary>
                  <BaseButtonSecondary
                    onClick={() => {
                      setIsEditingNotes(false);
                      setTempNotes(props.note ?? "");
                    }}
                    className="rounded-lg bg-gray-200 px-3 py-1 hover:bg-gray-300"
                  >
                    {strings.buttons.CANCEL}
                  </BaseButtonSecondary>
                </div>
              </div>
            ) : (
              <div className="rounded-lg border p-3">{props.note ?? ""}</div>
            )}
          </div>
        )}
        {props.shouldShowAuthedVersion && props.internalNote && (
          <Fragment>
            <div className="my-2 rounded-2xl bg-gray-100 px-4 py-4">
              <div className="mb-1 text-left text-lg font-extrabold">
                Internal Notes
              </div>
              {props.internalNote}
            </div>
          </Fragment>
        )}
        <hr className="mb-6 mt-4 block w-full border border-gray-400" />
        {props.children.editItemsButton}
        {props.shouldShowAuthedVersion && props.allowEdit && (
          <div className="mb-4">
            <AddNewInvoiceItemSelection
              onSearch={onFilterTextBoxChanged}
              PBItemQueryResultList={pbItemsFromTypesense}
              customer={props.customer}
              globalDiscount={props.discount}
              totalTaxRate={props.totalTaxRate}
              handleAddItem={handleAddItem}
              currency={props.currency}
            />
          </div>
        )}
        {/* SECTION: LINE ITEMS */}
        <ul>
          {temporaryInvoiceLineItemList.map((item, i) => {
            const {
              title,
              quantity,
              description,
              unitPrice,
              discount,
              discountable,
              priceBookItemID,
            } = item;
            const subtotalItemWithLineDiscount =
              item.quantity * item.unitPrice * (1 - (item.discount ?? 0) / 100);
            const totalWithoutDiscount = item.quantity * item.unitPrice;
            const key = title ? title.replace(" ", "-") : i;

            if (
              props.shouldShowAuthedVersion &&
              item.toBeEdited &&
              props.customer &&
              props.siteKey
            ) {
              return (
                <div key={`list-item_${key}_${i}`}>
                  <EditInvoiceItemLine
                    key={`list-item_${key}_${i}`}
                    lineItem={item}
                    customer={props.customer}
                    globalDiscount={props.discount}
                    totalTaxRate={props.totalTaxRate}
                    siteKey={props.siteKey}
                    currency={props.currency}
                    onSearch={onFilterTextBoxChanged}
                    handleEditItem={(updatedItem) => {
                      handleEditLineItem(i, updatedItem);
                    }}
                    handleDeleteItem={() => {
                      handleDeleteItem(i);
                    }}
                  />
                  <hr className="my-4 block w-full border border-gray-200" />
                </div>
              );
            }

            return (
              <div key={`list-item_${key}_${i}`}>
                <div
                  className={`${
                    props.shouldShowAuthedVersion && props.allowEdit
                      ? "grid grid-cols-[5fr,2fr,1fr]"
                      : "grid grid-cols-[5fr,2fr]"
                  }`}
                >
                  {/* TITLE AND DESCRIPTION */}
                  <div className="flex items-center">
                    <div className="flex flex-col">
                      <span className="block whitespace-normal text-base font-medium leading-5">
                        {title && <div>{title}</div>}
                      </span>
                      <span className="block whitespace-normal text-sm leading-5 text-gray-400">
                        {description && (
                          <p className={`${title && "mt-1"} text-sm`}>
                            {description}
                          </p>
                        )}
                      </span>
                      {discountable === false && (
                        <p className={`text-sm italic`}>* Non-discountable</p>
                      )}
                      {props.shouldShowAuthedVersion &&
                        props.allowEdit &&
                        priceBookItemID === "import" && (
                          <p className="text-sm italic text-red-400">
                            Imported item. Please replace with the correct
                            pricebook item.
                          </p>
                        )}
                    </div>
                  </div>

                  {/* AMOUNT */}
                  <div className="flex justify-self-end">
                    <div className="flex flex-col items-end space-y-2">
                      {discount && discount > 0 && discountable !== false ? (
                        <div className="flex items-center gap-2">
                          <span className="line-through">
                            {currencyFormatter(
                              totalWithoutDiscount,
                              props.currency,
                            )}
                          </span>
                          <span className="text-lg font-bold">
                            {currencyFormatter(
                              subtotalItemWithLineDiscount,
                              props.currency,
                            )}
                          </span>
                        </div>
                      ) : (
                        <div className="text-lg font-bold">
                          {currencyFormatter(
                            totalWithoutDiscount,
                            props.currency,
                          )}
                        </div>
                      )}
                      {discount && discount > 0 && discountable !== false ? (
                        <div className="mt-2 text-sm text-gray-600">
                          {quantity} @{" "}
                          {currencyFormatter(unitPrice, props.currency)} +{" "}
                          {discount}% discount
                        </div>
                      ) : (
                        <div className="mt-2 text-sm text-gray-600">
                          {quantity} @{" "}
                          {currencyFormatter(unitPrice, props.currency)}
                        </div>
                      )}
                    </div>
                  </div>
                  {/*/!* ACTION BUTTONS *!/*/}
                  {props.shouldShowAuthedVersion && props.allowEdit && (
                    <div className="flex flex-row items-center justify-self-end">
                      {priceBookItemID !== "import" && (
                        <StyledTooltip title="Edit Line Items">
                          <PencilIconWithRef
                            onClick={() => {
                              handleTurnItemToBeEditable(i);
                            }}
                          />
                        </StyledTooltip>
                      )}
                      <button
                        className="mx-1 rounded-full hover:bg-red-50"
                        onClick={() => {
                          handleDeleteItem(i);
                        }}
                      >
                        <TrashIcon
                          aria-label="delete button"
                          className="block h-10 w-10 cursor-pointer p-2 text-red-700"
                        />
                      </button>
                    </div>
                  )}
                </div>
                <hr className="my-4 block w-full border border-gray-200" />
              </div>
            );
          })}
        </ul>

        <div className="flex flex-col justify-end space-x-10 md:flex-row">
          {/* SECTION: TIPS */}
          {props.tipsEnabled &&
            props.children.tipsSection &&
            props.amountDue > 0}
          {/* SECTION: TOTALS and PAYMENTS */}
          <div className="mb-8 flex-col justify-end">
            {props.shouldShowAuthedVersion && props.allowEdit && (
              <article className="flex justify-end">
                {editGlobalDiscountOpen ? (
                  <div className="mb-2 flex flex-row justify-end gap-2">
                    <BaseButtonPrimary
                      type="button"
                      onClick={async () => {
                        props.updateGlobalDiscount &&
                          (await props
                            .updateGlobalDiscount(globalDiscount)
                            .then(() => setEditGlobalDiscountOpen(false)));
                      }}
                      className="max-w-sm text-primary"
                      isBusy={props.isSavingGlobalDiscount}
                      busyText={strings.buttons.BUSY_SAVING}
                    >
                      {strings.buttons.SAVE_CHANGES}
                    </BaseButtonPrimary>
                    <StyledTooltip title="Close">
                      <XIconWithRef
                        onClick={() => {
                          setEditGlobalDiscountOpen(false);
                        }}
                      />
                    </StyledTooltip>
                  </div>
                ) : (
                  <StyledTooltip title="Edit Global Discount">
                    <PencilIconWithRef
                      onClick={() => setEditGlobalDiscountOpen(true)}
                    />
                  </StyledTooltip>
                )}
              </article>
            )}
            <article className="mb-2 text-right text-base">
              {strings.SUBTOTAL}:{" "}
              {currencyFormatter(
                props.subTotalWithoutGlobalDiscount,
                props.currency,
              )}
            </article>
            {editGlobalDiscountOpen === true && (
              <form
                autoComplete="off"
                className="mb-2 flex items-center justify-end gap-4"
              >
                <BaseInputNumber
                  type="number"
                  step="0.01"
                  className={`block w-20 rounded outline-none focus:ring-1 focus:ring-primaryLight sm:text-sm`}
                  defaultValue={props.discount == 0 ? "" : props.discount}
                  onBlur={(event) => {
                    const discount = parseFloat(event.target.value);
                    if (discount !== 0 && !isNaN(discount)) {
                      setGlobalDiscount(discount);
                    } else {
                      setGlobalDiscount(0);
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      const discount = parseFloat(
                        (event.target as HTMLInputElement).value,
                      );
                      if (discount !== 0 && !isNaN(discount)) {
                        setGlobalDiscount(discount);
                      } else {
                        setGlobalDiscount(0);
                      }
                    }
                  }}
                />
                <span className="text-lg"> %</span>
              </form>
            )}
            {props.discount > 0 && editGlobalDiscountOpen === false && (
              <>
                <article className="mb-2 text-right text-base">
                  {strings.DISCOUNT}: {props.discount}%
                </article>
                <article className="mb-2 text-right text-base">
                  {strings.SUBTOTAL_WITH_DISCOUNT}:{" "}
                  {currencyFormatter(
                    props.subTotalWithGlobalDiscount,
                    props.currency,
                  )}
                </article>
              </>
            )}
            {props.allDiscountsApplied > 0 && (
              <article className="mb-2 text-right text-base italic text-green-600">
                {strings.TOTAL_DISCOUNT_AMOUNT}:{" "}
                {currencyFormatter(props.allDiscountsApplied, props.currency)}
              </article>
            )}
            {props.tipAmount > 0 && (
              <>
                <article className="mb-2 text-right text-base">
                  {strings.TIP}:{" "}
                  {currencyFormatter(props.tipAmount, props.currency)}
                </article>
              </>
            )}
            <article className="mb-2 text-right text-base">
              {strings.TAX_TOTAL}:{" "}
              {currencyFormatter(props.totalTaxAmount, props.currency)} (
              {formatAsPercent(props.totalTaxRate)})
            </article>
            {props.totalTaxAmountPST && (
              <article className="mb-2 text-right text-sm italic">
                {strings.PST}:{" "}
                {currencyFormatter(props.totalTaxAmountPST, props.currency)}
              </article>
            )}
            {props.totalTaxAmountGST && (
              <article className="mb-2 text-right text-sm italic">
                {strings.GST}:{" "}
                {currencyFormatter(props.totalTaxAmountGST, props.currency)}
              </article>
            )}
            <article className="mb-2 text-right text-lg font-medium">
              {strings.TOTAL_AMOUNT}:{" "}
              {currencyFormatter(props.totalAmount, props.currency)}
            </article>

            {props.paymentsMade.length > 0 &&
              !props.shouldShowAuthedVersion && (
                <UNAUTHEDPaymentsMade
                  paymentsMade={props.paymentsMade}
                  currency={props.currency}
                  timezone={props.timezone}
                />
              )}

            {props.paymentsMade.length > 0 && props.shouldShowAuthedVersion && (
              <AUTHEDPaymentsMade
                paymentsMade={props.paymentsMade}
                handleRefund={props.handleRefund}
                handleDelete={props.handleDeletePayment}
                currency={props.currency}
                timezone={props.timezone}
                siteKeyID={props.siteKey}
                userDisplayNamesMap={props.userDisplayNamesMap}
              />
            )}

            <article className="text-right text-3xl font-medium">
              {strings.AMOUNT_DUE}:{" "}
              {currencyFormatter(props.amountDue, props.currency)}
            </article>

            {allPaymentAmounts > props.totalAmount && (
              <div className="flex flex-row justify-end pt-2">
                <article className="w-fit rounded-full bg-orange-200 px-4 text-right text-xl font-medium">
                  {strings.OVERPAYMENT}:{" "}
                  {currencyFormatter(
                    allPaymentAmounts - props.totalAmount,
                    props.currency,
                  )}
                </article>
              </div>
            )}

            {props.shouldShowAuthedVersion && props.handlePayment !== null && (
              <div className="mt-4 flex flex-row justify-end">
                <BaseButtonSecondary
                  type="button"
                  ref={buttonElement}
                  className="w-full uppercase sm:w-44"
                  onClick={() => {
                    props.handlePayment !== null && props.handlePayment();
                    setTimeout(() => {
                      // TODO: this shouldn't be necessary anymore
                      buttonElement.current?.blur();
                    }, 1000);
                  }}
                >
                  {strings.HANDLE_PAYMENT}
                </BaseButtonSecondary>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

// function calculateDiscountedPrice(discount: number, price: number): number {
//   const decimalDiscount = discount / 100;
//   const discountAmount = price * decimalDiscount;
//   return price - discountAmount;
// }

function UNAUTHEDPaymentsMade(props: {
  paymentsMade: UnauthedPaymentMade[];
  currency: string;
  timezone: string;
}): JSX.Element {
  props.paymentsMade.sort(
    (a, b) =>
      convertISOToFSTimestamp(a.timestampPaymentMade).toMillis() -
      convertISOToFSTimestamp(b.timestampPaymentMade).toMillis(),
  );

  const payments: UnauthedPaymentMade[] = [];
  const refunds: UnauthedPaymentMade[] = [];

  for (const obj of props.paymentsMade) {
    if (obj.paymentType !== "refund") {
      payments.push(obj);
    } else {
      refunds.push(obj);
    }
  }

  return (
    <>
      <hr className="my-2 block w-full border border-gray-400" />
      <article className="flex flex-wrap justify-between gap-3">
        <div className="ml-auto text-right">
          {payments.map((payment) => (
            <PaymentLineFormatted
              key={
                payment.timestampPaymentMade +
                payment.amount +
                payment.paymentMethod
              }
              payment={payment}
              currencyType={props.currency}
              timezone={props.timezone}
              isUserAuthenticated={false}
              userDisplayNamesMap={{}}
            />
          ))}

          {refunds.map((refund) => (
            <PaymentLineFormatted
              key={
                refund.timestampPaymentMade +
                refund.amount +
                refund.paymentMethod
              }
              payment={refund}
              currencyType={props.currency}
              timezone={props.timezone}
              isUserAuthenticated={false}
              userDisplayNamesMap={{}}
            />
          ))}
        </div>
      </article>
      <hr className="my-2 block w-full border border-gray-400" />
    </>
  );
}

const iconStyles: React.CSSProperties = {
  width: "1.25rem",
  height: "1.25rem",
  marginRight: "0.75rem",
  color: "currentColor",
};

/** Sorted timestamp ascending. For authenticated users. */
function AUTHEDPaymentsMade(props: {
  paymentsMade: ExistingStiltPayment[];
  currency: string;
  timezone: string;
  siteKeyID: string | null;
  handleRefund:
    | ((paymentID: string, refundAmount: number) => Promise<void>)
    | null;
  handleDelete: ((paymentID: string) => Promise<void>) | null;
  userDisplayNamesMap: Record<string, string>;
}): JSX.Element {
  const [busyID, setBusyID] = useState<string | null>(null); // used for refunding and deleting payments
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // used for refunding and deleting payments
  const [successMessage, setSuccessMessage] = useState<string | null>(null); // used for refunding and deleting payments
  const [refundAmount, setRefundAmount] = useState(0);
  const [showRefundAmountField, setShowRefundAmountField] = useState(false);
  const [showConfirmDeletePaymentDialog, setShowConfirmDeletePaymentDialog] =
    useState(false);
  const [selectedPaymentToDelete, setSelectedPaymentToDelete] =
    useState<ExistingStiltPayment | null>(null);
  const [selectedRefundID, setSelectedRefundID] = useState<string | null>(null);

  props.paymentsMade.sort(
    (a, b) =>
      a.timestampPaymentMade.toMillis() - b.timestampPaymentMade.toMillis(),
  );

  const payments: ExistingStiltPayment[] = [];
  const refunds: ExistingStiltPayment[] = [];

  for (const obj of props.paymentsMade) {
    if (obj.paymentType !== "refund") {
      payments.push(obj);
    } else {
      refunds.push(obj);
    }
  }

  const handleChange = (e: React.ChangeEvent<any>) => {
    setRefundAmount(e.target.value);
  };

  async function confirmDeletePayment() {
    if (!props.siteKeyID) return;
    if (!selectedPaymentToDelete) return;
    if (props.handleDelete === null) return;
    setBusyID(selectedPaymentToDelete.id);
    try {
      await props.handleDelete(selectedPaymentToDelete.id);
      setSuccessMessage(
        selectedPaymentToDelete.paymentType !== "refund"
          ? strings.DELETED_PAYMENT
          : strings.DELETED_REFUND,
      );
    } catch (e) {
      logger.error(
        `error deleting payment (id: ${selectedPaymentToDelete.id}) -`,
        JSON.stringify(e, null, 2),
      );
      setErrorMessage(
        selectedPaymentToDelete.paymentType === "refund"
          ? strings.ERR_DELETING_REFUND
          : strings.ERR_DELETING_PAYMENT,
      );
    } finally {
      setBusyID(null);
      setSelectedPaymentToDelete(null);
      setShowConfirmDeletePaymentDialog(false);
    }
  }

  const confirmDeletePaymentDialog = selectedPaymentToDelete && (
    <DeletePaymentDialog
      isOpen={showConfirmDeletePaymentDialog}
      onClose={() => setShowConfirmDeletePaymentDialog(false)}
      handleConfirmDelete={confirmDeletePayment}
      isSubmitting={busyID === selectedPaymentToDelete.id}
      payment={selectedPaymentToDelete}
      currency={props.currency}
    />
  );

  return (
    <>
      <hr className="my-2 block w-full border border-gray-400" />
      <article className="flex flex-wrap justify-between gap-3">
        <div className="ml-auto text-right">
          {payments.map((payment) => {
            return (
              <div
                key={payment.id}
                className="mb-3 flex flex-wrap items-center justify-end space-x-2 font-bold last:mb-0 xs:flex-nowrap"
              >
                <PaymentLineFormatted
                  payment={payment}
                  currencyType={props.currency}
                  isUserAuthenticated={true}
                  timezone={props.timezone}
                  userDisplayNamesMap={props.userDisplayNamesMap}
                />

                {props.handleRefund !== null &&
                  shouldShowRefundButton(props.paymentsMade) && (
                    <BaseButtonSecondary
                      className="ml-4 uppercase tracking-wide"
                      onClick={async () => {
                        setShowRefundAmountField(!showRefundAmountField);
                        setSelectedRefundID(payment.id);
                      }}
                    >
                      <CurrencyExchangeIcon style={iconStyles} />
                      {strings.buttons.REFUND}
                    </BaseButtonSecondary>
                  )}
                {props.handleDelete !== null &&
                  shouldShowDeletePaymentButton(payment) && (
                    <StyledTooltip title="Delete Payment">
                      <TrashButton
                        type="button"
                        aria-label="delete payment"
                        color="text-red-700"
                        hoverColor="hover:bg-red-50"
                        onDelete={() => {
                          setSelectedPaymentToDelete(payment);
                          setShowConfirmDeletePaymentDialog(true);
                        }}
                      />
                    </StyledTooltip>
                  )}
                {showRefundAmountField &&
                  props.handleRefund !== null &&
                  selectedRefundID === payment.id && (
                    <div className="flex flex-row items-center justify-end space-x-2">
                      <div className="flex items-center">
                        <FaDollarSign className="mx-2" />
                        <input
                          className="block w-36 flex-1 rounded border border-gray-300 px-2 py-1.5 outline-none focus:border-primaryLight focus:ring-1 focus:ring-primaryLight sm:text-sm"
                          value={refundAmount}
                          onChange={handleChange}
                        />
                      </div>
                      <BaseButtonPrimary
                        className="max-w-2xl"
                        type="button"
                        isBusy={busyID === payment.id}
                        disabled={busyID === payment.id}
                        overrideLoadingElement={
                          <LoadingSpinner marginClass="" sizeClass="h-5 w-5" />
                        }
                        onClick={async () => {
                          if (props.handleRefund === null) return;
                          setBusyID(payment.id);
                          try {
                            await props.handleRefund(payment.id, refundAmount);
                            setSuccessMessage(strings.ISSUED_REFUND);
                          } catch (e) {
                            logger.error(
                              `error refunding payment (id: ${payment.id}) -`,
                              JSON.stringify(e, null, 2),
                            );
                            setErrorMessage(strings.ERR_ISSUING_REFUND);
                          } finally {
                            setBusyID(null);
                            setShowRefundAmountField(false);
                            setSelectedRefundID(null);
                          }
                        }}
                      >
                        <GiConfirmed className="h-5" />
                      </BaseButtonPrimary>
                    </div>
                  )}
              </div>
            );
          })}

          {refunds.map((refund) => {
            return (
              <div
                key={refund.id}
                className="mb-3 flex flex-wrap items-center justify-end space-x-2 font-semibold italic last:mb-0 xs:flex-nowrap"
              >
                <PaymentLineFormatted
                  payment={refund}
                  currencyType={props.currency}
                  isUserAuthenticated={true}
                  timezone={props.timezone}
                  userDisplayNamesMap={props.userDisplayNamesMap}
                />
                {props.handleDelete !== null &&
                  shouldShowDeletePaymentButton(refund) && (
                    <StyledTooltip title="Delete Refund">
                      <TrashButton
                        type="button"
                        aria-label="delete refund"
                        color="text-red-700"
                        hoverColor="hover:bg-red-50"
                        onDelete={async () => {
                          setSelectedPaymentToDelete(refund);
                          setShowConfirmDeletePaymentDialog(true);
                        }}
                      />
                    </StyledTooltip>
                  )}
              </div>
            );
          })}
          {confirmDeletePaymentDialog}
        </div>
      </article>
      {successMessage && (
        <div className="mx-auto mt-2 max-w-fit">
          <StyledMessage
            type="success"
            dismissible
            onDismiss={() => setSuccessMessage(null)}
          >
            {{ message: successMessage }}
          </StyledMessage>
        </div>
      )}
      {errorMessage && (
        <div className="mx-auto mt-2 max-w-sm">
          <ErrorMessage
            clearMessage={() => setErrorMessage(null)}
            message={errorMessage}
          />
        </div>
      )}
      <hr className="my-2 block w-full border border-gray-400" />
    </>
  );
}

/** show refund buttons if total amount PAID is less than total amount REFUNDED */
export function shouldShowRefundButton(
  paymentsMade: ExistingStiltPayment[],
): boolean {
  const justRefunds = paymentsMade.filter((payment) => payment.amount < 0);
  const justPayments = paymentsMade.filter((payment) => payment.amount > 0);

  let totalRefunded = 0;
  for (const refund of justRefunds) {
    totalRefunded += refund.amount;
  }

  let totalPaid = 0;
  for (const payment of justPayments) {
    totalPaid += payment.amount;
  }

  const absRefunded = Math.abs(totalRefunded);

  if (absRefunded >= totalPaid) {
    return false;
  }
  return true;
}

/** show delete payment button if payment hasn't synced with codat/qbo */
export function shouldShowDeletePaymentButton(
  payment: ExistingStiltPayment,
): boolean {
  // Only allow deleting manual payments. Payments processed via HPP
  // (Paya or Zift) cannot be deleted by a user
  if (payment.paymentSource !== "manual") {
    return false;
  }
  // There's an accountingRefID, so let's not delete this one!
  if (
    typeof payment.customData.accountingRefID === "string" ||
    typeof payment.customData.accountingRefID === "number"
  ) {
    return false;
  }
  // There's a pushOperationKey, so let's not delete this one as it might have been successful in syncing!
  if (payment.customData.pushOperationKey) {
    return false;
  }
  // As a backup... if there's a timestampLastSynced, let's not delete this one!
  if (payment.accountingSync?.timestampLastSynced) {
    return false;
  }
  // As yet another backup, more properties that indicate we shouldn't delete...
  if (
    payment.accountingSync?.syncStatus === "SYNCED" ||
    payment.accountingSync?.syncStatus === "AWAITING_CONFIRM"
  ) {
    return false;
  }
  return true;
}
