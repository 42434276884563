import { Transition } from "@headlessui/react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { useState, useEffect, Fragment, useRef } from "react";
import Draggable, { DraggableEvent, DraggableData } from "react-draggable";
// Local
import { StyledTooltip } from "../StyledTooltip";
import { logger } from "../../logging";

const dragIconStyles: React.CSSProperties = {
  display: "block",
  height: "26px",
  width: "22px",
};

export default function BubbleActiveCall(props: {
  children: React.ReactNode;
}): JSX.Element {
  // nodeRef usage fixes a console warning --
  // https://github.com/STRML/react-draggable/blob/v4.4.2/lib/DraggableCore.js#L159-L171
  // https://stackoverflow.com/questions/63603902/finddomnode-is-deprecated-in-strictmode-finddomnode-was-passed-an-instance-of-d
  const nodeRef = useRef<HTMLDivElement>(null);

  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  /** user knows that the bubble is draggable if they've dragged it before, we don't need to continuously show them the tooltip */
  const userKnows = useRef(false);

  useEffect(() => {
    const storedCoords = localStorage.getItem("activeCallBubblePosition");
    if (storedCoords) {
      setPosition(JSON.parse(storedCoords));
      userKnows.current = true;
    }
  }, []);

  function handleStop(_e: DraggableEvent, data: DraggableData) {
    const newPos = { x: data.x, y: data.y };
    // intervene if drag handle leaves the browser window --
    if (newPos.y <= -75) newPos.y = -75; // TOP
    const content = document.getElementById("dragSize");
    if (content) {
      const { width, height } = content.getBoundingClientRect();
      if (newPos.x >= window.innerWidth - width - 10) {
        newPos.x = window.innerWidth - width - 10; // RIGHT
      }
      if (newPos.y > window.innerHeight - height) {
        newPos.y = window.innerHeight - height; // BOTTOM
      }
      if (newPos.x < -width) newPos.x = -width; // LEFT

      newPos.y = Math.round(newPos.y);
      newPos.x = Math.round(newPos.x);
    } else {
      logger.error("Expected element with id 'dragSize' not found"); // no need to return or throw
    }

    userKnows.current = true;
    setPosition(newPos);
    setIsDragging(false);
    localStorage.setItem("activeCallBubblePosition", JSON.stringify(newPos));
  }

  const dragHandleButton = (
    <button
      className={`${isDragging ? "cursor-grabbing" : "cursor-grab"} rotate-90 rounded-lg bg-sky-800 text-white`}
      aria-label="click and drag to move the active call bubble"
    >
      <DragIndicatorIcon style={dragIconStyles} />
    </button>
  );

  return (
    <Draggable
      nodeRef={nodeRef}
      handle=".dragHandle"
      position={position}
      defaultPosition={{ x: 0, y: 0 }}
      onStart={() => setIsDragging(true)}
      onStop={handleStop}
    >
      <Transition appear show={true} as="div" ref={nodeRef}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div
            id="dragSize"
            className={`${
              isDragging
                ? "shadow-darker"
                : "shadow-elevation ring-1 ring-black/30"
            } inline-block w-fit max-w-60 transform rounded-xl border-2 border-sky-600 bg-sky-100 px-3 pb-1 pt-2 text-sky-900 transition-all`}
          >
            {/*
            - dragSize id exists so we can ensure the drag handle stays within the screen bounds
            - dragHandle div has some padding on it so the target is larger
            - omitting the tooltip if the user is aware that the bubble is draggable
            */}
            <div
              className={`dragHandle p-2 pb-0 ${isDragging ? "cursor-grabbing" : "cursor-grab"} absolute -right-3 -top-[22px]`}
            >
              {userKnows ? (
                dragHandleButton
              ) : (
                <StyledTooltip title="Drag to move">
                  {dragHandleButton}
                </StyledTooltip>
              )}
            </div>

            {props.children}
          </div>
        </Transition.Child>
      </Transition>
    </Draggable>
  );
}
