//Libs

//Local
import { VroomBreak, VroomManager, VroomVehicle } from "../../../models/vroom";
import { ExistingVehicle } from "../../../models/vehicle";
import { ExistingSiteKeyLocation } from "../../../models/site-key-location";
import {
  // convertFSTimestampToLuxonDT,
  getIntegerFromCoordinate,
  convertFSTimestampToLuxonDT,
} from "../../../utils";
import { ExistingStiltCalendarEvent } from "../../../models/stilt-calendar-event";
import { DateTime } from "luxon";

export const VROOM_VEHICLE_CONFIG = {
  DEFAULT_START_HOUR: 6,
  DEFAULT_START_MINUTE: 30,
  DEFAULT_END_HOUR: 23,
  DEFAULT_END_MINUTE: 59,
};

function getBreaksFromStiltEvents(
  stiltEvents: ExistingStiltCalendarEvent[],
  stiltVehicle: ExistingVehicle,
  day: DateTime,
): VroomBreak[] {
  const breaks: VroomBreak[] = [];
  stiltEvents.forEach((event) => {
    let startTime = convertFSTimestampToLuxonDT(event.start);
    let endTime = convertFSTimestampToLuxonDT(event.end);

    // Get the vehicleStartTime and vehicleEndTime from the vehicle using the day and vehicle's start and end times
    const vehicleStartTime = day.set({
      hour: parseInt(
        stiltVehicle.startTime?.split(":")[0] ||
          VROOM_VEHICLE_CONFIG.DEFAULT_START_HOUR.toString(),
      ),
      minute: parseInt(
        stiltVehicle.startTime?.split(":")[1] ||
          VROOM_VEHICLE_CONFIG.DEFAULT_START_MINUTE.toString(),
      ),
    });
    const vehicleEndTime = day.set({
      hour: parseInt(
        stiltVehicle.endTime?.split(":")[0] ||
          VROOM_VEHICLE_CONFIG.DEFAULT_END_HOUR.toString(),
      ),
      minute: parseInt(
        stiltVehicle.endTime?.split(":")[1] ||
          VROOM_VEHICLE_CONFIG.DEFAULT_END_MINUTE.toString(),
      ),
    });

    // If the event starts after the end of the vehicle's working hours, skip it
    if (startTime > vehicleEndTime) {
      console.log(
        "Event starts after vehicle's working hours",
        event,
        vehicleEndTime,
      );
      return;
    }

    // If the event ends before the start of the vehicle's working hours, skip it
    if (endTime < vehicleStartTime) {
      console.log(
        "Event ends before vehicle's working hours",
        event,
        vehicleStartTime,
      );
      return;
    }

    // If the event starts before the start of the vehicle's working hours, set it to the start of the vehicle's working hours
    if (startTime < vehicleStartTime) {
      startTime = vehicleStartTime;
    }

    // If the event ends after the end of the vehicle's working hours, set it to the end of the vehicle's working hours
    if (endTime > vehicleEndTime) {
      endTime = vehicleEndTime;
    }

    // Get difference between start and end time in seconds
    const eventDuration = Math.ceil(endTime.diff(startTime, "seconds").seconds);

    breaks.push({
      id: parseInt(event.id) || Math.floor(Math.random() * 1000000), // Fallback to random ID if event.id can't be parsed
      time_windows: [
        [Math.ceil(startTime.toSeconds()), Math.ceil(startTime.toSeconds())],
      ],
      service: eventDuration,
      description: event.title || "Calendar Break",
      max_load: [],
    });
  });

  return breaks;
}

export function convertToVroomVehicles(args: {
  vehicles: ExistingVehicle[];
  maxTasks: number;
  siteKeyWorkTypes: number[];
  siteKeyTaskTypes: number[];
  getLocationDoc: (locationID: string) => ExistingSiteKeyLocation;
  portableSkill: number;
  vehicleIDsAsSkills: Record<string, number>;
  stiltEvents: ExistingStiltCalendarEvent[];
  day: DateTime;
}): VroomVehicle[] {
  const vroomVehicleList: VroomVehicle[] = [];

  // let portablesUsed = 0;
  for (let i = 0; i < args.vehicles.length; i++) {
    const vehicle = args.vehicles[i];

    // -------
    // SETUP SKILLS
    const skills = VroomManager.getVehicleSkills({
      workTypes: vehicle.craftTypes,
      taskTypes: vehicle.taskTypes,
      siteKeyWorkTypes: args.siteKeyWorkTypes,
      siteKeyTaskTypes: args.siteKeyTaskTypes,
    });
    // passing the actual location doc's longitude, instead of the vehicle's.
    // later, we're gonna allow vehicle lat/lng to change on the fly.. so
    // this won't need to be refactored when that time comes
    const locationDoc = args.getLocationDoc(vehicle.locationID);
    const locationSkill = getIntegerFromCoordinate(locationDoc.longitude);
    skills.push(locationSkill);

    // Push the vehicle id as a skill so we can "lock" certain tasks to that
    // vehicle using the skill mechanism
    if (args.vehicleIDsAsSkills[vehicle.id]) {
      skills.push(args.vehicleIDsAsSkills[vehicle.id]);
    }

    // we can assign args.portableSkill to a maximum of 4 vehicles
    /**
     * how to determine which vehicles should get portableSkill?
     *
     * - 1st vroom API call = send portable jobs and max 4 trucks.
     * - 2nd call = send all, with some adjustment / extra params
     *   added to the already scheduled portable jobs.(don’t forget
     *   about the ‘priority’ property)
     *
     * What’s the vroom plan for when they need to use two trucks for a job?
     */

    // console.log(`VEHICLE ${vehicle.title}\nSKILLS`, skills); // REMOVE:

    // -------

    let vehicleWorkingHours: number[] | null = null;

    // Use vehicle's custom hours if available, otherwise use defaults
    const startDate = args.day.set({
      hour: vehicle.startTime
        ? parseInt(vehicle.startTime.split(":")[0])
        : VROOM_VEHICLE_CONFIG.DEFAULT_START_HOUR,
      minute: vehicle.startTime
        ? parseInt(vehicle.startTime.split(":")[1])
        : VROOM_VEHICLE_CONFIG.DEFAULT_START_MINUTE,
      second: 0,
    });
    const endDate = args.day.set({
      hour: vehicle.endTime
        ? parseInt(vehicle.endTime.split(":")[0])
        : VROOM_VEHICLE_CONFIG.DEFAULT_END_HOUR,
      minute: vehicle.endTime
        ? parseInt(vehicle.endTime.split(":")[1])
        : VROOM_VEHICLE_CONFIG.DEFAULT_END_MINUTE,
      second: 59,
    });
    vehicleWorkingHours = [
      Math.ceil(startDate.toSeconds()),
      Math.ceil(endDate.toSeconds()),
    ];

    // Get breaks from the stiltEvents
    const breaks = getBreaksFromStiltEvents(
      args.stiltEvents.filter((ev) =>
        ev.assignedVehicleIDs?.includes(vehicle.id),
      ),
      vehicle,
      args.day,
    );

    const vroomVehicle: VroomVehicle = {
      id: i,
      skills: skills,
      start: [vehicle.longitude, vehicle.latitude],
      end: [vehicle.longitude, vehicle.latitude],
      max_tasks: vehicle.maxTasksPerDay ?? args.maxTasks,
      description: vehicle.id,
      time_window: vehicleWorkingHours,
      breaks: breaks,
    };

    vroomVehicleList.push(vroomVehicle);
  }
  return vroomVehicleList;
}

/** @returns the duration of the event in hours */
// function getEventDuration(ev: ExistingStiltCalendarEvent): number {
//   const start = convertFSTimestampToLuxonDT(ev.start);
//   const end = convertFSTimestampToLuxonDT(ev.end);
//   const diff = end.diff(start, "hours");
//   return diff.hours;
// }
