/**
 * Converts a coordinate to a non-negative integer.
 * @throws Error if the coordinate is not a number or is not finite.
 * @example
 * getIntegerFromCoordinate(-82.9046847); // 829046847
 */
export function getIntegerFromCoordinate(coord: number): number {
  if (typeof coord !== "number" || isNaN(coord) || !isFinite(coord)) {
    throw new Error(`Received invalid coordinate: ${coord}`);
  }

  const str = Math.abs(coord).toString().split(".").join("");
  const number = Number(str);
  if (number > 999999999) {
    // Return a number that is 999999999 by truncating the number
    return Number(str.slice(0, 9));
  }
  return number;
}
