// Libs
import { DocumentData, DocumentSnapshot, Timestamp } from "firebase/firestore";
import { z } from "zod";

// Local
import { NotFoundError } from "../error-classes";
import { dropUndefined, guardIsPlainObject } from "../utils";
import { logger } from "../logging";
import { zFallback } from "../utils/zod-fallback";

export interface ChatMessageReadStatus {
  userId: string;
  chatRoomID: string;
  lastReadTimestamp: Timestamp;
  unreadCount: number;
}

export interface ExistingChatMessageReadStatus extends ChatMessageReadStatus {
  id: string;
  refPath: string;
}

export const ChatMessageReadStatusManager = {
  /**
   * Convert the Document Snapshot into a validated ExistingChatMessageReadStatus object.
   */
  createFromFirestoreSnapshot: createChatMessageReadStatusFromFirestoreSnapshot,
  /** Drop `id` and `refPath` before saving to the database */
  convertForFirestore: convertChatMessageReadStatusForFirestore,
  /** Use when validating something outgoing - writing to the DB or reading from the user */
  parse: validateChatMessageReadStatus,
  /** Validate a ChatMessageReadStatus doc, with fallbacks. Use for reading from the database. */
  parseWithFallbacks: validateChatMessageReadStatusWithFallbacks,
  /** Create a new read status */
  createNew: createNewChatMessageReadStatus,
};

/**
 * Create a new chat message read status
 */
function createNewChatMessageReadStatus(
  userId: string,
  chatRoomID: string,
  unreadCount: number = 0,
): ChatMessageReadStatus {
  const now = Timestamp.now();

  return {
    userId,
    chatRoomID,
    lastReadTimestamp: now,
    unreadCount,
  };
}

/**
 * Convert the Document Snapshot into a validated ExistingChatMessageReadStatus object.
 */
function createChatMessageReadStatusFromFirestoreSnapshot(
  snapshot: DocumentSnapshot,
): ExistingChatMessageReadStatus {
  if (!snapshot.exists()) {
    throw new NotFoundError(
      `Document does not exist. refPath: ${snapshot.ref.path}`,
    );
  }

  const snapshotData = snapshot.data();
  return {
    id: snapshot.id,
    refPath: snapshot.ref.path,
    ...validateChatMessageReadStatusWithFallbacks(snapshotData),
  };
}

const chatMessageReadStatusSchema = z.object({
  userId: z.string(),
  chatRoomID: z.string(),
  lastReadTimestamp: z.instanceof(Timestamp),
  unreadCount: z.number().int().nonnegative(),
});

function validateChatMessageReadStatus(
  data: DocumentData,
): ChatMessageReadStatus {
  const parsedData = chatMessageReadStatusSchema.parse(data);
  return parsedData;
}

const chatMessageReadStatusSchemaWithFallbacks = z.object({
  userId: zFallback(
    z.string(),
    "",
    "chatMessageReadStatusSchemaWithFallbacks: 'userId'",
  ),
  chatRoomID: zFallback(
    z.string(),
    "",
    "chatMessageReadStatusSchemaWithFallbacks: 'chatRoomID'",
  ),
  lastReadTimestamp: zFallback(
    z.instanceof(Timestamp),
    Timestamp.now(),
    "chatMessageReadStatusSchemaWithFallbacks: 'lastReadTimestamp'",
  ),
  unreadCount: zFallback(
    z.number().int().nonnegative(),
    0,
    "chatMessageReadStatusSchemaWithFallbacks: 'unreadCount'",
  ),
});

function validateChatMessageReadStatusWithFallbacks(
  value: unknown,
): ChatMessageReadStatus {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }

  try {
    // Parse with the fallback schema
    const result = chatMessageReadStatusSchemaWithFallbacks.parse(value);
    return result;
  } catch (error) {
    logger.warn("Error parsing chat message read status with fallbacks", error);

    // If even the fallback schema fails, return a completely default object
    return {
      userId: "",
      chatRoomID: "",
      lastReadTimestamp: Timestamp.now(),
      unreadCount: 0,
    };
  }
}

function convertChatMessageReadStatusForFirestore(
  chatMessageReadStatus: ChatMessageReadStatus,
): DocumentData {
  const local = Object.assign({}, chatMessageReadStatus);
  const { ...rest } = local;
  return dropUndefined(rest);
}
