import DatePicker from "react-datepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// Local
import { logger } from "../logging";

interface Props {
  value: string | null;
  onChange: (value: Date) => void;
  inputName: string;
  displayText: string;
  required?: boolean;
  addWrapperStyles?: string;
  /** default is max-w-fit */
  overrideWidth?: string;
  addInputStyles?: string;
  overrideInputStyles?: string;
}

export default function BaseInputDate(props: Props) {
  function handleChange(ev: Date | null): void {
    try {
      // TODO: once back on main, allow null to trigger props.onChange (want to
      // have the option of letting the user clear out a date)
      if (ev) props.onChange(ev);
    } catch (e) {
      logger.warn(e);
    }
  }

  return (
    <div
      className={`relative ${props.addWrapperStyles ?? ""} ${props.overrideWidth ?? "max-w-fit"}`}
    >
      <DatePicker
        selected={props.value ? new Date(props.value) : null}
        onChange={handleChange}
        customInput={
          <input
            id={props.inputName}
            className={`${props.overrideInputStyles ?? `inline-block rounded-md border border-gray-500 bg-white px-3 py-2 text-gray-600 transition-colors hover:border-gray-800 focus:border-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 ${props.overrideWidth ?? "max-w-fit"} ${props.addInputStyles ?? ""}`}`}
            type="text"
            value={props.value ?? ""}
          />
        }
      />

      {/* pointer-events-none allows the input element to be focused when the icon is clicked */}
      <span className="pointer-events-none absolute -right-1 top-2 px-2">
        <CalendarMonthIcon
          className="text-gray-500"
          style={{ width: "1.6rem", height: "1.6rem" }}
        />
      </span>

      <label
        htmlFor={props.inputName}
        className="absolute -top-5 left-0 text-nowrap bg-white px-1 leading-none"
      >
        {props.displayText}
        {props.required ? (
          <span className="font-medium leading-none text-redFail"> *</span>
        ) : (
          ""
        )}
      </label>
    </div>
  );
}
