import { useState } from "react";
import CheckIcon from "@heroicons/react/24/solid/CheckIcon";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

// Local
import BaseInputTextArea from "../BaseInputTextArea";
import BaseInputNumber from "../BaseInputNumber";
import currencyFormatter from "../../currency";
import { getTemporaryItemTotal } from "../../assets/js/estimateFunctions";
import { StiltLineItemFormData } from "../../models/stilt-payment";
import { ExistingCustomer } from "../../models/customer";
import StyledMessage from "../StyledMessage";
import BaseInputText from "../BaseInputText";
import { StyledTooltip } from "../StyledTooltip";
import { TrashButton } from "../TrashButton";

interface Props {
  lineItem: StiltLineItemFormData;
  currency: string;
  siteKey: string;
  totalTaxRate: number;
  globalDiscount: number;
  customer: ExistingCustomer | null;
  handleSave: (item: StiltLineItemFormData) => Promise<void>;
  handleDeleteItem: () => void;
}

/** this is a partial of StiltLineItemFormData */
const schema = z.object({
  description: z.string().min(0).max(4000),
  quantity: z.number().or(z.literal("")),
  discount: z.number().or(z.literal("")),
  unitPrice: z.number().or(z.literal("")),
});
type FormType = z.infer<typeof schema>;

/** edit a line item for a bespoke membership invoice */
export default function EditLineItem(props: Props) {
  const [unitPrice, setUnitPrice] = useState(props.lineItem.unitPrice);
  const [discount, setDiscount] = useState(props.lineItem.discount);
  const [quantity, setQuantity] = useState(props.lineItem.quantity);

  const { control, handleSubmit } = useForm<FormType>({
    resolver: zodResolver(schema),
    defaultValues: props.lineItem,
  });

  const onSubmit = async (formValues: FormType) => {
    const updatedItem: StiltLineItemFormData = {
      ...props.lineItem,
      toBeEdited: false,
      description: formValues.description,
      unitPrice: formValues.unitPrice === "" ? 0 : formValues.unitPrice,
      quantity: formValues.quantity === "" ? 0 : formValues.quantity,
      discount: formValues.discount === "" ? 0 : formValues.discount,
    };
    await props.handleSave(updatedItem);
  };

  return (
    <div className="flex flex-col items-center sm:grid sm:grid-cols-[5fr,1fr] lg:grid-cols-[8fr,1fr]">
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-2 gap-4 lg:grid-cols-[2fr,1fr,1fr,1fr]">
          <StyledTooltip title="Title is a read-only field">
            <BaseInputText
              id="overrideInvoice_lineItemTitle"
              text="Title"
              value={props.lineItem.title ?? ""}
              admin={true}
              disabled={true}
            />
          </StyledTooltip>

          <Controller
            name="quantity"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div>
                <BaseInputNumber
                  text="Quantity"
                  inputName="quantity"
                  admin={true}
                  required={true}
                  {...field}
                  onChange={(ev) => {
                    const n = ev.target.valueAsNumber;
                    field.onChange(isNaN(n) ? "" : n);
                    setQuantity(isNaN(n) ? 0 : n);
                  }}
                />
                {error && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: error.message }}
                    </StyledMessage>
                  </div>
                )}
              </div>
            )}
          />

          <Controller
            name="unitPrice"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div>
                <BaseInputNumber
                  text="Unit Price"
                  inputName="unitPrice"
                  admin={true}
                  required={true}
                  {...field}
                  onChange={(event) => {
                    const n = event.target.valueAsNumber;
                    field.onChange(isNaN(n) ? "" : n);
                    setUnitPrice(isNaN(n) ? 0 : n);
                  }}
                />
                {error && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: error.message }}
                    </StyledMessage>
                  </div>
                )}
              </div>
            )}
          />

          <Controller
            name="discount"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <div>
                {props.lineItem.discountable && (
                  <BaseInputNumber
                    text="Discount (%)"
                    inputName="discount"
                    admin={true}
                    required={false}
                    disabled={!props.lineItem.discountable}
                    {...field}
                    onChange={(event) => {
                      const n = event.target.valueAsNumber;
                      field.onChange(isNaN(n) ? "" : n);
                      setDiscount(isNaN(n) ? 0 : n);
                    }}
                  />
                )}
                {error && (
                  <div className="mt-2 text-sm">
                    <StyledMessage type="error">
                      {{ message: error.message }}
                    </StyledMessage>
                  </div>
                )}
              </div>
            )}
          />
        </div>

        <Controller
          name="description"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div>
              <BaseInputTextArea
                text="Description"
                inputName="description"
                admin={true}
                required={false}
                rows={3}
                {...field}
              />
              {error && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: error.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          )}
        />
      </div>

      <div className="mt-4 flex flex-row items-center justify-between gap-4 sm:mt-0 sm:flex-col sm:items-end">
        <div className="text-lg font-bold">
          {currencyFormatter(
            getTemporaryItemTotal(
              quantity,
              unitPrice,
              props.lineItem.discountable ?? false,
              discount,
            ),
            props.currency,
          )}
        </div>

        <button
          type="button"
          className="mx-1 rounded-full border border-gray-300 bg-white hover:bg-green-50"
          onClick={handleSubmit(onSubmit)}
        >
          <CheckIcon
            aria-label="confirm button"
            className="block h-7 w-7 p-0.5 text-green-700"
          />
        </button>

        <span className="mx-1 h-8 w-8 rounded-full border border-gray-300 bg-white hover:bg-gray-50">
          <TrashButton
            height="1.9rem"
            width="1.9rem"
            padding="4px"
            overrideMargin="mx-0"
            color="text-red-700"
            hoverColor="hover:bg-red-50"
            onDelete={props.handleDeleteItem}
          />
        </span>
      </div>
    </div>
  );
}
