import * as strings from "../../strings";
import HeadingOne from "../HeadingOne";

interface Props {
  merchantLogoURL: string | null;
}
/**
 * Contains "Feedback" heading and merchant's logo, if available.
 *
 * For the review request page.
 */
export function TheHeaderArea(props: Props): JSX.Element {
  return (
    <div
      className={`mb-8 ${
        props.merchantLogoURL
          ? "md:flex md:flex-row-reverse md:items-center"
          : ""
      }`}
    >
      {props.merchantLogoURL && (
        <img
          src={props.merchantLogoURL}
          alt="merchant logo"
          className="mx-auto mb-10 max-h-[6rem] w-auto md:mb-0 md:mr-0 md:max-h-full md:max-w-[15rem]"
        />
      )}
      <HeadingOne
        className={props.merchantLogoURL ? "text-center md:flex-row" : ""}
      >
        {strings.FEEDBACK}
      </HeadingOne>
    </div>
  );
}
