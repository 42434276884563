// Libs
import { Switch } from "@headlessui/react";
import { forwardRef } from "react";

//Local
import { StyledTooltip } from "./StyledTooltip";

type Ref = HTMLSpanElement;
type Props = {
  checked: boolean;
  /** for label's "for" attribute  */
  name: string;
  /** for label's display text & for screen reader */
  readableName: string;
  tooltipText?: string;
  onChange: (value: unknown) => void;
  onBlur: () => void;
  /** default is "medium" if not specified */
  size?: "small" | "medium";
} & React.ComponentPropsWithRef<"button">;

const StyledSwitchGroup = forwardRef<Ref, Props>(
  (
    {
      checked,
      name,
      readableName,
      tooltipText,
      onChange,
      onBlur,
      size = "medium",
      ...props
    },
    _ref,
  ): JSX.Element => {
    return (
      <StyledTooltip title={tooltipText}>
        <span
          className={`flex items-center ${size === "small" ? "space-x-2" : "space-x-4"}`}
        >
          <Switch.Group>
            <Switch.Label
              className={props.disabled ? "text-gray-500" : ""}
              htmlFor={name}
            >
              {readableName}
            </Switch.Label>
            <Switch
              value={checked.toString()}
              disabled={props.disabled}
              checked={checked}
              onChange={onChange}
              onBlur={onBlur}
              className={`${size === "small" ? "h-5 w-9" : "h-6 w-11"} ${
                checked ? "bg-greenPass" : "bg-gray-200"
              } relative inline-flex flex-shrink-0 items-center rounded-full`}
            >
              <span className="sr-only">
                {checked ? "Disable " : "Enable "}
                {readableName}
                {props.disabled ? " (this toggle is disabled)" : ""}
              </span>
              <span
                className={`${size === "small" ? "h-3.5 w-3.5" : "h-4 w-4"} ${getTranslateX(
                  size,
                  checked,
                )} inline-block transform rounded-full bg-white transition duration-200 ease-in-out`}
              />
            </Switch>
          </Switch.Group>
        </span>
      </StyledTooltip>
    );
  },
);
export default StyledSwitchGroup;

function getTranslateX(size: "small" | "medium", checked: boolean): string {
  if (size === "small") {
    if (checked) return "translate-x-[1.12rem]";
    return "translate-x-1";
  }
  if (checked) return "translate-x-6";
  return "translate-x-1";
}
