// Libs
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import type { SvgIconComponent } from "@mui/icons-material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import SiteIcon from "@mui/icons-material/Public";
import NotificationsIcon from "@mui/icons-material/Notifications";
// import LocationsIcon from "@mui/icons-material/RoomTwoTone";
// import AddSitekeyIcon from "@mui/icons-material/AddHome";
// import AssetsIcon from "@mui/icons-material/DashboardCustomizeRounded";
import EditLocationsIcon from "@mui/icons-material/EditLocation";
import CompaniesIcon from "@mui/icons-material/Factory";
import UsersIcon from "@mui/icons-material/Group";

//Local
import { DbRead, DbWrite } from "../database";
import { ExistingSiteKeyUserDoc } from "../models/site-key-users";
import { AVATAR_DEFAULT } from "../urls";
import {
  UserInfoListItem,
  UserInfoList,
  UserInfoCheckboxItem,
  UserInfoNav,
} from "../components/UserInfo";
import {
  ADMIN_LIST_COMPANIES_URL,
  ADMIN_LIST_LOCATIONS_URL,
  ADMIN_USER_MANAGEMENT_URL,
} from "../urls";
import EditUserPhoneDialog from "../components/admin/EditUserPhoneDialog";
import * as strings from "../strings";
import StyledSwitchGroup from "../components/StyledSwitchGroup";
import { ExistingSiteKey } from "../models/site-key";
import LoadingSpinner from "../components/LoadingSpinner";
import { phoneUtils } from "../utils/phoneUtils";
import { useRootUserStore } from "../store/root-user";
import SelectDefaultSiteKeyDialog from "./SelectDefaultSiteKeyDialog";

interface ListItemInputs {
  icon: SvgIconComponent;
  heading: string;
  subheading: string;
  isAdmin: boolean;
  path?: string;
}

interface Props {
  siteKey: ExistingSiteKey;
  userId: string;
}

// (alex) TODO: debug this... sometimes it takes a stupid long time to load
function UserSettings({ siteKey, userId }: Props) {
  const rootUser = useRootUserStore((state) => state.rootUser);
  const [isEditPhoneDialogOpen, setEditPhoneDialogOpen] = useState(false);
  const [callForwardingEnabled, setCallForwardingEnabled] = useState(
    siteKey?.customizations?.phones?.callForwardingEnabled === true,
  );
  const [callForwardingLoading, setCallForwardingLoading] = useState(false);
  const [isSelectingDefaultSiteKeyOpen, setIsSelectingDefaultSiteKeyOpen] =
    useState(false);

  const handleEditPhoneClick = () => {
    setEditPhoneDialogOpen(true);
  };

  const handleToggleNotifications = async (
    event: React.FormEvent<HTMLInputElement>,
  ) => {
    await DbWrite.rootUser.update(
      siteKey.id as string,
      { receiveNotifications: event.currentTarget.checked },
      userId,
    );
  };

  const closeEditPhoneDialog = () => {
    setEditPhoneDialogOpen(false);
  };

  const navigate = useNavigate();

  const handleClick = (path: string) => {
    navigate(path);
  };

  //QUERIES TO GET USER AND PERMISSIONS DOC
  const userQueryKey = ["user", siteKey, userId];
  const { data: userData } = useQuery(userQueryKey, () =>
    DbRead.user.getUserDoc(siteKey.id as string, userId),
  );

  // get user permissions with useEffect
  const userPermissionsQueryKey = ["userPermissions", siteKey, userId];
  const { data: userPermissions } = useQuery(userPermissionsQueryKey, () =>
    DbRead.user.getSiteKeyPermissions(siteKey.id as string, userId),
  );

  const { data: siteKeyList = [], isLoading: isLoadingSiteKeyList } = useQuery(
    ["userSettings_siteKeyList"],
    () => DbRead.siteKey.getRootUserListOfSiteKeyDocs(),
  );

  if (!userData || isLoadingSiteKeyList) {
    return <div>Loading...</div>;
  }

  const validatedUserData = userData as ExistingSiteKeyUserDoc;

  async function handleCallForwardingToggle(value: boolean) {
    setCallForwardingEnabled(value);
    setCallForwardingLoading(true);
    await DbWrite.siteKey.setCallForwarding(siteKey.id as string, value);
    setCallForwardingLoading(false);
  }

  async function handleSelectionNewSiteKey(selectedSiteKey: string) {
    await DbWrite.rootUser.update(
      siteKey.id as string,
      { defaultSiteKey: selectedSiteKey },
      userId,
    );
  }

  const UserInfo: ListItemInputs[] = [
    {
      icon: PhoneIcon,
      heading: "Phone",
      subheading: phoneUtils.display(validatedUserData.phone),
      isAdmin: false,
    },
    {
      icon: EmailIcon,
      heading: "Email",
      subheading: validatedUserData.email,
      isAdmin: false,
    },
    {
      icon: SiteIcon,
      heading: "Default Site",
      subheading: rootUser?.defaultSiteKey ?? "",
      isAdmin: false,
    },
    {
      icon: NotificationsIcon,
      heading: "Notifications",
      subheading: "ON / OFF",
      isAdmin: false,
    },
    // TODO: Locations
    // {
    //   icon: LocationsIcon,
    //   heading: "Locations",
    //   subheading: "Select Locations",
    //   isAdmin: false,
    // },
    // TODO: Add Site Key
    // {
    //   icon: AddSitekeyIcon,
    //   heading: "Add Site Key",
    //   subheading: "Apply to another site",
    //   isAdmin: true,
    // },
  ];

  const AdminOptions: ListItemInputs[] = [
    // TODO: Assets
    // {
    //   icon: AssetsIcon,
    //   heading: "Assets",
    //   subheading: "Edit Assets",
    //   isAdmin: false,
    //   path: ,
    // },
    {
      icon: EditLocationsIcon,
      heading: "Locations",
      subheading: "Edit Locations",
      isAdmin: false,
      path: ADMIN_LIST_LOCATIONS_URL,
    },
    {
      icon: CompaniesIcon,
      heading: "Companies",
      subheading: "Edit Companies",
      isAdmin: false,
      path: ADMIN_LIST_COMPANIES_URL,
    },
    {
      icon: UsersIcon,
      heading: "Users",
      subheading: "Manage Users",
      isAdmin: false,
      path: ADMIN_USER_MANAGEMENT_URL,
    },
  ];

  const editUserPhoneDialog = (
    <EditUserPhoneDialog
      siteKey={siteKey.id as string}
      userID={validatedUserData.id}
      userPhone={validatedUserData.phone}
      isDialogOpen={isEditPhoneDialogOpen}
      closeDialog={closeEditPhoneDialog}
    />
  );

  const selectDefaultSiteKeyDialog = rootUser && (
    <SelectDefaultSiteKeyDialog
      isDialogOpen={isSelectingDefaultSiteKeyOpen}
      closeDialog={() => setIsSelectingDefaultSiteKeyOpen(false)}
      defaultSiteKey={rootUser.defaultSiteKey}
      handleSelectionNewSiteKey={handleSelectionNewSiteKey}
      siteKeyList={siteKeyList}
    />
  );

  return (
    <div className="flex max-w-screen-xl flex-col rounded-md px-6 py-2 shadow-darker">
      <div className="py-3">
        <UserInfoNav
          name={validatedUserData.displayName}
          company={validatedUserData.companyName}
          position={validatedUserData.jobTitle}
          avatarUrl={validatedUserData.userPhoto_URL || AVATAR_DEFAULT}
        />
      </div>
      <div className="p-3 md:flex">
        <div className="w-full md:border-r-2 md:pr-8">
          <h2 className="mb-2 text-xl font-semibold text-primary">User Info</h2>
          <UserInfoList>
            {UserInfo.map((obj) => {
              if (obj.heading === "Notifications") {
                return (
                  <UserInfoCheckboxItem
                    key={obj.heading}
                    icon={obj.icon}
                    heading={obj.heading}
                    subheading={obj.subheading}
                    onChange={handleToggleNotifications}
                    isAdmin={obj.isAdmin}
                  />
                );
              } else if (obj.heading === "Phone") {
                return (
                  <UserInfoListItem
                    key={obj.heading}
                    icon={obj.icon}
                    heading={obj.heading}
                    subheading={obj.subheading}
                    onClick={handleEditPhoneClick}
                    isAdmin={obj.isAdmin}
                  />
                );
              } else if (obj.heading === "Default Site") {
                return (
                  <UserInfoListItem
                    key={obj.heading}
                    icon={obj.icon}
                    heading={obj.heading}
                    subheading={obj.subheading}
                    onClick={() => setIsSelectingDefaultSiteKeyOpen(true)}
                    isAdmin={obj.isAdmin}
                  />
                );
              }
              return (
                <UserInfoListItem
                  key={obj.heading}
                  icon={obj.icon}
                  heading={obj.heading}
                  subheading={obj.subheading}
                  onClick={() => console.log(obj.heading)}
                  isAdmin={obj.isAdmin}
                />
              );
            })}
          </UserInfoList>
        </div>
        <div className="mt-6 w-full sm:mt-0">
          <h2 className="mb-2 pl-6 text-xl font-semibold text-primary">
            Admin Options
          </h2>
          <UserInfoList className="md:pl-8">
            {AdminOptions.map((obj) => {
              return (
                <UserInfoListItem
                  key={obj.heading}
                  icon={obj.icon}
                  heading={obj.heading}
                  subheading={obj.subheading}
                  onClick={obj.path ? () => handleClick(obj.path!) : () => {}}
                  isAdmin={obj.isAdmin}
                />
              );
            })}
            {/* Call Forwarding Switch */}
            {(userPermissions?.permissions.isSiteAdmin ?? false) &&
              siteKey?.customizations?.phones?.callForwardingNumber && (
                <div className="flex flex-row py-4">
                  {callForwardingLoading && (
                    <div>
                      <LoadingSpinner />
                    </div>
                  )}
                  {!callForwardingLoading && (
                    <StyledSwitchGroup
                      readableName={strings.ENABLE_CALL_FORWARDING}
                      onBlur={() => {}}
                      onChange={() =>
                        handleCallForwardingToggle(!callForwardingEnabled)
                      }
                      checked={callForwardingEnabled}
                      id="callForwardingEnabled"
                      name={"callForwardingEnabled"}
                    />
                  )}

                  <span className="px-2">
                    {phoneUtils.display(
                      siteKey.customizations.phones.callForwardingNumber,
                    )}
                  </span>
                </div>
              )}
          </UserInfoList>
        </div>
      </div>
      {editUserPhoneDialog}
      {selectDefaultSiteKeyDialog}
    </div>
  );
}

export default UserSettings;
