//Libs
import { z } from "zod";
import { guardIsPlainObject } from "../utils";
import { CraftTypeValues } from "./craft-types";
import { TaskTypesValues } from "./task-types";

// Meant to match the format of the VROOM server
/// https://github.com/VROOM-Project/vroom/blob/master/docs/API.md

// VIOLATION OBJECT -- https://github.com/VROOM-Project/vroom/blob/master/docs/API.md#violation
// not gonna strongly type it, but that's an option
interface Violation {
  cause: string;
  duration?: number;
}

const violationSchema: z.ZodType<Violation> = z.object({
  cause: z.string(),
  duration: z.number().optional(),
});

export interface VroomStep {
  /** Stilt Task ID. Will be undefined when type is "start" or "end" */
  description?: string;
  /** a string (either start, job, pickup, delivery, break or end) */
  type: string;
  /** estimated time of arrival at this step */
  arrival: number;
  /** cumulated travel time upon arrival at this step */
  duration: number;
  /** setup time at this step */
  setup: number;
  /** service time at this step */
  service: number;
  /** waiting time upon arrival at this step */
  waiting_time: number;
  /** coordinates array for this step (if provided in input) */
  location?: number[];
  /** id of the task performed at this step, only provided if type value is job, pickup, delivery or break */
  id?: number;

  /** array of violation objects for this step */
  violations: Violation[];
  /** vehicle load after step completion (with capacity constraints) */
  load?: number[];
  /** traveled distance upon arrival at this step - provided when using the -g flag */
  distance?: number;
}

const vroomStepSchema: z.ZodType<VroomStep> = z
  .object({
    description: z.string().min(1).max(200).optional(),
    type: z.string().min(1).max(200),
    arrival: z.number(),
    duration: z.number(),
    setup: z.number(),
    service: z.number(),
    waiting_time: z.number(),
    location: z.array(z.number()).optional(),
    id: z.number().optional(),
    violations: z.array(violationSchema),
    load: z.array(z.number()).optional(),
    distance: z.number().optional(),
  })
  .refine(
    (step) => {
      if (step.type === "start" || step.type === "end") {
        return step.id === undefined && step.description === undefined;
      } else {
        return (
          typeof step.id === "number" && typeof step.description === "string"
        );
      }
    },
    {
      message:
        "Expect id and description to be undefined for start and end. Otherwise, values are expected.",
    },
  );

export interface VroomRoute {
  /** VroomVehicle.id assigned to this route */
  vehicle: number;
  steps: VroomStep[];
  /** cost for this route */
  cost: number;
  /** total setup time */
  setup: number;
  /** total service time */
  service: number;
  /** total travel time */
  duration: number;
  /** total waiting time */
  waiting_time: number;
  /** total priority sum for tasks in this route */
  priority: number;
  /** total route distance. provided when using the -g flag or passing distance matrices in input */
  distance?: number;
  /** array of violation objects for this route */
  violations: Violation[];

  /** Stilt vehicleID. from VroomVehicle */
  description: string; // technically optional, but we're making it required

  // [delivery]	total delivery for tasks in this route
  // [pickup]	total pickup for tasks in this route
  // [geometry]*	polyline encoded route geometry
}

export interface VroomUnassigned {
  id: number;
  location: number[]; // Tuple to represent lon and lat
  type: string;
  description: string;
  reason?: string;
}

const vroomRouteSchema: z.ZodType<VroomRoute> = z.object({
  vehicle: z.number(),
  steps: z.array(vroomStepSchema),
  cost: z.number(),
  setup: z.number(),
  service: z.number(),
  duration: z.number(),
  waiting_time: z.number(),
  priority: z.number(),
  distance: z.number().optional(),
  description: z.string().min(1).max(200),
  violations: z.array(violationSchema),
});

const vroomUnassignedSchema: z.ZodType<VroomUnassigned> = z.object({
  id: z.number(),
  location: z.array(z.number()),
  type: z.string(),
  description: z.string().min(1).max(200),
});

const vroomSummarySchema: z.ZodType<VroomSummary> = z.object({
  cost: z.number(),
  routes: z.number(),
  unassigned: z.number(),
  setup: z.number(),
  service: z.number(),
  duration: z.number(),
  waiting_time: z.number(),
  priority: z.number(),
  distance: z.number(),
  computing_times: z.object({
    loading: z.number(),
    solving: z.number(),
    routing: z.number(),
  }),
});

export interface VroomJob {
  /** basic index */
  id: number;
  /** Stilt Task ID. it is returned as the description of VroomStep */
  description: string; // technically optional, but required for our use case
  /** coordinates */
  location?: number[];
  /** an array of integers defining mandatory skills */
  skills?: number[];
  /** an array of time_window objects describing valid slots for job service start. a time_window object is a pair of timestamps in the form [start, end] */
  time_windows?: number[][];
  /** job service duration - how long the job will take (defaults to 0) */
  service?: number;
  /** an integer in the [0, 100] range describing priority level (defaults to 0) */
  priority?: number;
  /** job setup duration (defaults to 0) */
  setup?: number;

  /** an array of integers describing multidimensional quantities for delivery */
  // delivery?: number[];
  /** an array of integers describing multidimensional quantities for pickup */
  // pickup?: number[];
}

/*
A break object has the following properties:

Key	Description
id	integer
[time_windows]	an array of time_window objects describing valid slots for break start
[service]	break duration (defaults to 0)
[description]	a string describing this break
[max_load]	an array of integers describing the maximum vehicle load for which this break can happen
An error is reported if two break objects have the same id for the same vehicle.
*/
export interface VroomBreak {
  id: number;
  time_windows: number[][];
  service: number;
  description: string;
  max_load: number[];
}

export interface VroomVehicle {
  id: number;
  /** coordinates array */
  start?: number[];
  /** coordinates array */
  end?: number[];
  /** an array of integers defining skills */
  skills?: number[];
  /** an integer defining the maximum number of tasks in a route for this vehicle */
  max_tasks?: number;
  /** an integer defining the maximum travel time for this vehicle */
  max_travel_time?: number;
  /** an integer defining the maximum distance for this vehicle */
  max_distance?: number;

  /** Stilt vehicleID. it is returned as the description of a VroomRoute */
  description: string;

  /** routing profile (defaults to car) */
  profile?: string;
  /** a time_window object describing working hours. a time_window object is a pair of timestamps in the form [start, end] */
  time_window?: number[];

  // [capacity]	an array of integers describing multidimensional quantities
  // [costs]	a cost object defining costs for this vehicle
  // [breaks]  an array of break objects
  breaks: VroomBreak[];
  // [speed_factor]	a double value in the range (0, 5] used to scale all vehicle travel times (defaults to 1.), the respected precision is limited to two digits after the decimal point
  // [steps]	an array of vehicle_step objects describing a custom route for this vehicle
}

// export interface VroomInput {
//   jobs: VroomJob[];
//   vehicles: VroomVehicle[];
// }

interface VroomSummary {
  cost: number;
  routes: number;
  unassigned: number;
  setup: number;
  service: number;
  duration: number;
  waiting_time: number;
  priority: number;
  distance: number;
  computing_times: {
    loading: number;
    solving: number;
    routing: number;
  };
}

export interface VroomOutput {
  code: number;
  error?: string;
  routes: VroomRoute[];
  unassigned: VroomUnassigned[];
  summary: VroomSummary;
}

const vroomOutputSchema: z.ZodType<VroomOutput> = z.object({
  code: z.number(),
  error: z.string().min(1).max(200).optional(),
  routes: z.array(vroomRouteSchema),
  unassigned: z.array(vroomUnassignedSchema),
  summary: vroomSummarySchema,
});

/** @throws Error if value is not an object */
function validateVroomOutput(value: unknown): VroomOutput {
  if (!guardIsPlainObject(value)) {
    throw new Error(`value not an object: ${value}`);
  }
  const result = vroomOutputSchema.parse(value);
  return result;
}

export const VroomManager = {
  /** Convert JSON response into a validated VroomOutput object */
  createFromJSON: createVroomOutputFromJSON,
  // validateVroomStep: validateVroomStep,
  // validateVroomRoute: validateVroomRoute,
  // validateVroomJob: validateVroomJob,
  // validateVroomVehicle: validateVroomVehicle,
  // validateVroomInput: validateVroomInput,
  // validateVroomOutput: validateVroomOutput,
  getVehicleSkills: getVehicleSkills,
  getJobSkills: getJobSkills,
};

/**
 * @throws Error if json is not an object
 * @throws Error if json.routes is not an array
 */
function createVroomOutputFromJSON(json: Record<string, any>): VroomOutput {
  if (!guardIsPlainObject(json)) {
    throw new Error(`Expected json to be an object: ${json}`);
  }
  if (!Array.isArray(json["routes"])) {
    throw new Error(`Expected json.routes to be an array: ${json["routes"]}`);
  }
  return validateVroomOutput(json);
}

/**
 * Assuming that if a given vehicle has no workTypes (or no taskTypes), it
 * can handle any workType (or taskType) that the site has access to.
 *
 * To avoid unintended behavior due to overlapping CraftType and TaskType
 * values, we're multiplying each workType value by 1000.
 */
function getVehicleSkills(args: {
  siteKeyWorkTypes: number[];
  siteKeyTaskTypes: number[];
  workTypes: CraftTypeValues[];
  taskTypes: TaskTypesValues[];
}): number[] {
  const workSkills: number[] = [];

  if (args.workTypes.length === 0) {
    for (const workType of args.siteKeyWorkTypes) {
      workSkills.push(workType + 100000);
    }
  } else {
    for (const workType of args.workTypes) {
      workSkills.push(workType + 100000);
    }
  }

  const taskSkills =
    args.taskTypes.length === 0 ? args.siteKeyTaskTypes : args.taskTypes;

  return [...workSkills, ...taskSkills];
  // TODO: incorporate portables. teasdeez has 4 available
}

/**
 * THE CAMELOT JOBS ALL CAME IN WITH TASK TYPE 1002.
 *
 * if they don't choose the right taskType when booking the job...
 * optimizing won't work as expected.
 *
 */

/**
 * To avoid unintended behavior due to overlapping CraftType and TaskType
 * values, we're multiplying each workType value by 1000.
 */
function getJobSkills(
  workTypes: CraftTypeValues[],
  taskTypes: TaskTypesValues[],
): number[] {
  const workSkills: number[] = [];

  for (const workType of workTypes) {
    workSkills.push(workType + 100000);
  }

  return [...workSkills, ...taskTypes];
  // TODO: incorporate portables. teasdeez has 4 available
}

// i don't care which truck the portable goes on.
// if the job requires a portable, it'll be @ task.tsd.portableJob (boolean)
