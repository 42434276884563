// Libs
import { Control, Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useMemo, useState } from "react";
import QuestionMarkOutlined from "@mui/icons-material/QuestionMarkOutlined";
import { MdInfo } from "react-icons/md";

// Local
import BaseInputText from "../../components/BaseInputText";
import BaseInputSelect from "../../components/BaseInputSelect";
import StyledMessage from "../../components/StyledMessage";
import { StyledTooltip } from "../../components/StyledTooltip";
import {
  invoiceFrequency,
  invoiceMethod,
  getReadableInvoiceMethod,
} from "../../models/membership-template";
import { TemplateFormSchema, TemplateFormState } from "./types";
import BaseInputNumber from "../../components/BaseInputNumber";
import BaseInputTextArea from "../../components/BaseInputTextArea";
import { createToastMessageID } from "../../utils";
import { useToastMessageStore } from "../../store/toast-messages";
import StyledSwitchGroup from "../../components/StyledSwitchGroup";
import {
  getInvoiceFrequencyInMonths,
  durationIntervals,
  DurationInterval,
  convertDuration,
} from "../../utils/templateAndMembershipUtils";

const formDefaultDurations: { duration: string; value: number }[] = [
  { duration: "Ten years", value: 120 } as const,
  { duration: "Five years", value: 60 } as const,
  { duration: "Three years", value: 36 } as const,
  { duration: "Two years", value: 24 } as const,
  { duration: "One year", value: 12 } as const,
  { duration: "Six months", value: 6 } as const,
  { duration: "Indefinite (no expiration)", value: -1 } as const,
  { duration: "Other (custom duration)", value: 0 } as const,
];

interface Props {
  /** this connects the form to the save button in the parent component */
  id: string;
  onSubmit: (formValues: TemplateFormState) => Promise<void>;
  setIsBusy: (isBusy: boolean) => void;
  defaultValues?: TemplateFormState;
  children: {
    pbiSection: React.ReactNode;
    renewalPBISection: React.ReactNode;
    taskGenSection: (control: Control<TemplateFormState>) => React.ReactNode;
  };
}

/** These are shared between add/edit membership template components */
export function AddEditTemplateForm(props: Props) {
  const [customDuration, setCustomDuration] = useState<number | null>(null);
  const [chosenInterval, setChosenInterval] = useState<DurationInterval>(
    durationIntervals[0],
  );
  const { addToastMessage } = useToastMessageStore();

  const adjustedDefaultValues = useMemo(() => {
    if (!props.defaultValues) {
      // create case --
      return {
        title: "",
        description: "",
        invoiceMethod: invoiceMethod[0],
        invoiceFrequency: invoiceFrequency[0],
        automaticallyGenerateTasks: false,
        automaticallyPayInvoice: false,
        automaticallySendInvoice: false,
        discount: 0,
        defaultDuration: 12,
      };
    }
    // edit case --
    const defaultDuration = props.defaultValues.defaultDuration;
    // if the defaultDuration is not one of the formDefaultDurations values,
    // we know it is a custom duration
    const found = formDefaultDurations.find(
      (duration) => duration.value === defaultDuration,
    );
    let dd = defaultDuration;
    if (!found) {
      setCustomDuration(defaultDuration);
      dd = 0;
    }
    const dvs: TemplateFormState = {
      ...props.defaultValues,
      defaultDuration: dd,
    };
    return dvs;
  }, [props.defaultValues]);

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch,
    setValue,
  } = useForm<TemplateFormState>({
    resolver: zodResolver(TemplateFormSchema),
    defaultValues: adjustedDefaultValues,
  });

  const watchInvMethod = watch("invoiceMethod");
  const watchDefaultDuration = watch("defaultDuration");
  const watchAutoPayToggle = watch("automaticallyPayInvoice");
  const watchAutoEmailToggle = watch("automaticallySendInvoice");

  const { setIsBusy } = props;
  // update parent component's save button loading state
  useEffect(() => {
    setIsBusy(isSubmitting);
  }, [isSubmitting, setIsBusy]);

  const handleFormSubmit: SubmitHandler<TemplateFormState> = async (
    formValues,
  ) => {
    if (formValues.defaultDuration === 0) {
      if (customDuration == null) {
        addToastMessage({
          id: createToastMessageID(),
          message: "Please enter a custom duration.",
          type: "error",
          dialog: false,
        });
        return;
      }
      // convert to months & round to nearest integer
      if (chosenInterval === "Months") {
        formValues.defaultDuration = Math.round(customDuration);
      } else {
        formValues.defaultDuration = Math.round(customDuration * 12);
      }
      // verify validity
      if (
        isNaN(formValues.defaultDuration) ||
        formValues.defaultDuration === 0
      ) {
        addToastMessage({
          id: createToastMessageID(),
          message: "Please enter a valid duration.",
          type: "error",
          dialog: false,
        });
        return;
      }
    }
    await props.onSubmit(formValues);
  };

  return (
    <form
      id={props.id}
      autoComplete="off"
      onSubmit={handleSubmit(handleFormSubmit)}
      className="grid grid-cols-1 gap-4 md:grid-cols-2"
    >
      <Controller
        name="title"
        control={control}
        render={({ field }) => (
          <div>
            <BaseInputText
              text="Title"
              inputName="title"
              admin={true}
              required={true}
              {...field}
            />
            {errors.title?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.title.message }}
                </StyledMessage>
              </div>
            )}
          </div>
        )}
      />

      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <div>
            <BaseInputTextArea
              text="Description"
              inputName="description"
              admin={true}
              required={true}
              rows={1}
              {...field}
              value={field.value === null ? "" : field.value}
            />
            {errors.description?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.description.message }}
                </StyledMessage>
              </div>
            )}
          </div>
        )}
      />

      {/* DEFAULT DURATION SECTION */}
      <div className="grid grid-cols-1 gap-4 rounded bg-gray-100 px-5 py-4 md:col-span-2 md:grid-cols-2">
        <span className="space-y-2 text-sm font-medium md:row-span-2 md:mr-2">
          <p>
            The default duration of a membership is the length of time between
            the start date and the end date. Use this field to set the default
            duration of memberships created using this template. You can
            override the default duration on a per-membership basis.
          </p>
          {props.id === "editTemplateForm" &&
            adjustedDefaultValues.defaultDuration !== -1 && (
              <p className="font-semibold">
                <mark className="bg-stiltHighlight">
                  If changed, the new default duration will only be applied to
                  memberships that do not already have an end date.
                </mark>
              </p>
            )}
          {props.id === "editTemplateForm" &&
            adjustedDefaultValues.defaultDuration === -1 && (
              <p className="font-semibold">
                If changed from the original "indefinite" value, the new default
                duration will affect existing memberships that use this
                template.{" "}
                <mark className="bg-stiltHighlight">
                  These memberships will be assigned an end date the next time
                  an invoice is paid.
                </mark>
              </p>
            )}
        </span>
        <Controller
          name="defaultDuration"
          control={control}
          render={({ field }) => (
            <div className="mt-4 md:col-span-1 md:mt-2">
              <BaseInputSelect
                inputName="defaultDuration"
                text="Default Membership Duration"
                overrideBG="bg-gray-100"
                admin={true}
                required={true}
                {...field}
                onChange={(event) => {
                  const numeric = Number(event.target.value);
                  field.onChange(isNaN(numeric) ? 0 : numeric);
                }}
              >
                {formDefaultDurations.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.duration}
                  </option>
                ))}
              </BaseInputSelect>
              {errors.defaultDuration?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.defaultDuration.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          )}
        />

        {watchDefaultDuration === 0 && (
          <div className="mx-auto flex max-h-fit max-w-[20.5rem] items-center justify-between gap-2 self-center rounded border border-gray-200 bg-gray-50 py-0.5 pr-1 md:col-span-1 md:col-start-2 md:mx-0 lg:max-w-[26rem]">
            <p className="shrink pl-2 text-sm md:pl-4">
              Custom Duration{" "}
              <span className="text-base font-medium text-redFail">*</span>
            </p>
            <div className="flex shrink-0 items-center gap-2">
              <BaseInputNumber
                text="#"
                className="max-w-14 lg:max-w-20"
                overrideBG="bg-gray-100"
                inputName="customDuration"
                admin={true}
                required={false}
                value={customDuration ?? ""}
                onChange={(event) => {
                  const numberValue = event.target.valueAsNumber;
                  const valid = !isNaN(numberValue) && numberValue > 0;
                  setCustomDuration(valid ? numberValue : null);
                }}
              />
              <div className="max-w-40 lg:w-40">
                <BaseInputSelect
                  text="Interval"
                  inputName="interval"
                  overrideBG="bg-gray-100"
                  admin={true}
                  required={false}
                  value={chosenInterval}
                  onChange={(event) => {
                    const newInterval = event.target.value as DurationInterval;
                    if (customDuration != null) {
                      const converted = convertDuration(
                        customDuration,
                        chosenInterval,
                        newInterval,
                      );
                      setCustomDuration(Math.round(converted * 100) / 100); // Round to 2 decimal places
                    }
                    setChosenInterval(newInterval);
                  }}
                >
                  {durationIntervals.map((interval) => (
                    <option key={interval} value={interval}>
                      {interval}
                    </option>
                  ))}
                </BaseInputSelect>
              </div>
            </div>
          </div>
        )}

        {/* Helper text that changes based on the default duration "type" ... indefinite, custom, or preset */}
        <div className="space-y-2 text-sm md:col-span-2">
          {/* DURATION: INDEFINITE */}
          {watchDefaultDuration === -1 && (
            <span>
              <h4 className="mb-2 font-medium">INDEFINITE</h4>
              <ul className="list-circle pl-5">
                <li className="mb-0.5">
                  Memberships created from this template will never expire,
                  unless an end date is manually set for the membership.
                </li>
                <li>
                  If you modify this template to use a numerical duration in the
                  future, existing memberships will be affected. For example:
                  <ol className="list-decimal pl-5">
                    <li>
                      The template is created with an indefinite duration.
                    </li>
                    <li>
                      A membership is created with this template type. It does
                      not get an end date.
                    </li>
                    <li>
                      The template's default membership duration is later
                      changed to one year.
                    </li>
                    <li>
                      An invoice is generated for the membership, and is
                      subsequently paid. The action of posting payment to Stilt
                      will trigger the membership's end date to be set to one
                      year from the start date.
                    </li>
                  </ol>
                </li>
              </ul>
            </span>
          )}

          {/* DURATION: CUSTOM */}
          {watchDefaultDuration === 0 && (
            <span>
              <h4 className="font-medium">CUSTOM</h4>
              {customDuration === null || customDuration === 0 ? (
                <p>
                  Choose your custom duration, or select a preset duration from
                  the dropdown.
                </p>
              ) : (
                <p>
                  Memberships created using this template will be assigned an
                  end date of {customDuration}{" "}
                  <span className="lowercase">{chosenInterval}</span> after the
                  start date.
                </p>
              )}
            </span>
          )}

          {/* DURATION: PRESET */}
          {watchDefaultDuration !== 0 && watchDefaultDuration !== -1 && (
            <span>
              <h4 className="font-medium">PRESET</h4>
              <p>
                Memberships created using this template will be assigned an end
                date of{" "}
                <span className="lowercase">
                  {
                    formDefaultDurations.find(
                      (duration) => duration.value === watchDefaultDuration,
                    )?.duration
                  }
                </span>{" "}
                after the start date.
              </p>
            </span>
          )}
        </div>
      </div>

      {/* INVOICE METHOD SECTION */}
      <div className="grid grid-cols-1 gap-4 rounded bg-gray-100 px-5 py-4 sm:grid-cols-2 md:col-span-2">
        <p className="text-sm font-medium">
          The invoice method determines how memberships using this template are
          managed.
        </p>
        <Controller
          name="invoiceMethod"
          control={control}
          render={({ field }) => (
            <div className="sm:col-span-1">
              <BaseInputSelect
                inputName="invoiceMethod"
                text="Invoice Method"
                overrideBG="bg-gray-100"
                admin={true}
                required={true}
                className="mt-2 capitalize"
                {...field}
                value={field.value === null ? "" : field.value}
                onChange={(event) =>
                  field.onChange(
                    event.target.value === "" ? null : event.target.value,
                  )
                }
              >
                {invoiceMethod.map((method) => {
                  return (
                    <option key={method} value={method}>
                      {getReadableInvoiceMethod(method)}
                    </option>
                  );
                })}
              </BaseInputSelect>
              {errors.invoiceMethod?.message && (
                <div className="mt-2 text-sm">
                  <StyledMessage type="error">
                    {{ message: errors.invoiceMethod.message }}
                  </StyledMessage>
                </div>
              )}
            </div>
          )}
        />

        {/* helper text + extra toggles */}
        {watchInvMethod === "automatic" && (
          <div className="flex flex-col-reverse gap-4 text-sm sm:col-span-2 sm:flex-row sm:items-start sm:justify-between md:gap-6">
            <span className="lg:-mt-4">
              <h4 className="mb-2 font-medium">AUTOMATIC</h4>
              <ul className="list-circle pl-5">
                <li className="mb-0.5">
                  Memberships of this template type will have invoices
                  automatically generated for them.
                </li>
                {/* AUTO-RENEWAL */}
                {watchDefaultDuration !== -1 ? (
                  <li>
                    Memberships using this template will automatically renew at
                    the end of their term.
                  </li>
                ) : (
                  <li>
                    Because the template uses an indefinite duration,
                    memberships using this template will only automatically
                    renew if an end date has been set for the given membership.
                  </li>
                )}
                <li className="mb-0.5 ml-4">
                  {watchDefaultDuration !== -1 ? "The " : "If applicable, the "}
                  customer will receive an email notification fifteen days prior
                  to the renewal date (the membership's end date).
                </li>

                {/* AUTO-PAYMENT & AUTO-EMAIL */}
                {watchAutoPayToggle && (
                  <>
                    <li>
                      The customer's primary card on file will be used to
                      automatically process membership payments.
                    </li>
                    <li className="ml-4">
                      The invoice will not be emailed to the customer.
                    </li>
                    <li className="ml-4">
                      This setting can be overridden on a per-membership basis.
                    </li>
                  </>
                )}
                {!watchAutoPayToggle && (
                  <>
                    <li>No payments will be automatically processed.</li>
                    <li className="ml-4">
                      {watchAutoEmailToggle
                        ? "The invoice will automatically be emailed to the customer."
                        : "The invoice will not be emailed to the customer. This will need to be done manually."}
                    </li>
                    <li className="ml-4">
                      This setting can be overridden on a per-membership basis.
                    </li>
                  </>
                )}
              </ul>
            </span>

            {/* feels like the automaticallySendReceipt flag should be included 
            here (and on the template model).. right now it's just a site
            customization and overridable on the membership level
            TODO: */}

            {/* toggles */}
            <div className="flex flex-col items-end gap-2 md:gap-4 lg:mt-2 lg:min-w-fit">
              <div className="flex items-center gap-2">
                <Controller
                  name="automaticallyPayInvoice"
                  control={control}
                  render={({ field }) => (
                    <div className="rounded-md border border-gray-300 bg-white px-3 py-2 transition-colors hover:border-gray-500">
                      <StyledSwitchGroup
                        readableName="Charge Card Automatically"
                        onBlur={field.onBlur}
                        onChange={(ev) => {
                          field.onChange(ev); // ev is a boolean
                          if (ev) setValue("automaticallySendInvoice", false);
                        }}
                        ref={field.ref}
                        checked={field.value ?? false}
                        id="automaticallyPayInvoice"
                        name={field.name}
                      />
                    </div>
                  )}
                />
                <StyledTooltip
                  title={
                    watchAutoPayToggle
                      ? "Toggle off to prevent charging the customer's card when an invoice is generated for the membership. (This is the global setting; it can be overridden on a per-membership basis.)"
                      : "Toggle on to automatically charge the customer's card when an invoice is generated for the membership. (This is the global setting; it can be overridden on a per-membership basis.)"
                  }
                >
                  <QuestionMarkOutlined
                    style={{ width: "1.4rem", height: "1.4rem" }}
                    className="rounded-full border border-gray-500 p-0.5 text-gray-500 transition-colors hover:border-gray-700 hover:text-gray-700"
                  />
                </StyledTooltip>
              </div>

              <div className="flex items-center gap-2">
                <Controller
                  name="automaticallySendInvoice"
                  control={control}
                  render={({ field }) => (
                    <div
                      className={`rounded-md border bg-white px-3 py-2 transition-colors ${
                        watchAutoPayToggle
                          ? ""
                          : "border-gray-300 hover:border-gray-500"
                      }`}
                    >
                      <StyledSwitchGroup
                        disabled={watchAutoPayToggle}
                        readableName="Email Invoice Automatically"
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        ref={field.ref}
                        checked={field.value ?? false}
                        id="automaticallySendInvoice"
                        name={field.name}
                      />
                    </div>
                  )}
                />
                <StyledTooltip
                  title={getEmailInvTooltip(
                    watchAutoPayToggle,
                    watchAutoEmailToggle,
                  )}
                >
                  <QuestionMarkOutlined
                    style={{ width: "1.4rem", height: "1.4rem" }}
                    className="rounded-full border border-gray-500 p-0.5 text-gray-500 transition-colors hover:border-gray-700 hover:text-gray-700"
                  />
                </StyledTooltip>
              </div>
            </div>
          </div>
        )}

        {watchInvMethod === "manual" && (
          <span className="text-sm sm:col-span-2 lg:-mt-4">
            <h4 className="font-medium">MANUAL</h4>
            <p>
              Invoices will not be generated automatically for memberships using
              this template. There will be no automatic renewal at the end of
              the membership term.
            </p>
          </span>
        )}

        {watchInvMethod === "withTask" && (
          <span className="text-sm sm:col-span-2 lg:-mt-4">
            {/* TODO: I DON'T KNOW WHAT "WITH TASK" DOES */}
            <h4 className="font-medium">WITH TASK</h4>
            <p>
              Invoices will not be generated automatically for memberships using
              this template. There will be no automatic renewal at the end of
              the membership term.
            </p>
          </span>
        )}
      </div>

      <Controller
        name="invoiceFrequency"
        control={control}
        render={({ field }) => (
          <div className="mb-1 mt-2">
            <div className="mr-4 flex items-center gap-2">
              <div className="flex-1">
                <BaseInputSelect
                  inputName="invoiceFrequency"
                  text="Invoice Frequency"
                  admin={true}
                  required={true}
                  className="capitalize"
                  {...field}
                  value={field.value === null ? "" : field.value}
                  onChange={(event) =>
                    field.onChange(
                      event.target.value === "" ? null : event.target.value,
                    )
                  }
                >
                  {invoiceFrequency.map((frequency) => {
                    return (
                      <option
                        key={frequency}
                        value={frequency}
                        className="capitalize"
                      >
                        {frequency}
                      </option>
                    );
                  })}
                </BaseInputSelect>
              </div>

              <StyledTooltip title="How often should invoices be generated for this membership?">
                <QuestionMarkOutlined
                  style={{ width: "1.4rem", height: "1.4rem" }}
                  className="rounded-full border border-gray-500 p-0.5 text-gray-500 transition-colors hover:border-gray-700 hover:text-gray-700"
                />
              </StyledTooltip>
            </div>
            {errors.invoiceFrequency?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.invoiceFrequency.message }}
                </StyledMessage>
              </div>
            )}
            {field.value && watchDefaultDuration !== -1 && (
              <>
                {getMembershipDurationInMonths(
                  watchDefaultDuration,
                  customDuration,
                  chosenInterval,
                ) <= getInvoiceFrequencyInMonths(field.value) && (
                  <div className="mt-2 w-[90%]">
                    <StyledMessage
                      type="info"
                      overrideTextAlignment="text-left text-sm"
                      overrideIconAlignment="self-start mt-1 text-base"
                    >
                      {{
                        message:
                          "With this configuration, no invoices will be generated during the membership term. The next invoice will be generated when it's time for renewal.",
                        icon: <MdInfo />,
                      }}
                    </StyledMessage>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      />

      <Controller
        name="discount"
        control={control}
        render={({ field }) => (
          // this discount may be applied to services rendered or equipment purchased for customers with this membership.
          // customers with this membership plan are eligible for this percentage discount on services rendered and equipment purchases.
          // customers with this membership plan are eligible for this percentage discount on estimates/invoices.
          // this discount percentage will be available to apply to estimates for customers with this membership plan.
          <div className="mb-1 mt-2">
            <div className="flex items-center gap-2">
              <BaseInputNumber
                text="Discount"
                className="max-w-28"
                inputName="discount"
                admin={true}
                required={false}
                {...field}
                value={field.value}
                onChange={(event) => {
                  const numberValue = event.target.valueAsNumber;
                  // if (displayError === true && numberValue > 0) {
                  //   setDisplayError(false);
                  // }
                  field.onChange(isNaN(numberValue) ? "" : numberValue);
                }}
              />

              <StyledTooltip title="This discount percentage may be applied to estimates for customers with this membership plan.">
                <QuestionMarkOutlined
                  style={{ width: "1.4rem", height: "1.4rem" }}
                  className="rounded-full border border-gray-500 p-0.5 text-gray-500 transition-colors hover:border-gray-700 hover:text-gray-700"
                />
              </StyledTooltip>
            </div>
            {errors.discount?.message && (
              <div className="mt-2 text-sm">
                <StyledMessage type="error">
                  {{ message: errors.discount.message }}
                </StyledMessage>
              </div>
            )}
          </div>
        )}
      />

      <div className="flex flex-col gap-4 rounded bg-gray-100 px-5 py-4 md:col-span-2">
        <span>
          <p className="text-sm">
            Selecting a price book item is required. The item should define the
            membership fee, per invoicing period, for this plan. If the
            membership should renew at a different rate, a renewal price book
            item should also be specified.
          </p>
          <p className="mt-1 text-sm">
            Note that you can always create custom invoices with different
            amounts for individual memberships after they are created.
          </p>
        </span>
        <div className="flex flex-col gap-4 sm:grid sm:grid-cols-2 sm:items-start lg:gap-8">
          {props.children.pbiSection}
          {props.children.renewalPBISection}
        </div>
      </div>

      <div className="flex flex-col gap-4 rounded bg-gray-100 px-5 py-4 text-sm md:col-span-2">
        <span>
          <p className="mb-1.5">
            Template tasks are expected to be completed during the membership
            term.
          </p>
          <p>
            When automatic task generation is enabled, tasks will be
            automatically created and distributed evenly throughout the
            membership period. When automatic task generation is disabled, tasks
            must be manually created for the membership. This setting can be
            overridden on a per-membership basis.
          </p>
        </span>
        {props.children.taskGenSection(control)}
      </div>
    </form>
  );
}

/** get tooltip text for the "email invoice automatically" toggle */
function getEmailInvTooltip(
  autoPay: boolean | undefined,
  autoEmail: boolean | undefined,
): string {
  if (autoPay) {
    return "This setting is disabled because the customer's primary card on file will be charged automatically when a membership invoice is generated.";
  }
  if (autoEmail) {
    return "Toggle off to prevent automatically emailing membership invoices to the customer. (This is the global setting; it can be overridden on a per-membership basis.)";
  }
  return "Toggle on to automatically email membership invoices to the customer. (This is the global setting; it can be overridden on a per-membership basis.)";
}

/** Calculate the membership duration in months based on the form state */
function getMembershipDurationInMonths(
  defaultDuration: number,
  customDuration: number | null,
  chosenInterval: DurationInterval,
): number {
  if (defaultDuration !== 0) return defaultDuration; // preset duration
  if (customDuration === null) return Infinity;
  return chosenInterval === "Years" ? customDuration * 12 : customDuration;
}
