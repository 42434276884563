import React from "react";
import { Link } from "react-router-dom";
import {
  BuildingOffice2Icon,
  MegaphoneIcon,
  PhoneIcon,
  ExclamationTriangleIcon,
  ChatBubbleLeftRightIcon,
  BellAlertIcon,
  ClockIcon,
  ShieldCheckIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { useSiteKeyDocStore } from "../../store/site-key-doc";
import { useEffect, useState } from "react";
import { A2PRegistrationStatus } from "../../models/twilio";
import BaseButtonPrimary from "../../components/BaseButtonPrimary";
import BaseButtonSecondary from "../../components/BaseButtonSecondary";
import { SMS_REGISTRATION_URL } from "../../urls";
import StyledMessage from "../../components/StyledMessage";

interface SmsDashboardPageProps {
  onSetupSmsIntegration: () => void;
  errorMessage: string | null;
}

export default function SmsDashboardPage({
  onSetupSmsIntegration,
  errorMessage,
}: SmsDashboardPageProps) {
  const siteKeyDoc = useSiteKeyDocStore((state) => state.siteKeyDoc);
  const [registrationStatus, setRegistrationStatus] =
    useState<A2PRegistrationStatus | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [needsTwilioSetup, setNeedsTwilioSetup] = useState(false);

  useEffect(() => {
    // Check if Twilio credentials are missing
    const twilioAccountSid =
      siteKeyDoc?.customizations?.phones?.twilio?.accountSid;
    const twilioAuthToken =
      siteKeyDoc?.customizations?.phones?.twilio?.authToken;

    if (!twilioAccountSid || !twilioAuthToken) {
      setNeedsTwilioSetup(true);
    } else {
      setNeedsTwilioSetup(false);
    }

    // Rest of the useEffect logic for fetching registration status
    // ...
  }, [siteKeyDoc]);

  // useEffect(() => {
  //   const fetchRegistrationStatus = async () => {
  //     if (!siteKeyDoc) return;

  //     try {
  //       setIsLoading(true);
  //       const smsSettings = siteKeyDoc?.customizations?.sms;

  //       if (smsSettings?.a2pBrandId) {
  //         const status = await DbRead.twilio.getA2PRegistrationStatus(
  //           siteKeyDoc.id,
  //           smsSettings.a2pBrandId
  //         );
  //         setRegistrationStatus(status);
  //       }
  //     } catch (err) {
  //       logger.error("Error fetching A2P registration status:", err);
  //       setError("Failed to load registration status");
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchRegistrationStatus();
  // }, [siteKeyDoc]);

  const getBrandStatus = () => {
    if (!registrationStatus) return "Not Started";
    return registrationStatus.brandStatus || "Not Started";
  };

  const getCampaignStatus = () => {
    if (!registrationStatus) return "Not Started";
    return registrationStatus.campaignStatus || "Not Started";
  };

  const getPhoneNumberStatus = () => {
    if (!siteKeyDoc?.customizations?.sms?.phoneNumberId) return "Not Set Up";
    return "Configured";
  };

  const renderStatusBadge = (status: string) => {
    let bgColor = "bg-gray-200";
    let textColor = "text-gray-700";

    if (status === "APPROVED" || status === "Configured") {
      bgColor = "bg-green-100";
      textColor = "text-green-800";
    } else if (status === "PENDING" || status === "IN_REVIEW") {
      bgColor = "bg-yellow-100";
      textColor = "text-yellow-800";
    } else if (status === "REJECTED" || status === "FAILED") {
      bgColor = "bg-red-100";
      textColor = "text-red-800";
    }

    return (
      <span
        className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${bgColor} ${textColor}`}
      >
        {status}
      </span>
    );
  };

  // if (isLoading) {
  //   return (
  //     <div className="flex justify-center items-center h-full">
  //       <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
  //     </div>
  //   );
  // }

  return (
    <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
      <div className="mb-8 md:flex md:items-center md:justify-between">
        <div className="min-w-0 flex-1">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
            SMS Messaging Dashboard
          </h2>
        </div>
      </div>

      {needsTwilioSetup && (
        <div className="mb-6 border-l-4 border-yellow-400 bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                You need to set up SMS integration before you can use this
                feature.
              </p>
              <div className="mt-4">
                <BaseButtonPrimary onClick={onSetupSmsIntegration}>
                  Set Up SMS Integration
                </BaseButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      )}

      {errorMessage && (
        <div className="mb-6">
          <StyledMessage type="error">
            {{
              message: errorMessage,
            }}
          </StyledMessage>
        </div>
      )}

      {/* Benefits of SMS Integration Section */}
      <div className="mb-6 overflow-hidden bg-gradient-to-r from-blue-50 to-indigo-50 shadow sm:rounded-lg">
        <div className="border-b border-blue-100 px-4 py-3 sm:px-6">
          <h3 className="flex items-center text-lg font-semibold text-indigo-800">
            <ChatBubbleLeftRightIcon className="mr-2 h-5 w-5 text-indigo-600" />
            Benefits of Stilt SMS Integration
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-indigo-600">
            Enhance your customer communication with powerful SMS messaging
          </p>
        </div>
        <div className="px-4 py-4 sm:p-5">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div className="transform rounded-lg bg-white p-3 shadow-sm transition-all duration-200 hover:-translate-y-1 hover:shadow-md">
              <div className="flex items-start">
                <div className="flex-shrink-0 rounded-full bg-blue-100 p-2">
                  <ChatBubbleLeftRightIcon className="h-5 w-5 text-blue-600" />
                </div>
                <div className="ml-3">
                  <h4 className="text-base font-medium text-blue-800">
                    Two-Way Texting
                  </h4>
                  <p className="mt-1 text-sm text-gray-600">
                    Enable seamless two-way communication with your customers
                    via text messages. Respond to inquiries, send updates, and
                    provide support all through SMS.
                  </p>
                </div>
              </div>
            </div>

            <div className="transform rounded-lg bg-white p-3 shadow-sm transition-all duration-200 hover:-translate-y-1 hover:shadow-md">
              <div className="flex items-start">
                <div className="flex-shrink-0 rounded-full bg-purple-100 p-2">
                  <BellAlertIcon className="h-5 w-5 text-purple-600" />
                </div>
                <div className="ml-3">
                  <h4 className="text-base font-medium text-purple-800">
                    Automated Messaging
                  </h4>
                  <p className="mt-1 text-sm text-gray-600">
                    Set up automated text messages for appointment reminders,
                    follow-ups, and important notifications to keep your
                    customers informed.
                  </p>
                </div>
              </div>
            </div>

            <div className="transform rounded-lg bg-white p-3 shadow-sm transition-all duration-200 hover:-translate-y-1 hover:shadow-md">
              <div className="flex items-start">
                <div className="flex-shrink-0 rounded-full bg-green-100 p-2">
                  <ClockIcon className="h-5 w-5 text-green-600" />
                </div>
                <div className="ml-3">
                  <h4 className="text-base font-medium text-green-800">
                    Message History
                  </h4>
                  <p className="mt-1 text-sm text-gray-600">
                    Keep a complete record of all customer text conversations
                    for reference, training, and quality assurance purposes.
                  </p>
                </div>
              </div>
            </div>

            <div className="transform rounded-lg bg-white p-3 shadow-sm transition-all duration-200 hover:-translate-y-1 hover:shadow-md">
              <div className="flex items-start">
                <div className="flex-shrink-0 rounded-full bg-red-100 p-2">
                  <ShieldCheckIcon className="h-5 w-5 text-red-600" />
                </div>
                <div className="ml-3">
                  <h4 className="text-base font-medium text-red-800">
                    Compliant Messaging
                  </h4>
                  <p className="mt-1 text-sm text-gray-600">
                    Our A2P 10DLC registration process ensures your business can
                    send high-volume text messages in compliance with carrier
                    regulations.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 rounded-lg border border-indigo-200 bg-indigo-100 p-3">
            <p className="flex items-center text-sm text-indigo-700">
              <InformationCircleIcon className="mr-2 h-4 w-4" />
              Setting up SMS integration requires selecting a phone number and
              completing the A2P 10DLC registration process for business text
              messaging.
            </p>
          </div>
        </div>
      </div>

      <div className="mb-8 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            SMS Registration Status
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Complete all steps to enable SMS messaging for regulatory compliance
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="flex items-center text-sm font-medium text-gray-500">
                <BuildingOffice2Icon className="mr-2 h-5 w-5 text-gray-400" />
                Business Information
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                {renderStatusBadge(getBrandStatus())}
              </dd>
              <dd className="mt-1 text-right text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                <Link to={SMS_REGISTRATION_URL}>
                  <BaseButtonPrimary disabled={getBrandStatus() === "APPROVED"}>
                    {getBrandStatus() === "Not Started"
                      ? "Submit Required Info"
                      : "View Pending Submission"}
                  </BaseButtonPrimary>
                </Link>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="flex items-center text-sm font-medium text-gray-500">
                <MegaphoneIcon className="mr-2 h-5 w-5 text-gray-400" />
                Campaign Setup
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                {renderStatusBadge(getCampaignStatus())}
              </dd>
              <dd className="mt-1 text-right text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                <div className="text-sm text-gray-500">
                  {getBrandStatus() === "APPROVED"
                    ? "Campaign will be set up automatically after business verification"
                    : "Complete business information first"}
                </div>
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="flex items-center text-sm font-medium text-gray-500">
                <PhoneIcon className="mr-2 h-5 w-5 text-gray-400" />
                Phone Number
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                {renderStatusBadge(getPhoneNumberStatus())}
              </dd>
              <dd className="mt-1 text-right text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                <BaseButtonPrimary onClick={onSetupSmsIntegration}>
                  {getPhoneNumberStatus() === "Not Set Up"
                    ? "Set Up Phone Number"
                    : "Manage Phone Number"}
                </BaseButtonPrimary>
              </dd>
            </div>
          </dl>
        </div>
      </div>

      <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            SMS Messaging Capabilities
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Current messaging limits and capabilities
          </p>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">SMS Enabled</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {siteKeyDoc?.customizations?.sms?.smsEnabled ? "Yes" : "No"}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Messaging Throughput
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {getCampaignStatus() === "APPROVED"
                  ? "High Volume"
                  : "Standard"}
              </dd>
            </div>
            {/* {registrationStatus?.messagingServiceSid && (
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Messaging Service SID
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {registrationStatus.messagingServiceSid}
                </dd>
              </div>
            )} */}
          </dl>
        </div>
      </div>
    </div>
  );
}
