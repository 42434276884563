// Libs
import { DocumentData, Timestamp } from "firebase/firestore";
import cloneDeep from "lodash/cloneDeep";
import { SetStateAction } from "react";

// Local
import { ExistingTask } from "../../../models/task";
import { TaskStatus } from "../../../models/task-status";
import { ExistingSiteKey } from "../../../models/site-key";
import {
  checkCraftRecordPersistencePrompt,
  checkTsdRequired,
} from "../../../assets/js/tasks";
import { updateTask } from "./updateTask";

/**
 * returns `0` if TaskStatusChangeDialog opens, so we can delay the toast notification.
 * otherwise, will update the task
 */
export async function changeTaskStatus(args: {
  // DATA
  task: ExistingTask;
  nextTaskStatus: TaskStatus;
  siteKeyDoc: ExistingSiteKey;
  uid: string;

  // FUNCTIONS
  handleUpdateTaskStatus: (
    updateData: DocumentData,
    taskID: string,
  ) => Promise<void>;
  setOpenTaskStatusChangeDialog: (val: SetStateAction<string | null>) => void;
  setShowCraftPersistence: (val: SetStateAction<boolean>) => void;
  setNextTaskStatus: (val: SetStateAction<TaskStatus | null>) => void;
}): Promise<0 | void> {
  const previousTaskStatus = args.task.taskStatus;
  const updatedTask = cloneDeep(args.task);

  updatedTask.taskStatus = args.nextTaskStatus;
  if (
    args.nextTaskStatus === TaskStatus.IN_PROGRESS &&
    previousTaskStatus !== TaskStatus.ON_HOLD
  ) {
    updatedTask.timestampTaskStarted = Timestamp.now();
  }

  if (
    args.nextTaskStatus === TaskStatus.COMPLETE ||
    (previousTaskStatus === TaskStatus.IN_PROGRESS &&
      args.nextTaskStatus === TaskStatus.AWAITING_REVIEW &&
      updatedTask.timestampTaskCompleted === null)
  ) {
    updatedTask.timestampTaskCompleted = Timestamp.now();
  }

  if (
    args.nextTaskStatus === TaskStatus.AWAITING &&
    previousTaskStatus !== TaskStatus.AWAITING
  ) {
    updatedTask.timestampAwaitingStart = Timestamp.now();
  }

  if (
    args.nextTaskStatus === TaskStatus.AWAITING &&
    previousTaskStatus === TaskStatus.COMPLETE
  ) {
    updatedTask.timestampTaskCompleted = null;
    updatedTask.timestampAwaitingStart = Timestamp.now();
    updatedTask.timestampTaskStarted = null;
  }

  const tsdRequired = checkTsdRequired({
    task: updatedTask,
    siteKeyDoc: args.siteKeyDoc,
    previousTaskStatus,
  });
  if (
    tsdRequired ||
    args.nextTaskStatus === TaskStatus.AWAITING_PARTS ||
    args.nextTaskStatus === TaskStatus.PARTS_ORDERED ||
    args.nextTaskStatus === TaskStatus.CANCELED
  )
    args.setNextTaskStatus(args.nextTaskStatus);

  const showCraftPersistence = await checkCraftRecordPersistencePrompt(
    updatedTask,
    previousTaskStatus,
  );
  if (showCraftPersistence) args.setShowCraftPersistence(true);

  if (
    tsdRequired ||
    showCraftPersistence ||
    args.nextTaskStatus === TaskStatus.AWAITING_PARTS ||
    args.nextTaskStatus === TaskStatus.PARTS_ORDERED ||
    args.nextTaskStatus === TaskStatus.CANCELED
  ) {
    args.setOpenTaskStatusChangeDialog(args.task.id);
    return 0;
  } else {
    await updateTask({
      originalTask: args.task,
      updatedTaskData: updatedTask,
      originalTSD: args.task.taskSpecificDetails,
      updatedTSD: null,
      uid: args.uid,
      handleUpdateTaskStatus: args.handleUpdateTaskStatus,
    });
  }
}
