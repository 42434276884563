//Libs
import { Fragment, useEffect, useState } from "react";
import {
  TrashIcon,
  CheckIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/solid";
import { Listbox, Transition } from "@headlessui/react";
import { Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";

//Local
import BaseInputTextArea from "../BaseInputTextArea";
import BaseInputNumber from "../BaseInputNumber";
import { TemporaryEstimateItem } from "../../models/estimate-item";
import { logger } from "../../logging";
import { useToastMessageStore } from "../../store/toast-messages";
import { createToastMessageID } from "../../utils";
import * as strings from "../../strings";
import currencyFormatter from "../../currency";
import { getTemporaryItemTotal } from "../../assets/js/estimateFunctions";
import { useMembershipTemplatesStore } from "../../store/membership-templates";
import { ExistingMembershipTemplate } from "../../models/membership-template";
import { SchedulingButton } from "../../Pages/Scheduling/SchedulingContainer";
import { EstimateItemMembershipData } from "../../models/membership";

function getReadableStartMethod(startMethod: StartMethodOptions) {
  switch (startMethod) {
    case "sameTask":
      return "Current Task";
    case "uponPaymentReceived":
      return "Upon Payment Received";
    case "futureDate":
      return "Future Date";
    default:
      return "Unknown";
  }
}
const startMethodOptions = [
  "sameTask",
  "uponPaymentReceived",
  "futureDate",
] as const;
type StartMethodOptions = (typeof startMethodOptions)[number];

interface Props {
  //DATA
  estimateItem: TemporaryEstimateItem;
  currency: string;
  hasTask: boolean; // for memberships gazz
  //FUNCTIONS
  onSearch: (searchTerm: string) => Promise<void>;
  initialEstimateItemMembershipData: EstimateItemMembershipData | null;
  handleEditEstimateItem: (tempEstimateItemList: TemporaryEstimateItem) => void;
  handleDeleteEstimateItem: (estimateItem: TemporaryEstimateItem) => void;
}

export default function EditEstimateItemLine({
  onSearch,
  handleEditEstimateItem,
  handleDeleteEstimateItem,
  currency,
  estimateItem,
  initialEstimateItemMembershipData,
  hasTask,
}: Props) {
  const [displayError, setDisplayError] = useState(false);
  const addMessage = useToastMessageStore((state) => state.addToastMessage);
  const [description, setDescription] = useState(
    estimateItem.description ?? "",
  );
  const [unitPrice, setUnitPrice] = useState<number | "">(
    estimateItem.unitPrice,
  );
  const [discount, setDiscount] = useState<number | "">(
    estimateItem.discount ?? "",
  );
  const [quantity, setQuantity] = useState<number | "">(estimateItem.quantity);
  const [itemMembershipData, setItemMembershipData] =
    useState<EstimateItemMembershipData | null>(
      initialEstimateItemMembershipData,
    );
  const [membershipTemplate, setMembershipTemplate] =
    useState<ExistingMembershipTemplate | null>(null);
  const membershipTemplateList = useMembershipTemplatesStore(
    (state) => state.membershipTemplates,
  );

  const [membershipStartMethod, setMembershipStartMethod] =
    useState<StartMethodOptions>(hasTask ? "sameTask" : "uponPaymentReceived");

  useEffect(() => {
    const membershipTemplate = membershipTemplateList.find(
      (mT) => mT.priceBookItemID === estimateItem.priceBookItemID,
    );
    setMembershipTemplate(membershipTemplate ?? null);
    if (membershipTemplate && !initialEstimateItemMembershipData) {
      setItemMembershipData({
        membershipTemplateID: membershipTemplate.id,
        isSameTask: hasTask ? true : false,
        selectedStartDate: null,
        notes: null,
      });
    }
  }, [
    estimateItem.priceBookItemID,
    initialEstimateItemMembershipData,
    membershipTemplateList,
    hasTask,
  ]);

  const onSubmit = async () => {
    const editEstimateItem: TemporaryEstimateItem = {
      ...estimateItem,
      toBeEdited: false,
      description: description,
      unitPrice: unitPrice === "" ? 0 : unitPrice,
      quantity: quantity === "" ? 0 : quantity,
      discount: discount === "" ? 0 : discount,
    };
    if (itemMembershipData) {
      editEstimateItem.customData = {
        ...editEstimateItem.customData,
        membershipData: itemMembershipData,
      };
    }
    console.log("running onSubmit", editEstimateItem);

    try {
      await handleEditEstimateItem(editEstimateItem);
      onSearch("");
    } catch (error) {
      logger.error(`An error occurred during handleEditEstimateItem`, error);
      addMessage({
        id: createToastMessageID(),
        message: strings.UNEXPECTED_ERROR,
        dialog: false,
        type: "error",
      });
    }
  };

  return (
    <div className="flex flex-col items-center sm:grid sm:grid-cols-[5fr,1fr] lg:grid-cols-[8fr,1fr]">
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-2 gap-4 lg:grid-cols-[2fr,1fr,1fr,1fr] ">
          <div
            className={`relative col-span-2 block w-full min-w-0 rounded border border-primary p-4 text-gray-700 focus:border-primaryLight focus:outline-none focus:ring focus:ring-primaryLight sm:text-sm lg:col-span-1`}
          >
            <div className="truncate">{estimateItem.title}</div>
            <label className="absolute -top-3 left-3 bg-white px-2">
              Title
              <span className="text-lg font-medium text-redFail">*</span>
            </label>
          </div>

          {/* Field: Quantity */}
          <div>
            <BaseInputNumber
              text="Quantity"
              inputName="quantity"
              admin={true}
              required={true}
              value={quantity}
              onChange={(event) => {
                const numberValue = event.target.valueAsNumber;
                if (displayError === true && numberValue > 0) {
                  setDisplayError(false);
                }
                setQuantity(isNaN(numberValue) ? "" : numberValue);
              }}
            />
          </div>
          {/* Field: Unit Price */}
          <div className="relative">
            <BaseInputNumber
              text="Unit Price"
              inputName="unitPrice"
              admin={true}
              required={true}
              value={unitPrice}
              onChange={(event) => {
                const numberValue = event.target.valueAsNumber;
                if (displayError === true && numberValue > 0) {
                  setDisplayError(false);
                }
                setUnitPrice(isNaN(numberValue) ? "" : numberValue);
              }}
            />
            <label className="absolute right-1 top-4 bg-white px-2">
              /{estimateItem.units}
            </label>
          </div>

          {/* Field: Discount */}
          <div>
            {estimateItem.discountable && (
              <BaseInputNumber
                text="Discount (%)"
                inputName="discount"
                admin={true}
                required={false}
                disabled={!estimateItem.discountable}
                value={discount ?? 0}
                onChange={(event) => {
                  const numberValue = event.target.valueAsNumber;
                  if (displayError === true && numberValue > 0) {
                    setDisplayError(false);
                  }
                  setDiscount(isNaN(numberValue) ? "" : numberValue);
                }}
              />
            )}
          </div>
        </div>

        {/* Field: Description */}
        <div>
          <BaseInputTextArea
            text="Description"
            inputName="description"
            admin={true}
            required={false}
            rows={3}
            value={description ?? ""}
            onChange={(event) => setDescription(event.target.value)}
          />
        </div>

        {membershipTemplate !== null && (
          <div className="justify-end">
            <div className="width-auto flex items-start justify-start space-x-2">
              <div className="m-2 mb-4 flex max-w-fit items-end justify-end rounded-full bg-blue-200 p-2 text-sm font-bold capitalize text-gray-900">
                New Membership
              </div>
            </div>
            <div className="flex flex-row items-end justify-end space-x-2">
              {hasTask && (
                <span>
                  Does the membership start with the task on this current
                  estimate, or will it start at a date in the future?
                </span>
              )}
              {!hasTask && (
                <span>
                  To set a membership start date in the future, choose "future
                  date" in the dropdown menu, and then select the date.
                </span>
              )}
              <div className="z-10">
                <div className="w-fit">
                  <Listbox
                    onChange={(event) => {
                      // if (!startMethodOptions.includes(event as any)) {
                      //   throw new Error("Invalid start method");
                      // }
                      if (
                        event !== "futureDate" &&
                        event !== "sameTask" &&
                        event !== "uponPaymentReceived"
                      ) {
                        throw new Error("Invalid start method");
                      }
                      setMembershipStartMethod(event);

                      let startDate: Timestamp | null;
                      if (event === "futureDate") {
                        startDate = Timestamp.now();
                      } else {
                        // uponPaymentReceived or sameTask
                        startDate = null;
                      }
                      setItemMembershipData((prev) => ({
                        membershipTemplateID:
                          prev?.membershipTemplateID ?? membershipTemplate.id,
                        isSameTask: event === "sameTask",
                        selectedStartDate: startDate,
                        notes: prev?.notes ?? null,
                      }));
                    }}
                  >
                    <div className="relative mt-1">
                      <Listbox.Button className="relative h-10 w-60 cursor-pointer rounded-md border border-primary bg-white py-2 pl-3 pr-10 text-left outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primaryLight">
                        <span className="block truncate">
                          {getReadableStartMethod(membershipStartMethod)}
                        </span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-700"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>
                      <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute mt-1 max-h-60 w-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                          {hasTask && (
                            <Listbox.Option
                              key="membershipCurrentTask"
                              className={({ active, selected }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                  active || selected
                                    ? "bg-primaryOpacity90 text-primary"
                                    : "text-gray-700"
                                }`
                              }
                              value="sameTask"
                            >
                              {({ selected }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? "font-medium" : "font-normal"
                                    }`}
                                  >
                                    Current Task
                                  </span>
                                  {selected ? (
                                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          )}
                          <Listbox.Option
                            key="membershipUponPaymentReceived"
                            className={({ active, selected }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active || selected
                                  ? "bg-primaryOpacity90 text-primary"
                                  : "text-gray-700"
                              }`
                            }
                            value="uponPaymentReceived"
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  Upon Payment Received
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                          <Listbox.Option
                            key="membershipFutureDate"
                            className={({ active, selected }) =>
                              `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                active || selected
                                  ? "bg-primaryOpacity90 text-primary"
                                  : "text-gray-700"
                              }`
                            }
                            value="futureDate"
                          >
                            {({ selected }) => (
                              <>
                                <span
                                  className={`block truncate ${
                                    selected ? "font-medium" : "font-normal"
                                  }`}
                                >
                                  Future Date
                                </span>
                                {selected ? (
                                  <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary">
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </Listbox>
                </div>
              </div>
              <div className="flex w-fit flex-col items-end justify-end">
                {membershipStartMethod === "futureDate" ? (
                  <DatePicker
                    selected={itemMembershipData?.selectedStartDate?.toDate()}
                    onChange={(date: Date) => {
                      setItemMembershipData((prev) => ({
                        membershipTemplateID:
                          prev?.membershipTemplateID ?? membershipTemplate.id,
                        isSameTask: false,
                        selectedStartDate: Timestamp.fromDate(date),
                        notes: prev?.notes ?? null,
                      }));
                    }}
                    showTimeSelect={false}
                    shouldCloseOnSelect={true}
                    customInput={<SchedulingButton />}
                    minDate={DateTime.now().minus({ year: 1 }).toJSDate()}
                  />
                ) : null}
              </div>
            </div>

            <div className="flex flex-col items-end justify-between space-x-2 md:flex-row">
              <BaseInputTextArea
                text="Membership Notes"
                inputName="membershipNotes"
                admin={true}
                required={false}
                rows={2}
                className="mt-6 w-full"
                value={itemMembershipData?.notes ?? ""}
                onChange={(event) => {
                  setItemMembershipData((prev) => ({
                    membershipTemplateID:
                      prev?.membershipTemplateID ?? membershipTemplate.id,
                    isSameTask: prev?.isSameTask ?? true,
                    selectedStartDate: prev?.selectedStartDate ?? null,
                    notes: event.target.value,
                  }));
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="mt-4 flex flex-row items-center justify-between gap-4 sm:mt-0 sm:flex-col sm:items-end">
        <div className="text-lg font-bold">
          {currencyFormatter(
            getTemporaryItemTotal(
              quantity,
              unitPrice,
              estimateItem.discountable,
              discount,
            ),
            currency,
          )}
        </div>
        <button
          className="mx-1 rounded-full hover:bg-red-50"
          onClick={() => handleDeleteEstimateItem(estimateItem)}
        >
          <TrashIcon
            aria-label="delete button"
            className="block h-10 w-10 cursor-pointer p-2 text-red-700"
          />
        </button>
        <button
          className="mx-1 rounded-full hover:bg-green-50"
          onClick={onSubmit}
        >
          <CheckIcon
            aria-label="confirm button"
            className="block h-10 w-10 cursor-pointer p-2 text-green-700"
          />
        </button>
      </div>
    </div>
  );
}
