import { Fragment, useRef, useState, useEffect } from "react";
import { User } from "firebase/auth";
import { Timestamp } from "firebase/firestore";
import {
  ArrowBack,
  PersonAdd,
  Image,
  OpenInNew,
  Work,
  Check,
  Close,
  Edit,
} from "@mui/icons-material";
import * as MdIcons from "react-icons/md";

import {
  ExistingChatRoom,
  ChatRoomType,
  reassignSMSChatRoom,
} from "../../models/chat_room";
import {
  ExistingChatMessage,
  MessageType,
  ChatMessageManager,
} from "../../models/chat_message";
import { ExistingSiteKeyUserDoc } from "../../models/site-key-users";
import { DbWrite } from "../../database";
import ChatBubble from "./ChatBubble";
import { logger } from "../../logging";
import ChatRoomUserSelectionDialog from "../ChatRoomUserSelectionDialog";
import { ExistingChatMessageReadStatus } from "../../models/chat_message_read_status";
import { useUserPermissionsStore } from "../../store/user-permissions";
import { phoneUtils } from "../../utils";
import { CUSTOMERS_URL, WORK_RECORD_AND_TASKS_URL } from "../../urls";
import { UserCircleIcon } from "@heroicons/react/24/solid";

interface ChatRoomViewProps {
  room: ExistingChatRoom;
  messages: ExistingChatMessage[] | null;
  siteKey: string;
  firebaseUser: User | null;
  userDisplayNamesMap: Record<string, string>;
  siteKeyUsersList: ExistingSiteKeyUserDoc[];
  onBackClick: () => void;
  uploadingImages: {
    [key: string]: { progress: number; file: File };
  };
  handleImageUpload: (file: File) => void;
  handleAddMessage: (text: string) => void;
  markRoomAsRead: () => void;
  unreadMessageStatuses: ExistingChatMessageReadStatus[];
}

function AssignmentInfo({
  room,
  firebaseUser,
  userDisplayNamesMap,
  onReassign,
}: {
  room: ExistingChatRoom;
  firebaseUser: User | null;
  userDisplayNamesMap: Record<string, string>;
  onReassign: (userId: string) => void;
}) {
  const [showAssignMenu, setShowAssignMenu] = useState(false);

  // Only show for SMS conversations
  if (room.type !== ChatRoomType.SMS) return null;

  const assignedUserId = room.primaryAssignedUserId || room.createdBy;
  const assignedUserName = userDisplayNamesMap[assignedUserId] || "Unknown";
  const isAssignedToMe = assignedUserId === firebaseUser?.uid;

  return (
    <div className="flex items-center gap-2 border-b border-gray-200 bg-gray-50 px-2 py-1">
      {room.primaryAssignedUserId && (
        <div className="text-xs text-gray-500">
          Assigned to:
          <span
            className={`ml-1 font-medium ${isAssignedToMe ? "text-blue-600" : "text-gray-700"}`}
          >
            {isAssignedToMe ? "You" : assignedUserName}
          </span>
        </div>
      )}

      <button
        onClick={() => setShowAssignMenu(!showAssignMenu)}
        className="text-xs text-blue-600 hover:text-blue-800"
      >
        {room.primaryAssignedUserId ? "Reassign" : "Assign To Other User"}
      </button>

      {showAssignMenu && (
        <div className="absolute left-0 top-full z-50 mt-1 rounded-md border border-gray-200 bg-white shadow-lg">
          <div className="border-b p-2 text-xs font-medium text-gray-700">
            Assign to:
          </div>
          <div className="max-h-48 overflow-y-auto">
            {Object.entries(userDisplayNamesMap).map(([userId, name]) => (
              <button
                key={userId}
                className={`w-full px-4 py-2 text-left text-sm hover:bg-gray-100 ${
                  userId === assignedUserId ? "bg-blue-50 font-medium" : ""
                }`}
                onClick={() => {
                  onReassign(userId);
                  setShowAssignMenu(false);
                }}
              >
                {name} {userId === firebaseUser?.uid ? "(You)" : ""}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default function ChatRoomView({
  room,
  messages,
  siteKey,
  firebaseUser,
  userDisplayNamesMap,
  siteKeyUsersList,
  onBackClick,
  uploadingImages,
  handleImageUpload,
  handleAddMessage,
  markRoomAsRead,
  unreadMessageStatuses,
}: ChatRoomViewProps) {
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const messagesContainerRef = useRef<HTMLDivElement | null>(null);
  const [isAtBottom, setIsAtBottom] = useState(true);
  const [showAddUsersDialog, setShowAddUsersDialog] = useState<boolean>(false);
  const [addUsersValues, setAddUsersValues] = useState<string[]>([]);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [showPulseAnimation, setShowPulseAnimation] = useState(false);
  const [showOtherRoomsNotification, setShowOtherRoomsNotification] =
    useState(false);
  const [pulseOtherRoomsNotification, setPulseOtherRoomsNotification] =
    useState(false);
  const prevUnreadStatusesRef = useRef<ExistingChatMessageReadStatus[]>([]);
  const [isEditingChatName, setIsEditingChatName] = useState(false);
  const [newChatName, setNewChatName] = useState("");

  // Get user permissions to check if user is a site admin
  const userPermissions = useUserPermissionsStore(
    (state) => state.siteKeyUserPermissions,
  );
  const isSiteAdmin = userPermissions?.permissions.isSiteAdmin === true;

  // Initialize the newChatName with the current room name when the room changes
  useEffect(() => {
    if (room && room.name) {
      setNewChatName(room.name);
    }
  }, [room]);

  // Calculate total unread messages in other rooms
  const totalUnreadInOtherRooms = unreadMessageStatuses
    .filter((status) => status.chatRoomID !== room.id)
    .reduce((sum, status) => sum + status.unreadCount, 0);

  // Check if there are new unread messages in other rooms
  useEffect(() => {
    if (!unreadMessageStatuses || unreadMessageStatuses.length === 0) return;

    // Show notification if there are unread messages in other rooms
    setShowOtherRoomsNotification(totalUnreadInOtherRooms > 0);

    // Check if unread counts have increased in other rooms
    const prevTotalUnreadInOtherRooms = prevUnreadStatusesRef.current
      .filter((status) => status.chatRoomID !== room.id)
      .reduce((sum, status) => sum + status.unreadCount, 0);

    // If unread counts increased, show pulse animation
    if (totalUnreadInOtherRooms > prevTotalUnreadInOtherRooms) {
      setPulseOtherRoomsNotification(true);
      setTimeout(() => {
        setPulseOtherRoomsNotification(false);
      }, 2000);
    }

    // Update previous unread statuses
    prevUnreadStatusesRef.current = [...unreadMessageStatuses];
  }, [unreadMessageStatuses, room, totalUnreadInOtherRooms]);

  // Add scroll event listener to track if user is at bottom
  const handleScroll = () => {
    const container = messagesContainerRef.current;
    if (!container) return;

    const { scrollTop, scrollHeight, clientHeight } = container;
    // Consider "at bottom" if within 100px of the bottom
    const atBottom = scrollHeight - scrollTop - clientHeight < 100;
    setIsAtBottom(atBottom);
  };

  const scrollToBottom = (smooth = true) => {
    if (!messagesEndRef.current) return;

    messagesEndRef.current.scrollIntoView({
      behavior: smooth ? "smooth" : "auto",
    });
  };

  // Track previous message count to detect new messages
  const prevMessageCountRef = useRef<number>(0);

  // Scroll to bottom when component mounts or messages change
  useEffect(() => {
    if (!messages || messages.length === 0) return;

    const isInitialLoad = prevMessageCountRef.current === 0;
    const hasNewMessages = messages.length > prevMessageCountRef.current;

    // Update the previous message count reference
    prevMessageCountRef.current = messages.length;

    // If it's the initial load, scroll without animation
    if (isInitialLoad) {
      scrollToBottom(false);
    }
    // If new messages arrived and user is already at the bottom, animate scroll
    else if (hasNewMessages && isAtBottom) {
      scrollToBottom(true);

      // Show pulse animation if new messages arrive and user is not focused on input
      if (!isInputFocused) {
        setShowPulseAnimation(true);
        // Reset animation after it completes
        setTimeout(() => {
          setShowPulseAnimation(false);
        }, 1000); // Animation duration
      }
    }
  }, [messages, isAtBottom, isInputFocused]);

  // Mark room as read when new messages arrive
  useEffect(() => {
    if (!messages || messages.length === 0) return;

    // Mark as read for both initial load and new messages
    markRoomAsRead();
  }, [messages]);

  // Function to add users to an existing chat room
  async function handleAddUsersToRoom(userIds: string[]) {
    try {
      if (!firebaseUser) {
        logger.warn("User must be logged in to add users");
        return;
      }

      if (userIds.length === 0) {
        logger.warn("No users selected");
        return;
      }

      if (!room.id) {
        logger.warn("No chat room selected");
        return;
      }

      // Check if user has permission to add users to this chat
      // Only site admins can add users to group chats, but anyone can add users to job chats
      if (
        room.type === ChatRoomType.GROUP &&
        userPermissions?.permissions.isSiteAdmin !== true
      ) {
        logger.warn("Only site administrators can add users to group chats");
        return;
      }

      // Filter out users who are already in the room
      const newUserIds = userIds.filter((id) => !room.userIds.includes(id));

      if (newUserIds.length === 0) {
        logger.warn("Selected users are already in the chat");
        return;
      }

      // Update the room with the new users
      const updatedUserIds = [...room.userIds, ...newUserIds];

      // If this is becoming a group chat, update the type
      const isNowGroupChat = updatedUserIds.length > 2;
      const updatedType = isNowGroupChat ? ChatRoomType.GROUP : room.type;

      // If this is becoming a group chat and doesn't have a proper name, set it to "Group Chat"
      const updatedName =
        isNowGroupChat && room.type === ChatRoomType.DIRECT
          ? "Group Chat"
          : room.name;

      // Update the room in Firestore
      await DbWrite.chatRoom.update(siteKey, room.id, {
        userIds: updatedUserIds,
        type: updatedType,
        name: updatedName,
        timestampLastModified: Timestamp.now(),
        craftRecordID: room.craftRecordID,
      });

      // Reset the add users dialog
      setAddUsersValues([]);
      setShowAddUsersDialog(false);
    } catch (error) {
      logger.warn("Error adding users to chat room", error);
    }
  }

  // Group messages by sender and time proximity
  function groupMessages(messages: ExistingChatMessage[]) {
    const groups: { messages: ExistingChatMessage[] }[] = [];
    let currentGroup: ExistingChatMessage[] = [];
    let currentSender: string | null = null;
    let lastTimestamp: number | null = null;

    // Sort messages by timestamp
    const sortedMessages = [...messages].sort((a, b) => {
      const timeA = a.timestampCreated.seconds * 1000;
      const timeB = b.timestampCreated.seconds * 1000;
      return timeA - timeB;
    });

    sortedMessages.forEach((message) => {
      // System messages are always in their own group
      if (message.type === MessageType.SYSTEM) {
        if (currentGroup.length > 0) {
          groups.push({ messages: [...currentGroup] });
          currentGroup = [];
        }
        groups.push({ messages: [message] });
        currentSender = null;
        lastTimestamp = null;
        return;
      }

      const messageTime = message.timestampCreated.seconds * 1000;
      const timeDiff = lastTimestamp ? messageTime - lastTimestamp : 0;

      // Start a new group if:
      // 1. This is a different sender
      // 2. More than 5 minutes have passed since the last message
      if (
        message.createdBy !== currentSender ||
        timeDiff > 5 * 60 * 1000 ||
        currentGroup.length === 0
      ) {
        if (currentGroup.length > 0) {
          groups.push({ messages: [...currentGroup] });
          currentGroup = [];
        }
        currentSender = message.createdBy;
      }

      currentGroup.push(message);
      lastTimestamp = messageTime;
    });

    // Add the last group if it has messages
    if (currentGroup.length > 0) {
      groups.push({ messages: [...currentGroup] });
    }

    return groups;
  }

  // Add this to display room name and type
  const isSmsConversation = room.type === ChatRoomType.SMS;
  const customerPhone = room.customerPhone || "";

  // Add reassignment handler
  async function handleReassign(newAssignedUserId: string) {
    try {
      if (!firebaseUser) return;

      // Get the update object
      const roomUpdate = reassignSMSChatRoom(
        room,
        newAssignedUserId,
        firebaseUser.uid,
      );
    } catch (error) {
      console.error("Error reassigning conversation:", error);
    }
  }

  // Function to update the chat room name
  async function handleUpdateChatName() {
    try {
      if (!firebaseUser || !room.id || !newChatName.trim()) {
        return;
      }

      // Only allow admins to update group chat names
      if (room.type === ChatRoomType.GROUP && !isSiteAdmin) {
        logger.warn("Only administrators can update group chat names");
        return;
      }

      // Don't update if the name hasn't changed
      if (newChatName.trim() === room.name) {
        setIsEditingChatName(false);
        return;
      }

      const oldName = room.name || "Group Chat";
      const updatedName = newChatName.trim();

      // Update the room in Firestore
      await DbWrite.chatRoom.update(siteKey, room.id, {
        name: updatedName,
        timestampLastModified: Timestamp.now(),
        lastModifiedBy: firebaseUser.uid,
      });

      // Add a system message about the name change
      const systemMessage = ChatMessageManager.createFromNewMessage(
        {
          text: `${userDisplayNamesMap[firebaseUser.uid] || "A user"} changed the group name from "${oldName}" to "${updatedName}"`,
        },
        firebaseUser.uid,
        room.id,
        MessageType.SYSTEM,
      );

      await DbWrite.chatMessage.add(siteKey, room.id, systemMessage);

      // Exit editing mode
      setIsEditingChatName(false);
    } catch (error) {
      logger.warn("Error updating chat room name", error);
    }
  }

  function getChatRoomName(otherUsers: string[]) {
    if (room.type === ChatRoomType.SMS) {
      return room.name;
    }
    if (room.type === ChatRoomType.JOB) {
      return room.name;
    }
    if (room.type === ChatRoomType.GROUP) {
      // Return the custom group name if it exists, otherwise default to "Group Chat"
      return room.name || "Group Chat";
    }
    if (room.type === ChatRoomType.DIRECT) {
      return userDisplayNamesMap[otherUsers[0]] || "Unknown User";
    }
    return room.name;
  }

  try {
    // Make sure room has valid userIds array
    if (!Array.isArray(room.userIds)) {
      return (
        <div className="flex h-full flex-col items-center justify-center">
          <div className="text-gray-500">Invalid chat room data</div>
        </div>
      );
    }

    const isGroupChat = room.userIds.length > 2;
    const otherUsers = room.userIds.filter((id) => id !== firebaseUser?.uid);

    // Format participants for display
    let participantsDisplay = "";
    if (isGroupChat) {
      if (otherUsers.length <= 3) {
        // Show all names if 3 or fewer other participants
        participantsDisplay = otherUsers
          .map((id) => userDisplayNamesMap[id] || "Unknown")
          .join(", ");
      } else {
        // Show count if more than 3 other participants
        participantsDisplay = `${otherUsers.length} people`;
      }
    }

    return (
      <div
        className="flex h-full flex-col"
        data-testid="chat-room-view-component"
      >
        {/* Simplified Room Header */}
        <div
          className={`flex flex-shrink-0 flex-col border-b bg-white p-2 ${showPulseAnimation ? "animate-pulse-border border-b-2" : ""}`}
        >
          {/* Action buttons row */}
          <div className="mb-2 flex items-center justify-between">
            <div className="flex items-center gap-1">
              <div className="relative">
                <ArrowBack
                  className="h-5 w-5 cursor-pointer text-gray-500"
                  onClick={onBackClick}
                />
                {/* Notification badge for unread messages in other rooms */}
                {showOtherRoomsNotification && (
                  <div
                    className={`pointer-events-none absolute -right-1 -top-1 flex h-4 min-w-[16px] items-center justify-center rounded-full bg-red-500 px-1 text-[10px] font-medium text-white ${pulseOtherRoomsNotification ? "animate-pulse" : ""}`}
                    title={`${totalUnreadInOtherRooms} unread message${totalUnreadInOtherRooms !== 1 ? "s" : ""} in other chats`}
                  >
                    {totalUnreadInOtherRooms > 9
                      ? "9+"
                      : totalUnreadInOtherRooms.toString()}
                  </div>
                )}
              </div>

              {/* Action buttons */}
              <div className="ml-3 flex gap-2">
                {/* View Job button for job chats */}
                {room.type === ChatRoomType.JOB && room.craftRecordID && (
                  <button
                    onClick={() =>
                      window.open(
                        `${WORK_RECORD_AND_TASKS_URL}/${room.craftRecordID}`,
                        "_blank",
                      )
                    }
                    className="inline-flex items-center gap-1 rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 hover:bg-blue-100"
                  >
                    <Work className="h-3 w-3" />
                    View Job
                    <OpenInNew className="h-3 w-3" />
                  </button>
                )}

                {/* View Customer button for SMS chats */}
                {room.type === ChatRoomType.SMS && room.customerID && (
                  <button
                    onClick={() =>
                      window.open(
                        `${CUSTOMERS_URL}/${room.customerID}`,
                        "_blank",
                      )
                    }
                    className="inline-flex items-center gap-1 rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 hover:bg-green-100"
                  >
                    <UserCircleIcon className="h-3 w-3" />
                    View Customer
                    <OpenInNew className="h-3 w-3" />
                  </button>
                )}
              </div>
            </div>

            {/* Add Users Button */}
            {room.type !== ChatRoomType.DIRECT &&
              // Only show Add Users button if:
              // 1. The user is a site admin, OR
              // 2. It's a job chat (regardless of admin status)
              (userPermissions?.permissions.isSiteAdmin === true ||
                room.type === ChatRoomType.JOB) && (
                <button
                  onClick={() => setShowAddUsersDialog(true)}
                  className="hover:bg-primary/90 inline-flex items-center gap-1 rounded-md bg-primary px-2 py-1 text-xs font-medium text-white"
                  title="Add Users to Chat"
                >
                  <PersonAdd className="h-3 w-3" />
                  Add Users
                </button>
              )}
          </div>

          {/* Chat info row */}
          <div className="flex items-center gap-2">
            <div className="flex -space-x-2">
              {otherUsers.slice(0, 3).map((userId) => {
                const userPhotoUrl = siteKeyUsersList.find(
                  (u) => u.id === userId,
                )?.userPhoto_URL;
                return (
                  <div key={userId} className="relative">
                    {userPhotoUrl ? (
                      <img
                        src={userPhotoUrl}
                        alt="User"
                        className="h-8 w-8 rounded-full border-2 border-white object-cover"
                      />
                    ) : (
                      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-300 text-xs text-white">
                        {userDisplayNamesMap[userId]?.substring(0, 2) || "?"}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="flex flex-col">
              <div className="flex items-center">
                {isEditingChatName &&
                room.type === ChatRoomType.GROUP &&
                isSiteAdmin ? (
                  <div className="flex items-center">
                    <input
                      type="text"
                      value={newChatName}
                      onChange={(e) => setNewChatName(e.target.value)}
                      className="mr-2 w-full rounded-md border border-gray-300 px-2 py-1 text-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary"
                      autoFocus
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleUpdateChatName();
                        } else if (e.key === "Escape") {
                          setIsEditingChatName(false);
                          setNewChatName(room.name); // Reset to original name
                        }
                      }}
                    />
                    <button
                      onClick={handleUpdateChatName}
                      className="mr-1 rounded-full p-1 text-green-600 hover:bg-green-50"
                      title="Save"
                    >
                      <Check className="h-4 w-4" />
                    </button>
                    <button
                      onClick={() => {
                        setIsEditingChatName(false);
                        setNewChatName(room.name); // Reset to original name
                      }}
                      className="rounded-full p-1 text-red-600 hover:bg-red-50"
                      title="Cancel"
                    >
                      <Close className="h-4 w-4" />
                    </button>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <div className="text-sm font-medium">
                      {getChatRoomName(otherUsers)}
                    </div>

                    {/* Edit button for group chat names (admins only) */}
                    {room.type === ChatRoomType.GROUP && isSiteAdmin && (
                      <button
                        onClick={() => setIsEditingChatName(true)}
                        className="ml-1 rounded-full p-1 text-gray-500 hover:bg-gray-100"
                        title="Edit group name"
                      >
                        <Edit className="h-3 w-3" />
                      </button>
                    )}

                    {/* SMS Indicator Badge */}
                    {isSmsConversation && (
                      <div className="ml-2 rounded-full bg-blue-100 px-2 py-0.5 text-xs font-semibold text-blue-800">
                        SMS
                      </div>
                    )}
                  </div>
                )}
              </div>

              {isGroupChat && (
                <div className="text-xs text-gray-500">
                  {participantsDisplay}
                </div>
              )}
              {customerPhone && (
                <div className="text-xs text-gray-500">
                  {phoneUtils.display(customerPhone)}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Add assignment info for SMS chats */}
        {room.type === ChatRoomType.SMS && (
          <AssignmentInfo
            room={room}
            firebaseUser={firebaseUser}
            userDisplayNamesMap={userDisplayNamesMap}
            onReassign={handleReassign}
          />
        )}

        {/* Messages */}
        <div
          className={`flex-1 overflow-y-auto p-4 pb-2 ${showPulseAnimation ? "animate-pulse-light" : ""}`}
          ref={messagesContainerRef}
          onScroll={handleScroll}
        >
          {messages ? (
            <>
              {groupMessages(messages).map((group, groupIndex) => (
                <div key={groupIndex}>
                  {group.messages.map((message, messageIndex) => (
                    <ChatBubble
                      key={message.id}
                      message={message}
                      isGrouped={group.messages.length > 1}
                      isFirstInGroup={messageIndex === 0}
                      isLastInGroup={messageIndex === group.messages.length - 1}
                      showUserInfo={isGroupChat}
                      firebaseUser={firebaseUser}
                      userDisplayNamesMap={userDisplayNamesMap}
                      siteKeyUsersList={siteKeyUsersList}
                    />
                  ))}
                </div>
              ))}

              {/* Show uploading indicators */}
              {Object.entries(uploadingImages).length > 0 && (
                <div className="flex flex-col items-end">
                  {Object.entries(uploadingImages).map(
                    ([id, { progress, file }]) => (
                      <div
                        key={id}
                        className="relative mb-3 ml-auto flex w-fit max-w-[65%] flex-col rounded-lg bg-blue-500 px-3 py-2 text-white"
                      >
                        <div className="mb-2 overflow-hidden rounded bg-blue-400 p-2">
                          <div className="flex items-center gap-2">
                            <Image className="h-6 w-6 text-white" />
                            <div className="max-w-[150px] truncate text-sm font-medium">
                              {file.name}
                            </div>
                          </div>
                          <div className="mt-2 h-2 w-full rounded-full bg-blue-300">
                            <div
                              className="h-2 rounded-full bg-white"
                              style={{ width: `${progress}%` }}
                            ></div>
                          </div>
                          <div className="mt-1 text-right text-xs">
                            {Math.round(progress)}%
                          </div>
                        </div>
                      </div>
                    ),
                  )}
                </div>
              )}

              <div ref={messagesEndRef} />
            </>
          ) : (
            <div className="flex h-full items-center justify-center">
              <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-gray-900"></div>
            </div>
          )}
        </div>

        {/* Message Input */}
        <div className="mt-auto flex items-center space-x-2 border-t border-gray-200 pt-2">
          <div className="relative flex-1">
            <span
              id="inputSimulator"
              className="block max-h-32 min-h-[40px] w-full overflow-y-auto rounded-md border border-gray-300 p-2"
              role="textbox"
              contentEditable={true}
              data-placeholder="Type a message..."
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
              onKeyDown={(event) => {
                if (event.key === "Enter" && !event.shiftKey) {
                  event.preventDefault();
                  const textContent = event.currentTarget.textContent;
                  if (!textContent) return;
                  handleAddMessage(textContent);
                  event.currentTarget.textContent = "";
                  // Force scroll to bottom after sending a message
                  setIsAtBottom(true);
                  setTimeout(() => scrollToBottom(true), 100);
                }
              }}
              style={{
                outline: "none",
              }}
            />
          </div>

          {/* Add image upload button */}
          <input
            type="file"
            id="imageUpload"
            accept="image/*"
            className="hidden"
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (file) {
                handleImageUpload(file);
                // Reset the input
                e.target.value = "";
              }
            }}
          />
          <button
            className="self-end rounded-full p-1 hover:bg-gray-100"
            onClick={() => document.getElementById("imageUpload")?.click()}
          >
            <Image className="h-6 w-6 text-primary" />
          </button>

          <button className="self-end rounded-full p-1 hover:bg-gray-100">
            <MdIcons.MdSend
              className="h-6 w-6 text-primary"
              onClick={() => {
                const textContent =
                  document.getElementById("inputSimulator")?.textContent;
                if (!textContent) return;
                handleAddMessage(textContent);
                document.getElementById("inputSimulator")!.textContent = "";
                // Force scroll to bottom after sending a message
                setIsAtBottom(true);
                setIsInputFocused(false);
                setTimeout(() => scrollToBottom(true), 100);
              }}
            />
          </button>
        </div>

        {/* Add Users Dialog */}
        <ChatRoomUserSelectionDialog
          title="Add Users to Chat"
          description="Select users to add to this chat room"
          open={showAddUsersDialog}
          onClose={() => {
            setShowAddUsersDialog(false);
            setAddUsersValues([]);
          }}
          onConfirm={() => {
            if (addUsersValues.length > 0) {
              handleAddUsersToRoom(addUsersValues);
            }
          }}
          options={siteKeyUsersList
            .filter((user) => {
              // Filter out users already in the chat
              return (
                user.id !== firebaseUser?.uid &&
                !room.userIds.includes(user.id) &&
                !user.systemUser
              );
            })
            .map((user) => ({
              value: user.id,
              text: user.displayName,
            }))}
          selectedValues={addUsersValues}
          onSelectionChange={(values) => {
            setAddUsersValues(values);
          }}
          confirmButtonText="Add to Chat"
          usersList={siteKeyUsersList.filter((user) => !user.systemUser)}
          currentParticipants={room.userIds}
          isGroupChat={room.type === ChatRoomType.GROUP}
          isJobChat={room.type === ChatRoomType.JOB}
          isSiteAdmin={userPermissions?.permissions.isSiteAdmin === true}
        />
      </div>
    );
  } catch (error) {
    logger.warn("Error rendering chat room view", error);
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <div className="text-gray-500">Error loading chat room</div>
      </div>
    );
  }
}
