//Libs
import { useEffect, useMemo, useState } from "react";
import { z } from "zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useParams } from "react-router-dom";

//Local
import * as strings from "../../strings";
import { ErrorMessage } from "../ErrorMessage";
import BaseInputNumber from "../BaseInputNumber";
import BaseInputSelect from "../BaseInputSelect";
import { logger } from "../../logging";
import BaseInputText from "../BaseInputText";
import { SelfCheckoutFormConfig } from "../../Pages/PaymentsSelfCheckout/PaymentsSelfCheckoutContainer";
import StyledMessage from "../StyledMessage";
import BaseButton from "../BaseButton";

interface Props {
  handleProceedWithPayment: (
    selfCheckoutPayment: SelfCheckoutPayment,
  ) => Promise<string | undefined>;
  selfCheckoutFormConfig: SelfCheckoutFormConfig | null;
}

export interface SelfCheckoutPayment {
  totalAmount: number;
  referenceNumber: string;
  customerName: string;
  returnURL: string;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  countryCode: string;
  phone: string;
  email: string;
}

const ziftSelfCheckoutDataSchema = z.object({
  totalAmount: z.number().min(0),
  referenceNumber: z.string().min(1).max(200),
  customerName: z.string().min(1).max(200),
  street: z.string().min(1).max(200),
  city: z.string().min(1).max(200),
  countryCode: z.string().min(0).max(2),
  state: z
    .string()
    .min(0)
    .max(2, { message: "Cannot contain more than two characters" }),
  zipCode: z.string().min(1).max(15),
  phone: z.string().min(0).max(20),
  email: z.string().min(0).max(200),
});

type SelfCheckoutFormState = z.infer<typeof ziftSelfCheckoutDataSchema>;

export default function SelfCheckoutForm(props: Props) {
  /** using this for more control over WHEN a message displays -- i.e. for the
   * state the customer lives in, we need 2 characters, but we don't want to
   * immediately show an error message when the user has only typed 1 character.
   * it's likely that they're in the middle of typing the second character. we
   * only want to show this error message when the user has clicked the submit
   * button and the form is invalid.
   * (if we don't send two chars for `state` to the server, the request will be rejected when undergoing validation.) */
  const [showFormError, setShowFormError] = useState<
    keyof SelfCheckoutFormState | null
  >(null);

  const [displayError, setDisplayError] = useState<boolean>(false);

  const [isBusy, setIsBusy] = useState<boolean>(false);

  const SelfCheckoutDefaultValues: SelfCheckoutFormState = useMemo(() => {
    return {
      siteKey: "",
      totalAmount: 0,
      referenceNumber: "",
      customerName: "",
      returnURL: "",
      street: "",
      city: "",
      state: "",
      zipCode: "",
      countryCode: "US",
      phone: "",
      email: "",
    };
  }, []);

  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<SelfCheckoutFormState>({
    defaultValues: SelfCheckoutDefaultValues,
    resolver: zodResolver(ziftSelfCheckoutDataSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset(SelfCheckoutDefaultValues);
  }, [SelfCheckoutDefaultValues, reset]);

  // Extract siteKey ID from the URL
  type UrlParams = { siteKey: string };
  const data = useParams<UrlParams>();
  const siteKey = data.siteKey;
  if (typeof siteKey !== "string") {
    throw new Error(`siteKey was not a string: ${siteKey}`);
  }

  const onSubmit: SubmitHandler<SelfCheckoutFormState> = async (formValues) => {
    const data: SelfCheckoutPayment = {
      totalAmount: formValues.totalAmount,
      referenceNumber: formValues.referenceNumber,
      customerName: formValues.customerName,
      returnURL:
        props.selfCheckoutFormConfig?.returnURL ?? window.location.href,
      street: formValues.street,
      city: formValues.city,
      state: formValues.state,
      zipCode: formValues.zipCode,
      countryCode: formValues.countryCode,
      phone: formValues.phone,
      email: formValues.email,
    };

    if (data.state.length !== 2) {
      setShowFormError("state");
      return;
    }

    try {
      setIsBusy(true);
      const paymentURL = await props.handleProceedWithPayment(data);
      if (typeof paymentURL === "string") {
        window.location.href = paymentURL;
      } else {
        setDisplayError(true);
      }
    } catch (error) {
      setDisplayError(true);
      logger.error(error);
      setIsBusy(false);
    }
  };

  const errorMessage = (
    <ErrorMessage
      message="Error proceeding with payment"
      clearMessage={() => setDisplayError(false)}
    />
  );

  const FormError = (props: { msg: string }) => {
    return (
      <div className="mt-2 text-sm">
        <StyledMessage type="error">{{ message: props.msg }}</StyledMessage>
      </div>
    );
  };

  return (
    <div className="mx-4 max-w-7xl lg:mx-auto">
      <div className="p-4">
        {props.selfCheckoutFormConfig?.merchantLogoURL && (
          <img
            src={props.selfCheckoutFormConfig?.merchantLogoURL}
            alt="merchant logo"
            className="mx-auto mb-6 max-h-[6rem] w-auto md:max-h-full md:max-w-[15rem]"
          />
        )}
        <h2
          className={`${
            props.selfCheckoutFormConfig?.merchantLogoURL ? "text-center" : ""
          } text-2xl font-semibold text-primary`}
        >
          {props.selfCheckoutFormConfig?.title ?? strings.MAKE_A_PAYMENT}
        </h2>
      </div>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-4 grid grid-cols-1 gap-8 md:grid-cols-2">
          <span>
            <Controller
              name="referenceNumber"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  inputName="referenceNumber"
                  text="Job Number"
                  admin={true}
                  placeholder={
                    props.selfCheckoutFormConfig?.referenceNumberPlaceholder
                  }
                  required={true}
                  {...field}
                  value={field.value === null ? "" : field.value}
                />
              )}
            />
            {errors.referenceNumber?.message && (
              <FormError msg={errors.referenceNumber.message} />
            )}
          </span>

          <span>
            <Controller
              name="totalAmount"
              control={control}
              render={({ field }) => (
                <BaseInputNumber
                  text="Payment Amount"
                  inputName="totalAmount"
                  admin={true}
                  required={true}
                  {...field}
                  value={field.value}
                  onChange={(event) => {
                    const numberValue = event.target.valueAsNumber;
                    if (displayError === true && numberValue > 0) {
                      setDisplayError(false);
                    }
                    field.onChange(isNaN(numberValue) ? "" : numberValue);
                  }}
                />
              )}
            />
            {errors.totalAmount?.message && (
              <FormError msg={errors.totalAmount.message} />
            )}
          </span>

          <span>
            <Controller
              name="customerName"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  inputName="customerName"
                  text="Name"
                  admin={true}
                  required={true}
                  {...field}
                  value={field.value === null ? "" : field.value}
                />
              )}
            />
            {errors.customerName?.message && (
              <FormError msg={errors.customerName.message} />
            )}
          </span>

          <span>
            <Controller
              name="street"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  inputName="street"
                  text="Street"
                  admin={true}
                  required={true}
                  {...field}
                  value={field.value === null ? "" : field.value}
                />
              )}
            />
            {errors.street?.message && (
              <FormError msg={errors.street.message} />
            )}
          </span>

          <span>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  inputName="city"
                  text="City"
                  admin={true}
                  required={true}
                  {...field}
                  value={field.value === null ? "" : field.value}
                />
              )}
            />
            {errors.city?.message && <FormError msg={errors.city.message} />}
          </span>

          <span>
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  inputName="state"
                  text="State (2-digit code)"
                  admin={true}
                  required={true}
                  {...field}
                  value={field.value === null ? "" : field.value}
                  // added the onChange event so we can clear the error message when the user types the second character.
                  onChange={(ev) => {
                    if (
                      showFormError === "state" &&
                      ev.target.value.length === 2
                    ) {
                      setShowFormError(null);
                    }
                    field.onChange(ev);
                  }}
                />
              )}
            />
            {errors.state?.message && <FormError msg={errors.state.message} />}
            {showFormError && showFormError === "state" && (
              <FormError msg="State must contain two characters" />
            )}
          </span>

          <span>
            <Controller
              name="zipCode"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  inputName="zipCode"
                  text="Zip Code"
                  admin={true}
                  required={true}
                  {...field}
                  value={field.value === null ? "" : field.value}
                />
              )}
            />
            {errors.zipCode?.message && (
              <FormError msg={errors.zipCode.message} />
            )}
          </span>

          <Controller
            name="countryCode"
            control={control}
            render={({ field }) => (
              <BaseInputSelect
                inputName="countryCode"
                text="Country"
                admin
                {...field}
              >
                <option value="US">United States</option>
                <option value="CA">Canada</option>
              </BaseInputSelect>
            )}
          />

          <span>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  inputName="phone"
                  text="Phone Number"
                  admin={true}
                  required={false}
                  {...field}
                  value={field.value === null ? "" : field.value}
                />
              )}
            />
            {errors.phone?.message && <FormError msg={errors.phone.message} />}
          </span>

          <span>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <BaseInputText
                  inputName="email"
                  text="Email Address"
                  admin={true}
                  required={false}
                  {...field}
                  value={field.value === null ? "" : field.value}
                />
              )}
            />
            {errors.email?.message && <FormError msg={errors.email.message} />}
          </span>
        </div>

        <BaseButton
          type="submit"
          disabled={props.selfCheckoutFormConfig === null}
          isBusy={isBusy}
          className="mt-8 w-full"
          primaryColor={props.selfCheckoutFormConfig?.primaryColor ?? "primary"}
          secondaryColor={
            props.selfCheckoutFormConfig?.secondaryColor ?? "primaryButtonText"
          }
        >
          {strings.PAY_NOW}
        </BaseButton>
      </form>
      {displayError ? (
        <span className="absolute bottom-10 left-1/2 w-3/4 -translate-x-1/2 sm:w-96">
          {errorMessage}
        </span>
      ) : null}
    </div>
  );
}
